var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v("You need access")]),
    _vm._m(1),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "an-button",
          {
            attrs: { isWorking: _vm.isWorking },
            on: { click: _vm.handleRequestAccess }
          },
          [_vm._v("Request access")]
        ),
        _c(
          "an-link",
          {
            attrs: { primaryHover: "", variant: "dark" },
            on: { click: _vm.handleCancel }
          },
          [_vm._v("Cancel")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/falling-woman.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _vm._v(
        " Looks like you don't have permission to contribute to this project. "
      ),
      _c("br"),
      _vm._v(" Let’s ask for access! ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }