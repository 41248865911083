var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress-bar", style: _vm.backgroundStyle },
    [_c("div", { style: _vm.barStyle })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }