var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "svg-container",
    style: _vm.style,
    domProps: {
      innerHTML: _vm._s(
        require("!html-loader!../../assets/svg/" + _vm.name + ".svg")
      )
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }