import { createStoreModule } from '../utils';
import api from '@/api';

export default createStoreModule({
  name: 'experiments',
  crud: false,
  state: {
    activeExperiments: [],
    userExperiments: {},
    featureToggles: {},
    isExperimentsDoneLoading: false
  },
  getters: {
    isActive: s => (experimentName, variant) => {
      const experiments = s.userExperiments || {};
      const toggles = s.featureToggles || {};
      const allExperimentsAndFeatures = { ...experiments, ...toggles };
      const value = allExperimentsAndFeatures[experimentName];
      if (variant) return variant === value;
      return value;
    }
  },
  mutations: {
    setActiveExperiments: (state, data) => (state.activeExperiments = data),
    setUserExperiments: (state, data) => (state.userExperiments = data),
    setFeatureToggles: (state, data) => (state.featureToggles = data),
    setIsExperimentsDoneLoading: (state, f) => (state.isExperimentsDoneLoading = f)
  },
  actions: {
    async fetchAll({ commit }) {
      const {
        data: { results }
      } = await api.list('/v2/active-experiments');
      commit('setActiveExperiments', results);
      return results;
    }
  }
});
