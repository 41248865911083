var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "checkbox-container",
        { full: _vm.full, inversed: _vm.inversed, noMargin: _vm.noMargin }
      ],
      on: { click: _vm.handleContainerClick }
    },
    [
      _c("input", {
        attrs: { type: "checkbox", hidden: "" },
        domProps: { checked: _vm.value }
      }),
      _c("div", {
        class: [
          "checkbox",
          { disabled: _vm.disabled, noMargin: _vm.noMargin },
          { checked: _vm.value }
        ],
        domProps: {
          innerHTML: _vm._s(
            require("!html-loader!./" + _vm.checkedStatus + ".svg")
          )
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.onChange.apply(null, arguments)
          }
        }
      }),
      _vm.$slots.default
        ? _c("div", { staticClass: "checkbox-label" }, [_vm._t("default")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }