var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-card", attrs: { "data-cy": "screen-card" } },
    [
      _vm._t("overlay"),
      _c(
        "div",
        {
          staticClass: "thumbnail",
          style: _vm.thumbStyle,
          on: {
            mouseover: function($event) {
              _vm.hoverThumb = true
            },
            mouseout: function($event) {
              _vm.hoverThumb = false
            }
          }
        },
        [
          _c(
            "div",
            {
              class: _vm.actionsClasses,
              on: {
                click: function($event) {
                  return _vm.$emit("click-thumb", _vm.screen)
                }
              }
            },
            [
              !_vm.readOnly
                ? _c(
                    "div",
                    { staticClass: "popover-menu-wrapper" },
                    [
                      _c("Checkbox", {
                        attrs: { value: _vm.selected, variant: "dark" },
                        on: { input: _vm.select }
                      }),
                      _vm.popoverActions.length && !_vm.linkedScreen
                        ? _c(
                            "PopoverMenu",
                            {
                              attrs: {
                                items: _vm.popoverActions,
                                position: "right"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c("svg-icon", {
                                    staticStyle: { color: "#fff" },
                                    attrs: {
                                      fill: "currentColor",
                                      name: "three-dots-horizontal",
                                      size: 30
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.linkedScreen
                ? _c(
                    "div",
                    { staticClass: "actions-text" },
                    [
                      this.$slots.cta
                        ? _vm._t("cta")
                        : _c("span", [_vm._v(" View screen ")])
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.screen.name) + " ")
        ]),
        _c(
          "div",
          { staticClass: "subtitle" },
          [
            this.$slots.subtitle
              ? _vm._t("subtitle")
              : [
                  _vm._v(" " + _vm._s(_vm.lastUpdated) + " "),
                  this.flags.hasNewComments
                    ? _c("svg-icon", {
                        attrs: { name: "talking-bubble-alert", size: 24 }
                      })
                    : _vm._e(),
                  this.flags.home
                    ? _c("svg-icon", { attrs: { name: "rocket", size: 24 } })
                    : _vm._e()
                ]
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }