var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "logo" },
    [
      _c("svg-icon", {
        attrs: { name: _vm.name, height: _vm.height, width: _vm._width }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }