var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toggle-container", on: { click: _vm.onClick } },
    [
      _vm.offTitle
        ? _c(
            "div",
            {
              staticClass: "title-label",
              class: { "title-label-on": !_vm.value },
              attrs: { "data-content": _vm.offTitle }
            },
            [_vm._v(" " + _vm._s(_vm.offTitle) + " ")]
          )
        : _vm._e(),
      _vm.$slots.default
        ? _c("div", { staticClass: "toggle-label" }, [_vm._t("default")], 2)
        : _vm._e(),
      _c("input", {
        attrs: { type: "checkbox", hidden: "", "aria-hidden": "true" },
        domProps: { checked: _vm.value }
      }),
      _c("div", { staticClass: "toggle" }, [
        _c("div", { staticClass: "toggle-inner" }, [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                require("!html-loader!./" + _vm.toggleStatus + ".svg")
              )
            }
          })
        ])
      ]),
      _vm.onTitle
        ? _c(
            "div",
            {
              staticClass: "title-label",
              class: { "title-label-on": _vm.value },
              attrs: { "data-content": _vm.onTitle }
            },
            [_vm._v(" " + _vm._s(_vm.onTitle) + " ")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }