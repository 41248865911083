var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("an-input", {
          attrs: { placeholder: "Name your new team", focus: "" },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.create.apply(null, arguments)
            }
          },
          model: {
            value: _vm.teamName,
            callback: function($$v) {
              _vm.teamName = $$v
            },
            expression: "teamName"
          }
        })
      ],
      1
    ),
    _vm.teamName
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "an-button",
              {
                attrs: { isWorking: _vm.isWorking },
                on: { click: _vm.create }
              },
              [_vm._v("Create team")]
            ),
            _c(
              "an-link",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/ashamed-man.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }