var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Mentionable",
    {
      style: { display: "flex", alignItems: "center" },
      attrs: { keys: ["@"], items: _vm.items, offset: "6", insertSpace: "" },
      on: { stateChange: _vm.handleStateChange }
    },
    [
      _c(
        "textarea",
        _vm._b(
          {
            ref: "elementRef",
            class: ["base-textarea", _vm.className, _vm.textareaClasses],
            style: { maxHeight: _vm.allowMultiline ? "auto" : "42px" },
            domProps: { value: _vm.value },
            on: {
              input: _vm.handleInput,
              keydown: _vm.handleKeydown,
              blur: function($event) {
                _vm.$emit("blur", $event)
                _vm.focused = false
              },
              focus: function($event) {
                _vm.focused = true
              }
            }
          },
          "textarea",
          _vm.$attrs,
          false
        )
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }