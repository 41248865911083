var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "account-popover-container",
      attrs: { tabindex: "-1" },
      on: { focusout: _vm.close }
    },
    [
      _c("an-user-avatar", {
        attrs: { "data-cy": "toggle-open-account", user: _vm.user, size: 30 },
        on: { click: _vm.toggleOpen }
      }),
      _c(
        "Popover",
        {
          style: _vm.popoverStyles,
          attrs: { isOpen: _vm.isOpen },
          on: { close: _vm.close }
        },
        [
          _c(
            "div",
            { staticClass: "user-details" },
            [
              _c("an-user-avatar", {
                attrs: { user: _vm.user, size: 100 },
                on: { click: _vm.openAccountModal }
              }),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.user.name))
              ]),
              _c("div", { staticClass: "email" }, [
                _vm._v(_vm._s(_vm.user.email))
              ]),
              _c(
                "an-button",
                {
                  attrs: { "data-cy": "my-account", variant: "secondary" },
                  on: { click: _vm.openAccountModal }
                },
                [_vm._v("My Account")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "divider" }),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "an-link",
                {
                  attrs: {
                    href: "https://support.animaapp.com/en/",
                    target: "_blank"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent("account-panel.support.click")
                    }
                  }
                },
                [_vm._v(" Help & FAQ ")]
              ),
              _c(
                "router-link",
                {
                  attrs: { tag: "an-link", to: { name: "plugins" } },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent("account-panel.plugins.click")
                    }
                  }
                },
                [_vm._v(" Install plugin ")]
              ),
              _c(
                "an-link",
                {
                  attrs: {
                    href: "https://uservoice.animaapp.com/",
                    target: "_blank"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent(
                        "account-panel.suggest-features.click"
                      )
                    }
                  }
                },
                [_vm._v(" Suggest features ")]
              ),
              _c(
                "an-link",
                {
                  attrs: {
                    href:
                      "https://form.asana.com?hash=037ae77345711c39e8f763cd47b3581ffca0dc6d9fd9ea58f941d652ed8a05e9&id=1193275689522372",
                    target: "_blank"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent("account-panel.feedback.click")
                    }
                  }
                },
                [_vm._v(" Feedback ")]
              )
            ],
            1
          ),
          _vm.showSlackIntegration
            ? _c("div", { staticClass: "divider" })
            : _vm._e(),
          _vm.showSlackIntegration
            ? _c("an-link", { on: { click: _vm.connectSlack } }, [
                _c("div", { staticClass: "inline" }, [
                  _c(
                    "div",
                    { staticClass: "slack-icon" },
                    [
                      _c("svg-icon", {
                        attrs: { name: "slack-logo", size: 24 }
                      })
                    ],
                    1
                  ),
                  _c("span", [_vm._v("Connect with Slack")])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "divider" }),
          _c(
            "an-link",
            { attrs: { primaryHover: "" }, on: { click: _vm.logout } },
            [_vm._v("Log out")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }