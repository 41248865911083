import Vue from 'vue';
import Vuex from 'vuex';
// import createLogger from 'vuex/dist/logger';
import * as modules from './modules';

const __IS_DEV__ = process.env.NODE_ENV === 'development';

Vue.use(Vuex);

export default new Vuex.Store({ modules, plugins: __IS_DEV__ ? [] : [] });
