import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'notification',
  modelName: 'notifications',
  crud: true,
  endpoint: '/v2/users/me/notifications',
  actions: {
    setAsRead({ state, commit }, { id }) {
      const { items, count, page, total } = state;
      const results = items.map(item => {
        if (item.id === id) return { ...item, status: 'READ' };
        return item;
      });
      commit('setItems', { results, count, page, total });
    }
  },
  getters: {
    hasNewComments(state) {
      return ({ id = '', type = 'project' } = {}) => {
        const { items } = state;
        const unreadProjectComments = items.filter(n => {
          const { event_type, status } = n;
          return status === 'UNREAD' && event_type === 'COMMENT' && n[type]?.id === id;
        });
        return !!unreadProjectComments.length;
      };
    }
  }
});
