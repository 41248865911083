var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.transitionName } },
    [
      _vm.isOpen
        ? _c("Modal", {
            attrs: {
              variant: _vm.variant,
              width: _vm.width,
              mode: _vm.mode,
              opacity: _vm.opacity,
              whiteOverlay: _vm.whiteOverlay,
              background: _vm.background,
              component: _vm.componentName,
              componentProps: _vm.componentProps,
              closeButton: _vm.closeButton
            },
            on: { close: _vm.closeModal, updateParams: _vm.handleUpdate }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }