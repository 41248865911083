var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      d:
        "M8.837 7.606a2 2 0 0 0 0-3.212L3.692.575C2.372-.405.5.538.5 2.181V9.82c0 1.643 1.872 2.585 3.192 1.606l5.145-3.82z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }