import { initConversion as init } from 'anima-ppc-tracking';

export const initConversion = () => {
  const config = {
    facebookPixelId: process.env.FB_PIXEL_ID,
    googleMeasurementId: process.env.GA_MEASUREMENT_ID,
    linkedinId: process.env.LINKEDIN_PIXEL_ID,
    quoraPixelId: process.env.QUORA_PIXEL_ID
  };
  init(config);
};
