<template>
  <transition name="fade">
    <div v-if="show && events.length > 0" class="debug-events" @mouseover="hover = true" @mouseleave="hover = false">
      <transition-group name="slide-in-left" tag="ol">
        <li v-for="event in events" :key="event.id" @click="copyEventName(event)">
          <code>
            <span class="time">{{ event.time }}</span>
            <span>{{ event.eventName }}</span>
            <br />
            <span class="props" v-html="event.propsHtml" />
          </code>
        </li>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';
import { uuid } from '@/utils/uuid';
import { deleteArrayItemById } from '@/utils/javascript';
import { mapState } from 'vuex';
import { formatDate } from '@/utils/date';
import copy from '@/utils/copyToClp';

export default {
  data() {
    return {
      hover: false,
      shouldShow: false,
      events: [],
      timeoutIds: []
    };
  },
  mounted() {
    this.shouldShow = localStorage.getItem('debug-events');
    EventBus.$on('toggle-debug-events', this.toggleEvents);
    EventBus.$on('add-debug-event', this.addEvent);
  },
  destroyed() {
    EventBus.$off('toggle-debug-events', this.toggleEvents);
    EventBus.$off('add-debug-event', this.addEvent);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    show() {
      return this.user.is_admin && this.shouldShow;
    }
  },
  methods: {
    toggleEvents(value) {
      this.shouldShow = !!value;
    },
    mapProps(props) {
      const keys = Object.keys(props);
      const joined = keys.map(key => `<b>${key}</b>: ${props[key]}`).join(', ');
      return `{ ${joined} }`;
    },
    addEvent({ eventName, params: props = {} }) {
      if (!this.show) return;
      const id = uuid();
      const time = formatDate(new Date(), 'hh:mm:ss');
      this.events = [{ id, eventName, propsHtml: this.mapProps(props), time }, ...this.events];
      this.timeoutIds.push(setTimeout(() => this.removeEvent(id), 10000));
    },
    removeEvent(id) {
      if (!this.hover) {
        this.events = deleteArrayItemById(this.events, id);
      }
    },
    copyEventName({ eventName }) {
      copy(eventName);
    }
  },
  watch: {
    hover(value) {
      if (!value) {
        this.events = [];
        for (const timeoutId of this.timeoutIds) {
          clearTimeout(timeoutId);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.debug-events {
  position: fixed;
  left: 10px;
  bottom: 10px;
  max-height: 100px;
  width: 500px;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--white);
  box-shadow: var(--shadow);
  color: var(--primary-text);
  overflow: scroll;
  z-index: 10000;
  transition: all 0.2s ease;
  &:hover {
    max-height: 500px;
  }
}
code {
  font-size: 14px;
}
.time,
.props {
  font-size: 10px;
}
span + span {
  margin-left: 10px;
}
li {
  cursor: pointer;
  padding: 5px 0;
  &:hover {
    background-color: var(--light-container-background);
  }
  &:not(:hover) {
    @include ellipsis;
  }
}
</style>
