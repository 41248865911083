var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "onboarding" },
    [
      _c("TopBar", { attrs: { closeButton: "" }, on: { close: _vm.onClose } }),
      _c("Pricing", { attrs: { theme: _vm.theme, onboardingFlow: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }