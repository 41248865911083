var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "horizontal-card",
      on: {
        mouseover: function($event) {
          _vm.showButton = true
        },
        mouseleave: function($event) {
          _vm.showButton = false
        }
      }
    },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "content", class: _vm.disabledClass }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", [_vm._v(_vm._s(_vm.text))])
        ])
      ]),
      _vm.disabled && _vm.disabledText
        ? _c("div", { staticClass: "disabled-text" }, [
            _vm._v(_vm._s(_vm.disabledText))
          ])
        : _vm._e(),
      !_vm.disabled && _vm.showButton
        ? _c(
            "div",
            [
              _c(
                "an-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.ctaText))]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }