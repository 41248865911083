var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header" },
    _vm._l(_vm.views, function(view) {
      return _c(
        "div",
        {
          key: view.name,
          staticClass: "view-selector",
          on: {
            click: function($event) {
              return _vm.handleViewChange(view.name)
            }
          }
        },
        [_c("svg-icon", { attrs: { name: view.icon } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }