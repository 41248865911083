var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Plan and Billing")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          "Change or update your team plan and keep track of your billing settings."
        )
      ]),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "separated" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Current subscription")]),
          _c("div", { staticClass: "plan" }, [
            _c("div", { staticClass: "plan-header" }, [
              _c("div", { staticClass: "plan-header-text plan-name" }, [
                _c("span", [_vm._v(_vm._s(_vm.planName) + " plan")]),
                _vm.isPaused
                  ? _c("span", { staticClass: "warn-text" }, [
                      _vm._v(_vm._s(_vm.pauseText))
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "plan-header-cta" },
                [
                  _vm.isActivePlan
                    ? _c(
                        "an-link",
                        {
                          staticClass: "plan-header-cta-cancel",
                          on: { click: _vm.cancelPlan }
                        },
                        [_vm._v(" Cancel plan ")]
                      )
                    : _vm._e(),
                  _c(
                    "an-button",
                    {
                      attrs: {
                        variant: "secondary",
                        isWorking: _vm.isModifyPlanWorking
                      },
                      on: { click: _vm.onChangePlanClick }
                    },
                    [_vm._v(" " + _vm._s(_vm.modifyPlanButtonText) + " ")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "plan-text" }, [
              _vm._v(" " + _vm._s(_vm.planInterval) + " "),
              !_vm.isPaused
                ? _c(
                    "div",
                    {
                      class: { "warn-text": _vm.isCancelled || _vm.isPassedDue }
                    },
                    [_vm._v(" " + _vm._s(_vm.planRenewal) + " ")]
                  )
                : _vm._e()
            ])
          ])
        ]),
        !_vm.isEmpty(_vm.activeSubscription) &&
        !(_vm.isCancelled || _vm.isPaused)
          ? _c("div", { staticClass: "separated" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("Contributors on your team")
              ]),
              _c("div", { staticClass: "seats" }, [
                _c("div", { staticClass: "seats-header" }, [
                  _c("div", { staticClass: "seats-header-text seats-name" }, [
                    _c("span", [_vm._v(_vm._s(_vm.contributorsTitle))])
                  ]),
                  _c(
                    "div",
                    { staticClass: "seats-header-cta" },
                    [
                      _c(
                        "an-button",
                        {
                          attrs: { variant: "secondary" },
                          on: { click: _vm.onAddSeatsClick }
                        },
                        [_vm._v(" Add seats ")]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "seats-text" }, [
                  _vm._v(" " + _vm._s(_vm.pricePerUserStatement)),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.seatsUsedStatement) + " ")
                ])
              ])
            ])
          : _vm._e(),
        !_vm.isEmpty(_vm.activeSubscription)
          ? _c("div", { staticClass: "separated" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("Payment information")
              ]),
              _c("div", { staticClass: "payment" }, [
                _c(
                  "div",
                  { staticClass: "payment-header" },
                  [
                    _c("div", { staticClass: "payment-header-text" }, [
                      _c("span", { staticClass: "payment-amount" }, [
                        _vm._v(
                          _vm._s(_vm.totalPrice(_vm.activeSubscription)) + " "
                        )
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.paymentIntervalStatement))])
                    ]),
                    _c(
                      "div",
                      { staticClass: "payment-header-cta" },
                      [
                        _vm.isCancelled &&
                        _vm.team.credit_card_last_digits &&
                        _vm.isStripeActiveSubscription
                          ? _c(
                              "an-link",
                              {
                                staticClass: "payment-header-cta-cancel",
                                attrs: { primaryHover: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showDeleteCardPrompt = true
                                  }
                                }
                              },
                              [_vm._v(" Delete card ")]
                            )
                          : !_vm.showStripeCard &&
                            _vm.isStripeActiveSubscription
                          ? _c(
                              "an-button",
                              {
                                attrs: {
                                  variant: "secondary",
                                  isWorking: _vm.isManagePaymentWorking
                                },
                                on: { click: _vm.onManagePaymentClick }
                              },
                              [_vm._v(" Manage payment ")]
                            )
                          : _vm._e(),
                        _vm.isPaypalActiveSubscription
                          ? _c(
                              "an-button",
                              {
                                attrs: { variant: "secondary" },
                                on: { click: _vm.openPaypal }
                              },
                              [_vm._v(" Open PayPal ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.showDeleteCardPrompt
                      ? _c(
                          "an-prompt",
                          {
                            on: {
                              close: function($event) {
                                _vm.showDeleteCardPrompt = false
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "prompt-content" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Delete payment method")
                              ]),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "Are you sure you want to delete this payment method?"
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "actions" },
                                [
                                  _c(
                                    "an-button",
                                    {
                                      attrs: {
                                        isWorking: _vm.isManagePaymentWorking
                                      },
                                      on: { click: _vm.removeStripeCard }
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                  _c(
                                    "an-link",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.showDeleteCardPrompt = false
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "payment-text" }, [
                  _c("div", [_vm._v(_vm._s(_vm.nextPayment))]),
                  _vm.showStripeCard
                    ? _c("div", { staticClass: "payment-label" }, [
                        _vm._v("Payment method")
                      ])
                    : _vm._e(),
                  _vm.showStripeCard
                    ? _c("div", [
                        _c("div", { staticClass: "stripe-card" }, [
                          _c(
                            "div",
                            [
                              _c("StripeCardInput", {
                                on: {
                                  mount: _vm.onStripeCardMount,
                                  change: _vm.onStripeCardChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "an-button",
                                {
                                  attrs: {
                                    variant: "secondary",
                                    disabled: !_vm.validCard,
                                    isWorking: _vm.isManagePaymentWorking
                                  },
                                  on: { click: _vm.updateCard }
                                },
                                [_vm._v(" Update ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "an-link",
                                { on: { click: _vm.onCancelUpdateCard } },
                                [_vm._v("Dismiss")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.paymentInformationText) + " ")
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "separated" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("Invoice to")]),
            _c("an-textarea", {
              attrs: {
                placeholder: "Acme Corp, Inc. VAT 47845734",
                withBorder: "",
                allowMultiline: ""
              },
              model: {
                value: _vm.invoiceTo,
                callback: function($$v) {
                  _vm.invoiceTo = $$v
                },
                expression: "invoiceTo"
              }
            })
          ],
          1
        ),
        _vm.invoices && _vm.invoices.length
          ? _c("div", { staticClass: "payment-table" }, [
              _c("div", { staticClass: "label" }, [_vm._v("Invoices")]),
              _c("table", { staticClass: "table-container" }, [
                _vm._m(0),
                _c(
                  "tbody",
                  { staticClass: "table-body" },
                  _vm._l(_vm.invoices, function(item, i) {
                    return _c("tr", { key: "row" + i }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(item.created * 1000, "MMM DD, YYYY")
                          )
                        )
                      ]),
                      _c("td", [_vm._v("Invoice")]),
                      _c("td", [_vm._v(_vm._s(_vm.invoicePrice(item)))]),
                      _c(
                        "td",
                        { staticClass: "flex" },
                        [
                          _c(
                            "an-link",
                            {
                              staticClass: "clickable-icon",
                              attrs: {
                                href: item.hosted_invoice_url,
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$trackEvent(
                                    "team-settings-billing.view-invoice.click"
                                  )
                                }
                              }
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "eye", size: 24 }
                              })
                            ],
                            1
                          ),
                          _c(
                            "an-link",
                            {
                              staticClass: "clickable-icon",
                              attrs: { href: item.invoice_pdf, download: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$trackEvent(
                                    "team-settings-billing.download-invoice.click"
                                  )
                                }
                              }
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "download-dark", size: 24 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.hasChanges
          ? _c(
              "div",
              { staticClass: "actions fixed" },
              [
                _c(
                  "an-button",
                  {
                    attrs: { isWorking: _vm.isWorking },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Save changes")]
                ),
                _c(
                  "an-link",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "table-header" }, [
      _c("tr", [
        _c("td", [_vm._v("Date")]),
        _c("td", [_vm._v("Type")]),
        _c("td", [_vm._v("Amount")]),
        _c("td", [_vm._v("Invoices")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }