import router from '@/router';
import { EventBus } from '@/services/bus';
import axios from 'axios';
import { toRgba } from 'color2k';
import { findKey } from 'lodash-es';
import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'styleguide',
  modelName: 'styleguide',
  crud: true,
  endpoint: '/v2/styleguide',

  state: {
    styleguide: {},
    currentClassData: {
      className: '',
      text: ''
    },
    isStyleguideLoading: true
  },

  getters: {
    currentStyleguide: state => state.styleguide,
    currentClassData: state => state.currentClassData,
    isStyleguideLoading: state => state.isStyleguideLoading,
    currentStylesheet(_state, getters, rootState, rootGetters) {
      const styleguide = getters['currentStyleguide'];
      const syntax = rootGetters['omniview/codegenStylesheetLang'];
      return generateStylesheetFromStyleguide(styleguide, syntax);
    }
  },
  mutations: {
    setCurrentClassData: (state, data) => (state.currentClassData = data)
  },
  actions: {
    updateLocalStyleguideClass: ({ state }, payload) => {
      const { tokenId, newTokenId, type } = payload;
      let { classes, tokens } = state.styleguide;
      let overrides = {};
      let key = '';

      switch (type) {
        case 'class':
          {
            key = findKey(classes, c => c.name == tokenId);

            if (!key) return {};

            let newClasses = {
              ...classes,
              [key]: {
                ...classes[key],
                name: newTokenId
              }
            };

            state.styleguide = {
              ...state.styleguide,
              classes: newClasses
            };
            overrides = {
              tokens: {},
              classes: {
                [key]: newTokenId
              }
            };
          }

          break;
        case 'token':
          {
            key = findKey(tokens, c => c.token == tokenId);

            if (!key) {
              return {};
            }

            let newTokens = {
              ...tokens,
              [key]: {
                ...tokens[key],
                token: newTokenId
              }
            };

            state.styleguide = {
              ...state.styleguide,
              tokens: newTokens
            };
            overrides = {
              classes: {},
              tokens: {
                [key]: newTokenId
              }
            };
          }
          break;

        default:
          state.styleguide = {};
          break;
      }

      return overrides;
    },
    updateStyleguide: async ({ state, dispatch }, payload) => {
      const overrides = await dispatch('updateLocalStyleguideClass', payload);
      const { projectId } = router.currentRoute.params;
      const { id: styleguideId } = state.styleguide;
      let url = `/project/${projectId}/styleguide/${styleguideId}/overrides`;
      await axios.put(url, {
        overrides
      });
      EventBus.$emit('generate-code', { forceGenerate: true });
    }
  },
  crudMutations: {
    setItems: (state, { results, total, count, page }) => {
      state.total = total;
      state.count = count;
      state.page = page;
      state.items = results;

      state.styleguide = results.length > 0 ? results[0] : {};

      state.isStyleguideLoading = false;
    }
  }
});

export const generateStylesheetFromStyleguide = (styleguide, syntax = 'css') => {
  const { classes, tokens } = styleguide;

  if (!classes || !tokens) return '';

  let rootName = syntax === 'css' ? ':root' : '';
  let openClause = syntax === 'sass' ? '' : ' {';
  let closeClause = syntax === 'sass' ? '' : '}';
  let lineTermination = syntax === 'sass' ? '' : ';';
  let variable = ({ token, declaration = false }) => {
    // SASS
    if (['sass', 'scss'].includes(syntax)) return `$${token}`;
    // CSS
    if (declaration) return `--${token}`;
    else return `var(--${token})`;
  };
  let tab = () => `  `;
  let style = '';

  let colorTokens = [],
    fontSizeTokens = [],
    fontFamilyTokens = [];

  let sortedTokens = [];

  // THIS PATCHES A BUG IN CODEGEN REMOVE LATER

  Object.keys(tokens).forEach(key => {
    let { token_type, value } = tokens[key];

    if (token_type == 'color') {
      colorTokens.push({
        ...tokens[key],
        value: toRgba(value)
      });
    }
    if (token_type == 'font_family') {
      fontFamilyTokens.push(tokens[key]);
    }
    if (token_type == 'font_size') {
      fontSizeTokens.push(tokens[key]);
    }
  });

  const largeSizeRegex = new RegExp('x{1,}l$');
  const smallSizeRegex = new RegExp('x{1,}s$');
  let sizeOrder = ['s', 'm', 'l'];
  let tFonTokens = [];
  let otherFontTokens = [];

  fontSizeTokens.forEach(t => {
    const { token } = t;
    const isRegularSize = sizeOrder.some(s => token.endsWith(`-${s}`));
    const isLargeSize = largeSizeRegex.test(token);
    const isSmallSize = smallSizeRegex.test(token);
    if (isRegularSize || isLargeSize || isSmallSize) {
      tFonTokens.push(t);
    } else {
      otherFontTokens.push(t);
    }
  });

  let allSizes = tFonTokens.map(({ token }) => token.substring(token.lastIndexOf('-') + 1));

  let smallSizes = [];
  let regularSizes = [];
  let largeSizes = [];

  allSizes.forEach(s => {
    if (smallSizeRegex.test(s)) {
      smallSizes.push(s);
    } else if (largeSizeRegex.test(s)) {
      largeSizes.push(s);
    } else {
      regularSizes.push(s);
    }
  });

  smallSizes.sort((a, b) => b.length - a.length);
  largeSizes.sort((a, b) => a.length - b.length);
  regularSizes.sort((a, b) => {
    return sizeOrder.indexOf(a) - sizeOrder.indexOf(b);
  });

  let sortedSizes = [...smallSizes, ...regularSizes, ...largeSizes];

  tFonTokens.sort((a, b) => {
    let aSize = a.token.substring(a.token.lastIndexOf('-') + 1);
    let bSize = b.token.substring(b.token.lastIndexOf('-') + 1);
    return sortedSizes.indexOf(aSize) - sortedSizes.indexOf(bSize);
  });

  otherFontTokens.sort((a, b) => {
    // return parseInt(a.value, 10) - parseInt(b.value, 10);
    return a.token.length - b.token.length;
  });

  fontSizeTokens = [...tFonTokens, ...otherFontTokens];

  sortedTokens = [...colorTokens, ...tFonTokens, ...otherFontTokens, ...fontFamilyTokens];

  if (sortedTokens.length > 0 && rootName) {
    style = `${rootName}${openClause}\n`;
  }

  for (let i = 0; i < sortedTokens.length; i++) {
    let nextTokenType = i <= sortedTokens.length - 2 ? sortedTokens[i + 1].token_type : '';
    let { token, value, token_type } = sortedTokens[i];
    style += `${syntax === 'css' ? tab() : ''}${variable({ token, declaration: true })}: ${value}${lineTermination}\n${
      nextTokenType && nextTokenType != token_type ? `\n` : ''
    }`;
  }

  if (sortedTokens.length > 0) {
    style += `${closeClause}\n`;
  }

  const tokensSheet = `/* Variables */\n\n${style}`;
  style = '';

  Object.keys(classes).forEach(key => {
    let { name, properties } = classes[key];

    style += `.${name}${openClause}\n`;

    for (let i = 0; i < properties.length; i++) {
      const { prop_name, prop_type, prop_value } = properties[i];
      if (prop_type == 'reference') {
        style += `${tab() + prop_name}: ${variable({ token: tokens[prop_value]['token'] })}${lineTermination}\n`;
      } else {
        style += `${tab() + prop_name}: ${prop_value}${lineTermination}\n`;
      }
    }

    style += `${closeClause}\n`;
  });

  const classesSheet = `/* Classes */\n\n${style}`;

  return {
    tokensSheet,
    classesSheet,
    full: `${tokensSheet}${classesSheet}`
  };
};
