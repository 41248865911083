var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "an-card" }, [
    _vm.$slots.header
      ? _c("div", { staticClass: "an-card-head" }, [_vm._t("header")], 2)
      : _vm._e(),
    _c(
      "div",
      { staticClass: "an-card-body" },
      [_vm.$slots.body ? _vm._t("body") : _vm._t("default")],
      2
    ),
    _vm.$slots.footer
      ? _c("div", { staticClass: "an-card-footer" }, [_vm._t("footer")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }