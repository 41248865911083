var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toggle-container", on: { click: _vm.onClick } },
    [
      _c("input", {
        attrs: { type: "checkbox", hidden: "", "aria-hidden": "true" },
        domProps: { checked: _vm.value }
      }),
      _c("div", { staticClass: "toggle" }, [
        _c("div", { staticClass: "toggle-inner" }, [
          _c("div", { staticClass: "text", class: { active: _vm.value } }, [
            _vm._v(_vm._s(_vm.onText))
          ]),
          _c("div", { staticClass: "text", class: { active: !_vm.value } }, [
            _vm._v(_vm._s(_vm.offText))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }