var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Your code is ready")]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm._v(" Your download should start automatically. "),
        _c("br"),
        _vm._v(" If it doesn’t, "),
        _c(
          "an-link",
          {
            attrs: {
              variant: "primary",
              href: _vm.downloadUrl,
              download: "",
              target: "_blank"
            }
          },
          [_vm._v("click here")]
        ),
        _vm._v(". ")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "click" },
      [
        _c("an-link", { attrs: { theme: "dark" }, on: { click: _vm.close } }, [
          _vm._v("Close")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }