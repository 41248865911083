var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pref-pop" }, [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        { staticClass: "flex flex-col" },
        _vm._l(_vm.tabs, function(t) {
          return _c(
            "div",
            {
              key: t.name,
              staticClass: "tab-title flex items-center",
              class: {
                selected: _vm.codegenLang == t.name,
                disabled: t.disabled
              },
              on: {
                click: function($event) {
                  return _vm.handleLangChange(t)
                }
              }
            },
            [_c("div", { staticClass: "label" }, [_vm._v(_vm._s(t.label))])]
          )
        }),
        0
      ),
      _c("div", { staticClass: "flex items-center tab-body" }, [
        _vm.codegenLang === "react"
          ? _c(
              "div",
              { staticClass: "flex items-start" },
              [
                _c("CodePrefCol", {
                  staticStyle: { "margin-right": "20px" },
                  attrs: {
                    value: _vm.codegenReactSyntax,
                    title: "Syntax",
                    options: _vm.reactSyntaxOptions
                  },
                  on: { change: _vm.handleReactSyntaxChange }
                }),
                _c("CodePrefCol", {
                  attrs: {
                    value: _vm.codegenReactStyle,
                    title: "Styling",
                    options: _vm.reactStyleOptions
                  },
                  on: { change: _vm.handleReactStyleChange }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.codegenLang === "html"
          ? _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("CodePrefCol", {
                  attrs: {
                    value: _vm.codegenHTMLLayout,
                    title: "Layout",
                    options: _vm.htmlLayoutOptions
                  },
                  on: { change: _vm.handleHTMLLayoutChange }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.codegenLang === "vue"
          ? _c(
              "div",
              [
                _c("CodePrefCol", {
                  attrs: {
                    value: _vm.codegenVueStyle,
                    title: "Styling",
                    options: _vm.vueStyleOptions
                  },
                  on: { change: _vm.handleVueStyleChange }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }