var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-row-container",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "project-details" }, [
        _c(
          "div",
          { staticClass: "thumb" },
          [
            _c(
              "RoundedImage",
              {
                attrs: {
                  width: 105,
                  height: 60,
                  src: _vm.thumbnail,
                  showSlot: ""
                }
              },
              [
                _vm.isLocked
                  ? _c(
                      "div",
                      { staticClass: "locked" },
                      [
                        _c("svg-icon", { attrs: { name: "lock", size: 20 } }),
                        _c("span", [_vm._v("Project locked")])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.project.name))
          ]),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.subtitle) + " ")
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "members" },
        [_c("Members", { attrs: { members: _vm.members, size: 30 } })],
        1
      ),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _vm.actions && _vm.actions.length
            ? _c(
                "PopoverMenu",
                { attrs: { items: _vm.popoverActions, position: "right" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clickable-icon",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "three-dots-horizontal", size: 30 }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }