<template>
  <div class="account-popover-container" tabindex="-1" @focusout="close">
    <an-user-avatar  data-cy="toggle-open-account" :user="user" :size="30" @click="toggleOpen" />
    <Popover :isOpen="isOpen" :style="popoverStyles" @close="close">
      <div class="user-details">
        <an-user-avatar :user="user" :size="100" @click="openAccountModal" />
        <div class="name">{{ user.name }}</div>
        <div class="email">{{ user.email }}</div>
        <an-button data-cy="my-account" @click="openAccountModal" variant="secondary">My Account</an-button>
      </div>
      <div class="divider" />
      <div class="actions">
        <an-link
          href="https://support.animaapp.com/en/"
          @click.native="$trackEvent('account-panel.support.click')"
          target="_blank"
        >
          Help &amp; FAQ
        </an-link>
        <router-link tag="an-link" :to="{ name: 'plugins' }" @click.native="$trackEvent('account-panel.plugins.click')">
          Install plugin
        </router-link>
        <an-link
          href="https://uservoice.animaapp.com/"
          @click.native="$trackEvent('account-panel.suggest-features.click')"
          target="_blank"
        >
          Suggest features
        </an-link>
        <an-link
          href="https://form.asana.com?hash=037ae77345711c39e8f763cd47b3581ffca0dc6d9fd9ea58f941d652ed8a05e9&id=1193275689522372"
          target="_blank"
          @click.native="$trackEvent('account-panel.feedback.click')"
        >
          Feedback
        </an-link>
      </div>
      <div class="divider" v-if="showSlackIntegration" />
      <an-link @click="connectSlack" v-if="showSlackIntegration">
        <div class="inline">
          <div class="slack-icon"><svg-icon name="slack-logo" :size="24" /></div>
          <span>Connect with Slack</span>
        </div>
      </an-link>
      <div class="divider" />
      <an-link @click="logout" primaryHover>Log out</an-link>
    </Popover>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import auth from '@/auth';
import Popover from '@/components/Popovers/Popover';
import { createCookie } from '@/utils/cookie';
import { EventBus, toastSuccess } from '@/services/bus';

export default {
  data() {
    return {
      isOpen: false,
      popoverStyles: {
        width: '360px',
        padding: '30px',
        boxShadow: 'var(--slightly-stronger-shadow)'
      }
    };
  },
  components: {
    Popover
  },
  mounted() {
    EventBus.$on('toggle-account-popover', this.toggleOpen);
  },
  destroyed() {
    EventBus.$off('toggle-account-popover', this.toggleOpen);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters('teamMemberships', { userTeamMemberships: 'userTeams' }),
    showSlackIntegration() {
      return this.userTeamMemberships?.length > 1;
    }
  },
  methods: {
    ...mapActions({
      createNotificationsSettings: 'notificationsSettings/create'
    }),
    handleSwitchUI() {
      this.$trackEvent('account-panel.switch-webapp.click', { target: 'anima3' });
      this.close();

      createCookie('X-Source', 'anima3');

      toastSuccess("See you on the other side! (Reload page if you Scotty didn't beam you)");
      setTimeout(() => {
        window.location.replace(process.env.APP_BASE_URL);
      }, 2000);
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$trackEvent('account-panel.open');
      } else {
        this.$trackEvent('account-panel.close');
      }
    },
    close() {
      this.isOpen = false;
    },
    openAccountModal() {
      this.$trackEvent('account-panel.account-page.click');
      this.$router.push({ name: 'account' });
    },
    async connectSlack() {
      this.$trackEvent('account-panel.connect-slack.click');
      const { userTeamMemberships } = this;
      if (userTeamMemberships?.length === 1) {
        const { slug: teamSlug } = userTeamMemberships[0];
        this.$router.push({ name: 'team-settings-general', params: { teamSlug } });
      } else {
        this.$router.push({ name: 'connect-slack' });
      }
    },
    logout() {
      this.$trackEvent('account-panel.logout');
      auth.logout();
      this.$router.replace({ name: 'login' });
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.account-popover-container {
  display: inline-block;
  position: relative;
  outline: none;
}
.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: default;
  .name {
    @include secondary-title;
    margin-top: 20px;
    font-size: 22px;
  }
  .email {
    color: var(--secondary-text);
    margin: 6px 0 20px;
  }
}
.divider {
  width: 100%;
  border-bottom: var(--border);
  margin: 20px 0;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  * + * {
    margin-top: 10px;
  }
}
.inline {
  display: flex;
  align-items: center;
  .slack-icon {
    margin-right: 10px;
  }
}
</style>
