var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "div",
        [
          _vm.itemsType === "project"
            ? _c(
                "transition-group",
                {
                  style: _vm.gridStyle,
                  attrs: { name: "slide-card", tag: "div", mode: "out-in" }
                },
                _vm._l(_vm.items, function(item) {
                  return _c("ProjectCard", {
                    key: item.id,
                    attrs: {
                      project: item,
                      actions: _vm.actions,
                      members: _vm.members,
                      subtitle: item.subtitle,
                      flags: item.flags,
                      forceShowActions: _vm.forceShowActions
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClickCard(item)
                      }
                    }
                  })
                }),
                1
              )
            : _vm.itemsType === "screen"
            ? _c(
                "div",
                [
                  _vm.sortBy === "sections" && !_vm.filteredResults
                    ? _c(
                        "div",
                        [
                          _c("Sections", {
                            attrs: { actions: _vm.actions, items: _vm.items }
                          })
                        ],
                        1
                      )
                    : _c(
                        "transition-group",
                        {
                          style: _vm.gridStyle,
                          attrs: {
                            name: "slide-card",
                            tag: "div",
                            mode: "out-in"
                          }
                        },
                        _vm._l(_vm.items, function(item) {
                          return _c("MasterScreenCard", {
                            key: item.id,
                            attrs: {
                              screen: item,
                              actions: _vm.actions,
                              flags: item.flags,
                              selected: item.isSelected
                            },
                            on: { click: _vm.onClickCard }
                          })
                        }),
                        1
                      )
                ],
                1
              )
            : _vm.itemsType === "component"
            ? _c(
                "transition-group",
                {
                  style: _vm.gridStyle,
                  attrs: { name: "slide-card", tag: "div", mode: "out-in" }
                },
                _vm._l(_vm.items, function(item) {
                  return _c("ComponentCard", {
                    key: item.id,
                    attrs: { component: item, actions: _vm.actions },
                    on: {
                      click: function($event) {
                        return _vm.onClickCard(item)
                      }
                    }
                  })
                }),
                1
              )
            : _vm.itemsType === "education-post"
            ? _c(
                "transition-group",
                {
                  style: _vm.gridStyle,
                  attrs: { name: "slide-card", tag: "div", mode: "out-in" }
                },
                _vm._l(_vm.items, function(item) {
                  return _c("EducationPostCard", {
                    key: item.id,
                    attrs: { item: item },
                    on: {
                      click: function($event) {
                        return _vm.onClickCard(item)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.shouldShowPagination
            ? _c("Pagination", {
                attrs: {
                  itemsType: _vm.readableItemType,
                  currentAmount: _vm.items.length,
                  totalAmount: _vm.totalAmount,
                  isLoadingMore: _vm.isLoadingMore
                },
                on: {
                  "load-more": function($event) {
                    return _vm.$emit("load-more")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }