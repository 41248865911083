var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UploadImage", {
    attrs: {
      url: _vm.uploadUrl,
      src: _vm.team.logo_url,
      width: _vm.size,
      height: _vm.size
    },
    on: {
      success: function($event) {
        return _vm.$emit("success")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }