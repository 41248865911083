var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "block",
      style: {
        "padding-bottom":
          _vm.currentNodeInlineStyles.length > 0 || _vm.isAddingStyle
            ? "24px"
            : null
      }
    },
    [
      _c("div", { staticClass: "block-title" }, [
        _c("span", [_vm._v("CSS properties")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "tip",
                rawName: "v-tip",
                value: "Add css properties to selected elements",
                expression: "'Add css properties to selected elements'"
              }
            ],
            staticClass: "icon-c",
            on: { click: _vm.openStyleInput }
          },
          [
            _c("svg-icon", {
              staticClass: "plus",
              attrs: { fill: "currentColor", name: "plus", size: 16 }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "stylesList" },
        _vm._l(_vm.currentNodeInlineStyles, function(pair, idx) {
          return _c("div", { key: idx, staticClass: "style-pair" }, [
            _c("div", { staticClass: "key" }, [_vm._v(_vm._s(pair.key))]),
            _c("span", { staticClass: "points" }, [_vm._v(":")]),
            _c("div", { staticClass: "value" }, [_vm._v(_vm._s(pair.value))]),
            _c("span", [_vm._v(";")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tip",
                    rawName: "v-tip",
                    value: "Remove",
                    expression: "'Remove'"
                  }
                ],
                staticClass: "minus-c",
                on: {
                  click: function($event) {
                    return _vm.handleRemoveStyle(idx, pair.key)
                  }
                }
              },
              [
                _c("svg-icon", {
                  staticClass: "minus",
                  attrs: { fill: "currentColor", name: "minus", size: 16 }
                })
              ],
              1
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAddingStyle,
              expression: "isAddingStyle"
            }
          ],
          ref: "styleInput",
          staticClass: "flex items-center styleInputs-c"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: Object.values(_vm.newStyleFocus).some(function(e) {
                    return e
                  }),
                  expression: "Object.values(newStyleFocus).some(e => e)"
                }
              ],
              staticClass: "autocomplete"
            },
            _vm._l(_vm.cssKeyAutocomplete, function(key) {
              return _c(
                "div",
                {
                  key: key,
                  staticClass: "item",
                  on: {
                    mousedown: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.inputStyleKeyValue(key)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(key) + " ")]
              )
            }),
            0
          ),
          _c("GhostInput", {
            ref: "styleKeyInput",
            staticClass: "editableInput key",
            on: {
              focus: function($event) {
                return _vm.setStyleActiveFocus("key")
              },
              blur: function($event) {
                return _vm.setStyleLoseFocus("key")
              },
              input: function($event) {
                return _vm.changeAutocomplete($event, "key")
              }
            },
            model: {
              value: _vm.newStyle.key,
              callback: function($$v) {
                _vm.$set(_vm.newStyle, "key", $$v)
              },
              expression: "newStyle.key"
            }
          }),
          _c("span", [_vm._v(":")]),
          _c("GhostInput", {
            ref: "styleValueInput",
            staticClass: "editableInput value",
            on: {
              focus: function($event) {
                return _vm.setStyleActiveFocus("value")
              },
              blur: function($event) {
                return _vm.setStyleLoseFocus("value")
              },
              input: function($event) {
                return _vm.changeAutocomplete($event, "value")
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleAddStyle.apply(null, arguments)
              }
            },
            model: {
              value: _vm.newStyle.value,
              callback: function($$v) {
                _vm.$set(_vm.newStyle, "value", $$v)
              },
              expression: "newStyle.value"
            }
          }),
          _c("span", [_vm._v(";")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }