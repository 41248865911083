var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Move project")]),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Current team")]),
      _c("div", { staticClass: "teams-list" }, [
        _c(
          "div",
          {
            class: [
              "team-row-container",
              "selectable",
              "current-team",
              { selected: _vm.isSelected(_vm.currentTeam) }
            ],
            on: {
              click: function($event) {
                _vm.selectedTeam = _vm.currentTeam
              }
            }
          },
          [
            _c("TeamRow", {
              key: _vm.currentTeam.id,
              attrs: { team: _vm.currentTeam, members: _vm.teamMembers }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "label" }, [_vm._v("Move to")]),
      _c(
        "div",
        { staticClass: "teams-list" },
        _vm._l(_vm.teams, function(t, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "team-row-container",
                "selectable",
                { selected: _vm.isSelected(t) }
              ],
              on: {
                click: function($event) {
                  _vm.selectedTeam = t
                }
              }
            },
            [
              _c("TeamRow", {
                key: t.id,
                attrs: { team: t, members: t.members }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.showActions && _vm.selectedTeam
        ? _c(
            "div",
            { staticClass: "check-box-move-team" },
            [
              _c(
                "Checkbox",
                {
                  model: {
                    value: _vm.agree,
                    callback: function($$v) {
                      _vm.agree = $$v
                    },
                    expression: "agree"
                  }
                },
                [
                  _vm._v(
                    " I understand that all my current team members will lose access to this project and it’ll be fully accessible to " +
                      _vm._s(_vm.selectedTeam.name) +
                      " team members. "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showActions && _vm.agree
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "an-button",
                {
                  attrs: { isWorking: _vm.isWorking },
                  on: { click: _vm.moveProject }
                },
                [_vm._v("Move project")]
              ),
              _c("an-link", { on: { click: _vm.cancel } }, [_vm._v("Cancel")])
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }