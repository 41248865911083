<template>
  <div ref="$rootRef" :class="['scrollOverlay', variant]">
    <div
      :style="{
        background: whiteOverlay ? 'rgba(255, 255, 255,' + opacity + ')' : 'rgba(0, 0, 0,' + opacity + ')'
      }"
      :class="['clickableOverlay', variant, { noTopPadding: whiteOverlay }]"
      @click.self="close"
    >
      <div
        ref="$modalRef"
        :style="{ '--width': `${width}px`, '--bg': background }"
        :class="['modal', variant, mode, { background }]"
      >
        <div v-if="closeButton" :class="['close-button', variant]" @click="close">
          <svg-icon name="close" :size="closeButtonSize" :fill="closeButtonColor" />
        </div>
        <component
          v-if="component"
          @updateParams="$emit('updateParams', $event)"
          @close="close"
          v-bind="componentProps"
          :is="component"
        />
        <slot v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-modal',
  props: {
    variant: {
      type: String,
      default: 'center',
      validator: v => ['top', 'center', 'aside', 'full-screen'].includes(v)
    },
    mode: {
      type: String,
      default: 'light',
      validator: v => ['light', 'dark'].includes(v)
    },
    width: {
      type: Number,
      default: 600
    },
    opacity: {
      type: Number,
      default: 0.8
    },
    background: {
      type: String,
      default: ''
    },
    whiteOverlay: {
      type: Boolean,
      default: false
    },
    component: {
      type: [Object, String],
      default: undefined
    },
    componentProps: {
      type: Object,
      default: () => ({})
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    closeOnEsc: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    closeButtonColor() {
      return this.mode === 'light' ? 'var(--secondary)' : 'var(--white)';
    },
    closeButtonSize() {
      return this.isMobile || this.variant === 'full-screen' ? 30 : 24;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown);
  },

  methods: {
    handleClickOutside(e) {
      if (this.variant === 'full-screen') return;
      if (this.$refs.$bound && !this.$refs.$bound.contains(e.target)) {
        this.close(e.target);
      }
    },
    handleKeydown(e) {
      if (e.key === 'Escape' && this.closeOnEsc) {
        this.close(e.target);
      }
    },
    close(value) {
      this.$emit('close', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollOverlay {
  // PLEASE DO NOT INCREASE THIS Z-INDEX
  // (UNLESS YOU'RE GONNA STOP USING BAREMETRICS CANCEL MODAL)
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.full-screen {
    @include mobile {
      margin-top: var(--top-bar-height);
    }
  }
}
.clickableOverlay {
  min-height: 100%;
  &.top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
  &.full-screen {
    min-height: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
  &.noTopPadding {
    padding: 10px 50px;
  }
}
.modal {
  display: inline-block;
  position: relative;
  // width: 100%;
  &.light {
    background: var(--light-background);
    color: var(--primary-text);
  }
  &.dark {
    background: var(--dark-background);
    color: var(--white);
  }
  &.background {
    background: var(--bg);
  }
  &.center,
  &.top {
    max-height: 100%;
    min-height: 192px;
    width: var(--width);
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    @include mobile {
      min-width: 335px;
    }
  }
  &.top {
    @include desktop {
      margin-top: 120px;
    }
  }
  &.aside {
    min-height: 100vh;
    max-width: var(--width);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
  &.full-screen {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  .close-button {
    z-index: 999;
    font-weight: 200;
    right: 40px;
    top: 40px;
    cursor: pointer;
    &.full-screen {
      position: absolute;
      @include desktop {
        position: fixed;
      }
    }
    &.center,
    &.top {
      position: absolute;
      right: 25px;
      top: 25px;
    }
    @include mobile {
      right: 20px;
      top: 20px;
    }
  }
  @include mobile {
    width: 100vw;
    height: 100vh;
  }
}
</style>
