var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header", style: _vm.getHeaderStyle },
    [
      _c("NetflixMenu", { on: { handleScreenChange: _vm.handleScreenChange } }),
      _c(
        "router-link",
        {
          staticClass: "logo",
          attrs: {
            tag: "div",
            to: {
              name: "team",
              params: { teamSlug: _vm.currentProject.team_slug }
            }
          }
        },
        [_c("an-logo", { attrs: { type: "mini" } })],
        1
      ),
      _c("div", { staticClass: "titles", style: _vm.getHeaderTitleStyle }, [
        _c(
          "div",
          { staticClass: "project-screen" },
          [
            _c(
              "router-link",
              {
                staticClass: "project-title",
                attrs: {
                  tag: "div",
                  to: {
                    name: "project",
                    params: {
                      teamSlug: _vm.currentProject.team_slug,
                      projectId: _vm.$route.params.projectShortId
                    }
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.currentProject.name) + " ")]
            ),
            _c("svg-icon", {
              staticClass: "t-icon",
              attrs: { size: 20, fill: "currentColor", name: "caret-right" }
            }),
            _c(
              "div",
              {
                ref: "trigger",
                staticClass: "screen-title",
                on: {
                  click: function($event) {
                    return _vm.toggleNavigator({})
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$route.params.screenSlug))]),
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { size: 20, fill: "currentColor", name: "carret-down" }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _vm._v(
            "Page " +
              _vm._s(_vm.getScreenNumber) +
              " of " +
              _vm._s(this.currentComponents.length) +
              "."
          )
        ])
      ]),
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "mode-breakpoints" },
            [
              _vm.isReleaseOmni
                ? _c(
                    "div",
                    { staticClass: "mode-selector" },
                    _vm._l(_vm.modes, function(mode, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "mode-container",
                          class: { selected: _vm.activeMode.name == mode.name },
                          on: {
                            click: function($event) {
                              return _vm.handleModeChange({
                                mode: mode
                              })
                            }
                          }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "mode-icon",
                            attrs: {
                              fill: "currentColor",
                              size: 20,
                              name: mode.icon
                            }
                          }),
                          _c("div", { staticClass: "mode-text" }, [
                            _vm._v(_vm._s(mode.displayName))
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("BreakpointsSelector", {
                on: {
                  breakpointChange: _vm.handleBreakpointChange,
                  handleScreenChange: _vm.handleScreenChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _vm.currentRelease &&
          _vm.currentRelease.missing_fonts &&
          _vm.currentRelease.missing_fonts.length > 0
            ? _c(
                "missing-fonts-widget",
                {
                  staticClass: "sync-warning",
                  attrs: { items: _vm.currentRelease.missing_fonts },
                  on: { done: _vm.handleFontUploaded }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sync-warning-btn",
                      on: {
                        click: function($event) {
                          return _vm.$trackEvent("missing-fonts.button.click")
                        }
                      }
                    },
                    [_vm._v("Missing fonts")]
                  )
                ]
              )
            : _vm._e(),
          !_vm.isReleaseOmni
            ? _c("div", { staticClass: "sync-tip" }, [
                _vm._v(" You’re previewing a draft ")
              ])
            : _vm._e(),
          _c(
            "an-button",
            {
              attrs: { isWorking: _vm.loadingSync, variant: "primary" },
              on: { click: _vm.handleSync }
            },
            [_vm._v("Sync to project")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }