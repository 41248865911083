var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c slideInLeft" }, [
    _c(
      "div",
      { staticClass: "body-wrapper", staticStyle: { "padding-top": "0" } },
      [
        _vm.noOverrides
          ? _c(
              "EmptyState",
              {
                attrs: {
                  src: require("@/assets/illustrations/computer-overrides.svg"),
                  title: "No overrides yet"
                }
              },
              [
                _vm._v(
                  " Select an element to apply your own CSS and code customizations. "
                )
              ]
            )
          : _c(
              "div",
              _vm._l(_vm.currentComponentOverrides, function(item, idx) {
                return _c("div", { key: idx }, [
                  _c(
                    "div",
                    {
                      staticClass: "override",
                      on: {
                        click: function($event) {
                          return _vm.handleOverrideClick(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "override-title truncate" }, [
                        _vm._v(" " + _vm._s(_vm.getNodeName(idx, item)) + " ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "override-text" },
                        [
                          item.br && item.br.width
                            ? _c("svg-icon", {
                                directives: [
                                  {
                                    name: "tip",
                                    rawName: "v-tip",
                                    value: {
                                      content:
                                        "Override made on " +
                                        _vm.mapWidthToBreakpointText(
                                          item.br.width
                                        ) +
                                        " " +
                                        item.br.width +
                                        "px breakpoint",
                                      placement: "bottom-center",
                                      offset: 4
                                    },
                                    expression:
                                      "{\n                content: `Override made on ${mapWidthToBreakpointText(item.br.width)} ${item.br.width}px breakpoint`,\n                placement: 'bottom-center',\n                offset: 4\n              }"
                                  }
                                ],
                                staticStyle: {
                                  "margin-right": "10px",
                                  "flex-shrink": "0"
                                },
                                attrs: {
                                  name: _vm.mapWidthToBreakpointIcon(
                                    item.br.width
                                  ),
                                  size: 18,
                                  fill: "currentColor"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "truncate",
                              staticStyle: { flex: "1" }
                            },
                            [
                              item.viewName
                                ? _c("span", { staticClass: "override-item" }, [
                                    _vm._v("name : " + _vm._s(item.viewName))
                                  ])
                                : _vm._e(),
                              item.tagName
                                ? _c("span", { staticClass: "override-item" }, [
                                    _vm._v("tag : " + _vm._s(item.tagName))
                                  ])
                                : _vm._e(),
                              _vm._l(item.css, function(value, key) {
                                return _c(
                                  "span",
                                  { key: key, staticClass: "override-item" },
                                  [
                                    _vm._v(
                                      _vm._s(key) + " : " + _vm._s(value) + ";"
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              }),
              0
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }