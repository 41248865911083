<template>
  <div class="push-notifications" :style="{ zIndex: 50000 }">
    <transition-group name="slide-in-left" tag="div">
      <div class="notification-container" v-for="(n, idx) in notifications" :key="`${n.id}-${idx}`">
        <Notification :item="n" compactView @click="n.onClick" @close="removeNotification(n)" />
        <div class="link" v-if="n.onClick">
          <an-link @click="n.onClick" variant="primary">{{ n.cta }}</an-link>
        </div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import { EventBus } from '@/services/bus';
import Notification from '@/components/Notifications/Notification';

export default {
  components: {
    Notification
  },
  data() {
    return {
      timeout: 7000,
      notifications: [],
      onClick: () => {}
    };
  },
  mounted() {
    EventBus.$on('push-notification', ({ notification, onClick = null, cta = 'See more' }) => {
      this.$trackEvent('webapp.push-notification.show', { notification });

      const item = {
        ...notification,
        onClick,
        cta
      };

      this.notifications.push(item);
      setTimeout(() => this.removeNotification(item.id), this.timeout);
    });
  },
  methods: {
    removeNotification(id) {
      const idx = this.notifications.findIndex(n => n.id === id);
      this.notifications.splice(idx, 1);
    }
  }
};
</script>
<style lang="scss">
.push-notifications {
  position: fixed;
  bottom: 20px;
  left: 0;
  margin: 0 auto;
}

.notification-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 300px;
  margin-left: 20px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: var(--shadow);
  + .notification-container {
    margin-top: 10px;
  }
  .inner-container {
    display: flex;
    align-items: flex-start;
  }
  .link {
    font-size: 14px;
    margin: 0 5px 5px;
  }
}

.slide-in-left-leave-active,
.slide-in-left-enter-active {
  transition: all 0.2s ease;
}

.slide-in-left-enter,
.slide-in-left-leave-to {
  transform: translateX(-150%);
}
</style>
