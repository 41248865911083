<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="subtitle">
      All of your screens, prototypes, assets and comments will be permanently deleted and you’ll not be able to undo
      this action.
    </div>
    <div class="actions">
      <an-button @click="_delete" :isWorking="isWorking">Delete</an-button>
      <an-link @click="cancel">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { EventBus, toastError, toastSuccess } from '@/services/bus';

export default {
  name: 'delete-project',
  data() {
    return {
      isWorking: false
    };
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ctaData() {
      return {
        label: 'Delete',
        loading: this.isWorking,
        withCancel: true
      };
    },
    title() {
      const { name } = this.project;
      return `Delete project - ${name}`;
    }
  },
  methods: {
    ...mapActions({
      deleteProject: 'projects/delete'
    }),
    async _delete() {
      const { id, name } = this.project;
      this.isWorking = true;

      this.$trackEvent('delete-project.delete-button.click');

      try {
        await this.deleteProject({ id });
        EventBus.$emit('update-projects', { skipCache: true });
        EventBus.$emit('reload-team-info');

        this.$trackEvent('delete-project.delete.success');
        this.$emit('close', { redirect: { name: 'team' } });
        toastSuccess(`${name} was successfully deleted.`);
      } catch (err) {
        this.$emit('close', { redirect: { name: 'team' } });
        toastError(`Failed deleting "${name}"`);
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('delete-project.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>
