<template>
  <div
    :style="getPanelStyle"
    v-bind:class="{ panelTopPosition: showOnlyCode }"
    ref="omniPanel"
    id="omniPanel"
    class="panels"
  >
    <ChromeExtensionPricing v-show="isChromeExtension && showPricing" />
    <!-- PANEL HEAD -->
    <div class="panel-wrapper" @click="showAndHidePricing(true)">
      <div :style="getPathSelectorStyles" v-show="showPath" class="path-selector">
        <div v-for="(p, idx) in currentNodePath" :key="idx" class="flex items-center">
          <div
            @mouseover="handlePathMouseOver(p.nodeId)"
            @mouseout="handlePathMouseOut"
            :class="{ clickable: p.nodeId, active: currentNode.id == p.nodeId }"
            @click="handlePathClicked(p, idx)"
            class="path-container"
          >
            <div class="path-label">{{ p.name }}</div>
          </div>
          <svg-icon
            v-show="idx < currentNodePath.length - 1"
            class="path-icon"
            :size="20"
            fill="currentColor"
            name="arrow-right"
          ></svg-icon>
        </div>
      </div>
      <div style="height:100%;width:100%" v-show="activeMode.name == 'C'">
        <div class="panel-head">
          <div @click="handleTabChange('cleanCode')" class="tab-toggle">
            <span> Component </span>
            <div v-show="currentActivePanel == 'cleanCode' && panelHeight > 40" class="active"></div>
          </div>

          <div v-if="!isChromeExtension" @click="handleTabChange('styleguide')" class="tab-toggle">
            <span> Styleguide </span>
            <div
              v-if="showStyleguideNotification"
              :class="{ 'animate-dot': animateStyleguideTab }"
              class="blink-dot"
            ></div>
            <div v-show="currentActivePanel == 'styleguide' && panelHeight > 40" class="active"></div>
          </div>

          <div style="flex:auto"></div>

          <div class="actions" v-bind:class="{ addChromeExtensionButtons: isChromeExtension && showOnlyCode }">
            <v-tip
              ref="CodePrefsWidget"
              openClass="open"
              class="code-pref-dropdown"
              placement="bottom-end"
              :trigger="'click'"
              offset="8"
            >
              <div class="flex items-center" style="padding:0 15px">
                <span :style="{ textTransform: 'uppercase', textAlign: 'center', width: '45px' }">{{
                  codegenLang
                }}</span>
                <svg-icon :size="20" fill="currentColor" name="small-arrow-down"></svg-icon>
              </div>

              <template slot="popover">
                <CodePrefsWidget />
              </template>
            </v-tip>

            <!-- GROUPING / MULTIPLE SELECTION -->
            <!-- <v-tip
              :style="{ display: 'inline-flex', height: '100%' }"
              :delay="{ show: 200, hide: 0 }"
              placement="top"
              :trigger="'hover'"
              offset="-8"
              v-show="!showOnlyCode"
            >
              <div @click="toggleMultipleSelection" class="compare secondary-button">
                <div :style="{ color: isMultipleSelectionEnabled ? 'var(--primary)' : '#fff' }">
                  <svg-icon fill="currentColor" name="multiple-selection" :size="24"></svg-icon>
                </div>
              </div>

              <template slot="popover">
                <div class="simple-tip">
                  <div class="simple-tip-title">
                    Group elements
                  </div>
                  <div class="simple-tip-text">
                    Generate a single component from multiple elements
                  </div>
                  <div class="simple-tip-how">
                    <span v-close-popover @click="startTour('grouping')">Show me how</span>
                  </div>
                </div>
              </template>
            </v-tip> -->
            <!-- TOGGLE COMPARE -->
            <!-- <v-tip
              :style="{ display: 'inline-flex', height: '100%' }"
              :delay="{ show: 200, hide: 0 }"
              placement="top"
              :trigger="'hover'"
              offset="-8"
              v-show="!showOnlyCode"
            >
              <div class="compare secondary-button">
                <div @click="toggleCompare">
                  <svg-icon fill="currentColor" name="compare" :size="24"></svg-icon>
                </div>
              </div>
              <template slot="popover">
                <div class="simple-tip">
                  <div class="simple-tip-title">
                    Compare to design
                  </div>
                  <div class="simple-tip-text">
                    Compare the result of Anima's code with the original design
                  </div>
                  <div class="simple-tip-how">
                    <span v-close-popover @click="startTour('compare')">Show me how</span>
                  </div>
                </div>
              </template>
            </v-tip> -->

            <!-- TOGGLE PANEL OPEN STATE -->

            <div @click="handleClosePanel({ manual: true })" class="close-button" v-show="!isChromeExtension">
              <svg-icon
                :size="20"
                fill="currentColor"
                class="icon"
                :name="isOpen ? 'carret-down' : 'carret-top'"
              ></svg-icon>
            </div>
          </div>
        </div>

        <!-- PANEL CONTENT -->
        <div class="panel-body" @click="onPanelBodyClick">
          <PanelPaywall />
          <!-- <keep-alive> -->
          <component @open-feedback="isCodeFeedbackOpen = true" :is="currentActivePanel" />
          <!-- </keep-alive> -->
        </div>
      </div>
    </div>
    <CodeFeedback ref="codeFeedback" :show="isCodeFeedbackOpen" @close="isCodeFeedbackOpen = false" />
  </div>
</template>

<script>
import interact from 'interactjs';
import dayjs from 'dayjs';
import { has } from 'lodash-es';
import { ResizeObserver } from '@juggle/resize-observer';

import { EventBus, toastSuccess } from '@/services/bus';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Popover from '@/components/Popovers/Popover.vue';
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  PANEL_DRAG,
  SEND_MESSAGE,
  SELECT_OVERRIDE_NODE,
  HIGHLIGHT_COMMENT,
  DEACTIVATE_ALL,
  ACTIVATE_MULTI_SELECTION
} from '@/utils/events/omniviewEvents';
import Code from '@/components/OmniView/Panels/Code.vue';
import Styleguide from '@/components/OmniView/Panels/Styleguide.vue';
import CodeFeedback from '@/components/OmniView/Code/CodeFeedback';
import PanelPaywall from '@/components/OmniView/Panels/PanelPaywall';
import ChromeExtensionPricing from '@/components/OmniView/Panels/ChromeExtensionPricing';
import { UserMixin } from '@/mixins';
import CodePrefsWidget from './CodePrefsWidget.vue';
import { reportCustomEvent } from 'anima-ppc-tracking';
import copyToClipboard from '@/utils/copyToClp';

export default {
  components: {
    Popover,
    Styleguide,
    cleanCode: Code,
    CodeFeedback,
    ChromeExtensionPricing,
    CodePrefsWidget,
    PanelPaywall
  },
  data() {
    return {
      isPopOpen: false,
      resizablePanel: null,
      currentActivePanel: 'cleanCode',
      functionalReactSyntax: true,
      isCodeFeedbackOpen: false,
      isDownloadingZip: false,
      codePrefLearnHowTrigger: 'hover',
      isStyledComponentsEnabled: false,
      showPricing: false,
      isCodePrefsWidgetOpen: false,
      animateStyleguideTab: false
    };
  },
  mixins: [UserMixin],
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode', isChromeExtension: 'isChromeExtension' }),
    ...mapGetters({
      modes: 'omniview/modes',
      currentNode: 'omniview/currentNode',
      currentNodePath: 'omniview/currentNodePath',
      activeMode: 'omniview/activeMode',
      codegenLang: 'omniview/codegenLang',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      currentNodeHTML: 'omniview/currentNodeHTML',
      currentNodeJSX: 'omniview/currentNodeJSX',
      currentNodeCSS: 'omniview/currentNodeCSS',
      isGeneratingCode: 'omniview/isGeneratingCode',
      isActiveExperiment: 'experiments/isActive',
      isMultipleSelectionEnabled: 'omniview/isMultipleSelectionEnabled',
      isComponentView: 'webComponents/isComponentView',
      panelHeight: 'omniview/panelHeight',
      visitedStyleguide: 'omniview/visitedStyleguide',
      styleType: 'omniview/styleType',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      isCodeDisplayed: 'omniview/isCodeDisplayed'
    }),
    showStyleguideNotification() {
      const { projectId } = this.$route.params;
      return !has(this.visitedStyleguide, projectId);
    },
    isOpen() {
      return this.panelHeight > 40;
    },
    nodeHTML() {
      let h = '';
      switch (this.codegenLang) {
        case 'react':
          h = this.currentNodeJSX;
          break;

        case 'html':
          h = this.currentNodeHTML;
          break;

        default:
          break;
      }
      return h;
    },

    selected() {
      return !!this.currentNode.id;
    },
    showPath() {
      return this.selected && !this.isMultipleSelectionEnabled && !this.isComponentView;
    },
    getPathSelectorStyles() {
      let styles = this.activeMode.name == 'Co' ? { bottom: 0, cursor: 'default' } : { top: '-20px' };
      return styles;
    },
    getPanelStyle() {
      if (this.activeMode.name == 'Co') {
        return {
          minHeight: 0,
          maxHeight: 0
        };
      }
      return {
        minHeight: this.activeMode.name == 'C' ? '40px' : 0
      };
    },

    isCodePen() {
      return this.codegenLang == 'html';
    },
    isCodeSandbox() {
      return !!['vue', 'react'].includes(this.codegenLang);
    }
  },

  methods: {
    ...mapMutations({
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setIsMultipleSelectionEnabled: 'omniview/setIsMultipleSelectionEnabled',
      setCodegenLang: 'omniview/setCodegenLang',
      setCodegenReactSyntax: 'omniview/setCodegenReactSyntax',
      setCodegenHTMLLayout: 'omniview/setCodegenHTMLLayout',
      resetSelection: 'omniview/resetSelection',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      setPanelHeight: 'omniview/setPanelHeight',
      setVisitedStyleguide: 'omniview/setVisitedStyleguide'
    }),
    ...mapActions({
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      handleModeChange: 'omniview/handleModeChange',
      startTour: 'omniview/startTour'
    }),

    handleTabChange(tab) {
      EventBus.$emit(OPEN_PANEL);
      if (this.currentActivePanel === tab) return;
      const { projectId } = this.$route.params;

      this.currentActivePanel = tab;
      this.$trackEvent('omniview.code-panel.tab-toggle', { tab });
      if (tab == 'styleguide' && this.showStyleguideNotification) {
        let v = { ...this.visitedStyleguide, [projectId]: { visited: true, v: 1 } };
        this.setVisitedStyleguide(v);
        localStorage.setItem('visitedStyleguide', JSON.stringify(v));
      }
    },
    toggleCompare() {
      this.setIsCompareEnabled(true);
      this.handleClosePanel();
      this.$trackEvent('omniview.compare-button.click');
    },
    toggleMultipleSelection() {
      if (!this.isMultipleSelectionEnabled) {
        EventBus.$emit(SEND_MESSAGE, {
          action: DEACTIVATE_ALL
        });
        this.resetSelection();
        EventBus.$emit(SEND_MESSAGE, {
          action: ACTIVATE_MULTI_SELECTION
        });
        this.setIsSidebarMinimized(true);
        this.handleClosePanel();
        this.setIsMultipleSelectionEnabled(!this.isMultipleSelectionEnabled);
      } else {
        this.handleModeChange({ mode: this.modes[2] });
        this.setIsSidebarMinimized(false);
        this.handleCloseOpen();
      }

      this.$trackEvent('omniview.multiple-selection.click');
    },

    copyCode({ text, type }) {
      if (this.shouldShowPaywall) {
        return EventBus.$emit('show-paywall', { action: 'copy-code', type });
      }

      this.$trackEvent('omni.code-mode.copy-code', {
        type,
        'style-type': this.styleType,
        framework: this.codegenLang
      });
      if (type == 'css') {
        this.animateStyleguideTab = true;
      }

      this.$gtm.trackEvent({
        event: 'copy_code',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.workplace_type,
        event_label: this.codegenLang
      });
      this.setMarketingActiveUser('developer');

      reportCustomEvent('Copied code', { type, framework: this.codegenLang });

      copyToClipboard(text);
      toastSuccess('Copied to clipboard!', {
        timeout: 2000
      });
    },

    handleClosePanel({ forceClose = false } = {}) {
      const panel = this.$refs.omniPanel;
      if (panel) {
        if (this.isOpen || forceClose) {
          panel.style.height = '40px';
        } else {
          this.handleCloseOpen();
        }
      }
    },
    handleCloseOpen({ tab } = {}) {
      if (this.isOpen) return;
      const panel = this.$refs.omniPanel;

      if (panel) {
        panel.style.height = '35%';
        this.lastPanelState = 'open';

        tab && (this.currentActivePanel = tab);
      }
    },
    onPanelBodyClick() {
      if (this.shouldShowPaywall && this.isCodeDisplayed) {
        return EventBus.$emit('show-paywall', { action: 'panel-click' });
      }
    },
    initResizablePanel() {
      let panel = this.$refs.omniPanel;
      if (!panel) {
        panel = document.getElementById('omniPanel');
      }
      this.resizablePanel = interact(panel).resizable({
        edges: {
          top: true
        },
        onstart: () => {
          EventBus.$emit(PANEL_DRAG, true);
        },
        onend: event => {
          EventBus.$emit(PANEL_DRAG, false);
          event.target.style.transition = 'height 0.2s';
        },
        onmove: event => {
          event.target.style.height = event.rect.height + 'px';
          event.target.style.transition = 'none';
        }
      });
    },
    initPanelHeightObserver() {
      let panel = this.$refs.omniPanel;
      if (!panel) {
        panel = document.getElementById('omniPanel');
      }
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          let p = this.$refs.CodePrefsWidget;
          if (p) {
            p.$_updatePopper(() => {});
          }
          this.setPanelHeight(Math.round(entry.contentRect.height));
        });
      });

      observer.observe(panel);
    },
    showAndHidePricing(bool) {
      if (this.isChromeExtension) {
        this.$trackEvent('omniview.chrome.extension.show.pricing.modal');
        // correct format: this.$trackEvent('omniview.chrome-extension-pricing.show');
        this.showPricing = bool;
      }
    },
    handlePathClicked({ nodeId, name }, idx) {
      switch (this.activeMode.name) {
        case 'Co':
          EventBus.$emit(SEND_MESSAGE, {
            action: HIGHLIGHT_COMMENT,
            data: {
              nodeId
            }
          });
          break;
        case 'C':
          EventBus.$emit(SEND_MESSAGE, {
            action: SELECT_OVERRIDE_NODE,
            data: {
              nodeId,
              metadata: {
                source: 'path'
              }
            }
          });
          break;

        default:
          break;
      }
      this.$trackEvent('omniview.dom-path.click', { tag: name, depth: idx });
    },
    handleClickOutside(e) {
      const codeFeedbackRef = this.$refs.codeFeedback;
      if (codeFeedbackRef && !codeFeedbackRef.$el.contains(e.target)) {
        codeFeedbackRef.handleClose();
      }
    },

    handlePathMouseOver(nodeId) {
      if (!nodeId) return;
      const mode = this.activeMode.displayName;

      EventBus.$emit(SEND_MESSAGE, {
        action: `simulate-${mode}-mouseover`,
        data: {
          nodeId
        }
      });
    },
    handlePathMouseOut() {
      const mode = this.activeMode.displayName;

      EventBus.$emit(SEND_MESSAGE, {
        action: `simulate-${mode}-mouseout`
      });
    },

    openFeedbackPeriodically() {
      const lastFeedbackTime = localStorage.getItem('lastFeedbackTimestamp');
      if (lastFeedbackTime) {
        const hoursSince = dayjs(new Date(Date.now()).toISOString()).diff(
          dayjs(new Date(parseInt(lastFeedbackTime)).toISOString()),
          'hour'
        );
        if (hoursSince > 72) {
          this.$trackEvent('omniview-code-feedback-show');
          this.isCodeFeedbackOpen = true;
        }
      } else {
        this.isCodeFeedbackOpen = true;
      }
    },
    handleCodeSelect({ panel }) {
      console.log(panel);
      if (panel == 'css') {
        this.animateStyleguideTab = true;
      }
    }
  },

  async mounted() {
    if (this.isChromeExtension) {
      await this.fetchAllUserMemberships({ id: 'me', skipCache: true });
    }

    this.handleClosePanel();
    this.initResizablePanel();
    this.initPanelHeightObserver();
    document.addEventListener('mousedown', this.handleClickOutside);
    EventBus.$on('open-code-feedback', this.openFeedbackPeriodically);
    EventBus.$on(CLOSE_PANEL, this.handleClosePanel);
    EventBus.$on(OPEN_PANEL, this.handleCloseOpen);
    EventBus.$on('copy-code', this.copyCode);
    EventBus.$on('code-select', this.handleCodeSelect);
    EventBus.$on('code-panel-tab-change', this.handleTabChange);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    EventBus.$off('open-code-feedback', this.openFeedbackPeriodically);
    EventBus.$off(CLOSE_PANEL, this.handleClosePanel);
    EventBus.$off(OPEN_PANEL, this.handleCloseOpen);
    EventBus.$off('copy-code', this.copyCode);
    EventBus.$off('code-select', this.handleCodeSelect);
    EventBus.$off('code-panel-tab-change', this.handleTabChange);
  }
};
</script>

<style lang="scss" scoped>
@import './panels';
</style>

<style lang="scss">
.secondary.white-button {
  border: 1px solid white !important;
  background: transparent !important;
  color: white !important;

  .three-dots div {
    background: white !important;
  }

  &:hover {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    .three-dots div {
      background: var(--primary) !important;
    }
  }
}

.blink-dot {
  background: var(--primary);
  box-shadow: 0 0 0 0 var(--primary);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 8px;
}

.animate-dot {
  animation: pulse-red 1.5s infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.path-container {
  transition: 0.1s opacity;
}
.clickable:hover {
  opacity: 1;
}
</style>
