var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rootCont",
      staticClass: "code-container",
      on: { copy: _vm.preventCopyFromChromeExtension }
    },
    [
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "codepenFormRef",
          attrs: {
            method: "post",
            action: "https://codepen.io/pen/define/",
            target: "_blank"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "data" },
            domProps: { value: _vm.getFormValue }
          }),
          _c("input", { attrs: { type: "submit" } })
        ]
      ),
      _c(
        "form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "codesandboxFormRef",
          attrs: {
            action: "https://codesandbox.io/api/v1/sandboxes/define",
            method: "POST",
            target: "_blank"
          }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "parameters" },
            domProps: { value: _vm.codesandboxParams }
          }),
          _c("input", { attrs: { type: "submit", value: "Open in sandbox" } })
        ]
      ),
      _c(
        "Splitpanes",
        { on: { resized: _vm.handlePanelResized } },
        [
          _c(
            "Pane",
            { staticClass: "flex flex-col", attrs: { "min-size": "25" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex flex-col",
                  style: {
                    position: "relative",
                    flex: 1,
                    overflow: "hidden",
                    "--html-pane-width": _vm.htmlPaneWidth + "px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "code-header flex items-center" },
                    [
                      _c("span", { style: { textTransform: "uppercase" } }, [
                        _vm._v(_vm._s(_vm.getLangDisplayName))
                      ]),
                      _c("svg-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nodeHTML,
                            expression: "nodeHTML"
                          },
                          {
                            name: "tip",
                            rawName: "v-tip",
                            value: "Copy",
                            expression: "'Copy'"
                          }
                        ],
                        staticClass: "copy-icon",
                        attrs: {
                          name: "copy-alt",
                          size: 24,
                          fill: "currentColor"
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.proUser &&
                              _vm.copy({
                                text: _vm.nodeHTML,
                                type: _vm.codegenLang
                              })
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { ref: "tagBg", staticClass: "tag-bg" }),
                  _c(
                    "div",
                    {
                      ref: "html-code",
                      staticClass: "code-scroller",
                      style: {
                        flex: 1,
                        width: "100%",
                        paddingBottom: "60px",
                        overflowY: "auto"
                      },
                      on: { scroll: _vm.handleScroll }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.selected,
                              expression: "!selected"
                            }
                          ],
                          staticClass: "empty"
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { width: "74px", height: "52px" },
                            attrs: { name: "code-empty" }
                          }),
                          _c("div", { staticStyle: { "margin-top": "30px" } }, [
                            _vm._v("Select an element to get code")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selected && _vm.isGeneratingCode,
                              expression: "selected && isGeneratingCode"
                            }
                          ],
                          staticClass: "empty"
                        },
                        [
                          _c("DefaultLoader", {
                            attrs: { noMargin: "", size: 35, theme: "dark" }
                          })
                        ],
                        1
                      ),
                      _c("Prism", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selected &&
                              _vm.nodeHTML &&
                              !_vm.isGeneratingCode,
                            expression:
                              "selected && nodeHTML && !isGeneratingCode"
                          }
                        ],
                        ref: "prismMarkup",
                        attrs: {
                          code: _vm.currentNodeCleanMarkup,
                          language: _vm.getLanguage
                        },
                        on: {
                          nodeMouseOver: _vm.handleNodeMouseOver,
                          nodeMouseLeave: _vm.handleNodeMouseLeave,
                          "update-generated-views": _vm.updateGeneratedViews,
                          onRender: _vm.updateHTMLPanelWidth
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "Pane",
            { staticClass: "flex flex-col", attrs: { "min-size": "25" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex flex-col",
                  style: { position: "relative", flex: 1, overflow: "hidden" }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentNodeCSS,
                          expression: "currentNodeCSS"
                        }
                      ],
                      staticClass: "feedback",
                      on: { click: _vm.handleSendFeedback }
                    },
                    [_c("span", [_vm._v("Feedback")])]
                  ),
                  _c(
                    "div",
                    { staticClass: "code-header flex items-center" },
                    [
                      _c("span", { style: { textTransform: "uppercase" } }, [
                        _vm._v(_vm._s(_vm.codegenStylesheetLang))
                      ]),
                      _c("svg-icon", {
                        directives: [
                          {
                            name: "tip",
                            rawName: "v-tip",
                            value: "Copy",
                            expression: "'Copy'"
                          },
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentNodeCSS,
                            expression: "currentNodeCSS"
                          }
                        ],
                        staticClass: "copy-icon",
                        attrs: {
                          name: "copy-alt",
                          size: 24,
                          fill: "currentColor"
                        },
                        nativeOn: {
                          click: function($event) {
                            _vm.proUser &&
                              _vm.copy({
                                text: _vm.currentNodeCSS,
                                type: "css",
                                lang: "css"
                              })
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      ref: "css-code",
                      staticClass: "code-scroller",
                      style: {
                        flex: 1,
                        width: "100%",
                        paddingBottom: "60px",
                        overflowY: "auto"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.selected,
                              expression: "!selected"
                            }
                          ],
                          staticClass: "empty"
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { width: "74px", height: "52px" },
                            attrs: { name: "code-empty" }
                          }),
                          _c("div", { staticStyle: { "margin-top": "30px" } }, [
                            _vm._v("Select an element to get code")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selected && _vm.isGeneratingCode,
                              expression: "selected && isGeneratingCode"
                            }
                          ],
                          staticClass: "empty"
                        },
                        [
                          _c("DefaultLoader", {
                            attrs: { noMargin: "", size: 35, theme: "dark" }
                          })
                        ],
                        1
                      ),
                      _c("Prism", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selected &&
                              _vm.currentNodeCSS &&
                              !_vm.isGeneratingCode,
                            expression:
                              "selected && currentNodeCSS && !isGeneratingCode"
                          }
                        ],
                        ref: "prismcss",
                        attrs: {
                          plugins: ["inline-color"],
                          language: _vm.codegenStylesheetLang,
                          code: _vm.currentNodeCleanCSS
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }