<template>
  <div class="pricing" v-if="!proUser">
    <div class="wrapper">
      <span @click="showAndHidePricing()" class="close">
        <svg-icon name="close" />
      </span>
      <img src="@/assets/svg/anima-logo-text.svg" class="logo" />
      <an-button class="button" @click="upgrade">Upgrade to Pro plan</an-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('omniview', { isChromeExtension: 'isChromeExtension' }),
    ...mapGetters({
      userTeams: 'teamMemberships/userTeams'
    }),
    proUser() {
      if (!this.isChromeExtension) {
        return true;
      }
      return this.userTeams &&
        this.userTeams.find(team => {
          return team.plan !== 'Free';
        })
        ? true
        : false;
    }
  },
  methods: {
    upgrade() {
      this.$trackEvent('omniview.chrome.extension.click.updage.plan');

      window.top.postMessage('open-pricing-page', '*');
      setTimeout(() => {
        let teamSlug = this.userTeams && this.userTeams[0] ? this.userTeams[0].slug : '';
        this.$router.push({ name: 'payment', params: { teamSlug }, query: { plan: 'basic', interval: 'monthly' } });
      }, 1000);
    },
    showAndHidePricing() {
      this.$parent.showAndHidePricing(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.pricing {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.wrapper {
  // width:300px;
  // height:200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 72px;
  background: #3b3b3b;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.button {
  // width: 260px;
}
.logo {
  width: 180px;
  margin-bottom: 30px;
}
.close {
  cursor: pointer;
  color: white;
  position: absolute;
  top: 25px;
  right: 25px;
}
</style>
