<template>
  <div>
    <div v-if="type === 'all'" class="empty-state-container">
      <div v-if="isUserDeveloper">
        <LoadingScreen v-if="isCreatingSampleProject || isSyncing" :type="isSyncing ? 'syncing' : 'default'" />
        <div v-else class="developer-container">
          <div class="developer-illustration"><img src="@/assets/illustrations/sample-design-box-developer.svg" /></div>
          <div class="developer-content">
            <div class="title">Ready to turn design into code?</div>
            <div class="body">
              Pick any component and get its code, HTML, React or Vue.<br />
              Or, download a full web-page.<br /><br />
              To get started, let’s bring your design into Anima.
            </div>
            <div class="actions">
              <an-button @click="newProject" v-if="isDesktop">New Project</an-button>
              <span class="or-separator" v-if="isDesktop">Or</span>
              <an-button
                variant="empty"
                @click="createSampleProjectAndReload({ sample_type: sampleType })"
                :isWorking="isCreatingSampleProject"
              >
                Try our sample project
              </an-button>
            </div>
            <div class="divider" />
            <div class="body">
              Don’t have a design? Start your first project by inviting your team designers to sync the design
            </div>
            <an-button variant="secondary" @click="inviteToTeam">Invite to team</an-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="all-projects-header">
          <div class="all-projects-illustration" />
          <div class="content">
            <div class="title">Create your first project</div>
            <div class="body">
              Sync your design to Anima and get clean, Flexbox-based responsive code components in HTML, React or Vue.
            </div>
            <div class="actions">
              <an-button @click="newProject" v-if="isDesktop">New Project</an-button>
              <span class="or-separator" v-if="isDesktop">Or</span>
              <an-button variant="empty" @click="createSampleProjectAndReload" :isWorking="isCreatingSampleProject">
                Try our sample project
              </an-button>
            </div>
          </div>
        </div>
        <div class="boxes">
          <EmptyStateBox
            v-for="(c, index) in columns"
            :key="`col_${index}`"
            :title="c.title"
            :content="c.content"
            :linkLabel="c.linkLabel"
            @click-cta="c.onClick"
          />
        </div>
      </div>
    </div>
    <div v-else-if="type === 'archive'" class="empty-state-container">
      <div class="title">No archived projects yet</div>
      <div class="body">
        Here you can store team projects that are no longer active
      </div>
    </div>
    <div v-else-if="type === 'shared'" class="empty-state-container">
      <div class="title">Hey look, nothing!</div>
      <div class="body">Seems like no one shared projects with you yet...</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EmptyStateBox from '@/components/Team/EmptyState/EmpyStateBox';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import { EventBus, openModal, toastError } from '@/services/bus';
import api from '@/api';

export default {
  data() {
    return {
      isSyncing: false,
      isCreatingSampleProject: false,
      columns: [
        {
          title: 'Designers',
          content: 'Sync your design from Sketch, Figma, or XD using our plugin to start your first project.',
          linkLabel: 'Install plugin',
          onClick: this.navigateToPlugins
        },
        {
          title: 'Developers',
          content:
            'Start your first project by inviting your team designer to sync the design & get code components in HTML, React or Vue.',
          linkLabel: 'Invite to team',
          onClick: this.inviteToTeam
        }
      ]
    };
  },
  props: {
    type: {
      type: String,
      default: 'all',
      validator: value => ['all', 'archive', 'shared'].includes(value)
    }
  },
  components: {
    EmptyStateBox,
    LoadingScreen
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({ isActiveExperiment: 'experiments/isActive', isUserDeveloper: 'users/isUserDeveloper' }),
    sampleType() {
      return this.isActiveExperiment('show-indi-film-sample-project') ? 'code-indi-film' : 'code';
    }
  },
  mounted() {
    if (this.isMobile && this.type === 'all') {
      this.$router.push({ name: 'switch-to-desktop' });
    }
  },
  methods: {
    ...mapActions({ pollSyncingProject: 'projects/pollSyncingProject' }),
    inviteToTeam() {
      this.$trackEvent('team-empty-state.invite-to-team.click');
      this.$router.push({ name: 'team-members' });
    },
    async createSampleProjectAndReload({ sample_type = 'prototype' }) {
      if (this.isCreatingSampleProject) return;
      const { data } = await this.createSampleProject(sample_type);
      EventBus.$emit('update-projects', { skipCache: true });
      return data;
    },
    async createSampleProject(sample_type = 'prototype') {
      const { isUserDeveloper } = this;
      if (this.isCreatingSampleProject) return {};
      try {
        const params = {
          team_slug: this.team.slug,
          sample_type: sample_type
        };

        this.$trackEvent('team-empty-state.create-sample-project.click', { isUserDeveloper });
        this.isCreatingSampleProject = true;
        const project = await api.post('/rpc/add_sample_project', params);

        this.$trackEvent('team-empty-state.create-sample-project.success', { isUserDeveloper });
        return project;
      } catch (err) {
        console.error(err);
        this.$trackEvent('team-empty-state.create-sample-project.failure', { message: err.message });
        toastError('Oops, something went wrong... :(');
      } finally {
        this.isCreatingSampleProject = false;
      }
    },
    async goToSampleOmniview(mode) {
      if (this.isCreatingSampleProject) return;
      try {
        const { teamSlug } = this.$route.params;
        const sample_type = mode === 'play' ? 'prototype' : 'code';
        const query = { mode, tour: sample_type };

        const { data: sampleProject } = await this.createSampleProject(sample_type);

        this.isSyncing = true;
        await this.pollSyncingProject({ id: sampleProject.id });

        const { live_project_release_homepage_slug: screenSlug, short_id: projectId } = sampleProject;
        const params = { teamSlug, projectId, screenSlug };

        EventBus.$emit('update-projects', { skipCache: true });
        this.$router.push({ name: 'omniview', params, query });
      } catch (err) {
        console.error(err);
      } finally {
        this.isSyncing = false;
      }
    },
    navigateToPlugins() {
      this.$trackEvent('team-empty-state.navigate-to-plugins.click');
      this.$router.push({ name: 'plugins' });
    },
    newProject() {
      const { isUserDeveloper } = this;
      this.$trackEvent('team-empty-state.new-project.click', { isUserDeveloper });
      openModal({ name: 'new-project' });
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}
.all-projects-header {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  @include mobile {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    font-size: 16px;
    width: 500px;
    max-width: 100%;
    > p + p {
      margin-top: 30px;
    }
    @include mobile {
      max-width: 330px;
    }
  }
  .all-projects-illustration {
    background: url('~@/assets/illustrations/sample-design-box.svg') no-repeat;
    background-size: cover;
    width: 218px;
    height: 186px;
    margin-right: 60px;
    @include mobile {
      margin: 0 0 50px;
    }
  }
}
.title {
  @include headline;
}
.boxes {
  display: flex;
  @media screen and (max-width: 1375px) {
    flex-direction: column;
  }
}
.actions {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  @include mobile {
    margin-bottom: 50px;
  }
  .or-separator {
    margin: 0 24px;
  }
}
.developer-container {
  display: flex;
  justify-content: space-between;
}
.developer-illustration {
  margin-right: 50px;
}
.developer-content {
  margin-top: 62px;
  text-align: left;
  .body {
    max-width: 430px;
    margin: 30px 0;
  }
  .actions {
    margin-bottom: 120px;
  }
  .divider {
    height: 1px;
    max-width: 400px;
    background-color: var(--tertiary);
  }
}
// .illustration {
//   margin: 0 auto;
//   height: 235px;
//   width: 245px;
//   margin: 60px 0 40px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 10px;
//   @include mobile {
//     width: 200px;
//   }
//   img {
//     width: 100%;
//   }
// }
</style>
