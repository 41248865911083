<template>
  <div class="container">
    <div class="title">Your code is ready</div>
    <div class="body">
      Your download should start automatically.
      <br />
      If it doesn’t, <an-link variant="primary" :href="downloadUrl" download target="_blank">click here</an-link>.
    </div>
    <div class="click">
      <an-link theme="dark" @click="close">Close</an-link>
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@/utils/javascript';
import { timeout } from '@/utils/sugar';

export default {
  name: 'package-ready',
  props: {
    downloadUrl: {
      type: String,
      required: true
    }
  },
  async mounted() {
    this.$trackEvent('omniview.package-download-ready-modal.show');
    await timeout(1000);
    this.download();
  },
  methods: {
    download() {
      downloadFile(this.downloadUrl);
    },
    close() {
      this.$trackEvent('omniview.package-download-ready-modal.close');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title {
  @include headline;
  font-weight: 500;
}
.body {
  margin: 20px 0 30px;
}
</style>
