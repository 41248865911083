var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "guest-row",
      on: {
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c(
        "div",
        { class: { "is-deleted": _vm.isDeleted } },
        [
          _c("an-user-avatar", { attrs: { user: _vm.avatarUser } }),
          _vm._v(_vm._s(_vm.guest.email))
        ],
        1
      ),
      _c("div", { staticClass: "cta" }, [
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.$emit("remove")
              }
            }
          },
          [
            _vm.hover
              ? _c("an-link", { attrs: { variant: "primary" } }, [
                  _vm._v("Remove")
                ])
              : _c("svg-icon", { attrs: { name: "circle-check", size: 36 } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }