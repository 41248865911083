<template>
  <div
    :style="getNavigatorStyle"
    :class="{ isOpen: isNavigatorOpen, isExpanded: isNavigatorExpanded }"
    class="navigator"
  >
    <div :style="getNavigatorWrapperStyles" ref="navigatorBound" v-show="isNavigatorOpen" class="wrapper">
      <div @click="isNavigatorExpanded = !isNavigatorExpanded" class="expand-button">
        <svg-icon fill="currentColor" :name="isNavigatorExpanded ? 'carret-top' : 'carret-down'" :size="24"></svg-icon>
      </div>
      <div @click="isNavigatorExpanded = isNavigatorOpen = false" class="close-button">
        <svg-icon fill="currentColor" name="close" :size="20"></svg-icon>
      </div>
      <div class="screens-number">
        {{ currentComponents.length }} {{ currentComponents.length > 1 ? 'Screens' : 'Screen' }}
      </div>
      <div
        :style="{
          overflowX: isNavigatorExpanded ? 'hidden' : 'auto',
          overflowY: isNavigatorExpanded ? 'auto' : 'hidden'
        }"
        class="list-wrapper"
        ref="listWrap"
      >
        <div :style="{ flexWrap: isNavigatorExpanded ? 'wrap' : 'nowrap' }" class="cards">
          <!-- currentComponent.id -->
          <div
            :class="{
              selected: isSyncPreview ? currentComponent.variableID == c.variableID : currentComponent.id == c.id
            }"
            :style="{
              margin: isNavigatorExpanded ? '0 15px 15px 0' : '0 10px 0 0',
              width: '270px'
            }"
            @click="$emit('handleScreenChange', { component: c })"
            v-for="(c, index) in currentComponents"
            :key="index"
            class="card"
          >
            <div :style="{ height: '203px' }" class="thumb-container">
              <div class="thumb-overlay"></div>
              <!-- <div :style="getScreenThumb(c)" class="thumb"></div> -->
              <div class="thumb-image-container">
                <img :src="getScreenThumb(c)" class="thumb" />
              </div>
            </div>
            <div class="screen-title">{{ isSyncPreview ? c.viewName : c.name }}</div>
            <div class="screen-breakpoint">
              {{ mapWidthToBreakpointText(c.width) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCdnUrl } from '@/utils/urls';
import { mapWidthToBreakpointText } from '@/components/OmniView/utils';
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/services/bus';

export default {
  data() {
    return {
      isNavigatorOpen: false,
      isNavigatorExpanded: false,
      isOverflowing: false
    };
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.checkForOverflow);
    EventBus.$on('toggle-netflix-memu', this.toggleNavigator);
    this.checkForOverflow();
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.checkForOverflow);
    EventBus.$off('toggle-netflix-memu', this.toggleNavigator);
  },
  watch: {
    async isNavigatorOpen(f) {
      await this.$nextTick();
      if (f) {
        this.checkForOverflow();
      }
    }
  },
  computed: {
    ...mapState('components', { currentComponents: 'items' }),
    ...mapGetters({
      currentComponent: 'components/currentComponent'
    }),

    getNavigatorStyle() {
      // const header = 100;
      // const rowHeight = 200;
      // const rowCount = Math.ceil(this.currentComponents.length / this.numberOfCardPerRow);
      // const spacing = (rowCount - 1) * (this.isMobile ? 20 : 30);
      return {
        top: this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)',
        '--expand-height': this.isOverflowing ? '760px' : '400px'
      };
    },
    getScreenNumber() {
      const index = this.currentComponents.map(c => c.id).indexOf(this.currentComponent.id);
      if (index == -1) {
        return 1;
      }
      return index + 1;
    },
    numberOfCardPerRow() {
      let n = this.isMobile ? 1 : 7;
      return n;
    },
    getNavigatorWrapperStyles() {
      return {
        padding: this.isMobile ? '20px' : '30px'
      };
    },
    isSyncPreview() {
      const { releaseShortId } = this.$route.params;
      return !!releaseShortId;
    }
  },
  methods: {
    mapWidthToBreakpointText,
    openScreenNavigator() {
      this.isNavigatorOpen = !this.isNavigatorOpen;
    },
    getScreenThumb(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      let thumb = thumbnails_urls && thumbnails_urls['640x640'];
      thumb = getCdnUrl(thumb || thumb_url || thumbnail_url, { width: 266, height: 199, pos: 'fullwidth' });
      return thumb;
    },
    toggleNavigator(e) {
      const { forceClose } = e;

      if (forceClose) {
        this.isNavigatorOpen = false;
        this.isNavigatorExpanded = false;
        return;
      }

      this.isNavigatorOpen = !this.isNavigatorOpen;
      if (!this.isNavigatorOpen) {
        this.isNavigatorExpanded = false;
      }
      this.$trackEvent('omniview.navigator.toggle', { expanded: this.isNavigatorOpen });
    },
    checkForOverflow() {
      const el = this.$refs.listWrap;
      if (!el) return;
      if (!this.isNavigatorExpanded) {
        this.isOverflowing = el.clientWidth < el.scrollWidth;
      }
    },
    handleClickOutside(e) {
      const navigatorBound = this.$refs.navigatorBound;
      const trigger = document.querySelector('#netflix-menu-trigger');
      if (!navigatorBound || !trigger) return;
      if (trigger.contains(e.target)) {
        return;
      }
      if (navigatorBound && !navigatorBound.contains(e.target)) {
        this.isNavigatorOpen = this.isNavigatorExpanded = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navigator {
  position: absolute;
  left: 0;
  width: 100%;
  background: #333333;
  max-height: 0;
  transition: max-height 0.2s ease;
  overflow: hidden;
  height: 760px;
  z-index: 104;

  .expand-button,
  .close-button {
    cursor: pointer;
    position: absolute;
    background: #333333;
    width: 30px;
    height: 30px;
    right: 15px;
    border-radius: 50%;
    color: white;
    opacity: 0.6;
    z-index: 555;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expand-button {
    // bottom: 0;
    top: 30px;
    right: 70px;
  }
  .close-button {
    top: 30px;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .screens-number {
    color: white;
    font-size: 16px;
    margin-bottom: 30px;
    user-select: none;
  }

  .list-wrapper {
    overflow: hidden;
    min-width: 100%;
    display: flex;
    padding-bottom: 10px;
    // flex-direction: column;

    // &::-webkit-scrollbar {
    //   width: 0px;
    // }

    &::-webkit-scrollbar {
      height: 10px;
      width: 0px;
      height: 0px;
      cursor: pointer;
      background-color: #303030;
    }
    &::-webkit-scrollbar-thumb {
      background: #b3b2b2;
      border-radius: 200px;
    }
  }

  .cards {
    display: flex;
    align-items: center;

    .thumb-image-container {
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: #2d2d2d;
    }

    .thumb-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      width: 100%;
      height: 100%;
      pointer-events: all;
      border-radius: inherit;
      z-index: 1;
    }
    .thumb-container {
      position: relative;
      height: 195px;
      width: 100%;
      padding: 2px;
      overflow: hidden;
      margin-bottom: 5px;
      background: transparent;
      border-radius: inherit;
      &:hover {
        .thumb-overlay {
          background: rgba(#3b3b3b, 0.5);
        }
        cursor: pointer;
      }
    }
    .thumb {
      width: 100%;
      height: auto;
    }

    .screen-title {
      font-size: 12px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      user-select: none;
    }

    .screen-breakpoint {
      text-align: center;
      font-size: 10px;
      opacity: 0.4;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
    }
  }

  .card {
    color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 270px;
    border-radius: 2px;
    margin-right: 10px;

    &:hover {
      .thumb-container {
        background: var(--primary);
      }
    }

    &.selected {
      .thumb-container {
        background: var(--primary);
      }
      .thumb-overlay {
        background: rgba(#3b3b3b, 0.5);
      }
    }
  }
}

.navigator.isOpen {
  max-height: 400px;
}
.navigator.isExpanded {
  max-height: var(--expand-height);
}
</style>
