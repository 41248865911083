<template>
  <div ref="logo">
    <svg-icon :name="name" :height="height" :width="_width" />
  </div>
</template>

<script>
export default {
  name: 'an-logo',
  props: {
    type: {
      type: String,
      default: 'full',
      validate: type => ['full', 'mini'].includes(type)
    },
    height: {
      type: Number,
      default: 25
    },
    width: {
      type: Number
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return `anima-logo${this.type === 'full' ? '-text' : ''}`;
    },
    _width() {
      if (this.width) return this.width;
      return this.type === 'full' ? 85 : 22;
    }
  },
  mounted() {
    this.colorLogo(this.light ? 'var(--secondary)' : 'var(--white)');
  },
  methods: {
    colorLogo(color) {
      const paths = this.$refs.logo.getElementsByTagName('path');
      paths?.forEach(p => {
        if (p.getAttribute('letter')) {
          p.style.fill = color;
        }
      });
    }
  }
};
</script>
