import { render, staticRenderFns } from "./Play.vue?vue&type=template&id=275e9a4b&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('275e9a4b')) {
      api.createRecord('275e9a4b', component.options)
    } else {
      api.reload('275e9a4b', component.options)
    }
    module.hot.accept("./Play.vue?vue&type=template&id=275e9a4b&", function () {
      api.rerender('275e9a4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SvgIcon/Icons/Play.vue"
export default component.exports