var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root", attrs: { "data-cy": "root-page" } }, [
    _c("div", { staticClass: "another-wrapper" }, [
      _c(
        "div",
        { staticClass: "side-bar-wrapper" },
        [
          _c("sidebar", {
            attrs: { isOpen: _vm.isSidebarOpen },
            on: { close: _vm.closeSidebar }
          })
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "top-bar-wrapper" },
          [
            _c("top-bar", {
              attrs: { showBurger: "" },
              on: { "open-sidebar": _vm.openSidebar }
            })
          ],
          1
        ),
        _c("div", { staticClass: "view-container" }, [
          _c(
            "div",
            { staticClass: "view" },
            [
              _c(
                "transition",
                { attrs: { name: _vm.transitionName, mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }