var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Toasts"),
      _c("IntercomBubble"),
      _c("Modals"),
      _c("PushNotifications"),
      _c("Banners"),
      _c("DebugEvents"),
      _vm.isDevConsoleOpen
        ? _c("DevConsole", {
            on: {
              close: function($event) {
                _vm.isDevConsoleOpen = false
              }
            }
          })
        : _vm._e(),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }