var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "divider-container",
      class: ((_obj = {}), (_obj[_vm.theme] = true), _obj)
    },
    [
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        {
          staticClass: "text",
          class: ((_obj$1 = {}), (_obj$1[_vm.theme] = true), _obj$1),
          style: { color: _vm.textColor }
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _c("div", { staticClass: "divider" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }