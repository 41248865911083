const isProd = () => {
  return process.env.NODE_ENV === 'production' || !!process.env.ORGANIZATION_NAME;
};

const isDev = () => !isProd();

module.exports = {
  isDev,
  isProd
};
