var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._v(" " + _vm._s(_vm.text) + "  "),
      _c(
        "an-link",
        {
          attrs: {
            href: "https://status.animaapp.com",
            target: "_blank",
            variant: "primary"
          }
        },
        [_vm._v("See status")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }