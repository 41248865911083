<template>
  <div class="dev-console">
    <div ref="closeButton" class="clickable-icon" @click="onClose">
      <svg-icon name="close" fill="'var(--secondary)'" />
    </div>
    <div class="dev-console-content">
      <div class="item">
        <div class="item-label">API URL</div>
        <an-input v-model="apiURL" />
      </div>
      <div class="item">
        <div class="item-label">Codegen URL</div>
        <an-input v-model="codegenURL" />
      </div>
      <an-button style="float: right;" @click="onSave">Save</an-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      apiURL: localStorage.getItem('apiBaseURL') || process.env.API_BASE_URL,
      codegenURL: localStorage.getItem('codegenBaseURL') || process.env.CODEGEN_BASE_URL
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      localStorage.setItem('apiBaseURL', this.apiURL);
      localStorage.setItem('codegenBaseURL', this.codegenURL);
      location.reload();
    }
  }
};
</script>
<style scoped>
.dev-console {
  color: var(--primary-text);
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2147483002;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #fff;
}
.clickable-icon {
  position: absolute;
  top: 18px;
  right: 18px;
  height: 24px;
  width: 24px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.item {
  width: 400px;
  display: flex;
  align-items: center;
  margin: 8px;
}
.item-label {
  width: 150px;
}
</style>
