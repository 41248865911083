<template>
  <div class="empty-state-box">
    <div class="title">{{ title }}</div>
    <div class="content">{{ content }}</div>
    <div class="cta">
      <an-button @click="$emit('click-cta')" variant="secondary">{{ linkLabel }}</an-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    linkLabel: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-state-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 40px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  max-width: 520px;
  > * + * {
    margin-top: 20px;
  }
}
.empty-state-box + .empty-state-box {
  margin-left: 40px;
  @media screen and (max-width: 1375px) {
    margin: 30px 0 0;
  }
}
.illustration {
  img {
    width: 340px;
  }
}
.content {
  text-align: left;
}
.title {
  @include secondary-title;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}
</style>
