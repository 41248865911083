var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "omniPanel",
      staticClass: "panels",
      class: { panelTopPosition: _vm.showOnlyCode },
      style: _vm.getPanelStyle,
      attrs: { id: "omniPanel" }
    },
    [
      _c("ChromeExtensionPricing", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isChromeExtension && _vm.showPricing,
            expression: "isChromeExtension && showPricing"
          }
        ]
      }),
      _c(
        "div",
        {
          staticClass: "panel-wrapper",
          on: {
            click: function($event) {
              return _vm.showAndHidePricing(true)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPath,
                  expression: "showPath"
                }
              ],
              staticClass: "path-selector",
              style: _vm.getPathSelectorStyles
            },
            _vm._l(_vm.currentNodePath, function(p, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "flex items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "path-container",
                      class: {
                        clickable: p.nodeId,
                        active: _vm.currentNode.id == p.nodeId
                      },
                      on: {
                        mouseover: function($event) {
                          return _vm.handlePathMouseOver(p.nodeId)
                        },
                        mouseout: _vm.handlePathMouseOut,
                        click: function($event) {
                          return _vm.handlePathClicked(p, idx)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "path-label" }, [
                        _vm._v(_vm._s(p.name))
                      ])
                    ]
                  ),
                  _c("svg-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: idx < _vm.currentNodePath.length - 1,
                        expression: "idx < currentNodePath.length - 1"
                      }
                    ],
                    staticClass: "path-icon",
                    attrs: {
                      size: 20,
                      fill: "currentColor",
                      name: "arrow-right"
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeMode.name == "C",
                  expression: "activeMode.name == 'C'"
                }
              ],
              staticStyle: { height: "100%", width: "100%" }
            },
            [
              _c("div", { staticClass: "panel-head" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-toggle",
                    on: {
                      click: function($event) {
                        return _vm.handleTabChange("cleanCode")
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v(" Component ")]),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.currentActivePanel == "cleanCode" &&
                            _vm.panelHeight > 40,
                          expression:
                            "currentActivePanel == 'cleanCode' && panelHeight > 40"
                        }
                      ],
                      staticClass: "active"
                    })
                  ]
                ),
                !_vm.isChromeExtension
                  ? _c(
                      "div",
                      {
                        staticClass: "tab-toggle",
                        on: {
                          click: function($event) {
                            return _vm.handleTabChange("styleguide")
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(" Styleguide ")]),
                        _vm.showStyleguideNotification
                          ? _c("div", {
                              staticClass: "blink-dot",
                              class: { "animate-dot": _vm.animateStyleguideTab }
                            })
                          : _vm._e(),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.currentActivePanel == "styleguide" &&
                                _vm.panelHeight > 40,
                              expression:
                                "currentActivePanel == 'styleguide' && panelHeight > 40"
                            }
                          ],
                          staticClass: "active"
                        })
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticStyle: { flex: "auto" } }),
                _c(
                  "div",
                  {
                    staticClass: "actions",
                    class: {
                      addChromeExtensionButtons:
                        _vm.isChromeExtension && _vm.showOnlyCode
                    }
                  },
                  [
                    _c(
                      "v-tip",
                      {
                        ref: "CodePrefsWidget",
                        staticClass: "code-pref-dropdown",
                        attrs: {
                          openClass: "open",
                          placement: "bottom-end",
                          trigger: "click",
                          offset: "8"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center",
                            staticStyle: { padding: "0 15px" }
                          },
                          [
                            _c(
                              "span",
                              {
                                style: {
                                  textTransform: "uppercase",
                                  textAlign: "center",
                                  width: "45px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.codegenLang))]
                            ),
                            _c("svg-icon", {
                              attrs: {
                                size: 20,
                                fill: "currentColor",
                                name: "small-arrow-down"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "template",
                          { slot: "popover" },
                          [_c("CodePrefsWidget")],
                          1
                        )
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isChromeExtension,
                            expression: "!isChromeExtension"
                          }
                        ],
                        staticClass: "close-button",
                        on: {
                          click: function($event) {
                            return _vm.handleClosePanel({ manual: true })
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: {
                            size: 20,
                            fill: "currentColor",
                            name: _vm.isOpen ? "carret-down" : "carret-top"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "panel-body",
                  on: { click: _vm.onPanelBodyClick }
                },
                [
                  _c("PanelPaywall"),
                  _c(_vm.currentActivePanel, {
                    tag: "component",
                    on: {
                      "open-feedback": function($event) {
                        _vm.isCodeFeedbackOpen = true
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("CodeFeedback", {
        ref: "codeFeedback",
        attrs: { show: _vm.isCodeFeedbackOpen },
        on: {
          close: function($event) {
            _vm.isCodeFeedbackOpen = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }