var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeHeight" } }, [
    _vm.show && _vm.isDesktop
      ? _c(
          "div",
          { staticClass: "banner" },
          [
            _c(
              "an-banner",
              _vm._b(
                { on: { close: _vm.onClose } },
                "an-banner",
                _vm.props,
                false
              ),
              [_c(_vm.componentName, { tag: "component" })],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }