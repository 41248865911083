var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-container" }, [
    _c(
      "div",
      {
        staticClass: "thumb",
        style: _vm.thumbStyle,
        on: {
          click: _vm.onClick,
          mouseover: function($event) {
            _vm.hoverThumb = true
          },
          mouseout: function($event) {
            _vm.hoverThumb = false
          }
        }
      },
      [
        _vm.isVideo
          ? _c(
              "div",
              { class: ["thumb-overlay", { show: _vm.hoverThumb }] },
              [
                _c("an-icon", {
                  attrs: { width: 24, height: 18, color: "white", name: "play" }
                })
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "meta" }, [
      _c("span", { staticClass: "tag" }, [_vm._v(_vm._s(_vm.item.tag))]),
      _c("span", { staticClass: "length" }, [_vm._v(_vm._s(_vm.item.length))])
    ]),
    _c("div", { staticClass: "title", on: { click: _vm.onClick } }, [
      _c(
        "div",
        { staticClass: "icon" },
        [_c("an-icon", { attrs: { name: "youtube-play", size: 24 } })],
        1
      ),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.item.title))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }