var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "an-prompt",
        {
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c("div", { staticClass: "feedback-prompt-container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("We're sad to see you go")
            ]),
            _c("div", [
              _vm._v(" In order to build the best solution we need your help."),
              _c("br"),
              _vm._v(" Please share your feedback and help us improve. ")
            ]),
            _c(
              "div",
              { staticClass: "reasons" },
              _vm._l(_vm.reasons, function(reason, idx) {
                return _c(
                  "div",
                  { key: idx },
                  [
                    _c(
                      "an-radio",
                      {
                        attrs: {
                          label: reason,
                          value: _vm.chosenReason,
                          centered: false
                        },
                        on: { change: _vm.onRadioChange }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "reason" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "reason-label",
                                on: {
                                  click: function($event) {
                                    return _vm.onRadioChange(reason)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(reason))]
                            ),
                            _vm.chosenReason === reason
                              ? _c("an-input", {
                                  attrs: {
                                    placeholder: "Tell us more please",
                                    focus: ""
                                  },
                                  model: {
                                    value: _vm.details,
                                    callback: function($$v) {
                                      _vm.details = $$v
                                    },
                                    expression: "details"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "an-button",
                  {
                    attrs: { disabled: _vm.ctaDisabled },
                    on: { click: _vm.onClickDowngrade }
                  },
                  [_vm._v("Downgrade to Free")]
                ),
                _c("an-link", { on: { click: _vm.dismiss } }, [
                  _vm._v("I don’t want to downgrade")
                ])
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }