var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "outer-thumb",
      style: Object.assign(
        {},
        {
          overflow: "hidden",
          position: "relative",
          maxHeight: _vm.maxHeight + "px",
          maxWidth: _vm.maxWidth + "px",
          width: _vm.componentOriginalSize.width + "px",
          height: _vm.componentOriginalSize.height + "px"
        },
        _vm.getStyles.container
      )
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imageLoading,
              expression: "imageLoading"
            }
          ],
          staticClass: "flex items-center justify-center w-full",
          staticStyle: { padding: "20px" }
        },
        [
          _c("svg-icon", {
            staticClass: "icon-spin",
            attrs: { fill: "currentColor", name: "spin", size: 30 }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "fadeIn" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imageLoading,
              expression: "!imageLoading"
            }
          ],
          staticClass: "inner-thumb fadeIn",
          style: _vm.getStyles.bg
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }