<template>
  <div class="top-bar">
    <div class="logo">
      <an-link :href="homepageUrl" v-if="homepageUrl">
        <an-logo :height="logoHeight" :width="logoWidth" />
      </an-link>
      <an-logo :height="logoHeight" :width="logoWidth" v-else />
    </div>
    <div class="close-button" v-if="closeButton" @click="$emit('close')">
      <svg-icon name="close-white" :size="closeButtonSize" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    homepageUrl: {
      type: String
    },
    closeButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    logoHeight() {
      return this.isDesktop ? 30 : 20;
    },
    logoWidth() {
      return this.isDesktop ? 100 : 68;
    },
    closeButtonSize() {
      return this.isDesktop ? 30 : 24;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.top-bar {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.close-button {
  cursor: pointer;
}
</style>
