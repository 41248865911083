<template>
  <div :class="containerClasses" @click="$emit('click', item)" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="notification-avatar">
      <an-user-avatar :user="item.actor" />
    </div>
    <div class="notification-content">
      <div class="header">
        <div class="name">{{ userName }}</div>
        <div class="time" v-if="hover">
          <div @click.stop="$emit('close')" v-if="compactView">
            <svg-icon name="close" :size="14" />
          </div>
          <div v-else-if="isUnread">
            <an-link @click.stop="$emit('mark-read', item)" variant="primary">Mark as read</an-link>
          </div>
          <div class="time" v-else>{{ timeAgo }}</div>
        </div>
        <div class="time" v-else>{{ timeAgo }}</div>
      </div>
      <div class="project" v-if="!compactView && hasProjectData">
        <span v-if="isComment">on </span>
        <an-link variant="primary" @click.stop="$emit('click-project', item)">{{ item.project.name }}</an-link>
      </div>
      <div class="comment" v-html="content" />
    </div>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash-es';
import { formatDateTimeConversational } from '@/utils/date';

export default {
  data() {
    return {
      hover: false,
      commentLengthLimit: 75
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    compactView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    containerClasses() {
      const { compactView } = this;
      return {
        notification: true,
        new: this.isUnread,
        compactView
      };
    },
    isComment() {
      return this.item.event_type === 'COMMENT';
    },
    isSync() {
      return this.item.event_type === 'SYNC';
    },
    timeAgo() {
      return formatDateTimeConversational(this.item.created_at);
    },
    isRead() {
      const { status } = this.item;
      return status === 'READ';
    },
    isUnread() {
      const { status } = this.item;
      return status === 'UNREAD';
    },
    userName() {
      return get(this.item, 'actor.name');
    },
    hasProjectData() {
      return !isEmpty(this.item?.project?.name);
    },
    content() {
      const { commentLengthLimit } = this;
      let content;
      if (this.isComment) {
        content = this.item.content;
        if (content && content.length > commentLengthLimit) {
          return content.slice(0, commentLengthLimit) + '...';
        }
      } else {
        content = this.item.text;
      }
      return content;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 24px 30px;
  cursor: pointer;
  transition: background 0.5s ease;
  &:hover {
    background-color: var(--light-container-background);
  }
  .notification-avatar {
    margin-right: 20px;
  }
  .notification-content {
    font-size: 14px;
    width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      .name {
        font-weight: bold;
        @include ellipsis;
      }
      .time {
        color: var(--secondary-text);
        font-size: 12px;
      }
    }
    .project {
      font-size: 12px;
      margin-bottom: 6px;
    }
  }
  + .notification {
    border-top: var(--border);
  }
  &.new:not(.compactView) {
    background-color: var(--light-container-background);
  }
  &.compactView {
    padding: 10px 15px;
    .notification-avatar {
      margin-right: 10px;
    }
    .notification-content .project {
      margin-bottom: 0;
    }
  }
}
</style>
