var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "navigation" },
      [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("SearchInput", {
              on: { "toggle-open": _vm.toggleSearchOpen },
              model: {
                value: _vm.searchQuery,
                callback: function($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery"
              }
            })
          ],
          1
        ),
        _vm.showSort
          ? _c(
              "PopoverMenu",
              {
                attrs: {
                  items: _vm.sortingOptions,
                  position: "right",
                  selectedLabel: _vm.sortByLabel
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "sort-select",
                    attrs: { slot: "reference" },
                    slot: "reference"
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.sortByLabel) + " "),
                    _c("svg-icon", {
                      attrs: { name: "select-arrow-down", size: 24 }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("ProjectsList", {
          attrs: { type: "shared", searchQuery: _vm.searchQuery }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shared-projects-header" }, [
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Shared with me")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }