<template>
  <div class="project-empty-state">
    <div v-if="type === 'component'" class="components-empty-state">
      <div><img src="@/assets/illustrations/no-components.svg" /></div>
      <div class="title component-title">No components yet</div>
      <div>
        Components allow Anima to reuse React code across the project and make the code more consistent and clean.
        <br /><br />
        In code mode, select a component &amp; add it to the components library.
      </div>
      <!-- <div class="component-cta">
        <an-link variant="primary">Show me how to add components</an-link>
      </div> -->
    </div>
    <div v-else class="sync-with-quick-import">
      <div class="frame">
        <div class="section">
          <img class="above-section" src="@/assets/illustrations/design-tools-sync.svg" />
          <h2>Sync your design</h2>
          <p>Use the Anima plugin to sync your design to your project.</p>
          <an-button @click="openPluginsModal">Install plugin</an-button>
        </div>
        <div class="divider"></div>
        <div class="section" v-if="showFigmaUploadButton">
          <img class="above-section" src="@/assets/illustrations/sketch-figma-to-anima.svg" />
          <h2>Quick import</h2>
          <p>
            Not a designer? <br />
            Upload a Sketch file for a quick start
          </p>
          <PopoverMenu :items="importOptions" position="right" popperClass="select-import-menu">
            <div slot="reference">
              <an-button variant="secondary">
                Import <svg-icon fill="currentColor" name="small-arrow-down" :size="24" />
              </an-button>
            </div>
          </PopoverMenu>
        </div>
        <div class="section" v-else>
          <img class="above-section" src="@/assets/illustrations/sketch-to-anima.svg" />
          <h2>Quick import</h2>
          <p>
            Not a designer? <br />
            Upload a Sketch file for a quick start
          </p>
          <an-button variant="secondary" @click="openImportModal('sketch')">Import</an-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import { openModal } from '@/services/bus';

export default {
  props: {
    type: {
      type: String,
      default: 'screen',
      validator: v => ['screen', 'component'].includes(v)
    }
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({ isActiveExperiment: 'experiments/isActive' }),
    showFigmaUploadButton() {
      if (window.Cypress) return true;
      return !!this.user.is_admin;
    },
    importOptions() {
      const options = [
        { label: 'Import from Sketch', value: 'sketch', onClick: () => this.openImportModal('sketch') },
        { label: 'Import from Figma', value: 'figma', onClick: () => this.openImportModal('figma') }
      ];
      return options;
    }
  },
  methods: {
    openPluginsModal() {
      // this.$router.push({ name: 'plugins' });

      openModal({
        name: 'plugins',
        mode: 'dark'
      });
    },
    openImportModal(platform) {
      if (platform === 'sketch') {
        this.$router.push({ name: 'sketch-file-sync' });
      } else if (platform === 'figma') {
        this.$router.push({ name: 'figma-file-sync' });
      }
    }
  },
  components: {
    PopoverMenu
  }
};
</script>

<style lang="scss">
.select-import-menu {
  width: 200px !important;
}
</style>

<style lang="scss" scoped>
.sync-with-quick-import {
  width: 100%;
  .frame {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    min-height: 340px;
    justify-content: space-evenly;
    .divider {
      width: 1px;
      margin: 3% 0;
      background-color: #d8d8d8;
      align-self: stretch;
    }

    .section {
      margin-top: 190px;
      p {
        width: 340px;
        margin-bottom: 40px;
      }
      position: relative;
      .above-section {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
      h2 {
        @include headline;
        font-size: 28px;
      }
      > * {
        margin-bottom: 24px;
      }
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
      .divider {
        height: 1px;
        width: unset;
        margin: 0 3%;
      }
      .section {
        margin-top: 130px;
      }
    }
  }
}

.project-empty-state {
  display: flex;
  justify-content: center;
  text-align: center;
}
.components-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
}
.component-title,
.component-cta {
  margin: 40px 0 18px;
}
.title {
  @include headline;
  margin-bottom: 30px;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  > * + * {
    margin-left: 30px;
  }
}
</style>
