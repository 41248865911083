var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "title" }, [_vm._v("Cancel subscription")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          " Are you sure you want us to have a romance without a finance? "
        )
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "div",
            { staticClass: "disclaimer" },
            _vm._l(_vm.benefits, function(row, index) {
              return _c(
                "div",
                { key: index, staticClass: "disclaimer-content" },
                [
                  row.isDivider
                    ? _c("div", { staticClass: "divider" })
                    : _c(
                        "div",
                        { staticClass: "disclaimer-row" },
                        [
                          _c("svg-icon", {
                            attrs: { name: row.icon, size: 24 }
                          }),
                          _vm._v(" " + _vm._s(row.text))
                        ],
                        1
                      )
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "checkbox" },
            [
              _c(
                "an-checkbox",
                {
                  model: {
                    value: _vm.agree,
                    callback: function($$v) {
                      _vm.agree = $$v
                    },
                    expression: "agree"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.checkboxText) + " ")]
              )
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.agree
              ? _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c(
                      "an-button",
                      {
                        attrs: {
                          id: "barecancel-trigger",
                          isWorking: _vm.isWorking
                        },
                        on: { click: _vm.onClickCancel }
                      },
                      [_vm._v(" Cancel plan ")]
                    ),
                    _c("an-link", { on: { click: _vm.cancelCancellation } }, [
                      _vm._v("Dismiss")
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("FeedbackPrompt", {
        attrs: { show: _vm.showFeedbackPrompt },
        on: {
          submit: _vm.cancelPlan,
          close: _vm.closeFeedbackPrompt,
          dismiss: _vm.dismissFeedbackPrompt
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/falling-woman.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }