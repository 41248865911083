<template>
  <div class="container">
    <div class="title" v-if="!isOnboardingFlow">Invite members to team</div>
    <div class="subtitle" v-if="!isOnboardingFlow">Add or manage your team members and roles</div>
    <div class="body">
      <MembersForm showInviteWithLink />
    </div>
  </div>
</template>

<script>
import MembersForm from '@/components/Team/Settings/MembersForm';

export default {
  name: 'team-settings-members',
  props: {
    isOnboardingFlow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MembersForm
  },
  computed: {
    theme() {
      return this.isOnboardingFlow ? 'dark' : 'light';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>
