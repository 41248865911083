<template>
  <div>
    <!-- Domains -->
    <div class="domain-settings-container">
      <div class="label">Subdomain</div>
      <div class="input">
        <an-input
          v-model="projectFields.subdomain"
          :invalid="!isValidSubDomain"
          placeholder="Your animaapp.io subdomain"
        />
        <span class="secondary-text" style="margin-left: 14px;">.animaapp.io</span>
      </div>
      <div class="error-message" v-if="subdomainError">
        {{ subdomainError }}
      </div>
      <div class="label">Custom domain</div>
      <div class="input custom-domain">
        <an-input v-model="customDomain" placeholder="www.mydomain.com" @keyup.enter="addCustomDomain" />
        <an-button @click="addCustomDomain" :isWorking="isDomainWorking" variant="secondary">Add</an-button>
      </div>
      <div class="existing-domains" v-if="customDomainsList.length">
        <div class="label">Existing domains:</div>
        <CustomDomainRow
          v-for="customDomain in customDomainsList"
          :key="customDomain.domain"
          :customDomain="customDomain"
          :isDeleted="isDeleted(customDomain.domain)"
          @remove="markCustomDomainRemoved(customDomain)"
          @un-remove="markCustomDomainNotRemoved(customDomain)"
        />
      </div>
      <div v-else class="pad" />
    </div>

    <!-- Site information -->
    <Collapse title="Site information" @click.native="$trackEvent('project-settings-meta.site-info-section.toggle')">
      <div class="pad">
        <div class="upload-icon">
          <UploadImage
            :url="uploadIamgeUrl('meta_favicon_url')"
            :src="project.meta_favicon_url"
            @success="reloadProject"
            @click.native="$trackEvent('project-settings-meta.upload-favicon.click')"
          />
          <div class="upload-icon-text">
            <div>Favicon</div>
            <div class="secondary-text small">Upload a logo 32x32 pixels, PNG or JPG formats.</div>
          </div>
        </div>
        <div class="upload-cover-image">
          <div class="label">Cover image</div>
          <UploadImage
            :url="uploadIamgeUrl('meta_image_url')"
            :src="project.meta_image_url"
            :height="140"
            :width="240"
            @success="reloadProject"
            @click.native="$trackEvent('project-settings-meta.upload-meta-image.click')"
          />
          <div class="secondary-text">
            This image shows when your site is shared on social networks like Facebook, Twitter, etc.
          </div>
        </div>
      </div>
      <div class="mwa">
        <div class="label">Mobile web app</div>
        <div class="description">Show a custom app name and icon on mobile phone homescreens</div>
        <div class="input"><an-input placeholder="Mobile web app name" v-model="projectFields.mobile_app_name" /></div>
        <div class="upload-icon">
          <UploadImage
            :url="uploadIamgeUrl('mobile_app_icon_url')"
            :src="project.mobile_app_icon_url"
            @success="reloadProject"
            @click.native="$trackEvent('project-settings-meta.upload-mobile-app-icon.click')"
          />
          <div class="upload-icon-text">
            <div>App icon</div>
            <div class="secondary-text small">Upload an icon 180x180 pixels, in PNG format.</div>
          </div>
        </div>
      </div>
    </Collapse>

    <!-- SEO -->
    <Collapse title="SEO Settings" @click.native="$trackEvent('project-settings-meta.seo-section.toggle')">
      <div class="pad">
        <div class="label">Site title</div>
        <div class="input"><an-input placeholder="Add site title" v-model="projectFields.meta_title" /></div>
        <div class="label">Site description</div>
        <div class="input">
          <an-textarea
            :placeholder="siteDesctptionPlacholder"
            v-model="projectFields.meta_description"
            :rows="3"
            withBorder
            allowMultiline
          />
        </div>
      </div>
      <div class="separated">Analytics</div>
      <div class="pad">
        <div class="label">Custom HTML tags</div>
        <div class="input">
          <an-textarea
            :placeholder="customHtmlTagsPlaceholder"
            v-model="projectFields.meta_custom_snippet"
            withBorder
            allowMultiline
          />
        </div>
        <div class="label">Analytics code</div>
        <div class="input">
          <an-textarea
            :placeholder="analyticsCodePlaceholder"
            v-model="projectFields.analytics_snippet"
            withBorder
            allowMultiline
          />
        </div>
      </div>
    </Collapse>

    <!-- Submissions -->
    <Collapse title="Form submissions" @click.native="$trackEvent('project-settings-meta.submissions-section.toggle')">
      <div class="submissions pad">
        <div class="label">
          <an-link
            :href="downloadCsvUrl"
            variant="primary"
            @click.native="$trackEvent('project-settings-meta.download-csv.click')"
            download
          >
            Download CSV
          </an-link>
        </div>
      </div>
    </Collapse>

    <div class="actions fixed" v-if="projectChanged">
      <an-button :isWorking="isWorking" @click="save">Save changes</an-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep, keys, pick } from 'lodash-es';
import { mapActions, mapState } from 'vuex';
import auth from '@/auth';
import Collapse from '@/components/Collapse/Collapse';
import CustomDomainRow from '@/components/Project/Settings/CustomDomainRow';
import UploadImage from '@/components/Upload/UploadImage';
import { toastError, toastSuccess } from '@/services/bus';
import { normalizeDomain } from '@/utils/urls';

export default {
  data() {
    return {
      siteDesctptionPlacholder: 'Add a description of your web page',
      customHtmlTagsPlaceholder: '<meta... />',
      analyticsCodePlaceholder: '<script type="text/javascript" ... />',
      customDomain: '',
      newCustomDomains: [],
      domainsToDelete: new Set(),
      deleteCount: 0,
      passwordProtection: false,
      submissionEmail: '',
      projectFields: {
        subdomain: '',
        mobile_app_name: '',
        meta_title: '',
        meta_description: '',
        meta_custom_snippet: '',
        analytics_snippet: ''
      },
      subdomainError: '',
      isDomainWorking: false,
      isWorking: false
    };
  },
  components: {
    Collapse,
    UploadImage,
    CustomDomainRow
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('domains', { domains: 'items' }),
    projectChanged() {
      const { projectFields } = this;
      return keys(projectFields).some(key => projectFields[key] !== this.project[key]);
    },
    customDomainsChanged() {
      const nonRemovedNewDomains = this.newCustomDomains.filter(d => !this.domainsToDelete.has(d.domain));
      return !!(nonRemovedNewDomains.length || this.deleteCount);
    },
    uploadIamgeUrl() {
      return field => `/v2/uploads/projects/${this.project.id}/${field}`;
    },
    downloadCsvUrl() {
      return `${process.env.API_BASE_URL}/rpc/get_form_entries?project_id=${this.project.id}&t=${auth.getToken()}`;
    },
    customDomainsList() {
      const { newCustomDomains, domains } = this;
      return cloneDeep(newCustomDomains)
        .reverse()
        .concat(domains);
    },
    isValidSubDomain() {
      const { subdomain } = this.projectFields;
      return this.validateDomain(`${subdomain}.animaapp.io`);
    },
    isDeleted() {
      return domain => !!(this.deleteCount && this.domainsToDelete.has(domain));
    }
  },
  methods: {
    ...mapActions({
      fetchCustomDomains: 'domains/fetchAllOfParent',
      createDomain: 'domains/create',
      deleteDomain: 'domains/delete',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    reset() {
      this.onProjectChange();
    },
    onProjectChange() {
      this.projectFields = pick(this.project, [
        'subdomain',
        'mobile_app_name',
        'meta_title',
        'meta_description',
        'meta_custom_snippet',
        'analytics_snippet'
      ]);
    },
    reloadProject() {
      const { short_id: id } = this.project;
      this.fetchProject({ id, skipCache: true });
    },
    async createCustomDomain({ domain }) {
      const { project } = this;
      const { id } = project;

      if (!domain || !domain.trim()) return toastError('Please provide a domain');

      const payload = { domain: domain.trim() };

      return this.createDomain({ parent: 'projects', id, payload });
    },
    createOrDeleteCustomDomains() {
      const domainsToCreate = this.newCustomDomains.filter(({ domain }) => !this.domainsToDelete.has(domain));
      const domainsToDelete = this.domains.filter(({ domain }) => this.domainsToDelete.has(domain));

      return Promise.all([...domainsToCreate.map(this.createCustomDomain), ...domainsToDelete.map(this.deleteDomain)]);
    },
    validateDomain(value) {
      const domain = normalizeDomain(value);
      return /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,27}$/.test(domain);
    },
    async addCustomDomain() {
      // changed functionality due to Or's request. TODO: check what the UI/UX should be like
      try {
        const { short_id: id } = this.project;
        const newDomain = normalizeDomain(this.customDomain);

        if (!this.validateDomain(newDomain)) {
          toastError('Invalid domain');
          return;
        }

        this.$trackEvent('project-settings-meta.add-domain-button.click');

        this.isDomainWorking = true;
        if (newDomain && !this.domains.find(({ domain = '' }) => domain?.toLowerCase() === newDomain)) {
          await this.createCustomDomain({ domain: newDomain });
          this.customDomain = '';
          this.fetchCustomDomains({ parent: 'projects', id, skipCache: true });

          toastSuccess('Domain added successfully!');
          this.$trackEvent('project-settings-meta.add-domain.success');
        } else {
          toastError('This domain seems to exist already 🤔');
        }
      } catch (err) {
        toastError('Failed creating your custom domain :(');
        this.$trackEvent('project-settings-meta.add-domain.failure');
      } finally {
        this.isDomainWorking = false;
        this.customDomain = '';
      }
      // const customDomain = this.customDomain?.trim().toLowerCase();
      // if (customDomain && !this.newCustomDomains.find(d => d.domain === customDomain)) {
      //   this.newCustomDomains.push({ domain: customDomain });
      //   this.customDomain = '';
      // }
    },
    async markCustomDomainRemoved(customDomain) {
      // changed functionality due to Or's request. TODO: check what the UI/UX should be like
      try {
        const { short_id: id } = this.project;

        this.domainsToDelete.add(customDomain.domain);
        this.deleteCount++;

        this.$trackEvent('project-settings-meta.remove-domain-button.click');

        await this.deleteDomain(customDomain);
        await this.fetchCustomDomains({ parent: 'projects', id, skipCache: true });

        toastSuccess('Domain deleted successfully!');

        this.$trackEvent('project-settings-meta.remove-domain.success');
      } catch (err) {
        toastError('Failed deleting this custom domain :(');
        this.$trackEvent('project-settings-meta.remove-domain.failure');
      } finally {
        this.markCustomDomainNotRemoved(customDomain);
      }
    },
    markCustomDomainNotRemoved({ domain }) {
      this.domainsToDelete.delete(domain);
      this.deleteCount--;
    },
    async setPasswordProtected(is_password_protected) {
      const { short_id: id } = this.project;
      const payload = { is_password_protected };

      this.isWorking = true;
      await this.updateProject({ id, payload });
      await this.reloadProject();
      this.isWorking = false;
    },
    async save() {
      const { short_id: id } = this.project;
      try {
        this.isWorking = true;
        this.subdomainError = '';

        this.$trackEvent('project-settings-meta.save-button.click');

        // update custom domains
        // changed functionality due to Or's request. TODO: check what the UI/UX should be like
        // if (this.customDomainsChanged) {
        //   await this.createOrDeleteCustomDomains();
        //   await this.fetchCustomDomains({ parent: 'projects', id, skipCache: true });
        //   this.newCustomDomains = [];
        // }

        if (!this.isValidSubDomain) {
          if (!this.projectFields.subdomain) {
            this.subdomainError = 'Subdomain is mandatory';
          } else {
            this.subdomainError = 'A subdomain should consist only of letters, numbers and dashes.';
          }
          return;
        }

        // update project fields
        if (this.projectChanged) {
          let payload = keys(this.projectFields).reduce((acc, key) => {
            if (this.projectFields[key] !== this.project[key]) {
              return { ...acc, [key]: this.projectFields[key] || null };
            }
            return acc;
          }, {});

          payload.subdomain = normalizeDomain(payload.subdomain);

          await this.updateProject({ id, payload });
          await this.reloadProject();
        }

        toastSuccess('Project updated successfully');
        this.$trackEvent('project-settings-meta.save.success');
      } catch (err) {
        this.$trackEvent('project-settings-meta.save.failure', { message: err.message });
        if (err.response?.data?.message === 'Subdomain is taken') {
          this.subdomainError = 'This subdomain is already taken 🙊';
        }
        toastError('Failed updating project');
      } finally {
        this.isWorking = false;
      }
    }
  },
  watch: {
    project() {
      this.onProjectChange();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.pad {
  padding: 30px 0;
  &.submissions {
    padding-bottom: 0;
  }
}
.upload {
  &-icon {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &-text {
      margin-left: 24px;
    }
  }
  &-cover-image {
    .secondary-text {
      margin-top: 20px;
    }
  }
}
.mwa {
  margin-top: 30px;
  .input {
    margin: 20px 0 40px;
  }
}
.error-message {
  color: var(--red);
  margin: 10px 0 30px;
}
.domain-settings-container {
  .input {
    display: flex;
    align-items: center;
  }
  .custom-domain {
    display: flex;
    align-items: center;
    .clickable-icon {
      height: 36px;
      width: 36px;
      margin-left: 26px;
      cursor: pointer;
      border-radius: 100%;
    }
    .an-button {
      margin-left: 26px;
    }
  }
  .existing-domains {
    padding-bottom: 10px;
    max-height: 308px;
    overflow-y: auto;
  }
  .pad {
    padding-bottom: 10px;
  }
}
</style>
