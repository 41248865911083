<template>
  <div ref="rootCont" class="code-container" @copy="preventCopyFromChromeExtension">
    <form v-show="false" ref="codepenFormRef" method="post" action="https://codepen.io/pen/define/" target="_blank">
      <input type="hidden" name="data" :value="getFormValue" />
      <input type="submit" />
    </form>
    <form
      v-show="false"
      ref="codesandboxFormRef"
      action="https://codesandbox.io/api/v1/sandboxes/define"
      method="POST"
      target="_blank"
    >
      <input type="hidden" name="parameters" :value="codesandboxParams" />
      <input type="submit" value="Open in sandbox" />
    </form>
    <Splitpanes @resized="handlePanelResized">
      <Pane class="flex flex-col" min-size="25">
        <div
          :style="{ position: 'relative', flex: 1, overflow: 'hidden', '--html-pane-width': htmlPaneWidth + 'px' }"
          class="flex flex-col"
        >
          <div class="code-header flex items-center">
            <span :style="{ textTransform: 'uppercase' }">{{ getLangDisplayName }}</span>
            <svg-icon
              v-show="nodeHTML"
              @click.native="proUser && copy({ text: nodeHTML, type: codegenLang })"
              v-tip="'Copy'"
              class="copy-icon"
              name="copy-alt"
              :size="24"
              fill="currentColor"
            ></svg-icon>
          </div>
          <div ref="tagBg" class="tag-bg"></div>
          <div
            ref="html-code"
            @scroll="handleScroll"
            class="code-scroller"
            :style="{ flex: 1, width: '100%', paddingBottom: '60px', overflowY: 'auto' }"
          >
            <div class="empty" v-show="!selected">
              <svg-icon style="width: 74px;height: 52px;" name="code-empty"></svg-icon>
              <div style="margin-top:30px">Select an element to get code</div>
            </div>
            <div class="empty" v-show="selected && isGeneratingCode">
              <DefaultLoader noMargin :size="35" theme="dark" />
            </div>
            <Prism
              :code="currentNodeCleanMarkup"
              ref="prismMarkup"
              v-show="selected && nodeHTML && !isGeneratingCode"
              :language="getLanguage"
              @nodeMouseOver="handleNodeMouseOver"
              @nodeMouseLeave="handleNodeMouseLeave"
              @update-generated-views="updateGeneratedViews"
              @onRender="updateHTMLPanelWidth"
            />
          </div>
        </div>
      </Pane>
      <Pane class="flex flex-col" min-size="25">
        <div :style="{ position: 'relative', flex: 1, overflow: 'hidden' }" class="flex flex-col">
          <div @click="handleSendFeedback" v-show="currentNodeCSS" class="feedback">
            <span>Feedback</span>
          </div>
          <div class="code-header flex items-center">
            <span :style="{ textTransform: 'uppercase' }">{{ codegenStylesheetLang }}</span>
            <svg-icon
              @click.native="proUser && copy({ text: currentNodeCSS, type: 'css', lang: 'css' })"
              v-tip="'Copy'"
              class="copy-icon"
              name="copy-alt"
              :size="24"
              fill="currentColor"
              v-show="currentNodeCSS"
            ></svg-icon>
          </div>
          <div
            ref="css-code"
            class="code-scroller"
            :style="{ flex: 1, width: '100%', paddingBottom: '60px', overflowY: 'auto' }"
          >
            <div class="empty" v-show="!selected">
              <svg-icon style="width: 74px;height: 52px;" name="code-empty"></svg-icon>
              <div style="margin-top:30px">Select an element to get code</div>
            </div>
            <div class="empty" v-show="selected && isGeneratingCode">
              <DefaultLoader noMargin :size="35" theme="dark" />
            </div>
            <Prism
              :plugins="['inline-color']"
              ref="prismcss"
              v-show="selected && currentNodeCSS && !isGeneratingCode"
              :language="codegenStylesheetLang"
              :code="currentNodeCleanCSS"
            />
          </div>
        </div>
      </Pane>
    </Splitpanes>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Prism from './Prism';
import { Splitpanes, Pane } from 'splitpanes';
import { EventBus } from '@/services/bus';
import DefaultLoader from '@/components/Loading/DefaultLoader';
import { UserMixin } from '@/mixins';
import { prettifyAssetFilename } from '@/utils/prettify';
import { SEND_MESSAGE, SEND_COMPONENT_MESSAGE } from '@/utils/events/omniviewEvents';

export default {
  name: 'cleanCode',
  components: {
    Prism,
    Splitpanes,
    Pane,
    DefaultLoader
  },
  data() {
    return {
      phtml: '',
      pcss: '',
      pjsx: '',
      feedbackText: '',
      tagBackgroundStyles: {
        height: 0,
        top: 0
      },
      htmlPaneWidth: 0,
      htmlPaneSize: 50,
      codesandboxParams: ''
    };
  },
  watch: {
    panelHeight() {
      this.restTagBgPosition();
    },
    playgroundCode: {
      handler(to) {
        this.phtml = to['html'];
        this.pcss = to['css'];
        this.pjsx = to['jsx'];
      },
      deep: true,
      immediate: true
    }
  },
  mixins: [UserMixin],
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode', isChromeExtension: 'isChromeExtension' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentNodeHTML: 'omniview/currentNodeHTML',
      currentNodeJSX: 'omniview/currentNodeJSX',
      currentNodeCSS: 'omniview/currentNodeCSS',
      currentNodeMd5Map: 'omniview/currentNodeMd5Map',
      codegenLang: 'omniview/codegenLang',
      isGeneratingCode: 'omniview/isGeneratingCode',
      playgroundCode: 'omniview/playgroundCode',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      userTeams: 'teamMemberships/userTeams',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      isCodeDisplayed: 'omniview/isCodeDisplayed',
      styleType: 'omniview/styleType',
      codegenStylesheetLang: 'omniview/codegenStylesheetLang',
      panelHeight: 'omniview/panelHeight'
    }),
    proUser() {
      // return true//remove this row when you want to activate this feature
      if (!this.isChromeExtension) {
        return true;
      }
      return this.userTeams &&
        this.userTeams.find(team => {
          return team.plan !== 'Free';
        })
        ? true
        : false;
    },
    overrides() {
      return this.currentComponentMetadata.overrides || {};
    },
    nodeOverrides() {
      const componentOverrides = this.currentComponentMetadata.overrides || {};
      const nodeOverrides = componentOverrides[this.currentNode.id];
      if (!nodeOverrides) return {};

      return nodeOverrides;
    },
    getFormValue() {
      let data = { title: 'Anima clean code' };
      switch (this.codegenLang) {
        case 'react':
          data = {
            ...data,
            js_pre_processor: 'babel',
            js: this.pjsx,
            css: this.pcss,
            html: this.phtml,
            js_external:
              'https://cdnjs.cloudflare.com/ajax/libs/react/16.8.0/umd/react.production.min.js;https://cdnjs.cloudflare.com/ajax/libs/react-dom/16.8.0/umd/react-dom.production.min.js'
          };
          break;

        case 'html':
          data = {
            ...data,
            css: this.pcss,
            html: this.phtml
          };
          break;

        default:
          break;
      }

      return JSON.stringify(data);
    },
    selected() {
      if (this.showOnlyCode && (this.currentNodeCleanMarkup || this.isGeneratingCode)) {
        return true;
      }
      return !!this.currentNode.id;
    },
    nodeHTML() {
      let h = '';
      switch (this.codegenLang) {
        case 'react':
          h = this.currentNodeJSX;
          break;

        case 'html':
          h = this.currentNodeHTML;
          break;

        case 'vue':
          h = this.currentNodeJSX;
          break;

        default:
          break;
      }
      return h;
    },
    getLanguage() {
      let lang = '';
      switch (this.codegenLang) {
        case 'react':
          lang = 'jsx';
          break;

        case 'html':
          lang = 'markup';
          break;

        case 'vue':
          lang = 'html';
          break;

        default:
          break;
      }
      return lang;
    },
    getLangDisplayName() {
      let lang = '';
      switch (this.codegenLang) {
        case 'react':
          lang = 'jsx';
          break;

        case 'html':
          lang = 'html';
          break;

        case 'vue':
          lang = 'vue';
          break;

        default:
          break;
      }
      return lang;
    },
    currentNodeCleanCSS() {
      if (!this.currentNodeCSS) return '';
      const resetCssImportStatement = new RegExp(/^.*\/reset\.min\.css.*$/gm);
      const variablesImportStatement = new RegExp(/@import .*\/variables.*$/gm);
      const multiLineBreak = new RegExp(/\n\n+/g);
      let cleanCss = this.currentNodeCSS
        .replace(resetCssImportStatement, '')
        .replace(variablesImportStatement, '')
        .replace(`* {\n  box-sizing: border-box;\n}\n`, '')
        .replace(`*\n  box-sizing: border-box`, '')
        .replace(multiLineBreak, '\n\n');
      cleanCss = this.replaceUrlsWithFilenames(cleanCss);
      return cleanCss;
    },
    currentNodeCleanMarkup() {
      if (!this.nodeHTML) return '';
      // const regex = /\<img.+src=[\"|\'](https?:\/\/)([^\/].+?)[\"|\']/g;
      // const regex = /([\'|\"]https?:\/\/).+[\'|\"]/g;
      let cleanMarkup = `${this.nodeHTML}`;
      cleanMarkup = this.replaceUrlsWithFilenames(cleanMarkup);

      Object.keys(this.overrides).forEach(key => {
        let ov = this.overrides[key];
        if (ov['capture_url']) {
          let pos = ov['capture_url'].lastIndexOf('/');
          let filename = ov['capture_url'].substr(pos + 1);
          cleanMarkup = cleanMarkup.replace(ov['capture_url'], filename);
        }
      });

      return cleanMarkup;
    }
  },
  mounted() {
    EventBus.$on('open-in-codepen', this.handleOpenInCodepen);
    EventBus.$on('open-in-codesandbox', this.handleOpenInCodeSandbox);

    EventBus.$on('handle-class-click', this.handleClassClick);
    EventBus.$on('handle-class-dbl-click', this.handleClassDblClick);

    this.$refs['html-code'].addEventListener('mouseup', this.handleHTMLCodeTextSelection);
    this.$refs['html-code'].addEventListener('mouseleave', this.handleNodeMouseLeave);
    this.$refs['html-code'].addEventListener('keyup', this.handleHTMLCodeTextSelection);
    this.$refs['css-code'].addEventListener('mouseup', this.handleCSSCodeTextSelection);
    this.$refs['css-code'].addEventListener('keyup', this.handleCSSCodeTextSelection);
  },
  destroyed() {
    EventBus.$off('open-in-codepen', this.handleOpenInCodepen);
    EventBus.$off('open-in-codesandbox', this.handleOpenInCodeSandbox);
    EventBus.$off('handle-class-click', this.handleClassClick);
    EventBus.$off('handle-class-dbl-click', this.handleClassDblClick);

    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('mouseup', this.handleHTMLCodeTextSelection);
    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('mouseleave', this.handleNodeMouseLeave);
    this.$refs['html-code'] && this.$refs['html-code'].removeEventListener('keyup', this.handleHTMLCodeTextSelection);
    this.$refs['css-code'] && this.$refs['css-code'].removeEventListener('mouseup', this.handleCSSCodeTextSelection);
    this.$refs['css-code'] && this.$refs['css-code'].removeEventListener('keyup', this.handleCSSCodeTextSelection);
  },
  methods: {
    updateHTMLPanelWidth() {
      const root = this.$el.getBoundingClientRect();
      this.htmlPaneWidth = (root.width * this.htmlPaneSize) / 100;
    },
    handlePanelResized(e) {
      console.log(e);
      if (e[0]) {
        this.htmlPaneSize = e[0].size || 50;
        const root = this.$el.getBoundingClientRect();
        this.htmlPaneWidth = (root.width * this.htmlPaneSize) / 100;
      }
    },
    handleNodeMouseOver({ nodeId, height, top, left, isRoot }) {
      if (isRoot) return;
      if (!nodeId) return;
      const { component } = this.$route.query;

      const tagBg = this.$refs.tagBg;
      const rootCont = this.$refs['html-code'];

      if (rootCont && tagBg && top != undefined) {
        const { top: stTop } = rootCont.getBoundingClientRect();
        const _top = top + 60 - stTop;

        Object.assign(tagBg.style, {
          height: height + 'px',
          transform: `translate3d(${left}px, ${_top}px, 0)`,
          display: 'flex'
        });
      }
      if (component) {
        EventBus.$emit(SEND_COMPONENT_MESSAGE, {
          action: 'highlight-nodes',
          ids: [nodeId],
          iframeName: 'all',
          animate: false,
          scrollViewport: true
        });
      } else {
        EventBus.$emit(SEND_MESSAGE, {
          action: 'highlight-nodes',
          ids: [nodeId],
          animate: false,
          scrollViewport: true
        });
      }
    },
    updateGeneratedViews({ viewsMap }) {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'update-generated-views',
        viewsMap
      });
    },
    restTagBgPosition() {
      let tagBg = this.$refs.tagBg;
      if (tagBg) {
        Object.assign(tagBg.style, {
          transform: 'translate3d(0,0,0)',
          display: 'none'
        });
      }
    },

    handleNodeMouseLeave() {
      // this.tagBackgroundStyles = { top: 0, height: 0 };
      this.restTagBgPosition();
      EventBus.$emit(SEND_MESSAGE, {
        action: 'clear-highlighted-nodes'
      });
    },

    handleScroll() {
      this.restTagBgPosition();
    },
    handleClassDblClick({ className, isStyleguideClass }) {
      this.$trackEvent('omniview.code-mode.double-click-classname', { className, isStyleguideClass });
    },
    async handleClassClick({ className, isStyleguideClass }) {
      this.$trackEvent('omniview.code-mode.single-click-classname', { className, isStyleguideClass });

      if (isStyleguideClass) {
        EventBus.$emit('code-panel-tab-change', 'styleguide');
        await this.$nextTick();

        this.scrollToClass({
          className,
          refId: 'styleguidePrism'
        });

        return;
      }

      const ref = this.codegenLang == 'vue' ? 'prismMarkup' : 'prismcss';
      this.scrollToClass({
        className,
        ref
      });
    },
    scrollToClass({ className, ref, refId }) {
      let refEl;
      if (!ref) {
        refEl = document.querySelector(`#${refId}`);
      } else {
        refEl = this.$refs[ref];
      }

      if (!refEl) return;
      const selectors = refEl.querySelectorAll('.token.selector');
      let found = false;
      [...selectors].map(s => {
        let text = s.textContent;
        if (text.includes(`.${className}`)) {
          if (!found) {
            found = s;
          }
        }
      });
      if (found) {
        found.scrollIntoView();
      }
    },
    handleOpenInCodepen() {
      this.setMarketingActiveUser('developer');
      this.$nextTick(() => {
        this.$refs.codepenFormRef.submit();
      });
    },
    handleOpenInCodeSandbox({ params }) {
      this.setMarketingActiveUser('developer');
      this.codesandboxParams = params;
      this.$nextTick(() => {
        this.$refs.codesandboxFormRef.submit();
      });
    },
    preventCopyFromChromeExtension(e) {
      if (!this.proUser && this.isChromeExtension) {
        e.preventDefault();

        return false;
      }
    },
    getSelectedText() {
      var text = '';
      if (typeof window.getSelection != 'undefined') {
        text = window.getSelection().toString();
      } else if (typeof document.selection != 'undefined' && document.selection.type == 'Text') {
        text = document.selection.createRange().text;
      }
      return text;
    },
    handleHTMLCodeTextSelection() {
      this.handleCodeTextSelection('left');
    },
    handleCSSCodeTextSelection() {
      this.handleCodeTextSelection('right');
    },
    handleCodeTextSelection(codePanel) {
      if (this.shouldShowPaywall && this.isCodeDisplayed) {
        return EventBus.$emit('show-paywall', { action: 'select-code' });
      }
      setTimeout(() => {
        var selectedText = this.getSelectedText();
        const panel = codePanel == 'right' ? 'css' : this.codegenLang;
        if (selectedText) {
          this.$trackEvent('omniview.code-mode.select-code', {
            type: this.codegenLang,
            'style-type': this.styleType,
            panel,
            framework: this.codegenLang
          });
          this.setMarketingActiveUser('developer');
          EventBus.$emit('code-select', { panel });
        }
      }, 100);
    },
    copy(payload) {
      EventBus.$emit('copy-code', payload);
    },
    async handleSendFeedback() {
      this.$emit('open-feedback');
    },
    replaceUrlsWithFilenames(str) {
      Object.values(this.currentNodeMd5Map).forEach(obj => {
        const filename = prettifyAssetFilename(obj.filename, this.currentComponent?.name);
        str = str.replaceAll(obj.url, filename);
      });
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.code-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}
.code-header {
  background: #333333;
  height: 60px;
  min-height: 60px;
  flex-shrink: 0;
  width: 100%;
  padding: 0 20px;
  color: white;
  font-size: 14px;
  z-index: 1;

  .copy-icon {
    opacity: 0.4;
    margin-left: auto;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.empty {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feedback {
  position: absolute;
  bottom: 60px;
  right: 20px;
  background: #3b3b3b;
  border-radius: 100px;
  width: 40px;
  z-index: 1000000;
  width: 80px;
  height: 24px;
  padding: 0 12px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.feedback-popover {
  width: 270px;
  background: #3b3b3b;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  padding: 10px 16px;
  color: white;
  outline: none;
  &:focus {
    outline: none;
  }
}
.send-feedback {
  margin-left: auto;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>

<style lang="scss">
.feedback-textarea {
  background: #333333;
  border-radius: 6px;
  padding: 8px 8px;
  border: 1px solid;
  border-color: transparent;
  height: 110px;
  color: white;
  caret-color: var(--primary);

  &:focus {
    border-color: var(--primary);
  }

  &.active {
    border-color: var(--primary);
  }

  &::placeholder {
    color: white;
    opacity: 0.4;
    font-size: 14px;
  }
}

.tag-bg {
  background: #212122;
  width: calc(100% - 3px);
  position: absolute;
  pointer-events: none;
  z-index: 0;
}
</style>
