<template>
  <div :style="{ 'padding-bottom': currentNodeInlineStyles.length > 0 || isAddingStyle ? '24px' : null }" class="block">
    <div class="block-title">
      <span>CSS properties</span>
      <div @click="openStyleInput" v-tip="'Add css properties to selected elements'" class="icon-c">
        <svg-icon class="plus" fill="currentColor" name="plus" :size="16" />
      </div>
    </div>
    <div class="stylesList">
      <div class="style-pair" v-for="(pair, idx) in currentNodeInlineStyles" :key="idx">
        <div class="key">{{ pair.key }}</div>
        <span class="points">:</span>
        <div class="value">{{ pair.value }}</div>
        <span>;</span>
        <div v-tip="'Remove'" @click="handleRemoveStyle(idx, pair.key)" class="minus-c">
          <svg-icon class="minus" fill="currentColor" name="minus" :size="16" />
        </div>
      </div>
    </div>
    <div v-show="isAddingStyle" ref="styleInput" class="flex items-center styleInputs-c">
      <div v-show="Object.values(newStyleFocus).some(e => e)" class="autocomplete">
        <div
          @mousedown.prevent.stop="inputStyleKeyValue(key)"
          class="item"
          v-for="key in cssKeyAutocomplete"
          :key="key"
        >
          {{ key }}
        </div>
      </div>

      <GhostInput
        @focus="setStyleActiveFocus('key')"
        @blur="setStyleLoseFocus('key')"
        @input="changeAutocomplete($event, 'key')"
        class="editableInput key"
        ref="styleKeyInput"
        v-model="newStyle.key"
      />
      <span>:</span>
      <GhostInput
        @focus="setStyleActiveFocus('value')"
        @blur="setStyleLoseFocus('value')"
        @input="changeAutocomplete($event, 'value')"
        class="editableInput value"
        ref="styleValueInput"
        @keyup.enter="handleAddStyle"
        v-model="newStyle.value"
      />
      <span>;</span>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import cssMap from './css.json';
import { EventBus } from '@/services/bus';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { get, omit } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import GhostInput from '@/components/Input/GhostInput.vue';

export default {
  components: {
    GhostInput
  },

  data() {
    return {
      isAddingStyle: false,
      newStyle: {
        key: '',
        value: ''
      },
      newStyleFocus: {
        key: false,
        value: false
      },
      fuseCss: new Fuse([], {}),
      autocomplete: {
        value: '',
        collection: []
      },
      showAutocomplete: false
    };
  },
  mounted() {
    document.addEventListener('mousedown', this.handleStyleOutsideClick);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleStyleOutsideClick);
  },
  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      codegenLang: 'omniview/codegenLang',
      captureType: 'omniview/captureType',
      isGeneratingCapture: 'omniview/isGeneratingCapture',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      currentComponentAssets: 'components/currentComponentAssets',
      nodeOverrides: 'omniview/nodeOverrides'
    }),
    currentNodeInlineStyles() {
      let currentNodeCss = get(this.nodeOverrides, 'css', {});
      return Object.keys(currentNodeCss).map(key => ({ key, value: currentNodeCss[key] }));
    },
    currentNodeInlineStylesObject() {
      let currentNodeCss = get(this.nodeOverrides, 'css', {});
      return currentNodeCss;
    },
    cssKeyAutocomplete() {
      const list = this.fuseCss.search(this.autocomplete.value);
      if (!list || (list && list.length == 0)) {
        return this.autocomplete.collection;
      }
      return list.map(i => i.item);
    }
  },
  methods: {
    ...mapActions({
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      getBackAllTheOverrides: 'omniview/getBackAllTheOverrides'
    }),
    handleStyleOutsideClick(e) {
      if (this.$refs.styleInput && !this.$refs.styleInput.contains(e.target)) {
        if (!this.newStyle.key || !this.newStyle.value) {
          this.closeStyleInput();
          return;
        }
        this.handleAddStyle();
      }
    },
    clearStyleKeyValue() {
      const { styleKeyInput, styleValueInput } = this.$refs;
      this.newStyle = {
        key: '',
        value: ''
      };
      styleKeyInput.$refs.editable.innerHTML = '';
      styleValueInput.$refs.editable.innerHTML = '';
    },
    inputStyleKeyValue(currentValue) {
      const { styleKeyInput, styleValueInput } = this.$refs;
      if (this.newStyleFocus.key) {
        this.newStyle = { ...this.newStyle, key: currentValue };
        styleKeyInput.$refs.editable.innerHTML = currentValue;
        this.$nextTick(() => {
          styleValueInput.triggerFocus();
          let map = cssMap[this.newStyle.key];
          this.autocomplete.value = '';
          this.autocomplete.collection = map ? map.values : [];
          this.fuseCss.setCollection(map ? map.values : []);
        });
      } else if (this.newStyleFocus.value) {
        this.newStyle = { ...this.newStyle, value: currentValue };
        styleValueInput.$refs.editable.innerHTML = currentValue;
        this.handleAddStyle();
        this.$nextTick(() => {
          styleKeyInput.triggerFocus();
          this.autocomplete.collection = [];
          this.fuseCss.setCollection([]);
          this.autocomplete.value = '';
        });
      }
    },
    setStyleActiveFocus(key) {
      this.newStyleFocus[key] = true;
      // this.showAutocomplete = false;
    },
    setStyleLoseFocus(key) {
      this.fuseCss.setCollection([]);
      this.autocomplete.collection = [];
      this.newStyleFocus[key] = false;
      // this.showAutocomplete = false;
    },
    openStyleInput() {
      const { styleKeyInput } = this.$refs;
      this.isAddingStyle = true;
      this.$nextTick(() => {
        styleKeyInput.triggerFocus();
      });
    },
    closeStyleInput() {
      this.isAddingStyle = false;
      this.clearStyleKeyValue();
    },
    handleAddStyle() {
      const currentInlineStyles = this.currentNode.inlineCSS.trim();
      const { id: nodeId } = this.currentNode;
      const newInlineStyle = `${currentInlineStyles}${this.newStyle.key}:${this.newStyle.value};`;

      this.setInlineCSSMessage(newInlineStyle);

      let fields = {
        css: {
          ...this.currentNodeInlineStylesObject,
          [this.newStyle.key]: this.newStyle.value
        }
      };

      this.updateNodeOverrides({
        nodeId,
        fields
      });

      this.$trackEvent('omniview.code-override.added-css', { key: this.newStyle.key });
      this.clearStyleKeyValue();
    },
    handleRemoveStyle(idx, cssKey) {
      const { layer: nodeId } = this.$route.query;
      const currentNodeInlineStylesCopy = [...this.currentNodeInlineStyles];
      currentNodeInlineStylesCopy.splice(idx, 1);
      let newInlineStyle = '';
      currentNodeInlineStylesCopy.forEach(({ key, value }) => {
        newInlineStyle += `${key}:${value};`;
      });

      this.setInlineCSSMessage(newInlineStyle);

      const css = omit(this.currentNodeInlineStylesObject, cssKey);

      this.updateNodeOverrides({
        nodeId,
        fields: {
          css
        }
      });
    },
    changeAutocomplete(e, c) {
      if (c == 'key') {
        this.fuseCss.setCollection(Object.keys(cssMap));
        this.autocomplete.collection = Object.keys(cssMap);
      }
      if (c == 'value') {
        let map = cssMap[this.newStyle.key];
        this.autocomplete.collection = map ? map.values : [];
        this.fuseCss.setCollection(map ? map.values : []);
      }
      this.showAutocomplete = true;
      this.autocomplete.value = e;
    },
    setInlineCSSMessage(css) {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'set-inline-css',
        inlineCSS: css
      });
      this.getBackAllTheOverrides();
    }
  }
};
</script>

<style lang="scss" scoped>
@import './block.scss';

.styleInputs-c {
  position: relative;
  width: fit-content;
  span {
    display: inline-block;
    margin: 0 2px;
    color: #89ddff;
  }
}

.styleInput.key {
  background: #2d2d2d;
  padding: 0 5px;
}

.stylesList {
  display: flex;
  flex-direction: column;
  .style-pair {
    font-size: 14px;
    display: flex;
    align-items: center;

    &:hover {
      .minus-c {
        display: flex;
      }
    }

    .minus-c {
      margin-left: auto;
      margin-right: 3px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: none;

      &:hover {
        cursor: pointer;
        background: #333333;
      }
    }

    span {
      display: inline-block;
      margin: 0 5px;
      color: #89ddff;
    }
    .points {
      margin-right: 10px;
    }
    .key {
      color: #80cbc4;
    }
    .value {
      color: #fd9170;
    }
  }
}
</style>
