var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pairing-container" }, [
    _c(
      "div",
      { staticClass: "container" },
      [_vm.loading ? _c("LoadingScreen") : _vm._e()],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }