<template>
  <div style="z-index: 1">
    <div class="tabs-container" ref="tabs">
      <div
        v-for="(item, idx) in items"
        :key="tabId(idx)"
        :ref="tabId(idx)"
        :class="classes(item)"
        :data-content="item.label"
        @click="select(item, idx)"
        :data-cy="item.datacy"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="border" :style="borderStyle" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      borderStyle: {}
    };
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeTabLabel: {
      type: String
    }
  },
  mounted() {
    this.moveBorder();
  },
  methods: {
    tabId(index) {
      return `tab-${index}`;
    },
    select(tab) {
      this.$emit('change', tab);
    },
    getTabRef(tabIndex) {
      const refName = this.tabId(tabIndex);
      return this.$refs[refName][0];
    },
    getActiveTabIndex() {
      return this.items.findIndex(item => item.label === this.activeTabLabel);
    },
    async moveBorder() {
      await this.$nextTick();
      const tabIndex = this.getActiveTabIndex();
      const currentTab = this.getTabRef(tabIndex);
      const width = currentTab.offsetWidth;
      const marginLeft = this.items
        .slice(0, tabIndex)
        .map((_, index) => this.getTabRef(index))
        .reduce((acc, curr) => acc + curr.offsetWidth, 0);
      this.borderStyle = {
        width: `${width}px`,
        marginLeft: `${marginLeft}px`
      };
    },
    classes(tab) {
      return {
        'tabs-item': true,
        selected: tab.label === this.activeTabLabel
      };
    }
  },
  watch: {
    activeTabLabel() {
      this.moveBorder();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.border {
  background: var(--primary);
  height: 3px;
  border-radius: 100px;
  transition: 0.2s ease-in-out;
}
.tabs-item {
  padding: 30px 16px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  @media screen and (max-width: 600px) {
    padding: 12px;
  }
}
.tabs-item:before {
  display: block;
  content: attr(data-content);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.selected {
  position: relative;
  font-weight: bold;
  color: var(--primary);
}
</style>
