import api from '@/api';
import { createStoreModule } from '../utils';
import { poll } from '@/utils/javascript';

export default createStoreModule({
  name: 'project',
  modelName: 'projects',
  crud: true,
  endpoint: '/v2/projects',
  state: {
    sharedProjects: [],
    recentProjects: [],
    assetsRegistry: {},
    registryUrl: ''
  },
  mutations: {
    setSharedProjects: (s, data) => (s.sharedProjects = data),
    setRecentProjects: (s, data) => (s.recentProjects = data),
    setProjectAssetsRegistry: (s, registry) => (s.assetsRegistry = registry),
    setRegistryUrl: (s, url) => (s.registryUrl = url)
  },
  actions: {
    async fetchSharedProjects(ctx, { params, skipCache = false } = {}) {
      const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
      const url = '/v2/users/me/shared_projects';
      const {
        data: { results }
      } = await api.list(url, { params, cachePolicy });
      ctx.commit('setSharedProjects', results);
      return results;
    },
    addToRecentProjects: (ctx, { project_short_id }) => api.post('/v2/users/me/recent_projects', { project_short_id }),
    async fetchRecentProjects(ctx, { params, skipCache = true } = {}) {
      const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
      const url = '/v2/users/me/recent_projects';
      const {
        data: { results }
      } = await api.list(url, { params, cachePolicy });
      ctx.commit('setRecentProjects', results);
      return results;
    },
    pollSyncingProject(
      { dispatch },
      { id, interval = 3000, maxAttempts = 200, waitBeforeRun = false, storeResult = false } = {}
    ) {
      return poll({
        fn: () => dispatch('fetchOne', { id, skipCache: true, storeResult }),
        validate: project => project && !project.is_syncing,
        interval,
        maxAttempts,
        waitBeforeRun
      });
    }
  }
});
