import { timeout } from '@/utils/sugar';
import Fuse from 'fuse.js';

export const downloadFile = url => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', true);
  link.setAttribute('target', '_blank');
  link.style.visibility = 'hidden';
  link.click();
};

export const randomChoice = arr => arr[Math.floor(Math.random() * arr.length)];

export const moveItemWithinArray = (arr, item, newIndex) => {
  const arrClone = [...arr];
  const oldIndex = arrClone.indexOf(item);
  arrClone.splice(newIndex, 0, arrClone.splice(oldIndex, 1)[0]);
  return arrClone;
};

export const insertItemIntoArray = (arr, item, index) => {
  const arrClone = [...arr];
  arrClone.splice(index, 0, item);
  return arrClone;
};
export const deleteArrayItemById = (arr, itemId, key = 'id') => {
  const arrClone = [...arr];
  const item = arrClone.find(item => item[key] === itemId);
  if (item) {
    const itemIndex = arrClone.indexOf(item);
    arrClone.splice(itemIndex, 1);
  }
  return arrClone;
};

export const updateArrayItemById = (arr, itemId, fields, key = 'id') => {
  const arrClone = [...arr];
  const item = arrClone.find(item => item[key] === itemId);
  if (item) {
    const itemIndex = arrClone.indexOf(item);
    arrClone.splice(itemIndex, 1, { ...item, ...fields });
  }
  return arrClone;
};

export const filterArrayBySearchQuery = (arr, keys = [], query = '') => {
  const options = {
    findAllMatches: true,
    keys
  };
  const fuse = new Fuse(arr, options);
  const results = fuse.search(query);
  return results.map(res => res.item);
};

export const uniqueValuesArrayByKey = (arr, key = 'id') => {
  const set = new Set();

  return arr.filter(item => {
    if (set.has(item[key])) return false;
    set.add(item[key]);
    return true;
  });
};

export const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/_+/g, '') // remove underscores - caused 404 on the backend
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const poll = async ({ fn, validate, interval, maxAttempts, waitBeforeRun }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn();
    attempts++;

    console.debug('polling...');

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  if (waitBeforeRun) {
    await timeout(interval);
  }

  return new Promise(executePoll);
};

export const isDuplicateRouteError = err => {
  return err.message.includes('Avoided redundant navigation to current location');
};

export const isSameRoute = (a, b) => {
  if (!a.name || !b.name) return false;
  let aKey = `${a.name}${JSON.stringify(a.params)}${JSON.stringify(a.query)}`;
  let bKey = `${b.name}${JSON.stringify(b.params)}${JSON.stringify(b.query)}`;
  return aKey === bKey;
};

export class simpleQueue {
  constructor() {
    this.jobs = [];
  }

  enqueue(job) {
    this.jobs.push(job);
  }
  dequeue() {
    if (this.isEmpty()) false;
    return this.jobs.shift();
  }
  isEmpty() {
    return this.jobs.length == 0;
  }
}

export const filterObject = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export class AsyncQueue {
  constructor() {
    this.queue = [];
    this.workingOnPromise = false;
  }

  enqueue(promise) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject
      });
      this.dequeue();
    });
  }

  dequeue() {
    if (this.workingOnPromise) {
      return false;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item
        .promise()
        .then(value => {
          this.workingOnPromise = false;
          item.resolve(value);
          this.dequeue();
        })
        .catch(err => {
          this.workingOnPromise = false;
          item.reject(err);
          this.dequeue();
        });
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      this.dequeue();
    }
    return true;
  }
}
