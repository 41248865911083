import { isEmpty, upperFirst } from 'lodash-es';
import { createStoreModule } from '../utils';
import { isSufficientAccessLevel, isSufficientPlan, isSufficientRole } from '@/services/permissions';
import api from '@/api';

const fetchAllOfParent = parent => async (ctx, { id, params = {}, saveResults = true, skipCache = false }) => {
  try {
    const cachePolicy = skipCache ? 'no-cache' : 'cache-first';
    const url = `/v2/${parent}s/${id}/team_memberships/`;
    const { data } = await api.list(url, {
      params: { page_size: 500, order_by: 'created_at', ...params },
      cachePolicy
    });
    const upperParent = upperFirst(parent);
    if (saveResults) {
      ctx.commit(`set${upperParent}Memberships`, data);
    }
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const mutationForParent = parent => (state, { results = [] } = {}) => {
  state[parent] = results;
};

const getForParent = parent => state =>
  state[parent].map(tm => ({
    id: tm.team,
    name: tm.team_name,
    slug: tm.team_slug,
    plan: tm.team_plan,
    logo_url: tm.team_logo,
    members: tm.members,
    membersLength: tm.members?.length,
    totalMembers: tm.totalMembers,
    active_projects_count: tm.active_projects_count || 0,
    role: tm.role
  }));

export default createStoreModule({
  name: 'teamMembership',
  modelName: 'team_memberships',
  crud: true,
  endpoint: '/v2/team_memberships',
  state: {
    user: [],
    team: []
  },
  mutations: {
    setUserMemberships: mutationForParent('user'),
    setTeamMemberships: mutationForParent('team'),
    setCurrentTeamMembership: (state, { email } = {}) => {
      const { team: teamMemberships } = state;
      const myMembership = teamMemberships.find(tm => tm.email === email);
      state.currentItem = myMembership || {};
    },
    updateTeamMemberships(state, { teamId, payload }) {
      const { user, team } = state;
      const updateMembershipsOfId = membership => {
        if (membership.team !== teamId) return membership;
        return { ...membership, ...payload };
      };
      state.user = user.map(updateMembershipsOfId);
      state.team = team.map(updateMembershipsOfId);
    }
  },
  actions: {
    fetchAllUserMemberships: fetchAllOfParent('user'),
    fetchAllTeamMemberships: fetchAllOfParent('team'),
    resendInvitation: (_, { id }) => api.post(`/memberships/${id}/resend`)
  },
  getters: {
    userTeams: getForParent('user'),
    hasPermissions: (state, getters, rootState) => {
      const { user: userMemberships = [] } = state;
      const { plan: userPlan } = rootState.users.currentItem ?? {};
      return ({ teamSlug, role: requiredRole, plan: requiredPlan, accessLevel: requiredAccessLevel } = {}) => {
        const membership = userMemberships?.find(tm => tm.team_slug === teamSlug) || state.currentItem;

        // this will return true if:
        // user is a team member of that team,
        // user has sufficient role (if `role` is not provided, returns true)
        // user has sufficient plan (if `plan` is not provided, returns true)
        // user has sufficient access level (if `accessLevel` is not provided, returns true)
        return !!(
          !isEmpty(membership) &&
          isSufficientRole(requiredRole, membership.role) &&
          (isSufficientPlan(requiredPlan, membership.team_plan) || isSufficientPlan(requiredPlan, userPlan)) &&
          isSufficientAccessLevel(requiredAccessLevel, membership.access_level)
        );
      };
    },
    contributorsCountInTeam(state) {
      const { team: teamMembers } = state;
      return teamMembers?.filter(tm => tm.access_level === 'contributor').length || 0;
    }
  }
});
