import Vue from 'vue';
export const EventBus = new Vue();

const sugarEvent = eventName => (props = {}) => EventBus.$emit(eventName, props);

export const openModal = sugarEvent('open-modal');
export const closeModal = sugarEvent('close-modal');
export const showBanner = sugarEvent('show-banner');
export const hideBanner = sugarEvent('hide-banner');
export const pushNotification = sugarEvent('push-notification');
export const toast = sugarEvent('toast');
export const showIntercomBubble = sugarEvent('show-intercom-bubble');
export const hideIntercomBubble = sugarEvent('hide-intercom-bubble');
export const addDebugEvent = sugarEvent('add-debug-event');
export const toastSuccess = (content, options = {}) => toast({ content, variant: 'success', ...options });
export const toastError = content => toast({ content, variant: 'fail' });
export const toastWarning = content => toast({ content, variant: 'warn' });
