<template>
  <div class="top-bar-container">
    <div class="left-hand">
      <div v-if="isMobile && showBurger" class="burger" @click="$emit('open-sidebar')">
        <svg-icon name="mobile-burger" :size="30" />
      </div>
    </div>
    <div class="center">
      <div v-if="isMobile" class="logo">
        <an-logo />
      </div>
    </div>
    <div class="right-hand">
      <div class="top-bar-item" v-if="isDesktop">
        <an-link @click="upgrade" variant="primary" class="thick-af" v-if="showUpgrade">
          Upgrade
        </an-link>
      </div>
      <div class="top-bar-item" />
      <div class="top-bar-item">
        <AnnouncementsPopover :iconColor="iconColor" />
      </div>
      <div class="top-bar-item" v-show="isDesktop">
        <NotificationsPopover :iconColor="iconColor" />
      </div>
      <div class="top-bar-item" v-show="isDesktop">
        <AccountPopover />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AccountPopover from '@/components/Account/AccountPopover';
import AnnouncementsPopover from '@/components/Announcements/AnnouncementsPopover';
import NotificationsPopover from '@/components/Notifications/NotificationsPopover';

export default {
  props: {
    showBurger: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AccountPopover,
    AnnouncementsPopover,
    NotificationsPopover
  },
  computed: {
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    showUpgrade() {
      if (this.$route.name == 'shared' || !this.teamMembership.id || !!process.env.ORGANIZATION_NAME) {
        return false;
      }
      return !this.teamMembership.team_plan || !['Pro', 'Team'].includes(this.teamMembership.team_plan);
    },
    iconColor() {
      return `var(--${this.isMobile ? 'white' : 'secondary'})`;
    }
  },
  methods: {
    upgrade() {
      this.$trackEvent('top-nav-bar.upgrade.click');
      this.$router.push({ name: 'team-pricing' });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: var(--white);
  height: var(--top-bar-height);
  width: 100%;
  padding: 0 40px;
  @include mobile {
    color: var(--white);
    background: var(--secondary);
    padding: 0 16px;
    width: 100%;
  }
}
.right-hand,
.left-hand {
  display: flex;
  align-items: center;
  position: relative;
}
.right-hand {
  justify-content: flex-end;
  @include mobile {
    width: 30px;
  }
}
.burger {
  position: absolute;
  left: 0;
}
.center {
  justify-content: center;
}
.left-hand {
  justify-content: flex-start;
}
.top-bar-item {
  margin-left: 16px;
  display: flex;
  align-items: center;
  .thick-af {
    font-weight: 600;
  }
}
.logo {
  margin-left: 20px;
}
</style>
