<template>
  <div class="login">
    <div class="details">
      <form>
        <div class="property">
          <div class="label">Email address</div>
          <div class="input">
            <an-input v-model="email" />
          </div>
        </div>
        <div class="property">
          <div class="label">Password</div>
          <div class="password-input">
            <an-input v-model="password" placeholder="********" type="password" autocomplete />
            <an-button :isWorking="loading" @click="updateDetails" variant="secondary">Update</an-button>
          </div>
        </div>
      </form>
    </div>
    <div class="separated" v-if="user.is_admin">
      <an-checkbox v-model="showDebugEvents">
        [Admins] Show events
      </an-checkbox>
    </div>
    <div class="separated">
      <an-link @click="openDeleteAccountModal" primaryHover>Delete account</an-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isValidEmail } from '@/utils/email';
import { EventBus, openModal, toastError, toastSuccess } from '@/services/bus';
import api from '@/api';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      showDebugEvents: false
    };
  },
  mounted() {
    this.reset();
  },
  computed: mapState('users', { user: 'currentItem' }),
  methods: {
    reset() {
      this.email = this.user.email;
      if (this.user.is_admin) {
        this.showDebugEvents = !!localStorage.getItem('debug-events');
      }
    },
    async updateDetails() {
      const { password, email } = this;
      if (!isValidEmail(email)) {
        toastError('Please insert a valid email');
        return;
      }
      if (password && password.length < 8) {
        toastError('Your new password is too short!');
        return;
      }
      this.loading = true;
      try {
        if (email !== this.user.email) {
          await api.post('/users/me/send_email_verification', { email });
          toastSuccess('To verify your new email, please check you inbox');
        }
        if (password) {
          await api.post('/rpc/set_password', { new_password: password });
          toastSuccess('New password saved');
        }
      } catch (err) {
        toastError('Failed saving changes :(');
      } finally {
        this.loading = false;
      }
    },
    openDeleteAccountModal() {
      openModal({ name: 'account-delete', onCloseRedirect: { name: 'account' } });
    }
  },
  watch: {
    user: {
      handler: 'reset',
      immediate: true
    },
    showDebugEvents(value) {
      localStorage.setItem('debug-events', value ? '1' : '');
      EventBus.$emit('toggle-debug-events', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.details,
form {
  width: 100%;
}
.separated {
  width: 100%;
}
</style>
