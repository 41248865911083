import { showBanner } from '@/services/bus';

export const showAppStatusBanner = () =>
  showBanner({
    name: 'app-status-banner',
    variant: 'fail'
  });

export const showUpgradeTeamBanner = () =>
  showBanner({
    name: 'upgrade-team-plan-banner',
    variant: 'fail'
  });
