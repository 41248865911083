var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Permissions")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          "Manage how collaborators can interact with your projects at team & project level."
        )
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("div", { staticClass: "label" }, [_vm._v("Project settings")]),
          _c(
            "an-toggle",
            {
              model: {
                value: _vm.hotspotsEnabled,
                callback: function($$v) {
                  _vm.hotspotsEnabled = $$v
                },
                expression: "hotspotsEnabled"
              }
            },
            [_vm._v(" Show hotspot hints by default ")]
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "an-button",
              { attrs: { isWorking: _vm.isWorking }, on: { click: _vm.save } },
              [_vm._v("Save changes")]
            ),
            _c(
              "an-link",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }