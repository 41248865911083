<template>
  <div class="signup-page-container">
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="signup-container">
      <LoadingScreen v-if="googleLoading" />
      <div class="form-container" v-else>
        <div class="title">Create Account</div>
        <div class="subtitle">
          Already have an account?
          <an-link @click="$router.push({ name: 'login', query: $route.query })" variant="primary">Log in</an-link>
        </div>
        <div class="social-signup">
          <GoogleButton @success="onGoogleSignupSuccess" @failure="onGoogleSignupFailure">
            Sign up with Google
          </GoogleButton>
        </div>
        <div class="text-divider">
          <Divider text="Or" :theme="theme" />
        </div>
        <form @submit.prevent="signup">
          <div class="property" v-if="nameRequired">
            <div class="name-property">
              <div class="input">
                <an-input
                  v-model="firstName"
                  :theme="theme"
                  :invalid="!validFirstName"
                  placeholder="First name"
                  data-cy="signup-first-name"
                />
              </div>
              <div class="input">
                <an-input
                  v-model="lastName"
                  :theme="theme"
                  :invalid="!validLastName"
                  placeholder="Last name"
                  data-cy="signup-last-name"
                />
              </div>
            </div>
            <div class="invalid-message" v-if="!validFirstName || !validLastName" data-cy="signup-name-error">
              Umm.. we hope you’re not a ghost
            </div>
          </div>
          <div class="property">
            <div class="input">
              <an-input
                id="email"
                type="email"
                v-model="email"
                placeholder="name@work-email.com"
                :theme="theme"
                :invalid="!validEmail"
                data-cy="signup-email"
              />
              <div class="invalid-message" v-if="errorMessage" data-cy="signup-email-error">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div class="property">
            <div class="input">
              <an-input
                id="pass"
                type="password"
                v-model="password"
                placeholder="Password"
                :theme="theme"
                :invalid="!validPassword"
                data-cy="signup-password"
              />
              <div class="invalid-message" v-if="!validPassword" data-cy="signup-password-error">
                Password must contain at least 8 characters
              </div>
            </div>
          </div>
          <div class="terms-of-service">
            By creating an account, I agree with Anima’s
            <an-link variant="primary" :href="privacyUrl" target="_blank">Privacy Policy</an-link> and
            <an-link variant="primary" :href="termsUrl" target="_blank">Terms of Service.</an-link>
          </div>
          <div class="actions">
            <an-button class="justify-center" type="submit" :isWorking="loading" size="xl" data-cy="signup-submit">
              Create account
            </an-button>
          </div>
        </form>
      </div>
      <div class="signup-illustration" v-if="!googleLoading" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { reportSignup } from 'anima-ppc-tracking';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import GoogleButton from '@/components/Button/GoogleButton';
import Divider from '@/components/Divider/Divider';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { SignupMixin } from '@/mixins';

import auth from '@/auth';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      errorMessage: '',
      theme: 'dark',
      validFirstName: true,
      validLastName: true,
      validEmail: true,
      validPassword: true,
      termsUrl: 'https://www.animaapp.com/tos/TOS.pdf',
      privacyUrl: 'https://www.animaapp.com/tos/TOS.pdf#page=9',
      loading: false,
      googleLoading: false
    };
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
  },
  beforeDestroy() {
    this.removeTheme();
  },
  components: {
    GoogleButton,
    Divider,
    LoadingScreen,
    OnboardingTopBar
  },
  mixins: [SignupMixin],
  computed: {
    ...mapState('projects', ['sharedProjects']),
    nameRequired() {
      return this.isDesktop;
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      createTeam: 'teams/create',
      fetchSharedProjects: 'projects/fetchSharedProjects'
    }),
    ...mapMutations({
      setCurrentUser: 'users/setCurrentItem',
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    validateName() {
      this.validateFirstName();
      this.validateLastName();
    },
    validateFirstName() {
      this.validFirstName = !!this.firstName?.trim().length || !this.nameRequired;
    },
    validateLastName() {
      this.validLastName = !!this.lastName?.trim().length || !this.nameRequired;
    },
    validateEmail() {
      const email = normalizeEmail(this.email);
      this.validEmail = isValidEmail(email);
      if (!this.validEmail) {
        this.errorMessage = 'Please enter a valid email address';
      } else {
        this.errorMessage = '';
      }
    },
    validatePassword() {
      this.validPassword = this.password.length >= 8;
    },
    validateForm() {
      this.validateName();
      this.validateEmail();
      this.validatePassword();
      const isValid = this.validEmail && this.validFirstName && this.validLastName && this.validPassword;
      if (!isValid) {
        this.$trackEvent('signup.signup.invalid-form', {
          is_email_valid: this.validEmail,
          is_password_valid: this.validPassword,
          is_first_name_valid: this.validFirstName,
          is_last_name_valid: this.validLastName,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        });
      }
      return isValid;
    },
    async onGoogleSignupSuccess(googleUser) {
      try {
        this.googleLoading = true;
        await this.onGoogleSuccess(googleUser);
      } catch (error) {
        this.$sentry.captureException(error);
        toastError("We couldn't log you in with your Google account :(");
      } finally {
        this.googleLoading = false;
      }
    },
    async onGoogleSignupFailure(err) {
      this.onGoogleFailure(err);
      toastError("We couldn't log you in with your Google account :(");
    },
    async signup() {
      try {
        const { query } = this.$route;
        this.$trackEvent('signup.signup-button.click', query);
        this.errorMessage = '';

        if (!this.validateForm()) {
          return;
        }

        const email = normalizeEmail(this.email);
        const { firstName, lastName } = this;

        this.loading = true;
        const { access_token: token, ...user } = await auth.signup(firstName, lastName, email, this.password);
        localStorage.setItem('token', token);
        this.setCurrentUser(user);

        this.$trackEvent('signup.signup.success', {
          ...query,
          cta: 'signup-button'
        });

        this.$gtm.trackEvent({
          event: 'sign_up',
          event_category: 'Signup',
          event_action: `${firstName} ${lastName}`,
          event_label: email
        });

        this.$tracking.setUserEmail(user.email);
        this.$tracking.setUserId(user.id);
        this.$tracking.alias(user.id);

        reportSignup({}, user.id);

        await this.createFirstTeamFlow(user);
        await this.fetchSharedProjects();
        setTimeout(() => {
          this.redirectAfterAuth();
          this.loading = false;
        }, 1000);
      } catch (error) {
        this.$sentry.captureException(error);
        let message = error.message;
        if (error?.data?.message) {
          message = error.data.message;
        }
        this.errorMessage = message;
        this.$trackEvent('signup.signup.failure', { message });
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$topBarHeight: 80px;
.form-container {
  width: 335px;
  text-align: center;
  @include mobile {
    width: 375px;
    padding: 20px;
  }
}
.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $topBarHeight;
  height: calc(100vh - #{$topBarHeight});
  width: 100%;
  @include mobile {
    margin-top: 60px;
  }
  .container {
    margin: 0;
    width: 335px;
    @include mobile {
      width: 100%;
    }
  }
  .signup-illustration {
    background: url('~@/assets/illustrations/signup.svg');
    background-size: cover;
    margin-left: 210px;
    width: 624px;
    height: 550px;
    @media screen and (max-width: 1280px) {
      width: 500px;
      height: 440px;
    }
    @include mobile {
      display: none;
    }
  }
}
.title {
  @include headline;
  font-weight: 500;
  text-align: center;
}
.subtitle {
  margin: 20px 40px 0;
  @include mobile {
    margin: 16px 0;
  }
}
.social-signup,
.text-divider {
  margin: 40px 0;
  width: 100%;
}
.title {
  font-weight: 700;
  @include mobile {
    font-size: 32px;
    line-height: 50px;
  }
}
.property {
  .name-property {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 50px;
    .input {
      margin: 0;
      & + .input {
        margin-left: 15px;
      }
    }
  }
  + .property {
    margin-top: 24px;
  }
}
.terms-of-service {
  font-size: 14px;
  text-align: center;
  margin: 30px 0 40px;
}
.actions {
  height: 48px;
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    width: 100%;
    &.an-button {
      @include mobile {
        border-radius: 0;
      }
    }
  }
}
.invalid-message {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  color: var(--red);
}
</style>
