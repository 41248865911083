var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", class: _vm.containerClass },
    [
      _c("h1", { staticClass: "title" }, [_vm._v("Import from Sketch")]),
      _vm.subtitle ? _c("h2", [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.uploadState === "uploading"
          ? _c(
              "div",
              { staticClass: "progress-bar-container" },
              [
                _c("ProgressBar", {
                  attrs: {
                    progress: _vm.uploadProgress,
                    height: 12,
                    backgroundColor: "rgba(255, 98, 80, 0.3)"
                  }
                }),
                _c("div", { staticClass: "progress-bar-text" }, [
                  _vm._v("Uploading screens")
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stage === "upload-file",
              expression: "stage === 'upload-file'"
            }
          ],
          staticClass: "stage"
        },
        [
          _c(
            "upload",
            {
              attrs: {
                accept: ".sketch",
                isFileValid: this.validateFileBeforeUpload,
                fieldName: "file",
                url: "/v2/uploads/projects/" + this.project.id + "/file"
              },
              on: {
                start: _vm.onUploadStart,
                success: _vm.onUploadSuccess,
                failure: _vm.onUploadFailure,
                "upload-progress": _vm.onUploadProgress
              }
            },
            [
              _c("div", { staticClass: "upload-file-frame" }, [
                _c("div", { staticClass: "content" }, [
                  _c("img", {
                    staticClass: "upload-file-frame-illustration",
                    attrs: {
                      src: require("@/assets/illustrations/sketch-to-anima.svg")
                    }
                  }),
                  _c("p", { staticClass: "upload-file-frame-text" }, [
                    _c("span", { staticClass: "faded" }, [
                      _vm._v("Drag & drop, or")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "demmy-link" }, [
                      _vm._v("Browse your files")
                    ])
                  ])
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm.stage === "pick-artboards"
        ? _c("sketch-select-artboards", {
            attrs: { "sketch-file": _vm.sketchFile },
            on: { submit: _vm.syncArtboards, close: _vm.handleClose }
          })
        : _vm._e(),
      _vm.stage === "syncing-project"
        ? _c(
            "div",
            [
              _vm.sketchProcessState <= 3 ? _c("default-loader") : _vm._e(),
              _vm.sketchProcessState === 1
                ? _c("div", [_vm._v("Processing Sketch file")])
                : _vm._e(),
              _vm.sketchProcessState === 2
                ? _c("div", [_vm._v("Uploading processed data")])
                : _vm._e(),
              _vm.sketchProcessState === 3
                ? _c("div", [_vm._v("Generating code")])
                : _vm._e(),
              _vm.sketchProcessState === 4
                ? _c("div", [_vm._v("Oops. Something went wrong.")])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }