<template>
  <div class="assets slideInRight">
    <EmptyState
      :src="require(`@/assets/illustrations/bird-home-dark.svg`)"
      v-if="currentComponentAssets && currentComponentAssets.length == 0"
      title="Import your assets"
    >
      We couldn’t find any assets on this screen. Set layers for export in Figma, Sketch & XD and we will list them
      here.
    </EmptyState>

    <div v-else class="flex flex-col h-full w-full">
      <div class="download-all">
        <div class="title">Download all assets</div>
        <div v-if="pngAssets && pngAssets.length > 0" class="b flex items-center">
          <svg-icon class="b-icon" :size="46" name="image-png"></svg-icon>
          <div class="flex flex-col">
            <div class="b-text">{{ pngAssets.length }} image{{ pngAssets.length > 1 ? 's' : '' }}</div>
            <div class="b-sub-text">PNG</div>
          </div>
          <a :class="{ visible: isDownloadingZip['png'] }" class="download" download>
            <div @click.prevent="downloadZip('png')" class="download-link">
              <svg-icon v-if="!isDownloadingZip['png']" :size="18" fill="currentColor" name="download"></svg-icon>
              <svg-icon v-else :size="18" fill="currentColor" class="icon-spin" name="spin"></svg-icon>
            </div>
          </a>
        </div>
        <div v-if="svgAssets && svgAssets.length > 0" class="b flex items-center">
          <svg-icon class="b-icon" :size="46" name="image-svg"></svg-icon>
          <div class="flex flex-col">
            <div class="b-text">{{ svgAssets.length }} vector image{{ pngAssets.length > 1 ? 's' : '' }}</div>
            <div class="b-sub-text">SVG</div>
          </div>
          <a :class="{ visible: isDownloadingZip['svg'] }" class="download" download>
            <div @click.prevent="downloadZip('svg')" class="download-link">
              <svg-icon v-if="!isDownloadingZip['svg']" :size="18" fill="currentColor" name="download"></svg-icon>
              <svg-icon v-else :size="18" fill="currentColor" class="icon-spin" name="spin"></svg-icon>
            </div>
          </a>
        </div>
      </div>
      <ul class="assets-list">
        <div class="title">Individual assets</div>
        <virtual-list class="vv-list" wclass="v-list" :size="68" :remain="getRemain" :bench="0">
          <li class="asset" v-for="(layer, idx) in currentComponentAssets" :key="`${layer.md5}${idx}`">
            <div style="flex:1;max-width:260px" class="flex items-center">
              <div :style="getAssetBg(layer)" class="asset-image"></div>
              <div class="text flex flex-col">
                <div class="name">{{ getLayerName(layer) }}</div>
                <div class="size">{{ Math.round(layer.width) }} px × {{ Math.round(layer.height) }} px</div>
              </div>
            </div>
            <a
              @click="trackAssetDonwnload(layer)"
              target="_blank"
              class="download"
              :href="getAssetDownloadURL(layer)"
              download
            >
              <div class="download-link">
                <svg-icon :size="18" fill="currentColor" name="download"></svg-icon>
              </div>
            </a>
          </li>
        </virtual-list>
      </ul>
    </div>
    <div v-if="currentUser.is_admin" style="padding:20px" class="dev-container flex items-center justify-center">
      <div class="dev">Download</div>
      <an-button :isWorking="isDownloadingZip['model']" @click="DownloadModel" class="dev" variant="secondary" size="sm"
        >Model</an-button
      >
      <an-button
        :isWorking="isDownloadingZip['all']"
        @click="DownloadAllAssets"
        class="dev"
        variant="secondary"
        size="sm"
        >Assets</an-button
      >
    </div>
  </div>
</template>

<script>
import auth from '@/auth';
import { mapActions, mapGetters, mapState } from 'vuex';
import VirtualList from 'vue-virtual-scroll-list';
import EmptyState from '@/components/OmniView/EmptyState.vue';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { kebabCase } from 'lodash-es';

export default {
  name: 'assets-view',
  components: {
    EmptyState,
    VirtualList
  },
  data() {
    return {
      isDownloadingZip: {
        png: false,
        svg: false,
        all: false,
        model: false
      }
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projects', { assetsRegistry: 'assetsRegistry' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('components', { pngAssets: 'pngAssets' }),
    ...mapState('components', { svgAssets: 'svgAssets' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapGetters({
      currentComponentAssets: 'components/currentComponentAssets'
    }),
    getRemain() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return Math.floor((h - 150 - 74 - 230) / 68);
    }
  },
  methods: {
    ...mapActions({
      fetchReleaseModel: 'releases/fetchReleaseModel'
    }),
    getAssetURL(layer) {
      return this.assetsRegistry && this.assetsRegistry[layer.md5] ? this.assetsRegistry[layer.md5].url : '';
    },
    getAssetBg(layer) {
      return {
        'background-image': `url('${
          this.assetsRegistry && this.assetsRegistry[layer.md5] ? this.assetsRegistry[layer.md5].url : ''
        }')`,
        'background-size': layer.width > 40 ? 'contain' : 'auto'
      };
    },
    getAssetDownloadURL(layer) {
      return `${process.env.API_BASE_URL1}/v2/projects/${this.$route.params.projectId ||
        this.currentProject.id}/assets/${layer.md5}?t=${auth.getToken()}`;
    },
    getExtfromFileName(fileName) {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(fileName)[1];
    },
    getLayerName(layer) {
      return layer.fileName.replace(/\.[^/.]+$/, '');
    },
    trackAssetDonwnload(layer) {
      const ext = this.getExtfromFileName(layer.fileName);
      this.$trackEvent('omni.code-mode.download-asset', { 'asset-type': ext });
    },
    downloadZip(type) {
      if (this.isDownloadingZip[type]) return;

      let source = type == 'svg' ? this.svgAssets : this.pngAssets;

      const fileURLs = source.map(layer => ({ fileName: layer.fileName, url: this.getAssetDownloadURL(layer) }));

      this.isDownloadingZip[type] = true;
      this.doExport(fileURLs, type, `assets(${type}).zip`);
    },
    doExport(fileURLs, type, zipName) {
      let zip = new JSZip();
      fileURLs
        .reduce((prev, { url, fileName }) => {
          return prev.then(() => {
            let promise = fetch(url).then(res => res.arrayBuffer());
            zip.file(fileName, promise);

            return promise;
          });
        }, Promise.resolve())
        .then(() => zip.generateAsync({ type: 'blob' }))
        .then(blob => {
          this.isDownloadingZip[type] = false;
          return saveAs(blob, zipName);
        });
    },
    async DownloadModel() {
      if (this.isDownloadingZip['model']) return;
      let zip = new JSZip();

      await this.$waitFor(() => this.currentRelease, null, { notEmpty: true });

      this.isDownloadingZip['model'] = true;
      let res = await this.fetchReleaseModel();
      const model = JSON.stringify(res);
      zip.file('model.json', model);
      zip.generateAsync({ type: 'blob' }).then(blob => {
        const { short_id, name } = this.currentProject;
        this.isDownloadingZip['model'] = false;
        return saveAs(blob, `${kebabCase(name)}#${short_id}.anima.zip`);
      });
    },
    async DownloadAllAssets() {
      if (this.isDownloadingZip['all']) return;

      const fileURLs = this.currentComponentAssets.map(layer => ({
        fileName: layer.fileName,
        url: this.getAssetDownloadURL(layer)
      }));

      this.isDownloadingZip['all'] = true;
      const { name } = this.currentProject;
      this.doExport(fileURLs, 'all', `${name}.assets.zip`);
    }
  }
};
</script>
<style lang="scss" scoped>
.assets {
  // height: calc(100vh - 150px);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.assets-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  .title {
    padding: 0 20px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.asset {
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px;

  transition: background 0.2s;

  .text {
    margin-left: 15px;
    overflow: hidden;
  }

  .name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .size {
    opacity: 0.4;
    font-size: 12px;
  }

  &:hover {
    background: #2d2d2d;
    .download {
      visibility: visible;
    }
  }
}
.asset-image {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  max-width: 40px;
  background-repeat: no-repeat;
  background-position: center;
}
.download-all {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 0 0 #2d2d2d;

  .title {
    padding: 0 20px;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .b {
    transition: background 0.2s;
    padding: 10px 20px;

    &-icon {
      margin-right: 16px;
      flex-shrink: 0;
    }

    &-text {
      flex-shrink: 0;
      font-size: 16px;
    }
    &-sub-text {
      flex-shrink: 0;
      font-size: 14px;
      opacity: 0.4;
    }

    &:hover {
      background: #2d2d2d;
      .download {
        visibility: visible;
      }
    }
  }

  .b:last-child {
    margin-bottom: 20px;
  }
}

.download {
  margin-left: auto;
  flex-shrink: 0;
  visibility: hidden;

  &.visible {
    visibility: visible !important;
  }
}

.download-link {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--primary);
}

.dev-container {
  height: 100px;
  .dev {
    display: none;
    margin: 0 5px;
  }

  &:hover {
    .dev {
      display: inline-flex;
    }
  }
}
</style>
