import api from '@/api';
import { get } from 'lodash';
import { downloadFile, poll } from '@/utils/javascript';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      CodeReady: {
        Flex: false,
        Absolute: false,
        React: false
      },
      _type: 'Flex',
      _exportWhenReady: false,
      _clicked: false,
      exportCodeLoading: false,
      _isReadyToDownload: false
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapGetters({
      _codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      isActiveExperiment: 'experiments/isActive'
    })
  },
  watch: {
    _isReadyToDownload: {
      handler(isReady) {
        if (isReady) {
          this.exportCodeLoading = false;
          if (this._clicked) {
            this._exportCode();
          }
        }
      }
    }
  },
  mounted() {
    this.checkRelease(this.currentProjectRelease || {});
  },
  methods: {
    ...mapActions({
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchCodePackages: 'codePackages/fetchAllOfParent',
      fetchCodePackageById: 'codePackages/fetchOne'
    }),
    checkRelease(release) {
      let typeMap = {
        Flex: !!get(release, 'download_zips.Flex', ''),
        Play: !!get(release, 'download_zips.Play', ''),
        React: !!get(release, 'download_zips.React', ''),
        Vue: !!get(release, 'download_zips.Vue', '')
      };

      this.CodeReady = typeMap;
      this._isReadyToDownload = typeMap[this._type];
    },
    checkCodePackage(codePackage) {
      this._isReadyToDownload = codePackage?.status !== 'processing';
      return this._isReadyToDownload;
    },
    _startPolling() {
      if (this._isReadyToDownload) return;
      const id = this.currentProject.live_project_release;
      this.exportCodeLoading = true;

      const fetchAction = this.fetchProjectRelease;

      const request = setInterval(() => {
        if (this._isReadyToDownload) {
          clearInterval(request);
          return this.handleExportCode();
        }

        fetchAction({ id, skipCache: true })
          .then(data => {
            this.checkRelease(data);
          })
          .catch(() => {
            clearInterval(request);
            this.exportCodeLoading = false;
          });
      }, 5000);
    },
    async fetchRelevantCodePackage(settings) {
      try {
        const { id: projectReleaseId } = this.currentProjectRelease;
        this.exportCodeLoading = true;
        const { results: codePackages = [] } = await this.fetchCodePackages({
          parent: 'project_releases',
          id: projectReleaseId,
          params: { settings },
          skipCache: true
        });
        this.exportCodeLoading = false;
        return codePackages[0];
      } catch (err) {
        this.exportCodeLoading = false;
        return null;
      }
    },
    _getCodegenFrameworkSettings({
      framework,
      codegenReactStyle,
      codegenReactSyntax,
      codegenHTMLLayout,
      codegenVueStyle
    }) {
      let settings = {};
      if (framework === 'html') {
        settings = {
          ...settings,
          preset_settings: codegenHTMLLayout === 'auto_flexbox' ? 'clean_code' : 'high_fidelity'
        };
      } else if (framework === 'react') {
        settings = {
          ...settings,
          preset_settings: 'clean_code',
          web_components_enable: true,
          web_framework: 'React',
          web_framework_settings: {
            component_type: codegenReactSyntax,
            styled_components: codegenReactStyle === 'styled',
            stylesheet_syntax: codegenReactStyle === 'styled' ? 'css' : codegenReactStyle
          }
        };
      } else if (framework === 'vue') {
        settings = {
          ...settings,
          preset_settings: 'clean_code',
          web_components_enable: true,
          web_framework: 'Vue',
          web_framework_settings: {
            stylesheet_syntax: codegenVueStyle
          }
        };
      }

      return settings;
    },
    _getDownloadUrl() {
      if (!this._type) return null;
      return this.currentProjectRelease.download_zips[this._type];
    },
    async requestPackage({ settings }) {
      try {
        const { id } = this.currentProjectRelease;
        this._setType();

        this.$trackEvent('omniview.package-on-demand.start', { type: this._type });
        this.trackExportCodeEvent();

        const payload = {
          package_types: this._type,
          settings
        };
        this.exportCodeLoading = true;
        return await api.post(`/project_release/${id}/package`, payload);
      } finally {
        this.exportCodeLoading = false;
      }
    },
    _exportCode() {
      this._clicked = false;
      const url = this._getDownloadUrl();
      this.$trackEvent('omni.code-mode.download-code');
      this.$trackEvent('omniview.export-code.code-exported');

      /* for Cypres test */
      const anchor = document.createElement('a');
      anchor.setAttribute('data-cy', 'exportCode-anchor');
      anchor.href = url;
      document.body.appendChild(anchor);
      if (window.Cypress) {
        this.exportCodeLoading = false;
        return;
      }
      /* --- */

      let win = window.open(url, '_blank');
      if (win) {
        win.focus();
      }
      this.exportCodeLoading = false;
      this._exportWhenReady = false;
      anchor.remove();
    },
    _setType() {
      const { layout, framework } = this._codeDownloadPrefs;

      this._type = 'Play';
      if (framework === 'react') {
        this._type = 'React';
      } else if (framework === 'vue') {
        this._type = 'Vue';
      } else if (layout === 'auto_flexbox') {
        this._type = 'Flex';
      }
    },
    trackExportCodeEvent() {
      const { layout, framework } = this._codeDownloadPrefs;
      this.$trackEvent('omniview.download-code-button.clicked', {
        layout,
        page: 'omniview'
      });
      this.$gtm.trackEvent({
        event: 'html_code_download',
        event_category: 'HTML Code Download',
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: framework
      });
    },
    handleExportCode() {
      this._clicked = true;
      this._setType();

      this.trackExportCodeEvent();
      this.checkRelease(this.currentProjectRelease);

      if (this._isReadyToDownload) {
        this._exportCode();
      } else {
        this._startPolling();
      }
    },
    async handleDownloadCodePackage(settings) {
      let codePackage = await this.fetchRelevantCodePackage(settings);
      this.exportCodeLoading = true;

      if (codePackage?.status === 'processing') {
        codePackage = await this._pollCodePackage(codePackage, settings);
      }

      const { status, download_url: downloadUrl } = codePackage || {};

      if (status === 'finished' && downloadUrl) {
        this.trackExportCodeEvent();
        downloadFile(downloadUrl);
      } else if (!codePackage || !status || ['outdated', 'failed'].includes(status) || !downloadUrl) {
        await this.requestPackage({ settings });
      }

      this.exportCodeLoading = false;
      return status;
    },
    async _pollCodePackage({ id }) {
      if (this._isReadyToDownload) return;
      this.exportCodeLoading = true;

      try {
        const codePackage = await poll({
          fn: () => this.fetchCodePackageById({ id, skipCache: true }),
          validate: this.checkCodePackage,
          interval: 5000,
          maxAttempts: 30
        });
        return codePackage;
      } finally {
        this.exportCodeLoading = false;
      }
    }
  }
};
