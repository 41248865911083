var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-header" },
    [
      _c(
        "router-link",
        { staticClass: "back-link", attrs: { to: _vm.backRouteObject } },
        [
          _c("svg-icon", {
            attrs: {
              name: "caret-left",
              size: 30,
              fill: "var(--secondary-text)"
            }
          }),
          !_vm.isWorking
            ? _c("span", [_vm._v(_vm._s(_vm.backLinkLabel))])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "project-info" },
        [
          _vm.projectName
            ? _c("NameChangeGhostInput", {
                key: _vm.project.name,
                attrs: {
                  value: _vm.project.name,
                  disabled: _vm.loading,
                  "data-cy": "project-name"
                },
                on: { blur: _vm.updateName },
                model: {
                  value: _vm.projectName,
                  callback: function($$v) {
                    _vm.projectName = $$v
                  },
                  expression: "projectName"
                }
              })
            : _vm._e(),
          _vm.isDesktop
            ? _c(
                "div",
                { staticClass: "actions" },
                _vm._l(_vm.navItems, function(item) {
                  return _c(
                    "div",
                    { key: item.name, staticClass: "nav-item-container" },
                    [
                      _c(
                        "PopoverMenu",
                        {
                          attrs: {
                            items: item.popoverItems || [],
                            position: "right",
                            popperClass: "select-import-menu"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              item.tooltipText
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tip",
                                          rawName: "v-tip",
                                          value: {
                                            content: item.tooltipText,
                                            placement: "top",
                                            classes: "nav-item-tooltip"
                                          },
                                          expression:
                                            "{ content: item.tooltipText, placement: 'top', classes: 'nav-item-tooltip' }"
                                        }
                                      ],
                                      class: [
                                        "nav-item",
                                        "clickable-icon",
                                        item.name,
                                        { disabled: item.disabled }
                                      ],
                                      attrs: { "data-cy": item.dataCy },
                                      on: { click: item.onClick }
                                    },
                                    [
                                      _c("div", {
                                        ref: item.name + "Icon",
                                        refInFor: true,
                                        staticClass: "nav-item-svg-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            require("!html-loader!./icons/" +
                                              item.name +
                                              ".svg")
                                          )
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "an-tooltip",
        { attrs: { text: "Invite people to project" } },
        [
          _c("Members", {
            attrs: { members: _vm.members, showMore: "" },
            on: { click: _vm.onClickMembers }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }