<template>
  <div class="container">
    <div class="not-found-top-bar" v-if="showTopBar">
      <top-bar v-if="isMobile" />
      <an-logo v-if="isDesktop" :light="$theme === 'light'" />
    </div>
    <div :class="['quirky', $theme]">Oops ..</div>
    <div class="title">The {{ resource }} you were looking for does not exist.</div>
    <div class="cta">
      <slot name="cta" />
      <an-button v-if="!$slots.cta" @click="$router.replace({ name: 'root' }, () => {})">
        Back home
      </an-button>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/Layout/TopBar';

export default {
  props: {
    showTopBar: {
      type: Boolean,
      default: true
    },
    resource: {
      type: String,
      default: 'page'
    }
  },
  components: {
    TopBar
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  @include mobile {
    padding: 0 20px;
  }
}
@mixin text-size {
  font-size: 68px;
  line-height: 88px;
  @include mobile {
    font-size: 32px;
    line-height: 50px;
  }
}
.quirky {
  @include quirky-text;
  color: var(--secondary-text);
  font-size: 32px;
  line-height: 42px;
  @include mobile {
    font-size: 24px;
    line-height: 32px;
  }
  &.dark {
    color: var(--white);
  }
}
.title {
  @include headline;
  width: 520px;
  margin-top: 30px;
  @include mobile {
    width: 100%;
    margin-top: 20px;
  }
}
.cta {
  margin-top: 40px;
}
.not-found-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include desktop {
    padding: 25px 40px;
  }
}
</style>
