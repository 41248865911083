var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "title" }, [_vm._v("Now just a minute")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          " Are you sure you want to delete your team? All your team data will be permanently deleted for all users and you’ll not be able to undo this action. "
        )
      ]),
      _c(
        "div",
        { staticClass: "disclaimer" },
        [
          _c("div", { staticClass: "disclaimer-title" }, [
            _vm._v("Consider other options")
          ]),
          _vm._v(" Only want to stop paying for this team? "),
          _c("an-link", { attrs: { variant: "primary" } }, [
            _vm._v("Change plan")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "Checkbox",
            {
              model: {
                value: _vm.agreeDeleted,
                callback: function($$v) {
                  _vm.agreeDeleted = $$v
                },
                expression: "agreeDeleted"
              }
            },
            [
              _vm._v(
                " All my team's design projects, assets and comments will be deleted. "
              )
            ]
          ),
          _c(
            "Checkbox",
            {
              model: {
                value: _vm.agreeLinks,
                callback: function($$v) {
                  _vm.agreeLinks = $$v
                },
                expression: "agreeLinks"
              }
            },
            [_vm._v(" All shared and prototype links will be inaccessible. ")]
          )
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showActions
          ? _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "an-button",
                  {
                    attrs: { isWorking: _vm.isWorking },
                    on: { click: _vm.deleteTeam }
                  },
                  [_vm._v("Delete Team")]
                ),
                _c("an-link", { on: { click: _vm.cancel } }, [_vm._v("Cancel")])
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/falling-woman.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }