var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Downgrade your plan")]),
      _vm._m(0),
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "separated" },
          [
            _c(
              "an-radio",
              {
                attrs: {
                  centered: false,
                  name: "downgrade",
                  label: "pause",
                  value: _vm.downgradeType
                },
                on: { change: _vm.onRadioChange }
              },
              [
                _c("div", { staticClass: "downgrade-label" }, [
                  _vm._v("Pause subscription")
                ]),
                _c("div", { staticClass: "downgrade-text" }, [
                  _vm._v(
                    " Pause your subscription for up to 3 months, effective on your next billing date. You can resume or cancel at any time. "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "pause-select" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("Select pausing period")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "select",
                        attrs: { tabindex: "-1" },
                        on: {
                          click: _vm.toggleSelectOpen,
                          focusout: _vm.closeSelect
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.pausePeriod) + " "),
                        _c(
                          "div",
                          {
                            staticClass: "select-icon",
                            class: { rotated: _vm.pauseSelectIsOpen }
                          },
                          [
                            _c("svg-icon", {
                              attrs: { name: "arrow-right", size: 30 }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "Popover",
                      {
                        attrs: {
                          isOpen: _vm.pauseSelectIsOpen,
                          position: "left",
                          popperClass: "popper"
                        },
                        on: { close: _vm.closeSelect }
                      },
                      [
                        _c(
                          "an-link",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changePeriod("1 month")
                              }
                            }
                          },
                          [_vm._v("Pause for 1 month")]
                        ),
                        _c(
                          "an-link",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changePeriod("2 month")
                              }
                            }
                          },
                          [_vm._v("Pause for 2 months")]
                        ),
                        _c(
                          "an-link",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changePeriod("3 month")
                              }
                            }
                          },
                          [_vm._v("Pause for 3 months")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "separated" },
          [
            _c(
              "an-radio",
              {
                attrs: {
                  centered: false,
                  label: "cancel",
                  value: _vm.downgradeType
                },
                on: { change: _vm.onRadioChange }
              },
              [
                _c("div", { staticClass: "downgrade-label" }, [
                  _vm._v("Cancel subscription")
                ]),
                _c("div", [
                  _vm._v(
                    " Your subscription will remain active until the end of your billing period. You’ll have full access to all your projects by then. "
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "an-button",
              { attrs: { isWorking: _vm.isWorking }, on: { click: _vm.apply } },
              [_vm._v("Apply changes")]
            ),
            _c("an-link", { on: { click: _vm.cancel } }, [_vm._v("Dismiss")])
          ],
          1
        )
      ]),
      _vm.showCancelOfferPrompt
        ? _c(
            "an-prompt",
            {
              on: {
                close: function($event) {
                  _vm.showCancelOfferPrompt = false
                }
              }
            },
            [
              _c("div", { staticClass: "prompt-content" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.firstName) + ", before you cancel,"),
                  _c("br"),
                  _vm._v("consider this offer:")
                ]),
                _c("div", { staticClass: "disclaimer" }, [
                  _c("span", { staticClass: "text bold" }, [
                    _vm._v(_vm._s(_vm.cancelOfferTitle))
                  ]),
                  _c("img", {
                    staticClass: "prompt-illustration",
                    attrs: {
                      src: require("@/assets/illustrations/cat-in-a-box.png")
                    }
                  }),
                  _vm.cancelOfferSuccess
                    ? _c("span", { staticClass: "text" }, [
                        _vm._v(" We're happy to have you with us! ")
                      ])
                    : _c("span", { staticClass: "text" }, [
                        _vm._v("Give us another try. No commitment.")
                      ])
                ]),
                _vm.cancelOfferSuccess
                  ? _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "an-link",
                          { on: { click: _vm.closeCancelOfferPrompt } },
                          [_vm._v("Dismiss")]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "an-button",
                          {
                            attrs: { isWorking: _vm.cancelOfferWorking },
                            on: { click: _vm.onActivateCancellationOffer }
                          },
                          [_vm._v("Accept offer")]
                        ),
                        _c(
                          "an-link",
                          { on: { click: _vm.closeCancelOfferPrompt } },
                          [_vm._v("Continue to cancel subscription")]
                        )
                      ],
                      1
                    )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _vm._v(" Are you sure you want to change your Anima plan to free? "),
      _c("br"),
      _vm._v("Sometimes you just need a short break! ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }