import { render, staticRenderFns } from "./SketchSelectArtboards.vue?vue&type=template&id=478be2fc&scoped=true&"
import script from "./SketchSelectArtboards.vue?vue&type=script&lang=js&"
export * from "./SketchSelectArtboards.vue?vue&type=script&lang=js&"
import style0 from "./SketchSelectArtboards.vue?vue&type=style&index=0&id=478be2fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478be2fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('478be2fc')) {
      api.createRecord('478be2fc', component.options)
    } else {
      api.reload('478be2fc', component.options)
    }
    module.hot.accept("./SketchSelectArtboards.vue?vue&type=template&id=478be2fc&scoped=true&", function () {
      api.rerender('478be2fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SketchFileUpload/SketchSelectArtboards.vue"
export default component.exports