var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-header" }, [
    !_vm.isEmpty(_vm.team)
      ? _c(
          "div",
          { staticClass: "details" },
          [
            _vm.isDesktop
              ? _c(
                  "an-tooltip",
                  { attrs: { text: _vm.uploadTooltipText } },
                  [
                    _c("UploadTeamLogo", {
                      attrs: { team: _vm.team },
                      on: { success: _vm.onUploadSuccess },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onUploadClick.apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticStyle: { width: "36px" } },
                  [
                    _c("UploadTeamLogo", {
                      attrs: { team: _vm.team, size: 36 },
                      on: { success: _vm.onUploadSuccess },
                      nativeOn: {
                        click: function($event) {
                          return _vm.onUploadClick.apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                ),
            _c(
              "div",
              { staticClass: "name-and-members" },
              [
                _vm.teamName
                  ? _c("NameChangeGhostInput", {
                      key: _vm.team.name,
                      attrs: { disabled: _vm.loading },
                      on: { blur: _vm.updateName },
                      model: {
                        value: _vm.teamName,
                        callback: function($$v) {
                          _vm.teamName = $$v
                        },
                        expression: "teamName"
                      }
                    })
                  : _vm._e(),
                _c(
                  "an-tooltip",
                  { attrs: { text: "Invite members" } },
                  [
                    _c("Members", {
                      attrs: { members: _vm.teamMembers, showMore: "" },
                      on: { click: _vm.onMembersClick }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isDesktop
      ? _c(
          "div",
          { staticClass: "new-project-container" },
          [
            _vm.showSlackIntegration
              ? _c("an-link", { on: { click: _vm.connectToSlack } }, [
                  _c("div", { staticClass: "inline" }, [
                    _c(
                      "div",
                      { staticClass: "slack-icon" },
                      [
                        _c("svg-icon", {
                          attrs: { name: "slack-logo", size: 24 }
                        })
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Connect to Slack")])
                  ])
                ])
              : _vm._e(),
            _c(
              "an-button",
              {
                attrs: { "data-cy": "create-project" },
                on: { click: _vm.onNewProjectClick }
              },
              [_vm._v("New Project")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }