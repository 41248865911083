var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-domain-row",
      on: {
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "details-container",
          class: { "is-deleted": _vm.isDeleted }
        },
        [_vm._v(" " + _vm._s(_vm.customDomain.domain) + " ")]
      ),
      _c("div", { staticClass: "cta" }, [
        _c(
          "div",
          {
            on: {
              click: function($event) {
                return _vm.$emit("remove")
              }
            }
          },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _vm.hover || _vm.isDeleted
                  ? _c(
                      "an-button",
                      { attrs: { variant: "empty", isWorking: _vm.isDeleted } },
                      [_vm._v("Remove")]
                    )
                  : _c("svg-icon", {
                      attrs: { name: "circle-check", size: 36 }
                    })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }