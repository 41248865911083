var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view slideInLeft" }, [
    _c("div", { staticClass: "title-container" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Comments")]),
      _c(
        "div",
        { staticClass: "filter-dropdown" },
        [
          _c(
            "v-tip",
            {
              attrs: { placement: "bottom-end", trigger: "click", offset: "4" }
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _c("span", { style: { textTransform: "capitalize" } }, [
                    _vm._v(_vm._s(_vm.commentsFilter))
                  ]),
                  _c("svg-icon", {
                    attrs: {
                      size: 20,
                      fill: "currentColor",
                      name: "small-arrow-down"
                    }
                  })
                ],
                1
              ),
              _c("template", { slot: "popover" }, [
                _c("div", { staticClass: "filter-pop" }, [
                  _c("div", { staticClass: "filter-items" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          { name: "close-popover", rawName: "v-close-popover" }
                        ],
                        staticClass: "filter",
                        class: { selected: _vm.commentsFilter == "open" },
                        on: {
                          click: function($event) {
                            return _vm.setCommentsFilter("open")
                          }
                        }
                      },
                      [_vm._v(" Open ")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          { name: "close-popover", rawName: "v-close-popover" }
                        ],
                        staticClass: "filter",
                        class: { selected: _vm.commentsFilter == "resolved" },
                        on: {
                          click: function($event) {
                            return _vm.setCommentsFilter("resolved")
                          }
                        }
                      },
                      [_vm._v(" Resolved ")]
                    )
                  ])
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "body-wrapper",
        style: {
          "padding-top": "60px",
          paddingBottom: _vm.selected ? "120px" : 0
        }
      },
      [
        _vm.getCommentsByNode.length == 0
          ? [
              _vm.commentsFilter == "open"
                ? _c(
                    "EmptyState",
                    {
                      attrs: {
                        src: require("@/assets/illustrations/delivery-owl-dark.svg"),
                        title: "No comments yet"
                      }
                    },
                    [_vm._v(" Click on any element to start a conversation ")]
                  )
                : _vm._e(),
              _vm.commentsFilter == "resolved"
                ? _c(
                    "EmptyState",
                    {
                      attrs: {
                        src: require("@/assets/illustrations/delivery-owl-dark.svg"),
                        title: "You’re all caught up!"
                      }
                    },
                    [_vm._v(" There are no resolved comments yet. ")]
                  )
                : _vm._e()
            ]
          : _c(
              "virtual-list",
              {
                staticClass: "vv-list",
                attrs: {
                  wclass: "v-list",
                  size: 133,
                  remain: _vm.getRemain,
                  bench: 0
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "comments-list" },
                  _vm._l(_vm.getCommentsByNode, function(comment) {
                    return _c("Comment", {
                      key: comment.id,
                      attrs: { comment: comment },
                      on: {
                        highlight: function($event) {
                          return _vm.$emit("highlight", $event)
                        },
                        resolve: function($event) {
                          return _vm.$emit("resolve", $event)
                        },
                        openReplies: function($event) {
                          return _vm.$emit("change", {
                            view: "replies",
                            comment: comment
                          })
                        },
                        mouseover: function($event) {
                          return _vm.handleCommentMouseOver(comment)
                        }
                      },
                      nativeOn: {
                        mouseout: function($event) {
                          return _vm.handleCommentMouseOut.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    })
                  }),
                  1
                )
              ]
            )
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        staticClass: "new-comment-container"
      },
      [
        _c(
          "div",
          { staticClass: "form-container" },
          [
            _c("an-textarea", {
              ref: "newCommentRef",
              attrs: {
                className: "comment-textarea " + (_vm.selected ? "active" : ""),
                autoFocus: "",
                rows: "1",
                placeholder: "Add your comment"
              },
              on: { enter: _vm.handleCreateComment },
              model: {
                value: _vm.newCommentText,
                callback: function($$v) {
                  _vm.newCommentText = $$v
                },
                expression: "newCommentText"
              }
            }),
            _c(
              "div",
              {
                staticClass: "icon-container",
                class: { active: _vm.isInputActive },
                on: { click: _vm.handleCreateComment }
              },
              [
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { name: "send", size: 23, fill: "currentColor" }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }