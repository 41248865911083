var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "copy-text" }, [
    _c("div", { staticClass: "text-container" }, [
      _vm.label
        ? _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.label) + ":")
          ])
        : _vm._e(),
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
    ]),
    _c(
      "div",
      { staticClass: "copy" },
      [
        _c(
          "an-link",
          { attrs: { variant: "primary" }, on: { click: _vm.copy } },
          [_vm._v("Copy")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }