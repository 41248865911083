import { moveItemWithinArray, insertItemIntoArray } from '@/utils/javascript';

export const isPositionChanged = (destination, source) => {
  if (!destination) return false;
  const isSameList = destination.droppableId === source.droppableId;
  const isSamePosition = destination.index === source.index;
  return !isSameList || !isSamePosition;
};

export const getSortedItems = (items, sectionId) => {
  return items
    .filter(item => item.sorting_section.id === sectionId)
    .sort((a, b) => a.sorting_section_position - b.sorting_section_position);
};
export const getSortedSections = items => {
  const itemsClone = [...items];
  return itemsClone.sort((a, b) => a.listPosition - b.listPosition);
};
export const getSortedTeams = items => {
  const itemsClone = [...items];
  return itemsClone.sort((a, b) => a.sorting_position - b.sorting_position);
};

const getAfterDropPrevNextItem = (allItems, destination, source, droppedItemId) => {
  const beforeDropDestinationItems = getSortedItems(allItems, destination.droppableId);
  const droppedItem = allItems.find(item => item.id === droppedItemId);
  const isSameList = destination.droppableId === source.droppableId;

  const afterDropDestinationItems = isSameList
    ? moveItemWithinArray(beforeDropDestinationItems, droppedItem, destination.index)
    : insertItemIntoArray(beforeDropDestinationItems, droppedItem, destination.index);

  return {
    prevItem: afterDropDestinationItems[destination.index - 1],
    nextItem: afterDropDestinationItems[destination.index + 1]
  };
};

export const calculateItemListPosition = (allItems, destination, source, droppedItemId) => {
  const { prevItem, nextItem } = getAfterDropPrevNextItem(allItems, destination, source, droppedItemId);
  let position;

  if (!prevItem && !nextItem) {
    position = 1;
  } else if (!prevItem) {
    position = nextItem.sorting_section_position - 1;
  } else if (!nextItem) {
    position = prevItem.sorting_section_position + 1;
  } else {
    position =
      prevItem.sorting_section_position + (nextItem.sorting_section_position - prevItem.sorting_section_position) / 2;
  }
  return position;
};
export const calculateSectionListPosition = (allSections, newIndex, droppedSectionId) => {
  const beforeDropDestinationSections = getSortedSections(allSections);
  const droppedItem = allSections.find(section => section.id === droppedSectionId);
  const afterDropDestinationItems = moveItemWithinArray(beforeDropDestinationSections, droppedItem, newIndex);
  const prevItem = afterDropDestinationItems[newIndex - 1];
  const nextItem = afterDropDestinationItems[newIndex + 1];

  let position;

  if (!prevItem && !nextItem) {
    position = 1;
  } else if (!prevItem) {
    position = nextItem.listPosition - 1;
  } else if (!nextItem) {
    position = prevItem.listPosition + 1;
  } else {
    position = prevItem.listPosition + (nextItem.listPosition - prevItem.listPosition) / 2;
  }
  return position;
};
export const calculateTeamListPosition = (allTeams, newIndex, droppedTeamId) => {
  const beforeDropDestinationTeams = getSortedTeams(allTeams);
  const droppedItem = allTeams.find(team => team.team === droppedTeamId);
  const afterDropDestinationItems = moveItemWithinArray(beforeDropDestinationTeams, droppedItem, newIndex);
  const prevItem = afterDropDestinationItems[newIndex - 1];
  const nextItem = afterDropDestinationItems[newIndex + 1];

  let position;

  if (!prevItem && !nextItem) {
    position = 1;
  } else if (!prevItem) {
    position = nextItem.sorting_position - 1;
  } else if (!nextItem) {
    position = prevItem.sorting_position + 1;
  } else {
    position = prevItem.sorting_position + (nextItem.sorting_position - prevItem.sorting_position) / 2;
  }
  return position;
};
