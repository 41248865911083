<template>
  <div class="avatar" :style="styles" @click="$emit('click')">
    <slot />
    <span v-if="!$slots.default">
      {{ src ? '' : text.slice(0, 2).toUpperCase() }}
    </span>
  </div>
</template>

<script>
import { isDark } from '@/utils/colors';

export default {
  name: 'an-avatar',
  data() {
    return {
      bgColor: null
    };
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 30
    },
    backgroundColor: {
      type: String
    },
    textColor: {
      type: String
    },
    borderColor: {
      type: String,
      default: '#ffffff'
    },
    text: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles() {
      let height, width;
      const { backgroundColor, textColor, borderColor, size, src, noBorder } = this;
      const background = `${backgroundColor} url("${src}") no-repeat`;
      const color = !src && isDark(background) ? 'var(--white)' : 'var(--primary-text)';
      const border = `1px solid ${borderColor}`;
      const fontSize = size < 40 ? '14px' : `${Math.round(size * 0.5)}px`;
      height = width = `${size}px`;
      return {
        height,
        width,
        background,
        border: !noBorder ? border : 'none',
        fontSize,
        color: textColor || color,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  border: 1px solid white;
  -moz-user-select: none;
}
</style>
