<template>
  <div id="app">
    <!-- Modals/toasts should go here  -->
    <Toasts />
    <IntercomBubble />
    <Modals />
    <PushNotifications />
    <Banners />
    <DebugEvents />
    <DevConsole v-if="isDevConsoleOpen" @close="isDevConsoleOpen = false" />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Toasts from '@/containers/Toasts.vue';
import Modals from '@/containers/Modals.vue';
import Banners from '@/containers/Banners.vue';
import PushNotifications from '@/containers/PushNotifications.vue';
import DebugEvents from '@/containers/DebugEvents.vue';
import DevConsole from '@/views/DevConsole.vue';
import {
  generateUserExperiments,
  setUserExperimentsCookie,
  trackExperiments,
  getFeatureToggles
} from '@/services/experiments';
import { showAppStatusBanner } from '@/services/banners';
import { checkAppStatus } from '@/services/app-status';
import { detectAdBlock } from '@/services/ad-blocker';
import IntercomBubble from '@/components/IntercomBubble/IntercomBubble.vue';

export default {
  components: {
    Toasts,
    Modals,
    PushNotifications,
    Banners,
    IntercomBubble,
    DebugEvents,
    DevConsole
  },
  created() {
    this.showDevPrefsIfNeeded();
    this.populateExperiments();
    this.populateFeatureToggles();
    this.screenSizeListener();
    this.checkAppStatus();
  },
  data() {
    return {
      isDevConsoleOpen: false
    };
  },
  methods: {
    ...mapActions({
      getAllExperiments: 'experiments/fetchAll',
      screenSizeListener: 'screenSize/listen'
    }),
    ...mapMutations('experiments', ['setUserExperiments', 'setIsExperimentsDoneLoading', 'setFeatureToggles']),
    ...mapMutations('webappSystem', ['setAdBlockerEnabled']),
    async populateExperiments() {
      try {
        const activeExperiments = await this.getAllExperiments();
        const userExperiments = generateUserExperiments(activeExperiments);
        setUserExperimentsCookie(userExperiments);
        this.setUserExperiments(userExperiments);

        const secondsSinceLastTracked = localStorage.lastTrackedExperiments
          ? (new Date() - Date.parse(localStorage.lastTrackedExperiments)) / 1000
          : 0;
        if (secondsSinceLastTracked == 0 || secondsSinceLastTracked > 86400) {
          trackExperiments(userExperiments);
          localStorage.lastTrackedExperiments = new Date();
        }
        this.setIsExperimentsDoneLoading(true);
      } catch (err) {
        console.error(err);
        this.setUserExperiments({});
      }
    },
    populateFeatureToggles() {
      const ft = getFeatureToggles();
      this.setFeatureToggles(ft);
    },
    async checkAppStatus() {
      const status = await checkAppStatus();
      if (status === 'problem') {
        showAppStatusBanner();
      } else {
        setTimeout(this.checkAppStatus, 60 * 1000);
      }
    },
    async checkForAdBlocker() {
      const isAdBlockerEnabled = await detectAdBlock();
      this.setAdBlockerEnabled(isAdBlockerEnabled);
    },
    showDevPrefsIfNeeded() {
      if (window.location.host.startsWith('canary')) {
        this.isDevConsoleOpen = true;
      }
    }
  }
};
</script>

<style lang="scss"></style>
