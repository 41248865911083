var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["master-card-container", { spread: _vm.hover }],
      on: {
        mouseover: function($event) {
          $event.preventDefault()
          _vm.hover = true
        },
        mouseout: function($event) {
          $event.preventDefault()
          _vm.hover = false
        }
      }
    },
    [
      _vm._l(_vm.linkedScreens, function(linkedScreen) {
        return _c("ScreenCard", {
          key: linkedScreen.id,
          staticClass: "linked-screen",
          attrs: {
            screen: linkedScreen,
            selected: linkedScreen.isSelected,
            "linked-screen": ""
          },
          on: {
            "click-thumb": function($event) {
              return _vm.$emit("click", $event)
            },
            mouseover: function($event) {
              _vm.hover = true
            },
            mouseout: function($event) {
              _vm.hover = false
            }
          }
        })
      }),
      _c("ScreenCard", {
        staticClass: "main-screen",
        attrs: {
          screen: _vm.screen,
          actions: _vm.actions,
          flags: _vm.flags,
          selected: _vm.selected
        },
        on: {
          mouseover: function($event) {
            _vm.hover = true
          },
          mouseout: function($event) {
            _vm.hover = false
          },
          "click-thumb": function($event) {
            return _vm.$emit("click", $event)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }