var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v(
        " All of your screens, prototypes, assets and comments will be permanently deleted and you’ll not be able to undo this action. "
      )
    ]),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "an-button",
          { attrs: { isWorking: _vm.isWorking }, on: { click: _vm._delete } },
          [_vm._v("Delete")]
        ),
        _c("an-link", { on: { click: _vm.cancel } }, [_vm._v("Cancel")])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/falling-woman.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }