<template>
  <GhostInput class="name" v-bind="$attrs" v-on="listeners" ref="ghostInput" />
</template>

<script>
import GhostInput from '@/components/Input/GhostInput';
export default {
  components: { GhostInput },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus,
        mouseover: this.onMouseover,
        mouseleave: this.onMouseleave
      };
    }
  },
  methods: {
    onMouseover(e) {
      this.$refs.ghostInput.$el.style.background = `rgba(216, 216, 216, 0.15)`;
      this.$emit('mouseover', e);
    },
    onMouseleave(e) {
      if (!this.focus) {
        this.$refs.ghostInput.$el.style.background = `transparent`;
      }
      this.$emit('mouseleave', e);
    },
    onFocus(e) {
      this.$refs.ghostInput.$el.style.background = `rgba(216, 216, 216, 0.15)`;
      this.focus = true;
      var cell = e.target;
      var range, selection;
      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(cell);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(cell);
        selection.removeAllRanges();
        selection.addRange(range);
      }
      this.$emit('focus', e);
    },
    onBlur(e) {
      this.focus = false;
      this.$refs.ghostInput.$el.style.background = 'transparent';
      this.$emit('blur', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.name {
  @include headline;
  letter-spacing: 1px;
  line-height: 42px;
  transition: all 0.2s ease;

  @include desktop {
    margin-right: 43px;
    width: 374px;
    padding: 4px 8px;
  }
  @include mobile {
    margin-bottom: 16px;
  }

  ::v-deep .editable {
    width: 100%;
  }
  ::v-deep .editable::selection {
    background: var(--primary);
    color: white;
  }
}
</style>
