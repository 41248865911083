var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full h-full cont" }, [
    _c("div", { staticClass: "title flex items-center justify-center" }, [
      _vm._v("Export options")
    ]),
    _c(
      "div",
      { staticClass: "flex flex-col items-center jusitfy-center w-full" },
      [
        _vm._l(_vm.options, function(op) {
          return _c(
            "div",
            {
              key: op.name,
              staticClass: "option flex flex-col",
              class: { selected: _vm.selectedOption == op.name },
              on: {
                click: function($event) {
                  return _vm.handleOptionChange(op)
                }
              }
            },
            [
              _c(
                "an-radio",
                {
                  attrs: {
                    full: "",
                    spacingX: 10,
                    type: "dot",
                    label: op.name,
                    value: _vm.selectedOption,
                    "data-cy": "export-code-radio"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex items-center",
                      staticStyle: { "font-size": "12px" },
                      attrs: { "data-cy": "export-label" }
                    },
                    [
                      _vm._v(" " + _vm._s(op.label) + " "),
                      ["zip", "codesandbox"].includes(op.name)
                        ? _c("span", { staticClass: "badge pro" }, [
                            _vm._v("Pro")
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c("p", { staticClass: "desc" }, [
                _vm._v(" " + _vm._s(op.description) + " ")
              ])
            ],
            1
          )
        }),
        _c("div", { staticClass: "sep" }),
        _c(
          "div",
          { staticClass: "settings" },
          [
            _c(
              "div",
              {
                staticClass: "toggle flex items-center",
                on: {
                  click: function($event) {
                    _vm.isCodeSettingsExpanded = !_vm.isCodeSettingsExpanded
                  }
                }
              },
              [
                _c("svg-icon", {
                  staticStyle: { "margin-right": "5px" },
                  attrs: {
                    size: 17,
                    fill: "currentColor",
                    name: _vm.isCodeSettingsExpanded
                      ? "small-arrow-down"
                      : "small-arrow-right"
                  }
                }),
                _c("span", { staticClass: "label" }, [_vm._v("Code settings")])
              ],
              1
            ),
            _c("TransitionHeight", { attrs: { watch: _vm.selectedOption } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCodeSettingsExpanded,
                      expression: "isCodeSettingsExpanded"
                    }
                  ],
                  staticClass: "content"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex w-full",
                      staticStyle: {
                        "justify-content": "space-between",
                        padding: "10px 0",
                        "flex-wrap": "wrap"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-col" },
                        [
                          _c("div", { staticClass: "col-title" }, [
                            _vm._v("Code Framework")
                          ]),
                          _c(
                            "an-radio",
                            {
                              attrs: {
                                full: "",
                                spacingX: 6,
                                spacingY: 4,
                                type: "dot",
                                label: "html",
                                value: _vm.codeDownloadPrefs.framework
                              },
                              on: {
                                change:
                                  _vm.handleCodeDownloadPrefsFrameworkChange
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v("Pure HTML")]
                              )
                            ]
                          ),
                          _c(
                            "an-radio",
                            {
                              attrs: {
                                full: "",
                                spacingX: 6,
                                spacingY: 4,
                                type: "dot",
                                label: "react",
                                value: _vm.codeDownloadPrefs.framework
                              },
                              on: {
                                change:
                                  _vm.handleCodeDownloadPrefsFrameworkChange
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v("React")]
                              )
                            ]
                          ),
                          _c(
                            "an-radio",
                            {
                              attrs: {
                                full: "",
                                spacingX: 6,
                                spacingY: 8,
                                type: "dot",
                                label: "vue",
                                value: _vm.codeDownloadPrefs.framework
                              },
                              on: {
                                change:
                                  _vm.handleCodeDownloadPrefsFrameworkChange
                              }
                            },
                            [
                              _c("div", { staticClass: "flex items-center" }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12px" } },
                                  [_vm._v("Vue")]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.selectedOption == "zip" &&
                      _vm.codeDownloadPrefs.framework == "html"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-col",
                                staticStyle: { "margin-left": "auto" }
                              },
                              [
                                _c("div", { staticClass: "col-title" }, [
                                  _vm._v("Layout")
                                ]),
                                _c(
                                  "an-radio",
                                  {
                                    attrs: {
                                      full: "",
                                      spacingX: 6,
                                      spacingY: 15,
                                      type: "dot",
                                      label: "absolute",
                                      value: _vm.codeDownloadPrefs.layout
                                    },
                                    on: {
                                      change:
                                        _vm.handleCodeDownloadLayoutPrefsChange
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v("Absolute Position")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "radio-sub-title" },
                                      [_vm._v("Great for prototypes")]
                                    )
                                  ]
                                ),
                                _c(
                                  "an-radio",
                                  {
                                    attrs: {
                                      full: "",
                                      spacingX: 6,
                                      spacingY: 15,
                                      type: "dot",
                                      label: "auto_flexbox",
                                      value: _vm.codeDownloadPrefs.layout
                                    },
                                    on: {
                                      change:
                                        _vm.handleCodeDownloadLayoutPrefsChange
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-col" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { "font-size": "12px" }
                                          },
                                          [_vm._v("Auto Flex")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "radio-sub-title" },
                                          [_vm._v("Great for Developers")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm.codeDownloadPrefs.framework == "html"
                        ? [
                            _c("CodePrefCol", {
                              attrs: {
                                value: _vm.codegenHTMLLayout,
                                title: "Layout",
                                options: _vm.htmlLayoutOptions
                              },
                              on: { change: _vm.handleHTMLLayoutChange }
                            })
                          ]
                        : _vm._e(),
                      _vm.codeDownloadPrefs.framework == "react"
                        ? [
                            _c("CodePrefCol", {
                              attrs: {
                                value: _vm.codegenReactSyntax,
                                title: "Syntax",
                                options: _vm.reactSyntaxOptions
                              },
                              on: { change: _vm.handleReactSyntaxChange }
                            }),
                            _c("CodePrefCol", {
                              attrs: {
                                value: _vm.codegenReactStyle,
                                title: "Styling",
                                options: _vm.reactStyleOptions
                              },
                              on: { change: _vm.handleReactStyleChange }
                            })
                          ]
                        : _vm._e(),
                      _vm.codeDownloadPrefs.framework === "vue"
                        ? [
                            _c("CodePrefCol", {
                              attrs: {
                                value: _vm.codegenVueStyle,
                                title: "Styling",
                                options: _vm.vueStyleOptions
                              },
                              on: { change: _vm.handleVueStyleChange }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex flex-col" },
          [
            _c(
              "an-button",
              {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  isWorking: _vm.isExportLoading,
                  "data-cy": "export-code-action-button"
                },
                on: { click: _vm.exportCodeTrigger }
              },
              [_vm._v(" " + _vm._s(_vm.exportCTA) + " ")]
            ),
            _c(
              "an-button",
              {
                staticClass: "justify-center",
                staticStyle: { color: "#fff" },
                attrs: { variant: "empty" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }