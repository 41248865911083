var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "title" }, [
      _vm._v("We'll miss you, " + _vm._s(_vm.firstName))
    ]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v(
        " Before you go, be advises that all projects and data will be permanently deleted for all users and you’ll not be able to undo this action. "
      )
    ]),
    _c(
      "div",
      { staticClass: "disclaimer" },
      [
        _c("div", { staticClass: "disclaimer-title" }, [
          _vm._v("Consider other options")
        ]),
        _vm._v(" Only want to stop paying? "),
        _c(
          "an-link",
          { attrs: { variant: "primary" }, on: { click: _vm.changePlan } },
          [_vm._v("Change plan")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c(
          "Checkbox",
          {
            model: {
              value: _vm.agreeDeleted,
              callback: function($$v) {
                _vm.agreeDeleted = $$v
              },
              expression: "agreeDeleted"
            }
          },
          [
            _vm._v(
              " All your account projects & data will be permanently deleted. "
            )
          ]
        ),
        _c(
          "Checkbox",
          {
            model: {
              value: _vm.agreeNoRestore,
              callback: function($$v) {
                _vm.agreeNoRestore = $$v
              },
              expression: "agreeNoRestore"
            }
          },
          [_vm._v(" We will NOT be able to restore your account. ")]
        )
      ],
      1
    ),
    _vm.showActions
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "an-button",
              {
                attrs: { isWorking: _vm.isWorking },
                on: { click: _vm.deleteUser }
              },
              [_vm._v("Delete account")]
            ),
            _c("an-link", { on: { click: _vm.openAccountModal } }, [
              _vm._v("Cancel")
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/falling-woman.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }