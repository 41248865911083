<template>
  <div class="project-row-container" @click="$emit('click')">
    <div class="project-details">
      <div class="thumb">
        <RoundedImage :width="105" :height="60" :src="thumbnail" showSlot>
          <div v-if="isLocked" class="locked">
            <svg-icon name="lock" :size="20" />
            <span>Project locked</span>
          </div>
        </RoundedImage>
      </div>
      <div class="info">
        <div class="name">{{ project.name }}</div>
        <div class="subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>
    <div class="members">
      <Members :members="members" :size="30" />
    </div>
    <div class="actions">
      <PopoverMenu :items="popoverActions" position="right" v-if="actions && actions.length">
        <div slot="reference" class="clickable-icon">
          <svg-icon name="three-dots-horizontal" :size="30" />
        </div>
      </PopoverMenu>
    </div>
  </div>
</template>

<script>
import RoundedImage from '../Upload/RoundedImage.vue';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import Members from '@/components/Members/Members';
import { getCdnUrl } from '@/utils/urls';

export default {
  data() {
    return {
      hoverSubtitle: false
    };
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    subtitle: {
      type: String
    },
    actions: {
      type: Array
    }
  },
  components: {
    RoundedImage,
    Members,
    PopoverMenu
  },
  computed: {
    thumbnail() {
      const { thumb_url: thumbUrl } = this.project;
      return getCdnUrl(thumbUrl, { size: 200 }) || require('@/assets/illustrations/empty-project.svg');
    },
    isLocked() {
      return !!this.project.is_locked;
    },
    popoverActions() {
      const { project, actions } = this;
      return actions.map(action => ({
        ...action,
        onClick: () => action.onClick(project)
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.project-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    border-radius: 10px;
    box-shadow: var(--shadow);
  }
}
.project-details {
  display: flex;
  align-items: center;
  width: 30%;
  .thumb {
    cursor: pointer;
  }
  .info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 20px;
    .name {
      @include secondary-title;
      @include ellipsis;
      max-width: 300px;
      font-size: 18px;
    }
    .subtitle {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.locked {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgba(59, 59, 59, 0.9);
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  color: var(--white);
}
.actions {
  .clickable-icon {
    opacity: 0.3;
  }
}
</style>
