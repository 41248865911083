<template>
  <div id="breakpoints-selector" class="c">
    <div class="spacer"></div>
    <div class="breakpoints">
      <v-tip :trigger="'hover'" offset="12" :delay="{ show: 400, hide: 0 }">
        <div
          id="responsive-breakpoint"
          @click="handleBreakpointChange({ id: 'res' })"
          class="breakpoint-container"
          :class="{ selected: activeBreakpoint.id == 'res' }"
        >
          <svg-icon fill="currentColor" class="br-icon" :size="20" name="responsive" />

          <div class="br-text">Responsive</div>
        </div>

        <template slot="popover">
          <div class="br-tip">
            <div class="br-tip-title">
              Responsive
            </div>
            <div class="br-tip-text">
              Adjusts View to current screen size with all available breakpoints.
            </div>
          </div>
        </template>
      </v-tip>

      <v-tip
        v-for="(br, i) in visibleBreakpoints"
        :key="i"
        :trigger="'hover'"
        offset="12"
        :delay="{ show: 400, hide: 0 }"
      >
        <div
          class="breakpoint-container"
          :class="{ selected: activeBreakpoint.id == br.id }"
          @click="handleBreakpointChange(br)"
        >
          <svg-icon fill="currentColor" class="br-icon" :size="20" :name="mapWidthToBreakpointIcon(br.width)" />
          <div class="br-text">{{ mapWidthToBreakpointText(br.width) }}</div>
        </div>

        <template slot="popover">
          <div class="br-tip">
            <div class="br-tip-title">
              {{ mapWidthToBreakpointText(br.width) }}
              <div class="br-tip-range">{{ mapWidthToBreakpointRange(br.width) }}</div>
            </div>
            <div class="br-tip-text">
              Adjusts view to {{ mapWidthToBreakpointText(br.width) }} screen size, depends on the design you synced.
            </div>
          </div>
        </template>
      </v-tip>
    </div>
    <div class="br-selector">
      <v-tip placement="bottom-center" :trigger="'click'" offset="12">
        <svg-icon class="icon" :size="20" fill="currentColor" name="three-dots-vertical"></svg-icon>

        <template slot="popover">
          <div class="br-pop">
            <div class="br-pop-title">
              Breakpoints
            </div>
            <div class="br-pop-body">
              <div style="padding:12px 16px" v-if="menuBreakpoints.length == 0">
                No additional breakpoints yet. Use Anima plugin to sync more breakpoints and enable viewing this screen
                in different screen sizes.
                <div class="flex items-center" style="padding: 4px 16px">
                  <a
                    href="https://medium.com/sketch-app-sources/how-to-add-breakpoints-to-your-sketch-prototype-4896bed5ebf2"
                    target="_blank"
                    class="learn-more"
                    >Learn how</a
                  >
                </div>
              </div>
              <template v-else>
                <div
                  v-close-popover
                  @click="handleBreakpointChange(br)"
                  :class="{ selected: activeBreakpoint.id == br.id }"
                  class="br-pop-item"
                  v-for="br in menuBreakpoints"
                  :key="br.id"
                >
                  <svg-icon
                    style="margin-right:5px"
                    fill="currentColor"
                    class="br-icon"
                    :size="20"
                    :name="mapWidthToBreakpointIcon(br.width)"
                  />
                  <span>{{ mapWidthToBreakpointRange(br.width) }}</span>

                  <span style="margin-left:auto;text-transform: capitalize;">{{
                    mapWidthToBreakpointText(br.width)
                  }}</span>
                </div>
                <div style="padding: 12px 16px">
                  Use Anima plugin to sync more breakpoints and enable viewing this screen in different screen sizes.
                </div>
                <div class="flex items-center" style="padding: 4px 16px">
                  <a
                    href="https://medium.com/sketch-app-sources/how-to-add-breakpoints-to-your-sketch-prototype-4896bed5ebf2"
                    target="_blank"
                    class="learn-more"
                    >Learn how</a
                  >
                </div>
              </template>
            </div>
          </div>
        </template>
      </v-tip>
    </div>
    <div class="spacer"></div>
    <div class="width-indicator">
      <span @click="toggleEditableWidth" v-show="!isEditableWidth">{{ currentFrameWidth }} </span>
      <input
        type="text"
        @input="limit"
        @blur="submitNewWidth"
        @keypress="submitNewWidth"
        ref="widthInputRef"
        @focus="handleWidthFocus"
        v-show="isEditableWidth"
        class="widthInput"
        v-model="newWidth"
        maxlength="4"
      />
      <span class="unit">px</span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  mapWidthToBreakpointIcon,
  mapWidthToBreakpointText,
  mapWidthToBreakpointRange
} from '@/components/OmniView/utils';
// import GhostInput from '@/components/Input/GhostInput.vue';

export default {
  components: {
    // GhostInput
  },
  data() {
    return {
      newWidth: '0',
      isEditableWidth: false
    };
  },
  computed: {
    ...mapState('components', { allComponents: 'items' }),
    ...mapState('projects', { currentProject: 'currentItem' }),

    ...mapGetters({
      breakpoints: 'omniview/breakpoints',
      activeBreakpoint: 'omniview/activeBreakpoint',
      currentFrameWidth: 'omniview/currentFrameWidth'
    }),
    menuBreakpoints() {
      if (this.breakpoints.length > 3) {
        return this.breakpoints.slice(3);
      } else {
        return [];
      }
    },
    visibleBreakpoints() {
      if (this.breakpoints.length > 3) return this.breakpoints.slice(0, 3);
      return this.breakpoints;
    }
  },
  mounted() {
    EventBus.$on('handleBreakpointChange', this.handleBreakpointChange);
  },
  destroyed() {
    EventBus.$off('handleBreakpointChange', this.handleBreakpointChange);
  },
  methods: {
    mapWidthToBreakpointText,
    mapWidthToBreakpointIcon,
    mapWidthToBreakpointRange,
    ...mapMutations({
      setIsCompareEnabled: 'omniview/setIsCompareEnabled'
    }),
    toggleEditableWidth() {
      const { widthInputRef } = this.$refs;
      this.newWidth = `${this.currentFrameWidth}`;
      this.isEditableWidth = true;
      this.$nextTick(() => {
        widthInputRef.focus();
      });
    },
    limit(e) {
      if (e.target.value > 4) this.value = e.target.value = e.target.value.slice(0, 4);
    },
    handleWidthFocus() {},
    submitNewWidth(e) {
      let x = e.keyCode || e.which;
      if (
        (isNaN(String.fromCharCode(e.which)) && x != 46) ||
        x === 32 ||
        x === 13 ||
        (x === 46 && event.currentTarget.innerText.includes('.'))
      ) {
        e.preventDefault();
      }

      if (x == 13) {
        this.isEditableWidth = false;
        if (this.newWidth) {
          this.handleBreakpointChange({ id: 'res', width: this.newWidth });
        }
      }
    },
    handleBreakpointChange(breakpoint) {
      this.setIsCompareEnabled(false);
      this.$store.commit('omniview/setActiveBreakpoint', breakpoint);
      this.$emit('breakpointChange', breakpoint);
      if (breakpoint.id == 'res') {
        // fixes offset in mainframe
        if (breakpoint.width) {
          EventBus.$emit('setCustomWith', this.newWidth);
        } else {
          setTimeout(() => {
            EventBus.$emit('update-offset');
          }, 200);
        }
      } else {
        // this.handleScreenChange(breakpoint.component);
        this.$emit('handleScreenChange', {
          component: breakpoint.component,
          fetchBreakpoints: false
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.c {
  display: flex;
  align-items: center;
}
.breakpoints {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0 3px;
}
.spacer {
  flex-shrink: 0;
  width: 1px;
  height: 60px;
  background: rgba(#fff, 0.3);
  border-radius: 100px;
}
.br-selector {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  .icon {
    color: rgba(#fff, 0.3);
  }

  &:hover {
    cursor: pointer;
    .icon {
      color: #fff;
    }
  }
}
.breakpoint-container {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  opacity: 0.4;
  color: white;
  padding: 0 10px;

  .br-text {
    font-size: 12px;
    text-transform: capitalize;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    .br-icon,
    .br-text {
      color: white;
    }
  }
  &.selected {
    opacity: 1;
    .br-icon,
    .br-text {
      color: var(--primary);
    }
  }
}

.width-indicator {
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 66px;
  span {
    color: white;
    font-size: 14px;
  }

  .unit {
    font-size: 12px;
    color: rgba(#fff, 0.3);
    margin-left: 5px;
  }
}

.widthInput {
  background: #2d2d2d;
  padding: 0 5px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  color: white;
  width: 100%;
  &:focus {
    outline: none;
  }
}

.widthInput::-webkit-outer-spin-button,
.widthInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.widthInput[type='number'] {
  -moz-appearance: textfield;
}

.br-tip {
  max-width: 207px;
  padding: 16px;
  background: #333333;
  color: white;
  border-radius: 10px;
}

.br-tip-title {
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.br-tip-range {
  font-size: 12px;
  opacity: 0.4;
}
.br-tip-text {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.4;
}
.br-tip-link {
  margin-left: auto;
  font-size: 12px;
  color: #ff6250;
}

.br-pop {
  background: #3b3b3b;
  width: 260px;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);

  &-title {
    padding: 12px 16px;
    box-shadow: 0 1px 0 0 #2d2d2d;
    font-size: 14px;
  }
  &-body {
    font-size: 12px;
    color: rgba(#fff, 0.4);
    .learn-more {
      color: var(--primary);
      margin-left: auto;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 #2d2d2d;
    padding: 12px 16px;
    transition: background 0.2s;

    cursor: pointer;
    &:hover {
      background: #2d2d2d;
    }

    &.selected {
      background: #2d2d2d;
    }
  }
}
</style>
