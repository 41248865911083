<template>
  <div>
    <div class="project-header-container">
      <Header :isWorking="loading" />
    </div>
    <div>
      <div class="navigation">
        <Tabs
          data-cy="project-view-tabs"
          v-if="tabs.length > 0"
          :activeTabLabel="activeTabLabel"
          :items="tabs"
          @change="onTabChange"
        />
        <template v-if="!isFigmaSyncing">
          <PopoverMenu :items="sortingOptions" position="right" :selectedLabel="sortByLabel">
            <div slot="reference" class="sort-select">
              {{ sortByLabel }}
              <svg-icon name="select-arrow-down" :size="24" />
            </div>
          </PopoverMenu>
        </template>

        <div data-cy="figma-import-progress" v-else class="flex items-center progress-bar-container">
          <ProgressBar :progress="figmaImportProgress" :height="10" backgroundColor="rgba(255, 98, 80, 0.3)" />
          <div class="progress-bar-text flex items-center">
            <svg-icon style="margin-right:10px" fill="currentColor" class="icon-spin" name="spin" :size="20"></svg-icon>
            <span>{{ figmaImportStatus }}</span>
          </div>
        </div>
      </div>

      <template v-if="isFigmaSyncing">
        <div class="figma-grid">
          <ScreenCard
            :style="{ ...{ opacity: 1, pointerEvents: 'none', userSelect: 'none' }, minHeight: '100px' }"
            v-for="(frame, index) in figmaFrames"
            :key="index"
            :screen="frame"
            class="relative"
            :actions="[]"
            :cta="'Building screen...'"
            readOnly
          >
            <template slot="overlay">
              <div class="overlay flex items-center justify-center"></div>
            </template>
            <!-- <template slot="cta">
              <div class="flex flex-col">
                <div>Building ....</div>
                <div class="flex items-center justify-center w-full" style="padding:20px">
                  <svg-icon fill="currentColor" class="icon-spin" name="spin" :size="30"></svg-icon>
                </div>
              </div>
            </template> -->
            <template slot="subtitle">
              <div class="flex items-center w-full">
                <!-- <div>Building screen...</div> -->
                <svg-icon
                  style="margin-left:auto;color:var(--primary)"
                  fill="currentColor"
                  class="wrench"
                  name="rocket"
                  :size="20"
                />
              </div>
            </template>
          </ScreenCard>
        </div>
      </template>

      <template v-else>
        <div class="filters-bar">
          <span>{{ totalItems }}</span>
          <div class="search" v-if="isDesktop && !!screens.length">
            <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
          </div>
        </div>
        <div class="project-page-content">
          <transition name="fadeIn" mode="out-in">
            <LoadingScreen
              v-if="loading"
              :type="isPollingSyncing ? 'syncing' : 'default'"
              :text="isPollingSyncing ? 'Syncing project' : null"
            />
            <CardsGrid
              v-else-if="!!cardItems.length"
              :itemsType="type"
              :items="cardItems"
              :actions="actions"
              :sortBy="sortBy"
              :filteredResults="!!searchQuery"
              :totalAmount="totalLiveComponents"
              :isLoadingMore="loadingMore"
              @load-more="loadMore"
              @click-screen="navigateToScreen"
              @click-component="navigateToComponent"
            />
            <NoSearchResults v-else-if="!!searchQuery" />
            <EmptyState :type="type" v-else />
          </transition>
        </div>

        <div class="multi-select-bar-container" v-if="selection.length > 0">
          <MultiSelectBar
            :selection="selection"
            :selectionState="selection.length < cardItems.length ? 'some' : 'all'"
            type="screen"
            @check-all="selectAll"
            @uncheck="clearSelection"
            @delete="deleteSelected"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { isEmpty, omit } from 'lodash-es';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import Header from '@/components/Project/Header/Header';
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import CardsGrid from '@/components/CardsGrid/CardsGrid';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import EmptyState from '@/components/Project/EmptyState';
import NoSearchResults from '@/components/Team/EmptyState/NoSearchResults';
import SearchInput from '@/components/SearchInput/SearchInput';
import Tabs from '@/components/Layout/Tabs/Tabs';
import copy from '@/utils/copyToClp';
import { EventBus, openModal, toastError, toastSuccess } from '@/services/bus';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import { filterArrayBySearchQuery, updateArrayItemById } from '@/utils/javascript';
import { SocketMixin, UserMixin } from '@/mixins';
import MultiSelectBar from '@/components/Project/MultiSelectBar.vue';
// import FigmaCard from '@/views/FigmaFileImport/FigmaCard.vue';
import ScreenCard from '@/components/Project/ScreenCard.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

const defaultTabLabel = 'Screens';

const emptyStateBody =
  'Use the Anima plugin to sync your design to your project and share your awesome prototypes and code with your team.';
const emptyStateTitle = 'Sync a design to your project';

export default {
  data() {
    return {
      screenActions: [
        { label: 'Copy link', onClick: this.copyScreenLink },
        { label: 'Delete', onClick: this._deleteScreen, isMarked: true }
      ],
      componentActions: [
        { label: 'Copy link', onClick: this.copyComponentLink },
        { label: 'Delete', onClick: this._deleteComponent, isMarked: true }
      ],
      type: 'screen',
      searchQuery: '',
      sortBy: 'updated',
      activeTabLabel: defaultTabLabel,
      loading: false,
      loadingMore: false,
      isPollingSyncing: false,
      showDeleteModal: false,
      emptyStateTitle,
      emptyStateBody,
      selection: [],
      figmaCallbackInterval: null,
      interval: null
    };
  },
  components: {
    Header,
    Tabs,
    LoadingScreen,
    CardsGrid,
    EmptyState,
    SearchInput,
    NoSearchResults,
    MultiSelectBar,
    PopoverMenu,
    // FigmaCard,
    ScreenCard,
    ProgressBar
  },
  mixins: [SocketMixin, UserMixin],

  created() {
    EventBus.$on('on-figma-ws-message', this.handleFigmaWebSocketMessage);
    EventBus.$on('start_figma_import_polling', this.pollFigmaStatus);
  },

  async mounted() {
    EventBus.$on('reload-project-data', this.reloadData);
    EventBus.$on('select-card-item', this.modifySelection);
    const { params, query } = this.$route;
    const projectSort = localStorage.getItem('projectSort');

    if (query.sort) {
      this.setSortBy(query.sort);
    } else if (projectSort) {
      this.setSortBy(projectSort);
    }

    if (query.platform) this.trackUserSyncData(query.platform);

    if (!params.teamSlug) {
      this.loading = true;
      const project = await this.fetchProject({ id: params.projectId });
      let { short_id: projectId, team_slug: teamSlug, team: teamId } = project;
      if (!teamSlug) {
        const { slug } = await this.fetchTeam({ id: teamId });
        teamSlug = slug;
      }
      this.$router.push({ name: 'project', params: { teamSlug, projectId } });
    }
  },
  destroyed() {
    EventBus.$off('reload-project-data', this.reloadData);
    EventBus.$off('select-card-item', this.modifySelection);
    EventBus.$off('on-figma-ws-message', this.handleFigmaWebSocketMessage);
    EventBus.$off('start_figma_import_polling', this.pollFigmaStatus);

    if (this.figmaCallbackInterval) {
      clearInterval(this.figmaCallbackInterval);
    }
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapState('webComponents', { _components: 'items' }),
    ...mapState('webComponents', { totalComponents: 'total' }),
    ...mapState('figmaIntegration', { isFigmaSyncingMap: 'isSyncing' }),
    ...mapState('figmaIntegration', { figmaFrames: 'frames' }),
    ...mapState('figmaIntegration', { figmaImportStatus: 'figmaImportStatus' }),
    ...mapState('figmaIntegration', { figmaImportProgress: 'figmaImportProgress' }),
    ...mapGetters({
      screens: 'components/linkedScreens',
      totalScreens: 'components/totalLinkedScreens',
      hasNewComments: 'notifications/hasNewComments',
      isActiveExperiment: 'experiments/isActive'
    }),
    isFigmaSyncing() {
      const { projectId } = this.$route.params;
      return this.isFigmaSyncingMap[projectId];
    },
    totalLiveComponents() {
      return this.type === 'screen' ? this.totalScreens : this.cardItems.length;
    },
    tabs() {
      const screensTab = { label: 'Screens', type: 'screen', routeName: 'project' };
      const componentsTab = { label: 'Components', type: 'component', routeName: 'project-components' };

      if (this.isFigmaSyncing) return [];

      return [screensTab, componentsTab];
    },
    cardItems() {
      let list;
      const slugs = this.screens.map(s => s.slug);
      if (this.type === 'screen') {
        list = this.screens.map(this.mapScreens);
      } else {
        list = this._components.filter(c => {
          let p = c.instances.find(i => i['is_primary']);
          return p && slugs.includes(p['screen_slug']) && (c['is_live'] || c['is_suggestion']);
        });
      }

      if (this.searchQuery) {
        list = filterArrayBySearchQuery(list, ['name'], this.searchQuery);
      }

      return list;
    },
    sortingOptions() {
      const options = [
        { label: 'Last updated', value: 'updated', onClick: () => this.setSortBy('updated') },
        { label: 'Date created', value: 'created', onClick: () => this.setSortBy('created') },
        { label: 'Name A-Z', value: 'name', onClick: () => this.setSortBy('name') }
      ];
      if (this.type === 'screen') {
        options.push({ label: 'Sections', value: 'sections', onClick: () => this.setSortBy('sections') });
      }
      return options;
    },
    sortByLabel() {
      const { sortBy, sortingOptions } = this;
      const option = sortingOptions.find(so => so.value === sortBy);
      return option?.label;
    },
    omniviewRoute() {
      return ({ slug: screenSlug, model_id: modelId }, params = {}) => {
        const { short_id: projectId } = this.project;
        const { slug: teamSlug } = this.team;
        const { role: userRole } = this.user;
        if (userRole === 'developer' && this.isActiveExperiment('omniview-default-mode-for-developers')) {
          return {
            name: 'omniview',
            params: { teamSlug, projectId, screenSlug, ...params },
            query: { mode: 'code', layer: modelId }
          };
        }
        return { name: 'omniview', params: { teamSlug, projectId, screenSlug, ...params } };
      };
    },
    webComponentRoute() {
      return wc => {
        const { short_id: projectId } = this.project;
        const { slug: teamSlug } = this.team;

        let screenSlug;
        let model_id = -1;

        const primaryInstance = wc.instances.find(i => i.is_primary);
        screenSlug = primaryInstance['screen_slug'];
        model_id = primaryInstance['model_id'];

        if (!screenSlug) {
          const { live_project_release_homepage_slug: homepageSlug } = this.project;
          const correctHomepageSlug = this.screens.some(screen => screen.slug === homepageSlug);
          const screenSlug = correctHomepageSlug ? homepageSlug : this.screens[0]?.slug;

          if (!screenSlug) {
            return toastError('Please add screens to this project');
          }
        }

        return {
          name: 'omniview',
          params: { teamSlug, projectId, screenSlug },
          query: { mode: 'code', component: model_id, layer: model_id }
        };
      };
    },
    screenParams() {
      const { sort, v: project_release_version } = this.$route.query;
      return { get_all: true, order_by: this.getSortingType(sort), project_release_version };
    },
    componentParams() {
      return { ...omit(this.screenParams, 'project_release_version') };
    },
    actions() {
      if (this.type === 'screen') {
        return this.screenActions;
      }
      return this.componentActions;
    },
    totalItems() {
      const amount = this.type === 'screen' ? this.totalScreens : this.totalLiveComponents;
      return `${amount} ${this.type}s`;
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      fetchProject: 'projects/fetchOne',
      addToRecentProjects: 'projects/addToRecentProjects',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchProjectReleases: 'projectReleases/fetchAllOfParent',
      fetchRelease: 'releases/fetchOne',
      fetchScreens: 'components/fetchAllOfParent',
      fetchComponents: 'webComponents/fetchAllOfParent',
      deleteScreen: 'components/delete',
      deleteWebComponent: 'webComponents/delete',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchReleaseModel: 'releases/fetchReleaseModel',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      pollSyncingProject: 'projects/pollSyncingProject',
      createGuest: 'projectGuests/create',
      getTaskCallback: 'figmaIntegration/getTaskCallback'
    }),
    ...mapMutations({
      selectScreen: 'components/setCurrentItem',
      removeScreenFromStore: 'components/removeFromItems',
      setTeam: 'teams/setCurrentItem',
      setProject: 'projects/setCurrentItem',
      setTeamMemberships: 'teamMemberships/setTeamMemberships',
      setCurrentTeamMembership: 'teamMemberships/setCurrentTeamMembership',
      setSections: 'components/setSections',
      setFigmaFrames: 'figmaIntegration/setFrames',
      setIsFigmaSyncing: 'figmaIntegration/setIsSyncing',
      setFigmaImportStatus: 'figmaIntegration/setFigmaImportStatus',
      setFigmaImportProgress: 'figmaIntegration/setFigmaImportProgress',
      setIsFetchingThumbnails: 'figmaIntegration/setIsFetchingThumbnails'
    }),
    pollFigmaStatus(projectId = null) {
      return new Promise((resolve, reject) => {
        if (!projectId) {
          projectId = this.$route.params.projectId;
        }
        this.interval && clearInterval(this.interval);
        this.interval = setInterval(async () => {
          try {
            const res = await this.checkIfFigmaImportSyncing();
            console.warn(res);
            resolve(res);
            const { code, frames } = res.data || { frames: [] };

            if (code == 'not_found' || code == 'complete') {
              await this.pollSyncingProject({ id: projectId });
              this.setIsFigmaSyncing({
                value: false,
                projectId
              });
              clearInterval(this.interval);
            }

            if (code == 'pending') {
              if (frames.length > 0) {
                const figmaFrames = frames
                  .filter(Boolean)
                  // .map(frame => ({ ...frame, thumb_url: frame.thumbnailUrl, isSyncing: true }));
                  .map(frame => ({
                    ...frame,
                    thumb_url: frame.thumbnailUrl,
                    isSyncing: true
                  }));

                let map = {};
                this.figmaFrames.map(f => {
                  map[f.id] = f;
                });

                this.setFigmaFrames(
                  figmaFrames.map(f => ({ ...f, thumb_url: map[f.id]['thumb_url'] || map[f.id]['thumbnailUrl'] }))
                );
                this.setIsFigmaSyncing({
                  value: true,
                  projectId
                });
              }
            }

            if (code == 'error') {
              this.setIsFigmaSyncing({
                value: false,
                projectId
              });
              clearInterval(this.interval);
            }
          } catch (error) {
            clearInterval(this.interval);
            reject(error);
          }
        }, 2000);
      });
    },
    async handleFigmaWebSocketMessage(e) {
      const { projectId } = this.$route.params;
      if (!e.eventType) return;
      const data = JSON.parse(e.data);
      console.warn(e.eventType, data);
      switch (e.eventType) {
        case 'get_thumbnails_end':
          {
            const { thumbnails, pageId } = data || {};

            if (thumbnails && pageId) {
              this.setFigmaFrames(
                this.figmaFrames.map(f => ({ ...f, thumb_url: thumbnails[f.id] ? thumbnails[f.id] : f.thumb_url }))
              );

              this.setIsFetchingThumbnails({
                value: false,
                pageId
              });
            }
          }

          break;

        case 'generate_file_start':
          {
            this.setFigmaImportProgress(0.15);
            this.setFigmaImportStatus('Building screens');
          }

          break;
        case 'build_assets_start':
          {
            this.setFigmaImportProgress(0.5);
            this.setFigmaImportStatus('Exporting assets');
          }

          break;

        case 'upload_assets_start':
          {
            this.setFigmaImportProgress(0.6);
            this.setFigmaImportStatus('Uploading assets');
          }

          break;
        case 'upload_assets_end':
          {
            this.setFigmaImportProgress(0.8);
            this.setFigmaImportStatus('Syncing release');
          }

          break;
        case 'build_child_done':
          {
            const { frameId } = data;
            console.warn(frameId);
            const updatedFrames = updateArrayItemById(this.figmaFrames, frameId, { isSyncing: false }, 'id');
            this.setFigmaFrames(updatedFrames);
          }

          break;
        case 'import_end':
          {
            // const { frameId } = data;
            await this.pollSyncingProject({ id: projectId, waitBeforeRun: true });
            this.setFigmaImportProgress(0.9);
            this.setFigmaImportStatus('Finishing things up');
            // await this.reloadData();
            await Promise.all([
              this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, skipCache: true }),
              this.fetchComponents({ parent: 'projects', id: projectId, params: this.componentParams, skipCache: true })
            ]);
            this.setIsFigmaSyncing({
              value: false,
              projectId
            });
          }

          break;

        default:
          break;
      }
    },

    async reloadData() {
      await this.fetchData({ skipCache: true });
    },
    getSortingType(sortBy) {
      const fallbackSort = localStorage.getItem('projectSort');
      const sortMap = {
        updated: '-updated_at',
        created: '-created_at',
        name: 'name',
        sections: 'sections'
      };
      return sortMap[sortBy] || sortMap[fallbackSort] || sortMap.updated;
    },
    onTabChange(tab) {
      this.activeTabLabel = tab.label;
      this.type = tab.type;
      this.$router.replace({ name: tab.routeName }, () => {});
    },
    async fetchData({ skipCache = false } = {}) {
      const { teamSlug, projectId } = this.$route.params;
      const { v, invite } = this.$route.query;
      const parent = 'projects';

      this.loading = true;
      const transaction = this.$sentry.startTransaction({ name: 'project-page-loading' });

      try {
        // if user gets directly to project page, load team information, should be async.
        this.loadTeamInformation(teamSlug);

        // also load project guests I guess.
        this.loadGuests({ projectId, skipCache });

        // SYNCHRONOUS REQUESTS:
        // - project
        // - if project is syncing - poll request the project until is not syncing anymore.
        // - project components (screens)

        await this.pollFigmaStatus().catch(e => console.log(e));

        await this.fetchProjectIfHasAccess({ projectId, skipCache });

        if (invite) {
          this.handleInvite(invite, projectId);
        }

        if (isEmpty(this.project) || this.project.is_syncing || isEmpty(this.screens)) {
          this.isPollingSyncing = this.project.is_syncing;
          await this.pollSyncingProject({ id: projectId });
        }

        // now that the project is done syncing, fetch components.
        await Promise.all([
          this.fetchScreens({ parent, id: projectId, params: this.screenParams, skipCache }),
          this.fetchComponents({ parent, id: projectId, params: this.componentParams, skipCache })
        ]);

        // if the user sorted by section in the old app change the sorting to sections
        const sortCheck = localStorage.getItem('sectionsSortCheck');
        if (!sortCheck && this.project.sections_sort.sections && this.project.sections_sort.sections.length > 0) {
          this.setSortBy('sections');
          localStorage.setItem('sectionsSortCheck', 'true');
        }
        // set sections
        this.setSections(this.project.sections_sort.sections || []);

        // add to the user's recently used projects list
        this.addToRecentProjects({ project_short_id: this.project.short_id });

        // rest of the data can be fetched asynchronously.
        // Will not throw an error in case of failure!
        this.fetchLiveProjectRelease({ version: v, skipCache });
        this.fetchCustomDomains({ parent, id: projectId, skipCache });
        this.fetchProjectReleases({ parent, id: projectId, skipCache });
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.isPollingSyncing = false;
        transaction.finish();
      }
    },
    async checkIfFigmaImportSyncing() {
      return new Promise((resolve, reject) => {
        const { projectId } = this.$route.params;
        this.getTaskCallback({
          payload: { taskId: projectId, taskDetails: true }
        })
          .then(resolve)
          .catch(reject);
      });
    },
    async loadMore() {
      try {
        const { projectId } = this.$route.params;
        this.loadingMore = true;
        this.$trackEvent('project-page.load-more.click');
        if (this.type === 'screen') {
          await this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, nextPage: true });
        } else {
          await this.fetchComponents({
            parent: 'projects',
            id: projectId,
            params: this.componentParams,
            nextPage: true
          });
        }
      } catch (err) {
        toastError('Failed loading more screens :(');
      } finally {
        this.loadingMore = false;
      }
    },
    async fetchProjectIfHasAccess({ projectId, skipCache } = {}) {
      try {
        await this.fetchProject({ id: projectId, skipCache });
      } catch (err) {
        const { response = {} } = err;
        if (response.status === 403) {
          openModal({ name: 'project-request-access', onCloseRedirect: '/' });
        }
        throw err;
      }
    },
    async fetchLiveProjectRelease({ version: revision_number, skipCache = false }) {
      const { live_project_release, short_id } = this.project;
      if (revision_number) {
        const params = { revision_number };
        const { results: projectReleases } = await this.fetchProjectReleases({
          parent: 'projects',
          id: short_id,
          params,
          skipCache
        });

        const { release } = projectReleases[0] ?? {};
        await this.fetchRelease({ id: release });
      } else if (live_project_release) {
        const { release } = await this.fetchProjectRelease({ id: live_project_release, skipCache });
        await this.fetchRelease({ id: release });
      }
      if (this.currentRelease.id) {
        this.fetchReleaseModel();
      }
    },
    loadGuests({ projectId, skipCache = true } = {}) {
      return this.fetchProjectGuests({
        parent: 'projects',
        id: projectId,
        params: { page_size: 200, order_by: 'created_at' },
        skipCache
      });
    },
    async handleInvite(inviteEmail, projectId) {
      const email = normalizeEmail(inviteEmail);
      try {
        this.$trackEvent('project.invite-guest-from-email');

        const { results: guests } = await this.loadGuests({ projectId, skipCache: false });
        const guestAlreadyExists = !!guests.find(g => normalizeEmail(g.email) === email);

        if (guestAlreadyExists) {
          toastError(`It seems ${email} is already a guest in this project...`);
        } else if (isValidEmail(email)) {
          const newGuest = { email, access_level: 'viewer' };
          await this.createGuest({ parent: 'projects', id: projectId, payload: newGuest });

          this.$trackEvent('project.invite-guest-from-email.success');
          toastSuccess(`Successfully added ${email} as a guest on ${this.project.name}`);

          this.$router.replace({ query: {} });
          await this.loadGuests({ projectId, skipCache: true });
        }
      } catch (err) {
        console.error(err);
        this.$trackEvent('project.invite-guest-from-email.failure');
        toastError(`Oh no! We failed inviting ${email} as a guest!`);
      }
    },
    async loadTeamInformation(teamSlug) {
      try {
        const teamParams = { is_slug: true };
        await this.fetchTeam({ id: teamSlug, params: teamParams });
        await this.fetchTeamMemberships({ id: teamSlug, params: teamParams });
        this.setCurrentTeamMembership(this.user);
      } catch (err) {
        this.setTeam({});
        this.setTeamMemberships();
      }
    },
    getThumbUrl(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      const thumb = thumbnails_urls && thumbnails_urls['640x640'];
      return thumb || thumb_url || thumbnail_url;
    },
    navigateToScreen(component) {
      let breakpoint;
      this.$trackEvent('screen-card.thumbnail.click');
      this.selectScreen(component);
      if (component.linkedScreens?.length) breakpoint = 'res';
      this.$router.push(this.omniviewRoute(component, { breakpoint }));
    },
    navigateToComponent(component) {
      this.$trackEvent('component-card.thumbnail.click');
      this.$router.push(this.webComponentRoute(component));
    },
    async copyScreenLink(component) {
      this.$trackEvent('screen-card.quick-action-copy-link.click');

      const resolved = this.$router.resolve(this.omniviewRoute(component));
      const link = `${process.env.APP_BASE_URL}${resolved.href}`;
      copy(link);

      toastSuccess('Linked copied.');
    },
    async copyComponentLink(component) {
      this.$trackEvent('component-card.quick-action-copy-link.click');

      const resolved = this.$router.resolve(this.webComponentRoute(component));
      const link = `${process.env.APP_BASE_URL}${resolved.href}`;
      copy(link);

      toastSuccess('Linked copied.');
    },
    async _deleteScreen(screen) {
      const { projectId } = this.$route.params;
      const { id } = screen;

      this.$trackEvent('screen-card.quick-action-delete.click');

      try {
        this.loading = true;
        await this.deleteScreen({ id });
        this.fetchScreens({ parent: 'projects', id: projectId, params: this.screenParams, skipCache: true });
      } catch (err) {
        toastError('Failed deleting screen');
      } finally {
        this.loading = false;
      }
    },
    async _deleteComponent(component) {
      const { projectId } = this.$route.params;
      const { id } = component;

      this.$trackEvent('component-card.quick-action-delete.click');

      try {
        this.loading = true;
        await this.deleteWebComponent({ id });
        this.fetchComponents({ parent: 'projects', id: projectId, params: this.componentParams, skipCache: true });
      } catch (err) {
        toastError('Failed deleting component');
      } finally {
        this.loading = false;
      }
    },
    setSortBy(value) {
      const sortingOption = this.sortingOptions.find(s => s.value === value?.toLowerCase());
      if (sortingOption) {
        this.sortBy = sortingOption.value;
      } else {
        this.sortBy = 'created';
      }
    },
    toggleSearchOpen(value) {
      if (value) {
        this.$trackEvent('project-page.search.open');
      }
    },
    isSelected(item) {
      return this.selection.includes(item.id);
    },
    modifySelection(item) {
      const selectionSet = new Set(this.selection || []);
      if (this.isSelected(item)) {
        selectionSet.delete(item.id);
      } else {
        const linkedScreens = item.linkedScreens || [];
        const ids = [item.id, ...linkedScreens.map(ls => ls.id)];
        if (this.selection.length === 0) {
          this.$trackEvent('project-page.multi-select-bar.display');
        }
        ids.forEach(id => selectionSet.add(id));
      }
      this.selection = Array.from(selectionSet);
    },
    selectAll() {
      this.$trackEvent('project-page.multi-select-bar-select-all.click');
      this.selection = this.cardItems.flatMap(item => {
        return [item.id, ...item.linkedScreens?.map(ls => ls.id)];
      });
    },
    clearSelection() {
      this.selection = [];
    },
    async deleteSelected() {
      try {
        this.loading = true;
        this.$trackEvent('project-page.multi-select-delete.click');
        const promises = this.selection.map(async id => {
          await this.deleteScreen({ id });
          this.removeScreenFromStore({ id });
        });
        await Promise.all(promises);
        this.$trackEvent('project-page.multi-select-delete.success');
      } catch (err) {
        this.$trackEvent('project-page.multi-select-delete.failure', { message: err.message });
        toastError('Failed to delete some screens');
        console.error(err);
      } finally {
        this.loading = false;
        this.clearSelection();
        setTimeout(() => this.fetchData(true), 500);
      }
    },
    setSocket() {
      this.openSocket(this.project?.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'project', action: 'updated' }, this.setProject);
      this.socket.on({ resource: 'project', action: 'sync' }, () => {
        this.fetchScreens({
          parent: 'projects',
          id: this.project?.id,
          params: this.screenParams,
          skipCache: true
        });
      });
      this.socket.on({ resource: 'component', action: 'updated' }, () => {
        this.fetchScreens({
          parent: 'projects',
          id: this.project?.id,
          params: this.screenParams,
          skipCache: true
        });
      });
    },
    onRouteChange() {
      const { name } = this.$route;
      const activeTab = this.tabs.find(t => t.routeName === name);
      if (activeTab) {
        this.onTabChange(activeTab);
        this.setSortBy(this.sortBy);
        this.fetchData();
      }
    },
    mapScreens(screen) {
      if (screen.linkedScreens?.length) {
        screen.linkedScreens = screen.linkedScreens.map(this.mapScreens);
      }
      return {
        ...screen,
        thumb_url: this.getThumbUrl(screen),
        isSelected: this.isSelected(screen),
        flags: {
          hasNewComments: this.hasNewComments({ id: screen.id, type: 'component' })
        }
      };
    }
  },
  watch: {
    $route: {
      handler: 'onRouteChange',
      immediate: true
    },
    sortBy(newValue) {
      localStorage.setItem('projectSort', newValue);
      const query = { ...this.$route.query, sort: newValue };
      this.$router.replace({ query }, () => {});
    },
    project: {
      handler: 'setSocket',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_navigationBar.scss';
.project-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.filters-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  @include desktop {
    padding: 0 10px;
    margin: 30px 0 40px;
  }
  @include mobile {
    padding: 0 20px;
  }
  .search {
    margin-left: 30px;
  }
}
.project-page-content {
  @include mobile {
    padding: 20px;
  }
}
.screens {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
}
.screens > .screen {
  margin: 16px;
}
.sort-select {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.multi-select-bar-container {
  position: absolute;
  width: 450px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.figma-grid {
  margin-top: 30px;
  // display: grid;
  // grid-gap: 32px 32px;
  // user-select: none;
  // width: 100%;
  // box-sizing: border-box;
  // flex-flow: row wrap;
  // align-content: stretch;
  // cursor: default;
  // grid-template-columns: repeat(auto-fill, 260px);

  display: grid;
  justify-content: start;
  gap: 40px 30px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.progress-bar-container {
  width: 250px;
  flex-direction: column;
  padding: 40px 0;
  width: 100%;
  .progress-bar-text {
    margin-top: 20px;
    font-family: 'Roslindale Deck';
    opacity: 0.4;
    // opacity: 0;
  }
}

.wrench {
  transform-origin: center;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-name: shake;
}

@keyframes wrench {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shake {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-2px, -2px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.1);
  z-index: 99;
}
</style>
