export const findAndRemoveNode = (root, nodeWhiteList) => {
  let shouldRemove;

  if (!nodeWhiteList.includes(root.nodeID)) {
    shouldRemove = true;
  }

  for (let i = 0; i < root.subviews.length; i++) {
    const node = root.subviews[i];

    if (nodeWhiteList.some(id => findNode(id, node))) {
      shouldRemove = false;
    }

    if (shouldRemove) {
      root.subviews[i] = { ...root.subviews[i], subviews: [] };
    } else {
      findAndRemoveNode(node, nodeWhiteList);
    }
  }
};
export const removeModelLeafs = (root, nodeWhiteList) => {
  let i = 0;
  restartLoop: while (i < root.subviews.length) {
    let node = root.subviews[i];
    if (!nodeWhiteList.includes(node.modelID) && node.subviews.length == 0) {
      root.subviews.splice(i, 1);
      i = 0;
      continue restartLoop;
    }
    removeModelLeafs(node, nodeWhiteList);
    i++;
  }
};

export const findNode = (id, currentNode) => {
  let i, currentChild, result;
  if (id == currentNode.modelID) {
    return currentNode;
  } else {
    for (i = 0; i < currentNode.subviews.length; i++) {
      currentChild = currentNode.subviews[i];
      result = findNode(id, currentChild);
      if (result !== false) {
        return result;
      }
    }
    return false;
  }
};
export const findAndMatch = (node, ids) => {
  const matches = [];

  const checkForMatches = _node => {
    let i, currentChild, result;
    for (i = 0; i < _node.subviews.length; i++) {
      currentChild = _node.subviews[i];

      if (ids.includes(currentChild.modelID)) {
        matches.push(currentChild.modelID);
      }
      result = checkForMatches(currentChild);
      if (result !== false) {
        return result;
      }
    }
    return false;
  };

  checkForMatches(node);

  return matches;
};

export const getNodeMd5Array = node => {
  const addedLayersIds = [];
  const md5s = [];

  if (node.model_class == 'ADModelImageView') {
    md5s.push(node.md5);
  }

  const getMd5 = _node => {
    let i, currentChild, result;
    for (i = 0; i < _node.subviews.length; i++) {
      currentChild = _node.subviews[i];

      if (currentChild.model_class == 'ADModelImageView' && addedLayersIds.indexOf(currentChild.modelID) == -1) {
        addedLayersIds.push(currentChild.modelID);
        md5s.push(currentChild.md5);
      }
      result = getMd5(currentChild);
      if (result !== false) {
        return result;
      }
    }
    return false;
  };

  getMd5(node);

  return md5s;
};
