var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "title" }, [_vm._v("Switch to desktop")]),
    _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("Welcome aboard " + _vm._s(_vm.firstName) + "!")]),
      _c("p", [
        _vm._v(
          "It's time to create your first project. You'll need a desktop for this part."
        )
      ]),
      _c("p", [
        _vm._v(
          "Head to your inbox on your desktop, we’ve sent you an email with instructions."
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "illustration" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/time-lamp-desktop.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }