import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { noop } from '@/utils/sugar';
import { isProd } from '@/utils/environment';

if (isProd()) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    Vue,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05
  });
  Vue.prototype.$sentry = Sentry;
} else {
  Vue.prototype.$sentry = {
    captureException: console.error,
    captureMessage: console.error,
    startTransaction: () => ({ finish: noop }),
    transaction: {
      startChild: () => ({ finish: noop })
    }
  };
}
