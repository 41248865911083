import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api';
import { sync } from 'vuex-router-sync';
import { registerSharedComponents, registerModalComponents, registerBannerComponents } from '@/plugins/register';
import { injectRecaptchaScript } from '@/plugins/recaptcha';
import { initStripe, initPaypal } from '@/services/payment';
import { initConversion } from '@/services/conversion';
import VueWorker from 'vue-worker';
import Hotjar from 'vue-hotjar';
import VueIntercom from 'vue-intercom';
import VueGtm from 'vue-gtm';
import VueYouTube from 'vue-youtube';
import { isEmpty, isObject } from 'lodash-es';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import AsyncComputed from 'vue-async-computed';
import { isProd } from '@/utils/environment';

import 'splitpanes/dist/splitpanes.css';
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');

sync(store, router);

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  }
});

Vue.mixin({
  methods: {
    $waitFor(exp, expectedValue, { key = '', notEmpty = false } = {}) {
      const deep = isObject(exp());

      return new Promise(resolve => {
        const v = deep ? exp()[key] : exp();
        const c = deep && notEmpty ? !isEmpty(exp()) : v === expectedValue;
        if (c) {
          resolve();
        } else {
          const unWatch = this.$watch(
            exp,
            value => {
              const v = deep ? value[key] : value;
              const c = deep && notEmpty ? !isEmpty(value) : v === expectedValue;

              if (c) {
                resolve();
                unWatch();
              }
            },
            {
              immediate: true,
              deep: true
            }
          );
        }
      });
    }
  }
});

Vue.use(AsyncComputed);
Vue.use(VueWorker);

Vue.use(VueYouTube);
Vue.use(VueIntercom, { appId: 'id43h9l8' });
Vue.use(VueGtm, {
  id: 'GTM-5GH35Q5',
  defer: true,
  enabled: isProd(),
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

Vue.use(Hotjar, {
  id: '2007135',
  isProduction: isProd(),
  snippetVersion: 6
});

// Google analytics
// import '@/plugins/gtag';

// Event analytics
import '@/plugins/trackEvent';

// Tooltips
import '@/plugins/tippy';
import '@/plugins/tooltip';

// Main styles
import '@/styles/main.scss';

// sentry Vue integration
import '@/plugins/sentry';

// listen to screen size changes
import '@/plugins/screenSize';

// allow changing theme.
import '@/plugins/theme';

initStripe();
initPaypal();
initConversion();
// Recaptcha script
injectRecaptchaScript();
// Register shared components
registerSharedComponents();
// Register modal components
registerModalComponents();
// Register banner components
registerBannerComponents();
// Init Api config
api.init();

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
