var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pairing-container" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.loading
          ? _c("LoadingScreen")
          : _c("div", [
              _c(
                "div",
                { staticClass: "illustration" },
                [_c("svg-icon", { attrs: { name: "circle-check", size: 84 } })],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("You’re all set!")]),
              _c(
                "div",
                { staticClass: "subtitle" },
                [
                  _vm._v(
                    " Anima successfully added an integration with Figma API. "
                  ),
                  _c(
                    "an-link",
                    {
                      staticClass: "link",
                      attrs: { variant: "primary" },
                      on: { click: _vm.handleOpen }
                    },
                    [_vm._v("Go back to " + _vm._s(this.getPlatformName()))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("span", [_vm._v("Not " + _vm._s(_vm.email) + "? ")]),
                  _c(
                    "an-link",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.switchAccount }
                    },
                    [_vm._v("Log in")]
                  ),
                  _c("span", [_vm._v("  with another account")])
                ],
                1
              )
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }