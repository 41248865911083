var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty" }, [
    _c("div", { staticClass: "flex items-center flex-col" }, [
      _c("img", { staticClass: "image", attrs: { src: _vm.src } }),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "text" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }