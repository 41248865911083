var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "text" }, [
      _c("div", [
        _vm._v(" " + _vm._s(Math.floor(_vm.timer.progress * 100) + " %") + " ")
      ])
    ]),
    _c(
      "div",
      { ref: "loaderSvg" },
      [
        _c("svg-icon", {
          attrs: { width: 206, height: 24, name: "loader-line" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }