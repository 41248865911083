<template>
  <div class="pref-pop">
    <div class="flex">
      <div class="flex flex-col">
        <div
          class="tab-title flex items-center"
          @click="handleLangChange(t)"
          :class="{ selected: codegenLang == t.name, disabled: t.disabled }"
          v-for="t in tabs"
          :key="t.name"
        >
          <div class="label">{{ t.label }}</div>
        </div>
      </div>
      <div class="flex items-center tab-body">
        <div class="flex items-start" v-if="codegenLang === 'react'">
          <CodePrefCol
            style="margin-right:20px"
            :value="codegenReactSyntax"
            @change="handleReactSyntaxChange"
            title="Syntax"
            :options="reactSyntaxOptions"
          />
          <CodePrefCol
            :value="codegenReactStyle"
            @change="handleReactStyleChange"
            title="Styling"
            :options="reactStyleOptions"
          />
        </div>
        <div class="flex items-center" v-if="codegenLang === 'html'">
          <CodePrefCol
            :value="codegenHTMLLayout"
            @change="handleHTMLLayoutChange"
            title="Layout"
            :options="htmlLayoutOptions"
          />
        </div>
        <div v-if="codegenLang === 'vue'">
          <CodePrefCol
            :value="codegenVueStyle"
            @change="handleVueStyleChange"
            title="Styling"
            :options="vueStyleOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get } from 'lodash-es';
import CodePrefCol from './CodePrefCol';
import { HTML_LAYOUT_CHANGE, REACT_STYLE_CHANGE, REACT_SYNTAX_CHANGE, VUE_STYLE_CHANGE } from '../CodePrefsMixin';
import { UPDATE_DEV_PREFS } from '@/utils/events/omniviewEvents';

export default {
  data() {
    return {
      tabs: [
        { name: 'html', label: 'Pure HTML', disabled: false },
        { name: 'react', label: 'React', disabled: false },
        { name: 'vue', label: 'Vue', disabled: false }
      ]
    };
  },
  components: {
    CodePrefCol
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('omniview', ['htmlLayoutOptions', 'reactSyntaxOptions', 'reactStyleOptions', 'vueStyleOptions']),
    ...mapGetters({
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      codegenReactStyle: 'omniview/codegenReactStyle',
      codegenVueStyle: 'omniview/codegenVueStyle',
      currentNode: 'omniview/currentNode',
      codegenLang: 'omniview/codegenLang',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs'
    })
  },
  mounted() {
    EventBus.$on('set-code-language', this.handleLangChange);
  },
  destroyed() {
    EventBus.$off('set-code-language', this.handleLangChange);
  },
  methods: {
    ...mapMutations({
      setCodegenLang: 'omniview/setCodegenLang',
      setCodeDownloadPrefs: 'omniview/setCodeDownloadPrefs'
    }),
    ...mapActions({
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    handleLangChange({ name: lang, disabled }) {
      if (disabled) return;
      const { framework } = this.codeDownloadPrefs;
      if (lang != framework) {
        const newCodePrefs = { ...this.codeDownloadPrefs, framework: lang };
        this.setCodeDownloadPrefs(newCodePrefs);
        localStorage.setItem('codeDownloadPrefs', JSON.stringify(newCodePrefs));
      }

      if (lang == this.codegenLang) return;

      localStorage.setItem('codeFramework', lang);
      this.setCodegenLang(lang);

      const isFramework = lang == 'vue' || lang == 'react';
      EventBus.$emit(UPDATE_DEV_PREFS, { lang, triggerFullPreProcess: isFramework });
      if (isFramework) {
        EventBus.$emit('pre-process', { forcePreProcess: true });
      }

      if (this.showOnlyCode) {
        let fileId = get(this.$route, 'query.fileId', false);
        EventBus.$emit('generateCodeWithoutIframe', { nodeId: this.currentNode, fileId });
      } else {
        EventBus.$emit('generate-code', { forceGenerate: true });
      }

      this.$trackEvent('omniview.codegen-framework.change', {
        from: this.codegenLang,
        to: lang
      });
      this.$gtm.trackEvent({
        event: 'select_code',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: this.codegenLang
      });
    },
    handleHTMLLayoutChange(value) {
      EventBus.$emit(HTML_LAYOUT_CHANGE, { value });
      EventBus.$emit(UPDATE_DEV_PREFS, { html_layout: value });
    },
    handleReactSyntaxChange(value) {
      EventBus.$emit(REACT_SYNTAX_CHANGE, { value });
      EventBus.$emit(UPDATE_DEV_PREFS, { react_syntax: value });
    },
    handleReactStyleChange(value) {
      EventBus.$emit(REACT_STYLE_CHANGE, { value });
      EventBus.$emit(UPDATE_DEV_PREFS, { react_style: value });
    },
    handleVueStyleChange(value) {
      EventBus.$emit(VUE_STYLE_CHANGE, { value });
      EventBus.$emit(UPDATE_DEV_PREFS, { vue_style: value });
    }
  }
};
</script>

<style lang="scss" scoped>
.pref-pop {
  background: #2d2d2d;
  // width: 200px;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  // box-shadow: 0 0 10px rgba(black, 0.1);
  border: 1px solid rgba(black, 0.1);

  &-title {
    padding: 12px 16px;
    box-shadow: 0 1px 0 0 #2d2d2d;
    font-size: 14px;
  }
  &-body {
    padding: 12px;
    font-size: 12px;
  }
}

.tab-title {
  padding: 6px 14px;
  font-size: 14px;
  border-right: 2px solid transparent;
  &.selected {
    color: var(--primary);
    border-color: var(--primary);
  }
  &:hover {
    color: var(--primary);
    cursor: pointer;
  }
  &.disabled {
    .label {
      opacity: 0.4;
    }
    &:hover {
      color: #fff;
      cursor: default;
    }
  }
}
.tab-body {
  padding: 0 20px;
  min-width: 280px;
}

.badge {
  border-radius: 100px;
  padding: 0 6px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  &.new {
    background: var(--green);
    color: var(--white);
    margin-left: auto;
  }
}
</style>
