import Vue from 'vue';
import store from '@/store';
// eslint-disable-next-line no-unused-vars
import VueRouter, { RouteConfig } from 'vue-router';
import auth from '@/auth';
import { trackEvent } from '@/services/tracking';
import { beforeEnterModal, beforeSignupOrLogin } from '@/router/navigationGuards';

import Root from '@/views/Root.vue';
import Team from '@/views/Team.vue';
import Project from '@/views/Project.vue';
import Login from '@/views/Login.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import FigmaAPICallback from '@/views/FigmaAPICallback.vue';
import FigmaAPIGenerateToken from '@/views/FigmaAPIGenerateToken.vue';
import Paired from '@/views/Paired.vue';
import Signup from '@/views/Signup.vue';
import Onboarding from '@/views/Onboarding/Onboarding';
import OnboardingFirstTeam from '@/views/Onboarding/FirstTeam';
import OnboardingFirstTeamPricing from '@/views/Onboarding/FirstTeamPricing';
import Shared from '@/views/Shared.vue';
import OmniView from '@/views/OmniView.vue';
import SyncPreview from '@/views/SyncPreview';
import SyncWebsite from '@/views/SyncWebsite';
import PageNotFound from '@/views/PageNotFound';
import ChromeExtensionUninstall from '@/views/ChromeExtensionUninstall';
import ForgotPassword from '@/views/ForgotPassword';
import ResetPassword from '@/views/ResetPassword';
import Learn from '@/views/Learn.vue';

import ProjectsList from '@/components/Team/ProjectsList';
import TeamSettings from '@/components/Team/Settings/Settings';

import api from '@/api';

Vue.use(VueRouter);

const getTeamSettingsPaths = name => {
  const children = ['general', 'members', 'permissions', 'billing', 'delete'].map(section => {
    const childName = `${name}-${section}`;
    return {
      name: childName,
      path: section,
      beforeEnter: beforeEnterModal({ name: childName, onCloseRedirect: { name } })
    };
  });
  return {
    name,
    path: 'settings',
    component: TeamSettings,
    children
  };
};

const getTeamPaths = () => {
  const name = 'team';
  const path = '/team/:teamSlug';
  const settingsName = `${name}-settings`;
  return {
    path,
    component: Team,
    children: [
      {
        name,
        path: '',
        component: ProjectsList,
        props: { type: 'all' }
      },
      {
        name: `${name}-archive`,
        path: 'archive',
        component: ProjectsList,
        props: { type: 'archive' }
      },
      {
        name: `${name}-members`,
        path: 'members',
        beforeEnter: beforeEnterModal({ name: 'team-settings-members' })
      },
      {
        name: `${name}-pricing`,
        path: 'pricing',
        beforeEnter: beforeEnterModal({
          name: 'pricing',
          calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
        })
      },
      {
        name: `${name}-payment`,
        path: 'payment',
        beforeEnter: beforeEnterModal({
          name: 'payment',
          calcRedirect: (_, from) => /onboarding|payment/.test(from.name) && { name: 'team' }
        })
      },
      {
        name: `${name}-payment-success`,
        path: 'payment/success',
        beforeEnter: beforeEnterModal({ name: 'payment-success', onCloseRedirect: { name: 'team' } })
      },
      {
        name: `${name}-downgrade`,
        path: 'downgrade',
        beforeEnter: beforeEnterModal({ name: 'downgrade', onCloseRedirect: { name: 'team-settings-billing' } })
      },
      {
        name: `${name}-cancel-plan`,
        path: 'downgrade/cancel',
        beforeEnter: beforeEnterModal({ name: 'cancel-plan', onCloseRedirect: { name: 'team-settings-billing' } })
      },
      {
        name: 'new-project',
        path: 'project/new',
        beforeEnter: beforeEnterModal({ name: 'new-project' })
      },
      getTeamSettingsPaths(settingsName)
    ]
  };
};

/**
 * @type {RouteConfig[]}
 */
const routes = [
  {
    path: '/',
    name: 'root',
    component: Root,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'shared',
        path: 'shared',
        component: Shared
      },
      {
        name: 'plugins',
        path: 'plugins',
        beforeEnter: beforeEnterModal({ name: 'plugins', mode: 'dark' })
      },
      {
        name: 'learn',
        path: 'learn',
        component: Learn
      },
      {
        name: 'pricing',
        path: 'pricing',
        beforeEnter: async (to, from, next) => {
          const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
          const params = { teamSlug };
          next({ name: 'team-pricing', params });
        }
      },
      {
        name: 'payment',
        path: 'payment',
        beforeEnter: async (to, from, next) => {
          const { query = {} } = to;
          const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
          const params = { teamSlug };

          if (!query.plan || !query.interval) {
            return next({ name: 'team-pricing', params });
          }

          next({ name: 'team-payment', params, query });
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'connect-slack',
        path: '/slack',
        beforeEnter: beforeEnterModal({
          name: 'connect-slack'
        }),
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'default-team-redirect',
        path: 'my-team/*',
        beforeEnter: async (to, from, next) => {
          try {
            const { params } = to;
            const teamSlug = await store.dispatch('users/getDefaultTeamSlug');

            next({ path: `/team/${teamSlug}/${params.pathMatch}` });
          } catch (err) {
            next({ name: 'page-not-found' });
          }
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'chrome-extension-uninstall',
        path: '/chrome-extension-uninstall',
        component: ChromeExtensionUninstall
      },
      {
        name: 'account',
        path: 'account',
        beforeEnter: beforeEnterModal({ name: 'account' })
      },
      {
        name: 'new-team',
        path: 'team(s)?/new',
        beforeEnter: beforeEnterModal({ name: 'new-team' })
      },
      {
        name: 'switch-to-desktop',
        path: 'switch',
        beforeEnter: beforeEnterModal({
          name: 'switch-to-desktop',
          fsHideCloseButton: true,
          onCloseRedirect: { name: 'root' }
        })
      },
      getTeamPaths(),
      {
        name: 'temp-team-invitation-backward-compatibility',
        path: 'join/:teamSlug/:invitationCode',
        redirect: to => {
          const {
            params: { teamSlug, invitationCode }
          } = to;
          const params = { teamSlug };
          const query = { invite: invitationCode };
          return { name: 'team', params, query };
        }
      },
      {
        name: 'project',
        path: '/team/:teamSlug/project/:projectId',
        component: Project,
        children: [
          {
            name: 'project-components',
            path: 'components',
            props: { type: 'component' },
            beforeEnter(to, _, next) {
              const user = store.state.users.currentItem;
              if (!user?.is_admin) {
                next({ name: 'project', params: to.params });
              } else next();
            }
          },
          {
            name: 'sketch-file-sync',
            path: 'sketch-file-sync',
            beforeEnter: beforeEnterModal({ name: 'sketch-file-sync' })
          },
          {
            name: 'figma-file-sync',
            path: 'figma-file-sync',
            beforeEnter: beforeEnterModal({ name: 'figma-file-sync' })
          },
          {
            name: 'project-settings',
            path: 'settings',
            beforeEnter: beforeEnterModal({ name: 'project-settings', onCloseRedirect: { name: 'project' } }),
            children: [
              {
                name: 'project-settings-general',
                path: 'general',
                alias: '',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'General' }
                })
              },
              {
                name: 'project-settings-guests',
                path: 'guests',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'Members' }
                })
              },
              {
                name: 'project-settings-public-link',
                path: 'public-link',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  onCloseRedirect: { name: 'project' },
                  props: { defaultTabLabel: 'Prototype link' }
                })
              },
              {
                name: 'project-settings-versions',
                path: 'versions',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'Versions' }
                })
              },
              {
                name: 'project-settings-files',
                path: 'files',
                beforeEnter: beforeEnterModal({
                  name: 'project-settings',
                  props: { defaultTabLabel: 'Files' }
                })
              }
            ]
          },
          {
            name: 'project-invite',
            path: 'invite',
            beforeEnter: beforeEnterModal({
              name: 'project-invite',
              onCloseRedirect: { name: 'project' },
              props: { eventSource: 'face-pile' }
            })
          },
          {
            name: 'project-share',
            path: 'invite',
            beforeEnter: beforeEnterModal({
              name: 'project-invite',
              onCloseRedirect: { name: 'project' },
              props: { eventSource: 'share-button' }
            })
          },
          {
            name: 'archive-project',
            path: 'archive',
            beforeEnter: beforeEnterModal({ name: 'archive-project' })
          },
          {
            name: 'delete-project',
            path: 'delete',
            beforeEnter: beforeEnterModal({ name: 'delete-project' })
          }
        ]
      },
      {
        name: 'temp-project-backward-compatibility',
        path: '/p/:projectId',
        component: Project
      }
    ]
  },
  {
    name: 'omniview',
    path: '/team/:teamSlug/project/:projectId/screen/:screenSlug/omniview',
    component: OmniView,
    meta: {
      requiresAuth: true
    }
  },
  // Backward support for preview page
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/prototype',
    name: 'syncPreview',
    component: SyncPreview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/syncwebsite',
    name: 'syncWebsite',
    component: SyncWebsite,
    meta: {
      requiresAuth: true
    }
  },
  // omniview for a release for chrome extension
  {
    path: '/p/:projectShortId/:releaseShortId/:screenSlug/omniview',
    name: 'releaseOmniview',
    component: SyncPreview,
    meta: {
      requiresAuth: true
    }
  },
  // Backward support for single comment
  {
    path: '/p/:projectId/:screenSlug/comments/:commentNumber',
    component: OmniView,
    meta: {
      mode: 'comments',
      requiresAuth: true
    }
  },
  {
    name: 'signup',
    path: '/signup',
    component: Signup,
    beforeEnter: beforeSignupOrLogin
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: beforeSignupOrLogin
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'reset-password',
    path: '/reset-password/:token',
    component: ResetPassword
  },
  {
    name: 'paired',
    path: '/paired',
    component: Paired,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    component: Onboarding
  },
  {
    name: 'onboarding-team',
    path: '/onboarding/team',
    component: OnboardingFirstTeam,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'onboarding-pricing',
    path: '/onboarding/:teamSlug/pricing',
    component: OnboardingFirstTeamPricing,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'figma-generate-token',
    path: '/figma/generate-token',
    component: FigmaAPIGenerateToken
  },
  {
    name: 'figma-callback',
    path: '/figma/callback',
    component: FigmaAPICallback
  },
  {
    name: 'verify-email',
    path: '/verify/:token',
    component: VerifyEmail,
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'temp-team-backward-compatibility',
    path: '/:teamSlug',
    redirect: { name: 'team' },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'project-settings-backward-compatibility',
    path: '/project/settings/:projectId/:section',
    beforeEnter: async (to, from, next) => {
      const { projectId, section = '' } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });
      const params = { projectId, teamSlug };
      let routeName = 'project-settings';

      if (['files', 'general'].includes(section.toLowerCase())) {
        routeName = `project-settings-${section}`;
      }

      next({ name: routeName, params });
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'project-prototype-backward-compatibility',
    path: '/p/s/:projectId/:subdomain/:screenSlug/prototype',
    beforeEnter: async (to, _, next) => {
      const { projectId, screenSlug } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });
      const params = { projectId, teamSlug, screenSlug };

      next({ name: 'omniview', params });
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'omniview-short-link',
    path: '/p/:projectId/s/:screenSlug',
    beforeEnter: async (to, _, next) => {
      const { projectId, screenSlug } = to.params;
      const { team_slug: teamSlug } = await store.dispatch('projects/fetchOne', { id: projectId });

      const params = { projectId, teamSlug, screenSlug };

      next({
        path: `/team/${teamSlug}/project/${projectId}/screen/${screenSlug}/omniview/`,
        params,
        query: to.query,
        replace: true
      });
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'personal-plan-backward-compatibility',
    path: '/settings/personal/plan(/change)?',
    redirect: to => ({ name: 'pricing', query: to.query }),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'team-pricing-backward-compatibility',
    path: '/settings/:teamSlug/plan/change',
    redirect: to => ({ name: 'team-pricing', params: to.params }),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'personal-account-backward-compatibility',
    path: '/account/settings/personal/account',
    redirect: { name: 'account' },
    meta: {
      requiresAuth: true
    }
  },

  {
    name: 'latest-project-release',
    path: '/latest-project-release/:email',
    beforeEnter: async (to, _, next) => {
      try {
        const { email: user_email } = to.params;
        const params = { user_email };
        const {
          data: { url }
        } = await api.get('/get_last_project_release', null, { params });

        if (!url) throw 'NO URL! :(';

        next(url);
      } catch (err) {
        console.error(err);
        next({ name: 'root' });
      }
    },
    meta: {
      requiresAuth: true
    }
  },

  // keep this last k? thx.
  {
    name: 'page-not-found',
    path: '*',
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  // @or: plugins have hard-coded pairing URL with hash mode
  // this is a hotfix https://stackoverflow.com/a/58423455/413289
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  const { name, query } = to;

  if (name && to.name != from.name) {
    store.dispatch('tracking/trackPageView', to);
  }

  if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
    if (query.t) {
      localStorage.setItem('token', query.t);
    }
    if (!auth.isLoggedIn()) {
      if (name == 'root') {
        next({ name: 'login' });
      } else {
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        });
      }
    } else {
      try {
        await store.dispatch('users/fetchOne', { id: 'me' });
        const teamSlug = await store.dispatch('users/getDefaultTeamSlug');
        if (name === 'root') {
          return next({ name: 'team', params: { teamSlug } });
        }
        return next();
      } catch (error) {
        trackEvent('webapp.auth.failure');
        console.error(error);
        return next({ name: 'login', query });
      }
    }
  } else {
    next();
  }
});

export default router;
