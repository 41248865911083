var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Anima Plugin")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v("Download Anima plugin and start syncing your design")
    ]),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "plugins-container" },
        _vm._l(_vm.plugins, function(plugin) {
          return _c("div", { key: plugin.name, staticClass: "plugin-box" }, [
            _c(
              "div",
              { staticClass: "plugin-icon" },
              [_c("svg-icon", { attrs: { name: plugin.icon, size: 100 } })],
              1
            ),
            _c("div", { staticClass: "plugin-title" }, [
              _vm._v("Anima for " + _vm._s(plugin.name))
            ]),
            _c("div", { staticClass: "plugin-description" }, [
              _vm._v(
                " Sync your " +
                  _vm._s(plugin.name) +
                  " designs to Anima, share outstanding prototypes & convert your design to HTML, React, Vue & CSS code. "
              )
            ]),
            _c(
              "div",
              { staticClass: "learn-more-link" },
              [
                _c(
                  "an-link",
                  {
                    attrs: {
                      variant: "primary",
                      href: plugin.learnUrl,
                      target: "_blank"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$trackEvent("plugins.learn-more.click", {
                          plugin: plugin.name
                        })
                      }
                    }
                  },
                  [_vm._v(" Learn more ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "download-button" },
              [
                _c(
                  "an-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.navigateToDownload(plugin)
                      }
                    }
                  },
                  [_vm._v("Install plugin")]
                )
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }