var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c fadeIn" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _vm.nestedComponentsStack.length > 1
              ? _c(
                  "div",
                  {
                    staticClass: "icon-c",
                    staticStyle: { "margin-right": "10px" },
                    on: { click: _vm.OpenPrevComponent }
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: { color: "white" },
                      attrs: {
                        fill: "currentColor",
                        name: "arrow-left",
                        size: 24
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("span", { staticClass: "truncate" }, [
              _vm._v(
                _vm._s(
                  _vm.isComponentOrSuggestion
                    ? _vm.currentWebComponentName
                    : _vm.newViewName
                )
              )
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "icon-c",
            staticStyle: { "margin-left": "auto" },
            on: { click: _vm.clearSelection }
          },
          [
            _c("svg-icon", {
              attrs: { fill: "currentColor", name: "close", size: 18 }
            })
          ],
          1
        )
      ]),
      _vm.showComponentToggle
        ? _c("div", { staticClass: "block centered" }, [
            _c("div", { staticClass: "block-title" }, [
              _c("div", { staticClass: "w-full flex items-center" }, [
                _c("span", [_vm._v("Type")]),
                _c(
                  "div",
                  {
                    staticClass: "flex items-center",
                    staticStyle: { "margin-left": "auto" }
                  },
                  [
                    _c(
                      "an-radio",
                      {
                        attrs: {
                          centered: true,
                          spacingX: 6,
                          spacingY: 0,
                          type: "dot",
                          full: "",
                          label: "element",
                          value: _vm.nodeType
                        },
                        on: { change: _vm.handleNodeTypeChange }
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              opacity: _vm.nodeType == "element" ? 1 : 0.4
                            }
                          },
                          [_vm._v("Element")]
                        )
                      ]
                    ),
                    _c(
                      "an-radio",
                      {
                        staticStyle: { "margin-left": "30px" },
                        attrs: {
                          centered: true,
                          label: "component",
                          type: "dot",
                          full: "",
                          spacingX: 6,
                          spacingY: 0,
                          value: _vm.nodeType
                        },
                        on: { change: _vm.handleNodeTypeChange }
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              opacity: _vm.nodeType == "component" ? 1 : 0.4
                            }
                          },
                          [_vm._v("Component")]
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm.elementAssets.length > 0
        ? _c("div", [
            _c("div", { staticClass: "block centered" }, [
              _c(
                "div",
                {
                  staticClass: "block-title flex flex-col",
                  staticStyle: { "align-items": "flex-start" }
                },
                [
                  _c("span", { staticStyle: { "margin-bottom": "20px" } }, [
                    _vm._v("Asset")
                  ]),
                  _c(
                    "div",
                    { staticClass: "asset-image-mask" },
                    [
                      _c("svg-icon", {
                        attrs: { name: "image-mask", size: 100 }
                      }),
                      _c("div", {
                        staticClass: "asset-image",
                        style: _vm.getAssetBg(_vm.elementAssets[0])
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex items-center" }, [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("Size")
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(Math.round(_vm.elementAssets[0].width)) +
                          "px × " +
                          _vm._s(Math.round(_vm.elementAssets[0].height)) +
                          "px"
                      )
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "block centered" }, [
              _c("div", { staticClass: "block-title" }, [
                _c("span", [_vm._v("Download Asset")]),
                _c(
                  "div",
                  {
                    staticClass: "flex items-center",
                    staticStyle: { "margin-left": "auto" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "download",
                        attrs: {
                          target: "_blank",
                          href: _vm.getAssetDownloadURL(_vm.elementAssets[0]),
                          download: ""
                        }
                      },
                      [
                        _c("div", { staticClass: "download-capsule" }, [
                          _vm._v("PNG")
                        ])
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        : _c("div", { staticClass: "block centered" }, [
            _c(
              "div",
              {
                staticClass: "block-title flex flex-col",
                staticStyle: { "align-items": "flex-start" }
              },
              [
                _c("span", { staticStyle: { "margin-bottom": "20px" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.isComponentOrSuggestion ? "Component" : "Element"
                    ) + " Preview"
                  )
                ]),
                _vm.isComponentOrSuggestion
                  ? _c("WebComponentThumb")
                  : _c(
                      "ImagePreview",
                      _vm._b({}, "ImagePreview", _vm.imagePreviewProps, false)
                    )
              ],
              1
            )
          ]),
      _vm.isComponentOrSuggestion
        ? _c("div", [
            _c("div", { staticClass: "block centered" }, [
              _c("div", { staticClass: "block-title" }, [
                _c("div", { staticClass: "w-full flex flex-col" }, [
                  _c("div", { staticClass: "w-full flex items-center" }, [
                    _c("span", [_vm._v("Props")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          "margin-left": "auto"
                        }
                      },
                      [
                        !_vm.isComponentView
                          ? _c(
                              "an-button",
                              {
                                attrs: { size: "sm", variant: "secondary" },
                                on: { click: _vm.openAndEditComponent }
                              },
                              [_vm._v(" View ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full flex items-center" },
                    [
                      _vm.isPreprocessing
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-full flex items-center justify-center",
                              staticStyle: {
                                padding: "20px",
                                "margin-top": "24px",
                                background: "#333333"
                              }
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "icon-spin",
                                attrs: {
                                  fill: "currentColor",
                                  name: "spin",
                                  size: 30
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "transition",
                        { attrs: { name: "fadeIn" } },
                        [_c("UsageCode")],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("OverrideTagName"),
      _c("OverrideCss"),
      _c("div", { staticClass: "fixed-footer flex items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "open-in-sandbox  w-full flex items-center justify-center truncate",
            on: { click: _vm.openInExternalEditor }
          },
          [
            _c(
              "an-button",
              {
                attrs: {
                  isWorking: _vm.isGeneratingPlaygroundCode,
                  variant: "empty"
                }
              },
              [
                _c("svg-icon", {
                  staticClass: "icon icon-codepen",
                  attrs: { fill: "currentColor", name: "codepen", size: 24 }
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "Open In " + (_vm.isCodePen ? "CodePen" : "CodeSandbox")
                    )
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }