<template>
  <div
    :class="{ isSyncing }"
    @click.stop="handleCardClick"
    class="figma-card-wrap"
    :style="{ cursor: bodySelect ? 'pointer' : 'default' }"
  >
    <div class="figma-card">
      <div class="border"></div>
      <div style="position:relative;flex-grow:1">
        <div class="cover-wrap">
          <div v-show="isLoadingThumbnail" class="flex items-center justify-center w-full">
            <svg-icon style="margin-right:10px" fill="currentColor" class="icon-spin" name="spin" :size="14"></svg-icon>
          </div>
          <div v-show="!isLoadingThumbnail" class="cover-wrap-inner" style="background-color: rgb(229, 229, 229);">
            <div class="cover-image" :style="thumbnailURL"></div>
          </div>
        </div>
      </div>
      <div class="footer-wrap">
        <div v-if="selectable" class="checkbox-wrap">
          <Checkbox v-on:input="handleInput" :value="localValue"> </Checkbox>
        </div>
        <!-- <div class="flex flex-col name-date"> -->
        <div class="name truncate flex items-center">
          <span class="truncate">{{ data.name }}</span>
        </div>
        <!-- <span class="date">{{ lastModified }}</span> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import { formatDateTimeConversational } from '@/utils/date';
import { has } from 'lodash-es';

export default {
  props: {
    bodySelect: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      required: true
    },
    controlled: {
      type: Boolean,
      required: false
    },
    value: {
      type: Boolean
    },
    selectable: {
      type: Boolean,
      default: true
    },
    isLoadingThumbnail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Checkbox
  },
  mounted() {},
  data() {
    return {
      selected: false
    };
  },
  computed: {
    lastModified() {
      if (this.data.lastModified) return formatDateTimeConversational(this.data.lastModified);
      return '';
    },
    localValue() {
      return this.controlled ? this.value : this.selected;
    },
    thumbnailURL() {
      const backgroundImage = `url("${this.data.thumbnailUrl}")`;

      return {
        backgroundImage
      };
    },
    isSyncing() {
      if (!has(this.data, 'isSyncing')) return false;
      return this.data.isSyncing;
    }
  },
  methods: {
    handleCardClick() {
      if (!this.bodySelect) return;
      if (this.selectable) {
        this.handleInput();
      }
    },
    handleInput() {
      if (this.controlled) {
        this.$emit('selectChange');
      } else {
        this.selected = !this.selected;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.isSyncing {
  opacity: 0.5;
}

.figma-card-wrap {
  margin: 0;
  height: 0;
  padding-top: 75%;
  display: flex;
  border-radius: 7.5px;
  position: relative;
  flex: 1 1 280px;
  align-self: flex-start;
  background-color: transparent;
  scroll-margin-top: 64px;
  scroll-margin-bottom: 24px;
}

.figma-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: content-box;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

.border {
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  background-clip: padding-box;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.cover-wrap {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iI0ZGRkZGRiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ii8+PHBhdGggZmlsbD0iI0YxRjFGMSIgZD0iTSAwIDggSCAxNiBWIDAgSCA4IFYgMTYgSCAwIi8+PC9zdmc+);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: stretch;
  text-decoration: none;
  overflow: hidden;
  // height: 162px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.cover-wrap-inner {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.cover-image {
  margin: 10px;
  flex: 1 0 auto;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.footer-wrap {
  height: 56px;
  align-items: stretch;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  flex-direction: row;
}
.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.name {
  color: #333;
  font-weight: 500;
  padding: 0 5px;
}
.date {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.053px;
}
</style>
