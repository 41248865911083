var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { class: ["container", _vm.theme] },
        [
          _c(_vm.loaders[_vm.type], {
            tag: "component",
            attrs: { text: _vm.text, theme: _vm.theme }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }