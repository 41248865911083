import { createStoreModule } from '../utils';
import api from '@/api';
import Vue from 'vue';

export default createStoreModule({
  name: 'figmaIntegration',
  modelName: 'figma_integration',
  crud: false,
  state: {
    isSyncing: {},
    isFetchingThumbnails: {},
    frames: [],
    figmaImportStatus: '',
    figmaImportProgress: 0
  },
  getters: {
    syncedFrames(state) {
      state.frames.filter(frame => frame.isSynced);
    },
    remainingFrames(state) {
      state.frames.filter(frame => !frame.isSynced);
    }
  },
  mutations: {
    setFrames(state, frames) {
      state.frames = frames;
    },
    setIsSyncing(state, { value, projectId }) {
      console.warn(value, projectId);
      Vue.set(state.isSyncing, projectId, value);
    },
    setIsFetchingThumbnails(state, { value, pageId }) {
      console.warn(value, pageId);
      Vue.set(state.isFetchingThumbnails, pageId, value);
    },
    setFigmaImportStatus(state, status) {
      state.figmaImportStatus = status;
    },
    setFigmaImportProgress(state, value) {
      state.figmaImportProgress = value;
    }
  },
  actions: {
    async createToken(_ctx, { params }) {
      await api.post('/figma_token/generate', params);
    },
    async getPages(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/file/pages', payload);
      return data;
    },
    async getThumbnails(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/get_thumbnails', payload);
      return data;
    },
    async getFrames(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/file/frames', payload);
      return data;
    },
    async getFramesAsync(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/file/frames/async', payload);
      return data;
    },
    async generateModel(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/preview_model', payload);
      return data;
    },
    async generateModelAsync(_ctx, { payload }) {
      const { data } = await api.post('/figma_token/preview_model/async', payload);
      return data;
    },
    async getTaskCallback(_ctx, { payload }) {
      return api.post('/figma_token/task/callback', { ...payload, v2: true });
    }
  },
  endpoint: '/figma_token'
});
