var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Account settings")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v("View and update your account details, profile and more.")
    ]),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "account-sidebar" },
        [
          _c("UploadAvatar", {
            attrs: { user: _vm.user, size: 160 },
            on: { success: _vm.onUploadProfilePictureSuccess }
          }),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.user.name))]),
          _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.user.email))]),
          _c("div", { staticClass: "counts" }, [
            _c("div", [
              _c("span", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.userTeams.length))
              ]),
              _c("span", { staticClass: "entity" }, [_vm._v("Teams")])
            ])
          ]),
          _c("span", { staticClass: "joined" }, [
            _vm._v("Anima user since " + _vm._s(_vm.joinDate))
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "account-body" },
        [
          _c(
            "div",
            { staticClass: "navigation" },
            [
              _c("Tabs", {
                attrs: { activeTabLabel: _vm.activeTabLabel, items: _vm.tabs },
                on: { change: _vm.onTabChange }
              })
            ],
            1
          ),
          _c(_vm.component, { tag: "component" })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }