var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "details" }, [
      _c("form", [
        _c("div", { staticClass: "property" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Email address")]),
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("an-input", {
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "property" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Password")]),
          _c(
            "div",
            { staticClass: "password-input" },
            [
              _c("an-input", {
                attrs: {
                  placeholder: "********",
                  type: "password",
                  autocomplete: ""
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _c(
                "an-button",
                {
                  attrs: { isWorking: _vm.loading, variant: "secondary" },
                  on: { click: _vm.updateDetails }
                },
                [_vm._v("Update")]
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm.user.is_admin
      ? _c(
          "div",
          { staticClass: "separated" },
          [
            _c(
              "an-checkbox",
              {
                model: {
                  value: _vm.showDebugEvents,
                  callback: function($$v) {
                    _vm.showDebugEvents = $$v
                  },
                  expression: "showDebugEvents"
                }
              },
              [_vm._v(" [Admins] Show events ")]
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "separated" },
      [
        _c(
          "an-link",
          {
            attrs: { primaryHover: "" },
            on: { click: _vm.openDeleteAccountModal }
          },
          [_vm._v("Delete account")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }