var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "banner-container",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "clickable-icon close",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("close")
            }
          }
        },
        [_c("svg-icon", { attrs: { name: "close" } })],
        1
      ),
      _c("div", { staticClass: "quirky-text" }, [_vm._v("Meet")]),
      _c("div", { staticClass: "title" }, [_vm._v("Anima")]),
      _c("div", { staticClass: "pill-5" }, [_vm._v("5")]),
      _c("img", {
        staticClass: "shapes",
        attrs: { src: require("@/assets/illustrations/anima-5-shapes.svg") }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }