<template>
  <button
    type="button"
    class="google-button"
    @click="$emit('click', $event)"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    <span ref="icon" class="google-button__icon">
      <svg-icon :name="iconName" :key="iconName" />
    </span>
    <span class="google-button__text"><slot /></span>
  </button>
</template>

<script>
import googleAuth from '@/services/googleAuth';
export default {
  data() {
    return {
      hover: false
    };
  },
  props: {
    params: {
      type: Object,
      default: () => ({ clientId: process.env.GOOGLE_AUTH_CLIENT_ID })
    }
  },
  mounted() {
    this.initGoogleAuth();
  },
  computed: {
    iconName() {
      return this.hover ? 'google-logo-white' : 'google-logo';
    }
  },
  methods: {
    async initGoogleAuth() {
      try {
        const auth2 = await googleAuth.load(this.params);
        auth2.attachClickHandler(
          this.$el,
          {},
          googleUser => {
            this.$emit('success', googleUser);
          },
          error => {
            console.log(error);
            this.$emit('failure', error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.google-button {
  height: 48px;
  width: 100%;
  background: var(--white);
  border-radius: 2px;
  white-space: nowrap;
  padding: 0;
  cursor: pointer;
  border-radius: 10px;
}

.google-button:focus,
.google-button:hover {
  background-color: var(--blue);
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  .google-button__text {
    color: var(--white);
  }
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-text);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
</style>
