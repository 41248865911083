<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <div class="container dark">
      <div v-if="!resetPasswordSubmitted">
        <div class="title">Forgot your password?</div>
        <div class="subtitle">
          Don’t worry, it happens to the best of us! We’ll email you a password reset link.
        </div>
        <div class="body">
          <div class="label">Your email address</div>
          <div class="input">
            <an-input
              id="email"
              type="email"
              v-model="email"
              :theme="theme"
              :invalid="!emailValid"
              placeholder="Email address"
              focus
              @input="showButton = true"
              @keyup.enter="submit"
            />
          </div>
          <transition name="fadeIn">
            <div class="actions" v-if="showButton">
              <an-button @click="submit" :isWorking="isWorking">Reset password</an-button>
            </div>
          </transition>
          <div class="link">
            <an-link @click="navigateToLogin" :theme="theme">Return to log in</an-link>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="title">Check your inbox</div>
        <div class="subtitle">
          We’ve sent a reset link to your inbox.
          <br />
          If it doesn’t show up soon, check your spam folder.
        </div>
        <div class="link">
          <an-link @click="submit" :theme="theme">Resend email</an-link>
        </div>
        <div class="actions">
          <an-button @click="navigateToLogin">Back to log in</an-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import api from '@/api';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      email: '',
      emailValid: true,
      showButton: false,
      isWorking: false,
      resetPasswordSubmitted: false,
      theme: 'dark'
    };
  },
  components: {
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
  },
  methods: {
    async submit() {
      try {
        this.isWorking = true;
        const email = normalizeEmail(this.email);
        this.emailValid = isValidEmail(email);

        this.$trackEvent('forgot-password.reset-password-button.click');

        if (this.emailValid) {
          await api.post('/forgot_password', { email });
          this.$trackEvent('forgot-password.reset-password.success');
          this.resetPasswordSubmitted = true;
        } else {
          if (this.isDesktop) toastError('Oops, did you provide an email?');
        }
      } catch (err) {
        const { response } = err;
        const message = response?.data?.message || 'Something went wrong! Is that really your email';
        this.$trackEvent('forgot-password.reset-password.failure');
        if (this.isDesktop) toastError(message);
      } finally {
        this.isWorking = false;
      }
    },
    navigateToLogin() {
      this.$trackEvent('forgot-password.navigate-to-login.click');
      this.$router.replace({ name: 'login', query: this.$route.query });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  margin-top: 160px;
  max-width: 100%;
  @include mobile {
    margin-top: 110px;
  }
  .subtitle {
    @include desktop {
      margin: 20px 0 0;
      width: 100%;
    }
  }
  .body {
    margin: 40px auto;
    max-width: 444px;
  }
}
.actions {
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    &.an-button {
      @include mobile {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}
.link {
  text-align: center;
  margin: 40px 0 0;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
</style>
