var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Avatar", {
    key: _vm.user.profile_picture_url || _vm.user.email,
    attrs: {
      src: _vm.avatarImageURL,
      text: _vm.avatarText,
      backgroundColor: _vm.backgroundColor,
      borderColor: _vm.borderColor,
      size: _vm.size,
      noBorder: _vm.noBorder
    },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }