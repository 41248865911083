import TrackingService from 'anima-tracking';
import store from '@/store';
import { addDebugEvent } from '@/services/bus';

const config = {
  web: true,
  clientId: 'com.animaapp.web',
  gtagId: 'UA-105546425-1',
  //mpToken: 'b0e73daa3c5bf70fb1ae7c179668f2a5',
  baseURL: '//logs.animaapp.com' //process.env.APP_BASE_URL
};

export const tracking = new TrackingService(config);
export const trackEvent = (eventName, params = {}) => {
  const extraTrackingData = store.getters['tracking/extraData'];
  const isChromeExtension = store.state.omniview?.isChromeExtension;

  if (isChromeExtension) {
    let extensionName = store.state['omniview'].extensionName;

    // add this flag so we can detect if this event is coming from the chrome extension or not
    params.extensionName = extensionName;
    params.fromChromeExtension = true;
  }
  try {
    tracking.trackEvent(eventName, { ...extraTrackingData, ...params });
  } catch (err) {
    console.error('failed tracking event', err);
  }

  addDebugEvent({ eventName, params });
};

export const debugEvent = (eventName, params = {}) => {
  addDebugEvent({ eventName, params });
};

// remove timeout until refactor for better measuring
export const measure = label => tracking.measureStart(`web-app.${label}`);
export const measureEnd = (label, props) => tracking.measureEnd(`web-app.${label}`, props);
