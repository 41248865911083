var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "header",
      style: _vm.getHeaderStyle,
      on: { mouseleave: _vm.handleNavigatorMouseLeave }
    },
    [
      _c("NetflixMenu", { on: { handleScreenChange: _vm.handleScreenChange } }),
      _c(
        "router-link",
        {
          staticClass: "logo",
          attrs: {
            tag: "div",
            to: {
              name: "team",
              params: { teamSlug: _vm.$route.params.teamSlug }
            }
          }
        },
        [_c("an-logo", { attrs: { type: "mini" } })],
        1
      ),
      _c("div", { staticClass: "titles", style: _vm.getHeaderTitleStyle }, [
        _c(
          "div",
          { staticClass: "project-screen" },
          [
            _c(
              "router-link",
              {
                staticClass: "project-title",
                attrs: {
                  tag: "div",
                  to: {
                    name: "project",
                    params: {
                      teamSlug: _vm.$route.params.teamSlug,
                      projectId: _vm.$route.params.projectId
                    }
                  }
                }
              },
              [_vm._v(_vm._s(_vm.currentProject.name))]
            ),
            _c("svg-icon", {
              staticClass: "t-icon",
              attrs: { size: 16, fill: "currentColor", name: "arrow-right" }
            }),
            _c(
              "div",
              {
                staticClass: "screen-title",
                attrs: { id: "netflix-menu-trigger" },
                on: {
                  click: function($event) {
                    return _vm.toggleNavigator({})
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.currentComponent.name))]),
                _c("svg-icon", {
                  staticClass: "icon",
                  attrs: { size: 10, fill: "currentColor", name: "carret-down" }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isMobile,
                expression: "!isMobile"
              },
              {
                name: "tip",
                rawName: "v-tip",
                value: _vm.lastUpdateInfo,
                expression: "lastUpdateInfo"
              }
            ],
            staticClass: "info"
          },
          [
            _vm._v(
              " Screen " +
                _vm._s(_vm.getScreenNumber) +
                " of " +
                _vm._s(this.currentComponents.length) +
                ". " +
                _vm._s(_vm.updatedAt) +
                " "
            )
          ]
        )
      ]),
      !_vm.isMobile
        ? [
            _c(
              "div",
              { staticClass: "mode-breakpoints" },
              [
                _c(
                  "div",
                  { staticClass: "mode-selector" },
                  _vm._l(_vm.modes, function(mode, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "mode-container",
                        class: { selected: _vm.activeMode.name == mode.name },
                        attrs: { "data-cy": "mode-container" },
                        on: {
                          click: function($event) {
                            return _vm.handleModeChange({ mode: mode })
                          }
                        }
                      },
                      [
                        mode.name == "C"
                          ? [
                              _c(
                                "an-tip-big",
                                {
                                  attrs: {
                                    showBanner: true,
                                    trigger: "manual",
                                    open: _vm.openCodeTooltip,
                                    "data-cy": "explore-popover"
                                  },
                                  on: {
                                    close: function($event) {
                                      _vm.openCodeTooltip = false
                                    }
                                  }
                                },
                                [
                                  _c("template", { slot: "popover-caller" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-col items-center"
                                      },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "mode-icon",
                                          attrs: {
                                            fill: "currentColor",
                                            size: 20,
                                            name: mode.icon
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "mode-text" },
                                          [_vm._v(_vm._s(mode.displayName))]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("template", { slot: "popover-title" }, [
                                    _vm._v(" Explore Anima Code ")
                                  ]),
                                  _c("template", { slot: "popover-text" }, [
                                    _vm._v(
                                      " Get clean, Flexbox-based responsive code components in HTML, React or Vue, with zero dependencies and minimal code refactoring. "
                                    )
                                  ]),
                                  _c("template", { slot: "popover-footer" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/svg/tooltip-code-example.svg")
                                      }
                                    })
                                  ])
                                ],
                                2
                              )
                            ]
                          : [
                              _c("svg-icon", {
                                staticClass: "mode-icon",
                                attrs: {
                                  fill: "currentColor",
                                  size: 20,
                                  name: mode.icon
                                }
                              }),
                              _c("div", { staticClass: "mode-text" }, [
                                _vm._v(_vm._s(mode.displayName))
                              ])
                            ]
                      ],
                      2
                    )
                  }),
                  0
                ),
                !_vm.isComponentView
                  ? _c("BreakpointsSelector", {
                      on: {
                        breakpointChange: _vm.handleBreakpointChange,
                        handleScreenChange: _vm.handleScreenChange
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "v-tip",
                  { attrs: { trigger: "hover", offset: "10" } },
                  [
                    _vm.isAbleToPublish
                      ? _c(
                          "an-button",
                          {
                            attrs: {
                              isWorking: _vm.isPublishing,
                              variant: "secondary",
                              size: "sm"
                            },
                            on: { click: _vm.handlePublish }
                          },
                          [_vm._v("Publish")]
                        )
                      : _vm._e(),
                    _c("template", { slot: "popover" }, [
                      _c("div", { staticClass: "publish-tip" }, [
                        _vm._v(
                          " Publish your latest changes to your custom domain. "
                        )
                      ])
                    ])
                  ],
                  2
                ),
                _vm.isPublished
                  ? _c(
                      "an-button",
                      {
                        attrs: { variant: "secondary", size: "sm" },
                        on: { click: _vm.handleOpenCustomDomain }
                      },
                      [_vm._v(" Visit site ")]
                    )
                  : !_vm.isPro
                  ? _c(
                      "an-link",
                      {
                        staticClass: "go-pro-button",
                        attrs: { variant: "primary" },
                        on: { click: _vm.goPro }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "go-pro-button" },
                          [
                            _c("svg-icon", {
                              attrs: { name: "rocket-pro", size: 20 }
                            }),
                            _c("span", [_vm._v("Go Pro")])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "an-button",
                  {
                    staticStyle: { "margin-left": "15px" },
                    attrs: {
                      "data-cy": "header-export-button",
                      isWorking: _vm.isExportLoading,
                      size: "sm",
                      variant: "secondary"
                    },
                    on: { click: _vm.handleDownloadCodePopup }
                  },
                  [_vm._v(" Export Code ")]
                ),
                _c(
                  "an-button",
                  {
                    staticStyle: { "margin-left": "15px" },
                    attrs: { variant: "primary", size: "sm" },
                    on: { click: _vm.handleShare }
                  },
                  [_vm._v("Share")]
                ),
                _c(
                  "v-tip",
                  {
                    attrs: { trigger: "hover", delay: { show: 400, hide: 0 } }
                  },
                  [
                    _c(
                      "an-link",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "margin-top": "5px"
                        },
                        attrs: { "data-cy": "copy-link-button" },
                        on: { click: _vm.copyUrlToClipboard }
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "link", fill: "white", size: 24 }
                        })
                      ],
                      1
                    ),
                    _c("template", { slot: "popover" }, [
                      _c("div", { staticClass: "br-tip" }, [
                        _c("div", { staticClass: "br-tip-title" }, [
                          _vm._v(" Copy Link ")
                        ])
                      ])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }