<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">You need access</div>
    <div class="subtitle">
      Looks like you don't have permission to contribute to this project.
      <br />
      Let’s ask for access!
    </div>

    <div class="actions">
      <an-button @click="handleRequestAccess" :isWorking="isWorking">Request access</an-button>
      <an-link @click="handleCancel" primaryHover variant="dark">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { toastError, toastSuccess } from '@/services/bus';
export default {
  name: 'team-partial-access',
  data() {
    return {
      isWorking: false
    };
  },
  methods: {
    async handleRequestAccess() {
      try {
        const { teamSlug: team_slug } = this.$route.params;
        const payload = { team_slug };

        this.isWorking = true;
        this.$trackEvent('partial-access.request-access-button.click');

        await api.post('/rpc/request_access', payload);

        this.$trackEvent('partial-access.request-access.success');
        toastSuccess('Successfully sent request for access!');
        this.$emit('close');
      } catch (err) {
        console.error(err);
        this.$trackEvent('partial-access.request-access.failure', { message: err.message });
        toastError("Failed to request access. We've been notified and we will investigate!");
      } finally {
        this.isWorking = false;
      }
    },
    handleCancel() {
      this.$emit('close');
      this.$trackEvent('partial-access.cancel.click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
</style>
