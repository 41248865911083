var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "separated" },
      [
        _vm._m(0),
        _vm.isFilesLoading
          ? _c("DefaultLoader")
          : !_vm.computedFiles.length
          ? _c("div", { staticClass: "empty-state" }, [
              _c("div", { staticClass: "empty-state-title" }, [
                _vm._v("No files yet")
              ]),
              _c(
                "div",
                [
                  _vm._v(
                    " Upload videos and GIFs to use in your prototype and get a URL you can paste in the plugin. "
                  ),
                  _c(
                    "an-link",
                    {
                      attrs: {
                        variant: "primary",
                        href:
                          "https://docs.animaapp.com/v3/sketch/prototype/videos.html"
                      }
                    },
                    [_vm._v(" Learn more ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "empty-state-upload-button" },
                [
                  _c(
                    "Upload",
                    {
                      attrs: {
                        url: _vm.uploadUrl("file"),
                        accept: _vm.uploadAccept,
                        context: { type: "file" }
                      },
                      on: {
                        start: _vm.onUploadStart,
                        success: _vm.onUploadSuccess,
                        failure: _vm.onUploadFailure
                      }
                    },
                    [
                      _c(
                        "an-button",
                        {
                          attrs: {
                            variant: "secondary",
                            isWorking: _vm.isWorking
                          }
                        },
                        [_vm._v("Add files")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c(
              "div",
              { staticClass: "files-list" },
              [
                _c("an-tooltip", { attrs: { text: "Upload a new file" } }, [
                  _c(
                    "div",
                    { staticClass: "upload-image" },
                    [
                      _c("UploadImage", {
                        attrs: {
                          url: _vm.uploadUrl("file"),
                          accept: _vm.uploadAccept,
                          width: 110,
                          height: 64
                        },
                        on: {
                          success: _vm.onUploadSuccess,
                          start: _vm.onUploadStart
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._l(_vm.computedFiles, function(item) {
                  return _c(
                    "FileRow",
                    {
                      key: item.id,
                      attrs: {
                        item: item,
                        fileName: item.name,
                        uploadDate: item.updated_at,
                        actions: _vm.fileActions
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "file-thumbnail",
                          attrs: { slot: "thumbnail" },
                          slot: "thumbnail"
                        },
                        [
                          _vm.isVideo(item.ext)
                            ? _c("video", {
                                attrs: { src: item.url, muted: "" },
                                domProps: { muted: true }
                              })
                            : _c(
                                "RoundedImage",
                                {
                                  key: item.url,
                                  attrs: {
                                    width: 110,
                                    height: 64,
                                    src: _vm.getThumbnail(item)
                                  }
                                },
                                [_c("div", [_vm._v(_vm._s(item.ext))])]
                              )
                        ],
                        1
                      )
                    ]
                  )
                })
              ],
              2
            )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "separated" },
      [
        _c("div", { staticClass: "label" }, [_vm._v("Fonts")]),
        _vm.isFontsLoading
          ? _c("DefaultLoader")
          : !_vm.fontsList.length
          ? _c("div", { staticClass: "empty-state" }, [
              _c("div", { staticClass: "empty-state-title" }, [
                _vm._v("No fonts yet")
              ]),
              _c("div", [
                _vm._v(
                  " Here will be a list of the fonts this project members have uploaded. "
                )
              ])
            ])
          : _c(
              "div",
              { staticClass: "files-list" },
              _vm._l(_vm.fontsList, function(item) {
                return _c(
                  "FileRow",
                  {
                    key: item.id,
                    attrs: {
                      item: item,
                      fileName: item.family,
                      uploadDate: item.updated_at,
                      actions: _vm.fontActions
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "font-thumbnail",
                        style: _vm.fontThumbnailStyle(item),
                        attrs: { slot: "thumbnail" },
                        slot: "thumbnail"
                      },
                      [_vm._v(" Aa ")]
                    )
                  ]
                )
              }),
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-header" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Media")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }