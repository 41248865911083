var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showThankYou,
            expression: "showThankYou"
          }
        ],
        staticClass: "wrapper"
      },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("Thank you! We really appreciate your feedback")
        ])
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showThankYou,
            expression: "!showThankYou"
          }
        ],
        staticClass: "wrapper"
      },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("Help us understand what didn't work for you")
        ]),
        _c("div", { staticClass: "subTitle" }, [
          _vm._v("Why did you install Anima Chrome Extenstion?")
        ]),
        _vm._l(_vm.questions, function(item) {
          return _c(
            "div",
            {
              key: item.value,
              staticClass: "row",
              on: {
                click: function($event) {
                  return _vm.changeSelected(item.value)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "checkbox",
                  class: { checked: _vm.selected === item.value }
                },
                [_c("div", { staticClass: "checkbox_inner" })]
              ),
              _vm._v(" " + _vm._s(item.text) + " ")
            ]
          )
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selected == "other",
                expression: "selected == 'other'"
              }
            ]
          },
          [
            _c("an-textarea", {
              staticStyle: { "max-height": "auto" },
              attrs: {
                withBorder: "",
                autoFocus: "",
                placeholder: "Add your comment",
                allowMultiline: "",
                rows: "3"
              },
              model: {
                value: _vm.textarea,
                callback: function($$v) {
                  _vm.textarea = $$v
                },
                expression: "textarea"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button" },
          [
            _c(
              "an-button",
              {
                attrs: { disabled: !_vm.selected },
                on: { click: _vm.sendForum }
              },
              [_vm._v("Send")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }