import { createStoreModule } from '../utils';

const attachSourceToSubscriptions = (items = [], _service) => items.map(item => ({ ...item, _service }));

export default createStoreModule({
  name: 'stripeSubscription',
  modelName: 'subscriptions',
  crud: true,
  endpoint: '/v2/subscriptions',
  getters: {
    activeSubscription: (state, getters, rootState) => {
      const stripeItems = attachSourceToSubscriptions(state?.items, 'stripe');
      const paypalItems = attachSourceToSubscriptions(rootState.paypalSubscriptions?.items, 'paypal');
      const allItems = stripeItems.concat(paypalItems);
      return allItems.find(sub => !sub.is_deleted && ['active', 'past_due'].includes(sub.status)) || {};
    }
  }
});
