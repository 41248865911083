var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-cy": "first-team-page" } },
    [
      _c("TopBar"),
      _c(
        "div",
        { staticClass: "container dark" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("Create your team")]),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v("Name & personalize your workspace.")
          ]),
          _c(
            "div",
            { staticClass: "body" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("Team name")]),
              _c(
                "div",
                { staticClass: "input input-button-container" },
                [
                  _c("an-input", {
                    attrs: {
                      theme: _vm.theme,
                      invalid: _vm.invalidTeamName,
                      placeholder: "Team name",
                      "data-cy": "team-name-input"
                    },
                    on: {
                      focus: function($event) {
                        _vm.inputFocus = true
                      },
                      blur: _vm.onBlur,
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onBlur.apply(null, arguments)
                      },
                      change: function($event) {
                        _vm.invalidTeamName = !_vm.teamName
                      }
                    },
                    model: {
                      value: _vm.teamName,
                      callback: function($$v) {
                        _vm.teamName = $$v
                      },
                      expression: "teamName"
                    }
                  })
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showInviteForm
                  ? _c(
                      "div",
                      { staticClass: "team-members" },
                      [
                        _c("TeamMembers", {
                          attrs: { theme: _vm.theme, onboarding: "" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showInviteForm
              ? _c(
                  "div",
                  { staticClass: "actions dark" },
                  [
                    _c("an-button", { on: { click: _vm.onContinue } }, [
                      _vm._v("Invite & continue")
                    ]),
                    _c(
                      "span",
                      { staticClass: "link" },
                      [
                        _c(
                          "an-link",
                          {
                            attrs: {
                              theme: _vm.theme,
                              "data-cy": "team-invite-skip"
                            },
                            on: { click: _vm.onSkip }
                          },
                          [_vm._v("I'll do it later")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }