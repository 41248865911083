<template>
  <div class="notifications-popover-container" tabindex="-1" @focusout="close">
    <div ref="icon" class="icon" @click="toggleOpen">
      <svg-icon :name="iconName" :size="30" />
    </div>
    <Popover :isOpen="isOpen" :style="popoverStyles" @close="close">
      <div class="notifications-header">
        <span>Notifications</span>
        <an-link v-if="hasNewNotifications" variant="primary" @click="onMarkAllRead">Mark all as read</an-link>
      </div>
      <div class="notifications-body">
        <EmptyState v-if="!notifications.length" />
        <Notification
          v-else
          v-for="item in filteredNotifications"
          :key="item.id"
          :item="item"
          @click="onClick"
          @click-project="onClickProject"
          @mark-read="onMarkRead"
        />
      </div>
    </Popover>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Popover from '@/components/Popovers/Popover';
import Notification from '@/components/Notifications/Notification';
import EmptyState from '@/components/Notifications/EmptyState';
import { colorPathChildren } from '@/utils/colors';
import { SocketMixin } from '@/mixins';

const COMMENT = 'COMMENT';
const SYNC = 'SYNC';
const PROJECT_CREATED = 'PROJECT_CREATED';
const ROLE_APPROVED = 'ROLE_APPROVED';

export default {
  data() {
    return {
      clicked: false,
      isOpen: false,
      socket: null
    };
  },
  props: {
    iconColor: {
      type: String,
      default: 'var(--secondary)'
    }
  },
  components: {
    Popover,
    Notification,
    EmptyState
  },
  mixins: [SocketMixin],
  mounted() {
    this.reloadNotifications();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('notifications', { notifications: 'items' }),
    hasNewNotifications() {
      const { notifications } = this;
      return notifications.some(notification => notification.status === 'UNREAD');
    },
    iconName() {
      const { clicked, hasNewNotifications } = this;
      return hasNewNotifications && !clicked ? 'notifications-alert' : 'notifications';
    },
    filteredNotifications() {
      const { notifications } = this;
      return notifications.filter(n => [COMMENT, SYNC, PROJECT_CREATED, ROLE_APPROVED].includes(n.event_type));
    },
    popoverStyles() {
      return {
        width: this.isDesktop ? '400px' : '320px',
        height: this.isDesktop ? '650px' : '550px',
        padding: 0,
        right: this.isDesktop ? '-50px' : 0,
        boxShadow: 'var(--slightly-stronger-shadow)'
      };
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/fetchAllOfParent',
      updateNotification: 'notifications/update',
      setAsRead: 'notifications/setAsRead'
    }),
    ...mapMutations({ addNotification: 'notifications/unshiftItems' }),
    toggleOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$trackEvent('notifications.panel.open');
      } else {
        this.$trackEvent('notifications.panel.close');
      }
      this.clicked = true;
    },
    close() {
      this.isOpen = false;
    },
    navigateToProject({ workspace_slug: teamSlug, project: { id: projectId } }) {
      this.$router.push({ name: 'project', params: { teamSlug, projectId } }, () => {});
    },
    navigateToComment({ link: path }) {
      this.$router.push({ path }, () => {});
    },
    onClick(notification) {
      const { event_type: eventType } = notification;

      this.$trackEvent('notifications.notification.click');

      if (eventType === COMMENT) {
        this.navigateToComment(notification);
      } else if (eventType === SYNC) {
        this.navigateToProject(notification);
      }
      this.close();
    },
    onClickProject(notification) {
      this.$trackEvent('notifications.notification-project-link.click');

      this.navigateToProject(notification);
      this.close();
    },
    async onMarkAllRead() {
      try {
        const { notifications } = this;
        const unread = notifications.filter(n => n.status === 'UNREAD');

        this.$trackEvent('notifications.mark-all-read.click');

        await Promise.all(unread.map(n => this.onMarkRead(n, false)));
        this.reloadNotifications();
      } catch (err) {
        console.error(err);
      }
    },
    onMarkRead({ id }, trackEvent = true) {
      const payload = { status: 'READ' };

      if (trackEvent) this.$trackEvent('notifications.mark-read.click');

      this.setAsRead({ id });
      return this.updateNotification({ id, payload });
    },
    reloadNotifications() {
      const params = { order_by: '-created_at', page_size: 50 };
      return this.fetchNotifications({ parent: 'users', id: 'me', skipCache: true, params });
    },
    changeIconColor() {
      const { $refs, iconColor } = this;
      colorPathChildren($refs.icon, iconColor);
    },
    setSocket() {
      this.openSocket(this.user?.id);
      this.socket.on({ resource: 'notification', action: 'created' }, this.onNewNotification);
    },
    onNewNotification(notification) {
      this.clicked = false;
      this.addNotification(notification);
      // TODO: trigger push notifications?
      // if (event_type === SYNC && project.id === projectId) {
      //   pushNotification({
      //     notification,
      //     cta: 'Reload',
      //     onClick: () => {
      //       this.$trackEvent('notifications.push-notification.click');
      //       EventBus.$emit('reload-project-data');
      //       this.onMarkRead(notification);
      //     }
      //   });
      // }
    }
  },
  watch: {
    iconColor: {
      handler: 'changeIconColor',
      immediate: true
    },
    user: {
      handler: 'setSocket',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-popover-container {
  display: inline-block;
  position: relative;
  outline: none;
}
.icon {
  height: 100%;
  cursor: pointer;
  outline: none;
}
.notifications-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: var(--border);
  width: 100%;
  a {
    font-size: 12px;
  }
}
.notifications-body {
  overflow-y: scroll;
  height: 580px;
}
</style>
