<template>
  <div class="toggle-container" @click="onClick">
    <div v-if="offTitle" class="title-label" :class="{ 'title-label-on': !value }" :data-content="offTitle">
      {{ offTitle }}
    </div>
    <div class="toggle-label" v-if="$slots.default">
      <slot />
    </div>
    <input type="checkbox" :checked="value" hidden aria-hidden="true" />
    <div class="toggle">
      <div class="toggle-inner">
        <div v-html="require(`!html-loader!./${toggleStatus}.svg`)" />
      </div>
    </div>
    <div v-if="onTitle" class="title-label" :class="{ 'title-label-on': value }" :data-content="onTitle">
      {{ onTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-toggle',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    offTitle: {
      type: String,
      default: null
    },
    onTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    toggleStatus() {
      return this.value ? 'toggleOn' : 'toggleOff';
    }
  },
  methods: {
    onClick() {
      this.$emit('input', !this.value);
      this.$emit('change', !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.toggle-label {
  margin-right: 20px;
}
.toggle {
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  height: 24px;
  width: 48px;
  border-radius: 12px;
  transition: all 400ms;
  background-color: var(--tertiary);
  padding: 2px 3px;
}
.toggle .toggle-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  transition: all 400ms;
  background-color: var(--secondary);
  right: calc(100% - 20px - 3px);
}
input:checked + .toggle {
  background: var(--primary);
}
input:checked + .toggle .toggle-inner {
  background: var(--white);
  right: 3px;
}
.title-label {
  padding: 0 16px;
}
.title-label:before {
  display: block;
  content: attr(data-content);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.title-label-on {
  font-weight: bold;
}
</style>
