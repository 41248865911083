<template>
  <div
    @click="handleContainerClick"
    :style="{
      '--spacing-y': spacingY + 'px',
      '--spacing-x': spacingX + 'px',
      alignItems: centered ? 'center' : 'flex-start'
    }"
    :class="['radio-container', { full }]"
  >
    <input type="radio" :value="label" v-model="radioButtonValue" hidden />
    <div class="radio" v-html="require(`!html-loader!./${checkedStatus}.svg`)" @click="onClick" />
    <div class="radio-label">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'an-radio',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'check'
    },
    spacingY: {
      type: Number,
      default: 20
    },
    spacingX: {
      type: Number,
      default: 15
    },
    centered: {
      type: Boolean,
      default: true
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkedStatus() {
      if (this.type == 'check') {
        return this.value === this.label ? 'checked' : 'unchecked';
      } else {
        return this.value === this.label ? 'checked-dot' : 'unchecked-dot';
      }
    },
    radioButtonValue() {
      return this.value;
    }
  },
  methods: {
    handleContainerClick() {
      if (this.full) {
        this.onClick();
      }
    },
    onClick() {
      this.$emit('change', this.label);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-container {
  display: flex;
  justify-content: flex-start;
  + .radio-container {
    margin-top: var(--spacing-y);
  }

  &.full {
    cursor: pointer;
  }
}
.radio {
  cursor: pointer;
}
.radio-label {
  margin-left: var(--spacing-x);
  position: relative;
}
</style>
