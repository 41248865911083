<template>
  <div v-if="items.length">
    <transition-group name="slide-card" tag="div" v-if="itemsType === 'project'" mode="out-in" :style="gridStyle">
      <ProjectCard
        v-for="item in items"
        :key="item.id"
        :project="item"
        :actions="actions"
        :members="members"
        :subtitle="item.subtitle"
        :flags="item.flags"
        :forceShowActions="forceShowActions"
        @click="onClickCard(item)"
      />
    </transition-group>
    <div v-else-if="itemsType === 'screen'">
      <div v-if="sortBy === 'sections' && !filteredResults">
        <Sections :actions="actions" :items="items" />
      </div>
      <transition-group v-else name="slide-card" tag="div" mode="out-in" :style="gridStyle">
        <MasterScreenCard
          v-for="item in items"
          :key="item.id"
          :screen="item"
          :actions="actions"
          :flags="item.flags"
          :selected="item.isSelected"
          @click="onClickCard"
        />
      </transition-group>
    </div>
    <transition-group
      v-else-if="itemsType === 'component'"
      name="slide-card"
      tag="div"
      mode="out-in"
      :style="gridStyle"
    >
      <ComponentCard
        v-for="item in items"
        :key="item.id"
        :component="item"
        :actions="actions"
        @click="onClickCard(item)"
      />
    </transition-group>

    <transition-group
      v-else-if="itemsType === 'education-post'"
      name="slide-card"
      tag="div"
      mode="out-in"
      :style="gridStyle"
    >
      <EducationPostCard v-for="item in items" :key="item.id" :item="item" @click="onClickCard(item)" />
    </transition-group>

    <Pagination
      v-if="shouldShowPagination"
      :itemsType="readableItemType"
      :currentAmount="items.length"
      :totalAmount="totalAmount"
      :isLoadingMore="isLoadingMore"
      @load-more="$emit('load-more')"
    />
  </div>
</template>

<script>
import ProjectCard from '@/components/Project/ProjectCard';
import MasterScreenCard from '@/components/Project/MasterScreenCard';
import ComponentCard from '@/components/Project/ComponentCard';
import Sections from '@/components/Sections/Sections';
import Pagination from '@/components/Pagination/Pagination';
import EducationPostCard from '@/components/Learn/EducationPostCard';

export default {
  props: {
    itemsType: {
      type: String,
      required: true,
      validator: value => ['screen', 'project', 'component', 'education-post'].includes(value)
    },
    items: {
      type: Array,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    members: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: 'updated'
    },
    forceShowActions: {
      type: Boolean,
      default: false
    },
    filteredResults: {
      type: Boolean,
      default: false
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProjectCard,
    MasterScreenCard,
    EducationPostCard,
    ComponentCard,
    Sections,
    Pagination
  },
  computed: {
    gridStyle() {
      const { itemsType, isMobile } = this;
      let min, columnGap, rowGap;

      if (itemsType === 'education-post') {
        columnGap = '20px';
        rowGap = '60px';
        return {
          display: 'flex',
          flexWrap: 'wrap',
          columnGap,
          rowGap
        };
      }
      if (isMobile) {
        min = itemsType === 'project' ? '335px' : '120px';
        columnGap = '20px';
        rowGap = '30px';
      } else {
        min = itemsType === 'project' ? '300px' : '220px';
        columnGap = '30px';
        rowGap = '40px';
      }
      return {
        display: 'grid',
        justifyContent: 'start',
        columnGap,
        rowGap,
        gridTemplateColumns: `repeat(auto-fill, minmax(${min}, 1fr))`
      };
    },
    shouldShowPagination() {
      const { totalAmount, items, filteredResults } = this;
      return items.length < totalAmount && !filteredResults;
    },
    readableItemType() {
      const { itemsType } = this;
      return itemsType === 'education-post' ? 'video' : itemsType;
    }
  },
  methods: {
    onClickCard(data) {
      this.$emit(`click-${this.itemsType}`, data);
    },
    onClickEmptyStateCTA() {
      this.$trackEvent('cards-grid.empty-state-download-plugins.click');
      this.$router.push({ name: 'plugins' });
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-card-enter-active,
.slide-card-leave-active {
  transition: all 0.2s;
}
.slide-card-enter,
.slide-card-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.slide-card-move {
  transition: transform 0.2s;
}
</style>
