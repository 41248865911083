var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-state-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
    _c(
      "div",
      { staticClass: "cta" },
      [
        _c(
          "an-button",
          {
            attrs: { variant: "secondary" },
            on: {
              click: function($event) {
                return _vm.$emit("click-cta")
              }
            }
          },
          [_vm._v(_vm._s(_vm.linkLabel))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }