<template>
  <span>
    You’re on the free plan, upgrade to unlock more projects.&nbsp;
    <an-link @click="onUpgradeClick" variant="primary">Upgrade now</an-link>
  </span>
</template>

<script>
import { TeamMixin } from '@/mixins';

export default {
  name: 'upgrade-team-plan-banner',
  mixins: [TeamMixin],
  methods: {
    onUpgradeClick() {
      this.$trackEvent('upgrade-team-plan-banner.upgrade.click');
      this.openUpgradeLockedModal();
    }
  }
};
</script>
