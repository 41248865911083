import { hideIntercomBubble, showIntercomBubble } from '@/services/bus';
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      timeoutInSeconds: Math.random() + 1,
      timeoutId: null
    };
  },
  mounted() {
    if (this.onboardingDetails) {
      this.setOnboardingTimeout();
    }
  },
  destroyed() {
    this.destroyOnboardingTimeout();
  },
  computed: {
    ...mapGetters('webappSystem', ['onboardingDetails']),
    ...mapGetters('users', ['firstName'])
  },
  methods: {
    ...mapMutations('webappSystem', ['setOnboardingDetails']),
    getProps(intent) {
      const { firstName } = this;
      const message = `Hi ${firstName}!\nI’d love to show you how to <b>${intent}</b> in the best way, shall we schedule a demo?`;
      let props;
      props = {
        contact: 'support',
        message,
        onClick: () => {
          this.$trackEvent('onboarding-intercom-bubble.schedule-demo.click', { intent, message });
          window.open('https://calendly.com/anima-team/15min', '_blank');
        },
        hideTimeout: 20 * 1000 // 20 seconds
      };
      return props;
    },
    setOnboardingTimeout() {
      const { intent } = this.onboardingDetails;
      if (!intent) return;
      const props = this.getProps(intent);

      this.timeoutId = setTimeout(() => {
        showIntercomBubble({ props });
        this.setOnboardingDetails(null);
      }, this.timeoutInSeconds * 1000);
    },
    destroyOnboardingTimeout() {
      clearTimeout(this.timeoutId);
      hideIntercomBubble();
    }
  }
};
