import { EventBus, openModal } from '@/services/bus';
import { readCookie } from '@/utils/cookie';
import { OPEN_PANEL, SELECT_OVERRIDE_NODE, SEND_COMPONENT_MESSAGE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { isSameRoute, objectMap, updateArrayItemById } from '@/utils/javascript';
import axios from 'axios';
import { get, has } from 'lodash-es';
import StyleToObject from 'style-to-object';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getNodeMd5Array, findAndMatch } from './modelUtils';
import { analyzeCode, patchInstanceFromMaster } from './utils';
import cache from 'memory-cache';
import dayjs from 'dayjs';
import { uuid } from '@/utils/uuid';
import { findNodeWorker, multipleSelectWorker } from './workerFunctions';
// import { uuid } from '@/utils/uuid';
import promiseRetry from 'promise-retry';
import { getParameters } from 'codesandbox-import-utils/lib/api/define';

const CODEGEN_BASE_URL = process.env.CODEGEN_BASE_URL || 'https://codegen.animaapp.com';

const codegenMixin = {
  data() {
    return {
      fontsMap: false,
      isFetchingFonts: false,
      firstLoad: true,
      slugifyLoading: false,
      generateController: { abort: () => {} }
    };
  },
  mounted() {
    EventBus.$on('generate-code', this.generateCode);
    EventBus.$on('generateCodeWithoutIframe', this.generateCodeWithoutIframe);
    EventBus.$on('add-component-to-library', this.addComponentToLibrary);
    EventBus.$on('open-component-in-library', this.openComponentInLibrary);
    EventBus.$on('pre-process', this.preProcessComponent);
    EventBus.$on('populate-component-frame', this.populateComponentFrame);
    EventBus.$on('reject-suggestion', this.rejectSuggestion);
    EventBus.$on('open-component-interface', this.openComponentInterface);
    EventBus.$on('update-component-preview', this.updateComponentPreview);
    EventBus.$on('select-node', this.selectNodeById);

    document.addEventListener('keydown', this.handleKeydown);
  },
  destroyed() {
    EventBus.$off('generate-code', this.generateCode);
    EventBus.$off('generateCodeWithoutIframe', this.generateCodeWithoutIframe);
    EventBus.$off('add-component-to-library', this.addComponentToLibrary);
    EventBus.$off('open-component-in-library', this.openComponentInLibrary);
    EventBus.$off('pre-process', this.preProcessComponent);
    EventBus.$off('populate-component-frame', this.populateComponentFrame);
    EventBus.$off('reject-suggestion', this.rejectSuggestion);
    EventBus.$off('open-component-interface', this.openComponentInterface);
    EventBus.$off('update-component-preview', this.updateComponentPreview);
    EventBus.$off('select-node', this.selectNodeById);

    document.removeEventListener('keydown', this.handleKeydown);
  },
  computed: {
    ...mapState('projects', { assetsRegistry: 'assetsRegistry' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('omniview', { isWaitingForOverrides: 'isWaitingForOverrides' }),
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('projects', { registryUrl: 'registryUrl' }),
    ...mapState('componentsMetadata', { metadatas: 'items' }),
    ...mapState('webComponents', { DbWebComponents: 'items' }),

    ...mapGetters({
      playgroundCode: 'omniview/playgroundCode',
      isGeneratingPlaygroundCode: 'omniview/isGeneratingPlaygroundCode',
      activeMode: 'omniview/activeMode',
      nodes: 'omniview/nodes',
      currentNode: 'omniview/currentNode',
      codegenLang: 'omniview/codegenLang',
      codegenStylesheetLang: 'omniview/codegenStylesheetLang',
      lastGeneratedId: 'omniview/lastGeneratedId',
      modes: 'omniview/modes',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      queue: 'omniview/queue',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      isGeneratingCode: 'omniview/isGeneratingCode',
      currentMasterSlug: 'omniview/currentMasterSlug',
      nodesWithOverrides: 'omniview/nodesWithOverrides',
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      isMultipleSelectionEnabled: 'omniview/isMultipleSelectionEnabled',
      multiSelectedNodes: 'omniview/multiSelectedNodes',
      captureType: 'omniview/captureType',
      isGeneratingCapture: 'omniview/isGeneratingCapture',
      suggestedComponents: 'webComponents/suggestedComponents',
      getAllWebComponentsOverrides: 'webComponents/getAllWebComponentsOverrides',
      currentWebComponent: 'webComponents/currentWebComponent',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      isWebComponentsLoading: 'webComponents/isWebComponentsLoading',
      webInstancesMap: 'webComponents/webInstancesMap',
      isSuggestion: 'webComponents/isSuggestion',
      isSuggestionById: 'webComponents/isSuggestionById',
      currentWebComponentNode: 'webComponents/currentWebComponentNode',
      isComponentOrSuggestionById: 'webComponents/isComponentOrSuggestionById',
      currentStyleguide: 'styleguide/currentStyleguide',
      codegenReactStyle: 'omniview/codegenReactStyle',
      slugsMap: 'omniview/slugsMap',
      styleType: 'omniview/styleType',
      modelNodesMap: 'omniview/modelNodesMap'
    }),

    mappedRouteToMode() {
      let { mode } = this.$route.query;
      let m;
      switch (mode) {
        case 'play':
          m = this.modes[0];
          break;
        case 'comments':
          m = this.mode[1];
          break;
        case 'code':
          m = this.modes[2];
          break;
        default:
          m = this.modes[0];
      }
      return m;
    },

    isPreSync() {
      return (
        this.$route.name == 'syncPreview' || this.$route.name == 'releaseOmniview' || this.$route.name == 'syncWebsite'
      );
    },
    isReleaseOmni() {
      return this.$route.name == 'releaseOmniview';
    },

    getCodegenHeaders() {
      return {
        'Content-Type': 'application/json; charset=utf-8',
        'plugin-name': 'OmniView',
        'user-id': this.currentUser.id,
        'project-id': this.currentProject.id,
        'release-id': this.currentRelease.id,
        'origin-url': window.location.href
      };
    }
  },
  methods: {
    ...mapMutations({
      setPlaygroundCode: 'omniview/setPlaygroundCode',
      setIsGeneratingPlaygroundCode: 'omniview/setIsGeneratingPlaygroundCode',
      setIsExportingPlaygroundCode: 'omniview/setIsExportingPlaygroundCode',
      resetPlaygroundCode: 'omniview/resetPlaygroundCode',
      setComponentViewData: 'omniview/setComponentViewData',
      setCurrentWebComponent: 'webComponents/setCurrentWebComponent',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      setCurrentNode: 'omniview/setCurrentNode',
      setLastGeneratedId: 'omniview/setLastGeneratedId',
      setCurrentNodePath: 'omniview/setCurrentNodePath',
      setBreakpoints: 'omniview/setBreakpoints',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setIsFullScreen: 'omniview/setIsFullScreen',
      setCurrentNodeHTML: 'omniview/setCurrentNodeHTML',
      setCurrentNodeJSX: 'omniview/setCurrentNodeJSX',
      setCurrentNodeCSS: 'omniview/setCurrentNodeCSS',
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      selectScreen: 'components/setCurrentItem',
      setCurrentComponentData: 'components/setCurrentComponentData',
      setProjectAssetsRegistry: 'projects/setProjectAssetsRegistry',
      setRegistryUrl: 'projects/setRegistryUrl',
      setIsWaitingForOverrides: 'omniview/setIsWaitingForOverrides',
      setIsExportAllowed: 'omniview/setIsExportAllowed',
      setNodes: 'omniview/setNodes',
      setMetadata: 'componentsMetadata/setMetadata',
      setCommentsSubView: 'omniview/setCommentsSubView',
      resetSelection: 'omniview/resetSelection',
      setDomLoading: 'omniview/setDomLoading',
      setIframeLoading: 'omniview/setIframeLoading',
      setCurrentMasterSlug: 'omniview/setCurrentMasterSlug',
      setNodesWithOverrides: 'omniview/setNodesWithOverrides',
      setCodegenLang: 'omniview/setCodegenLang',
      setModelNodesMap: 'omniview/setModelNodesMap',
      setMultiSelectedNodes: 'omniview/setMultiSelectedNodes',
      setCurrentNodeMd5Map: 'omniview/setCurrentNodeMd5Map',
      setIsGeneratingCapture: 'omniview/setIsGeneratingCapture',
      setIsModelLoading: 'omniview/setIsModelLoading',
      setComponentViewSize: 'omniview/setComponentViewSize',
      setIsPreprocessing: 'webComponents/setIsPreprocessing',
      setIsPopulatingComponentFrame: 'webComponents/setIsPopulatingComponentFrame',
      setWebComponents: 'webComponents/setWebComponents',
      setIsWebComponentsLoading: 'webComponents/setIsWebComponentsLoading',
      setSlugsMap: 'omniview/setSlugsMap'
    }),
    ...mapActions({
      fetchReleaseModel: 'releases/fetchReleaseModel',
      fetchWebComponents: 'codegen/fetchWebComponents',
      fetchSingleComponent: 'components/fetchOne',
      fetchModelFromUrl: 'releases/fetchModelFromUrl',
      fetchProjectComments: 'comments/fetchComments',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchMetadata: 'componentsMetadata/fetchMetadata',
      updateMetadata: 'componentsMetadata/update',
      createMetadata: 'componentsMetadata/create',
      fetchProject: 'projects/fetchOne',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchRelease: 'releases/fetchOne',
      fetchComponents: 'components/fetchAllOfParent',
      handleModeChange: 'omniview/handleModeChange',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      cleanup: 'omniview/cleanup',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      getNodesWithOverridesData: 'omniview/getNodesWithOverridesData',
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      fetchDBWebComponents: 'webComponents/fetchDBWebComponents',
      updateDBWebComponent: 'webComponents/update'
    }),

    // ------  MODEL UTILS ------

    getNodeMd5Array,
    findAndMatch,

    // ------

    selectNodeById(data) {
      EventBus.$emit(SEND_MESSAGE, {
        action: SELECT_OVERRIDE_NODE,
        data
      });
    },

    async slugifyScreensNames(text) {
      if (this.slugifyLoading) return;
      try {
        if (text.every(x => has(this.slugsMap, x))) return;
        let map = {};
        this.slugifyLoading = true;
        const { data } = await axios.post('rpc/slugify', {
          text
        });

        let slugs = data.result;
        for (let i = 0; i < text.length; i++) {
          map[text[i]] = slugs[i];
        }
        this.setSlugsMap(map);
      } catch (e) {
        this.$trackEvent('omniview.slugify.failed');
      } finally {
        this.slugifyLoading = false;
      }
    },

    async populateComponentFrame({ iframeName = 'componentIframe', nodeId = null } = {}) {
      try {
        this.setIsPopulatingComponentFrame({ iframeName, flag: true });

        let { instance } = this.currentWebComponent;
        const { layer } = this.$route.query;

        let _nodeId = layer || this.currentNode.id;

        if (!instance && nodeId) {
          instance = { model_id: nodeId };
        }
        if (!instance && !nodeId) {
          instance = { model_id: _nodeId };
        }

        if (nodeId) {
          _nodeId = nodeId;
        }
        const docFrame = window.frames[iframeName].document;

        docFrame.open();
        // docFrame.write('');
        const { subModel, modelNode } = await this.getNodeSubModel({ nodeId: _nodeId });

        await this.$waitFor(() => this.modelNodesMap, null, { notEmpty: true });

        let { height, width } = this.modelNodesMap[_nodeId] || { width: 0, height: 0 };

        let padding = 2;

        if (iframeName == 'componentIframeInterface') {
          height = height + 20;
          padding = 0;
        }
        this.setComponentViewSize({ iframeName, data: { width: width + padding, height: height + padding } });

        const cacheKey = `${iframeName}-hosted-${instance.model_id};`;

        const [model_overrides, md5_map] = await Promise.all([this.getModelOverrides(), this.getNodeMd5Map(modelNode)]);
        const settings = await this.getCodegenSettings({
          framework: 'html',
          fontsMap: this.fontsMap || {},
          md5Map: md5_map,
          overrides: model_overrides,
          shrink: true,
          presetSettings: 'high_fidelity'
        });

        let res;

        let cachedData = cache.get(cacheKey);

        if (cachedData == -1) {
          res = cachedData;
        } else {
          res = await this.makeCodegenRequest({
            mode: 'hosted',
            model: subModel,
            settings: {
              ...settings,
              preset_settings: 'high_fidelity'
            }
          });
          cache.put(cacheKey, res);
        }

        const slug = res['homepage_slug'];
        const html = res.files[`${slug}.html`];

        const doc = new DOMParser().parseFromString(html, 'text/html');

        doc.querySelector('#anima-hotspots-script').remove();
        doc.querySelector('#anima-overrides-script').remove();

        const animaScripts = doc.createElement('script');

        animaScripts.setAttribute('src', 'https://animaapp.s3.amazonaws.com/static/anima.min.js');

        animaScripts.setAttribute('defer', '');

        doc.head.appendChild(animaScripts);

        let iframeBodyStyle = {};
        if (iframeName === 'componentIframeInterface') {
          const { width: componentWidth = 1, height: componentHeight = 1 } = this.modelNodesMap[_nodeId] || {};
          const [iframeWidth, iframeHeight] = [550, 375];
          const widthRatio = iframeWidth / (componentWidth + 20);
          const heightRatio = iframeHeight / (componentHeight + 20);

          const zoom = Math.min(widthRatio, heightRatio, 1);
          iframeBodyStyle = { zoom, '-moz-transform': `scale(${zoom})` };
        }

        let body = doc.querySelector('body');
        if (body) {
          const documentBg = body.style.backgroundColor;
          const backgroundColor = documentBg ? documentBg : '#fff';
          Object.assign(body.style, {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor,
            ...iframeBodyStyle
          });
        }

        let style = doc.createElement('style');

        style.innerHTML = `
        [class*="intercom"]{
          display:none
        }
        `;

        doc.head.appendChild(style);

        // let intercom = doc.querySelectorAll('[class*="intercom"]');
        // console.warn(intercom);

        let artboard = doc.querySelector('.artboard');
        if (artboard) {
          Object.assign(artboard.style, {
            minWidth: 'auto'
            // minHeight: 'auto'
          });
        }
        // let el = doc.querySelector('.artboard > div');
        // if (el) {
        //   Object.assign(el.style, {
        //     position: 'static'
        //     // minHeight: 'auto'
        //   });
        // }

        docFrame.write(doc.documentElement.innerHTML);

        docFrame.close();
      } catch (error) {
        console.warn(error);
      } finally {
        this.setIsPopulatingComponentFrame({ iframeName, flag: false });
      }
    },

    async startMiniProcessing({ customSettings = {} } = {}) {
      const { screenSlug } = this.$route.params;
      const { layer } = this.$route.query;
      const { subModel, modelNode } = await this.getNodeSubModel();

      const [model_overrides, md5_map] = await Promise.all([this.getModelOverrides(), this.getNodeMd5Map(modelNode)]);

      const settings = await this.getCodegenSettings({
        framework: this.codegenLang,
        fontsMap: this.fontsMap || {},
        md5Map: md5_map,
        overrides: model_overrides,
        customSettings,
        original_screen_slug: screenSlug,
        presetSettings: 'clean_code'
      });

      let propEnableMap = {};
      let preProcessDataCopy = JSON.parse(JSON.stringify(settings.pre_process_data));

      // debugger;

      const { master: currentSelectedMaster } = this.getMasterAndInstanceByNodeId(layer);

      Object.keys(settings.pre_process_data)
        .filter(masterId => !!settings['pre_process_data'][masterId]['props'])
        .forEach(masterId => {
          const data = settings['pre_process_data'][masterId];

          const rProps = data.props['regular'];
          const nProps = data.props['nested'];

          const props = [...rProps, ...nProps];

          if (masterId == currentSelectedMaster['master_id']) {
            for (let i = 0; i < props.length; i++) {
              const prop = props[i];
              propEnableMap[prop['name']] = {
                master_id: masterId,
                is_enable: prop['is_enable']
              };
            }
          }

          preProcessDataCopy[masterId] = {
            ...preProcessDataCopy[masterId],
            props: {
              regular: rProps.map(p => ({ ...p, is_enable: true })),
              nested: nProps.map(p => ({ ...p, is_enable: true }))
            }
          };
        });

      settings['pre_process_data'] = preProcessDataCopy;
      settings['web_components_enable'] = true;

      let res = await this.makeCodegenRequest({
        mode: 'pre_process',
        model: subModel,
        settings
      });

      const { master_components } = res;

      const new_master_components = master_components.map(master => {
        const rProps = master.props['regular'];
        const nProps = master.props['nested'];

        return {
          ...master,
          props: {
            regular: rProps.map(prop => {
              return {
                ...prop,
                ...(propEnableMap[prop['name']] && { is_enable: propEnableMap[prop['name']]['is_enable'] })
              };
            }),
            nested: nProps.map(prop => ({
              ...prop,
              ...(propEnableMap[prop['name']] && { is_enable: propEnableMap[prop['name']]['is_enable'] })
            }))
          }
        };
      });

      res['master_components'] = new_master_components;
      this.setTeamSlugForAllInstances(res['components_instances']);
      this.setTeamSlugForAllInstances(res['master_components']);
      return res;
    },
    setTeamSlugForAllInstances(instances) {
      const { screenSlug } = this.$route.params;
      for (let instance of instances) {
        instance.screen_slug = screenSlug;
      }
    },
    async checkForProjectProcessing() {
      const { projectId } = this.$route.params;
      const { is_pre_process_running } = await this.fetchProject({ id: projectId, skipCache: true });
      return is_pre_process_running;
    },

    async triggerFullPreProcess() {
      const { projectId } = this.$route.params;
      return axios.post(`/project/${projectId}/trigger_preprocess`, {});
    },

    async preProcessComponent({ forcePreProcess = false, customSettings = {} } = {}) {
      try {
        const { projectId } = this.$route.params;
        const { layer } = this.$route.query;
        const { id: model_id } = this.currentNode;
        const nodeId = layer || model_id;

        this.setIsPreprocessing(true);

        let is_pre_process_running;

        if (forcePreProcess) {
          is_pre_process_running = true;
        } else {
          is_pre_process_running = await this.checkForProjectProcessing();
        }

        let preProcessData = { components_instances: [], master_components: [] };

        const makeCodegenPreprocessCall = async () => {
          preProcessData = await this.startMiniProcessing({
            customSettings
          });
          const { components_instances, master_components } = preProcessData;

          const instance = components_instances.find(c => c.model_id == nodeId);

          if (!instance) {
            throw new Error('web component instance not found');
          }

          let master = master_components.find(c => c.master_id == instance.master_id);

          if (!master) {
            throw new Error('web component master not found');
          }
          master = { ...get(this.currentWebComponent, 'master', {}), ...master, subsIds: [] };

          this.setCurrentWebComponent({
            master,
            instance: patchInstanceFromMaster(instance, master)
          });
        };

        if (!is_pre_process_running) {
          await this.fetchDBWebComponents({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: {
              skip_cache: true,
              get_all: true
            }
          });

          const { master: foundMaster, instance: foundInstance } = this.getMasterAndInstanceByNodeId(nodeId);
          if (!foundMaster) {
            await makeCodegenPreprocessCall();
          } else {
            const { instances } = foundMaster;
            preProcessData = {
              components_instances: instances,
              master_components: [foundMaster]
            };

            let master = {
              ...get(this.currentWebComponent, 'master', {}),
              ...foundMaster,
              component_name: foundMaster.name
            };

            this.setCurrentWebComponent({
              master,
              instance: patchInstanceFromMaster(foundInstance, master)
            });
          }
        } else {
          await makeCodegenPreprocessCall();
        }

        this.setIsPreprocessing(false);

        return preProcessData;
      } catch (error) {
        console.log(error);
        this.setIsPreprocessing(false);
      }
    },

    async rejectSuggestion({ onDoneEvent = false }) {
      // const { projectId } = this.$route.params;
      const { id } = this.currentNode;
      const { layer } = this.$route.query;
      const nodeId = id || layer;

      const { master } = this.getMasterAndInstanceByNodeId(nodeId);

      const newItems = updateArrayItemById(this.DbWebComponents, master.id, {
        is_live: false,
        is_suggestion: false
      });

      this.setWebComponents(newItems);

      await promiseRetry(
        doRetry => {
          return this.updateDBWebComponent({
            id: master.id,
            payload: {
              is_live: false,
              is_suggestion: false
            }
          }).catch(doRetry);
        },
        { retries: 3 }
      );

      if (onDoneEvent) {
        EventBus.$emit(onDoneEvent);
      }
    },

    updateComponentPreview({ id = null, iframeName = 'main' } = {}) {
      let nodeId = id;
      if (!nodeId) {
        nodeId = this.$route.query.layer;
      }

      let msg = iframeName == 'main' ? SEND_MESSAGE : SEND_COMPONENT_MESSAGE;
      EventBus.$emit(msg, {
        action: 'get-preview',
        iframeName,
        data: {
          nodeId,
          isLocal: true
        }
      });
    },

    async openComponentInterface() {
      this.$trackEvent('omniview.component-edit.click');
      openModal({
        name: 'component-interface',
        variant: 'center',
        opacity: 0.4,
        width: 1168,
        closeButton: true,
        props: { eventSource: 'omniview' }
        // onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
      });

      await this.$nextTick();
      this.populateComponentFrame({ iframeName: 'componentIframeInterface' });
    },

    CreateFakeWebComponentOrUpdate(id) {
      const { layer } = this.$route.query;
      const nodeId = id ? id : layer;

      const { master } = this.getMasterAndInstanceByNodeId(nodeId);

      let newItems = [];

      if (!master) {
        const master_id = uuid();
        newItems = [
          ...this.DbWebComponents,
          {
            id: uuid(),
            master_id,
            is_live: true,
            is_suggestion: false,
            screenshot_url: '',
            name: '',
            instances: [
              {
                master_id,
                is_primary: true,
                model_id: nodeId,
                props: { regular: [], nested: [] },
                screen_slug: '',
                usage_code: ''
              }
            ],
            subsIds: []
          }
        ];
      } else {
        newItems = updateArrayItemById(
          this.DbWebComponents,
          master.master_id,
          {
            is_live: true,
            is_suggestion: false
          },
          'master_id'
        );
      }
      this.setWebComponents(newItems);
    },

    async addComponentToLibrary({
      onDoneEvent = false,
      preProcessParams = {
        forcePreProcess: true,
        customSettings: {
          auto_detect_component: true,
          generate_hosted_html_for_primary_instances: 'true'
        }
      }
    } = {}) {
      const { projectId } = this.$route.params;
      this.setIsPreprocessing(true);
      this.updateComponentPreview();
      this.CreateFakeWebComponentOrUpdate();

      const pre_process_data = await this.preProcessComponent(preProcessParams);

      // send add to library request and refetch components
      if (!Object.keys(pre_process_data).length) {
        this.$sentry.captureMessage('addComponentToLibrary: pre_process_data is empty');
        return;
      }
      axios
        .post(`/project/${projectId}/web_component`, {
          pre_process_data
        })
        .finally(() => {
          if (onDoneEvent) {
            EventBus.$emit(onDoneEvent);
          }
        });
    },

    showSuggestionsPeriodically({ scope = 'project' } = {}) {
      const savedTime = localStorage.getItem(`${scope}_lastShownSuggestionTimestamp`);
      if (savedTime) {
        const hoursSince = dayjs(new Date(Date.now()).toISOString()).diff(
          dayjs(new Date(parseInt(savedTime)).toISOString()),
          'hour'
        );
        if (hoursSince >= 0) {
          this.checkSuggestedComponents({ scope });
        }
      } else {
        this.checkSuggestedComponents({ scope });
      }
    },

    async checkSuggestedComponents({ scope }) {
      await this.$waitFor(() => this.isWebComponentsLoading, false);

      const instancesIds = [];

      for (let i = 0; i < this.suggestedComponents.length; i++) {
        const wc = this.suggestedComponents[i];
        const wci = wc.instances || [];
        const primaryInstance = wci.find(i => i.is_primary);
        if (primaryInstance) {
          instancesIds.push(primaryInstance.model_id);
        } else {
          instancesIds.push(wci[0].model_id);
        }
      }

      let matches = [];

      if (scope == 'nested') {
        const { modelNode } = await this.getNodeSubModel();
        matches = this.findAndMatch(modelNode, instancesIds);
      } else {
        matches = instancesIds;
      }

      if (matches.length > 0) {
        openModal({
          name: 'component-interface',
          variant: 'center',
          opacity: 0.4,
          width: 660,
          closeButton: true,
          props: { eventSource: 'omniview', isSuggestions: true, suggestedNodeIds: matches, suggestionsScope: scope }
        });
      }
    },

    async openComponentInLibrary({
      nodeId = null,
      preProcessParams = {},
      openComponentInterface = false,
      onDoneEvent = null
    } = {}) {
      return new Promise((resolve, reject) => {
        let model_id;
        const q = { ...this.$route.query };

        model_id = nodeId ? nodeId : q.layer;

        const { primaryInstance } = this.getMasterAndInstanceByNodeId(model_id);

        model_id = primaryInstance ? primaryInstance.model_id : model_id;

        let newRoute = { ...this.$route, query: { ...q, component: model_id, layer: model_id } };

        const done = async () => {
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [this.$route.query.layer]: true });
          this.populateComponentFrame({ nodeId: model_id });
          this.generateCode({ _nodeId: model_id });
          const data = await this.preProcessComponent(preProcessParams);
          // this.checkSuggestedComponents({ scope: 'nested' });
          if (onDoneEvent) {
            const { name, params } = onDoneEvent;
            EventBus.$emit(name, params);
          }
          openComponentInterface && this.openComponentInterface();

          resolve(data);
        };

        if (isSameRoute(newRoute, this.$route)) {
          done();
          return;
        }

        this.$router
          .push(newRoute)
          .then(() => {
            done();
          })
          .catch(e => {
            reject(e);
            console.log(e);
          });
      });
    },

    getBaseCodegenSettings() {
      const { id: releaseId } = this.currentRelease;
      const { cdn_distribution_domain: cdnDomain } = this.currentProject;

      const codegenSettings = {
        base_dir_images: `https://${cdnDomain || 'anima-uploads.s3.amazonaws.com'}/releases/${releaseId}/img/`,
        web_components_enable: false,
        vendor_prefix: '',
        is_for_playground_editor: false,
        shrink_views_frames_to_fit_subviews: false,
        md5_to_url: {},
        model_overrides: {},
        fonts_map: {}
      };

      return codegenSettings;
    },

    async getCodegenSettings({
      overrides = {},
      md5Map = {},
      fontsMap = {},
      framework = 'html',
      isMultipleSelect = false,
      shrink = false,
      isCodeSandbox = false,
      isCodePen = false,
      customSettings = {},
      presetSettings = 'clean_code',
      useModel = true
    } = {}) {
      let settings = this.getBaseCodegenSettings();

      let md5_to_url = {};
      Object.keys(md5Map).forEach(key => {
        md5_to_url[key] = md5Map[key].url || '';
      });

      settings['shrink_views_frames_to_fit_subviews'] = isMultipleSelect || shrink;
      settings['md5_to_url'] = md5_to_url;
      settings['fonts_map'] = fontsMap;
      settings['model_overrides'] = overrides;
      settings['is_display_data_id'] = !isCodePen && !isCodeSandbox;

      // possible values: 'clean_code' | 'high_fidelity'
      // clean_code: for code displayed by the users.
      // high_fidelity: for prototypes in iframes
      settings['preset_settings'] = presetSettings;

      // console.warn(`SENT overrides`, this.getAllWebComponentsOverrides);
      const { layer } = this.$route.query;
      if (useModel) {
        let _nodeId = layer || this.currentNode.id;
        let { modelNode } = await this.getNodeSubModel({ nodeId: _nodeId });
        settings['pre_process_data'] = this.getAllWebComponentsOverrides(modelNode);
      }

      settings['styleguide_data'] = {
        classes: get(this.currentStyleguide, 'classes', {}),
        tokens: get(this.currentStyleguide, 'tokens', {})
      };

      settings = {
        ...settings,
        ...customSettings
      };

      // External Editor Settings
      settings['is_for_playground_editor'] = isCodePen;
      settings['use_url_from_md5'] = isCodeSandbox;

      switch (framework) {
        case 'html':
          settings = {
            ...settings,
            auto_flexbox_enabled: this.codegenHTMLLayout == 'flexbox'
          };
          break;

        case 'react':
          settings = {
            ...settings,
            auto_flexbox_enabled: true,
            web_components_enable: true,
            web_framework: 'React',
            web_framework_settings: {
              component_type: this.codegenReactSyntax,
              styled_components: this.codegenReactStyle == 'styled',
              stylesheet_syntax: this.codegenStylesheetLang
            }
          };
          break;
        case 'vue':
          settings = {
            ...settings,
            auto_flexbox_enabled: true,
            web_components_enable: true,
            web_framework: 'Vue',
            web_framework_settings: {
              stylesheet_syntax: this.codegenStylesheetLang
            }
          };
          break;
      }

      return settings;
    },

    async getNodeSubModel({ isMultipleSelect = false, nodeId = null } = {}) {
      const { model } = await this.getModelAndScreen({ slim: false, waitForSlugifyCall: true });

      let _nodeId = nodeId;
      if (!_nodeId) {
        _nodeId = this.$route.query.layer || this.currentNode.id || (await this.getCurrentScreenNodeId());
      }

      let found = await this.$worker.run(findNodeWorker, [model, _nodeId]);

      if (!found) {
        throw new Error('Node not found');
      }

      if (isMultipleSelect) {
        found = await this.$worker.run(multipleSelectWorker, [found, this.multiSelectedNodes]);
      }

      const subModel = {
        ...model,
        screens: [found]
      };

      return { subModel, modelNode: found, model };
    },
    //get the node without the iframe
    async generateCodeWithoutIframe({ nodeId = '', fileId = '', figma_access_token = '' }) {
      const transaction = this.$sentry.startTransaction({ name: 'generate-code-for-chrome-extension' });
      // const span = transaction.startChild({
      //   op: 'task',
      //   description: `init data`,
      // });

      this.setCurrentNode(nodeId);
      this.lastLoadNodeId = nodeId;
      try {
        const codegenSettings = await this.getCodegenSettings({
          framework: this.codegenLang,
          fontsMap: this.fontsMap || {},
          md5Map: {},
          overrides: {},
          isMultipleSelect: this.isMultipleSelectionEnabled,
          isCodePen: false,
          isCodeSandbox: false,
          presetSettings: this.codegenHTMLLayout === 'absolute' ? 'high_fidelity' : 'clean_code',
          useModel: false
        });

        this.setIsGeneratingCode(true);
        const codegenResult = await this.makeCodegenRequestWithoutIframe(
          nodeId,
          fileId,
          figma_access_token,
          codegenSettings
        );
        if (nodeId !== this.lastLoadNodeId) {
          return;
        }
        // span.finish();

        this.populateCodePanels(codegenResult);
        this.setIsGeneratingCode(false);
      } catch (error) {
        console.log(error);
        this.setIsGeneratingCode(false);
        if (this.generateController && this.generateController.signal && this.generateController.signal.aborted) {
          this.setIsGeneratingCode(true);
        }
      } finally {
        this.setIsGeneratingPlaygroundCode(false);
        this.setIsExportingPlaygroundCode(false);
      }
      transaction.finish();
    },

    async getCurrentScreenNodeId() {
      const { screenSlug } = this.$route.params;

      const { model } = await this.getModelAndScreen();
      const currentScreenModel = model.screens.find(s => this.slugsMap[s.variableID] === screenSlug);

      return currentScreenModel['modelID'];
    },

    initLoadingState(isExternalEditor, isExportingCode) {
      isExternalEditor
        ? isExportingCode
          ? this.setIsExportingPlaygroundCode(true)
          : this.setIsGeneratingPlaygroundCode(true)
        : this.setIsGeneratingCode(true);

      isExternalEditor ? this.resetPlaygroundCode() : this.resetCodePanels();
    },

    trackExternalEditorEvents({ framework, externalEditor }) {
      this.$trackEvent('omniview.code-mode.external-editor', {
        type: framework,
        editor: externalEditor,
        'style-type': this.styleType,
        framework
      });
      this.$gtm.trackEvent({
        event: 'external_edit',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: framework
      });
    },

    trackGetCleanSnippetEvent({ framework, elementType }) {
      this.$trackEvent('omni.code-mode.get-code', {
        'style-type': this.styleType,
        framework,
        defaultFramework: 'html',
        panels: this.panelsState,
        element_type: elementType,
        html_tag: get(this.currentNode, 'tagName', null),
        classname: get(this.currentNode, 'classes', null)
      });
    },

    // the most important function in the omniview
    async generateCode({
      isCodePen = false,
      isCodeSandbox = false,
      _nodeId,
      forceGenerate = false,
      fullModel = false,
      framework = null
    } = {}) {
      let isExportingCode = false;
      try {
        const { layer } = this.$route.query;

        let nodeId = _nodeId ? _nodeId : layer;

        if (fullModel) {
          nodeId = await this.getCurrentScreenNodeId();
          isExportingCode = true;
        }

        if (!nodeId) return;

        if (!forceGenerate && nodeId == this.lastGeneratedId) {
          return;
        }

        framework = framework ? framework : this.codegenLang;

        if (this.isGeneratingCode) {
          this.generateController.abort();
        }

        const isExternalEditor = isCodeSandbox || isCodePen;

        let start = window.performance.now();

        const isWebComponent = this.isComponentOrSuggestionById(nodeId);

        this.initLoadingState(isExternalEditor, isExportingCode);

        await this.$waitFor(() => !this.loading.project && !this.loading.release && !this.loading.assets, true);

        let { modelNode, subModel } = await this.getNodeSubModel({
          nodeId,
          isMultipleSelect: this.isMultipleSelectionEnabled
        });

        const [overrides, md5Map] = await Promise.all([
          this.getModelOverrides(),
          this.getNodeMd5Map(modelNode, { fullModel, shouldForceUseMd5Array: isExternalEditor })
        ]);

        this.setCurrentNodeMd5Map(md5Map);

        const codegenSettings = await this.getCodegenSettings({
          framework,
          fontsMap: this.fontsMap || {},
          md5Map,
          overrides,
          isMultipleSelect: this.isMultipleSelectionEnabled,
          isCodePen,
          isCodeSandbox,
          isWebComponent,
          presetSettings: 'clean_code'
        });

        // const size = new TextEncoder().encode(JSON.stringify(model)).length;
        // const kiloBytes = size / 1024;
        // const megaBytes = kiloBytes / 1024;
        // console.warn(megaBytes);

        const codeCallParams = {
          model: subModel,
          settings: codegenSettings,
          mode: isCodeSandbox ? 'package' : 'clean_snippet',
          options: {
            onabort: () => {
              this.setIsGeneratingCode(true);
            }
          }
        };

        if (isExternalEditor) {
          this.trackExternalEditorEvents({
            framework,
            externalEditor: isCodeSandbox ? 'codesandbox' : 'codepen'
          });
        }

        if (isCodeSandbox) {
          const params = await this.makeCodeSandboxRequest(codeCallParams);
          EventBus.$emit('open-in-codesandbox', { params });
          this.$trackEvent('omniview.codesandbox-export.success', { projectId: this.$route.params.projectId });
          // openLink({
          //   link,
          //   newTab: true
          // });
        } else {
          this.trackGetCleanSnippetEvent({
            framework,
            elementType: isWebComponent ? 'component' : 'element'
          });
          const codegenResult = await this.makeCodegenRequest({
            mode: 'clean_snippet',
            model: subModel,
            settings: codegenSettings,
            options: {
              onabort: () => {
                this.setIsGeneratingCode(true);
              }
            }
          });
          let end = window.performance.now();

          // codegenResult = this.processCodegenResult(codegenResult);

          this.$trackEvent('omniview.code-mode.get-code-done', { time: (end - start).toFixed(2) });
          isCodePen ? this.populatePlaygroundCode(codegenResult) : this.populateCodePanels(codegenResult);
          this.setIsGeneratingCode(false);
        }

        this.setLastGeneratedId(nodeId);
      } catch (error) {
        console.log(error);
        this.setIsGeneratingCode(false);
        if (this.generateController && this.generateController.signal && this.generateController.signal.aborted) {
          this.setIsGeneratingCode(true);
        }
      } finally {
        isExportingCode ? this.setIsExportingPlaygroundCode(false) : this.setIsGeneratingPlaygroundCode(false);
      }
    },
    failedCodeError() {
      return {
        components: [],
        files: {
          'index.html': 'Failed to export code',
          'index.vue': 'Failed to export code',
          'index.jsx': 'Failed to export code',
          'style.css': 'Failed to export code'
        },
        homepage_slug: '',
        forms: []
      };
    },
    processCodegenResult(result) {
      if (this.codegenLang != 'html') {
        return result;
      }

      function indexInParent(node) {
        let children = node.parentNode.childNodes;
        let num = 0;
        for (let i = 0; i < children.length; i++) {
          if (children[i] == node) return num;
          if (children[i].nodeType == 1) num++;
        }
        return -1;
      }

      const doc = new DOMParser().parseFromString(result['files']['index.html'], 'text/html');
      let map = {};
      const elements = doc.querySelectorAll('[data-id]');
      elements.forEach(el => {
        let id = el.getAttribute('data-id');
        let key = indexInParent(el) + el.className.replace(' ', '');
        if (!has(map, key)) {
          map[key] = id;
        }
      });

      return result;
    },
    async makeCodegenRequestWithoutIframe(nodeId = '', fileId = '', figma_access_token = '', settings) {
      //get the code only for specific node without the iframe
      let codegenURL = 'https://figma-service.animaapp.com/generate/node';

      this.generateController = new AbortController();
      this.generateController.signal.onabort = onabort;

      delete settings.base_dir_images;
      const codeRes = await fetch(codegenURL, {
        method: 'POST',
        body: JSON.stringify({
          file_key: fileId,
          mode: 'clean_code',
          node_id: nodeId,
          figma_access_token: figma_access_token ? figma_access_token : this.currentUser.figma_auth_token,
          settings: JSON.stringify(settings)
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      });

      if (codeRes && codeRes.status != 200 && codeRes.status != 201) {
        let error = await codeRes.json();

        if (window.top && window.top.postMessage && error && error.errors && error.errors === 'Invalid token') {
          //if your token is not valid anymore
          window.top.postMessage('access-token-is-not-valid', '*');
        }

        return this.failedCodeError();
      }
      let codegenResult = {};
      try {
        codegenResult = await codeRes.json();
      } catch (e) {
        return this.failedCodeError();
      }
      return codegenResult;
    },
    async makeCodegenRequest({ model, settings, mode, options: { requestParams = {}, onabort = () => {} } = {} }) {
      let codegenBaseURL = localStorage.getItem('codegenBaseURL') || CODEGEN_BASE_URL;
      let codegenURL = readCookie('X-CodegenURL') || `${codegenBaseURL}/generate`;

      this.generateController = new AbortController();
      this.generateController.signal.onabort = onabort;

      const codeRes = await fetch(codegenURL, {
        method: 'POST',
        body: JSON.stringify({
          mode,
          model,
          settings
        }),
        headers: this.getCodegenHeaders,
        credentials: 'same-origin',
        ...requestParams,
        signal: this.generateController.signal
      });

      if (codeRes && codeRes.status != 200) {
        throw new Error('Something went wrong');
      }

      const codegenResult = await codeRes.json();

      return codegenResult;
    },

    async makeCodeSandboxRequest({ model, settings, mode, options: { requestParams = {}, onabort = () => {} } } = {}) {
      try {
        const codegenResponse = await this.makeCodegenRequest({
          model,
          settings,
          mode,
          options: { onabort, requestParams }
        });

        const files = objectMap(codegenResponse.files, val => ({
          content: val
        }));

        const parameters = getParameters({
          files
        });

        return parameters;

        // return codegenResult['link'];
      } catch (error) {
        this.$trackEvent('omniview.codesandbox-export.failed', { projectId: this.$route.params.projectId });
        const exception = error.error || error.message || error.originalError || error;
        this.$sentry.captureException(exception);
      }
    },

    populatePlaygroundCode(codegenResult) {
      switch (this.codegenLang) {
        case 'html':
          this.setPlaygroundCode({ ...this.playgroundCode, html: codegenResult.files['index.html'] });
          break;
        case 'react':
          this.setPlaygroundCode({
            ...this.playgroundCode,
            jsx: codegenResult.files['index.jsx'],
            html: codegenResult.files['index.html']
          });

          break;

        case 'vue':
          // nothing for now
          break;

        default:
          break;
      }

      const extension = this.codegenStylesheetLang || 'css';

      let styles = get(codegenResult, ['files', `style.${extension}`], '');
      let globals = get(codegenResult, ['files', `globals.${extension}`], '');
      let styleguide = get(codegenResult, ['files', `styleguide.${extension}`], '');

      let css = `${globals}\n${styleguide}${styleguide ? '\n' : ''}${styles}`;

      this.setPlaygroundCode({ ...this.playgroundCode, css });
      EventBus.$emit('open-in-codepen');
    },

    populateCodePanels(codegenResult) {
      let html, css;
      switch (this.codegenLang) {
        case 'html':
          this.setCurrentNodeHTML(codegenResult.files['index.html']);
          html = codegenResult.files['index.html'];

          break;
        case 'react':
          this.setCurrentNodeJSX(codegenResult.files['index.jsx']);
          html = codegenResult.files['index.jsx'];

          break;

        case 'vue':
          this.setCurrentNodeJSX(codegenResult.files['index.vue']);
          html = codegenResult.files['index.vue'];

          break;

        default:
          break;
      }

      const extension = this.codegenStylesheetLang || 'css';

      let styles = get(codegenResult, ['files', `style.${extension}`], '');
      let globals = get(codegenResult, ['files', `globals.${extension}`], '');

      css = `${styles}\n${globals}`;

      this.setCurrentNodeCSS(css);

      this.reportCodeState(html, css);
    },

    resetCodePanels() {
      this.resetPlaygroundCode();
      this.setCurrentNodeHTML('');
      this.setCurrentNodeJSX('');
      this.setCurrentNodeCSS('');
    },

    async getNodeMd5Map(m, { fullModel = false, shouldForceUseMd5Array = false } = {}) {
      let md5s = [];

      if (!fullModel || shouldForceUseMd5Array) {
        md5s = this.getNodeMd5Array(m);
      } else {
        for (let i = 0; i < (m.screens || []).length; i++) {
          const n = m.screens[i];
          let arr = this.getNodeMd5Array(n);
          md5s = [...md5s, ...arr];
        }
      }

      let md5Map = {};

      await this.$waitFor(() => !this.loading.assets, true);

      md5s.forEach(md5 => {
        md5Map[md5] = this.assetsRegistry[md5] ? this.assetsRegistry[md5] : {};
      });
      return md5Map;
    },

    async getModelOverrides() {
      await this.$waitFor(() => !this.isWaitingForOverrides, true);
      const nodes = { ...this.nodes };
      let currentNodeOverrides = this.currentComponentMetadata.overrides || {};
      let overrides = { ...currentNodeOverrides };
      const ids = Object.keys(nodes);
      for (let i = 0; i < ids.length; i++) {
        let nodeId = ids[i];
        let nodeData = nodes[nodeId];

        if (!nodeData.isModifed) continue;
        const nodeClassObject = nodeData.originalAttributes.find(o => o.name == 'class');
        let nodeName = '';
        if (nodeClassObject) {
          nodeName = nodeClassObject.value;
        }

        const styleObject = StyleToObject(nodeData.inlineCSS) || {};
        overrides[nodeId] = {
          ...(overrides[nodeId] || {}),
          css: styleObject,
          tagName: nodeData.tagName,
          nodeName
        };
      }

      if (overrides[this.currentNode.id] && overrides[this.currentNode.id]['capture_url']) {
        let url = overrides[this.currentNode.id]['capture_url'];
        let pos = url.lastIndexOf('.');
        url = url.substr(0, pos < 0 ? url.length : pos) + `.${this.captureType}`;
        overrides[this.currentNode.id]['capture_url'] = url;
      }

      return overrides;
    },

    async getModelAndScreen({ slim = false, waitForSlugifyCall = false } = {}) {
      try {
        if (this.isPreSync) {
          await this.$waitFor(() => !this.loading.model, true);

          this.loading.model = true;
          const model = await this.fetchReleaseModel({ slim });
          this.loading.model = false;
          if (model) {
            model.screens = model.screens.map(s => ({
              ...s,
              thumbnail_url: s.thumbURLString
            }));
          }
          const { screenSlug } = this.$route.params;

          await this.slugifyScreensNames((model.screens || []).map(s => s.variableID));

          let currentScreen = model.screens.find(s => this.slugsMap[s.variableID] == screenSlug);

          return { model, currentScreen };
        }

        await this.$waitFor(
          () => !this.loading.release && !this.loading.fetching && !this.currentProject.is_syncing,
          true
        );

        const { id: cId, model_id: cModelId } = this.currentComponent;
        let { model_file_url } = this.currentRelease;
        const isProgressiveUpload = model_file_url.includes('None');
        if (isProgressiveUpload) {
          model_file_url = this.currentComponent.model_url;
        }

        if (has(this.loading.model, model_file_url)) {
          await this.$waitFor(() => this.loading.model, false, {
            key: model_file_url
          });
        }

        this.$set(this.loading.model, model_file_url, true);
        let model;
        if (isProgressiveUpload) {
          model = await this.fetchModelFromUrl(model_file_url);
        } else {
          model = await this.fetchReleaseModel({ slim });
        }

        this.$set(this.loading.model, model_file_url, false);

        let currentScreen = model.screens.find(s => s.modelID == cModelId);
        if (!currentScreen) {
          const { release_model_file_url } = await this.fetchSingleComponent({ id: cId });
          if (release_model_file_url) {
            if (has(this.loading.model, release_model_file_url)) {
              await this.$waitFor(() => this.loading.model, false, {
                key: release_model_file_url
              });
            }

            this.$set(this.loading.model, release_model_file_url, true);

            model = await this.fetchModelFromUrl(release_model_file_url);
            this.$set(this.loading.model, release_model_file_url, false);
            currentScreen = model.screens.find(s => s.modelID == cModelId);
          }
        }

        this.fetchFontsMap(model);
        const slugifyCall = async () => this.slugifyScreensNames((model.screens || []).map(s => s.variableID));
        waitForSlugifyCall ? await slugifyCall() : slugifyCall();

        return { model, currentScreen };
      } catch (error) {
        this.$trackEvent('omniview.fetch-model.failed');
        console.warn(error);
        console.warn('failed fetching model');
      }
    },
    async fetchFontsMap(model) {
      if (this.isFetchingFonts) return;
      // only in the model is loaded and there is no fontsMap defined yet
      if (model && !this.fontsMap) {
        try {
          this.isFetchingFonts = true;
          const { data } = await axios.post('rpc/get_fonts_map', {
            theme: model.theme || {},
            use_fonts_server_url: true
          });
          this.fontsMap = data.fonts_map || {};
        } catch (error) {
          // if the request fails define the map to an empty object
          this.fontsMap = {};
          this.$trackEvent('omniview.fetch-fonts.failed');
        } finally {
          this.isFetchingFonts = false;
        }
      }
    },

    reportCodeState(html, css) {
      const flags = analyzeCode(html, css);
      this.$trackEvent('omniview.clean-code.code-properties', { ...flags });

      const { has_flexbox, has_absolute } = flags;

      if (has_flexbox || has_absolute) {
        const layout = has_flexbox && has_absolute ? 'mixed' : has_flexbox ? 'flexbox' : 'absolute';

        if (this.codegenLang == 'html' && this.codegenHTMLLayout == 'absolute') return; // we don't want to track absolute manual layout
        this.$trackEvent('omniview.clean-code.css-layout', { layout });
      }
    },

    checkForModeInRoute() {
      return new Promise(resolve => {
        let { mode } = this.$route.query;
        if (!mode) {
          mode = 'play'; //default mode
        }
        const done = arg => resolve(arg);
        switch (mode) {
          case 'play':
            this.handleModeChange({
              mode: this.modes[0],
              fromRoute: true
            }).then(done);
            break;
          case 'comments':
            this.handleModeChange({
              mode: this.modes[1],
              fromRoute: true
            }).then(done);

            break;
          case 'code':
            this.handleModeChange({
              mode: this.modes[2],
              fromRoute: true
            }).then(done);
            break;

          default:
            break;
        }
      });
    },

    async handleCodeModeNodeMessage(data) {
      const { id: currentNodeId } = this.currentNode;

      const callbackEvent = get(data, 'metadata.callbackEvent', false);
      const source = get(data, 'metadata.source', false);
      if (callbackEvent) {
        EventBus.$emit(callbackEvent['name'], callbackEvent['params']);
      }

      if (source == 'iframe') {
        if (currentNodeId && currentNodeId == data.id) {
          EventBus.$emit(SEND_MESSAGE, {
            action: SELECT_OVERRIDE_NODE,
            data: {
              nodeId: data.parentNodeId,
              metadata: {
                source: 'client'
              }
            }
          });
          return;
        }
      }

      EventBus.$emit(OPEN_PANEL);
      // change the tab to cleancode
      EventBus.$emit('code-panel-tab-change', 'cleanCode');

      this.setCurrentNode({ ...data, viewName: (this.modelNodesMap[data.id] || {}).viewName || '' });
      this.setCurrentNodePath(data.path);
      this.generateCode();

      this.$waitFor(() => this.isWebComponentsLoading, false).then(() => {
        const { master: foundMaster } = this.getMasterAndInstanceByNodeId(data.id);

        // if (foundMaster) {
        //   let master = { ...foundMaster, component_name: foundMaster.name };
        //   this.setCurrentWebComponent({
        //     master,
        //     instance: patchInstanceFromMaster(foundInstance, master)
        //   });
        // }

        if (this.isComponentOrSuggestionById(data.id)) {
          this.preProcessComponent({
            forcePreProcess: true
          }).then(() => {
            EventBus.$emit(SEND_MESSAGE, {
              action: 'update-nodes-data',
              data: {
                [data.id]: { viewName: this.currentWebComponent['master']['component_name'] }
              }
            });
          });
        }

        if (!foundMaster || !foundMaster['screenshot_url']) {
          let delay = source == 'route' ? 1000 : 0;

          if (delay) {
            this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.id]: true });
          }

          setTimeout(() => {
            EventBus.$emit(SEND_MESSAGE, {
              action: 'get-preview',
              data: {
                nodeId: data.id,
                isLocal: true
              }
            });
          }, delay);
        }
      });
    },
    handleKeydown(e) {
      if (e.key === 'Escape') {
        this.handleEscapeClicked();
      }
    },
    handleEscapeClicked() {
      this.setIsFullScreen(false);
      this.setIsCompareEnabled(false);
      this.resetSelection();
    }
  }
};

export default codegenMixin;
