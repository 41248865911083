var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "project-header-container" },
      [_c("Header", { attrs: { isWorking: _vm.loading } })],
      1
    ),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "navigation" },
          [
            _vm.tabs.length > 0
              ? _c("Tabs", {
                  attrs: {
                    "data-cy": "project-view-tabs",
                    activeTabLabel: _vm.activeTabLabel,
                    items: _vm.tabs
                  },
                  on: { change: _vm.onTabChange }
                })
              : _vm._e(),
            !_vm.isFigmaSyncing
              ? [
                  _c(
                    "PopoverMenu",
                    {
                      attrs: {
                        items: _vm.sortingOptions,
                        position: "right",
                        selectedLabel: _vm.sortByLabel
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sort-select",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.sortByLabel) + " "),
                          _c("svg-icon", {
                            attrs: { name: "select-arrow-down", size: 24 }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              : _c(
                  "div",
                  {
                    staticClass: "flex items-center progress-bar-container",
                    attrs: { "data-cy": "figma-import-progress" }
                  },
                  [
                    _c("ProgressBar", {
                      attrs: {
                        progress: _vm.figmaImportProgress,
                        height: 10,
                        backgroundColor: "rgba(255, 98, 80, 0.3)"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "progress-bar-text flex items-center" },
                      [
                        _c("svg-icon", {
                          staticClass: "icon-spin",
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            fill: "currentColor",
                            name: "spin",
                            size: 20
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.figmaImportStatus))])
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          2
        ),
        _vm.isFigmaSyncing
          ? [
              _c(
                "div",
                { staticClass: "figma-grid" },
                _vm._l(_vm.figmaFrames, function(frame, index) {
                  return _c(
                    "ScreenCard",
                    {
                      key: index,
                      staticClass: "relative",
                      style: Object.assign(
                        {},
                        {
                          opacity: 1,
                          pointerEvents: "none",
                          userSelect: "none"
                        },
                        { minHeight: "100px" }
                      ),
                      attrs: {
                        screen: frame,
                        actions: [],
                        cta: "Building screen...",
                        readOnly: ""
                      }
                    },
                    [
                      _c("template", { slot: "overlay" }, [
                        _c("div", {
                          staticClass:
                            "overlay flex items-center justify-center"
                        })
                      ]),
                      _c("template", { slot: "subtitle" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center w-full" },
                          [
                            _c("svg-icon", {
                              staticClass: "wrench",
                              staticStyle: {
                                "margin-left": "auto",
                                color: "var(--primary)"
                              },
                              attrs: {
                                fill: "currentColor",
                                name: "rocket",
                                size: 20
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                }),
                1
              )
            ]
          : [
              _c("div", { staticClass: "filters-bar" }, [
                _c("span", [_vm._v(_vm._s(_vm.totalItems))]),
                _vm.isDesktop && !!_vm.screens.length
                  ? _c(
                      "div",
                      { staticClass: "search" },
                      [
                        _c("SearchInput", {
                          on: { "toggle-open": _vm.toggleSearchOpen },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "project-page-content" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fadeIn", mode: "out-in" } },
                    [
                      _vm.loading
                        ? _c("LoadingScreen", {
                            attrs: {
                              type: _vm.isPollingSyncing
                                ? "syncing"
                                : "default",
                              text: _vm.isPollingSyncing
                                ? "Syncing project"
                                : null
                            }
                          })
                        : !!_vm.cardItems.length
                        ? _c("CardsGrid", {
                            attrs: {
                              itemsType: _vm.type,
                              items: _vm.cardItems,
                              actions: _vm.actions,
                              sortBy: _vm.sortBy,
                              filteredResults: !!_vm.searchQuery,
                              totalAmount: _vm.totalLiveComponents,
                              isLoadingMore: _vm.loadingMore
                            },
                            on: {
                              "load-more": _vm.loadMore,
                              "click-screen": _vm.navigateToScreen,
                              "click-component": _vm.navigateToComponent
                            }
                          })
                        : !!_vm.searchQuery
                        ? _c("NoSearchResults")
                        : _c("EmptyState", { attrs: { type: _vm.type } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.selection.length > 0
                ? _c(
                    "div",
                    { staticClass: "multi-select-bar-container" },
                    [
                      _c("MultiSelectBar", {
                        attrs: {
                          selection: _vm.selection,
                          selectionState:
                            _vm.selection.length < _vm.cardItems.length
                              ? "some"
                              : "all",
                          type: "screen"
                        },
                        on: {
                          "check-all": _vm.selectAll,
                          uncheck: _vm.clearSelection,
                          delete: _vm.deleteSelected
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }