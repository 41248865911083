var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "announcement-container" }, [
    _c("div", { staticClass: "announcement-header" }, [
      _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
      _c("div", { staticClass: "tag" }, [_vm._v(_vm._s(_vm.tag))])
    ]),
    _c("div", { staticClass: "announcement-body" }, [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.item.title))]),
      _vm.item.image_url
        ? _c("div", { staticClass: "image" }, [
            _c("img", { attrs: { src: _vm.item.image_url } })
          ])
        : _vm._e(),
      _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.item.content))]),
      _vm.item.cta && _vm.item.cta_url
        ? _c(
            "div",
            { staticClass: "cta" },
            [
              _c(
                "an-button",
                { attrs: { variant: "secondary" }, on: { click: _vm.onClick } },
                [_vm._v(_vm._s(_vm.item.cta))]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }