<template>
  <div>
    <SettingsItem
      v-for="section in sections"
      :key="section.title"
      :title="section.title"
      :text="section.text"
      :disabled="section.disabled"
      :disabledText="section.disabledText"
      :ctaText="section.ctaText"
      @click="section.onClick"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { upperFirst } from 'lodash-es';
import { isSufficientRole } from '@/services/permissions';
import SettingsItem from '@/components/Layout/SettingsItem';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      menu: [
        {
          title: 'General',
          text: 'Edit your team details and manage integrations',
          route: 'general',
          rolePermission: 'member'
        },
        {
          title: 'Members',
          text: 'Manage your team members and roles',
          route: 'members',
          rolePermission: 'member'
        },
        {
          title: 'Plan and Billing',
          text: 'Change or update your team plan and billing settings ',
          route: 'billing',
          rolePermission: 'admin'
        },
        {
          title: 'Delete team',
          text: 'Permanently delete your team account and all of its data',
          route: 'delete',
          rolePermission: 'owner',
          ctaText: 'Delete'
        }
      ],
      showModal: ''
    };
  },
  components: {
    SettingsItem
  },
  computed: {
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters('teamMemberships', ['userTeams']),
    sections() {
      const { role } = this.teamMembership;
      return this.menu.map(item => ({
        disabled: !isSufficientRole(item.rolePermission, role),
        disabledText: `${upperFirst(item.rolePermission)}s only`,
        onClick: () => {
          const section = item.route;
          this.$trackEvent('team-page.settings-section.click', { section });

          if (section === 'delete') {
            return this.onClickDelete();
          }
          this.navigate(section);
        },
        ...item
      }));
    }
  },
  methods: {
    navigate(sectionName) {
      const name = `team-settings${sectionName ? `-${sectionName}` : ''}`;
      this.$router.push({ name }, () => {});
    },
    onClickDelete() {
      const teamsOwnedByUser = this.userTeams.filter(tm => tm?.role === 'owner');
      const isLastTeam = teamsOwnedByUser.length <= 1;

      if (isLastTeam) {
        return toastError('Please create a new team in order to delete this one.');
      }

      this.navigate('delete');
    }
  }
};
</script>
