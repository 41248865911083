import { EventBus } from '@/services/bus';
import { get } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';

export const HTML_LAYOUT_CHANGE = 'html-layout-change';
export const REACT_LAYOUT_CHANGE = 'react-layout-change';
export const REACT_SYNTAX_CHANGE = 'react-syntax-change';
export const REACT_STYLE_CHANGE = 'react-style-change';
export const VUE_STYLE_CHANGE = 'vue-style-change';

/**
 * @type {Vue.Component}
 */
const CodePrefsMixin = {
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapGetters({
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      codegenReactStyle: 'omniview/codegenReactStyle',
      codegenVueStyle: 'omniview/codegenVueStyle',
      currentNode: 'omniview/currentNode',
      codegenLang: 'omniview/codegenLang'
    })
  },
  mounted() {
    EventBus.$on(HTML_LAYOUT_CHANGE, this.handleHTMLLayoutChange);
    EventBus.$on(REACT_SYNTAX_CHANGE, this.handleReactSyntaxChange);
    EventBus.$on(REACT_STYLE_CHANGE, this.handleReactStyleChange);
    EventBus.$on(VUE_STYLE_CHANGE, this.handleVueStyleChange);
  },
  destroyed() {
    EventBus.$off(HTML_LAYOUT_CHANGE, this.handleHTMLLayoutChange);
    EventBus.$off(REACT_SYNTAX_CHANGE, this.handleReactSyntaxChange);
    EventBus.$off(REACT_STYLE_CHANGE, this.handleReactStyleChange);
    EventBus.$off(VUE_STYLE_CHANGE, this.handleVueStyleChange);
  },
  methods: {
    ...mapMutations({
      setCodegenHTMLLayout: 'omniview/setCodegenHTMLLayout',
      setCodegenReactSyntax: 'omniview/setCodegenReactSyntax',
      setCodegenReactStyle: 'omniview/setCodegenReactStyle',
      setCodegenVueStyle: 'omniview/setCodegenVueStyle',
      setCodegenLang: 'omniview/setCodegenLang'
    }),
    handleHTMLLayoutChange({ value, lang = null, generate = true } = {}) {
      if (lang) {
        this.setCodegenLang(lang);
      }
      if (value == this.codegenHTMLLayout) return;
      this.setCodegenHTMLLayout(value);

      if (this.showOnlyCode) {
        let fileId = get(this.$route, 'query.fileId', false);
        EventBus.$emit('generateCodeWithoutIframe', { nodeId: this.currentNode, fileId });
      } else {
        generate && EventBus.$emit('generate-code', { forceGenerate: true });
      }
      this.$trackEvent('omniview.html-syntax.change', { style: value });
    },
    handleReactSyntaxChange({ value, lang = null, generate = true } = {}) {
      if (lang) {
        this.setCodegenLang(lang);
      }
      if (value == this.codegenReactSyntax) return;
      this.setCodegenReactSyntax(value);

      if (this.showOnlyCode) {
        let fileId = get(this.$route, 'query.fileId', false);
        EventBus.$emit('generateCodeWithoutIframe', { nodeId: this.currentNode, fileId });
      } else {
        generate && EventBus.$emit('generate-code', { forceGenerate: true });
      }

      this.$trackEvent('omniview.react-syntax.change', { syntax: value });
    },
    handleReactStyleChange({ value, lang = null, generate = true } = {}) {
      if (lang) {
        this.setCodegenLang(lang);
      }

      if (value == this.codegenReactStyle) return;

      this.setCodegenReactStyle(value);

      if (this.showOnlyCode) {
        //if it's chrome extension
        let fileId = get(this.$route, 'query.fileId', false);
        EventBus.$emit('generateCodeWithoutIframe', { nodeId: this.currentNode, fileId });
      } else {
        generate && EventBus.$emit('generate-code', { forceGenerate: true });
      }

      this.$trackEvent('omniview.react-style.change', { style: value });
    },
    handleVueStyleChange({ value, lang = null, generate = true } = {}) {
      if (lang) {
        this.setCodegenLang(lang);
      }

      if (value == this.codegenVueStyle) return;

      this.setCodegenVueStyle(value);

      if (this.showOnlyCode) {
        //if it's chrome extension
        let fileId = get(this.$route, 'query.fileId', false);
        EventBus.$emit('generateCodeWithoutIframe', { nodeId: this.currentNode, fileId });
      } else {
        generate && EventBus.$emit('generate-code', { forceGenerate: true });
      }

      this.$trackEvent('omniview.vue-style.change', { style: value });
    }
  }
};

export default CodePrefsMixin;
