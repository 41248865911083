<template>
  <div class="container">
    <div class="purchase_message_container">
      <div class="img_container">
        <img src="@/assets/illustrations/successful-payment.svg" />
      </div>
      <h2 class="title">Upgrade Complete!</h2>
      <p class="text">Thank you! Your Anima plan was successfully upgraded.</p>
      <an-button data-cy="navigate" class="button" @click="navigate">{{ ctaText }}</an-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment-success',
  props: {
    nextPage: {
      type: Object
    }
  },
  mounted() {
    if (!this.nextPage) {
      this.nextPage = this.$route.params.nextPage;
    }
    this.$trackEvent('payment.purchase.success.page');
  },
  methods: {
    navigate() {
      if (this.nextPage) {
        return this.$emit('close', { redirect: this.nextPage, afterClosing: this.$route.params.afterClosing });
      }

      const nextPage = 'team';
      this.$emit('close', { redirect: { name: nextPage }, afterClosing: this.$route.params.afterClosing });
    }
  },
  computed: {
    ctaText() {
      switch (this.$route.params.source) {
        case 'projects':
          return 'Back to projects';
        case 'omniview':
          return 'Back to Get code';
        case 'omniview_get_pro':
          return 'Back to the project';
        case 'figma':
          return 'Back to Figma';
        default:
          return 'Back to projects';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  height: 100vh;
}
.img_container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(10px);
  width: 268px;
  height: 221px;
}
img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}
.title {
  @include headline;
  margin-top: 65px;
}
.text {
  margin-top: 20px;
}
.button {
  margin-top: 40px;
}
</style>
