var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tip",
    { attrs: { trigger: "click", offset: "4", open: _vm.isMounted } },
    [
      _vm._t("default"),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm._l(_vm.items, function(item) {
              return _c("div", { key: item.familyName, staticClass: "item" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(item.familyName) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _vm.isUploaded[item.familyName]
                      ? _c("svg-icon", {
                          attrs: { name: "circle-check", size: 24 }
                        })
                      : _c(
                          "Upload",
                          {
                            attrs: {
                              url: _vm.uploadUrl,
                              accept: _vm.uploadAccept,
                              context: { familyName: item.familyName }
                            },
                            on: {
                              start: _vm.onUploadStart,
                              success: _vm.onUploadSuccess,
                              failure: _vm.onUploadFailure
                            }
                          },
                          [
                            _c(
                              "an-button",
                              {
                                staticClass: "upload-btn",
                                attrs: {
                                  variant: "empty",
                                  size: "sm",
                                  isWorking: _vm.isLoading[item.familyName]
                                }
                              },
                              [_vm._v(" Upload ")]
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              ])
            }),
            _c(
              "div",
              { staticClass: "done" },
              [
                _c(
                  "an-button",
                  {
                    directives: [
                      { name: "close-popover", rawName: "v-close-popover" }
                    ],
                    attrs: { variant: "secondary", size: "sm" },
                    on: { click: _vm.doneUploading }
                  },
                  [_vm._v("Done")]
                )
              ],
              1
            )
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }