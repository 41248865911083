var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["toast", _vm.$theme] }, [
    _c("div", { staticClass: "toast-content" }, [
      _c(
        "div",
        { staticClass: "toast-icon" },
        [_c("svg-icon", { attrs: { name: _vm.icons[_vm.variant], size: 20 } })],
        1
      ),
      _c("div", { staticClass: "toast-description" }, [
        _vm._v(" " + _vm._s(_vm.content) + " ")
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "clickable-icon close-icon",
        on: {
          click: function($event) {
            return _vm.$emit("close")
          }
        }
      },
      [_c("svg-icon", { attrs: { name: "close", size: 15 } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }