<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/falling-woman.png" />
    </div>
    <div class="title">We'll miss you, {{ firstName }}</div>
    <div class="subtitle">
      Before you go, be advises that all projects and data will be permanently deleted for all users and you’ll not be
      able to undo this action.
    </div>
    <div class="disclaimer">
      <div class="disclaimer-title">Consider other options</div>
      Only want to stop paying?
      <an-link variant="primary" @click="changePlan">Change plan</an-link>
    </div>
    <div class="body">
      <Checkbox v-model="agreeDeleted">
        All your account projects &amp; data will be permanently deleted.
      </Checkbox>
      <Checkbox v-model="agreeNoRestore">
        We will NOT be able to restore your account.
      </Checkbox>
    </div>
    <div class="actions" v-if="showActions">
      <an-button @click="deleteUser" :isWorking="isWorking">Delete account</an-button>
      <an-link @click="openAccountModal">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Checkbox from '@/components/Checkbox/Checkbox';
import { openModal, toastError } from '@/services/bus';

export default {
  name: 'account-delete',
  data() {
    return {
      agreeDeleted: false,
      agreeNoRestore: false,
      isWorking: false
    };
  },
  components: {
    Checkbox
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({ firstName: 'users/firstName' }),
    showActions() {
      return this.agreeDeleted && this.agreeNoRestore;
    }
  },
  methods: {
    ...mapActions({ _deleteUser: 'users/delete' }),
    async deleteUser() {
      this.isWorking = true;
      try {
        this.$trackEvent('account.delete-account.click', { user_id: this.user?.id });

        await this._deleteUser(this.user);

        this.$trackEvent('account.delete-account.success');
        window.location.href = 'https://animaapp.com';
      } catch (err) {
        this.$trackEvent('account.delete-account.failure', { message: err.message });
        toastError("Failed deleting your user. that's awkward.");
      } finally {
        this.isWorking = false;
      }
    },
    openAccountModal() {
      openModal({ name: 'account' });
    },
    changePlan() {
      const { default_team_slug: teamSlug } = this.user;
      this.$router.push({ name: 'team-pricing', params: { teamSlug } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.subtitle {
  margin: 20px 0 0;
}
</style>
