<template>
  <div class="w-full" v-if="!isPreprocessing">
    <Prism
      class="usage-snippet"
      style="padding:20px;margin-top:24px"
      :language="getLanguage"
      mode="usageCode"
      :masterId="masterId"
      :componentData="componentData"
      :code="usageCode"
      @propMouseOver="handlePropMouseover"
      @propMouseLeave="handlePropMouseout"
      @update-component-prop-name="handleUpdatePropName"
      @update-component-name="handleUpdateComponentName"
      @update-component-prop-enable="handleUpdatePropEnable"
    />
  </div>
</template>

<script>
import Prism from '@/components/OmniView/Panels/Prism';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash-es';
import { prettifyAssetFilename } from '@/utils/prettify';
// import { EventBus } from '@/services/bus';
import { AsyncQueue } from '@/utils/javascript';
import axios from 'axios';
import { EventBus } from '@/services/bus';
// import { patchInstanceFromMaster } from '../utils';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';

const requestQueue = new AsyncQueue();

export default {
  components: {
    Prism
  },
  data() {
    return {
      // usageCode: ''
    };
  },
  mounted() {
    // this.updateUsageCode();
  },
  computed: {
    ...mapGetters({
      currentWebComponent: 'webComponents/currentWebComponent',
      webComponents: 'webComponents/currentWebComponent',
      isPreprocessing: 'webComponents/isPreprocessing',
      currentNodeMd5Map: 'omniview/currentNodeMd5Map',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      codegenLang: 'omniview/codegenLang'
    }),
    masterId() {
      const { layer } = this.$route.query;
      let { master } = this.getMasterAndInstanceByNodeId(layer);
      return master['master_id'];
    },
    usageCode() {
      let cleanCode = get(this.currentWebComponent, 'instance.usage_code', '');
      Object.values(this.currentNodeMd5Map).forEach(obj => {
        const filename = prettifyAssetFilename(obj.filename);
        cleanCode = cleanCode.replaceAll(obj.url, filename);
      });
      return cleanCode;
    },
    getLanguage() {
      let lang = '';
      switch (this.codegenLang) {
        case 'react':
          lang = 'jsx';
          break;

        case 'html':
          lang = 'markup';
          break;

        case 'vue':
          lang = 'html';
          break;

        default:
          break;
      }
      return lang;
    },
    componentData() {
      // const { layer } = this.$route.query;
      // const { master, instance } = this.getMasterAndInstanceByNodeId(layer);

      // return {
      //   master,
      //   instance: patchInstanceFromMaster(instance, master)
      // };

      return this.currentWebComponent;
    }
  },
  methods: {
    ...mapActions({
      updateWebComponentOverride: 'webComponents/updateWebComponentOverride'
    }),

    async handleUpdateComponentName(payload) {
      const { newName, oldName, usageCode, masterId } = payload;

      const { projectId } = this.$route.params;

      const requestParams = {
        project_id: projectId,
        master_id: masterId,
        override_key: 'component_name',
        new_override_value: newName
      };

      this.updateWebComponentOverride({
        requestParams,
        type: 'name',
        usageCode
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.edit-name', {
        oldName: oldName,
        name: newName,
        projectId,
        masterId
      });
    },

    handlePropMouseover({ nodeId }) {
      if (!nodeId) return;
      EventBus.$emit(SEND_MESSAGE, {
        action: 'highlight-nodes',
        ids: [nodeId],
        scrollToCenter: true,
        scrollViewport: true
      });
    },
    handlePropMouseout() {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'clear-highlighted-nodes'
      });
    },

    async handleUpdatePropEnable(data) {
      const { propType, propFingerprint, newPropEnable, usageCode, masterId } = data;

      const { projectId } = this.$route.params;

      const requestParams = {
        project_id: projectId,
        master_id: masterId,
        override_key: 'prop_enable',
        new_override_value: newPropEnable,
        prop_fingerprint: propFingerprint,
        prop_enable: !newPropEnable
      };

      this.updateWebComponentOverride({
        requestParams,
        type: propType,
        usageCode
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.prop-toggle', { enabled: newPropEnable, propType });
    },

    async handleUpdatePropName(data) {
      const { propType, propName, newPropName, propFingerprint, usageCode, masterId } = data;
      const { projectId } = this.$route.params;

      const requestParams = {
        project_id: projectId,
        master_id: masterId,
        override_key: 'prop_name',
        prop_fingerprint: propFingerprint,
        new_override_value: newPropName
      };

      this.updateWebComponentOverride({
        requestParams,
        type: propType,
        usageCode
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.edit-prop-name', {
        oldName: propName,
        name: newPropName,
        propType,
        projectId,
        masterId
      });
    },
    async AfterUpdate() {
      await this.$nextTick();
      this.handlePropMouseout();
      EventBus.$emit('generate-code', { forceGenerate: true });
    },
    async makeUpdateCall(requestParams) {
      const { project_id, master_id } = requestParams;
      const { data } = await axios.put(`/project/${project_id}/web_component/${master_id}/overrides`, {
        overrides: [requestParams]
      });
      return data;
    }
  }
};
</script>
