var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navigator",
      class: {
        isOpen: _vm.isNavigatorOpen,
        isExpanded: _vm.isNavigatorExpanded
      },
      style: _vm.getNavigatorStyle
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isNavigatorOpen,
              expression: "isNavigatorOpen"
            }
          ],
          ref: "navigatorBound",
          staticClass: "wrapper",
          style: _vm.getNavigatorWrapperStyles
        },
        [
          _c(
            "div",
            {
              staticClass: "expand-button",
              on: {
                click: function($event) {
                  _vm.isNavigatorExpanded = !_vm.isNavigatorExpanded
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: {
                  fill: "currentColor",
                  name: _vm.isNavigatorExpanded ? "carret-top" : "carret-down",
                  size: 24
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "close-button",
              on: {
                click: function($event) {
                  _vm.isNavigatorExpanded = _vm.isNavigatorOpen = false
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { fill: "currentColor", name: "close", size: 20 }
              })
            ],
            1
          ),
          _c("div", { staticClass: "screens-number" }, [
            _vm._v(
              " " +
                _vm._s(_vm.currentComponents.length) +
                " " +
                _vm._s(
                  _vm.currentComponents.length > 1 ? "Screens" : "Screen"
                ) +
                " "
            )
          ]),
          _c(
            "div",
            {
              ref: "listWrap",
              staticClass: "list-wrapper",
              style: {
                overflowX: _vm.isNavigatorExpanded ? "hidden" : "auto",
                overflowY: _vm.isNavigatorExpanded ? "auto" : "hidden"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "cards",
                  style: {
                    flexWrap: _vm.isNavigatorExpanded ? "wrap" : "nowrap"
                  }
                },
                _vm._l(_vm.currentComponents, function(c, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "card",
                      class: {
                        selected: _vm.isSyncPreview
                          ? _vm.currentComponent.variableID == c.variableID
                          : _vm.currentComponent.id == c.id
                      },
                      style: {
                        margin: _vm.isNavigatorExpanded
                          ? "0 15px 15px 0"
                          : "0 10px 0 0",
                        width: "270px"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("handleScreenChange", {
                            component: c
                          })
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "thumb-container",
                          style: { height: "203px" }
                        },
                        [
                          _c("div", { staticClass: "thumb-overlay" }),
                          _c("div", { staticClass: "thumb-image-container" }, [
                            _c("img", {
                              staticClass: "thumb",
                              attrs: { src: _vm.getScreenThumb(c) }
                            })
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "screen-title" }, [
                        _vm._v(_vm._s(_vm.isSyncPreview ? c.viewName : c.name))
                      ]),
                      _c("div", { staticClass: "screen-breakpoint" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.mapWidthToBreakpointText(c.width)) +
                            " "
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }