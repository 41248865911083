<template>
  <div class="container">
    <div class="title">Project settings</div>
    <div class="subtitle">Manage project settings and guest access for {{ project.name }}</div>
    <div class="navigation">
      <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
    </div>
    <div class="body">
      <component :is="component" v-bind="props || {}" @close="close" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Tabs from '@/components/Layout/Tabs/Tabs';
import General from '@/components/Project/Settings/General';
import Members from '@/components/Project/Settings/Guests';
import Meta from '@/components/Project/Settings/Meta';
import Versions from '@/components/Project/Settings/Versions';
import Files from '@/components/Project/Settings/Files/Files';

export default {
  name: 'project-settings',
  data() {
    return {
      tabs: [
        { label: 'General', component: General, routeName: 'project-settings-general' },
        {
          label: 'Guests',
          component: Members,
          routeName: 'project-settings-guests',
          props: { onCloseRedirect: { name: 'project' } }
        },
        { label: 'Prototype link', component: Meta, routeName: 'project-settings-public-link' },
        { label: 'Versions', component: Versions, routeName: 'project-settings-versions' },
        { label: 'Files', component: Files, routeName: 'project-settings-files' }
      ],

      activeTabLabel: 'General',
      component: General,
      props: {}
    };
  },
  components: {
    Tabs
  },
  mounted() {
    const { projectId } = this.$route.params;
    this.fetchProject({ id: projectId });
  },
  computed: mapState('projects', { project: 'currentItem' }),
  methods: {
    ...mapActions({ fetchProject: 'projects/fetchOne' }),
    showRelevantComponent() {
      const tab = this.tabs.find(tab => tab.routeName === this.$route.name) || this.tabs[0];
      this.activeTabLabel = tab.label;
      this.component = tab.component;
      this.props = tab.props ?? {};
    },
    onTabChange(tab) {
      this.$router.push({ name: tab.routeName });
    },
    close(value) {
      this.$emit('close', value);
    }
  },
  watch: {
    $route: {
      handler: 'showRelevantComponent',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_navigationBar.scss';
.navigation {
  justify-content: center;
  width: 100%;
  margin: 40px 0 10px;
}
</style>
