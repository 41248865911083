import { openModal } from '@/services/bus';
import { upperFirst } from 'lodash-es';
import recognizedDomains from './recognizedDomains.json';

const openUpgradeModal = config =>
  openModal({ name: 'upgrade-team', variant: 'center', width: 600, closeButton: true, ...config });

export default {
  methods: {
    openUpgradeLockedModal(customSettings = {}) {
      const mode = 'light';
      const props = {
        title: 'Unlock unlimited projects',
        content: 'Your team has run out of projects on the free plan.\nUpgrade to unlock more projects.',
        mode,
        source: 'projects',
        ...customSettings
      };
      openUpgradeModal({ props, mode, onCloseRedirect: this.$route });
    },
    openUpgradeDownloadCodeModal(customSettings = {}) {
      const mode = 'dark';
      const props = {
        title: 'Unlock Pro features',
        content:
          'Upgrade to download project code package in a zip file including HTML & CSS files, images & fonts, with absolute position or Auto flex.',
        mode,
        source: 'omniview',
        nextPage: this.$route,
        ...customSettings
      };
      openUpgradeModal({ props, mode, onCloseRedirect: this.$route });
    },
    getDefaultTeamName({ email, first_name: firstName }, forceUsingUsername = false) {
      const [emailUsername, emailDomain] = email.split('@');

      let ownerName;
      if (recognizedDomains.includes(emailDomain) || forceUsingUsername) {
        ownerName = firstName || emailUsername || 'my';
        if (firstName) ownerName = `${ownerName}’s`;
      } else {
        ownerName = emailDomain.split('.').shift();
      }

      return `${upperFirst(ownerName)} team`;
    }
  }
};
