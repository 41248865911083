var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("p", { staticClass: "secondary title" }, [_vm._v("Import options")]),
    _c("div", { class: { "disable-events": _vm.isWorking } }, [
      _c(
        "div",
        {
          class: ["root-selection", { marked: _vm.isImportAll }],
          attrs: { disabled: _vm.isWorking },
          on: { click: _vm.importAll }
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/svg/sketch.svg"), width: "50" }
          }),
          _c("div", { staticClass: "details" }, [
            _c("h3", [_vm._v("Import all artboards")]),
            _c("p", { staticClass: "secondary" }, [
              _vm._v(_vm._s(_vm.getArtboardCountText(_vm.totalArtboardsNumber)))
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "root-selection",
          class: _vm.isImportSome && "marked",
          on: { click: _vm.importSome }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/svg/sketch-multi.svg"),
              width: "50"
            }
          }),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isImportSome,
              expression: "isImportSome"
            }
          ],
          staticClass: "pages-selection"
        },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            { key: page.id, staticClass: "page-section" },
            [
              _c("div", { staticClass: "collapsable" }, [
                _c(
                  "div",
                  {
                    staticClass: "category",
                    on: {
                      click: function($event) {
                        return _vm.toggleCollapsePage(page.id)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("h4", { staticClass: "page-title" }, [
                        _vm._v(" " + _vm._s(page.name) + " ")
                      ]),
                      _c("p", { staticClass: "secondary" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getArtboardCountText(
                              _vm.artboardsNumByPage(page.id)
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", {
                      staticClass: "toggle-collapse",
                      class: _vm.isPageArtboardsShown(page.id) ? "down" : "up"
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPageArtboardsShown(page.id),
                        expression: "isPageArtboardsShown(page.id)"
                      }
                    ]
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.artboardsByPage(page.id), function(artboard) {
                        return _c(
                          "li",
                          {
                            key: artboard.id,
                            staticClass: "artboard-checkbox"
                          },
                          [
                            _c(
                              "checkbox",
                              {
                                attrs: {
                                  value: _vm.isArtboardSelected(
                                    page.id,
                                    artboard.id
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.selectArtboard(
                                      page.id,
                                      artboard.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "secondary" }, [
                                  _vm._v(_vm._s(artboard.name))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _c("file-upload-divider", { staticClass: "divider" })
            ],
            1
          )
        }),
        0
      )
    ]),
    _vm.hasSelectedArtboards
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _c("file-upload-divider", { staticClass: "divider" }),
            _c(
              "an-button",
              {
                attrs: { variant: "primary", disabled: _vm.isWorking },
                on: { click: _vm.submit }
              },
              [_vm._v("Import Sketch file")]
            ),
            !_vm.isWorking
              ? _c("an-link", { on: { click: _vm.close } }, [_vm._v("Cancel")])
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details" }, [
      _c("h3", [_vm._v("Select artboards")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }