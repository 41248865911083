import { reportSignup } from 'anima-ppc-tracking';
import { mapActions, mapMutations, mapState } from 'vuex';
import TeamMixin from './TeamMixin';

import auth from '@/auth';
import { get } from 'lodash-es';

export default {
  mixins: [TeamMixin],
  computed: {
    ...mapState('projects', ['sharedProjects'])
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      createTeam: 'teams/create',
      createDefaultTeam: 'teams/createDefaultTeam',
      fetchSharedProjects: 'projects/fetchSharedProjects',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    _isTeamInvitationRoute(path) {
      if (!path) {
        return false;
      }

      const resolved = this.$router.resolve(path);
      if (!resolved?.route) {
        return false;
      }
      const { query, name } = resolved.route;

      return name === 'team' && (query.source === 'team-invite' || query.invite);
    },
    async createFirstTeamFlow(user = {}) {
      // create default team
      const teamName = this.getDefaultTeamName(user, this._isTeamInvitationRoute(this.$route.query?.redirect));
      const defaultTeam = await this.createDefaultTeam(teamName);

      await Promise.all([
        this.fetchTeamMemberships(defaultTeam),
        this.updateUser({ id: 'me', payload: { default_team_slug: defaultTeam.slug } })
      ]);

      this.getUser({ id: 'me', skipCache: true });
    },
    async onGoogleSuccess(googleUser) {
      const { is_login, access_token, ...user } = await auth.googleLogin(googleUser);
      localStorage.setItem('token', access_token);

      if (!is_login) {
        this.$trackEvent('signup.signup.success', {
          cta: 'google-button',
          ...this.$route.query
        });
        await this.createFirstTeamFlow(user);
        await this.fetchSharedProjects();
        this.$gtm.trackEvent({
          event: 'sign_up',
          event_category: 'Signup',
          event_action: googleUser.getBasicProfile().getName(),
          event_label: googleUser.getBasicProfile().getEmail()
        });

        reportSignup({}, user.id);
      } else {
        this.$trackEvent('login.google-login.success');
        this.$trackEvent('login.login.success');
      }

      await this.redirectAfterAuth(is_login);
    },
    async onGoogleFailure(err) {
      this.$sentry.captureException(err);
      this.$trackEvent('social.google-login.failure', { message: JSON.stringify(err.message) });
    },
    redirectAfterAuth(isLogin) {
      const { sharedProjects = [] } = this;
      const { query } = this.$route;
      const isSignup = !isLogin;
      let routeName = 'root';

      let passOnBoarding = get(this.$route, 'query.passOnBoarding', false);

      if (isSignup && !passOnBoarding) {
        let newQuery = { ...query };
        routeName = sharedProjects.length && !query.pairing ? 'shared' : 'onboarding';

        if (query.pairing && query.platform === 'figma') {
          const resolved = this.$router.resolve({ name: 'paired', query: { ...query, after_login: true } });
          newQuery = { redirect: resolved.href };
        }

        return this.$router.replace({ name: routeName, query: newQuery }, () => {});
      } else if (query.pairing && passOnBoarding) {
        let newQuery = query;
        newQuery.redirect = `http://${window.location.host}/figma/generate-token?callbackUrl=${newQuery.redirect}`;
        return this.$router.replace({ name: 'paired', query: { ...newQuery, after_login: true } });
      } else if (query.pairing) {
        return this.$router.replace({ name: 'paired', query: { ...query, after_login: true } });
      } else if (query.redirect) {
        if (query.redirect.startsWith('http')) {
          window.location.href = query.redirect;
          return;
        }

        const pairingRedirect = query.redirect?.includes('pairing=true')
          ? `${query.redirect}&after_login=true`
          : query.redirect;

        return this.$router.replace(pairingRedirect || '/', () => {});
      }

      return this.$router.replace({ name: routeName, query }, () => {});
    }
  }
};
