var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-page-container" },
    [
      _c("OnboardingTopBar", {
        attrs: { homepageUrl: "https://animaapp.com" }
      }),
      _c(
        "div",
        { staticClass: "signup-container" },
        [
          _vm.googleLoading
            ? _c("LoadingScreen")
            : _c("div", { staticClass: "form-container" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Create Account")]),
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _vm._v(" Already have an account? "),
                    _c(
                      "an-link",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "login",
                              query: _vm.$route.query
                            })
                          }
                        }
                      },
                      [_vm._v("Log in")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "social-signup" },
                  [
                    _c(
                      "GoogleButton",
                      {
                        on: {
                          success: _vm.onGoogleSignupSuccess,
                          failure: _vm.onGoogleSignupFailure
                        }
                      },
                      [_vm._v(" Sign up with Google ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-divider" },
                  [_c("Divider", { attrs: { text: "Or", theme: _vm.theme } })],
                  1
                ),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.signup.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm.nameRequired
                      ? _c("div", { staticClass: "property" }, [
                          _c("div", { staticClass: "name-property" }, [
                            _c(
                              "div",
                              { staticClass: "input" },
                              [
                                _c("an-input", {
                                  attrs: {
                                    theme: _vm.theme,
                                    invalid: !_vm.validFirstName,
                                    placeholder: "First name",
                                    "data-cy": "signup-first-name"
                                  },
                                  model: {
                                    value: _vm.firstName,
                                    callback: function($$v) {
                                      _vm.firstName = $$v
                                    },
                                    expression: "firstName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "input" },
                              [
                                _c("an-input", {
                                  attrs: {
                                    theme: _vm.theme,
                                    invalid: !_vm.validLastName,
                                    placeholder: "Last name",
                                    "data-cy": "signup-last-name"
                                  },
                                  model: {
                                    value: _vm.lastName,
                                    callback: function($$v) {
                                      _vm.lastName = $$v
                                    },
                                    expression: "lastName"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          !_vm.validFirstName || !_vm.validLastName
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-message",
                                  attrs: { "data-cy": "signup-name-error" }
                                },
                                [_vm._v(" Umm.. we hope you’re not a ghost ")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "property" }, [
                      _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c("an-input", {
                            attrs: {
                              id: "email",
                              type: "email",
                              placeholder: "name@work-email.com",
                              theme: _vm.theme,
                              invalid: !_vm.validEmail,
                              "data-cy": "signup-email"
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _vm.errorMessage
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-message",
                                  attrs: { "data-cy": "signup-email-error" }
                                },
                                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "property" }, [
                      _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c("an-input", {
                            attrs: {
                              id: "pass",
                              type: "password",
                              placeholder: "Password",
                              theme: _vm.theme,
                              invalid: !_vm.validPassword,
                              "data-cy": "signup-password"
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          !_vm.validPassword
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-message",
                                  attrs: { "data-cy": "signup-password-error" }
                                },
                                [
                                  _vm._v(
                                    " Password must contain at least 8 characters "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "terms-of-service" },
                      [
                        _vm._v(
                          " By creating an account, I agree with Anima’s "
                        ),
                        _c(
                          "an-link",
                          {
                            attrs: {
                              variant: "primary",
                              href: _vm.privacyUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v("Privacy Policy")]
                        ),
                        _vm._v(" and "),
                        _c(
                          "an-link",
                          {
                            attrs: {
                              variant: "primary",
                              href: _vm.termsUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v("Terms of Service.")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "an-button",
                          {
                            staticClass: "justify-center",
                            attrs: {
                              type: "submit",
                              isWorking: _vm.loading,
                              size: "xl",
                              "data-cy": "signup-submit"
                            }
                          },
                          [_vm._v(" Create account ")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
          !_vm.googleLoading
            ? _c("div", { staticClass: "signup-illustration" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }