var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Payment")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v("Add a payment method and review your upgrade details.")
    ]),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "billing-info" }, [
        _c(
          "div",
          { staticClass: "billing-plan" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("Billing plan")]),
            _vm._l(["annually", "monthly"], function(_interval) {
              return _c("PricingRow", {
                key: _interval,
                attrs: {
                  price: _vm.getPrice({
                    plan: _vm.planName,
                    interval: _interval
                  }),
                  planName: _vm.planNameDisplay,
                  planInterval: _interval,
                  isCurrentPlan: _interval === _vm.interval,
                  disabled: _vm.isActivePlan(_interval) || _vm.loading,
                  showRibbon:
                    _interval === "annually" &&
                    _vm.isNewSubscription &&
                    _vm.isDesktop
                },
                on: {
                  click: function($event) {
                    return _vm.changeInterval(_interval)
                  }
                }
              })
            })
          ],
          2
        ),
        _c("div", { staticClass: "paid-seats" }, [
          _c("div", { staticClass: "label" }, [
            _vm._v(" Select your team paid seats ")
          ]),
          _c(
            "div",
            { staticClass: "number-of-contributors" },
            [
              _c("span", [_vm._v("No. of contributors")]),
              _c("an-number-input", {
                attrs: {
                  min: _vm.minSeats,
                  max: 99,
                  disabled: _vm.loading,
                  minErrorTooltipText: _vm.minErrorTooltipText
                },
                model: {
                  value: _vm.quantity,
                  callback: function($$v) {
                    _vm.quantity = $$v
                  },
                  expression: "quantity"
                }
              })
            ],
            1
          )
        ]),
        _vm.isStripe
          ? _c("div", { staticClass: "separated" }, [
              _c(
                "div",
                { staticClass: "payment-method-label" },
                [
                  _vm._v(" Payment method "),
                  _c("svg-icon", {
                    attrs: { name: "powered-by-stripe", width: 110, height: 24 }
                  })
                ],
                1
              ),
              _vm.creditCardLastDigits && !_vm.clickedChange
                ? _c(
                    "div",
                    { staticClass: "payment-method-label label" },
                    [
                      _vm._v(" " + _vm._s(_vm.creditCardLastDigits) + " "),
                      _c(
                        "an-link",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.clickedChange = true
                            }
                          }
                        },
                        [_vm._v("Change")]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "payment" },
                    [
                      _c("StripeCardInput", {
                        on: {
                          mount: _vm.onStripeCardMount,
                          error: _vm.onStripeError
                        }
                      }),
                      _c("div", { staticClass: "invoice-address-label" }, [
                        _vm._v("Invoice to (optional)")
                      ]),
                      _c("an-textarea", {
                        attrs: {
                          placeholder: "Billing address",
                          withBorder: "",
                          allowMultiline: ""
                        },
                        model: {
                          value: _vm.invoiceTo,
                          callback: function($$v) {
                            _vm.invoiceTo = $$v
                          },
                          expression: "invoiceTo"
                        }
                      })
                    ],
                    1
                  )
            ])
          : _vm._e(),
        _vm.isStripe && _vm.isNewSubscription
          ? _c("div", { staticClass: "separated" }, [
              _c(
                "div",
                {
                  staticClass: "promo-code-header",
                  on: { click: _vm.toggleCouponCodeSection }
                },
                [
                  _vm._v(" Add a coupon code "),
                  _c(
                    "div",
                    {
                      staticClass: "collapse-icon",
                      class: { rotated: _vm.isPromoCodeOpen }
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "arrow-right", size: 30 }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm.isPromoCodeOpen
                ? _c("div", [
                    _vm.isActivePromoCode
                      ? _c(
                          "div",
                          { staticClass: "disclaimer promo-code-details" },
                          [
                            _c(
                              "div",
                              { staticClass: "promo-code-details-name" },
                              [
                                _vm._v(" " + _vm._s(_vm.promoCode) + " "),
                                _c(
                                  "an-link",
                                  {
                                    attrs: { variant: "primary" },
                                    on: { click: _vm.resetPromoCode }
                                  },
                                  [_vm._v("Remove")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "promo-code-details-saving" },
                              [_vm._v(" " + _vm._s(_vm.promoCodeSaving) + " ")]
                            )
                          ]
                        )
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "label promo-code-input-label" },
                            [_vm._v("Coupon code")]
                          ),
                          _c(
                            "div",
                            { staticClass: "promo-code-input-container" },
                            [
                              _c("an-input", {
                                staticClass: "promo-code-input",
                                model: {
                                  value: _vm.promoCode,
                                  callback: function($$v) {
                                    _vm.promoCode = $$v
                                  },
                                  expression: "promoCode"
                                }
                              }),
                              _c(
                                "an-button",
                                {
                                  attrs: {
                                    variant: "secondary",
                                    isWorking: _vm.isPromoCodeWorking,
                                    invalid: !!_vm.promoCodeError,
                                    disabled: !_vm.promoCode.trim()
                                  },
                                  on: { click: _vm.applyPromoCode }
                                },
                                [_vm._v(" Apply code ")]
                              )
                            ],
                            1
                          ),
                          _vm.promoCodeError
                            ? _c(
                                "div",
                                { staticClass: "promo-code-input-error" },
                                [_vm._v(_vm._s(_vm.promoCodeError))]
                              )
                            : _vm._e()
                        ])
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.showSwitchService
          ? _c("div", { staticClass: "disclaimer" }, [
              _vm.isStripe
                ? _c(
                    "span",
                    [
                      _vm._v(" Having trouble checking out? Try "),
                      _c(
                        "an-link",
                        {
                          attrs: {
                            "data-cy": "try-paypal",
                            variant: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchService("paypal")
                            }
                          }
                        },
                        [_vm._v("PayPal")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isPaypal
                ? _c(
                    "span",
                    [
                      _vm._v(" I changed my mind! Let me pay with my "),
                      _c(
                        "an-link",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.switchService("stripe")
                            }
                          }
                        },
                        [_vm._v("credit card")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "checkout" },
        [
          _c("div", { staticClass: "checkout-title" }, [_vm._v("Check out")]),
          _vm.loading
            ? _c("Loader")
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "checkout-list" },
                  _vm._l(_vm.checkoutDetails, function(line) {
                    return _c(
                      "div",
                      { key: line, staticClass: "checkout-list-item" },
                      [
                        _c("svg-icon", {
                          attrs: { name: "circle-check", size: 20 }
                        }),
                        _c("div", { staticClass: "checkout-list-item-text" }, [
                          _vm._v(_vm._s(line))
                        ])
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "total-price-container" },
                  [
                    _c("span", [_vm._v("Total")]),
                    _c("transition", { attrs: { name: "slide-fade" } }, [
                      _c(
                        "span",
                        {
                          key: _vm.priceAfterDiscountDisplay,
                          staticClass: "total-price"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.priceAfterDiscountDisplay) + " "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm.priceDetails
                  ? _c("div", { staticClass: "price-details" }, [
                      _vm._v(_vm._s(_vm.priceDetails))
                    ])
                  : _vm._e(),
                _vm.diffDisclaimer
                  ? _c("div", { staticClass: "diff-disclaimer" }, [
                      _vm._v(_vm._s(_vm.diffDisclaimer))
                    ])
                  : _vm._e(),
                _vm.hasChanges
                  ? _c("div", { staticClass: "cta" }, [
                      _vm.isPaypal
                        ? _c(
                            "div",
                            { staticClass: "paypal-button" },
                            [
                              _vm.showPaypalLoading || _vm.paypalSubscriptionId
                                ? _c(
                                    "an-button",
                                    {
                                      attrs: {
                                        variant: "tertiary",
                                        isWorking: _vm.isWorking
                                      },
                                      on: { click: _vm.purchase }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.ctaText) + " ")]
                                  )
                                : _c("PayPalButton", {
                                    attrs: {
                                      plan: _vm.planName,
                                      interval: _vm.interval,
                                      quantity: _vm.quantity
                                    },
                                    on: {
                                      click: _vm.onPaypalClick,
                                      approve: _vm.onPaypalApproved,
                                      error: _vm.onPaypalError
                                    }
                                  })
                            ],
                            1
                          )
                        : _vm.isStripe
                        ? _c(
                            "div",
                            [
                              _c(
                                "an-button",
                                {
                                  attrs: {
                                    "data-cy": "purchase-cta-btn",
                                    isWorking: _vm.isWorking
                                  },
                                  on: { click: _vm.purchase }
                                },
                                [_vm._v(_vm._s(_vm.ctaText))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
          _vm.errorMessage
            ? _c("div", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.errorMessage))
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }