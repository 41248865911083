<template>
  <div class="members">
    <an-user-avatar
      class="member"
      v-for="(member, index) in users"
      :key="member.user.email || index"
      :user="member.user"
      :borderColor="avatarStyle.borderColor"
      :size="size"
      @click="$emit('click', member)"
    />
    <div class="more" @click="$emit('click')" v-if="showMore && users.length">
      <svg-icon name="circle-plus" :size="size" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
      required: true
    },
    peekAmount: {
      type: Number,
      default: 5
    },
    size: {
      type: Number,
      default: 36
    },
    showMore: {
      type: Boolean,
      default: false
    },
    avatarStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    users() {
      return this.members.filter(m => m.user?.name || m.user?.email).slice(0, this.peekAmount);
    }
  }
};
</script>

<style lang="scss" scoped>
.members {
  display: flex;
  align-items: center;
  .member {
    z-index: 2;
  }
  .more {
    z-index: 1;
    cursor: pointer;
    border-radius: 100%;
  }
}
.more,
.avatar + .avatar {
  margin-left: -8px;
}
</style>
