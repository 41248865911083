<template>
  <transition name="codeFeedback">
    <div v-if="show" class="feedback">
      <div class="wrapper">
        <div @click="handleClose" class="close">
          <svg-icon :size="20" name="close" fill="currentColor"></svg-icon>
        </div>

        <template v-if="step == 'rate'">
          <div class="title">How would you rate the code quality?</div>
          <div class="numbers flex items-center">
            <div @click="rate(n)" class="number" v-for="n in 10" :key="n">
              {{ n }}
            </div>
          </div>
          <div class="text flex items-center">
            <div>Low</div>
            <div style="margin-left:auto">High</div>
          </div>
        </template>
        <template v-if="step == 'msg'">
          <div class="title">Help us improve</div>
          <div class="form-container">
            <an-textarea
              style="width:100%"
              @enter="handleFeedbackMsg"
              v-model="feedbackText"
              :className="'code-feedback-textarea'"
              autoFocus
              placeholder="How can we make it better?"
            />
            <div :class="{ active: this.feedbackText != '' }" @click="handleFeedbackMsg" class="icon-container">
              <svg-icon class="icon" name="send" :size="23" fill="currentColor"></svg-icon>
            </div>
          </div>
        </template>
        <template v-if="step == 'done'">
          <div class="flex items-center justify-center" style="height:100%;width:100%">
            Thank you !
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import { toastError } from '@/services/bus';
import axios from 'axios';
import { mapState } from 'vuex';
export default {
  props: ['show'],
  data() {
    return {
      step: 'rate',
      rating: -1,
      feedbackText: '',
      feedbackAlreadySent: false
    };
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' })
  },
  methods: {
    rate(n) {
      this.rating = n;
      this.$trackEvent('omniview-code-rating-clicked', { rating: n });
      this.step = 'msg';
    },
    done() {
      this.$emit('close');
      this.step = 'rate';
      this.rating = -1;
      localStorage.setItem('lastFeedbackTimestamp', Date.now().toString());
    },
    handleClose() {
      if (this.show) {
        if (this.feedbackAlreadySent || this.rating == -1) {
          this.done();
          return;
        }
        // if the user only rated and closed the popup send the rating
        this.$trackEvent('omniview.clean-code-rating', {
          rating: this.rating
        });

        this.done();
      }
    },
    async handleFeedbackMsg() {
      const feedback = this.feedbackText.trim();
      if (!feedback) return;
      try {
        const data = {
          feedback,
          source: 'Code Panel',
          email: this.currentUser.email
        };
        this.$trackEvent('omniview.clean-code-rating', {
          rating: this.rating
        });
        this.feedbackAlreadySent = true;

        this.step = 'done';
        setTimeout(() => {
          this.done();
        }, 500);
        await axios.post('/feedback', data);
      } catch (e) {
        toastError('Something went wrong on our side... Try again?');
        this.done();
      } finally {
        this.feedbackText = '';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.feedback {
  width: 440px;
  height: 152px;
  background: white;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 10px;
}
.wrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.close {
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}
.title {
  margin-bottom: 20px;
  color: #3b3b3b;
}
.numbers {
  margin-bottom: 8px;
}
.text {
  font-size: 14px;
  width: 100%;
  color: #3b3b3b;
  opacity: 0.4;
}
.number {
  width: 32px;
  height: 32px;
  border: 1px solid #a1a1a1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b3b3b;
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 8px;
  }
  &:hover {
    color: white;
    background: var(--primary);
    cursor: pointer;
  }
}

.form-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid;
  border-color: rgba(#3b3b3b, 0.4);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 16px;
  color: white;
  transition: background 0.2s;

  .icon {
    opacity: 0.3;
    left: -2px;
    position: relative;
    color: #3b3b3b;
  }

  &.active {
    cursor: pointer;
    background: var(--primary);
    color: white;
    border-color: transparent;

    .icon {
      opacity: 1;
      color: white;
    }
  }

  &:hover {
  }
}
</style>

<style lang="scss">
.code-feedback-textarea {
  background: white;
  border-radius: 20px;
  padding: 8px 24px;
  border: 1px solid;
  border-color: var(--primary);
  height: 42px;
  max-height: 66px;
  color: #3b3b3b;
  caret-color: var(--primary);

  &::-webkit-scrollbar {
    width: 0;
  }

  &::placeholder {
    font-size: 14px;
    color: #3b3b3b;
    opacity: 0.4;
  }
}
</style>
