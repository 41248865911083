var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "codeFeedback" } }, [
    _vm.show
      ? _c("div", { staticClass: "feedback" }, [
          _c(
            "div",
            { staticClass: "wrapper" },
            [
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.handleClose } },
                [
                  _c("svg-icon", {
                    attrs: { size: 20, name: "close", fill: "currentColor" }
                  })
                ],
                1
              ),
              _vm.step == "rate"
                ? [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("How would you rate the code quality?")
                    ]),
                    _c(
                      "div",
                      { staticClass: "numbers flex items-center" },
                      _vm._l(10, function(n) {
                        return _c(
                          "div",
                          {
                            key: n,
                            staticClass: "number",
                            on: {
                              click: function($event) {
                                return _vm.rate(n)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(n) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "text flex items-center" }, [
                      _c("div", [_vm._v("Low")]),
                      _c("div", { staticStyle: { "margin-left": "auto" } }, [
                        _vm._v("High")
                      ])
                    ])
                  ]
                : _vm._e(),
              _vm.step == "msg"
                ? [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("Help us improve")
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-container" },
                      [
                        _c("an-textarea", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            className: "code-feedback-textarea",
                            autoFocus: "",
                            placeholder: "How can we make it better?"
                          },
                          on: { enter: _vm.handleFeedbackMsg },
                          model: {
                            value: _vm.feedbackText,
                            callback: function($$v) {
                              _vm.feedbackText = $$v
                            },
                            expression: "feedbackText"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "icon-container",
                            class: { active: this.feedbackText != "" },
                            on: { click: _vm.handleFeedbackMsg }
                          },
                          [
                            _c("svg-icon", {
                              staticClass: "icon",
                              attrs: {
                                name: "send",
                                size: 23,
                                fill: "currentColor"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.step == "done"
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center justify-center",
                        staticStyle: { height: "100%", width: "100%" }
                      },
                      [_vm._v(" Thank you ! ")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }