<template>
  <div>
    <div class="stripe-container" :class="[{ 'stripe-container-errr': this.error }]">
      <div ref="stripeCardInput" />
    </div>
    <div class="error" v-show="error">{{ this.error }}</div>
  </div>
</template>

<script>
import { createCardInstance } from '@/services/payment/stripe';

export default {
  props: {
    onError: {
      type: Function,
      default: console.error
    }
  },
  data() {
    return {
      error: ''
    };
  },
  mounted() {
    this.setStripeCardInput();
  },
  methods: {
    async setStripeCardInput() {
      const card = await createCardInstance(this.$refs.stripeCardInput, this.onChange);
      this.$emit('mount', card);
    },
    onChange(event) {
      if (event.error) this.$emit('error', event.error);
      this.$emit('change', event);
      this.error = event.error.message;
    }
  }
};
</script>

<style scoped>
.stripe-container {
  margin: 10px auto;
  padding: 16px;
  border: var(--border);
  border-radius: 10px;
}
.error {
  color: rgb(226, 65, 46);
}
.stripe-container-errr {
  border-color: rgb(226, 65, 46);
}
</style>
