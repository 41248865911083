var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-guests-container" }, [
    _c("div", { staticClass: "guests-invite inviteLayout" }, [
      _c("div", { staticClass: "separated inviteLayout" }, [
        _vm.inviteLayout
          ? _c("div", { staticClass: "invite-description" }, [
              _vm._v(
                " Invite guests only to this project to view prototypes & leave"
              ),
              _c("br"),
              _vm._v(" comments. Ideal for external users and clients. ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "label" }, [_vm._v("Invite project guests")]),
        _c(
          "div",
          { staticClass: "guests-input-form" },
          [
            _c("an-input", {
              attrs: { placeholder: "Email address" },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addGuest.apply(null, arguments)
                },
                focus: function($event) {
                  _vm.focusForm = true
                },
                blur: function($event) {
                  _vm.focusForm = false
                }
              },
              model: {
                value: _vm.email,
                callback: function($$v) {
                  _vm.email = $$v
                },
                expression: "email"
              }
            }),
            _c(
              "div",
              { staticClass: "add-button" },
              [
                _c("AddButton", {
                  attrs: { submitted: _vm.submitted, focus: _vm.focusForm },
                  on: { click: _vm.addGuest }
                })
              ],
              1
            )
          ],
          1
        ),
        !_vm.inviteLayout
          ? _c("div", { staticClass: "secondary-text margin-top" }, [
              _vm._v(
                " Guests only have access to projects they’re invited to. Guests can view prototypes and leave comments. Ideal for external users and clients. You can invite as many guests as you'd like for free. "
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "rows" },
        _vm._l(_vm.guests, function(guest) {
          return _c("GuestRow", {
            key: guest.id,
            attrs: { guest: guest },
            on: {
              remove: function($event) {
                return _vm.removeGuest(guest)
              }
            }
          })
        }),
        1
      )
    ]),
    !_vm.inviteLayout
      ? _c("div", { staticClass: "team-members-disclaimer" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Team members")]),
          _c("div", { staticClass: "text-container" }, [
            _vm._v(
              " This project is accessible to all members of team " +
                _vm._s(_vm.team.name) +
                " "
            ),
            _c(
              "div",
              [
                _c(
                  "an-link",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.goToTeamSettings }
                  },
                  [_vm._v("Manage Team Members")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }