var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multi-select-bar" }, [
    _c("div", { staticClass: "number-items", on: { click: _vm.clickCheck } }, [
      _c(
        "div",
        { staticClass: "clickable-icon uncheck" },
        [_c("svg-icon", { attrs: { name: _vm.checkIcon, size: 24 } })],
        1
      ),
      _vm._v(" " + _vm._s(_vm.amountMessage) + " ")
    ]),
    _c("div", { staticClass: "actions" }, [
      _c(
        "div",
        {
          staticClass: "clickable-icon trash",
          on: {
            mouseover: function($event) {
              _vm.hoverIcon = true
            },
            mouseout: function($event) {
              _vm.hoverIcon = false
            },
            click: function($event) {
              return _vm.$emit("delete")
            }
          }
        },
        [
          _c("div", {
            ref: "trashIcon",
            attrs: { size: 30 },
            domProps: {
              innerHTML: _vm._s(require("!html-loader!@/assets/svg/trash.svg"))
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }