<template>
  <a :class="classes" v-bind="$attrs" v-on="listeners" :data-content="innerText">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'an-link',
  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator: value => {
        return ['primary', 'secondary'].indexOf(value) !== -1;
      }
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value)
    },
    primaryHover: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      const { disabled, variant, theme, primaryHover } = this;
      return ['an-link', variant, theme, { 'primary-hover': primaryHover, disabled }];
    },
    innerText() {
      return this.getChildrenTextContent(this.$slots.default);
    },
    listeners() {
      const click = this.disabled ? () => {} : this.$listeners?.click;
      return {
        ...this.$listeners,
        click: click || (() => {})
      };
    }
  },
  methods: {
    getChildrenTextContent(children) {
      return children.map(node => (node.children ? this.getChildrenTextContent(node.children) : node.text)).join('');
    }
  }
};
</script>

<style lang="scss" scoped>
.an-link {
  display: inline-block;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
  &.primary {
    color: var(--primary);
    &:hover {
      color: var(--primary-hover);
    }
  }
  &.secondary {
    color: var(--primary-text);
    &.dark {
      color: var(--dark-primary-text);
    }
  }
  &.disabled {
    opacity: 0.3;
  }
}
.an-link:before {
  display: block;
  content: attr(data-content);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.primary-hover:hover {
  @include desktop {
    color: var(--primary) !important;
  }
}
</style>
