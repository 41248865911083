var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar" }, [
    _c(
      "div",
      { staticClass: "logo" },
      [
        _vm.homepageUrl
          ? _c(
              "an-link",
              { attrs: { href: _vm.homepageUrl } },
              [
                _c("an-logo", {
                  attrs: { height: _vm.logoHeight, width: _vm.logoWidth }
                })
              ],
              1
            )
          : _c("an-logo", {
              attrs: { height: _vm.logoHeight, width: _vm.logoWidth }
            })
      ],
      1
    ),
    _vm.closeButton
      ? _c(
          "div",
          {
            staticClass: "close-button",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [
            _c("svg-icon", {
              attrs: { name: "close-white", size: _vm.closeButtonSize }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }