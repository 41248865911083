<template>
  <div class="post-container">
    <div class="title">{{ title }}</div>
    <youtube :video-id="src" :player-vars="playerVars" />
  </div>
</template>

<script>
export default {
  name: 'learn-video-modal',
  data() {
    return {
      playerVars: {
        autoplay: 1,
        modestbranding: 1
      }
    };
  },
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.post-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto;
  width: 100%;
}
.title {
  @include headline;
  font-weight: 400;
  margin-bottom: 30px;
}
</style>
