import { render, staticRenderFns } from "./Popover.vue?vue&type=template&id=60b1d214&scoped=true&"
import script from "./Popover.vue?vue&type=script&lang=js&"
export * from "./Popover.vue?vue&type=script&lang=js&"
import style0 from "./Popover.vue?vue&type=style&index=0&id=60b1d214&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b1d214",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60b1d214')) {
      api.createRecord('60b1d214', component.options)
    } else {
      api.reload('60b1d214', component.options)
    }
    module.hot.accept("./Popover.vue?vue&type=template&id=60b1d214&scoped=true&", function () {
      api.rerender('60b1d214', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Popovers/Popover.vue"
export default component.exports