var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-right" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDesktop || _vm.isOpen,
            expression: "isDesktop || isOpen"
          }
        ],
        staticClass: "sidebar-container"
      },
      [
        _vm.isDesktop
          ? _c(
              "div",
              { staticClass: "logo-section" },
              [_c("router-link", { attrs: { to: "/" } }, [_c("an-logo")], 1)],
              1
            )
          : _vm._e(),
        _vm.isMobile
          ? _c("div", { staticStyle: { width: "100%" } }, [
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "close", size: 30, fill: "white" }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "user-details-row" }, [
                _c(
                  "div",
                  [
                    _c("an-user-avatar", {
                      attrs: { user: _vm.user, size: 60 }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.user.name))
                  ]),
                  _c("div", { staticClass: "user-email" }, [
                    _vm._v(_vm._s(_vm.user.email))
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "section-first" }, [
          _vm.showSharedProjects
            ? _c(
                "div",
                {
                  staticClass: "link-label",
                  on: {
                    click: function($event) {
                      return _vm.navigate("shared")
                    }
                  }
                },
                [_vm._v("Shared with me")]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "title teams" },
          [
            _c("span", [_vm._v("Teams")]),
            _vm.isDesktop
              ? _c(
                  "an-tooltip",
                  {
                    attrs: { text: "New team", position: "top-left", offset: 5 }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "add-team", on: { click: _vm.newTeam } },
                      [_c("svg-icon", { attrs: { name: "plus" } })],
                      1
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "section" },
          _vm._l(_vm.userTeams, function(team) {
            return _c(
              "div",
              {
                key: team.id,
                staticClass: "team-link",
                attrs: { "data-cy": "team-link" },
                on: {
                  click: function($event) {
                    return _vm.navigateToTeam(team)
                  }
                }
              },
              [
                _c("div", { class: _vm.teamLinkClasses(team) }, [
                  _vm._v(" " + _vm._s(team.name) + " ")
                ]),
                _vm.shouldShowPlan
                  ? _c("div", { staticClass: "plan" }, [
                      _vm._v(" " + _vm._s(_vm.fixPlanName(team.plan)) + " ")
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _vm.isDesktop
          ? _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("Discover Anima")])
            ])
          : _vm._e(),
        _c("div", { staticClass: "section last" }, [
          _vm.isDesktop
            ? _c(
                "div",
                {
                  staticClass: "link-label",
                  on: {
                    click: function($event) {
                      return _vm.navigate("plugins")
                    }
                  }
                },
                [_vm._v("Plugins")]
              )
            : _vm._e(),
          _vm.isDesktop
            ? _c("div", { staticClass: "link-label" }, [
                _c(
                  "a",
                  {
                    attrs: { href: _vm.anima101Url, target: "_blank" },
                    on: {
                      click: function($event) {
                        return _vm.trackNavigation("Anima 101")
                      }
                    }
                  },
                  [_vm._v(" Anima 101 ")]
                )
              ])
            : _vm._e(),
          _vm.isDesktop
            ? _c(
                "div",
                {
                  class: [
                    "link-label",
                    { selected: _vm.$route.name === "learn" }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.navigate("learn")
                    }
                  }
                },
                [_vm._v(" Learn ")]
              )
            : _vm._e(),
          _vm.isMobile
            ? _c(
                "div",
                { staticClass: "link-label", on: { click: _vm.logout } },
                [_vm._v("Logout")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              staticClass: "link-label"
            },
            [_vm._v("Showcase")]
          )
        ]),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.showBanner
            ? _c(
                "div",
                { staticClass: "anima-5-promo" },
                [
                  _c("Anima5Banner", {
                    on: {
                      click: _vm.goToBlogPost,
                      close: _vm.closeAnima5Banner
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }