import { pick, isEmpty, keys } from 'lodash-es';
import { readCookie, createCookie } from '@/utils/cookie';
import { trackEvent } from '@/services/tracking';
import dayjs from 'dayjs';

const EXPERIMENTS_COOKIE_NAME = process.env.EXPERIMENTS_COOKIE_NAME;

// given a list of variants, choose one randomly (given a chance)
const decideBetweenVariants = (variants = [], chance = 1) => {
  let accValue = 0;
  for (const variant of variants) {
    accValue += variant.traffic;
    if (chance < accValue) return variant.value;
  }
  return null;
};

// given an experiment, randomly choose if experiment is active.
// if experiment has variants, randomly choose one.
const decideExperiment = ({ variants, traffic }) => {
  const randomNumber = Math.random();
  if (!isEmpty(variants)) return decideBetweenVariants(variants, randomNumber);
  return randomNumber < traffic;
};

// extract user's experiments from cookie, and remove inactive ones.
const extractActiveUserExperiments = activeExperiments => {
  const userExperiments = JSON.parse(readCookie(EXPERIMENTS_COOKIE_NAME) || '{}');
  const { _lastUpdated: userExperimentsUpdatedAt } = userExperiments;
  const activeExperimentNames = activeExperiments
    .filter(({ updated_at: experimentUpdatedAt }) => {
      // remove outdated experiments from list
      if (!experimentUpdatedAt) return true;
      return dayjs(userExperimentsUpdatedAt).isAfter(experimentUpdatedAt);
    })
    .map(exp => exp.name);

  return pick(userExperiments, activeExperimentNames);
};

// given a list of experiments, randomly choose which of them is active.
const decideExperiments = experiments =>
  experiments.reduce((acc, exp) => {
    const value = decideExperiment(exp);
    return { ...acc, [exp.name]: value || false };
  }, {});

const extractQueryParam = paramKey => {
  // extract query params
  // eslint-disable-next-line no-unused-vars
  const [_, query] = decodeURIComponent(window.location.toString()).split('?');
  const params = (query ?? '')
    .split('&')
    .filter(p => p)
    .map(p => p.split('='));

  // find param
  return params.find(param => param[0] === paramKey);
};

// this string: exp1:value1;exp2;exp3:false;exp4:true
// will result this: {exp1: 'value1', exp2: true, exp3: false, exp4: true}
const overrideStringToObject = queryItem => {
  // separate each experiment by ';'
  if (!isEmpty(queryItem)) {
    const arr = (queryItem[1] || '').split(';');
    return arr.reduce((acc, curr) => {
      // separate key-values by ':'
      let [name, value] = curr.split(':');
      if (!name) return acc;
      if (!value || value === 'true') value = true;
      else if (value === 'false') value = false;
      return { ...acc, [name]: value };
    }, {});
  }
  return {};
};

// extract experiment overrides from url.
// this query: <url>?exp.ovr=exp1:value1;exp2;exp3:false;exp4:true
// will result this: {exp1: 'value1', exp2: true, exp3: false, exp4: true}
const getOverridesFromURL = () => {
  try {
    const expOverrides = extractQueryParam('exp.ovr');
    return overrideStringToObject(expOverrides);
  } catch (e) {
    console.debug(e);
  }
  return {};
};

export const getFeatureToggles = () => {
  try {
    const featureToggles = extractQueryParam('feat.tgl');
    return overrideStringToObject(featureToggles);
  } catch (e) {
    console.debug(e);
  }
  return {};
};

export const overrideManually = (currentExperiments, key, value) => ({ ...currentExperiments, [key]: value || true });

export const generateUserExperiments = (experiments = []) => {
  const expObject = decideExperiments(experiments);
  const userExperiments = extractActiveUserExperiments(experiments);
  const overrides = getOverridesFromURL();
  const _lastUpdated = new Date().getTime();
  return { ...expObject, ...userExperiments, ...overrides, _lastUpdated };
};

export const setUserExperimentsCookie = userExperiments => {
  createCookie(EXPERIMENTS_COOKIE_NAME, JSON.stringify(userExperiments), 30);
};

export const trackExperiments = experiments => {
  keys(experiments)
    .filter(key => key !== '_lastUpdated')
    .forEach(key => {
      const variant = experiments[key];
      trackEvent('$experiment_started', { 'Experiment name': key, 'Variant name': String(variant) });
    });
};
