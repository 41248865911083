var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OnboardingTopBar", {
        attrs: { homepageUrl: "https://animaapp.com" }
      }),
      _c(
        "div",
        { staticClass: "onboarding-container" },
        [
          !_vm.isStep0
            ? _c("div", { staticClass: "small-title" }, [_vm._v("Get started")])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "skip" },
            [
              _c(
                "an-link",
                {
                  key: _vm.skipText,
                  attrs: { "data-cy": "skip-onboarding", theme: _vm.theme },
                  on: {
                    click: function($event) {
                      return _vm.doneOnboarding("skip")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.skipText) + " ")]
              )
            ],
            1
          ),
          _vm.isStep0
            ? _c("div", { staticClass: "step-0" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Hello " + _vm._s(_vm.firstName) + ",")
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    " Let’s personalize your Anima experience & make it perfect for your needs. "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c(
                      "an-button",
                      {
                        attrs: {
                          "data-cy": "start-onboarding-questions",
                          size: "xl"
                        },
                        on: { click: _vm.nextStep }
                      },
                      [_vm._v("Let's do it")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "transition-group",
            { attrs: { name: "slide-onboarding", tag: "span" } },
            [
              _vm.isRoleStep
                ? _c("span", { key: "step-1" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.roleText))
                    ]),
                    _c(
                      "span",
                      {
                        class: {
                          "onboarding-select-container": true,
                          selected: !!_vm.role
                        }
                      },
                      [
                        _c("OnboardingSelect", {
                          attrs: {
                            value: _vm.role,
                            items: _vm.roleOptions,
                            theme: "dark",
                            isOpenByDefault: ""
                          },
                          on: { choice: _vm.selectRole }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.isWorkplaceStep
                ? _c("span", { key: "step-2" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(" , and I "),
                      _c("span", { class: { strike: _vm.showStrike } }, [
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.workplaceText))
                        ])
                      ])
                    ]),
                    _c(
                      "span",
                      {
                        class: {
                          "onboarding-select-container": true,
                          selected: !!_vm.workPlace
                        }
                      },
                      [
                        _c("OnboardingSelect", {
                          attrs: {
                            value: _vm.workPlace,
                            items: _vm.workPlaceOptions,
                            theme: "dark",
                            isOpenByDefault: ""
                          },
                          on: { choice: _vm.selectWorkplace }
                        })
                      ],
                      1
                    ),
                    _vm.isUsageStep
                      ? _c("span", { staticClass: "text" }, [_vm._v(".")])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.isUsageStep
                ? _c(
                    "span",
                    { key: "step-3", staticClass: "break-line-desktop" },
                    [
                      _c("span", { staticClass: "text break-line-mobile" }, [
                        _vm._v(" I want to use Anima to ")
                      ]),
                      _c(
                        "span",
                        {
                          class: {
                            "onboarding-select-container": true,
                            selected: !!_vm.workPlace
                          }
                        },
                        [
                          _c("OnboardingSelect", {
                            attrs: {
                              value: _vm.usage,
                              items: _vm.usageOptions,
                              theme: "dark",
                              width: _vm.isDesktop ? "360px" : "295px",
                              isOpenByDefault: ""
                            },
                            on: { choice: _vm.selectUsage }
                          })
                        ],
                        1
                      ),
                      _vm.isDone
                        ? _c("span", { staticClass: "text" }, [_vm._v(".")])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm.isDone
            ? _c(
                "div",
                { staticClass: "done actions" },
                [
                  _c(
                    "an-button",
                    {
                      attrs: {
                        "data-cy": "finish-onboarding-questions",
                        size: "xl"
                      },
                      on: { click: _vm.doneOnboarding }
                    },
                    [_vm._v("Let's go!")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }