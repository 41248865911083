<template>
  <div :class="['member-row', $theme]" @mouseover="hover = true" @mouseleave="hover = false">
    <div :class="['details-container', { pending: isPending && !hover }]">
      <div class="avatar-placeholder">
        <RoundedImage v-if="isPending" :width="36" :height="36" circle dashedEmptyState />
        <an-user-avatar v-else :user="user" :theme="$theme" />
      </div>
      {{ member.email }}
    </div>
    <div v-if="isPending" :class="['pending-actions', { pending: !hover }]">
      <transition name="fadeIn" mode="out-in">
        <div v-if="hover">
          <an-link class="remove-link" @click="$emit('remove', member)" variant="primary">Remove</an-link>
          <an-link @click="$emit('resend-invite', member)" primaryHover>Resend</an-link>
        </div>
        <div v-else>Pending</div>
      </transition>
    </div>
    <div v-else class="cta" @mouseover="roleSelectHover = true" @mouseleave="roleSelectHover = false">
      <RoleSelect
        :role="member.role"
        :accessLevel="member.access_level"
        :withAdmin="roleOptions.withAdmin"
        :withDelete="roleOptions.withDelete"
        :isDeleted="!roleSelectHover"
        :allowDeleting="isCurrentMember"
        @change-role="onSelectRole"
        @remove="$emit('remove', member)"
        @un-remove="$emit('un-remove', member)"
      />
    </div>
  </div>
</template>

<script>
import RoundedImage from '@/components/Upload/RoundedImage';
import RoleSelect from '@/components/Team/Settings/RoleSelect';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      hover: false,
      roleSelectHover: false
    };
  },
  props: {
    member: {
      type: Object,
      required: true
    },
    roleOptions: {
      type: Object,
      default: () => ({ withAdmin: true, withDelete: true })
    }
  },
  components: {
    RoleSelect,
    RoundedImage
  },
  computed: {
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    user() {
      const { member } = this;
      if (member.user?.email) {
        return member.user;
      }
      return member;
    },
    isPending() {
      const { user_user_id: userId } = this.member ?? {};
      return !userId;
    },
    isCurrentMember() {
      return this.teamMembership?.email === this.user?.email;
    }
  },
  methods: {
    onSelectRole(updatedRole) {
      const { member } = this;
      this.$emit('change-role', { member, updatedRole });
    }
  }
};
</script>

<style lang="scss" scoped>
.member-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  div {
    display: flex;
    align-items: center;
    .avatar-placeholder {
      margin-right: 16px;
      flex-shrink: 0;
    }
  }
  .cta {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: 10px;
  }
}
.details-container {
  transition: opacity 1s ease;
  @include ellipsis;
  flex: 1;
  min-width: 0;
  &.pending {
    opacity: 0.4;
  }
}
.pending-actions {
  transition: opacity 1s ease;
  &.pending {
    opacity: 0.4;
  }
  .remove-link {
    margin-right: 20px;
  }
}
</style>
