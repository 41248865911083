var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "purchase_message_container" },
      [
        _vm._m(0),
        _c("h2", { staticClass: "title" }, [_vm._v("Upgrade Complete!")]),
        _c("p", { staticClass: "text" }, [
          _vm._v("Thank you! Your Anima plan was successfully upgraded.")
        ]),
        _c(
          "an-button",
          {
            staticClass: "button",
            attrs: { "data-cy": "navigate" },
            on: { click: _vm.navigate }
          },
          [_vm._v(_vm._s(_vm.ctaText))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img_container" }, [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/successful-payment.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }