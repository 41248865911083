var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c", attrs: { id: "breakpoints-selector" } },
    [
      _c("div", { staticClass: "spacer" }),
      _c(
        "div",
        { staticClass: "breakpoints" },
        [
          _c(
            "v-tip",
            {
              attrs: {
                trigger: "hover",
                offset: "12",
                delay: { show: 400, hide: 0 }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "breakpoint-container",
                  class: { selected: _vm.activeBreakpoint.id == "res" },
                  attrs: { id: "responsive-breakpoint" },
                  on: {
                    click: function($event) {
                      return _vm.handleBreakpointChange({ id: "res" })
                    }
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "br-icon",
                    attrs: {
                      fill: "currentColor",
                      size: 20,
                      name: "responsive"
                    }
                  }),
                  _c("div", { staticClass: "br-text" }, [_vm._v("Responsive")])
                ],
                1
              ),
              _c("template", { slot: "popover" }, [
                _c("div", { staticClass: "br-tip" }, [
                  _c("div", { staticClass: "br-tip-title" }, [
                    _vm._v(" Responsive ")
                  ]),
                  _c("div", { staticClass: "br-tip-text" }, [
                    _vm._v(
                      " Adjusts View to current screen size with all available breakpoints. "
                    )
                  ])
                ])
              ])
            ],
            2
          ),
          _vm._l(_vm.visibleBreakpoints, function(br, i) {
            return _c(
              "v-tip",
              {
                key: i,
                attrs: {
                  trigger: "hover",
                  offset: "12",
                  delay: { show: 400, hide: 0 }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "breakpoint-container",
                    class: { selected: _vm.activeBreakpoint.id == br.id },
                    on: {
                      click: function($event) {
                        return _vm.handleBreakpointChange(br)
                      }
                    }
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "br-icon",
                      attrs: {
                        fill: "currentColor",
                        size: 20,
                        name: _vm.mapWidthToBreakpointIcon(br.width)
                      }
                    }),
                    _c("div", { staticClass: "br-text" }, [
                      _vm._v(_vm._s(_vm.mapWidthToBreakpointText(br.width)))
                    ])
                  ],
                  1
                ),
                _c("template", { slot: "popover" }, [
                  _c("div", { staticClass: "br-tip" }, [
                    _c("div", { staticClass: "br-tip-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.mapWidthToBreakpointText(br.width)) +
                          " "
                      ),
                      _c("div", { staticClass: "br-tip-range" }, [
                        _vm._v(_vm._s(_vm.mapWidthToBreakpointRange(br.width)))
                      ])
                    ]),
                    _c("div", { staticClass: "br-tip-text" }, [
                      _vm._v(
                        " Adjusts view to " +
                          _vm._s(_vm.mapWidthToBreakpointText(br.width)) +
                          " screen size, depends on the design you synced. "
                      )
                    ])
                  ])
                ])
              ],
              2
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "br-selector" },
        [
          _c(
            "v-tip",
            {
              attrs: {
                placement: "bottom-center",
                trigger: "click",
                offset: "12"
              }
            },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: {
                  size: 20,
                  fill: "currentColor",
                  name: "three-dots-vertical"
                }
              }),
              _c("template", { slot: "popover" }, [
                _c("div", { staticClass: "br-pop" }, [
                  _c("div", { staticClass: "br-pop-title" }, [
                    _vm._v(" Breakpoints ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "br-pop-body" },
                    [
                      _vm.menuBreakpoints.length == 0
                        ? _c("div", { staticStyle: { padding: "12px 16px" } }, [
                            _vm._v(
                              " No additional breakpoints yet. Use Anima plugin to sync more breakpoints and enable viewing this screen in different screen sizes. "
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex items-center",
                                staticStyle: { padding: "4px 16px" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "learn-more",
                                    attrs: {
                                      href:
                                        "https://medium.com/sketch-app-sources/how-to-add-breakpoints-to-your-sketch-prototype-4896bed5ebf2",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Learn how")]
                                )
                              ]
                            )
                          ])
                        : [
                            _vm._l(_vm.menuBreakpoints, function(br) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  key: br.id,
                                  staticClass: "br-pop-item",
                                  class: {
                                    selected: _vm.activeBreakpoint.id == br.id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleBreakpointChange(br)
                                    }
                                  }
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "br-icon",
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: {
                                      fill: "currentColor",
                                      size: 20,
                                      name: _vm.mapWidthToBreakpointIcon(
                                        br.width
                                      )
                                    }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mapWidthToBreakpointRange(br.width)
                                      )
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "auto",
                                        "text-transform": "capitalize"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.mapWidthToBreakpointText(br.width)
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            _c(
                              "div",
                              { staticStyle: { padding: "12px 16px" } },
                              [
                                _vm._v(
                                  " Use Anima plugin to sync more breakpoints and enable viewing this screen in different screen sizes. "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex items-center",
                                staticStyle: { padding: "4px 16px" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "learn-more",
                                    attrs: {
                                      href:
                                        "https://medium.com/sketch-app-sources/how-to-add-breakpoints-to-your-sketch-prototype-4896bed5ebf2",
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Learn how")]
                                )
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "spacer" }),
      _c("div", { staticClass: "width-indicator" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isEditableWidth,
                expression: "!isEditableWidth"
              }
            ],
            on: { click: _vm.toggleEditableWidth }
          },
          [_vm._v(_vm._s(_vm.currentFrameWidth) + " ")]
        ),
        _c("input", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEditableWidth,
              expression: "isEditableWidth"
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newWidth,
              expression: "newWidth"
            }
          ],
          ref: "widthInputRef",
          staticClass: "widthInput",
          attrs: { type: "text", maxlength: "4" },
          domProps: { value: _vm.newWidth },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newWidth = $event.target.value
              },
              _vm.limit
            ],
            blur: _vm.submitNewWidth,
            keypress: _vm.submitNewWidth,
            focus: _vm.handleWidthFocus
          }
        }),
        _c("span", { staticClass: "unit" }, [_vm._v("px")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }