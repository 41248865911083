var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-in-out-right" } }, [
    _vm.show
      ? _c(
          "div",
          {
            staticClass: "intercom-bubble",
            attrs: { "data-cy": "intercom-bubble" },
            on: {
              mouseover: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.onMouseOver.apply(null, arguments)
              },
              mouseleave: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.onMouseLeave.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "avatar" }, [
              _c("img", { attrs: { src: _vm.contactAvatarSrc } })
            ]),
            _c(
              "div",
              {
                staticClass: "content",
                attrs: { "data-cy": "intercom-bubble-content" },
                on: { click: _vm.onClick }
              },
              [
                _c("div", { staticClass: "contact-details" }, [
                  _c("b", [_vm._v(_vm._s(_vm.contactName))]),
                  _vm._v(" from Anima ")
                ]),
                _c("div", {
                  staticClass: "message",
                  domProps: { innerHTML: _vm._s(_vm.messageHtml) }
                })
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }