// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/small-arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".disable-events[data-v-478be2fc] {\n  pointer-events: none;\n  opacity: 0.4;\n}\n.artboard-checkbox[data-v-478be2fc] {\n  padding: 15px 0;\n}\n.artboard-checkbox[data-v-478be2fc]:first-child {\n  margin-top: 15px;\n}\n.artboard-checkbox[data-v-478be2fc]:last-child {\n  margin-bottom: 15px;\n}\n.toggle-collapse[data-v-478be2fc] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 15px;\n  height: 15px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: 30px 30px;\n}\n.toggle-collapse.down[data-v-478be2fc] {\n  transform: rotate(180deg);\n}\n.pages-selection[data-v-478be2fc] {\n  padding: 30px 90px;\n  overflow-y: auto;\n  max-height: 35vh;\n}\n.page-title[data-v-478be2fc] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\nh3[data-v-478be2fc],\nh4[data-v-478be2fc] {\n  font-family: \"Roslindale\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 26px;\n}\n.root-selection[data-v-478be2fc] {\n  align-items: center;\n  cursor: pointer;\n  border-radius: 10px;\n  display: flex;\n  padding: 30px 20px;\n}\n.root-selection img[data-v-478be2fc] {\n  margin-right: 25px;\n}\n.root-selection.marked[data-v-478be2fc] {\n  background: var(--light-container-background);\n}\n.content[data-v-478be2fc] {\n  width: 50%;\n  max-width: 750px;\n  text-align: left;\n}\n.secondary[data-v-478be2fc] {\n  opacity: 0.4;\n}\n.title[data-v-478be2fc] {\n  margin-bottom: 20px;\n}\n.category[data-v-478be2fc] {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.page-section:last-child .divider[data-v-478be2fc] {\n  display: none;\n}\n.actions[data-v-478be2fc] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.actions .divider[data-v-478be2fc] {\n  margin-bottom: 40px;\n}\n.actions > *[data-v-478be2fc] {\n  margin-bottom: 20px;\n}", ""]);
// Exports
module.exports = exports;
