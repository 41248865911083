var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Team settings")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v("Edit your team details and manage integrations")
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("div", { staticClass: "label" }, [_vm._v("Team name")]),
        _c("an-input", {
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _c(
          "div",
          { staticClass: "upload" },
          [
            _c("UploadTeamLogo", {
              attrs: { team: _vm.team },
              on: { success: _vm.reloadTeam },
              nativeOn: {
                click: function($event) {
                  return _vm.onUploadClick.apply(null, arguments)
                }
              }
            }),
            _vm._m(0)
          ],
          1
        ),
        _c("div", { staticClass: "integrations" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Integrations")]),
          _c("div", { staticClass: "integration" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "integration-name" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("svg-icon", { attrs: { name: "slack-logo", size: 24 } })],
                  1
                ),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.slackHeader))
                ])
              ]),
              _c("div", { staticClass: "secondary-text small" }, [
                _vm._v(
                  " Connect Anima to your Slack workspace and notify your team with updates. "
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _vm.isConnectedWithSlack
                  ? _c(
                      "an-button",
                      {
                        attrs: {
                          variant: "empty",
                          isWorking: _vm.isWorkingSlack
                        },
                        on: { click: _vm.removeSlack }
                      },
                      [_vm._v(" Remove ")]
                    )
                  : _c(
                      "an-button",
                      {
                        attrs: {
                          variant: "secondary",
                          isWorking: _vm.isWorkingSlack
                        },
                        on: { click: _vm.connectToSlack }
                      },
                      [_vm._v(" Connect ")]
                    )
              ],
              1
            )
          ])
        ])
      ],
      1
    ),
    _vm.hasChanges
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _c(
              "an-button",
              { attrs: { isWorking: _vm.isWorking }, on: { click: _vm.save } },
              [_vm._v("Save changes")]
            ),
            _c(
              "an-link",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-text" }, [
      _c("div", [_vm._v("Change Icon")]),
      _c("div", { staticClass: "secondary-text small" }, [
        _vm._v("Upload a logo 200 X 200px, PNG or JPG formats.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }