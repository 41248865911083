const rolePermissionLevelsMap = {
  member: 0,
  admin: 1,
  owner: 2
};

const accessLevelPermissionMap = {
  viewer: 0,
  contributor: 1
};

const planPermissionMap = {
  free: 0,
  prototype: 1,
  basic: 1,
  pro: 2,
  team: 2
};

const getRolePermissionLevel = role => rolePermissionLevelsMap[role?.toLowerCase()] ?? -1;
const getAccessLevelPermission = accessLevel => accessLevelPermissionMap[accessLevel?.toLowerCase()] ?? -1;
const getPlanPermissionLevel = plan => planPermissionMap[plan?.toLowerCase()] ?? -1;

export const isSufficientRole = (allowed = '', given = '') => {
  const allowedPermission = getRolePermissionLevel(allowed);
  const givenPermission = getRolePermissionLevel(given);
  return allowedPermission <= givenPermission;
};

export const isSufficientAccessLevel = (allowed = '', given = '') => {
  const allowedPermission = getAccessLevelPermission(allowed);
  const givenPermission = getAccessLevelPermission(given);
  return allowedPermission <= givenPermission;
};

export const isSufficientPlan = (allowed = '', given = '') => {
  const allowedPermission = getPlanPermissionLevel(allowed);
  const givenPermission = getPlanPermissionLevel(given);
  return allowedPermission <= givenPermission;
};
