<template>
  <div :style="{ cursor: disabled ? 'not-allowed' : 'text' }" :class="{ focus }" class="flex items-center">
    <div
      :style="getStyles"
      ref="editable"
      contenteditable
      spellcheck="false"
      class="editable"
      :class="{ ellipsis: !focus, undo }"
      :data-placeholder="$attrs.placeholder"
      v-on="listeners"
      v-bind="$attrs"
      data-cy="ghost-input"
    />
    <div
      :style="{ display: (undo && focus) || permaUndo ? 'flex' : 'none' }"
      style="padding-left:10px"
      class="flex items-center justify-center"
    >
      <div @mousedown="$emit('undo')" class="flex items-center justify-center undo-icon">
        <svg-icon fill="currentColor" name="restore" :size="16"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      focus: false
    };
  },
  props: {
    value: {
      type: String,
      required: true
    },
    undo: {
      type: Boolean,
      default: false
    },
    permaUndo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.resetValue();
  },
  computed: {
    getStyles() {
      let s = {};
      if (this.disabled) {
        s['user-select'] = 'none';
        s['pointer-events'] = 'none';
        s['opacity'] = '0.5';
      }

      return s;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        keypress: this.onKeyPress,
        keyup: this.onKeyUp,
        focus: this.onFocus,
        blur: this.onBlur
      };
    }
  },
  methods: {
    triggerFocus() {
      const { editable } = this.$refs;
      if (editable) {
        editable.focus();
      }
    },

    resetValue(v) {
      const { editable } = this.$refs;
      editable.innerHTML = v || this.value;
    },
    onInput(e) {
      this.$emit('input', e.target.innerText);
    },
    onFocus(e) {
      this.focus = true;
      this.$emit('focus', e);
    },
    onBlur(e) {
      this.focus = false;
      this.$emit('blur', e);
    },
    onKeyUp(e) {
      this.$emit('keyup', e);
    },
    onKeyPress(event) {
      const { key, target } = event;
      this.$emit('keypress', event);
      if (key === 'Enter') {
        this.onKeyUp(event);
        event.preventDefault();
        target.blur();
        return;
      }
    }
  },
  watch: {
    value(newValue) {
      const { editable } = this.$refs;
      if ((newValue && editable.innerHTML == '') || !newValue) this.resetValue();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.ellipsis {
  @include ellipsis;
}
.editable {
  outline: none;
  border: none;
  caret-color: var(--primary);
  width: auto;
  max-width: 100%;
  padding-right: 2px;
  overflow-x: hidden;

  &.undo {
    flex: 1;
  }
}
.editable:empty:before {
  content: attr(data-placeholder);
  color: var(--secondary-text);
  cursor: text;
}
.undo-icon {
  cursor: pointer;
  color: white;
  padding-left: 5px;
  &:hover {
    color: var(--primary);
  }
}
</style>
