var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.proUser
    ? _c("div", { staticClass: "pricing" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c(
              "span",
              {
                staticClass: "close",
                on: {
                  click: function($event) {
                    return _vm.showAndHidePricing()
                  }
                }
              },
              [_c("svg-icon", { attrs: { name: "close" } })],
              1
            ),
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/svg/anima-logo-text.svg") }
            }),
            _c(
              "an-button",
              { staticClass: "button", on: { click: _vm.upgrade } },
              [_vm._v("Upgrade to Pro plan")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }