var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "select-container",
      attrs: { tabindex: "-1" },
      on: { focusout: _vm.close }
    },
    [
      _c(
        "div",
        {
          staticClass: "select",
          class: { empty: _vm.isEmpty(_vm.value) },
          style: { width: _vm.isEmpty(_vm.value) ? _vm.width : "auto" },
          on: { click: _vm.togglePopover }
        },
        [
          _vm._v(" " + _vm._s(_vm.displayLabel) + " "),
          _vm.value.emoji
            ? _c(
                "span",
                { staticClass: "emoji" },
                [_c("an-emoji", { attrs: { name: _vm.value.emoji } })],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "Popover",
        {
          style: { width: _vm.width },
          attrs: {
            isOpen: _vm.popoverOpen,
            popperClass: "popover-customize",
            theme: _vm.theme,
            position: "left"
          },
          on: { close: _vm.close }
        },
        [
          _vm.multiSelect
            ? _c("div", { staticClass: "multi" }, [
                _vm._v("Select one or more..")
              ])
            : _vm._e(),
          _vm._l(_vm.items, function(item) {
            return _c(
              "div",
              { key: item.label, staticClass: "option" },
              [
                _c(
                  "an-link",
                  {
                    attrs: {
                      theme: _vm.theme,
                      variant: _vm.isMarked(item) ? "primary" : "secondary",
                      "data-cy": item.datacy,
                      primaryHover: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClick(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }