<template>
  <span>
    {{ text }}&nbsp;
    <an-link href="https://status.animaapp.com" target="_blank" variant="primary">See status</an-link>
  </span>
</template>

<script>
export default {
  name: 'app-status-banner',
  data: () => ({
    text: "Oh noes! It seems like we have an outage. 😰 We're on it!"
  })
};
</script>
