var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "z-index": "1" } }, [
    _c(
      "div",
      { ref: "tabs", staticClass: "tabs-container" },
      _vm._l(_vm.items, function(item, idx) {
        return _c(
          "div",
          {
            key: _vm.tabId(idx),
            ref: _vm.tabId(idx),
            refInFor: true,
            class: _vm.classes(item),
            attrs: { "data-content": item.label, "data-cy": item.datacy },
            on: {
              click: function($event) {
                return _vm.select(item, idx)
              }
            }
          },
          [_vm._v(" " + _vm._s(item.label) + " ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "border", style: _vm.borderStyle })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }