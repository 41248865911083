var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.notFound
      ? _c(
          "div",
          [
            _c("PageNotFound", {
              attrs: { showTopBar: false, resource: "team" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "team-header-container" },
            [_c("Header", { on: { "click-new-project": _vm.newProject } })],
            1
          ),
          _c("div", { staticClass: "navigation" }, [
            _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("Tabs", {
                  attrs: {
                    activeTabLabel: _vm.activeTabLabel,
                    items: _vm.tabs
                  },
                  on: { change: _vm.onTabChange }
                }),
                _vm.isDesktop && _vm.showSearch
                  ? _c(
                      "div",
                      { staticClass: "search" },
                      [
                        _c("SearchInput", {
                          on: { "toggle-open": _vm.toggleSearchOpen },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.isDesktop && _vm.showSort
              ? _c(
                  "div",
                  { staticClass: "sorting-section" },
                  [
                    _c(
                      "div",
                      {
                        ref: "grid",
                        staticClass: "clickable-icon",
                        on: {
                          click: function($event) {
                            return _vm.onChangeLayoutClick("grid")
                          }
                        }
                      },
                      [_c("svg-icon", { attrs: { name: "grid", size: 30 } })],
                      1
                    ),
                    _c(
                      "div",
                      {
                        ref: "horizontal",
                        staticClass: "clickable-icon",
                        on: {
                          click: function($event) {
                            return _vm.onChangeLayoutClick("horizontal-list")
                          }
                        }
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "horizontal-list", size: 30 }
                        })
                      ],
                      1
                    ),
                    _c(
                      "PopoverMenu",
                      {
                        attrs: {
                          items: _vm.sortingOptions,
                          position: "right",
                          selectedLabel: _vm.sortByLabel
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sort-select",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.sortByLabel) + " "),
                            _c("svg-icon", {
                              attrs: { name: "select-arrow-down", size: 24 }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "sort-mobile" },
            [
              _vm.showSearch
                ? _c(
                    "div",
                    { staticClass: "search" },
                    [
                      _c("SearchInput", {
                        on: { "toggle-open": _vm.toggleSearchOpen },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSort && !_vm.searchBarOpen
                ? _c(
                    "PopoverMenu",
                    {
                      attrs: {
                        items: _vm.sortingOptions,
                        position: "right",
                        selectedLabel: _vm.sortByLabel
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sort-select",
                          attrs: { slot: "reference" },
                          slot: "reference"
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.sortByLabel) + " "),
                          _c("svg-icon", {
                            attrs: { name: "select-arrow-down", size: 24 }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "transition",
                { attrs: { name: "fadeIn", mode: "out-in" } },
                [
                  _c("router-view", {
                    key: _vm.activeTabLabel,
                    attrs: {
                      shouldUpdate: _vm.shouldUpdateProjects,
                      searchQuery: _vm.searchQuery,
                      layout: _vm.layout
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }