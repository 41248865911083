<template>
  <div class="container">
    <div class="title">Invite people to collaborate</div>
    <div class="subtitle">
      Add members &amp; guests to collaborate on this project
    </div>
    <div class="navigation">
      <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
    </div>
    <div class="body">
      <component :is="component" v-bind="props" @close="$emit('close')" />
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Layout/Tabs/Tabs';
import TeamMembersForm from '@/components/Team/Settings/MembersForm';
import GuestsForm from '@/components/Project/Settings/Guests';
import PublicLink from '@/components/Project/Invite/PublicLink';

export default {
  name: 'project-invite',
  data() {
    return {
      tabs: [
        { label: 'Invite to team', component: TeamMembersForm },
        { label: 'Invite to project', component: GuestsForm },
        { label: 'Prototype link', component: PublicLink }
      ],
      activeTabLabel: 'Invite to team',
      component: TeamMembersForm
    };
  },
  props: {
    defaultTabLabel: {
      type: String,
      default: 'Invite to team',
      validator: value => ['Invite to team', 'Invite to project', 'Prototype link'].includes(value)
    },
    eventSource: {
      type: String
    }
  },
  mounted() {
    const relevantTab = this.tabs.find(tab => tab.label === this.defaultTabLabel);
    this.onTabChange(relevantTab);
  },
  components: {
    Tabs
  },
  computed: {
    props() {
      return { inviteLayout: true, showInviteWithLink: true, eventSource: this.eventSource };
    }
  },
  methods: {
    onTabChange(tab) {
      this.activeTabLabel = tab.label;
      this.component = tab.component;
      this.$trackEvent('project-invite.tab.click', { label: tab.label });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_navigationBar.scss';
.subtitle {
  width: 520px;
}
.navigation {
  justify-content: center;
  width: 100%;
  margin: 40px 0 10px;
}
</style>
