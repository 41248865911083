<template>
  <div class="content">
    <p class="secondary title">Import options</p>
    <div :class="{ 'disable-events': isWorking }">
      <div :class="['root-selection', { marked: isImportAll }]" @click="importAll" :disabled="isWorking">
        <img :src="require('@/assets/svg/sketch.svg')" width="50" />
        <div class="details">
          <h3>Import all artboards</h3>
          <p class="secondary">{{ getArtboardCountText(totalArtboardsNumber) }}</p>
        </div>
      </div>
      <div class="root-selection" :class="isImportSome && 'marked'" @click="importSome">
        <img :src="require('@/assets/svg/sketch-multi.svg')" width="50" />
        <div class="details">
          <h3>Select artboards</h3>
        </div>
      </div>
      <div v-show="isImportSome" class="pages-selection">
        <div v-for="page of pages" :key="page.id" class="page-section">
          <div class="collapsable">
            <div class="category" @click="toggleCollapsePage(page.id)">
              <div>
                <h4 class="page-title">
                  {{ page.name }}
                </h4>
                <p class="secondary">{{ getArtboardCountText(artboardsNumByPage(page.id)) }}</p>
              </div>
              <div class="toggle-collapse" :class="isPageArtboardsShown(page.id) ? 'down' : 'up'" />
            </div>
            <div v-show="isPageArtboardsShown(page.id)">
              <ul>
                <li v-for="artboard of artboardsByPage(page.id)" v-bind:key="artboard.id" class="artboard-checkbox">
                  <checkbox
                    v-on:input="selectArtboard(page.id, artboard.id)"
                    :value="isArtboardSelected(page.id, artboard.id)"
                  >
                    <span class="secondary">{{ artboard.name }}</span>
                  </checkbox>
                </li>
              </ul>
            </div>
          </div>
          <file-upload-divider class="divider" />
        </div>
      </div>
    </div>

    <div v-if="hasSelectedArtboards" class="actions">
      <file-upload-divider class="divider" />
      <an-button variant="primary" @click="submit" :disabled="isWorking">Import Sketch file</an-button>
      <an-link @click="close" v-if="!isWorking">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import SketchFile from '@/plugins/SketchFile';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import FileUploadDivider from './FileUploadDivider.vue';

export default {
  name: 'SketchSelectArtboards',
  emits: ['submit'],
  props: {
    sketchFile: {
      type: SketchFile,
      required: true
    }
  },
  data() {
    const selectedByArtboards = {};
    const pageArtboardsShown = {};
    for (const pageId in this.sketchFile.getPages()) {
      selectedByArtboards[pageId] = {};
      pageArtboardsShown[pageId] = false;
      for (const artboardId in this.sketchFile.getArtboards(pageId)) {
        selectedByArtboards[pageId][artboardId] = true;
      }
    }
    return {
      isWorking: false,
      selectedByArtboards,
      importBy: 'all',
      pageArtboardsShown
    };
  },
  components: {
    Checkbox,
    FileUploadDivider
  },
  computed: {
    isPageArtboardsShown() {
      return pageId => this.pageArtboardsShown[pageId];
    },
    isImportAll() {
      return this.importBy === 'all';
    },
    isImportSome() {
      return this.importBy === 'some';
    },
    hasSelectedArtboards() {
      const hasSelectedArtboard = pageId => Object.values(this.selectedByArtboards[pageId]).some(selected => selected);
      return Object.keys(this.pages).some(hasSelectedArtboard);
    },
    totalArtboardsNumber() {
      return this.sketchFile.getTotalArtboardsNumber();
    },
    artboardsNumByPage() {
      return pageId => Object.keys(this.sketchFile.getArtboards(pageId)).length;
    },
    pages() {
      return this.sketchFile.getPages();
    },
    artboardsByPage() {
      return pageId => this.sketchFile.getArtboards(pageId);
    },
    isAllSelected() {
      return Object.keys(this.pages).every(id => this.isAllPageSelected(id));
    },
    isAllPageSelected() {
      return pageId => Object.values(this.selectedByArtboards[pageId]).every(selected => selected);
    },
    isArtboardSelected() {
      return (pageId, artboardId) => !!this.selectedByArtboards[pageId][artboardId];
    }
  },
  methods: {
    getArtboardCountText(artboardsCount) {
      let word = artboardsCount == 1 ? 'artboard' : 'artboards';
      return `${artboardsCount} ${word} found`;
    },
    importSome() {
      this.importBy = 'some';
      this.applyOnAll(false);
    },
    importAll() {
      this.importBy = 'all';
      this.applyOnAll(true);
      this.submit();
    },
    toggleCollapsePage(pageId) {
      this.pageArtboardsShown[pageId] = !this.pageArtboardsShown[pageId];
    },
    submit() {
      this.isWorking = true;
      const importBy = this.importBy;
      this.importBy = null;

      this.$emit('submit', { selectedArtboardsByPages: this.selectedByArtboards, importBy });
    },
    close() {
      this.$emit('close');
    },
    applyOnAll(value) {
      for (let pageId of Object.keys(this.pages)) {
        for (let artboardId in this.artboardsByPage(pageId)) {
          this.selectedByArtboards[pageId][artboardId] = value;
        }
      }
    },
    selectAll() {
      const assignedValue = this.isAllSelected ? false : true;
      this.applyOnAll(assignedValue);
    },
    selectAllPage(pageId) {
      const assignedValue = this.isAllPageSelected(pageId) ? false : true;
      for (let artboardId of Object.keys(this.artboardsByPage(pageId))) {
        this.selectedByArtboards[pageId][artboardId] = assignedValue;
      }
    },
    selectArtboard(pageId, artboardId) {
      this.selectedByArtboards[pageId][artboardId] = !this.selectedByArtboards[pageId][artboardId];
    },
    async selectAllArtboards() {
      let res = {};
      const pages = this.sketchFile.getPages();
      for (const page in pages) {
        res[page] = {};
        const artboards = this.sketchFile.getArtboards(page);
        for (const artboard in artboards) {
          res[page][artboard] = true;
        }
      }
      await this.syncArtboards(res);
    }
  }
};
</script>

<style lang="scss" scoped>
.disable-events {
  pointer-events: none;
  opacity: 0.4;
}
.artboard-checkbox {
  padding: 15px 0;
  &:first-child {
    margin-top: 15px;
  }
  &:last-child {
    margin-bottom: 15px;
  }
}
.toggle-collapse {
  background-image: url('~@/assets/svg/small-arrow-down.svg');
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
  &.down {
    transform: rotate(180deg);
  }
}
.pages-selection {
  padding: 30px 90px;
  overflow-y: auto;
  max-height: 35vh;
}
.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h3,
h4 {
  @include secondary-title;
}
.root-selection {
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  padding: 30px 20px;
  img {
    margin-right: 25px;
  }

  &.marked {
    background: var(--light-container-background);
  }
}
.content {
  width: 50%;
  max-width: 750px;
  text-align: left;
}
.secondary {
  opacity: 0.4;
}
.title {
  margin-bottom: 20px;
}
.category {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-section:last-child .divider {
  display: none;
}
.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  .divider {
    margin-bottom: 40px;
  }
  & > * {
    margin-bottom: 20px;
  }
}
</style>
