var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showTopBar
      ? _c(
          "div",
          { staticClass: "not-found-top-bar" },
          [
            _vm.isMobile ? _c("top-bar") : _vm._e(),
            _vm.isDesktop
              ? _c("an-logo", { attrs: { light: _vm.$theme === "light" } })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c("div", { class: ["quirky", _vm.$theme] }, [_vm._v("Oops ..")]),
    _c("div", { staticClass: "title" }, [
      _vm._v(
        "The " + _vm._s(_vm.resource) + " you were looking for does not exist."
      )
    ]),
    _c(
      "div",
      { staticClass: "cta" },
      [
        _vm._t("cta"),
        !_vm.$slots.cta
          ? _c(
              "an-button",
              {
                on: {
                  click: function($event) {
                    _vm.$router.replace({ name: "root" }, function() {})
                  }
                }
              },
              [_vm._v(" Back home ")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }