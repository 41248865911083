<template>
  <div class="w-full h-full cont">
    <div class="title flex items-center justify-center">Export options</div>
    <div class="flex flex-col items-center jusitfy-center w-full">
      <div
        @click="handleOptionChange(op)"
        :class="{ selected: selectedOption == op.name }"
        class="option flex flex-col"
        v-for="op in options"
        :key="op.name"
      >
        <an-radio full :spacingX="10" type="dot" :label="op.name" :value="selectedOption" data-cy="export-code-radio"
          ><div class="flex items-center" style="font-size:12px" data-cy="export-label">
            {{ op.label }}
            <span v-if="['zip', 'codesandbox'].includes(op.name)" class="badge pro">Pro</span>
          </div></an-radio
        >
        <p class="desc">
          {{ op.description }}
        </p>
      </div>
      <div class="sep"></div>
      <div class="settings">
        <div @click="isCodeSettingsExpanded = !isCodeSettingsExpanded" class="toggle flex items-center">
          <svg-icon
            style="margin-right:5px"
            :size="17"
            fill="currentColor"
            :name="isCodeSettingsExpanded ? 'small-arrow-down' : 'small-arrow-right'"
          ></svg-icon>
          <span class="label">Code settings</span>
        </div>
        <TransitionHeight :watch="selectedOption">
          <div v-show="isCodeSettingsExpanded" class="content">
            <div style="justify-content:space-between;padding: 10px 0;flex-wrap:wrap" class="flex w-full">
              <!-- FRAMEWORK -->
              <div class="flex flex-col">
                <div class="col-title">Code Framework</div>
                <an-radio
                  full
                  :spacingX="6"
                  :spacingY="4"
                  type="dot"
                  label="html"
                  :value="codeDownloadPrefs.framework"
                  @change="handleCodeDownloadPrefsFrameworkChange"
                  ><span style="font-size:12px">Pure HTML</span></an-radio
                >

                <an-radio
                  full
                  :spacingX="6"
                  :spacingY="4"
                  type="dot"
                  label="react"
                  :value="codeDownloadPrefs.framework"
                  @change="handleCodeDownloadPrefsFrameworkChange"
                >
                  <span style="font-size:12px">React</span>
                </an-radio>
                <an-radio
                  full
                  :spacingX="6"
                  :spacingY="8"
                  type="dot"
                  label="vue"
                  :value="codeDownloadPrefs.framework"
                  @change="handleCodeDownloadPrefsFrameworkChange"
                >
                  <div class="flex items-center">
                    <span style="font-size:12px">Vue</span>
                  </div>
                </an-radio>
              </div>

              <!-- ZIP/HTML -->
              <template v-if="selectedOption == 'zip' && codeDownloadPrefs.framework == 'html'">
                <div style="margin-left:auto" class="flex flex-col">
                  <div class="col-title">Layout</div>
                  <an-radio
                    full
                    :spacingX="6"
                    :spacingY="15"
                    type="dot"
                    label="absolute"
                    :value="codeDownloadPrefs.layout"
                    @change="handleCodeDownloadLayoutPrefsChange"
                  >
                    <span style="font-size:12px">Absolute Position</span>
                    <span class="radio-sub-title">Great for prototypes</span>
                  </an-radio>
                  <an-radio
                    full
                    :spacingX="6"
                    :spacingY="15"
                    type="dot"
                    label="auto_flexbox"
                    :value="codeDownloadPrefs.layout"
                    @change="handleCodeDownloadLayoutPrefsChange"
                  >
                    <div class="flex flex-col">
                      <span style="font-size:12px">Auto Flex</span>
                      <span class="radio-sub-title">Great for Developers</span>
                    </div></an-radio
                  >
                </div>
              </template>

              <!-- INSPECT/HTML -->
              <template v-else-if="codeDownloadPrefs.framework == 'html'">
                <CodePrefCol
                  :value="codegenHTMLLayout"
                  @change="handleHTMLLayoutChange"
                  title="Layout"
                  :options="htmlLayoutOptions"
                />
              </template>

              <!-- REACT -->
              <template v-if="codeDownloadPrefs.framework == 'react'">
                <CodePrefCol
                  :value="codegenReactSyntax"
                  @change="handleReactSyntaxChange"
                  title="Syntax"
                  :options="reactSyntaxOptions"
                />
                <CodePrefCol
                  :value="codegenReactStyle"
                  @change="handleReactStyleChange"
                  title="Styling"
                  :options="reactStyleOptions"
                />
              </template>

              <!-- VUE -->
              <template v-if="codeDownloadPrefs.framework === 'vue'">
                <CodePrefCol
                  :value="codegenVueStyle"
                  @change="handleVueStyleChange"
                  title="Styling"
                  :options="vueStyleOptions"
                />
              </template>
            </div>
          </div>
        </TransitionHeight>
      </div>
      <div class="flex flex-col">
        <an-button
          :isWorking="isExportLoading"
          @click="exportCodeTrigger"
          style="margin-bottom:10px"
          data-cy="export-code-action-button"
        >
          {{ exportCTA }}
        </an-button>

        <an-button @click="$emit('close')" class="justify-center" style="color:#fff" variant="empty">Cancel</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus, openModal, toastError, toastSuccess } from '@/services/bus';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import TransitionHeight from './TransitionHeight.vue';
import downloadCodeMixin from '@/components/OmniView/downloadCodeMixin';
import downloadCodeMixinLegacy from '@/components/OmniView/downloadCodeMixinLegacy';
import { TeamMixin } from '@/mixins';
import { HTML_LAYOUT_CHANGE, REACT_STYLE_CHANGE, REACT_SYNTAX_CHANGE, VUE_STYLE_CHANGE } from './CodePrefsMixin';
import { UPDATE_DEV_PREFS } from '@/utils/events/omniviewEvents';
import CodePrefCol from '@/components/OmniView/Panels/CodePrefCol';

export default {
  mixins: [downloadCodeMixin, downloadCodeMixinLegacy, TeamMixin],
  components: {
    TransitionHeight,
    CodePrefCol
  },
  name: 'export-code',
  props: {
    upgradePlanPageParams: {
      type: Object
    }
  },
  data() {
    return {
      selectedOption: 'zip',
      baseOptions: [
        {
          name: 'zip',
          label: 'Zip File',
          description: 'Best for website hosting without inspecting or changing the code. No developers needed.'
        },

        {
          name: 'codesandbox',
          label: 'CodeSandbox',
          description: 'Best for developers that want to run the code in a self-contained independent environement.'
        }
      ],
      isCodeSettingsExpanded: true
    };
  },
  computed: {
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', ['htmlLayoutOptions', 'reactSyntaxOptions', 'reactStyleOptions', 'vueStyleOptions']),

    ...mapGetters({
      codegenLang: 'omniview/codegenLang',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      isExportAllowed: 'omniview/isExportAllowed',
      hasPermissions: 'teamMemberships/hasPermissions',
      isExportingPlaygroundCode: 'omniview/isExportingPlaygroundCode',
      activeMode: 'omniview/activeMode',
      modes: 'omniview/modes',
      styleType: 'omniview/styleType',
      isActiveExperiment: 'experiments/isActive',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      codegenReactStyle: 'omniview/codegenReactStyle',
      codegenVueStyle: 'omniview/codegenVueStyle',
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      isPro: 'omniview/isPro'
    }),
    options() {
      let options = [...this.baseOptions];

      if (this.activeMode.name !== 'C') {
        options.splice(1, 0, {
          name: 'inspect',
          label: 'Inspect Code',
          description: 'Best for developers that want to use code as a starting point instead of writing from scratch.'
        });
      }
      return options;
    },
    exportCTA() {
      let cta = 'Export';
      switch (this.selectedOption) {
        case 'zip':
          cta = this.isExportAllowed ? 'Export' : 'Upgrade';
          break;
        case 'inspect':
          cta = 'Inspect';
          // TODO
          break;
        case 'codesandbox':
          cta = 'Export';
          break;

        default:
          cta = 'Export';
          break;
      }

      return cta;
    },
    isExportLoading() {
      let flag = false;
      switch (this.selectedOption) {
        case 'zip':
          flag = this.exportCodeLoading;
          break;
        case 'inspect':
          flag = false;
          // TODO
          break;
        case 'codesandbox':
          flag = this.isExportingPlaygroundCode;
          break;

        default:
          break;
      }

      return flag;
    }
  },
  methods: {
    ...mapMutations({
      setCodeDownloadPrefs: 'omniview/setCodeDownloadPrefs',
      setCodegenHTMLLayout: 'omniview/setCodegenHTMLLayout',
      setCodegenReactSyntax: 'omniview/setCodegenReactSyntax',
      setCodegenLang: 'omniview/setCodegenLang'
    }),
    ...mapActions({
      handleModeChange: 'omniview/handleModeChange'
    }),
    handleReactSyntaxChange(value) {
      EventBus.$emit(REACT_SYNTAX_CHANGE, {
        value,
        lang: this.codeDownloadPrefs.framework
      });
      EventBus.$emit(UPDATE_DEV_PREFS, { react_syntax: value });
    },
    handleHTMLLayoutChange(value) {
      EventBus.$emit(HTML_LAYOUT_CHANGE, {
        value,
        lang: this.codeDownloadPrefs.framework
      });
      EventBus.$emit(UPDATE_DEV_PREFS, { html_layout: value });
    },
    handleReactStyleChange(value) {
      EventBus.$emit(REACT_STYLE_CHANGE, {
        value,
        lang: this.codeDownloadPrefs.framework
      });
      EventBus.$emit(UPDATE_DEV_PREFS, { react_style: value });
    },
    handleVueStyleChange(value) {
      EventBus.$emit(VUE_STYLE_CHANGE, {
        value,
        lang: this.codeDownloadPrefs.framework
      });
      EventBus.$emit(UPDATE_DEV_PREFS, { vue_style: value });
    },
    handleOptionChange({ name }) {
      this.selectedOption = name;
      this.$trackEvent('omniview.export-options.changed', { option: name });
    },
    handleCodeDownloadLayoutPrefsChange(value) {
      const { layout } = this.codeDownloadPrefs;
      if (value == layout) return;
      const newCodePrefs = { ...this.codeDownloadPrefs, layout: value };
      this.handleHTMLLayoutChange(value);
      this.setCodeDownloadPrefs(newCodePrefs);
      localStorage.setItem('codeDownloadPrefs', JSON.stringify(newCodePrefs));
    },
    handleCodeDownloadPrefsFrameworkChange(value) {
      const { framework } = this.codeDownloadPrefs;
      if (value == framework) return;
      const newCodePrefs = { ...this.codeDownloadPrefs, framework: value };
      this.setCodeDownloadPrefs(newCodePrefs);
      localStorage.setItem('codeDownloadPrefs', JSON.stringify(newCodePrefs));
    },
    exportCodeTrigger() {
      const { framework, layout } = this.codeDownloadPrefs;
      let payload = {};
      switch (this.selectedOption) {
        case 'zip':
          this.exportZip();
          payload = { framework, layout };
          break;
        case 'inspect':
          this.handleModeChange({
            mode: this.modes[2]
          });
          this.$emit('close');
          localStorage.setItem('codeFramework', framework);
          this.setCodegenLang(framework);
          if (framework == 'html') {
            let l = this.exportPrefToCodegenPref(layout);
            this.setCodegenHTMLLayout(l);
            localStorage.setItem('htmlLayout', l);
            payload = { layout };
          } else if (framework == 'react') {
            payload = { style: this.codegenReactStyle, syntax: this.codegenReactSyntax };
          }
          break;
        case 'codesandbox':
          this.exportCodesandbox();
          if (framework == 'html') {
            payload = { layout };
          } else if (framework == 'react') {
            payload = { style: this.codegenReactStyle, syntax: this.codegenReactSyntax };
          }
          break;

        default:
          break;
      }
      this.$trackEvent('omniview.export-code.started', {
        framework,
        'style-type': this.styleType,
        type: framework,
        ...payload
      });
    },

    exportCodesandbox() {
      if (this.isExportAllowed) {
        let isCodeSandbox = this.codeDownloadPrefs.framework != 'html';
        EventBus.$emit('generate-code', {
          isCodeSandbox,
          isCodePen: !isCodeSandbox,
          forceGenerate: true,
          fullModel: true,
          framework: this.codeDownloadPrefs.framework
        });
      } else {
        if (this.isPro) {
          openModal({ name: 'team-partial-access', mode: 'dark' });
        } else {
          this.openUpgradeDownloadCodeModal(this.upgradePlanPageParams);
        }
      }
    },
    // careful changing this fn name it might conflict with the mixins functions
    async exportZip() {
      this.$trackEvent('omniview-download-code-popup-clicked', {
        mode: this.isExportAllowed ? 'download' : 'upgrade'
      });
      const { teamSlug } = this.$route.params;
      if (this.isExportAllowed) {
        const { codeDownloadPrefs, codegenReactSyntax, codegenReactStyle, codegenVueStyle } = this;
        const settings = this._getCodegenFrameworkSettings({
          framework: codeDownloadPrefs.framework,
          codegenHTMLLayout: codeDownloadPrefs.layout,
          codegenReactSyntax,
          codegenReactStyle,
          codegenVueStyle
        });

        try {
          const status = await this.handleDownloadCodePackage(settings);
          if (!status || ['outdated', 'failed'].includes(status)) {
            this.$emit('close');
            toastSuccess('We’re creating your code zip file now. We’ll email you once it’s ready.');
          }
        } catch (err) {
          toastError('Something went wrong here, please try again.');
        }
      } else {
        const isPro = this.hasPermissions({ teamSlug, plan: 'pro' });
        if (isPro) {
          openModal({ name: 'team-partial-access', mode: 'dark' });
        } else {
          this.openUpgradeDownloadCodeModal(this.upgradePlanPageParams);
        }
      }
    },
    exportPrefToCodegenPref(layout) {
      let map = {
        absolute: 'absolute',
        auto_flexbox: 'flexbox'
      };
      return map[layout];
    }
  }
};
</script>

<style lang="scss" scoped>
.cont {
  padding: 40px 0;
}

.title {
  font-family: Roslindale, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 32px;
}

.badge {
  border-radius: 100px;
  padding: 0 14px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  &.pro {
    background: #009379;
    margin-left: 10px;
  }
}
.option {
  padding: 15px 20px;
  width: 360px;
  max-width: 360px;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 4px;
  &.selected {
    border-color: var(--primary);
  }
  &:hover {
    cursor: pointer;
    border-color: var(--primary);
  }
  .desc {
    margin-top: 5px;
    font-size: 12px;
    line-height: 20px;
  }
  margin-bottom: 20px;
}
.sep {
  height: 1px;
  background: #fff;
  width: 360px;
  margin-bottom: 20px;
}

.settings {
  margin-bottom: 40px;
  width: 360px;
  .toggle {
    cursor: pointer;
    color: white;
    .label {
      font-size: 12px;
    }
  }
}

.content {
  .col-title {
    font-size: 12px;
    margin-bottom: 0px;
    opacity: 0.4;
  }

  .radio-sub-title {
    font-size: 12px;
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: 5px;
    white-space: nowrap;
  }
}

.badge {
  border-radius: 100px;
  padding: 0 6px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  &.soon {
    background: #009379;
    margin-left: 5px;
  }
}
</style>
