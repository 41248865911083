import { createStoreModule } from '../utils';

const getDefaultTeamSlug = async ctx => {
  let user = {};
  try {
    // shouldn't be slow as the user object is already cached.
    user = await ctx.dispatch('fetchOne', { id: 'me' });
    if (!user.default_team_slug) {
      let teamSlug = null;
      let teams = await ctx.dispatch(
        'teamMemberships/fetchAllUserMemberships',
        { id: 'me', skipCache: true },
        { root: true }
      );
      if (!teams.results.length) {
        const team = await ctx.dispatch('teams/createDefaultTeam', 'My Team', { root: true });
        teamSlug = team.slug;
      } else {
        teamSlug = teams.results[0].team_slug;
      }
      ctx.dispatch('update', { id: 'me', payload: { default_team_slug: teamSlug } });
      user.default_team_slug = teamSlug;
    }
    return user.default_team_slug;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export default createStoreModule({
  name: 'user',
  modelName: 'users',
  crud: true,
  endpoint: '/v2/users',
  actions: {
    getDefaultTeamSlug
  },
  getters: {
    firstName: s => {
      const { currentItem: user } = s;
      return user?.first_name || user?.name?.trim().split(' ')[0];
    },
    userEmail: s => {
      const { currentItem: user } = s;
      return user?.email;
    },
    isUserDeveloper: s => s.currentItem?.role === 'developer'
  }
});
