var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interface" },
    [
      _vm.propsLoading
        ? _c(
            "div",
            {
              staticClass: "flex items-center justify-center w-full h-full",
              staticStyle: { color: "white" }
            },
            [
              _c("svg-icon", {
                staticClass: "icon-spin",
                attrs: { fill: "currentColor", name: "spin", size: 30 }
              })
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("span", {
                  staticClass: "s",
                  domProps: { textContent: _vm._s("<") }
                }),
                _c("GhostInput", {
                  key: _vm.renderId,
                  staticClass: "interfaceInput c-name",
                  on: {
                    blur: function($event) {
                      return _vm.$emit("update-component-name", {
                        name: _vm.componentNameCopy
                      })
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return $event.target.blur()
                    }
                  },
                  model: {
                    value: _vm.componentNameCopy,
                    callback: function($$v) {
                      _vm.componentNameCopy = $$v
                    },
                    expression: "componentNameCopy"
                  }
                })
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "prop-list flex flex-col space-y-6px" },
              _vm._l(_vm.regularProps, function(prop) {
                return _c(
                  "li",
                  {
                    key: prop.prop_fingerprint,
                    staticClass: "flex items-center prop-item space-x-8px",
                    class: {
                      highlight: _vm.isPropNodeSelected(prop, "regular")
                    },
                    on: {
                      mouseover: function($event) {
                        return _vm.handlePropMouseOver(prop)
                      },
                      mouseout: _vm.handlePropMouseOut
                    }
                  },
                  [
                    _c("an-checkbox", {
                      attrs: { noMargin: "", variant: "dark" },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update-component-prop-enable", {
                            prop: prop,
                            type: "regular"
                          })
                        }
                      },
                      model: {
                        value: prop["is_enable"],
                        callback: function($$v) {
                          _vm.$set(prop, "is_enable", $$v)
                        },
                        expression: "prop['is_enable']"
                      }
                    }),
                    _c("GhostInput", {
                      key: _vm.renderId,
                      staticClass: "interfaceInput prop",
                      on: {
                        blur: function($event) {
                          return _vm.$emit("update-component-prop-name", {
                            prop: prop,
                            type: "regular"
                          })
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return $event.target.blur()
                        }
                      },
                      model: {
                        value: prop.name,
                        callback: function($$v) {
                          _vm.$set(prop, "name", $$v)
                        },
                        expression: "prop.name"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "truncate",
                        staticStyle: { color: "white" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getPropValueDisplay(prop)) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "ul",
              {
                staticClass: "flex flex-col space-y-6px",
                staticStyle: { "padding-left": "15px", "margin-top": "10px" }
              },
              _vm._l(_vm.nestedProps, function(prop) {
                return _c(
                  "li",
                  {
                    key: prop.prop_fingerprint,
                    staticClass: "flex flex-col space-y-6px"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center space-x-8px nested-row",
                        class: {
                          highlight: _vm.isPropNodeSelected(prop, "nested")
                        },
                        staticStyle: { color: "white", padding: "0 10px" },
                        on: {
                          mouseover: function($event) {
                            return _vm.handlePropMouseOver(prop)
                          },
                          mouseout: _vm.handlePropMouseOut
                        }
                      },
                      [
                        _c("an-checkbox", {
                          attrs: { noMargin: "", variant: "dark" },
                          on: {
                            input: function($event) {
                              return _vm.$emit("update-component-prop-enable", {
                                prop: prop,
                                type: "nested"
                              })
                            }
                          },
                          model: {
                            value: prop["is_enable"],
                            callback: function($$v) {
                              _vm.$set(prop, "is_enable", $$v)
                            },
                            expression: "prop['is_enable']"
                          }
                        }),
                        _c("GhostInput", {
                          key: _vm.renderId,
                          ref: "nestedInputRefs",
                          refInFor: true,
                          staticClass: "interfaceInput prop",
                          on: {
                            blur: function($event) {
                              return _vm.$emit("update-component-prop-name", {
                                prop: prop,
                                type: "nested"
                              })
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return $event.target.blur()
                            }
                          },
                          model: {
                            value: prop.name,
                            callback: function($$v) {
                              _vm.$set(prop, "name", $$v)
                            },
                            expression: "prop.name"
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              prop["is_enable"] && prop.value
                                ? "= {"
                                : "{ ... }"
                            )
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center nested-row-actions",
                            staticStyle: { "margin-left": "auto" }
                          },
                          [
                            prop["is_enable"] && prop.value
                              ? _c(
                                  "an-button",
                                  {
                                    style: {
                                      "--height": "22px",
                                      "--padding": "0 15px"
                                    },
                                    attrs: { size: "sm", variant: "secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleNestedLayerClick(prop)
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    prop["is_enable"] && prop.value
                      ? _c(
                          "div",
                          {
                            staticClass: "truncate flex flex-col space-y-6px",
                            staticStyle: { color: "white" }
                          },
                          [
                            _vm._l(prop.value, function(prop_value, prop_name) {
                              return _c(
                                "ul",
                                {
                                  key: prop_name,
                                  staticClass: "flex flex-col"
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "flex items-center prop-item-nested space-x-8px",
                                      staticStyle: {
                                        color: "white",
                                        opacity: "0.7"
                                      }
                                    },
                                    [
                                      _c("span", [_vm._v(_vm._s(prop_name))]),
                                      _c("div", { staticClass: "truncate" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "= " +
                                                (typeof prop_value ===
                                                  "object" &&
                                                prop_value !== null
                                                  ? "{ ... }"
                                                  : '"' + prop_value + '"')
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }),
                            _c(
                              "span",
                              { staticStyle: { "padding-left": "23px" } },
                              [_vm._v(_vm._s("}"))]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _c("div", [
              _c("span", {
                staticClass: "s",
                domProps: { textContent: _vm._s("/>") }
              })
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }