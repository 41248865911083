var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-notifications" }, [
      _c("div", { staticClass: "illustration" }, [
        _c("img", {
          attrs: { src: require("@/assets/illustrations/delivery-owl.svg") }
        })
      ]),
      _c("div", { staticClass: "empty-notifications-title" }, [
        _vm._v("You’re all caught up!")
      ]),
      _c("div", { staticClass: "empty-notifications-content" }, [
        _vm._v("Might as well take a break and check back later")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }