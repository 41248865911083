<template>
  <div class="pairing-container">
    <div class="container">
      <LoadingScreen v-if="loading" />
      <div v-else>
        <div class="illustration">
          <svg-icon name="circle-check" :size="84" />
        </div>
        <div class="title">You’re all set!</div>
        <div class="subtitle">
          Anima successfully added an integration with Figma API.
          <an-link class="link" variant="primary" @click="handleOpen">Go back to {{ this.getPlatformName() }}</an-link>
        </div>
        <div class="footer">
          <span>Not {{ email }}?&nbsp;</span>
          <an-link @click="switchAccount" variant="primary">Log in</an-link>
          <span>&nbsp; with another account</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import auth from '@/auth';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      email: '',
      loading: false
    };
  },
  components: {
    LoadingScreen
  },
  mounted() {
    this.onMounted();
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      generateToken: 'figmaIntegration/createToken'
    }),
    async onMounted() {
      this.loading = true;
      await this.performPairing();
      await this.fetchUserData();
      this.loading = false;
    },
    async fetchUserData() {
      const { email } = await this.getUser({ id: 'me' });
      this.email = email;
    },
    handleOpen() {
      this.$trackEvent('integrated.open-platform-link.click', { platform: this.getPlatformName() });
      if (this.$route.query.platform === 'figma') {
        window.location.replace('figma://animaapp');
      } else {
        this.$router.push({ name: 'root' });
      }
    },
    getPlatformName() {
      if (this.$route.query.platform === 'figma') {
        return 'Figma';
      } else {
        return 'Anima';
      }
    },
    async performPairing() {
      const code = this.$route.query?.code;
      const state = this.$route.query?.state;
      try {
        await this.generateToken({ params: { code, state } });
        this.$trackEvent('figma-file-import.grant-figma-access.success');
        const callbackUrl = localStorage.getItem('figmaCallbackUrl');
        if (callbackUrl) {
          localStorage.removeItem('figmaCallbackUrl');
          this.$router.replace(callbackUrl);
        }
      } catch (err) {
        const { message } = err.data;
        this.$trackEvent('figma-file-import.grant-figma-access.failure');
        toastError(`Oh no, something went wrong! ${message}`);
      }
    },
    switchAccount() {
      const { query } = this.$route;

      this.$trackEvent('integrated.switch-account-link.click');

      auth.logout();
      this.$router.replace({ name: 'login', query });
      window.location.reload();
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.pairing-container {
  height: 100vh;
  width: 100%;
}
.container {
  max-width: 100%;
}
.illustration {
  width: 430px;
  height: 140px;
  margin: 0 auto;
}
.title {
  font-size: 18px;
  line-height: 26px;
}
.footer {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
}
</style>
