<template>
  <div class="container">
    <div class="text">
      <div>
        {{ Math.floor(timer.progress * 100) + ' %' }}
      </div>
    </div>
    <div ref="loaderSvg">
      <svg-icon :width="206" :height="24" name="loader-line" />
    </div>
  </div>
</template>

<script>
import FakeProgress from '@/services/fake-progress';
import { colorPathChildren } from '@/utils/colors';

export default {
  props: {
    text: {
      type: String,
      default: null
    },
    theme: {
      type: String
    }
  },
  data() {
    return {
      timer: 0
    };
  },
  mounted() {
    if (this.theme === 'dark') {
      colorPathChildren(this.$refs.loaderSvg, 'var(--white)');
    }
    if (this.theme === 'light') {
      colorPathChildren(this.$refs.loaderSvg, 'var(--secondary)');
    }
    this.timer = new FakeProgress({
      timeConstant: 7000,
      autoStart: true
    });
  },
  beforeDestroy() {
    this.timer && this.timer.end();
  }
};
</script>

<style lang="scss" scoped>
// FULL SCREEN LOADING
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 200px auto;
  width: 100%;
}

.text {
  @include secondary-title;
  font-size: 68px;
  font-weight: 700;
  margin-bottom: 60px;
}

.light {
  .text {
    color: var(--secondary);
  }
}
.dark {
  .text {
    color: white;
  }
}
</style>
