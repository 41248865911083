var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avatar",
      style: _vm.styles,
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm._t("default"),
      !_vm.$slots.default
        ? _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.src ? "" : _vm.text.slice(0, 2).toUpperCase()) +
                " "
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }