import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'webappSystem',
  modelName: 'webappSystem',
  state: {
    adBlockerEnabled: false,
    onboardingDetails: null
  },
  mutations: {
    setAdBlockerEnabled: (state, val) => (state.adBlockerEnabled = val),
    setOnboardingDetails: (state, val) => (state.onboardingDetails = val)
  },
  getters: {
    adBlockerEnabled: state => state.adBlockerEnabled,
    onboardingDetails: state => state.onboardingDetails
  }
});
