import { render, staticRenderFns } from "./AssetsView.vue?vue&type=template&id=261b6213&scoped=true&"
import script from "./AssetsView.vue?vue&type=script&lang=js&"
export * from "./AssetsView.vue?vue&type=script&lang=js&"
import style0 from "./AssetsView.vue?vue&type=style&index=0&id=261b6213&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261b6213",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('261b6213')) {
      api.createRecord('261b6213', component.options)
    } else {
      api.reload('261b6213', component.options)
    }
    module.hot.accept("./AssetsView.vue?vue&type=template&id=261b6213&scoped=true&", function () {
      api.rerender('261b6213', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/OmniView/Assets/AssetsView.vue"
export default component.exports