<template>
  <div class="card-container">
    <div
      class="thumb"
      :style="thumbStyle"
      @click="onClick"
      @mouseover="hoverThumb = true"
      @mouseout="hoverThumb = false"
    >
      <div :class="['thumb-overlay', { show: hoverThumb }]" v-if="isVideo">
        <an-icon :width="24" :height="18" color="white" name="play" />
      </div>
    </div>
    <div class="meta">
      <span class="tag">{{ item.tag }}</span>
      <span class="length">{{ item.length }}</span>
    </div>
    <div class="title" @click="onClick">
      <div class="icon"><an-icon name="youtube-play" :size="24" /></div>
      <div class="text">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverThumb: false
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    thumbStyle() {
      const { thumb_url: thumbUrl } = this.item;
      return {
        background: `url('${thumbUrl}') no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    isVideo() {
      return this.item.post_type === 'video';
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  max-width: 340px;
}
.thumb {
  height: 200px;
  width: 340px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  .thumb-overlay {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(59, 59, 59, 0.95);
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s ease-in;
    color: var(--white);
    &.show {
      opacity: 1;
    }
  }
}
.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.4;
  margin: 20px 0 10px;
}
.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    margin-right: 10px;
  }
  .text {
    @include secondary-title;
    @include ellipsis;
  }
}
</style>
