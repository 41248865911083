var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("LoadingScreen")
        : _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(" " + _vm._s(_vm.subtitle) + " ")
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "an-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ name: "root" })
                      }
                    }
                  },
                  [_vm._v("Continue to Anima")]
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }