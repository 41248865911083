var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show && _vm.events.length > 0
      ? _c(
          "div",
          {
            staticClass: "debug-events",
            on: {
              mouseover: function($event) {
                _vm.hover = true
              },
              mouseleave: function($event) {
                _vm.hover = false
              }
            }
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "slide-in-left", tag: "ol" } },
              _vm._l(_vm.events, function(event) {
                return _c(
                  "li",
                  {
                    key: event.id,
                    on: {
                      click: function($event) {
                        return _vm.copyEventName(event)
                      }
                    }
                  },
                  [
                    _c("code", [
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(event.time))
                      ]),
                      _c("span", [_vm._v(_vm._s(event.eventName))]),
                      _c("br"),
                      _c("span", {
                        staticClass: "props",
                        domProps: { innerHTML: _vm._s(event.propsHtml) }
                      })
                    ])
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }