var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isInitial || _vm.isSaving
      ? _c(
          "form",
          { attrs: { enctype: "multipart/form-data", novalidate: "" } },
          [
            _c("div", { staticClass: "container-form" }, [
              _c("input", {
                ref: "input",
                staticClass: "input-file",
                attrs: {
                  type: "file",
                  name: _vm.fieldName,
                  disabled: _vm.isSaving,
                  accept: _vm.accept
                },
                on: {
                  change: function($event) {
                    return _vm.save($event.target)
                  }
                }
              }),
              _vm.isSaving && _vm.$slots.loading
                ? _c("div", [_vm._t("loading")], 2)
                : _vm.$slots.default
                ? _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.input.click()
                        }
                      }
                    },
                    [_vm._t("default")],
                    2
                  )
                : _vm.isInitial
                ? _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.input.click()
                        }
                      }
                    },
                    [_vm._t("init")],
                    2
                  )
                : _vm._e()
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }