<template>
  <div class="tooltip-container">
    <transition name="slide-fade">
      <div v-if="isOpen && !isMobile" class="tooltip" :style="tooltipStyle" v-html="html" />
    </transition>
    <div
      class="tooltip-trigger"
      @mouseover="hover = true"
      @mouseleave="close"
      tabindex="-1"
      @focusout="close"
      @click="onClick"
    >
      <slot />
    </div>
  </div>
</template>

<script>
const oppositePosition = pos => {
  switch (pos) {
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'right':
      return 'left';
    case 'left':
      return 'right';
    default:
      return null;
  }
};

export default {
  name: 'an-tooltip',
  data() {
    return {
      isOpen: false,
      hover: false
    };
  },
  props: {
    text: {
      type: String,
      required: true
    },
    trigger: {
      type: String,
      default: 'hover',
      validate: v => ['hover', 'click'].includes(v)
    },
    position: {
      type: String,
      default: 'top-right',
      validate: v => {
        const [a, b] = v?.split('-');
        if (!a || !['top', 'bottom'].includes(a)) return false;
        if (b && !['left', 'right'].includes(b)) return false;
        return true;
      }
    },
    offset: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tooltipStyle() {
      const { position, offset } = this;
      const [vertical, horizontal] = position.split('-');
      const pos = `calc(100% - ${offset}px)`;
      return {
        [oppositePosition(vertical)]: pos,
        [oppositePosition(horizontal)]: pos
      };
    },
    html() {
      return this.text.replace('\n', '<br />');
    }
  },
  methods: {
    onHoverChange() {
      const { hover, trigger, disabled } = this;
      if (trigger === 'hover') {
        this.isOpen = hover && !disabled;
      }
    },
    onClick() {
      if (this.trigger === 'click') {
        this.isOpen = !this.isOpen;
      }
    },
    close() {
      this.hover = false;
    }
  },
  watch: {
    hover(isHover) {
      if (isHover) setTimeout(this.onHoverChange, 500);
      else this.onHoverChange();
    },
    isOpen(newState) {
      this.$emit(newState ? 'open' : 'close');
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  display: inline-block;
  position: relative;
  .tooltip {
    white-space: nowrap;
    position: absolute;
    background-color: var(--white);
    color: var(--primary-text);
    padding: 10px 24px;
    box-shadow: var(--shadow);
    border-radius: 5px;
  }
  .tooltip-trigger {
    outline: none;
  }
}
</style>
