<template>
  <div class="announcements-popover-container" tabindex="-1" @focusout="close">
    <div ref="icon" class="icon" @click="toggleOpen">
      <svg-icon :name="iconName" :size="30" v-if="hasAnnouncements" />
    </div>
    <Popover :isOpen="isOpen" :style="popoverStyles" @close="close">
      <div>
        <div class="announcements-header">
          <span>Product updates</span>
        </div>
        <div class="announcements-body">
          <Announcement v-for="item in announcements" :key="item.id" :item="item" />
        </div>
      </div>
    </Popover>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash-es';
import { mapState, mapActions } from 'vuex';
import Popover from '@/components/Popovers/Popover';
import Announcement from '@/components/Announcements/Announcement';

export default {
  data() {
    return {
      clicked: false,
      isOpen: false
    };
  },
  props: {
    iconColor: {
      type: String,
      default: 'var(--secondary)'
    }
  },
  components: {
    Popover,
    Announcement
  },
  mounted() {
    this.fetchAnnouncements();
  },
  computed: {
    ...mapState('announcements', { announcements: 'items' }),
    ...mapState('users', { user: 'currentItem' }),
    hasAnnouncements() {
      return !isEmpty(this.announcements);
    },
    hasNewAnnouncements() {
      const { announcements } = this;
      const { last_seen_announcements_at } = this.user;
      if (!last_seen_announcements_at) {
        return true;
      }

      const lastSeenAnnouncements = dayjs(last_seen_announcements_at);
      const lastAnnouncementDate = dayjs(get(announcements, '0.created_at'));

      return !!(last_seen_announcements_at && lastAnnouncementDate.isAfter(lastSeenAnnouncements));
    },
    iconName() {
      return this.hasNewAnnouncements && !this.clicked ? 'announcement-alert' : 'announcement';
    },
    popoverStyles() {
      return {
        width: this.isDesktop ? '400px' : '320px',
        height: this.isDesktop ? '700px' : '550px',
        padding: 0,
        right: this.isDesktop ? '-100px' : 0,
        boxShadow: 'var(--slightly-stronger-shadow)'
      };
    }
  },
  methods: {
    ...mapActions({
      fetchAnnouncements: 'announcements/fetchAll',
      updateUser: 'users/update'
    }),
    toggleOpen() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$trackEvent('announcements.panel.open');
      } else {
        this.$trackEvent('announcements.panel.close');
      }
      this.clicked = true;
    },
    close() {
      this.isOpen = false;
    },
    async markSeenAnnouncements() {
      if (this.hasNewAnnouncements) {
        const payload = { last_seen_announcements_at: dayjs().toDate() };
        this.updateUser({ id: 'me', payload });
      }
    }
  },
  watch: {
    clicked() {
      this.markSeenAnnouncements();
    }
  }
};
</script>

<style lang="scss" scoped>
.announcements-popover-container {
  display: inline-block;
  position: relative;
  outline: none;
}
.icon {
  width: 30px;
  height: 100%;
  cursor: pointer;
  outline: none;
}
.announcements-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: var(--border);
  width: 100%;
  a {
    font-size: 12px;
  }
}
.announcements-body {
  overflow-y: scroll;
  height: 620px;
}
</style>
