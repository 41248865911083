<template>
  <div class="root" data-cy="root-page">
    <div class="another-wrapper">
      <div class="side-bar-wrapper">
        <sidebar :isOpen="isSidebarOpen" @close="closeSidebar" />
      </div>
      <div class="content">
        <div class="top-bar-wrapper">
          <top-bar @open-sidebar="openSidebar" showBurger />
        </div>
        <div class="view-container">
          <div class="view">
            <transition :name="transitionName" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Sidebar from '@/components/Layout/Sidebar/Sidebar';
import TopBar from '@/components/Layout/TopBar';
import { EventBus } from '@/services/bus';
import initHubSpot from '@/services/hubspot.js';
import { io } from 'socket.io-client';

export default {
  name: 'root',
  data() {
    return {
      transitionName: '',
      isSidebarOpen: false,
      figmaWs: null
    };
  },
  components: {
    Sidebar,
    TopBar
  },
  computed: {
    ...mapState('users', { user: 'currentItem' })
  },
  created() {
    this.createFigmaWebsocket();
  },
  destroyed() {
    this.figmaWs = null;
  },
  mounted() {
    this.applyTheme('light');

    this.$router.beforeEach((to, from, next) => {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      next();
    });

    this.$intercom.boot({
      user_id: this.user.id,
      name: this.user.name,
      email: this.user.email
    });
    this.$intercom._call('onShow', this.trackIntercomShow);
    this.$tracking.setUserEmail(this.user.email);
    this.$tracking.setUserId(this.user.id);
    this.$tracking.setContext({ latest_paired_design_tool: this.user.latest_paired_design_tool });
    this.$tracking.alias(this.user.id);

    initHubSpot();
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      'identify',
      {
        email: this.user.email,
        id: this.user.id
      }
    ]);
    _hsq.push(['setPath', this.$route.path]);
    _hsq.push(['trackPageView']);

    if (this.user.id) {
      if (this.$hj) {
        this.$hj('identify', this.user.id, {
          email: this.user.email
        });
      }
      if (this.$FullStory) {
        this.$FullStory.identify(this.user.id, {
          email: this.user.email
        });
      }
    }
  },
  methods: {
    createFigmaWebsocket() {
      if (this.figmaWs) {
        this.figmaWs.connect();
        return;
      }
      const figmaWsURL = `${process.env.FIGMA_WEBSOCKET_URL}`;
      this.figmaWs = io(figmaWsURL, {
        transports: ['websocket'],
        query: {
          userId: this.user.id
        }
      });

      this.figmaWs.on('connect', () => {
        console.warn('connection');
      });
      this.figmaWs.on('disconnect', () => {
        console.warn('disconnection');
      });
      this.figmaWs.on(`figma-ws-message`, message => {
        EventBus.$emit('on-figma-ws-message', message);
      });

      // this.figmaWs.onopen = () => {
      //   console.warn('open');
      //   // this.figmaWs.onmessage = message => {
      //   //   EventBus.$emit('on-figma-ws-message', message);
      //   // };
      //   this.figmaWs.addEventListener(`task-${this.user.id}`, message => {
      //     EventBus.$emit('on-figma-ws-message', message);
      //   });
      // };
    },
    ...mapActions({ trackIntercomShow: 'tracking/trackIntercomShow' }),
    openSidebar() {
      this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  display: flex;
  height: 100vh;
}
.side-bar-wrapper {
  flex-shrink: 0;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.another-wrapper {
  display: flex;
  width: 100%;
}
.view-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  margin-top: 60px;
}
.view {
  margin: 0 40px;
  height: 100%;
  width: 100%;
  max-width: 1450px;
  @media screen and (max-width: $breakpoint) and (min-width: 600px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 600px) {
    margin: 0;
  }
}
.view::after {
  content: '';
  display: block;
  height: 100px;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.top-bar-wrapper {
  position: relative;
  width: 100%;
}
</style>
