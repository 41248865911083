// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../assets/svg/image-mask.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mask[data-v-5f27283e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  max-height: 100%;\n  max-width: 100%;\n  z-index: 0;\n  position: absolute;\n}\n.name-border[data-v-5f27283e] {\n  z-index: 0;\n  position: absolute;\n  max-height: 100%;\n  max-width: 100%;\n  box-shadow: 0 0 0 1px #ff6250;\n}\n.name-border .name[data-v-5f27283e] {\n  position: relative;\n  height: 20px;\n  top: -22px;\n  left: -1px;\n  background: #ff6250;\n  color: white;\n  padding: 0 8px;\n  font-size: 14px;\n}", ""]);
// Exports
module.exports = exports;
