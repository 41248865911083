<template>
  <div>
    <OnboardingTopBar homepageUrl="/" />
    <LoadingScreen v-if="loading" />
    <div class="container" v-else>
      <div class="illustration">
        <img v-if="platformIllustrationSrc" :src="platformIllustrationSrc" class="platform-icon pulse" />
      </div>
      <div class="title">Login complete. You’re all set!</div>
      <div class="subtitle">
        We successfully paired <u>{{ email }}</u> with {{ platformName }}.<br />
        Now head back and start using Anima plugins to create awesome prototypes.
      </div>
      <div class="actions">
        <an-button @click="handleOpen">Back to {{ platformName }}</an-button>
        <an-link class="link" variant="primary" @click="navigateToExplore">Explore Anima</an-link>
      </div>
      <div class="footer">
        <span>Not {{ email }}?&nbsp;</span>
        <an-link @click="switchAccount" variant="primary">Log in</an-link>
        <span>&nbsp;with another account</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { reportPairedPlugin } from 'anima-ppc-tracking';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import auth from '@/auth';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      email: '',
      loading: false,
      platform: '',
      hasError: false
    };
  },
  components: {
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    this.onMounted();
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    platformIllustrationSrc() {
      if (!['xd', 'sketch', 'figma'].includes(this.platform)) return '';
      return require(`@/assets/illustrations/pair/pair-${this.platform}.svg`);
    },
    platformName() {
      switch (this.platform) {
        case 'xd':
          return 'Adobe XD';
        case 'sketch':
          return 'Sketch';
        case 'figma':
          return 'Figma';
        default:
          return 'Sketch';
      }
    },
    platformRedirect() {
      switch (this.platform) {
        case 'xd':
          return 'adbxd://';
        case 'sketch':
          return 'sketch://animaapp';
        case 'figma':
          return 'figma://animaapp';
        default:
          return 'sketch://animaapp';
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      updateUser: 'users/update',
      fetchPairingToken: 'pairingTokens/fetchOne',
      updatePairingToken: 'pairingTokens/update'
    }),
    async onMounted() {
      try {
        this.loading = true;
        this.platform = this.$route.query.platform?.toLowerCase();
        await this.fetchUserData();
        await this.performPairing();
      } catch (err) {
        this.hasError = true;
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchUserData() {
      const user = await this.getUser({ id: 'me' });
      this.email = user.email;
      this.$tracking.setUserEmail(user.email);
      this.$tracking.setUserId(user.id);
      this.$tracking.alias(user.id);
    },
    handleOpen() {
      this.$trackEvent('paired.open-platform-link.click', { platform: this.platformName });
      window.location.replace(this.platformRedirect);
    },
    navigateToExplore() {
      this.$trackEvent('paired.explore-anima.click');
      this.$router.push({ name: 'learn' });
    },
    async performPairing() {
      const { query } = this.$route;
      const { platform, pt: token } = this.$route.query ?? {};

      try {
        const { id } = await this.fetchPairingToken({ id: token, params: { primary_key: 'token' } });
        this.updatePairingToken({ id, payload: { is_paired: true } });
        this.updateUser({ id: 'me', payload: { latest_paired_design_tool: this.platformName } });
        this.$trackEvent('paired.plugin-pairing.success', { platform });
        this.$gtm.trackEvent({
          event: 'paired_plugin',
          event_category: 'paired_plugin',
          event_action: this.platformName,
          event_label: this.user?.role
        });

        reportPairedPlugin({ platform });

        if (query.redirect) {
          if (query.redirect.startsWith('http')) {
            window.location.href = query.redirect;
            return;
          }
          const pairingRedirect = query.redirect?.includes('pairing=true')
            ? `${query.redirect}&after_login=true`
            : query.redirect;

          return this.$router.replace(pairingRedirect || '/', () => {});
        }
      } catch (err) {
        this.$trackEvent('paired.plugin-pairing.failure', { token, platform, message: err.message });
        toastError('Oh no, something went wrong!');
        this.hasError = true;
      }
    },
    switchAccount() {
      const { query } = this.$route;

      this.$trackEvent('paired.switch-account-link.click');

      auth.logout();
      this.$router.replace({ name: 'login', query });
      window.location.reload();
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
}
.illustration {
  height: 230px;
  width: 290px;
  background-image: url('~@/assets/illustrations/pair/pair-dice.svg');
  position: relative;
  margin-top: 100px;
  .platform-icon {
    height: 60px;
    width: 68px;
    position: absolute;
    top: 0;
    right: 10px;
  }
}
.title {
  @include headline;
  font-weight: 400;
}
.footer {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  50% {
    transform: scale3d(2, 2, 2);
  }
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
.pulse {
  animation: pulse both linear 0.3s;
  animation-delay: 0.2s;
}
</style>
