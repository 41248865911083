<template>
  <div>
    <div v-if="!releases.length" class="empty-state">
      <div class="empty-state-title">No versions yet</div>
      <div class="empty-state-subtitle">
        Here you'll see a list of available previous versions of your design once synced from Sketch, Figma or XD.
      </div>
    </div>
    <table v-else class="table-container">
      <thead class="table-header">
        <tr>
          <td>Version</td>
          <td>Date created</td>
          <td />
          <td />
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="(item, i) in releases" :key="`row${i}`">
          <td>{{ item.revision_number }}</td>
          <td>{{ formatDate(item.created_at, 'MMM DD, YYYY HH:mm') }}</td>
          <td>
            <div class="align-right">
              <an-tooltip :text="`View version ${item.revision_number}`">
                <div class="clickable-icon" @click="viewVersion(item)">
                  <svg-icon name="eye" :size="24" />
                </div>
              </an-tooltip>
            </div>
          </td>
          <td>
            <div class="align-right">
              <span v-if="isLive(item)" class="live">Live</span>
              <an-link v-else variant="primary" @click="recover(item)">Set as Live</an-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { formatDate } from '@/utils/date';
import { toastError } from '@/services/bus';

export default {
  computed: {
    ...mapState('projectReleases', { releases: 'items' }),
    ...mapState('components', { components: 'items' }),
    ...mapState('projects', { project: 'currentItem' })
  },
  methods: {
    ...mapActions({
      fetchProject: 'projects/fetchOne',
      updateProject: 'projects/update',
      fetchProjectReleases: 'projectReleases/fetchAllOfParent'
    }),
    viewVersion({ revision_number: v }) {
      this.$trackEvent('project-settings-versions.view-version-button.click');
      this.$emit('close', { redirect: { name: 'project', query: { v } } });
    },
    formatDate,
    async recover({ id: releaseId }) {
      try {
        const { id: projectId } = this.project;
        const payload = {
          live_project_release: {
            type: 'project_releases',
            id: releaseId
          }
        };

        this.$trackEvent('project-settings-versions.recover-button.click');

        await this.updateProject({ id: projectId, payload });

        this.fetchProject({ id: projectId, skipCache: true });
        this.fetchProjectReleases({ parent: 'projects', id: projectId, skipCache: true });

        this.$trackEvent('project-settings-versions.recover.success');
      } catch (err) {
        this.$trackEvent('project-settings-versions.recover.failure', { message: err.message });
        toastError('Failed recovering release');
      }
    },
    isLive({ id: releaseId }) {
      return this.project.live_project_release === releaseId;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
.empty-state {
  text-align: center;
  width: 430px;
  margin: 0 auto;
  color: var(--secondary-text);
  margin-top: 50px;
  &-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.live {
  color: var(--green);
}
</style>
