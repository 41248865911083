<template>
  <div>
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="onboarding-container">
      <div v-if="!isStep0" class="small-title">Get started</div>
      <div class="skip">
        <an-link data-cy="skip-onboarding" @click="doneOnboarding('skip')" :theme="theme" :key="skipText">
          {{ skipText }}
        </an-link>
      </div>

      <div class="step-0" v-if="isStep0">
        <div class="title">Hello {{ firstName }},</div>
        <div class="text">
          Let’s personalize your Anima experience &amp; make it perfect for your needs.
        </div>
        <div class="actions">
          <an-button data-cy="start-onboarding-questions" @click="nextStep" size="xl">Let's do it</an-button>
        </div>
      </div>

      <transition-group name="slide-onboarding" tag="span">
        <!-- step 1: role -->
        <span v-if="isRoleStep" key="step-1">
          <span class="text">{{ roleText }}</span>
          <span :class="{ 'onboarding-select-container': true, selected: !!role }">
            <OnboardingSelect :value="role" @choice="selectRole" :items="roleOptions" theme="dark" isOpenByDefault />
          </span>
        </span>

        <!-- step 2: work place -->
        <span v-if="isWorkplaceStep" key="step-2">
          <span class="text">
            , and I
            <span :class="{ strike: showStrike }">
              <span class="text">{{ workplaceText }}</span>
            </span>
          </span>
          <span :class="{ 'onboarding-select-container': true, selected: !!workPlace }">
            <OnboardingSelect
              :value="workPlace"
              @choice="selectWorkplace"
              :items="workPlaceOptions"
              theme="dark"
              isOpenByDefault
            />
          </span>
          <span v-if="isUsageStep" class="text">.</span>
        </span>

        <!-- step 3: anima usage -->
        <span v-if="isUsageStep" class="break-line-desktop" key="step-3">
          <span class="text break-line-mobile">
            I want to use Anima to
          </span>
          <span :class="{ 'onboarding-select-container': true, selected: !!workPlace }">
            <OnboardingSelect
              :value="usage"
              @choice="selectUsage"
              :items="usageOptions"
              theme="dark"
              :width="isDesktop ? '360px' : '295px'"
              isOpenByDefault
            />
          </span>
          <span v-if="isDone" class="text">.</span>
        </span>
      </transition-group>

      <div class="done actions" v-if="isDone">
        <an-button data-cy="finish-onboarding-questions" size="xl" @click="doneOnboarding">Let's go!</an-button>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import OnboardingSelect from '@/components/Onboarding/Select';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { kebabCase } from 'lodash-es';
import { indefiniteArticle } from '@/utils/language';
import { reportCustomEvent } from 'anima-ppc-tracking';
import { TeamMixin } from '@/mixins';

export default {
  data() {
    return {
      theme: 'dark',
      step: 0,
      role: {},
      workPlace: {},
      usage: [],
      roleOptions: [
        { label: 'Product designer', value: 'product designer', emoji: 'paint' },
        { label: 'Developer', value: 'developer', emoji: 'joystick', datacy: 'option-developer' },
        { label: 'Product manager', value: 'product manager', emoji: 'watch' },
        { label: 'Team leader', value: 'team leader', emoji: 'magician', datacy: 'option-team-leader' },
        { label: 'Founder/Executive', value: 'big deal kinda', emoji: 'ace' },
        { label: 'Other', value: 'mysterious stranger', emoji: 'ghost' }
      ],
      workPlaceOptions: [
        { label: 'Enterprise', value: 'enterprise', emoji: 'spaceship', datacy: 'option-enterprise' },
        { label: 'Start-up', value: 'start-up', emoji: 'submarine' },
        { label: 'UX/UI agency', value: 'UX/UI agency', emoji: 'bulb' },
        { label: 'Branding firm', value: 'branding firm', emoji: 'octopus' },
        { label: "I'm a freelancer", value: 'am a freelancer', emoji: 'wfh' }
      ],
      usageOptions: [
        {
          label: 'Create high fidelity prototypes',
          value: 'create high fidelity prototypes',
          short: 'prototype',
          datacy: 'option-prototype'
        },
        {
          label: 'Export HTML, React or Vue',
          value: 'export HTML, React or Vue',
          short: 'export-code',
          datacy: 'option-export-code'
        },
        { label: 'Share interactive design', value: 'share interactive design', short: 'share-design' },
        { label: 'Collaborate with my team', value: 'collaborate with my team', short: 'collaboration' },
        { label: 'Perform user testing', value: 'perform user testing', short: 'user-testing' }
      ]
    };
  },
  components: {
    OnboardingTopBar,
    OnboardingSelect
  },
  mounted() {
    this.applyTheme('dark', 'onboarding');
    document.body.style.overflowY = 'auto';
    this.fetchUser({ id: 'me' });
    this.fetchData();
  },
  beforeDestroy() {
    this.removeTheme();
  },
  computed: {
    ...mapGetters({ firstName: 'users/firstName', userEmail: 'users/userEmail' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    skipText() {
      return `Skip ${this.isDesktop ? 'for now' : ''}`;
    },
    isStep0() {
      return this.step === 0;
    },
    isRoleStep() {
      return this.step >= 1;
    },
    isWorkplaceStep() {
      return this.step >= 2;
    },
    isUsageStep() {
      return this.step >= 3;
    },
    isDone() {
      return this.step >= 4;
    },
    showStrike() {
      return this.workPlace.value?.startsWith('am a freelancer');
    },
    roleText() {
      const { role } = this;
      const article = indefiniteArticle(role?.value);
      return `I am ${article}`;
    },
    workplaceText() {
      const { workPlace } = this;
      const article = this.showStrike ? 'a' : indefiniteArticle(workPlace?.value);
      return `work for ${article}`;
    }
  },
  mixins: [TeamMixin],
  methods: {
    ...mapActions({
      fetchUser: 'users/fetchOne',
      updateUser: 'users/update',
      updateTeam: 'teams/update',
      fetchTeam: 'teams/fetchOne',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      getDefaultTeamSlug: 'users/getDefaultTeamSlug'
    }),
    ...mapMutations({
      setOnboardingDetails: 'webappSystem/setOnboardingDetails',
      setUser: 'users/setCurrentItem'
    }),
    nextStep() {
      this.step++;
    },
    selectRole(role) {
      this.role = role;
      if (this.step === 1) {
        this.nextStep();
      }
    },
    selectWorkplace(workPlace) {
      this.workPlace = workPlace;
      if (this.step === 2) {
        this.nextStep();
      }
    },
    selectUsage(usage) {
      this.usage = usage;
      if (this.step === 3) {
        this.nextStep();
      }
    },
    async fetchData() {
      this.fetchUser({ id: 'me', skipCache: true });
      const defaultTeamSlug = await this.getDefaultTeamSlug();
      if (!defaultTeamSlug) {
        this.$trackEvent('onboarding.default-team-slug.missing', { userId: this.user?.id });
        return;
      }
      this.fetchTeam({ id: defaultTeamSlug, params: { is_slug: true }, skipCache: true });
      this.fetchTeamMemberships({ id: this.team?.id });
      this.fetchUserMemberships({ id: 'me', skipCache: true });
    },
    async doneOnboarding(action) {
      const { query } = this.$route;
      const role = kebabCase(this.role?.label);
      const workPlace = kebabCase(this.workPlace?.label);
      const usage = kebabCase(this.usage?.short);

      if (action === 'skip') {
        this.$trackEvent('onboarding.questions.skip', { role, workPlace, usage });
      } else {
        this.$trackEvent('onboarding.questions.submit', { role, workPlace, usage });
      }

      this.$gtm.trackEvent({
        event: 'singup_complete_data',
        event_category: role,
        event_action: workPlace,
        event_label: usage
      });

      reportCustomEvent('Submit Personalization Data', { role, workPlace, usage });

      const payload = {
        role,
        workplace_type: workPlace,
        intent: usage
      };
      this.updateUser({ id: 'me', payload });
      this.setUser({ ...this.user, ...payload });
      this.setOnboardingDetails({ ...payload, intent: this.usage?.value });

      this.$router.push({ name: 'onboarding-team', query });
    }
  }
};
</script>

<style lang="scss" scoped>
.onboarding-container {
  margin: 280px 128px 0 128px;
  @include mobile {
    margin: 220px 24px 0;
  }
}
.title {
  @include headline;
  font-weight: 800;
  font-size: 48px;
  line-height: 68px;
  margin-bottom: 30px;
}
.small-title {
  margin-bottom: 30px;
}
.skip {
  position: fixed;
  @include desktop {
    bottom: 40px;
    right: 120px;
  }
  @include mobile {
    top: 80px;
    right: 20px;
  }
}
.skip,
.small-title {
  @include secondary-title;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  opacity: 0.4;
  @include mobile {
    font-size: 16px;
    line-height: 24px;
  }
}
.break-line-desktop {
  display: inline;
  @include desktop {
    display: block;
  }
}
.text {
  @include secondary-title;
  font-size: 32px;
  line-height: 54px;
  &.break-line-mobile {
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  @include mobile {
    font-size: 20px;
    line-height: 40px;
  }
}
.actions {
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  &.done {
    margin-top: 40px;
    animation: 1s ease 0s normal forwards 1 slide-up;
  }
  button.an-button {
    @include desktop {
      @include secondary-title;
      font-size: 24px;
      line-height: 30px;
    }
    @include mobile {
      width: 100%;
      border-radius: 0;
    }
  }
}
.onboarding-select-container {
  @include secondary-title;
  margin-left: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  @include mobile {
    margin-left: 0;
    &::before {
      content: ' ';
    }
  }
  &.selected {
    margin-left: 0;
    &::before {
      content: ' ';
    }
  }
}
.step {
  &-0 {
    max-width: 645px;
    .title {
      opacity: 0;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 0.5s;
    }
    .text {
      opacity: 0;
      margin-bottom: 40px;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 1s;
    }
    .actions {
      opacity: 0;
      animation: 1s ease 0s normal forwards 1 slide-up;
      animation-delay: 2s;
    }
  }
}
.strike {
  text-decoration: line-through;
  color: var(--primary);
  .text {
    color: var(--white);
  }
}
.slide-onboarding-enter-active,
.slide-onboarding-leave-active {
  animation: 1s ease 0s normal forwards 1 slide-up;
}
.slide-onboarding-enter,
.slide-onboarding-leave-to {
  opacity: 0;
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
