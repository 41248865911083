var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        d:
          "M18.48 3.21002H5.55998C5.25101 3.20976 4.94508 3.27095 4.65998 3.39002C4.37651 3.51013 4.11857 3.68323 3.89998 3.90002C3.68945 4.12161 3.52013 4.37897 3.39998 4.66002C3.28329 4.94551 3.22546 5.25164 3.22998 5.56002V18.48C3.23288 19.0918 3.47728 19.6776 3.90998 20.11C4.34482 20.5391 4.92912 20.7829 5.53998 20.79H18.46C19.0717 20.7871 19.6576 20.5427 20.09 20.11C20.5264 19.6801 20.7715 19.0926 20.7701 18.48V5.56002C20.773 5.25594 20.7188 4.954 20.61 4.67002C20.4961 4.38567 20.326 4.1272 20.11 3.91002C19.9005 3.69157 19.6487 3.51804 19.37 3.40002C19.089 3.27779 18.7864 3.21318 18.48 3.21002ZM15 13.22L10.39 15.56C10.1947 15.6589 9.97886 15.7103 9.75998 15.7101C9.39317 15.7127 9.04059 15.5682 8.78121 15.3088C8.52182 15.0494 8.37729 14.6968 8.37998 14.33V9.64002C8.37129 9.42038 8.41231 9.2016 8.49998 9.00002C8.73509 8.57602 9.17544 8.30649 9.65998 8.29002C9.89587 8.2797 10.1303 8.33142 10.34 8.44002L15 10.74C15.2313 10.8581 15.4252 11.0381 15.56 11.26C15.6997 11.4739 15.7728 11.7245 15.77 11.98C15.7713 12.2384 15.6984 12.4918 15.56 12.71C15.4245 12.9289 15.2306 13.1056 15 13.22Z",
        fill: "#FF6250"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "M14.79 12C14.7916 12.0852 14.7671 12.169 14.72 12.24C14.6755 12.3111 14.6135 12.3697 14.54 12.41L9.91998 14.78C9.85258 14.8164 9.7765 14.8337 9.69998 14.83C9.62028 14.8336 9.54102 14.8163 9.46998 14.78C9.40231 14.7388 9.34704 14.68 9.30998 14.61C9.27018 14.5399 9.24949 14.4606 9.24998 14.38V9.69C9.24821 9.61239 9.26906 9.53595 9.30998 9.47C9.34936 9.39891 9.40401 9.33744 9.46998 9.29C9.54897 9.25495 9.63369 9.23461 9.71998 9.23C9.78584 9.21533 9.85412 9.21533 9.91998 9.23L14.54 11.57C14.6115 11.613 14.6729 11.671 14.72 11.74C14.7676 11.8182 14.7919 11.9084 14.79 12Z",
        fill: "white"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }