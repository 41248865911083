var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-container" },
    [
      !_vm.ready
        ? _c(
            "div",
            { staticClass: "loader" },
            [
              _c("LoadingScreen", {
                attrs: {
                  delay: false,
                  type: _vm.currentRelease.is_code_generation_done
                    ? "default"
                    : "syncing",
                  theme: "dark"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ready,
                expression: "ready"
              }
            ],
            staticClass: "wrapper"
          },
          [
            _c("main", { ref: "mainContent", attrs: { id: "sync-content" } }, [
              _c("div", { staticClass: "main-content" }, [
                _c("div", { staticClass: "main-scroll" }, [
                  _c("iframe", {
                    class: { centerIframe: _vm.centerIframe },
                    style: {
                      width: this.currentFrameWidth + "px",
                      height: "100%"
                    },
                    attrs: { src: _vm.getPreviewURL }
                  })
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: require("@/assets/svg/anima-logo-text.svg") }
                  }),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "div",
                      { staticClass: "mainButton" },
                      [
                        _c(
                          "an-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.connectToDomain }
                          },
                          [_vm._v("Connect to domain")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }