var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toasts",
      style: { zIndex: !!_vm.toasts.length ? 50000 : -1 },
      attrs: { "data-cy": "toast" }
    },
    _vm._l(_vm.toasts, function(toast) {
      return _c("Toast", {
        key: toast.id,
        attrs: { variant: toast.variant, content: toast.content },
        on: {
          close: function($event) {
            return _vm.removeToast(toast)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }