var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "bound",
      staticClass: "popover-container",
      attrs: { tabindex: "-1" },
      on: { focusout: _vm.close }
    },
    [
      _c(
        "div",
        {
          ref: "reference",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.open.apply(null, arguments)
            }
          }
        },
        [_vm._t("reference")],
        2
      ),
      _vm.items.length > 0
        ? _c(
            "Popover",
            {
              style: _vm.popoverStyle,
              attrs: {
                popperClass: _vm.popperClass,
                position: _vm.position,
                isOpen: _vm.isOpen,
                theme: _vm.theme,
                closeOnMouseOut: ""
              },
              on: { close: _vm.close }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "an-link",
                {
                  key: index,
                  class: _vm.getStyle(item),
                  attrs: {
                    "data-cy": item.dataCy,
                    variant: item.isMarked ? "primary" : "secondary",
                    theme: _vm.theme,
                    disabled: item.disabled
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.onClick(item)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }