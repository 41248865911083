var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "horizontal-layout-container" },
    [
      _vm.items.length
        ? _c(
            "div",
            [
              _vm.itemsType === "project"
                ? _c(
                    "transition-group",
                    {
                      staticClass: "horizontal-list",
                      attrs: { name: "slide-card", tag: "div" }
                    },
                    _vm._l(_vm.items, function(item) {
                      return _c("ProjectRow", {
                        key: item.id,
                        attrs: {
                          project: item,
                          actions: _vm.actions,
                          members: _vm.members,
                          subtitle: item.subtitle
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click-project", item)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.shouldShowPagination
        ? _c("Pagination", {
            attrs: {
              itemsType: _vm.itemsType,
              currentAmount: _vm.items.length,
              totalAmount: _vm.totalAmount,
              isLoadingMore: _vm.isLoadingMore
            },
            on: {
              "load-more": function($event) {
                return _vm.$emit("load-more")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }