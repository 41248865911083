<template>
  <div class="c fadeIn">
    <!-- COMPONENT/View NAME -->
    <div class="header">
      <div class="title">
        <div class="flex items-center">
          <div
            v-if="nestedComponentsStack.length > 1"
            style="margin-right:10px"
            @click="OpenPrevComponent"
            class="icon-c"
          >
            <svg-icon style="color:white" fill="currentColor" name="arrow-left" :size="24" />
          </div>
          <span class="truncate">{{ isComponentOrSuggestion ? currentWebComponentName : newViewName }}</span>
        </div>
      </div>
      <div style="margin-left:auto" @click="clearSelection" class="icon-c">
        <svg-icon fill="currentColor" name="close" :size="18" />
      </div>
    </div>
    <!-- ENABLE/DISABLE COMPONENT -->
    <div v-if="showComponentToggle" class="block centered">
      <div class="block-title">
        <div class="w-full flex items-center">
          <span>Type</span>
          <div style="margin-left:auto" class="flex items-center">
            <an-radio
              :centered="true"
              :spacingX="6"
              :spacingY="0"
              type="dot"
              full
              label="element"
              :value="nodeType"
              @change="handleNodeTypeChange"
            >
              <span :style="{ opacity: nodeType == 'element' ? 1 : 0.4 }">Element</span>
            </an-radio>
            <an-radio
              style="margin-left:30px"
              :centered="true"
              label="component"
              type="dot"
              full
              :spacingX="6"
              :spacingY="0"
              :value="nodeType"
              @change="handleNodeTypeChange"
            >
              <span :style="{ opacity: nodeType == 'component' ? 1 : 0.4 }">Component</span>
            </an-radio>
          </div>
        </div>
      </div>
    </div>
    <!-- ASSET/COMPONENT PREVIEW -->

    <div v-if="elementAssets.length > 0">
      <div class="block centered">
        <div style="align-items:flex-start" class="block-title flex flex-col">
          <span style="margin-bottom:20px">Asset</span>
          <div class="asset-image-mask">
            <svg-icon name="image-mask" :size="100"></svg-icon>
            <div :style="getAssetBg(elementAssets[0])" class="asset-image"></div>
          </div>
          <div class="flex items-center">
            <span style="margin-right:10px">Size</span>
            <div>{{ Math.round(elementAssets[0].width) }}px × {{ Math.round(elementAssets[0].height) }}px</div>
          </div>
        </div>
      </div>
      <div class="block centered">
        <div class="block-title">
          <span>Download Asset</span>
          <div style="margin-left:auto" class="flex items-center">
            <a target="_blank" class="download" :href="getAssetDownloadURL(elementAssets[0])" download>
              <div class="download-capsule">PNG</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="block centered">
      <div style="align-items:flex-start" class="block-title flex flex-col">
        <span style="margin-bottom:20px">{{ isComponentOrSuggestion ? 'Component' : 'Element' }} Preview</span>
        <WebComponentThumb v-if="isComponentOrSuggestion" />
        <ImagePreview v-else v-bind="imagePreviewProps" />
      </div>
    </div>
    <div v-if="isComponentOrSuggestion">
      <!-- USAGE CODE / PROPS -->
      <div class="block centered">
        <div class="block-title">
          <div class="w-full flex flex-col">
            <div class="w-full flex items-center">
              <span>Props</span>

              <div style="position:relative;margin-left:auto">
                <an-button v-if="!isComponentView" @click="openAndEditComponent" size="sm" variant="secondary">
                  View
                </an-button>
              </div>
            </div>
            <div class="w-full flex items-center">
              <div
                class="w-full flex items-center justify-center"
                style="padding:20px;margin-top:24px;background:#333333"
                v-if="isPreprocessing"
              >
                <svg-icon fill="currentColor" class="icon-spin" name="spin" :size="30"></svg-icon>
              </div>
              <transition name="fadeIn">
                <UsageCode />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OverrideTagName />

    <OverrideCss />
    <div class="fixed-footer flex items-center">
      <div @click="openInExternalEditor" class="open-in-sandbox  w-full flex items-center justify-center truncate">
        <an-button :isWorking="isGeneratingPlaygroundCode" variant="empty">
          <svg-icon class="icon icon-codepen" fill="currentColor" name="codepen" :size="24"></svg-icon>
          <span>{{ `Open In ${isCodePen ? 'CodePen' : 'CodeSandbox'}` }}</span>
        </an-button>
      </div>
      <!-- <div class="vt-spacer"></div>
      <div @click="downloadZip" class="download-code-snippet flex items-center truncate">
        <an-button :disabled="isGeneratingCode" variant="empty">
          <svg-icon class="icon" fill="currentColor" name="download" :size="24"></svg-icon>
          <span>Download</span>
        </an-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { EventBus, toastWarning } from '@/services/bus';
import { SELECT_OVERRIDE_NODE, SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { get, has } from 'lodash-es';
import auth from '@/auth';
import WebComponentThumb from './WebComponentThumb.vue';
import ImagePreview from './imagePreview.vue';
import OverrideCss from './overrideCss';
import OverrideTagName from './overrideTagName';
import UsageCode from './UsageCode.vue';
import JSZip from 'jszip';
import { kebabCase } from 'lodash-es';

import { saveAs } from 'file-saver';

import { UserMixin } from '@/mixins';

export default {
  mixins: [UserMixin],
  components: {
    ImagePreview,
    WebComponentThumb,
    OverrideTagName,
    OverrideCss,
    UsageCode
  },
  data() {
    return {
      //

      // CLASSES
      isChangingComponentState: false,
      newClassName: '',
      newViewName: '',

      // isMarkedAsComponent: false,
      isMarkedAsImage: false,
      nodeType: 'component'
    };
  },
  watch: {
    isComponentOrSuggestion: {
      handler(f) {
        this.nodeType = f ? 'component' : 'element';
      }
    },
    currentNode: {
      handler(node) {
        if (node.id) {
          const nodeOverrides = this.currentComponentMetadata.overrides[node.id] || {};
          this.isMarkedAsComponent = nodeOverrides.is_component || false;
          this.isMarkedAsImage = nodeOverrides.is_image || false;

          this.newViewName = has(nodeOverrides, 'viewName') ? nodeOverrides.viewName : node.viewName;

          // if (!has(nodeOverrides, 'is_component') && (this.modelNodesMap[node.id] || {}).is_component) {
          //   this.$nextTick(() => {
          //     this.handleMarkAsComponent();
          //   });
          // }
          if (!has(nodeOverrides, 'is_image') && (this.modelNodesMap[node.id] || {}).is_image) {
            this.$nextTick(() => {
              this.handleMarkAsImage();
            });
          }

          if (nodeOverrides['capture_type']) {
            this.setCaptureType(nodeOverrides['capture_type']);
          }
        }
      }
    },
    currentComponentMetadata: {
      handler(meta, from) {
        if (!from && meta) {
          // this.isMarkedAsComponent = (meta.overrides[this.currentNode.id] || {}).is_component || false;
          this.isMarkedAsImage = (meta.overrides[this.currentNode.id] || {}).is_image || false;
        }
      },
      immediate: true
    },

    modelNodesMap: {
      handler(map) {
        if (!this.newViewName) {
          this.newViewName = has(this.nodeOverrides, 'viewName')
            ? this.nodeOverrides.viewName
            : map[this.currentNode.id]
            ? map[this.currentNode.id].viewName
            : this.currentNode.viewName || '';
        }
      },
      immediate: false
    }
  },
  computed: {
    ...mapState('projects', { assetsRegistry: 'assetsRegistry' }),
    ...mapState('omniview', { modelNodesMap: 'modelNodesMap', screenshot: 'base64Screenshot' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('webComponents', { webComponents: 'items' }),

    ...mapGetters({
      currentNode: 'omniview/currentNode',
      codegenLang: 'omniview/codegenLang',
      captureType: 'omniview/captureType',
      isGeneratingCapture: 'omniview/isGeneratingCapture',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      currentComponentAssets: 'components/currentComponentAssets',
      nodeOverrides: 'omniview/nodeOverrides',
      isNativeImage: 'omniview/isNativeImage',
      currentWebComponent: 'webComponents/currentWebComponent',
      isWebComponent: 'webComponents/isWebComponent',
      isWebComponentById: 'webComponents/isWebComponentById',
      isSuggestion: 'webComponents/isSuggestion',
      isPreprocessing: 'webComponents/isPreprocessing',
      currentNodeMd5Map: 'omniview/currentNodeMd5Map',
      isComponentView: 'webComponents/isComponentView',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      currentWebComponentNode: 'webComponents/currentWebComponentNode',
      webInstancesMap: 'webComponents/webInstancesMap',
      nestedComponentsStack: 'webComponents/nestedComponentsStack',
      isComponentOrSuggestion: 'webComponents/isComponentOrSuggestion',
      isFetchingWebComponents: 'webComponents/isFetchingWebComponents',
      isGeneratingPlaygroundCode: 'omniview/isGeneratingPlaygroundCode',
      currentNodeHTML: 'omniview/currentNodeHTML',
      currentNodeJSX: 'omniview/currentNodeJSX',
      currentNodeCSS: 'omniview/currentNodeCSS',
      isGeneratingCode: 'omniview/isGeneratingCode',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      styleType: 'omniview/styleType'
    }),

    showComponentToggle() {
      return (
        !this.isComponentView && (this.codegenLang === 'react' || this.codegenLang === 'vue')
      );
    },

    // screenshotSrc() {
    //   const { layer } = this.$route.query;
    //   const { screenshot, nodeOverrides } = this;
    //   const { master } = this.getMasterAndInstanceByNodeId(layer);
    //   if (!master) return screenshot || nodeOverrides['preview_url'];

    //   return (
    //     `${master.screenshot_url}` ||
    //     `https://anima-uploads.s3.amazonaws.com/projects/${this.currentProject.id}/web_components/screenshots/${master.fingerprint}.png`
    //   );
    // },
    elementAssets() {
      return (this.currentComponentAssets || []).filter(l => l.modelID == this.currentNode.id);
    },
    assetExtension() {
      const currentAssetFileName = get(this.elementAssets, ['0', 'fileName'], '');
      const extensionBeginIndex = currentAssetFileName.lastIndexOf('.');
      if (extensionBeginIndex > -1) {
        return currentAssetFileName?.substr(extensionBeginIndex + 1);
      }
      return 'PNG';
    },

    imagePreviewProps() {
      const { screenshot, nodeOverrides } = this;

      return {
        loading: this.isGeneratingCapture[this.currentNode.id],
        src: screenshot || nodeOverrides['preview_url']
      };
    },

    currentWebComponentName() {
      return get(this.currentWebComponent, 'master.component_name', get(this.currentWebComponent, 'master.name', ''));
    },

    isConvertibleToImage() {
      return !this.isNativeImage;
    },

    isMarkedAsComponentDisabled() {
      return this.codegenLang == 'html';
    },

    getNodeImageBg() {
      return {
        'background-image': `url('${this.nodeOverrides.capture_url}')`,
        'background-size': 'contain'
      };
    },
    showNestedComponentActions() {
      const {
        instance: { model_id: id }
      } = this.currentWebComponent;
      const { id: nId } = this.currentWebComponentNode;

      if (!id || !nId) return false;

      return id != nId && has(this.webInstancesMap, nId);
    },
    selected() {
      const { layer } = this.$route.query;
      return layer || !!this.currentNode.id;
    },
    isCodePen() {
      return this.codegenLang == 'html';
    },
    isCodeSandbox() {
      return !!['vue', 'react'].includes(this.codegenLang);
    },
    nodeHTML() {
      let h = '';
      switch (this.codegenLang) {
        case 'react':
          h = this.currentNodeJSX;
          break;

        case 'html':
          h = this.currentNodeHTML;
          break;

        default:
          break;
      }
      return h;
    },

    isVue() {
      return this.codegenLang === 'vue';
    }
  },
  mounted() {
    this.nodeType = this.isComponentOrSuggestion ? 'component' : 'element';

    this.newViewName = this.currentNode.viewName;
    EventBus.$on('component-state-change', this.handleComponentStateChange);
  },
  destroyed() {
    EventBus.$off('component-state-change', this.handleComponentStateChange);
  },
  methods: {
    ...mapMutations({
      setIsWaitingForOverrides: 'omniview/setIsWaitingForOverrides',
      resetSelection: 'omniview/resetSelection',
      setCurrentComponentMetadata: 'componentsMetadata/setCurrentComponentMetadata',
      setCurrentNode: 'omniview/setCurrentNode',
      setCaptureType: 'omniview/setCaptureType',
      setIsGeneratingCapture: 'omniview/setIsGeneratingCapture',
      setNestedComponentsStack: 'webComponents/setNestedComponentsStack'
    }),

    ...mapActions({
      fetchMetadata: 'componentsMetadata/fetchMetadata',
      updateMetadata: 'componentsMetadata/update',
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      getBackAllTheOverrides: 'omniview/getBackAllTheOverrides',
      startTour: 'omniview/startTour',
      fetchDBWebComponents: 'webComponents/fetchDBWebComponents'
    }),

    downloadZip() {
      if (!this.selected) {
        toastWarning('Select an element to download React or HTML code');
        return;
      }

      if (this.shouldShowPaywall) {
        return EventBus.$emit('show-paywall', { action: 'open-external-editor' });
      }

      let zip = new JSZip();
      zip.file('style.css', this.currentNodeCSS);
      let stringToInsert = 'import "./style.css";';
      let stringToReplace = 'import React from "react";';
      const { component } = this.$route.query;

      let nodeHTMLCopy =
        this.codegenLang == 'react'
          ? this.nodeHTML.replace(stringToReplace, `${stringToReplace}\n${stringToInsert}`)
          : this.nodeHTML;

      zip.file(`index.${this.codegenLang == 'react' ? 'jsx' : 'html'}`, nodeHTMLCopy);

      zip.generateAsync({ type: 'blob' }).then(blob => {
        return saveAs(blob, kebabCase(`${this.currentNode.viewName}-${this.codegenLang}).zip`));
      });
      this.$trackEvent('omniview.component-code-zip.download', {
        type: this.codegenLang,
        framework: this.codegenLang,
        'style-type': this.styleType,
        isComponentLibrary: !!component
      });
      this.$gtm.trackEvent({
        event: 'component_code_download',
        event_category: this.currentUser?.role,
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: this.codegenLang
      });
      this.setMarketingActiveUser('developer');
      EventBus.$emit('open-code-feedback');
    },

    openInExternalEditor() {
      if (this.isGeneratingPlaygroundCode) return;

      if (!this.selected) {
        toastWarning(`Select an element to view it in ${this.isCodePen ? 'Codepen' : 'CodeSandbox'}`);
        return;
      }

      if (this.shouldShowPaywall) {
        return EventBus.$emit('show-paywall', { action: 'open-external-editor' });
      }

      EventBus.$emit('generate-code', {
        isCodePen: this.isCodePen,
        isCodeSandbox: this.isCodeSandbox,
        forceGenerate: true
      });
    },

    async openComponentInterface() {
      EventBus.$emit('open-component-interface');
    },

    handleComponentStateChange() {
      const { projectId } = this.$route.params;
      this.isChangingComponentState = false;
      this.fetchDBWebComponents({
        parent: 'projects',
        id: projectId,
        skipCache: true,
        params: { skip_cache: true, get_all: true }
      });
    },

    async enableComponent() {
      this.$trackEvent('omniview.component-add-to-library.click');
      const { layer } = this.$route.query;
      const nodeId = layer || this.currentNode.id;

      EventBus.$emit('add-component-to-library', {
        onDoneEvent: 'component-state-change',
        preProcessParams: {
          forcePreProcess: true,
          customSettings: {
            auto_detect_component: true,
            generate_hosted_html_for_primary_instances: 'true'
          }
        }
      });

      this.setNestedComponentsStack([nodeId]);

      this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [nodeId]: true });
    },

    disableComponent() {
      EventBus.$emit('reject-suggestion', { onDoneEvent: 'component-state-change' });
    },

    OpenPrevComponent() {
      let stack = [...this.nestedComponentsStack];
      stack.pop();
      let nodeId = stack[stack.length - 1];

      EventBus.$emit('open-component-in-library', {
        nodeId
      });
      this.setNestedComponentsStack(stack);
    },

    addNodeToStack(nodeId) {
      let stack = [...this.nestedComponentsStack];
      stack.push(nodeId);
      this.setNestedComponentsStack(stack);
    },

    openAndEditComponent(type) {
      let { layer: nodeId, component } = this.$route.query;

      if (type && type == 'nested') {
        nodeId = this.currentWebComponentNode.id;
      }

      if (component && nodeId == component) {
        this.openComponentInterface();
      } else {
        EventBus.$emit('open-component-in-library', {
          nodeId,
          openComponentInterface: false,
          preProcessParams: {
            forcePreProcess: true
          }
        });
        this.addNodeToStack(nodeId);
      }

      this.$trackEvent('omniview.component-open-in-library.click');
    },

    getAssetBg(layer) {
      return {
        'background-image': `url('${
          this.assetsRegistry && this.assetsRegistry[layer.md5] ? this.assetsRegistry[layer.md5].url : ''
        }')`,
        'background-size': 'contain'
      };
    },
    getAssetDownloadURL(layer) {
      return `${process.env.API_BASE_URL1}/v2/projects/${this.$route.params.projectId}/assets/${
        layer.md5
      }?t=${auth.getToken()}`;
    },

    handleNodeRename() {
      if (!this.newViewName) {
        this.newViewName = this.currentNode.viewName;
        this.$refs.newViewNameRef.resetValue(this.currentNode.viewName);
        return;
      }

      if (this.newViewName.trim() == this.currentNode.viewName.trim()) {
        this.$refs.newViewNameRef.resetValue(this.currentNode.viewName);
        return;
      }

      const { id: nodeId } = this.currentNode;

      const currentNodeOverrides = (this.currentComponentMetadata.overrides || {})[this.currentNode.id] || {};

      this.setCurrentComponentMetadata({
        componentId: this.currentComponentMetadata.id,
        fields: {
          overrides: {
            ...this.currentComponentMetadata.overrides,
            [nodeId]: {
              ...currentNodeOverrides,
              viewName: this.newViewName
            }
          }
        }
      });

      this.$trackEvent('omniview.code-override.renamed-view', { viewname: this.newViewName });

      this.setCurrentNode({ ...this.currentNode, viewName: this.newViewName });
      this.getBackAllTheOverrides({ regenerate: false });
      EventBus.$emit('generate-code', { forceGenerate: true });
    },
    undoViewNameRename() {
      const { id: nodeId } = this.currentNode;

      if ((this.modelNodesMap[nodeId] || {})['viewName']) {
        this.newViewName = this.modelNodesMap[nodeId]['viewName'];
        this.handleNodeRename();
      }
    },
    clearSelection() {
      this.resetSelection();
    },

    // TAG NAME

    // CLASSES
    clearClassValue() {
      const { classInput } = this.$refs;
      this.newClassName = '';
      classInput.$el.innerHTML = '';
    },
    openClassInput() {
      const { classInput } = this.$refs;
      this.isAddingClass = true;
      this.$nextTick(() => {
        classInput.triggerFocus();
      });
    },
    closeClassInput() {
      this.isAddingClass = false;
      this.clearClassValue();
    },
    handleClassAdd() {
      const className = this.newClassName;
      if (!className || this.currentNode.customClasses.indexOf(className) !== -1) return;
      if (className.indexOf(' ') !== -1) return;

      this.setClassesSMessage('add-css-class', className);
      this.clearClassValue();
    },
    handleClassDelete(className) {
      this.setClassesSMessage('remove-css-class', className);
    },
    setClassesSMessage(action, className) {
      EventBus.$emit(SEND_MESSAGE, {
        action: className
      });
      this.getBackAllTheOverrides();
    },
    // INLINE STYLES

    // async handleMarkAsComponent() {
    //   if (this.isMarkedAsComponentDisabled) return;

    //   this.updateNodeOverrides({
    //     nodeId: this.currentNode.id,
    //     fields: {
    //       is_component: this.isMarkedAsComponent,
    //       is_web_component: this.isMarkedAsComponent
    //     }
    //   });
    // },
    handleNodeTypeChange(type) {
      if (this.isChangingComponentState) return;

      this.nodeType = type;
      const enabled = type == 'component';

      this.isChangingComponentState = true;

      let call = enabled ? this.enableComponent : this.disableComponent;

      EventBus.$emit('generate-code', { forceGenerate: true });
      this.$trackEvent('omniview.component.toggle', { enabled });

      call();
    },

    async handleMarkAsImage() {
      this.maskSize = { width: 0, height: 0 };
      let fields = {
        is_image: this.isMarkedAsImage,
        capture_type: this.captureType
      };
      if (!this.isMarkedAsImage) {
        fields['capture_url'] = '';
        fields['capture_type'] = '';
      }
      this.updateNodeOverrides({
        nodeId: this.currentNode.id,
        fields
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: SELECT_OVERRIDE_NODE,
        data: {
          nodeId: this.currentNode.id,
          metadata: {
            source: 'client'
          }
        }
      });
    },
    changeCaptureType(type) {
      this.setCaptureType(type);
      this.updateNodeOverrides({
        nodeId: this.currentNode.id,
        fields: { capture_type: type }
      });

      EventBus.$emit(SEND_MESSAGE, {
        action: SELECT_OVERRIDE_NODE,
        data: {
          nodeId: this.currentNode.id,
          metadata: {
            source: 'client'
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './block.scss';

.c {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  padding-top: 60px;
}

.header {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;
  position: fixed;
  top: 1px;
  z-index: 10;
  .title {
    font-size: 16px;
    color: white;
    max-width: 260px;
  }
}

.viewNameInput {
  background: #2d2d2d;
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
}

.mark-as-component-tip,
.mark-as-image-tip {
  width: 300px;
  background: #333333;
  font-size: 13px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.asset-image-mask,
.preview-image-mask {
  position: relative;
  margin-bottom: 20px;
}
.asset-image-mask {
  width: 100px;
  height: 100px;
}
.preview-image-mask {
  width: 315px;
  height: 135px;
}

.asset-image,
.preview-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-shrink: 0;
  max-width: 75px;
  background-repeat: no-repeat;
  background-position: center;
}
.asset-image {
  width: 75px;
  height: 75px;
}
.preview-image {
  width: 85%;
  height: 85%;
}

.download-capsule {
  width: 65px;
  height: 24px;
  display: flex;
  justify-content: center;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 12px;
  border-radius: 200px;
  transition: background 0.2s;
  text-transform: uppercase;
  &:hover {
    background: var(--primary);
    color: white;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.block {
  .trigger {
    width: 100%;
  }
}
.mark-as-component,
.mark-as-image {
  width: 100%;
  .checkbox-label {
    opacity: 0.4;
  }
}
.editableInputOnFocus.focus {
  background: #2d2d2d;
  padding: 0 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}

.image-type-pop {
  padding: 20px;
  background: #333333;
  max-width: 110px;
  color: white;
  border-radius: 10px;
  font-size: 14px;
}

.drop-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 16px;
}
.image-type {
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  // margin-bottom: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    color: var(--primary);
  }
  &.selected {
    color: var(--primary);
  }
}
.image-preview-container {
  position: relative;
}
.mask-container {
  background-image: url('./../../../assets/svg/image-mask.svg');
  position: absolute;
  top: 0;
  left: 0;
  .mask {
    height: 100% !important;
    width: 100% !important;
  }
}
.usage-snippet {
  width: 100%;
  // max-height: 200px;
}

.fixed-footer {
  position: fixed;
  background-color: #3b3b3b;
  bottom: 0;
  width: 100%;
  height: 54px;
  z-index: 14;
  color: white;
  border-top: 1px solid #2d2d2d;
  justify-content: space-evenly;

  .vt-spacer {
    height: 100%;
    width: 1px;
    background: #2d2d2d;
  }
  .open-in-sandbox,
  .download-code-snippet {
    height: 100%;
    // padding: 0 15px;
    cursor: pointer;

    .an-button {
      height: 100%;
      color: white !important;
      .icon-codepen > svg {
        stroke: white;
      }
    }

    &:hover {
      .an-button {
        color: var(--primary) !important;
        .icon-codepen > svg {
          stroke: var(--primary);
        }
      }
    }
  }

  .icon {
    margin-right: 5px;
  }
}
</style>
