<template>
  <div data-cy="first-team-page">
    <TopBar />
    <div class="container dark">
      <div class="title">Create your team</div>
      <div class="subtitle">Name &amp; personalize your workspace.</div>
      <div class="body">
        <div class="label">Team name</div>
        <div class="input input-button-container">
          <an-input
            v-model="teamName"
            :theme="theme"
            :invalid="invalidTeamName"
            placeholder="Team name"
            @focus="inputFocus = true"
            @blur="onBlur"
            @keyup.enter="onBlur"
            @change="invalidTeamName = !teamName"
            data-cy="team-name-input"
          />
        </div>
        <transition name="fade">
          <div class="team-members" v-if="showInviteForm">
            <TeamMembers :theme="theme" onboarding />
          </div>
        </transition>
      </div>
      <transition name="fade">
        <div class="actions dark" v-if="showInviteForm">
          <an-button @click="onContinue">Invite &amp; continue</an-button>
          <span class="link">
            <an-link @click="onSkip" :theme="theme" data-cy="team-invite-skip">I'll do it later</an-link>
          </span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/Onboarding/TopBar';
import TeamMembers from '@/components/Team/Settings/MembersForm';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      teamName: '',
      theme: 'dark',
      invalidTeamName: false,
      inputFocus: false,
      hoverIcon: false,
      animatedObject: null
    };
  },
  components: {
    TopBar,
    TeamMembers
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
    document.body.style.overflowY = 'auto';
    this.teamName = this.team?.name || '';
    this.fetchData();
  },
  beforeDestroy() {
    this.removeTheme();
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    showInviteForm() {
      return this.teamMemberships?.length > 0;
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'users/update',
      updateTeam: 'teams/update',
      fetchUser: 'users/fetchOne',
      fetchTeam: 'teams/fetchOne',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      getDefaultTeamSlug: 'users/getDefaultTeamSlug',
      setCurrentTeamProps: 'teams/setCurrentTeamProps'
    }),
    async fetchData() {
      this.loading = true;
      const defaultTeamSlug = await this.getDefaultTeamSlug();
      await this.fetchTeam({ id: defaultTeamSlug, params: { is_slug: true }, skipCache: true });
      this.fetchTeamMemberships({ id: this.team?.id });
      if (!this.teamName) this.teamName = this.team?.name || '';
      this.loading = false;
    },
    onBlur() {
      this.inputFocus = false;
      this.updateTeamName();
    },
    async updateTeamName() {
      const teamName = this.teamName?.trim();
      try {
        if (teamName) {
          this.isWorking = true;
          this.invalidTeamName = false;
          const payload = { name: this.teamName };
          const { slug } = await this.updateTeam({ id: this.team.id, payload });
          this.setCurrentTeamProps({ ...payload, slug });

          this.$trackEvent('onboarding.set-team-name.success');

          this.updateUser({ id: 'me', payload: { default_team_slug: slug }, storeResult: true });
        } else {
          this.invalidTeamName = true;
        }
      } catch (err) {
        console.error(err);
      }
    },
    onContinue() {
      this.$trackEvent('onboarding.continue-button.click');
      this.navigateToNextPage();
    },
    onSkip() {
      this.$trackEvent('onboarding.skip-button.click');
      this.navigateToNextPage();
    },
    async navigateToNextPage() {
      const { query } = this.$route;
      const teamSlug = await this.getDefaultTeamSlug();
      const params = { teamSlug };
      if (query.redirect) {
        return this.$router.push(query.redirect);
      } else if (process.env.ORGANIZATION_NAME) {
        return this.$router.push({ name: 'team', params });
      } else {
        this.$router.push({ name: 'onboarding-pricing', params });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.container {
  @media screen and (max-width: 1440px) and (min-width: 1070px) {
    margin-top: 120px;
  }
}
.input-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  > * + * {
    margin-left: 20px;
  }
}
.actions {
  margin-top: 0;
}
.link {
  opacity: 0.4;
  transition: all 0.5s ease;
  &:hover {
    opacity: 1;
  }
}
</style>
