<template>
  <transition :name="transitionName">
    <div :class="[popperClass, 'popover', theme]" v-if="isOpen" :style="popoverMenuStyle" @mouseleave="onMouseLeave">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'right',
      validator: value => ['left', 'right', 'center'].includes(value)
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: null
    },
    closeOnMouseOut: {
      type: Boolean,
      default: false
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value)
    },
    transitionName: {
      type: String,
      default: 'fade'
    },
    refId: {
      type: String
    }
  },
  computed: {
    popoverMenuStyle() {
      const { position } = this;
      const { style = {} } = this.$attrs;
      let positionStyle = { [position]: 0 };
      if (position === 'center') {
        positionStyle = {
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0
        };
      }
      return {
        ...positionStyle,
        ...style
      };
    }
  },
  methods: {
    onMouseLeave() {
      if (this.closeOnMouseOut) this.$emit('close');
    },
    onDocClick(e) {
      if (this.closeOnOutsideClick) {
        const ref = document.querySelector(`#${this.refId}`);
        if (!e.target.contains(this.$el) && ref && !ref.contains(e.target)) {
          this.$emit('close');
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.onDocClick);
  },
  destroyed() {
    document.removeEventListener('click', this.onDocClick);
  }
};
</script>

<style lang="scss" scoped>
.popover {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white);
  border-radius: 10px;
  padding: 20px;
  width: 185px;
  box-shadow: var(--shadow);
  z-index: 1000;
  color: var(--secondary);
  cursor: auto;
  top: calc(100% + 10px);
  &.dark {
    background-color: var(--secondary);
    color: var(--white);
  }
}
</style>
