var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-notifications-container" }, [
    _c("div", { staticClass: "en-section" }, [
      _c(
        "div",
        { staticClass: "title" },
        [
          _vm._v(" Activity updates "),
          _c("an-toggle", {
            on: { change: _vm.toggleNotifications },
            model: {
              value: _vm.enableActivitiesEmails,
              callback: function($$v) {
                _vm.enableActivitiesEmails = $$v
              },
              expression: "enableActivitiesEmails"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "description" }, [
        _vm._v("New project syncs and updates.")
      ])
    ]),
    _vm._m(0),
    _c("div", { staticClass: "en-section" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Comments notification")]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "Configure the frequency in which comments are sent to your inbox."
        )
      ]),
      _c(
        "div",
        { staticClass: "toggle" },
        [
          _c("GiantToggle", {
            attrs: { onText: "Right away", offText: "Never" },
            on: { change: _vm.toggleNotifications },
            model: {
              value: _vm.enableCommentEmails,
              callback: function($$v) {
                _vm.enableCommentEmails = $$v
              },
              expression: "enableCommentEmails"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "en-section separate" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Required communication")]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "Emails regarding your account like billing, security, and support."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }