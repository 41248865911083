var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assets slideInRight" },
    [
      _vm.currentComponentAssets && _vm.currentComponentAssets.length == 0
        ? _c(
            "EmptyState",
            {
              attrs: {
                src: require("@/assets/illustrations/bird-home-dark.svg"),
                title: "Import your assets"
              }
            },
            [
              _vm._v(
                " We couldn’t find any assets on this screen. Set layers for export in Figma, Sketch & XD and we will list them here. "
              )
            ]
          )
        : _c("div", { staticClass: "flex flex-col h-full w-full" }, [
            _c("div", { staticClass: "download-all" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("Download all assets")
              ]),
              _vm.pngAssets && _vm.pngAssets.length > 0
                ? _c(
                    "div",
                    { staticClass: "b flex items-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "b-icon",
                        attrs: { size: 46, name: "image-png" }
                      }),
                      _c("div", { staticClass: "flex flex-col" }, [
                        _c("div", { staticClass: "b-text" }, [
                          _vm._v(
                            _vm._s(_vm.pngAssets.length) +
                              " image" +
                              _vm._s(_vm.pngAssets.length > 1 ? "s" : "")
                          )
                        ]),
                        _c("div", { staticClass: "b-sub-text" }, [
                          _vm._v("PNG")
                        ])
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "download",
                          class: { visible: _vm.isDownloadingZip["png"] },
                          attrs: { download: "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "download-link",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.downloadZip("png")
                                }
                              }
                            },
                            [
                              !_vm.isDownloadingZip["png"]
                                ? _c("svg-icon", {
                                    attrs: {
                                      size: 18,
                                      fill: "currentColor",
                                      name: "download"
                                    }
                                  })
                                : _c("svg-icon", {
                                    staticClass: "icon-spin",
                                    attrs: {
                                      size: 18,
                                      fill: "currentColor",
                                      name: "spin"
                                    }
                                  })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.svgAssets && _vm.svgAssets.length > 0
                ? _c(
                    "div",
                    { staticClass: "b flex items-center" },
                    [
                      _c("svg-icon", {
                        staticClass: "b-icon",
                        attrs: { size: 46, name: "image-svg" }
                      }),
                      _c("div", { staticClass: "flex flex-col" }, [
                        _c("div", { staticClass: "b-text" }, [
                          _vm._v(
                            _vm._s(_vm.svgAssets.length) +
                              " vector image" +
                              _vm._s(_vm.pngAssets.length > 1 ? "s" : "")
                          )
                        ]),
                        _c("div", { staticClass: "b-sub-text" }, [
                          _vm._v("SVG")
                        ])
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "download",
                          class: { visible: _vm.isDownloadingZip["svg"] },
                          attrs: { download: "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "download-link",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.downloadZip("svg")
                                }
                              }
                            },
                            [
                              !_vm.isDownloadingZip["svg"]
                                ? _c("svg-icon", {
                                    attrs: {
                                      size: 18,
                                      fill: "currentColor",
                                      name: "download"
                                    }
                                  })
                                : _c("svg-icon", {
                                    staticClass: "icon-spin",
                                    attrs: {
                                      size: 18,
                                      fill: "currentColor",
                                      name: "spin"
                                    }
                                  })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "ul",
              { staticClass: "assets-list" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Individual assets")
                ]),
                _c(
                  "virtual-list",
                  {
                    staticClass: "vv-list",
                    attrs: {
                      wclass: "v-list",
                      size: 68,
                      remain: _vm.getRemain,
                      bench: 0
                    }
                  },
                  _vm._l(_vm.currentComponentAssets, function(layer, idx) {
                    return _c(
                      "li",
                      { key: "" + layer.md5 + idx, staticClass: "asset" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center",
                            staticStyle: { flex: "1", "max-width": "260px" }
                          },
                          [
                            _c("div", {
                              staticClass: "asset-image",
                              style: _vm.getAssetBg(layer)
                            }),
                            _c("div", { staticClass: "text flex flex-col" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.getLayerName(layer)))
                              ]),
                              _c("div", { staticClass: "size" }, [
                                _vm._v(
                                  _vm._s(Math.round(layer.width)) +
                                    " px × " +
                                    _vm._s(Math.round(layer.height)) +
                                    " px"
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "download",
                            attrs: {
                              target: "_blank",
                              href: _vm.getAssetDownloadURL(layer),
                              download: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.trackAssetDonwnload(layer)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "download-link" },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    size: 18,
                                    fill: "currentColor",
                                    name: "download"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]),
      _vm.currentUser.is_admin
        ? _c(
            "div",
            {
              staticClass: "dev-container flex items-center justify-center",
              staticStyle: { padding: "20px" }
            },
            [
              _c("div", { staticClass: "dev" }, [_vm._v("Download")]),
              _c(
                "an-button",
                {
                  staticClass: "dev",
                  attrs: {
                    isWorking: _vm.isDownloadingZip["model"],
                    variant: "secondary",
                    size: "sm"
                  },
                  on: { click: _vm.DownloadModel }
                },
                [_vm._v("Model")]
              ),
              _c(
                "an-button",
                {
                  staticClass: "dev",
                  attrs: {
                    isWorking: _vm.isDownloadingZip["all"],
                    variant: "secondary",
                    size: "sm"
                  },
                  on: { click: _vm.DownloadAllAssets }
                },
                [_vm._v("Assets")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }