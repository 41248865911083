var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-empty-state" }, [
    _vm.type === "component"
      ? _c("div", { staticClass: "components-empty-state" }, [
          _vm._m(0),
          _c("div", { staticClass: "title component-title" }, [
            _vm._v("No components yet")
          ]),
          _vm._m(1)
        ])
      : _c("div", { staticClass: "sync-with-quick-import" }, [
          _c("div", { staticClass: "frame" }, [
            _c(
              "div",
              { staticClass: "section" },
              [
                _c("img", {
                  staticClass: "above-section",
                  attrs: {
                    src: require("@/assets/illustrations/design-tools-sync.svg")
                  }
                }),
                _c("h2", [_vm._v("Sync your design")]),
                _c("p", [
                  _vm._v(
                    "Use the Anima plugin to sync your design to your project."
                  )
                ]),
                _c("an-button", { on: { click: _vm.openPluginsModal } }, [
                  _vm._v("Install plugin")
                ])
              ],
              1
            ),
            _c("div", { staticClass: "divider" }),
            _vm.showFigmaUploadButton
              ? _c(
                  "div",
                  { staticClass: "section" },
                  [
                    _c("img", {
                      staticClass: "above-section",
                      attrs: {
                        src: require("@/assets/illustrations/sketch-figma-to-anima.svg")
                      }
                    }),
                    _c("h2", [_vm._v("Quick import")]),
                    _vm._m(2),
                    _c(
                      "PopoverMenu",
                      {
                        attrs: {
                          items: _vm.importOptions,
                          position: "right",
                          popperClass: "select-import-menu"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c(
                              "an-button",
                              { attrs: { variant: "secondary" } },
                              [
                                _vm._v(" Import "),
                                _c("svg-icon", {
                                  attrs: {
                                    fill: "currentColor",
                                    name: "small-arrow-down",
                                    size: 24
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "section" },
                  [
                    _c("img", {
                      staticClass: "above-section",
                      attrs: {
                        src: require("@/assets/illustrations/sketch-to-anima.svg")
                      }
                    }),
                    _c("h2", [_vm._v("Quick import")]),
                    _vm._m(3),
                    _c(
                      "an-button",
                      {
                        attrs: { variant: "secondary" },
                        on: {
                          click: function($event) {
                            return _vm.openImportModal("sketch")
                          }
                        }
                      },
                      [_vm._v("Import")]
                    )
                  ],
                  1
                )
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/illustrations/no-components.svg") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(
        " Components allow Anima to reuse React code across the project and make the code more consistent and clean. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " In code mode, select a component & add it to the components library. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Not a designer? "),
      _c("br"),
      _vm._v(" Upload a Sketch file for a quick start ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" Not a designer? "),
      _c("br"),
      _vm._v(" Upload a Sketch file for a quick start ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }