<template>
  <div class="overrides">
    <element-overrides v-show="selected" />
    <all-overrides v-show="!selected" />
  </div>
</template>

<script>
import ElemetOverrides from '@/components/OmniView/Code/ElemetOverrides.vue';
import OverridesList from '@/components/OmniView/Code/OverridesList';
import { mapGetters } from 'vuex';
export default {
  components: {
    'all-overrides': OverridesList,
    'element-overrides': ElemetOverrides
  },
  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode'
    }),
    selected() {
      return !!this.currentNode.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.overrides {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}
</style>
