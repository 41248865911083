var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view slideInLeft" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !(_vm.selected && _vm.activeView == "overrides"),
            expression: "!(selected && activeView == 'overrides')"
          }
        ],
        staticClass: "title-container"
      },
      [
        _c(
          "div",
          {
            staticClass: "tab-toggle",
            on: {
              click: function($event) {
                return _vm.handleTabChange("overrides")
              }
            }
          },
          [
            _c("span", [_vm._v("Overrides")]),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeView == "overrides",
                  expression: "activeView == 'overrides'"
                }
              ],
              staticClass: "active"
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "tab-toggle",
            on: {
              click: function($event) {
                return _vm.handleTabChange("assets")
              }
            }
          },
          [
            _c("span", [_vm._v("Assets")]),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeView == "assets",
                  expression: "activeView == 'assets'"
                }
              ],
              staticClass: "active"
            })
          ]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "body-wrapper",
        style: {
          paddingTop:
            _vm.selected && _vm.activeView == "overrides" ? "0" : "60px",
          marginBottom:
            _vm.selected && _vm.activeView == "overrides" ? "43px" : "0"
        }
      },
      [_c("keep-alive", [_c(_vm.activeView, { tag: "component" })], 1)],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }