<template>
  <div class="container">
    <div class="title">Account settings</div>
    <div class="subtitle">View and update your account details, profile and more.</div>
    <div class="body">
      <div class="account-sidebar">
        <UploadAvatar :user="user" :size="160" @success="onUploadProfilePictureSuccess" />
        <div class="name">{{ user.name }}</div>
        <div class="email">{{ user.email }}</div>
        <div class="counts">
          <div>
            <span class="number">{{ userTeams.length }}</span>
            <span class="entity">Teams</span>
          </div>
        </div>
        <span class="joined">Anima user since {{ joinDate }}</span>
      </div>
      <div class="account-body">
        <div class="navigation">
          <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
        </div>
        <component :is="component" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/date';
import Tabs from '@/components/Layout/Tabs/Tabs';
import Login from '@/components/Account/LoginDetails';
import EmailNotifications from '@/components/Account/EmailNotifications';
import Subscriptions from '@/components/Account/Subscriptions';
import UploadAvatar from '@/components/Account/UploadAvatar';

export default {
  name: 'account',
  data() {
    return {
      tabs: [
        { label: 'Login', component: Login, datacy: 'account-login' },
        { label: 'Email notifications', component: EmailNotifications, datacy: 'account-notifications' },
        { label: 'Subscriptions', component: Subscriptions, datacy: 'account-subscriptions' }
      ],
      activeTabLabel: 'Login',
      component: Login,
      loading: false
    };
  },
  mounted() {
    this.fetchNotificationsSettings({ parent: 'users', id: 'me', skipCache: true });
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({ userTeams: 'teamMemberships/userTeams' }),
    joinDate() {
      const { created_at } = this.user;
      return formatDate(created_at, 'MMMM YYYY');
    }
  },
  components: {
    Tabs,
    UploadAvatar
  },
  methods: {
    ...mapActions({
      fetchUser: 'users/fetchOne',
      fetchNotificationsSettings: 'notificationsSettings/fetchAllOfParent'
    }),
    onTabChange(tab) {
      this.activeTabLabel = tab.label;
      this.component = tab.component;
    },
    onUploadProfilePictureSuccess() {
      this.fetchUser({ id: 'me', skipCache: true });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_navigationBar.scss';
@import '@/styles/_mixins.scss';
.body {
  width: 960px;
  border-radius: 10px;
  display: flex;
  align-items: stretch;
  margin-top: 40px;
}
.account-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 610px;
  padding: 0 30px;
  border-radius: 10px;
  margin-right: 60px;
  box-shadow: var(--shadow);
  .name {
    @include secondary-title;
    margin-top: 30px;
    font-size: 22px;
  }
  .email {
    color: var(--secondary-text);
    margin: 6px 0 40px;
  }
  .counts {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: var(--border);
    border-bottom: var(--border);
    padding: 20px 0;
    width: 100%;
    .divider {
      width: 0px;
      height: 100%;
      border-right: var(--border);
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
    }
    div > .number {
      @include secondary-title;
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 6px;
    }
    div > .entity {
      color: grey;
    }
  }
  .joined {
    margin-top: 30px;
  }
}
.account-body {
  width: 500px;
  .navigation {
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }
}
</style>
