var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-card-container" }, [
    _c(
      "div",
      {
        staticClass: "component-card-background",
        on: {
          mouseover: function($event) {
            _vm.hoverThumb = true
          },
          mouseout: function($event) {
            _vm.hoverThumb = false
          },
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [
        _c(
          "div",
          { class: _vm.actionsClasses },
          [
            _c(
              "PopoverMenu",
              { attrs: { items: _vm.popoverActions, position: "right" } },
              [
                _c(
                  "div",
                  { attrs: { slot: "reference" }, slot: "reference" },
                  [
                    _c("svg-icon", {
                      staticStyle: { color: "#fff" },
                      attrs: {
                        fill: "currentColor",
                        name: "three-dots-horizontal",
                        size: 30
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _vm.cardWidth && _vm.cardHeight
          ? _c("WebComponentThumb", {
              attrs: {
                maxWidth: _vm.cardWidth,
                maxHeight: _vm.cardHeight,
                webComponent: _vm.component
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "component-name" }, [
      _vm._v(" " + _vm._s(_vm.component.name) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }