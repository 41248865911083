var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pricing-row",
      class: { selected: _vm.isCurrentPlan, disabled: _vm.disabled },
      on: { click: _vm.onClick }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "plan-type" }, [_vm._v(_vm._s(_vm.planType))]),
        _c("div", { staticClass: "price-container" }, [
          _c("div", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.formattedPrice))
          ]),
          _c("div", { staticClass: "per-amount" }, [
            _vm._v("/ mo per contributor")
          ])
        ])
      ]),
      _vm.showRibbon
        ? _c(
            "div",
            { staticClass: "ribbon" },
            [
              _c("svg-icon", {
                attrs: { name: "save-money-ribbon", width: 109, height: 100 }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }