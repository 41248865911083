var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["member-row", _vm.$theme],
      on: {
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c(
        "div",
        {
          class: ["details-container", { pending: _vm.isPending && !_vm.hover }]
        },
        [
          _c(
            "div",
            { staticClass: "avatar-placeholder" },
            [
              _vm.isPending
                ? _c("RoundedImage", {
                    attrs: {
                      width: 36,
                      height: 36,
                      circle: "",
                      dashedEmptyState: ""
                    }
                  })
                : _c("an-user-avatar", {
                    attrs: { user: _vm.user, theme: _vm.$theme }
                  })
            ],
            1
          ),
          _vm._v(" " + _vm._s(_vm.member.email) + " ")
        ]
      ),
      _vm.isPending
        ? _c(
            "div",
            { class: ["pending-actions", { pending: !_vm.hover }] },
            [
              _c("transition", { attrs: { name: "fadeIn", mode: "out-in" } }, [
                _vm.hover
                  ? _c(
                      "div",
                      [
                        _c(
                          "an-link",
                          {
                            staticClass: "remove-link",
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("remove", _vm.member)
                              }
                            }
                          },
                          [_vm._v("Remove")]
                        ),
                        _c(
                          "an-link",
                          {
                            attrs: { primaryHover: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("resend-invite", _vm.member)
                              }
                            }
                          },
                          [_vm._v("Resend")]
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v("Pending")])
              ])
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "cta",
              on: {
                mouseover: function($event) {
                  _vm.roleSelectHover = true
                },
                mouseleave: function($event) {
                  _vm.roleSelectHover = false
                }
              }
            },
            [
              _c("RoleSelect", {
                attrs: {
                  role: _vm.member.role,
                  accessLevel: _vm.member.access_level,
                  withAdmin: _vm.roleOptions.withAdmin,
                  withDelete: _vm.roleOptions.withDelete,
                  isDeleted: !_vm.roleSelectHover,
                  allowDeleting: _vm.isCurrentMember
                },
                on: {
                  "change-role": _vm.onSelectRole,
                  remove: function($event) {
                    return _vm.$emit("remove", _vm.member)
                  },
                  "un-remove": function($event) {
                    return _vm.$emit("un-remove", _vm.member)
                  }
                }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }