var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col code-pref-col" },
    [
      _c("div", { staticClass: "pref-col-title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._l(_vm.options, function(op) {
        return _c(
          "an-radio",
          {
            key: op.label,
            attrs: {
              full: "",
              spacingX: 6,
              spacingY: 2,
              type: "dot",
              label: op.value,
              value: _vm.value
            },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event)
              }
            }
          },
          [
            _c("span", { staticStyle: { "font-size": "12px" } }, [
              _vm._v(_vm._s(op.label))
            ]),
            op.badgeText
              ? _c("span", { staticClass: "soon badge" }, [
                  _vm._v(_vm._s(op.badgeText))
                ])
              : _vm._e()
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }