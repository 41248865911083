var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "omniview-container" },
    [
      !_vm.isMobile
        ? [
            _vm.notFoundError || _vm.componentNotFoundError
              ? _c(
                  "div",
                  { staticClass: "loader" },
                  [
                    _c("PageNotFound", {
                      attrs: {
                        resource: _vm.componentNotFoundError
                          ? "component"
                          : "project"
                      }
                    })
                  ],
                  1
                )
              : _vm.errors.major
              ? _c("div", { staticClass: "loader" }, [
                  _vm._v(" " + _vm._s(_vm.errors.major) + " ")
                ])
              : !_vm.ready
              ? _c(
                  "div",
                  { staticClass: "loader" },
                  [
                    _c("LoadingScreen", {
                      attrs: {
                        delay: false,
                        theme: "dark",
                        type:
                          _vm.currentProject && _vm.currentProject.is_syncing
                            ? "syncing"
                            : "default"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("transition", { attrs: { name: "fadeIn" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ready && !_vm.errors.major,
                      expression: "ready && !errors.major"
                    }
                  ],
                  staticClass: "wrapper"
                },
                [
                  _c("Header", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFullScreen && !_vm.showOnlyCode,
                        expression: "!isFullScreen && !showOnlyCode"
                      }
                    ],
                    on: { screenChange: _vm.reactToScreenChange }
                  }),
                  _c(
                    "main",
                    {
                      style: { paddingTop: _vm.getPaddingTop },
                      attrs: { id: "omniview-content" }
                    },
                    [
                      _vm.showComponentsSidebar
                        ? _c("ComponentsSidebar", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showOnlyCode,
                                expression: "!showOnlyCode"
                              }
                            ]
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          ref: "mainContent",
                          staticClass: "main-content flex flex-col",
                          style: {
                            height: "calc(100% - " + _vm.panelHeight + "px)",
                            paddingBottom: _vm.getPaddingBottom,
                            position: "relative"
                          }
                        },
                        [
                          _c("MainFrame", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showOnlyCode,
                                expression: "!showOnlyCode"
                              }
                            ]
                          }),
                          _c("ScreenNavArrow", {
                            attrs: {
                              side: "right",
                              show: _vm.showNavigationArrows
                            },
                            on: { click: _vm.reactToScreenChange }
                          }),
                          _c("ScreenNavArrow", {
                            attrs: {
                              side: "left",
                              show: _vm.showNavigationArrows
                            },
                            on: { click: _vm.reactToScreenChange }
                          }),
                          _c("Panels", {
                            style: Object.assign(
                              {},
                              { width: _vm.mainContentWidth + "px" },
                              !_vm.showPanel ? { maxHeight: 0 } : {}
                            ),
                            on: {
                              generateCode: _vm.generateCode,
                              toggle: _vm.togglePanel
                            }
                          })
                        ],
                        1
                      ),
                      _c("Sidebar", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showOnlyCode,
                            expression: "!showOnlyCode"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        : [
            _vm.notFoundError || _vm.componentNotFoundError
              ? _c(
                  "div",
                  { staticClass: "loader" },
                  [
                    _c("PageNotFound", {
                      attrs: {
                        resource: _vm.componentNotFoundError
                          ? "component"
                          : "project"
                      }
                    })
                  ],
                  1
                )
              : _c("transition", { attrs: { name: "fade" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ready && !_vm.errors.major,
                          expression: "ready && !errors.major"
                        }
                      ],
                      staticClass: "wrapper"
                    },
                    [
                      _c("Header", {
                        on: { screenChange: _vm.reactToScreenChange }
                      }),
                      _c(
                        "main",
                        {
                          style: { paddingTop: _vm.getPaddingTop },
                          attrs: { id: "omniview-content" }
                        },
                        [
                          _c(
                            "div",
                            {
                              ref: "mainContent",
                              staticClass: "main-content",
                              style: {
                                height: "100%",
                                paddingBottom: _vm.getPaddingBottom
                              }
                            },
                            [_c("MainFrame")],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }