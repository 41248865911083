<template>
  <div class="sync-container">
    <div v-if="!ready" class="loader">
      <LoadingScreen
        :delay="false"
        :type="currentRelease.is_code_generation_done ? 'default' : 'syncing'"
        theme="dark"
      />
    </div>
    <transition name="fade">
      <div class="wrapper" v-show="ready">
        <!-- HEADER -->
        <!-- <SyncHeader v-show="!isFullScreen && !showOnlyCode" @screenChange="reactToScreenChange" /> -->
        <!-- <Header @screenChange="reactToScreenChange" /> -->
        <main ref="mainContent" id="sync-content">
          <div class="main-content">
            <div class="main-scroll">
              <iframe
                :class="{ centerIframe: centerIframe }"
                :style="{
                  width: this.currentFrameWidth + 'px',
                  height: '100%'
                }"
                :src="getPreviewURL"
              ></iframe>
            </div>
            <div class="box">
              <img class="logo" :src="require(`@/assets/svg/anima-logo-text.svg`)" />
              <div class="buttons">
                <div class="mainButton">
                  <an-button variant="primary" @click="connectToDomain">Connect to domain</an-button>
                </div>
                <!-- <an-button variant="secondary">Download code</an-button> -->
              </div>
            </div>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { uuid } from '@/utils/uuid';

import LoadingScreen from '@/components/Loading/LoadingScreen';
import axios from 'axios';
import { has } from 'lodash-es';

import { isSameRoute, poll } from '@/utils/javascript';
import codegenMixin from '@/components/OmniView/codegenMixin';
import panelMixin from '@/components/OmniView/panelMixin';
import { SocketMixin } from '@/mixins';
import { EventBus } from '@/services/bus';
import { isEmpty } from 'lodash-es';

import { CLOSE_PANEL, SELECT_OVERRIDE_NODE, SEND_MESSAGE, HIGHLIGHT_COMMENT } from '@/utils/events/omniviewEvents';
let loadIframe = false;
export default {
  mixins: [codegenMixin, panelMixin, SocketMixin],
  components: {
    LoadingScreen
  },
  data() {
    return {
      loading: {
        iframe: true,
        breakpoints: true,
        fetching: true,
        model: false
      },
      centerIframe: false,
      masterSlug: ''
    };
  },
  computed: {
    ...mapState('components', { currentComponents: 'items' }),
    ...mapState('omniview', { isChromeExtension: 'isChromeExtension' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({
      activeMode: 'omniview/activeMode',
      activeBreakpoint: 'omniview/activeBreakpoint',
      breakpoints: 'omniview/breakpoint',
      userTeams: 'teamMemberships/userTeams',
      currentNode: 'omniview/currentNode',
      currentNodeModel: 'omniview/currentNodeModel',
      domLoading: 'omniview/domLoading',
      currentFrameWidth: 'omniview/currentFrameWidth',
      currentFrameHeight: 'omniview/currentFrameHeight',
      currentMasterSlug: 'omniview/currentMasterSlug',
      modelNodesMap: 'omniview/modelNodesMap',
      slugsMap: 'omniview/slugsMap'
    }),
    getPreviewURL() {
      if (this.currentRelease?.is_code_generation_failed == true) {
        this.$trackEvent('omniview.code-generation.failure', { release_short_id: this.currentRelease?.short_id });
        return 'https://animaapp.s3.amazonaws.com/static/error-pages/anima4-error.html';
      }

      // prevent invalid url when loading
      const { releaseShortId, screenSlug } = this.$route.params;
      if (!this.ready || isEmpty(this.currentProject) || (isEmpty(this.currentComponent) && !releaseShortId)) return '';

      const { subdomain, is_restart_button_enabled } = this.currentProject || {};
      const { master_slug } = this.currentComponent;
      const domain = process.env.NODE_ENV === 'staging' ? 'staging-animaapp.io' : 'animaapp.io';
      let protocol = 'https://';

      let url = `${subdomain}.${domain}`;

      if (releaseShortId) {
        // url = `${url}/preview/${releaseShortId}/${screenSlug}`;
        url = `${url}/${screenSlug}`;
      } else if (master_slug) {
        url = `${url}/${master_slug}`;
      }
      url += '?handoff=' + Math.floor(Math.random() * 100) + 1;
      if (is_restart_button_enabled) {
        url += '&r=1';
      }
      return `${protocol}${url}`;
    },
    getPaddingTop() {
      if (this.isFullScreen) return 0;
      const p = this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)';
      return p;
    },
    ready() {
      return !this.loading.fetching && this.currentProject && !this.currentProject.is_syncing; //&& this.currentProject.is_preview_ready;
    },
    showNavigationArrows() {
      return this.currentComponents?.length > 1;
    }
  },
  created() {
    if (this.user.id) {
      if (this.$hj) {
        this.$hj('identify', this.user.id, {
          email: this.user.email
        });
      }
      if (this.$FullStory) {
        this.$FullStory.identify(this.user.id, {
          email: this.user.email
        });
      }
    }
  },
  async mounted() {
    await this.fetchAllUserMemberships({ id: 'me', skipCache: true });

    window.addEventListener('resize', this.calculateScreenSize);
    this.selectScreen({});
    this.checkIfSyncAllowed();
    window.addEventListener('message', this.messageListener, false);
    this.$trackEvent('sync-preview.sync.website.view');

    this.loadData();
    // this.loading.fetching = false;

    // let layerId = get(this.$route, 'query.layer', false);
    // let fileId = get(this.$route, 'query.fileId', false);

    // if (fileId && layerId) {
    //   this.setIsGeneratingCode(true);
    //   this.generateCodeWithoutIframe({ nodeId: layerId, fileId });
    // }

    this.$trackEvent('sync-preview.preview.view');

    this.$tracking.setUserEmail(this.user.email);
    this.$tracking.setUserId(this.user.id);
    this.$tracking.setContext({ latest_paired_design_tool: this.user.latest_paired_design_tool });
    this.$tracking.alias(this.user.id);
    // let modeCode = this.modes.find(item => {
    //   return item.name === 'C';
    // });
    // this.handleModeChange({
    //   mode: modeCode,
    //   fromRoute: false
    // });
  },
  destroyed() {
    window.removeEventListener('message', this.messageListener, false);
  },
  methods: {
    ...mapMutations({
      setCurrentComponents: 'components/setComponents',
      selectScreen: 'components/setCurrentItem',
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      setBreakpoints: 'omniview/setBreakpoints',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setShowOnlyCode: 'omniview/setShowOnlyCode',
      setCurrentFrameWidth: 'omniview/setCurrentFrameWidth',
      setCurrentFrameHeight: 'omniview/setCurrentFrameHeight',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setDomLoading: 'omniview/setDomLoading',
      setIframeLoading: 'omniview/setIframeLoading',
      setCurrentMasterSlug: 'omniview/setCurrentMasterSlug',
      setIsSyncAllowed: 'omniview/setIsSyncAllowed',
      setSlugsMap: 'omniview/setSlugsMap'
    }),
    ...mapActions({
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchProject: 'projects/fetchOne',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchRelease: 'releases/fetchOne',
      fetchComponents: 'components/fetchAllOfParent',
      fetchReleaseModel: 'releases/fetchReleaseModel',
      fetchModelFromUrl: 'releases/fetchModelFromUrl'
    }),
    connectToDomain() {
      let url;

      let team_slug = this.currentProject.team_slug;
      let team = this.userTeams.find(team => {
        return team.slug === team_slug;
      });
      let pro = team && team.plan !== 'Free' ? true : false;

      if (pro) {
        const { releaseShortId } = this.$route.params;
        url = `/team/${team_slug}/project/${releaseShortId}/settings/public-link`;
      } else {
        let teamSlug = this.userTeams && this.userTeams[0] ? this.userTeams[0].slug : '';
        this.$router.push({ name: 'payment', params: { teamSlug }, query: { plan: 'basic', interval: 'monthly' } });
        // url = `/team/${team_slug}/pricing`;
      }

      this.$router.push(url);
    },
    messageListener(e) {
      if (e && e.data && e.data.sender === 'fullScreen') {
        //if the chrome extension wants to change the full screen
        let showOnlyCode = e.data.message.showOnlyCode;
        this.setShowOnlyCode(showOnlyCode);
        return;
      } else if (e && e.data && e.data.sender === 'selectLayer' && e.data.message && e.data.message.layerId) {
        //the chrome extension wants to change the layerId
        let layerId = e.data.message.layerId;
        let fileId = e.data.message.fileId;
        let figma_access = e.data.message.figma_access;

        if (loadIframe) {
          this.checkForNodeInRoute(this.activeMode, layerId);
        } else {
          this.generateCodeWithoutIframe({ nodeId: layerId, fileId, figma_access });
        }
        return;
      } else if (e && e.data === 'iframe-404-load') {
        this.$trackEvent('omniview.preview.not-found', { url: window.location.href });
      }

      if (!e.data.action) return;
      const data = e.data.data;

      switch (e.data.action) {
        case 'dom-load':
          console.log('DOM-load');
          this.setDomLoading(false);

          if (this.isReleaseOmni) {
            if (this.firstLoad) {
              this.checkForModeInRoute();
            } else {
              this.handleModeChange({
                mode: this.mappedRouteToMode,
                fromRoute: false
              });
            }
          }

          this.firstLoad = false;
          break;

        case 'edit-node':
          {
            let newRoute = { ...this.$route, query: { ...this.$route.query, layer: data.id } };
            if (isSameRoute(this.$route, newRoute)) {
              this.handleCodeModeNodeMessage(data);
            } else {
              this.$router
                .replace(newRoute)
                .then(() => {
                  this.handleCodeModeNodeMessage(data);
                })
                .catch(e => {
                  console.log(e);
                });
            }
          }
          break;

        case 'escape-clicked':
          this.handleEscapeClicked();
          break;

        case 'multi-selection-start':
          EventBus.$emit(CLOSE_PANEL, { forceClose: true });
          break;
        case 'multi-selection-update':
          if (data.lowestCommonParentId) {
            this.setMultiSelectedNodes(data.nodes.map(o => o.node));
            EventBus.$emit(SEND_MESSAGE, {
              action: SELECT_OVERRIDE_NODE,
              data: {
                nodeId: data.lowestCommonParentId,
                metadata: {
                  source: 'client',
                  scrollIntoView: false
                }
              }
            });
          }

          break;

        default:
          break;
      }
    },
    checkIfSyncAllowed() {
      const { projectShortId } = this.$route.params;
      axios.get(`/rpc/sync/allowed?project_id=${projectShortId}`).then(({ data }) => {
        this.setIsSyncAllowed(data?.result || false);
      });
    },
    checkForNodeInRoute(mode, layerId) {
      // mode here is an object
      let { layer } = '';
      if (layerId) {
        layer = layerId;
      }
      if (!layer) return;
      switch (mode.name) {
        case 'In':
          // no layers in play mode
          break;
        case 'Co':
          // TODO: implement me
          EventBus.$emit(SEND_MESSAGE, {
            action: HIGHLIGHT_COMMENT,
            data: {
              nodeId: layer,
              metadata: {
                source: 'route'
              }
            }
          });
          break;
        case 'C':
          EventBus.$emit(SEND_MESSAGE, {
            action: SELECT_OVERRIDE_NODE,
            data: {
              nodeId: layer,
              metadata: {
                source: 'route'
              }
            }
          });
          break;
        default:
          break;
      }
    },
    async loadData() {
      try {
        this.loading.fetching = true;
        const { projectShortId, releaseShortId } = this.$route.params;
        await this.fetchProjectAndReleases(projectShortId, releaseShortId);

        await this.getBreakpointsFromModel();

        this.loading.fetching = false;
      } catch (error) {
        console.log(error);
        this.loading.fetching = false;
      }
    },
    reactToScreenChange({ fetchBreakpoints }) {
      if (fetchBreakpoints) {
        this.getBreakpointsFromModel(false);
      }
    },
    async fetchProjectUntilStopSyncing(data) {
      await this.fetchProject(data);
      if (this.currentProject.is_syncing /*|| !this.currentProject.is_preview_ready*/) {
        setTimeout(() => {
          this.fetchProjectUntilStopSyncing(data);
        }, 2000);
      }
    },
    async fetchProjectAndReleases(projectShortId, releaseShortId) {
      await this.fetchProjectUntilStopSyncing({ id: projectShortId, skipCache: true });

      await poll({
        fn: () => this.fetchRelease({ id: releaseShortId, skipCache: true }),
        validate: release => {
          return release && release.is_code_generation_done;
        },
        interval: 500,
        maxAttempts: 90
      });
      // this.fetchReleaseModel();
    },

    async slugifyScreensNames(text) {
      if (text.every(x => has(this.slugsMap, x))) return;

      let map = {};

      const { data } = await axios.post('rpc/slugify', {
        text
      });

      let slugs = data.result;
      for (let i = 0; i < text.length; i++) {
        map[text[i]] = slugs[i];
      }
      this.setSlugsMap(map);
    },

    getMasterSlug(model = {}, currentScreen) {
      const similarScreenIds = model.similarScreenIds || [];
      const modelScreens = model.screens || [];
      const regex = new RegExp('screen[0-9]+');
      let masterSlug = '';
      for (let i = 0; i < similarScreenIds.length; i++) {
        const ids = similarScreenIds[i];
        if (ids.includes(currentScreen.modelID)) {
          for (let j = 0; j < ids.length; j++) {
            let id = ids[j];

            const screen = modelScreens.find(s => s.modelID == id);
            if (!screen) continue;

            if (!masterSlug || masterSlug.length > this.slugsMap[screen.variableID] || regex.test(masterSlug)) {
              masterSlug = this.slugsMap[screen.variableID];
            }
          }
          break;
        }
      }

      if (!masterSlug) {
        masterSlug = this.slugsMap[currentScreen.variableID];
      }

      if (this.currentMasterSlug && masterSlug != this.currentMasterSlug) {
        this.setDomLoading(true);
        this.setIframeLoading(true);
      }
      this.setCurrentMasterSlug(masterSlug);

      return masterSlug;
    },
    calculateScreenSize() {
      let screens = this.screens;
      if (!screens) {
        return;
      }

      let screenSlug =
        this.$route && this.$route.params && this.$route.params.screenSlug ? this.$route.params.screenSlug : '';
      let findCurrentScreen = this.screens.find(
        screen =>
          typeof screen.viewName === 'string' &&
          (screen.viewName.toLowerCase() === screenSlug ||
            screen.viewName.toLowerCase() === screenSlug.replaceAll('-', ' '))
      );
      let screenWidth = window.innerWidth;
      let newScreenSize;

      if (findCurrentScreen) {
        newScreenSize = findCurrentScreen.width;
      } else {
        console.log(findCurrentScreen);
        let smallestScreen = false;

        screens.forEach(screen => {
          //find the smallest screen
          if (screen.width < smallestScreen || !smallestScreen) {
            smallestScreen = screen.width;
          }
        });

        newScreenSize = smallestScreen;

        screens.forEach(screen => {
          if (screen.width <= screenWidth && screen.width > newScreenSize) {
            newScreenSize = screen.width;
          }
        });
      }

      if (newScreenSize < screenWidth) {
        this.centerIframe = true;
      } else {
        this.centerIframe = false;
      }

      this.setCurrentFrameWidth(newScreenSize);
    },
    async getBreakpointsFromModel() {
      try {
        this.loading.breakpoints = true;
        this.setBreakpoints([]);
        let model,
          currentScreen = null;
        let isProgressiveUpload = false;
        if (this.currentRelease.screens_metadata) {
          isProgressiveUpload = (this.currentRelease.screens_metadata || []).length > 0;
        }

        if (isProgressiveUpload) {
          await this.slugifyScreensNames((this.currentRelease.screens_metadata || []).map(s => s.variableID));
          const { screenSlug } = this.$route.params;
          currentScreen = this.currentRelease.screens_metadata.find(s => s.variableID == screenSlug);
          model = this.currentRelease;
          model.screens = model.screens_metadata;
          model.similarScreenIds = model.similar_screen_ids;
        } else {
          let { model: modelTemp, currentScreen: currentScreenTemp } = await this.getModelAndScreen({ slim: true });
          model = modelTemp;
          currentScreen = currentScreenTemp;

          // this.setCurrentFrameWidth(currentScreen.width, currentScreen.height)
        }

        if (model) {
          this.screens = model.screens;
          this.calculateScreenSize();
        }

        this.setCurrentComponents(model.screens || []);
        this.selectScreen(currentScreen || {});

        this.masterSlug = this.getMasterSlug(model, currentScreen);

        const sizes = [];
        let currentSizeIndex = -1;
        let currentSize = -1;
        for (let i = 0; i < (model.similarScreenIds || []).length; i++) {
          const ids = model.similarScreenIds[i];
          if (ids.includes(currentScreen.modelID)) {
            for (let j = 0; j < ids.length; j++) {
              const id = ids[j];
              if (id == currentScreen.modelID) {
                currentSizeIndex = j;
              }
              const foundScreen = model.screens.find(s => s.modelID == id);

              sizes.push({ width: foundScreen.width, component: foundScreen });
            }
            break;
          }
        }

        const sizesOnly = sizes.map(s => s.width);

        if (currentSizeIndex != -1) {
          currentSize = sizesOnly[currentSizeIndex];
        }

        sizes.sort((a, b) => (a.width < b.width ? 1 : -1));

        let breakpoints = sizes.map(({ width, component }) => ({ id: uuid(), width, component }));

        if (breakpoints.length == 0) {
          breakpoints = [{ id: uuid(), width: currentScreen.width, component: this.currentComponent }];
        }

        this.setBreakpoints(breakpoints);

        if (currentSize == -1) {
          this.setActiveBreakpoint(breakpoints[0]);
        } else {
          this.setActiveBreakpoint(breakpoints.find(br => br.width == currentSize));
        }

        this.loading.breakpoints = false;
      } catch (error) {
        this.loading.breakpoints = false;
        this.setBreakpoints([]);
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sync-container {
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  width: 100vw;
}
.centerIframe {
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}
#sync-content {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.main-content {
  position: relative;
  flex: 1;
  // overflow-x: auto;
  // overflow-y: hidden;
}
.main-scroll {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--secondary);
  // background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.box {
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 10px rgb(100, 100, 100);
  background: var(--secondary);
  padding: 20px 50px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 50%;
}
.logo {
  width: 140px;
  margin-bottom: 20px;
}
.buttons {
  display: flex;
}
.mainButton {
  // margin-right:20px;
}
</style>
