var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "plusIcon",
      class: [
        "clickable-icon",
        _vm.$theme,
        { focus: _vm.focus, submitted: _vm.submitted }
      ],
      on: {
        mouseover: function($event) {
          _vm.hoverIcon = true
        },
        mouseout: function($event) {
          _vm.hoverIcon = false
        },
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.submitted
        ? _c("Lottie", {
            attrs: {
              fileName: "circle-check",
              autoplay: "",
              height: 44,
              width: 44
            }
          })
        : _c("svg-icon", { attrs: { name: _vm.plusIconName, size: 36 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }