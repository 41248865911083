import { createStoreModule } from '../utils';
import router from '@/router';
import dayjs from 'dayjs';
import Vue from 'vue';

export default createStoreModule({
  name: 'tracking',
  state: {
    extraTrackingData: {}
  },
  mutations: {
    setExtraData: (state, extraData) => (state.extraTrackingData = extraData)
  },
  getters: {
    extraData: state => state.extraTrackingData || {}
  },
  actions: {
    trackPageView({ rootState }, toRoute) {
      const isDesktop = rootState.screenSize.isDesktop;
      const screenWidth = window?.innerWidth;
      const { name, params, query } = toRoute;
      Vue.prototype.$trackEvent('webapp.page.view', { ...params, ...query, isDesktop, screenWidth, name });
    },
    trackIdleTime({ rootState }, { startTime }) {
      const isDesktop = rootState.screenSize.isDesktop;
      const screenWidth = window?.innerWidth;
      const { name } = router.currentRoute;
      const timeInPage = dayjs().diff(startTime, 'second');
      Vue.prototype.$trackEvent('webapp.page.idle', { timeInPage, isDesktop, screenWidth, name });
    },
    trackIntercomShow() {
      Vue.prototype.$trackEvent('webapp.intercom.show');
    }
  }
});
