var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page-container" },
    [
      _c("OnboardingTopBar", {
        attrs: { homepageUrl: "https://animaapp.com" }
      }),
      _c(
        "div",
        { staticClass: "login-container" },
        [
          _vm.googleLoading
            ? _c("LoadingScreen")
            : _c("div", { staticClass: "form-container" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Log in to Anima")
                ]),
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _vm._v(" Don't have an account yet? "),
                    _c(
                      "an-link",
                      {
                        attrs: { variant: "primary", "data-cy": "signup" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "signup",
                              query: _vm.$route.query
                            })
                          }
                        }
                      },
                      [_vm._v(" Sign up for free ")]
                    )
                  ],
                  1
                ),
                _vm.showGoogleLogin
                  ? _c(
                      "div",
                      { staticClass: "social-login" },
                      [
                        _c(
                          "GoogleButton",
                          {
                            on: {
                              click: _vm.onGoogleLoginClick,
                              success: _vm.onGoogleLoginSuccess,
                              failure: _vm.onGoogleLoginFailure
                            }
                          },
                          [_vm._v(" Log in with Google ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showGoogleLogin
                  ? _c(
                      "div",
                      { staticClass: "text-divider" },
                      [
                        _c("Divider", {
                          attrs: { text: "Or", theme: _vm.theme }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.login.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "property" }, [
                      _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c("an-input", {
                            attrs: {
                              id: "email",
                              type: "email",
                              theme: _vm.theme,
                              placeholder: "name@work-email.com",
                              "data-cy": "login-email"
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "property" },
                      [
                        _c("an-input", {
                          attrs: {
                            id: "pass",
                            type: "password",
                            theme: _vm.theme,
                            placeholder: "Password",
                            "data-cy": "login-password"
                          },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "forgot-password" },
                      [
                        _c(
                          "an-link",
                          {
                            attrs: { theme: _vm.theme, primaryHover: "" },
                            on: { click: _vm.forgotPassword }
                          },
                          [_vm._v(" Forgot password? ")]
                        )
                      ],
                      1
                    ),
                    _vm.errorMessage
                      ? _c(
                          "div",
                          { staticClass: "invalid-message" },
                          [
                            _c("svg-icon", {
                              attrs: { name: "info", fill: "var(--red)" }
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.errorMessage))])
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "an-button",
                          {
                            staticClass: "justify-center",
                            attrs: {
                              type: "submit",
                              isWorking: _vm.loading,
                              size: "xl",
                              "data-cy": "login-submit"
                            }
                          },
                          [_vm._v(" Log in ")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
          !_vm.googleLoading
            ? _c("div", { staticClass: "login-illustration" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }