<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/ashamed-man.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="body">
      <an-input
        data-cy="create-project-input"
        v-model="projectName"
        placeholder="Name your new project"
        @keyup.enter="create"
        focus
      />
    </div>
    <div class="actions" v-if="projectName">
      <an-button data-cy="create-project-confirm" @click="create" :isWorking="isWorking">Create project</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { EventBus, toastError } from '@/services/bus';
import { mapActions, mapState } from 'vuex';
import { TeamMixin } from '@/mixins';

export default {
  name: 'new-project',
  data() {
    return {
      title: 'Create a new project',
      projectName: '',
      isWorking: false
    };
  },
  mixins: [TeamMixin],
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { totalProjects: 'total' }),
    ctaData() {
      return {
        label: 'Create',
        loading: this.isWorking
      };
    }
  },
  methods: {
    ...mapActions({
      createProject: 'projects/create',
      reloadProjects: 'projects/fetchAllOfParent'
    }),
    async create() {
      const { id, projects_limit_count: projectsLimitCount } = this.team;
      const payload = { name: this.projectName };
      const hasProjectsLimit = projectsLimitCount !== -1;

      if (!this.projectName || !this.projectName.trim()) {
        return toastError('Please provide a name');
      }

      this.$trackEvent('new-project.save-button.click');

      try {
        this.isWorking = true;
        const { short_id: projectId } = await this.createProject({ parent: 'teams', id, payload, storeResult: true });

        EventBus.$emit('update-projects', { skipCache: true });
        EventBus.$emit('reload-team-info');

        this.$trackEvent('new-project.save.success');

        if (hasProjectsLimit && this.totalProjects >= projectsLimitCount) {
          this.$emit('close');
          this.openUpgradeLockedModal();
          this.$trackEvent('new-project.locked-project-paywall.show');
        } else {
          this.$emit('close', { redirect: { name: 'project', params: { projectId } } });
        }
      } catch (err) {
        this.$trackEvent('new-project.save.failure', { message: err.message });
        toastError('Failed creating new project');
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.body {
  width: 400px;
}
</style>
