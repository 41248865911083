<template>
  <div :class="['toast', $theme]">
    <div class="toast-content">
      <div class="toast-icon"><svg-icon :name="icons[variant]" :size="20" /></div>
      <div class="toast-description">
        {{ content }}
      </div>
    </div>
    <div class="clickable-icon close-icon" @click="$emit('close')">
      <svg-icon name="close" :size="15" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    variant: {
      type: String,
      default: 'success',
      validator: value => {
        return ['success', 'fail', 'warn'].indexOf(value) !== -1;
      }
    },
    content: {
      type: String
    }
  },
  data() {
    return {
      icons: {
        success: 'circle-check',
        warn: 'info',
        fail: 'circle-x'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 50000;
  padding: 15px 24px;
  border-radius: 100px;
  box-shadow: var(--shadow);
  animation: 5s slide-and-leave ease-in forwards;
  overflow: hidden;
  background: var(--white);
  margin: 0 auto;

  &.dark {
    background: var(--secondary);
  }

  &-content {
    display: flex;
    align-items: center;
  }

  &-description {
    margin: 0 12px;
  }

  .close-icon {
    justify-self: flex-end;
    width: 24px;
    height: 24px;
  }
}
</style>
