var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "styleguide-container flex flex-col",
      class: [{ "items-center justify-center": _vm.isLoading }]
    },
    [
      _vm.isLoading ? _c("default-loader", { style: { margin: 0 } }) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && _vm.currentStylesheet,
              expression: "!isLoading && currentStylesheet"
            }
          ],
          ref: "st",
          staticClass: "stylesheet",
          attrs: { id: "st" },
          on: { mouseleave: _vm.resetPos, copy: _vm.handleManualCopyEvent }
        },
        [
          _c(
            "Splitpanes",
            [
              _c(
                "Pane",
                { staticClass: "flex flex-col", attrs: { "min-size": "25" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col",
                      style: {
                        position: "relative",
                        flex: 1,
                        overflow: "hidden"
                      }
                    },
                    [
                      _c("svg-icon", {
                        directives: [
                          {
                            name: "tip",
                            rawName: "v-tip",
                            value: "Copy",
                            expression: "'Copy'"
                          }
                        ],
                        staticClass: "copy-icon",
                        attrs: {
                          name: "copy-alt",
                          size: 24,
                          fill: "currentColor"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.copyStyleguide("token")
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "code-scroller",
                          style: {
                            flex: 1,
                            width: "100%",
                            padding: "20px 0 40px 0",
                            overflowY: "auto"
                          }
                        },
                        [
                          _c("Prism", {
                            ref: "prismcss",
                            staticClass: "styleguidePrism",
                            attrs: {
                              plugins: ["inline-color"],
                              mode: "styleguide",
                              language: _vm.codegenStylesheetLang,
                              type: "token",
                              code: _vm.currentStylesheet.tokensSheet
                            },
                            on: {
                              update: _vm.handleUpdate,
                              cancel: _vm.handleCancel
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "Pane",
                {
                  staticClass: "flex flex-col",
                  staticStyle: { position: "relative" },
                  attrs: { "min-size": "25" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col",
                      style: {
                        position: "relative",
                        flex: 1,
                        overflow: "hidden"
                      }
                    },
                    [
                      _c("svg-icon", {
                        directives: [
                          {
                            name: "tip",
                            rawName: "v-tip",
                            value: "Copy",
                            expression: "'Copy'"
                          }
                        ],
                        staticClass: "copy-icon",
                        attrs: {
                          name: "copy-alt",
                          size: 24,
                          fill: "currentColor"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.copyStyleguide("class")
                          }
                        }
                      }),
                      _c(
                        "Popover",
                        {
                          ref: "inspectPopoverRef",
                          style: {
                            overflow: "hidden",
                            padding: "0",
                            width: "348px",
                            top: _vm.top + 40 + "px",
                            transform: "translateX(-50%)",
                            left: "calc(" + (_vm.width + 35) + "px)",
                            right: "unset"
                          },
                          attrs: {
                            theme: "dark",
                            transitionName: "fadeIn",
                            isOpen: _vm.isOpen,
                            id: "class-preview-popover",
                            refId: _vm.refId
                          },
                          on: {
                            close: function($event) {
                              _vm.isOpen = false
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex flex-col class-preview",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center header" },
                                [
                                  _c("span", { staticClass: "truncate" }, [
                                    _c("span", { staticClass: "classname" }, [
                                      _vm._v(
                                        _vm._s(_vm.currentClassData.className)
                                      )
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v("  is used by")
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "ul",
                                { staticClass: "pages-list flex flex-col" },
                                _vm._l(_vm.selectedClassPageSlugs, function(
                                  slug,
                                  i
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      key: i,
                                      staticClass:
                                        "page-item flex items-center truncate",
                                      on: {
                                        click: function($event) {
                                          return _vm.highlightClass(slug)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "truncate" }, [
                                        _vm._v(_vm._s(slug))
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "auto",
                                            "flex-shrink": "0",
                                            "padding-left": "5px"
                                          }
                                        },
                                        [
                                          _vm.$route.params.screenSlug == slug
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    opacity: "0.5",
                                                    "flex-shrink": "0"
                                                  }
                                                },
                                                [_vm._v("Current page")]
                                              )
                                            : _c("svg-icon", {
                                                attrs: {
                                                  name: "arrow-right",
                                                  size: 18,
                                                  fill: "currentColor"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          ref: "rediush",
                          staticClass: "redish",
                          style: { left: _vm.width + "px" },
                          attrs: { "class-block-actions": "true" }
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon",
                            attrs: {
                              name: "copy-alt",
                              size: 24,
                              fill: "currentColor"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.copyClassBlock.apply(null, arguments)
                              }
                            }
                          }),
                          _c("svg-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedClassPageSlugs.length > 0,
                                expression: "selectedClassPageSlugs.length > 0"
                              }
                            ],
                            staticClass: "icon",
                            attrs: {
                              id: _vm.refId,
                              name: "inspect",
                              size: 24,
                              fill: "currentColor"
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClassPreviewClick.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "classesRef",
                          staticClass: "code-scroller",
                          style: {
                            flex: 1,
                            width: "100%",
                            padding: "20px 0 40px 0",
                            overflowY: "auto"
                          },
                          on: { scroll: _vm.handleScroll }
                        },
                        [
                          _c("Prism", {
                            ref: "prismcss",
                            staticClass: "styleguidePrism",
                            attrs: {
                              plugins: ["inline-color"],
                              mode: "styleguide",
                              type: "class",
                              language: _vm.codegenStylesheetLang,
                              id: "styleguideClasses",
                              code: _vm.currentStylesheet.classesSheet
                            },
                            on: {
                              update: _vm.handleUpdate,
                              cancel: _vm.handleCancel,
                              classBlockMouseover: _vm.classBlockMouseover,
                              classBlockMousleave: _vm.classBlockMousleave,
                              onWidthUpdate: _vm.onWidthUpdate
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.isLoading && !_vm.currentStylesheet
        ? _c(
            "div",
            {
              staticClass: "w-full h-full flex ",
              staticStyle: { color: "white", padding: "20px" }
            },
            [
              _vm._v(
                " Sorry, the styleguide could not be loaded, please refresh the page. "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }