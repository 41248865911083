var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse", class: { separate: _vm.isOpen } },
    [
      _c(
        "div",
        { staticClass: "collapse-header", on: { click: _vm.toggleOpen } },
        [
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _c("svg-icon", {
            class: { icon: true, isOpen: _vm.isOpen },
            attrs: { name: "select-arrow-down", size: 30 }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "collapse" } }, [
        _vm.isOpen
          ? _c(
              "div",
              { staticStyle: { "max-height": "2000px" } },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }