var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar-container" }, [
    _c("div", { staticClass: "left-hand" }, [
      _vm.isMobile && _vm.showBurger
        ? _c(
            "div",
            {
              staticClass: "burger",
              on: {
                click: function($event) {
                  return _vm.$emit("open-sidebar")
                }
              }
            },
            [_c("svg-icon", { attrs: { name: "mobile-burger", size: 30 } })],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "center" }, [
      _vm.isMobile
        ? _c("div", { staticClass: "logo" }, [_c("an-logo")], 1)
        : _vm._e()
    ]),
    _c("div", { staticClass: "right-hand" }, [
      _vm.isDesktop
        ? _c(
            "div",
            { staticClass: "top-bar-item" },
            [
              _vm.showUpgrade
                ? _c(
                    "an-link",
                    {
                      staticClass: "thick-af",
                      attrs: { variant: "primary" },
                      on: { click: _vm.upgrade }
                    },
                    [_vm._v(" Upgrade ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "top-bar-item" }),
      _c(
        "div",
        { staticClass: "top-bar-item" },
        [_c("AnnouncementsPopover", { attrs: { iconColor: _vm.iconColor } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDesktop,
              expression: "isDesktop"
            }
          ],
          staticClass: "top-bar-item"
        },
        [_c("NotificationsPopover", { attrs: { iconColor: _vm.iconColor } })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isDesktop,
              expression: "isDesktop"
            }
          ],
          staticClass: "top-bar-item"
        },
        [_c("AccountPopover")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }