import { ResizeObserver } from '@juggle/resize-observer';
import { EventBus } from '@/services/bus';
import { PANEL_DRAG } from '@/utils/events/omniviewEvents';

const panelsMixin = {
  data() {
    return {
      panelSize: 4,
      panelMinSize: 4,
      mainContentWidth: 0,
      isDragging: false
    };
  },
  mounted() {
    this.initWidthObserver();
  },
  computed: {
    showPanel() {
      return this.activeMode.name != 'In' && !this.isCompareEnabled;
    }
  },

  methods: {
    initWidthObserver() {
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          this.mainContentWidth = Math.round(entry.contentRect.width);
        });
      });

      myObserver.observe(this.$refs.mainContent);
    },
    togglePanel() {
      this.panelSize = this.panelSize == this.panelMinSize ? 25 : this.panelMinSize;
    },
    handleResize(e, data) {
      if (e === 'start-drag') {
        if (this.isDragging) return;
        EventBus.$emit(PANEL_DRAG, true);
        this.isDragging = true;
      }
      if (e === 'end-drag') {
        EventBus.$emit(PANEL_DRAG, false);
        this.panelSize = data[1].size;
        this.isDragging = false;
      }
    }
  }
};

export default panelsMixin;
