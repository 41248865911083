var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-row-container" }, [
    _c("div", { staticClass: "team-row" }, [
      _c(
        "div",
        { staticClass: "team-logo" },
        [
          _c("RoundedImage", {
            attrs: { src: _vm.getLogo(_vm.team), dashedEmptyState: "" }
          })
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "team-name" }, [
          _vm._v(_vm._s(_vm.team.name))
        ]),
        _c("div", { staticClass: "team-projects" }, [
          _vm._v(_vm._s(_vm.numberOfProjects))
        ])
      ])
    ]),
    _vm.members
      ? _c(
          "div",
          { staticClass: "members" },
          [
            _c("Members", {
              attrs: { members: _vm.members, peekAmount: 5, size: 30 }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }