<template>
  <div class="column" :class="{ 'current-plan': isCurrentPlan, [theme]: true }">
    <div class="title">{{ plan.name }}</div>
    <div class="price">{{ plan.name === 'Team' ? formatTeamPrice : formattedPrice }}</div>
    <div class="price-details">
      <span v-if="plan.name === 'Team'">
        Starts at {{ formattedPrice }} per user / month
        <br />
        with {{ plan.minQuantity }} seats included
      </span>
      <span v-else>
        Per user / month
        <br />
        billed {{ billingCycle }}ly
      </span>
    </div>
    <an-button data-cy="pricing-column-cta" class="cta" variant="primary" @click="onClick" :disabled="isCurrentPlan">
      {{ ctaButtonLabel }}
    </an-button>
    <div class="divider" />
    <div class="description">
      {{ plan.description }}
    </div>
    <div class="features-list">
      <div class="quirky-scratch-container" :class="{ invisible: !plan.scratchFirstFeature }">
        Unlimited
        <div class="scratch" />
      </div>
      <div
        v-for="feature in plan.features"
        :key="feature.label"
        class="features-list-item"
        :class="{ inactive: !feature.isActive }"
      >
        <svg-icon :name="getIconName(feature)" :size="24" />
        <div>{{ feature.label }}</div>
      </div>
    </div>
    <div class="ribbon" v-if="ribbonName">
      <svg-icon :name="ribbonName" :width="109" :height="109" />
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/billing';

export default {
  name: 'PricingColumn',
  props: {
    plan: {
      type: Object,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    billingCycle: {
      type: String,
      required: true,
      validator: value => ['month', 'annual'].includes(value)
    },
    isCurrentPlan: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value)
    },
    ribbonName: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedPrice() {
      return formatPrice(this.price);
    },
    ctaButtonLabel() {
      return this.isCurrentPlan ? 'Current plan' : `Get ${this.plan.name} plan`;
    },
    formatTeamPrice() {
      return formatPrice(this.price * this.plan.minQuantity);
    }
  },
  methods: {
    onClick() {
      this.$emit('select-plan', this.plan);
    },
    getIconName({ isActive }) {
      if (isActive) {
        return `circle-check${this.theme === 'dark' ? '-inverse' : ''}`;
      }
      return 'circle-minus-red';
    }
  }
};
</script>

<style lang="scss" scoped>
.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px;
  width: 330px;
  border: var(--border);
  border-radius: 15px;
  text-align: center;
  cursor: default;
  &.dark {
    background-color: var(--secondary);
    border: none;
  }
  &.current-plan {
    opacity: 0.3;
  }
  &:hover {
    border: var(--primary-border);
    &.dark {
      border: none;
    }
  }
  + .column {
    margin-left: 40px;
  }
  @media screen and (max-width: 1440px) {
    + .column {
      margin-left: 20px;
    }
  }
  @include mobile {
    + .column {
      margin: 50px 0 0;
    }
  }
}
.title {
  @include headline;
  font-size: 24px;
  line-height: 48px;
}
.price {
  @include huge-headline;
  font-size: 60px;
  line-height: 80px;
  margin-top: 40px;
}
.divider {
  height: 1px;
  width: 100%;
  background: var(--divider-color);
  margin: 40px 0 30px;
}
.price-details {
  @include secondary-title;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}
.cta {
  margin-top: 30px;
}
.features-carry {
  color: var(--secondary-text);
  margin-top: 20px;
  height: 32px;
}
.features-list {
  text-align: left;
  margin-top: 8px;
  width: 100%;
}
.quirky-scratch-container {
  @include quirky-text;
  margin: 20px 0 0 36px;
  &.invisible {
    visibility: hidden;
  }
  .scratch {
    height: 2px;
    border-radius: 100px;
    background: var(--primary);
    width: 34px;
    transform: rotate(-2deg);
    margin-top: 15px;
    margin-bottom: -19px;
  }
}
.features-list-item {
  display: flex;
  align-items: center;
  padding: 4px 0;
  .svg-container {
    margin-right: 14px;
  }
  + .features-list-item {
    margin-top: 20px;
  }
  &.inactive {
    opacity: 0.3;
  }
}
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
