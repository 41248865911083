var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sections", attrs: { id: "sections" } },
    _vm._l(_vm.sectionsSorted, function(section) {
      return _c("Section", {
        key: section.id,
        ref: "sections",
        refInFor: true,
        attrs: {
          "data-id": section.id,
          section: section,
          actions: _vm.actions,
          items: _vm.items
        },
        on: {
          create: _vm.handleCreateSection,
          delete: _vm.handleDeleteSection,
          dropEnd: _vm.handleChildDropEnd,
          copyWebLink: _vm.handleCopyWebLink
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }