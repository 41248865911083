var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tip",
    {
      ref: "tooltipBig",
      attrs: {
        trigger: _vm.trigger,
        offset: "10",
        delay: { show: 1000, hide: 0 },
        open: _vm.open
      }
    },
    [
      _vm._t("popover-caller"),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          {
            staticClass: "tooltip-big",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [
            _vm.showBanner
              ? _c("div", { staticClass: "tooltip-big-banner" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/svg/tooltip-new-banner.svg")
                    }
                  })
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "tooltip-big-title" },
              [_vm._t("popover-title")],
              2
            ),
            _c(
              "div",
              { staticClass: "tooltip-big-text" },
              [_vm._t("popover-text")],
              2
            ),
            _c(
              "div",
              { staticClass: "tooltip-big-footer" },
              [_vm._t("popover-footer")],
              2
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }