import { loadStripe } from '@stripe/stripe-js';

let Stripe;
const style = {
  base: {
    fontSmoothing: 'antialiased',
    padding: '12px 22px',
    '::placeholder': {
      color: '#aab7c4'
    }
  }
};

export const initStripe = async () => {
  Stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
};

export const createCardInstance = async (ref, onChange) => {
  if (!Stripe) await initStripe();
  const elements = Stripe.elements({ locale: 'en' });
  const card = elements.create('card', { style });
  card.mount(ref);
  card.on('change', onChange);
  return card;
};

export const getStripeToken = async card => {
  const result = await Stripe.createSource(card);
  if (result.error) {
    throw new Error(result.error.message || result.error.type || result.error);
  } else {
    return result.source;
  }
};
