<template>
  <div class="styleguide-container flex flex-col" :class="[{ 'items-center justify-center': isLoading }]">
    <default-loader :style="{ margin: 0 }" v-if="isLoading" />

    <div
      v-show="!isLoading && currentStylesheet"
      @mouseleave="resetPos"
      ref="st"
      @copy="handleManualCopyEvent"
      id="st"
      class="stylesheet"
    >
      <Splitpanes>
        <Pane class="flex flex-col" min-size="25">
          <div :style="{ position: 'relative', flex: 1, overflow: 'hidden' }" class="flex flex-col">
            <svg-icon
              @click.native="copyStyleguide('token')"
              v-tip="'Copy'"
              class="copy-icon"
              name="copy-alt"
              :size="24"
              fill="currentColor"
            ></svg-icon>
            <div class="code-scroller" :style="{ flex: 1, width: '100%', padding: '20px 0 40px 0', overflowY: 'auto' }">
              <Prism
                :plugins="['inline-color']"
                @update="handleUpdate"
                @cancel="handleCancel"
                mode="styleguide"
                class="styleguidePrism"
                ref="prismcss"
                :language="codegenStylesheetLang"
                type="token"
                :code="currentStylesheet.tokensSheet"
              />
            </div>
          </div>
        </Pane>
        <!-- </div> -->
        <!-- <div> -->
        <Pane style="position:relative" class="flex flex-col" min-size="25">
          <div :style="{ position: 'relative', flex: 1, overflow: 'hidden' }" class="flex flex-col">
            <svg-icon
              @click.native="copyStyleguide('class')"
              v-tip="'Copy'"
              class="copy-icon"
              name="copy-alt"
              :size="24"
              fill="currentColor"
            ></svg-icon>
            <Popover
              theme="dark"
              transitionName="fadeIn"
              :isOpen="isOpen"
              :style="{
                overflow: 'hidden',
                padding: '0',
                width: '348px',
                top: top + 40 + 'px',
                transform: 'translateX(-50%)',
                left: `calc(${width + 35}px)`,
                right: 'unset'
              }"
              :id="'class-preview-popover'"
              @close="isOpen = false"
              ref="inspectPopoverRef"
              :refId="refId"
            >
              <div @click.stop class="flex flex-col class-preview">
                <div class="flex items-center header">
                  <span class="truncate"
                    ><span class="classname">{{ currentClassData.className }}</span
                    ><span class="text">&nbsp; is used by</span>
                  </span>
                </div>
                <ul class="pages-list flex flex-col">
                  <li
                    @click="highlightClass(slug)"
                    class="page-item flex items-center truncate"
                    v-for="(slug, i) in selectedClassPageSlugs"
                    :key="i"
                  >
                    <div class="truncate">{{ slug }}</div>
                    <div style="margin-left:auto;flex-shrink:0;padding-left:5px">
                      <span style="font-size:12px;opacity:0.5;flex-shrink:0" v-if="$route.params.screenSlug == slug"
                        >Current page</span
                      >
                      <svg-icon v-else name="arrow-right" :size="18" fill="currentColor"></svg-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </Popover>
            <div :style="{ left: `${width}px` }" class-block-actions="true" ref="rediush" class="redish">
              <svg-icon
                @click.native="copyClassBlock"
                class="icon"
                name="copy-alt"
                :size="24"
                fill="currentColor"
              ></svg-icon>
              <svg-icon
                v-show="selectedClassPageSlugs.length > 0"
                class="icon"
                :id="refId"
                @click.native.stop="handleClassPreviewClick"
                name="inspect"
                :size="24"
                fill="currentColor"
              ></svg-icon>
            </div>
            <div
              @scroll="handleScroll"
              class="code-scroller"
              ref="classesRef"
              :style="{ flex: 1, width: '100%', padding: '20px 0 40px 0', overflowY: 'auto' }"
            >
              <Prism
                :plugins="['inline-color']"
                @update="handleUpdate"
                @cancel="handleCancel"
                @classBlockMouseover="classBlockMouseover"
                @classBlockMousleave="classBlockMousleave"
                @onWidthUpdate="onWidthUpdate"
                mode="styleguide"
                class="styleguidePrism"
                type="class"
                ref="prismcss"
                :language="codegenStylesheetLang"
                id="styleguideClasses"
                :code="currentStylesheet.classesSheet"
              />
            </div>
          </div>
        </Pane>
        <!-- </div> -->
      </Splitpanes>
    </div>

    <div v-if="!isLoading && !currentStylesheet" style="color:white;padding:20px" class="w-full h-full flex ">
      Sorry, the styleguide could not be loaded, please refresh the page.
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Prism from '../Panels/Prism';
import DefaultLoader from '@/components/Loading/DefaultLoader.vue';
import Popover from '@/components/Popovers/Popover';
import { uuid } from '@/utils/uuid';
import { get } from 'lodash-es';
import { SEND_MESSAGE } from '@/utils/events/omniviewEvents';
import { Splitpanes, Pane } from 'splitpanes';

export default {
  name: 'styleguide',
  components: {
    Prism,
    DefaultLoader,
    Popover,
    Splitpanes,
    Pane
  },
  data() {
    return {
      isLoading: true,
      isOpen: false,
      top: 0,
      refId: uuid(),
      width: 0,
      classEl: null
    };
  },
  watch: {
    isOpen(to) {
      if (to) {
        let s = this.$route.params.screenSlug;
        if (this.selectedClassPageSlugs.includes(s)) {
          this.highlightClass(s);
        }
      }
    },
    currentStylesheet: {
      handler(s) {
        if (s) {
          this.isLoading = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      currentStylesheet: 'styleguide/currentStylesheet',
      styleguide: 'styleguide/currentStyleguide',
      codegenLang: 'omniview/codegenLang',
      // panelHeight: 'omniview/panelHeight',
      queue: 'omniview/queue',
      shouldShowPaywall: 'omniview/shouldShowPaywall',
      currentClassData: 'styleguide/currentClassData',
      codegenStylesheetLang: 'omniview/codegenStylesheetLang',
      styleType: 'omniview/styleType'
    }),
    selectedClassPageSlugs() {
      if (!this.currentClassData.className) return [];

      let classes = Object.values(get(this.styleguide, 'classes', {}));
      let classObj = classes.find(c => c.name == this.currentClassData.className) || {};

      return Object.keys(get(classObj, 'usage', {}));
    }
  },

  mounted() {
    this.$refs['st'].addEventListener('mouseup', this.handleSelectEvent);
    this.$refs['st'].addEventListener('keyup', this.handleSelectEvent);
    this.$refs['st'].addEventListener('mouseover', this.handleMouseOverEvent);
  },
  beforeDestroy() {
    this.$refs['st'].removeEventListener('mouseup', this.handleSelectEvent);
    this.$refs['st'].removeEventListener('keyup', this.handleSelectEvent);
    this.$refs['st'].removeEventListener('mouseover', this.handleMouseOverEvent);
  },

  methods: {
    ...mapMutations({
      setCurrentClassData: 'styleguide/setCurrentClassData'
    }),
    ...mapActions({
      fetchComponents: 'styleguide/fetchAllOfParent'
    }),
    handleMouseOverEvent(e) {
      let el = e.target.getAttribute('class-block');
      if (el) {
        this.classBlockMouseover({
          el: e.target,
          rect: e.target.getBoundingClientRect(),
          classData: { className: el, text: e.target.textContent }
        });
      }
    },
    handleUpdate(type) {
      this.$trackEvent('omniview.styleguide.edit', { type });
    },
    handleCancel(type) {
      this.$trackEvent('omniview.styleguide.edit-cancel', { type });
    },
    copyStyleguide(type) {
      let text = type == 'token' ? this.currentStylesheet.tokensSheet : this.currentStylesheet.classesSheet;
      EventBus.$emit('copy-code', { text, type: 'styleguide', panel: type });
    },
    copyClassBlock() {
      EventBus.$emit('copy-code', { text: this.currentClassData.text, type: 'styleguide-class' });
    },
    handleManualCopyEvent() {
      this.$trackEvent('omni.code-mode.copy-code', {
        type: 'styleguide',
        'style-type': this.styleType,
        framework: this.codegenLang
      });
    },
    handleSelectEvent() {
      setTimeout(() => {
        var selectedText = this.getSelectedText();
        if (selectedText) {
          if (this.shouldShowPaywall) {
            return EventBus.$emit('show-paywall', { action: 'select-code', type: 'styleguide' });
          } else {
            this.$trackEvent('omniview.code-mode.select-code', {
              type: this.codegenStylesheetLang,
              'style-type': this.styleType,
              panel: 'styleguide',
              framework: this.codegenLang
            });
          }
        }
      }, 100);
    },
    getSelectedText() {
      var text = '';
      if (typeof window.getSelection != 'undefined') {
        text = window.getSelection().toString();
      } else if (typeof document.selection != 'undefined' && document.selection.type == 'Text') {
        text = document.selection.createRange().text;
      }
      return text;
    },
    onWidthUpdate({ width }) {
      if (!this.width) {
        this.width = width;
      }
    },
    handleClassPreviewClick() {
      this.$trackEvent('omniview.styleguide.class-preview-click');
      this.isOpen = !this.isOpen;
    },
    handleScroll() {
      if (this.classEl) {
        this.classEl.classList.remove('active');
      }
      this.resetPos();
    },
    classBlockMouseover({ rect, classData, el }) {
      this.setCurrentClassData(classData);
      this.classEl = el;
      this.isOpen = false;
      this.updatePos(rect);
    },
    classBlockMousleave() {
      this.resetPos();
    },
    resetPos() {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'clear-highlighted-nodes'
      });
      let r = this.$refs.rediush;
      if (r) {
        this.classEl = null;
        // this.top = 0;
        this.isOpen = false;
        Object.assign(r.style, {
          transform: 'translate3d(0,0,0)',
          display: 'none'
        });
      }
    },
    updatePos({ top, height }) {
      let r = this.$refs.rediush;
      let st = this.$refs.st;
      if (r && st) {
        const { top: stTop } = st.getBoundingClientRect();
        let _top = top + 10 - stTop + st.scrollTop;
        // h : expected height of the popover
        // const h = 78 + this.selectedClassPageSlugs.length * 32;
        // let spaceTop = top - stTop;
        // let spaceBot = this.panelHeight - spaceTop - h;
        // this.top = spaceBot <= 0 ? _top + (spaceBot - 20) : spaceTop <= 0 ? _top - (spaceTop - 30) : _top;
        this.top = _top;

        Object.assign(r.style, {
          height: height - 10 + 'px',
          transform: 'translate3d(0,' + `${_top}px,` + ' 0)',
          display: 'flex'
        });
      }
    },
    highlightClass(slug) {
      const { screenSlug } = this.$route.params;
      this.$trackEvent('omniview.styleguide.class-preview-highlight');
      let classes = Object.values(get(this.styleguide, 'classes', {}));
      let classObj = classes.find(c => c.name == this.currentClassData.className) || {};

      // put the className in edit mode if clickable
      // const classEl = document.querySelector(`[token-id=${this.currentClassData.className}]`);
      // if (classEl && [...classEl.classList].includes('element-clickable')) {
      //   classEl.click();
      // }

      let job = () => {
        EventBus.$emit(SEND_MESSAGE, {
          action: 'highlight-nodes',
          ids: get(classObj, `usage.[${slug}]`, [])
        });
      };
      if (screenSlug != slug) {
        this.queue.enqueue(job);

        EventBus.$emit('handleScreenChange', {
          screenSlug: slug
        });
      } else {
        job();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.styleguide-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.stylesheet {
  position: relative;
  user-select: text;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
    background-color: #303030;
  }
  &::-webkit-scrollbar-thumb {
    background: #b3b2b2;
    &:hover {
    }
  }
}

.copy-icon {
  opacity: 0.4;
  color: white;
  margin-left: auto;
  position: absolute;
  right: 20px;
  top: 18px;
  z-index: 10;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
.redish {
  width: 80px;
  height: 30px;
  // padding: 0 20px;
  color: white;
  padding: 0 7px;
  align-items: flex-start;
  position: absolute;
  z-index: 50;
  display: none;
  justify-content: space-around;
  .icon {
    opacity: 0.4;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
// .code-pref-dropdown {
//   padding: 0 20px;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   box-shadow: -1px 0 0 0 #2d2d2d;
//   position: relative;
//   &:hover {
//     cursor: pointer;
//   }
// }
// .code-pref-dropdown {
//   padding: 0;
//   &.open {
//     background: #2d2d2d;
//   }
// }

.class-preview {
  user-select: none;
  .header {
    padding: 18px 20px;
    font-size: 14px;
    box-shadow: 0 1px 0 0 #2a2a2a;
    color: #ffcb6b;
    .text {
      color: white;
      margin-right: 5px;
    }
    .classname {
      color: #ffcb6b;
    }
  }

  .pages-list {
    padding: 9px 0px;
    max-height: 146px;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      cursor: pointer;
      background-color: #303030;
    }
    &::-webkit-scrollbar-thumb {
      background: #b3b2b2;
      border-radius: 200px;
    }
    // padding-top: 9px;
    .page-item {
      // border-radius: 6px;
      height: 32px;
      flex-shrink: 0;
      padding: 0 20px;
      &:hover {
        cursor: pointer;
        background: #2a2a2a;
      }
    }
  }
}
</style>
