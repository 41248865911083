<template>
  <div
    class="outer-thumb"
    :style="{
      overflow: 'hidden',
      position: 'relative',
      maxHeight: maxHeight + 'px',
      maxWidth: maxWidth + 'px',
      width: componentOriginalSize.width + 'px',
      height: componentOriginalSize.height + 'px',
      ...getStyles.container
    }"
  >
    <div class="flex items-center justify-center w-full" style="padding:20px" v-show="imageLoading">
      <svg-icon fill="currentColor" class="icon-spin" name="spin" :size="30"></svg-icon>
    </div>
    <transition name="fadeIn">
      <div v-show="!imageLoading" class="inner-thumb fadeIn" :style="getStyles.bg"></div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash-es';
export default {
  props: {
    webComponent: {
      type: Object
    },
    maxWidth: {
      type: Number,
      default: 320
    },
    maxHeight: {
      type: Number,
      default: 350
    }
  },

  data() {
    return {
      imageLoading: false
    };
  },

  computed: {
    ...mapGetters({
      currentDBWebComponent: 'webComponents/currentDBWebComponent'
    }),
    component() {
      return this.webComponent ? this.webComponent : this.currentDBWebComponent;
    },
    thumbUrl() {
      const screenshot_url = get(this.component, 'screenshot_url', '');
      const thumbPosition = get(this.component, 'thumb_position', {});
      return thumbPosition['url'] ? thumbPosition['url'] : screenshot_url;
    },
    componentOriginalSize() {
      if (!this.thumbUrl)
        return {
          width: 0,
          height: 0
        };
      const { width, height } = this.component;
      return {
        width,
        height
      };
    },
    defaultContainerStyles() {
      return {};
    }
  },

  asyncComputed: {
    getStyles: {
      default: { bg: {}, container: {} },
      async get() {
        if (!this.thumbUrl) {
          return { bg: {}, container: {} };
        }

        const screenshot_url = get(this.component, 'screenshot_url', '');
        const thumbPosition = get(this.component, 'thumb_position', {});

        let { width, height } = this.component;
        let scale = { x: 1, y: 1 };

        const imageIntrinsicSize = await this.getImageIntrinsicSize(this.thumbUrl).catch(e => console.log(e));

        if (imageIntrinsicSize?.width && imageIntrinsicSize?.height) {
          const { width: intrinsicWidth, height: intrinsicHeight } = imageIntrinsicSize;

          if (this.thumbUrl == screenshot_url) {
            width = Math.min(width, intrinsicWidth);
            height = Math.min(height, intrinsicHeight);
          }

          if (this.thumbUrl == thumbPosition['url']) {
            const { screen_width: screenWidth, screen_height: screenHeight } = thumbPosition;

            if (screenWidth && screenHeight) {
              scale = {
                x: intrinsicWidth / screenWidth,
                y: intrinsicHeight / screenHeight
              };
            }
          }
        }

        const scaledSize = {
          width: width * scale.x,
          height: height * scale.y
        };

        const ratio = Math.min(Math.min(this.maxWidth / scaledSize.width, this.maxHeight / scaledSize.height), 1);
        const { x, y } = thumbPosition;

        return {
          container: {
            height: Math.min(ratio * scaledSize.height, scaledSize.height) + 'px',
            width: Math.min(scaledSize.width, scaledSize.width * ratio) + 'px'
          },
          bg: {
            width: scaledSize.width + 'px',
            height: scaledSize.height + 'px',
            position: 'absolute',
            backgroundImage: `url(${this.thumbUrl})`,
            backgroundRepeat: 'no-repeat',

            transform: `scale(${ratio})`,

            ...(this.thumbUrl != screenshot_url && { backgroundPosition: ` -${x * scale.x}px -${y * scale.y}px` }),
            ...(ratio < 1 && {
              left: `${(ratio * scaledSize.width - scaledSize.width) / 2}px`,
              top: `${(ratio * scaledSize.height - scaledSize.height) / 2}px`
            })
          }
        };
      }
    }
  },

  methods: {
    getImageIntrinsicSize(url) {
      return new Promise((resolve, reject) => {
        const onDone = () => {
          requestAnimationFrame(() => {
            this.imageLoading = false;
          });
        };
        this.imageLoading = true;
        let img = new Image();
        img.onload = () => {
          onDone();
          return resolve({ width: img.width, height: img.height });
        };
        img.onerror = () => {
          onDone();
          return reject();
        };
        img.src = url;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
