var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "announcements-popover-container",
      attrs: { tabindex: "-1" },
      on: { focusout: _vm.close }
    },
    [
      _c(
        "div",
        { ref: "icon", staticClass: "icon", on: { click: _vm.toggleOpen } },
        [
          _vm.hasAnnouncements
            ? _c("svg-icon", { attrs: { name: _vm.iconName, size: 30 } })
            : _vm._e()
        ],
        1
      ),
      _c(
        "Popover",
        {
          style: _vm.popoverStyles,
          attrs: { isOpen: _vm.isOpen },
          on: { close: _vm.close }
        },
        [
          _c("div", [
            _c("div", { staticClass: "announcements-header" }, [
              _c("span", [_vm._v("Product updates")])
            ]),
            _c(
              "div",
              { staticClass: "announcements-body" },
              _vm._l(_vm.announcements, function(item) {
                return _c("Announcement", {
                  key: item.id,
                  attrs: { item: item }
                })
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }