import { render, staticRenderFns } from "./overrideTagName.vue?vue&type=template&id=47bdd230&scoped=true&"
import script from "./overrideTagName.vue?vue&type=script&lang=js&"
export * from "./overrideTagName.vue?vue&type=script&lang=js&"
import style0 from "./overrideTagName.vue?vue&type=style&index=0&id=47bdd230&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47bdd230",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47bdd230')) {
      api.createRecord('47bdd230', component.options)
    } else {
      api.reload('47bdd230', component.options)
    }
    module.hot.accept("./overrideTagName.vue?vue&type=template&id=47bdd230&scoped=true&", function () {
      api.rerender('47bdd230', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/OmniView/Code/overrideTagName.vue"
export default component.exports