var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Connect to Slack")]),
    _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Choose team")]),
      _c(
        "div",
        { staticClass: "teams-list" },
        _vm._l(_vm.userMemberships, function(t, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "team-row-container",
                "selectable",
                { selected: _vm.isSelected(t) }
              ],
              on: {
                click: function($event) {
                  _vm.selectedTeam = t
                }
              }
            },
            [
              _c("TeamRow", {
                key: t.id,
                attrs: { team: t, members: t.members }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.showActions
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "an-button",
                {
                  attrs: { isWorking: _vm.isWorking },
                  on: { click: _vm.connectToSlack }
                },
                [_vm._v("Connect")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }