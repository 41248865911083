<template>
  <div :style="imageStyle" @mouseover="hover = true" @mouseleave="hover = false">
    <slot v-if="!src || showSlot" />
  </div>
</template>

<script>
export default {
  data: () => ({ hover: false }),
  props: {
    src: {
      type: String,
      default: ''
    },
    background: {
      type: String
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 50
    },
    circle: {
      type: Boolean,
      default: false
    },
    dashedEmptyState: {
      type: Boolean,
      default: false
    },
    showSlot: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageStyle() {
      const { src, height, width, background, circle, dashedEmptyState } = this;
      const bgColor = background || 'var(--white)';
      const borderStyle = !src && dashedEmptyState ? 'dashed' : 'solid';
      const border = `1px ${borderStyle} var(--${background || 'secondary'})`;
      const borderRadius = circle ? '100%' : '10px';
      return {
        border: !src && border,
        borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--secondary)',
        height: `${height}px`,
        width: `${width}px`,
        background: src ? `url("${src}") no-repeat` : bgColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    imageClass() {
      const { hover, src } = this;
      return { image: true, hover: !src && hover };
    }
  }
};
</script>
