var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content",
      attrs: { "data-cy": "figma-import-select-frames-content" }
    },
    [
      _c(
        "div",
        { staticClass: "grid page", on: { click: _vm.importAll } },
        [
          _c("div", { staticClass: "root-selection" }, [
            _c("img", {
              attrs: { src: require("@/assets/svg/figma.svg"), width: "50" }
            }),
            _c("div", { staticClass: "details" }, [
              _c("h3", [_vm._v("Import All")]),
              _c("p", { staticClass: "secondary" }, [
                _vm._v(_vm._s(_vm.getFramesCountText(_vm.totalFramesNumber)))
              ])
            ])
          ]),
          _c("FigmaCard", {
            attrs: {
              controlled: "",
              value: _vm.isImportAll,
              data: _vm.projectData
            },
            on: { selectChange: _vm.importAll }
          })
        ],
        1
      ),
      _c("file-upload-divider", { staticClass: "divider" }),
      _c(
        "div",
        { staticClass: "root-selection", on: { click: _vm.importSome } },
        [
          _c("img", {
            attrs: { src: require("@/assets/svg/figma-multi.svg"), width: "50" }
          }),
          _vm._m(0),
          _c("div", {
            staticClass: "toggle-collapse",
            class: _vm.isImportAll ? "down" : "up",
            staticStyle: { "margin-left": "auto" }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "collapsable" },
        _vm._l(_vm.figmaPages, function(page) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isImportAll,
                  expression: "!isImportAll"
                }
              ],
              key: page.id,
              staticClass: "page-section"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: page.frames.length > 0,
                      expression: "page.frames.length > 0"
                    }
                  ],
                  staticClass: "collapsable",
                  staticStyle: { "padding-left": "75px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "category",
                      on: {
                        click: function($event) {
                          return _vm.toggleCollapsePage(page.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("Checkbox", {
                              staticClass: "page-checkbox",
                              attrs: {
                                noMargin: "",
                                value: _vm.isAllPageSelected(page.id)
                              },
                              on: {
                                input: function($event) {
                                  return _vm.handlePageSelectChange(
                                    page.id,
                                    $event
                                  )
                                }
                              }
                            }),
                            _c("h4", { staticClass: "page-title" }, [
                              _vm._v(" " + _vm._s(page.name) + " ")
                            ])
                          ],
                          1
                        ),
                        _c("p", { staticClass: "secondary" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getFramesCountText(
                                _vm.framesNumByPage(page.id)
                              )
                            )
                          )
                        ])
                      ]),
                      _c("div", {
                        staticClass: "toggle-collapse",
                        class: _vm.isPageFramesShown(page.id) ? "up" : "down"
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPageFramesShown(page.id),
                          expression: "isPageFramesShown(page.id)"
                        }
                      ],
                      staticClass: "grid frame"
                    },
                    _vm._l(page.frames, function(frame) {
                      return _c("FigmaCard", {
                        key: frame.id,
                        attrs: {
                          controlled: "",
                          value: _vm.isFrameSelected(page.id, frame.id),
                          data: frame,
                          isLoadingThumbnail: _vm.isFetchingThumbnails[page.id]
                        },
                        on: {
                          selectChange: function($event) {
                            return _vm.selectFrame(page.id, frame.id)
                          }
                        }
                      })
                    }),
                    1
                  )
                ]
              ),
              page.frames.length > 0
                ? _c("file-upload-divider", { staticClass: "divider" })
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm.hasSelectedFrames
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c("file-upload-divider", { staticClass: "divider" }),
              _c(
                "an-button",
                {
                  attrs: {
                    "data-cy": "figma-import-import-file-button",
                    variant: "primary",
                    isWorking: _vm.isWorking
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v("Import Figma file")]
              ),
              _c("an-link", { on: { click: _vm.close } }, [_vm._v("Cancel")])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details" }, [
      _c("h3", [_vm._v("Import selected frames")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }