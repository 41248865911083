var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.transitionName } }, [
    _vm.isOpen
      ? _c(
          "div",
          {
            class: [_vm.popperClass, "popover", _vm.theme],
            style: _vm.popoverMenuStyle,
            on: { mouseleave: _vm.onMouseLeave }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }