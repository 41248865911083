var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", class: _vm.containerClass },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.stage === "upload-file",
            expression: "stage === 'upload-file'"
          }
        ],
        staticClass: "upload-file-frame-illustration",
        attrs: { src: require("@/assets/illustrations/figma-to-anima.svg") }
      }),
      _c("h1", { staticClass: "title" }, [_vm._v("Import from Figma")]),
      _vm.subtitle ? _c("h2", [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stage === "upload-file",
              expression: "stage === 'upload-file'"
            }
          ],
          staticClass: "stage"
        },
        [
          !_vm.grantedFigmaAPI
            ? _c("span", [
                _vm._v(
                  " You need to grant access to your Figma files first to be able to generate an Anima Model "
                )
              ])
            : _vm._e(),
          _vm.grantedFigmaAPI
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("an-input", {
                      attrs: {
                        "data-cy": "figma-import-input",
                        placeholder: "Paste Figma URL here",
                        focus: ""
                      },
                      on: {
                        change: _vm.resetFigmaFileUrl,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getFigmaFrames.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.figmaFileUrl,
                        callback: function($$v) {
                          _vm.figmaFileUrl = $$v
                        },
                        expression: "figmaFileUrl"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "actions" },
            [
              !_vm.grantedFigmaAPI
                ? [
                    _c("an-button", { on: { click: _vm.grantFigmaAccess } }, [
                      _vm._v("Integrate Anima with Figma")
                    ]),
                    _c(
                      "an-link",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ]
                : [
                    _c(
                      "an-button",
                      {
                        attrs: {
                          "data-cy": "figma-import-get-frames-button",
                          disabled: !_vm.validFigmaUrl(),
                          isWorking: _vm.isWorking
                        },
                        on: { click: _vm.getFigmaFrames }
                      },
                      [_vm._v("Import")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isWorking,
                            expression: "isWorking"
                          }
                        ],
                        staticClass: "flex items-center"
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon-spin",
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            fill: "currentColor",
                            name: "spin",
                            size: 20
                          }
                        }),
                        _c("span", [_vm._v("Getting file details")])
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ]
      ),
      _vm.stage === "pick-artboards"
        ? _c("FigmaSelectFrames", {
            attrs: {
              "figma-pages": this.frameOptions,
              "project-data": this.projectData,
              isWorking: this.isWorking
            },
            on: {
              submit: _vm.syncFigmaFrames,
              close: _vm.handleClose,
              "get-thumbnails": _vm.getThumbnails
            }
          })
        : _vm._e(),
      _vm.stage === "syncing-project"
        ? _c(
            "div",
            [
              _c("default-loader"),
              _c("div", [_vm._v(_vm._s(_vm.figmaProcessStatus))])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }