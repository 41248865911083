import axios from 'axios';
import { readCookie } from './utils/cookie';

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  const _url = new URL(url);
  return _url.searchParams.get(name);
};

const clearAuthStorage = () => {
  localStorage.removeItem('isAnonymousUser');
  localStorage.removeItem('token');
  localStorage.removeItem('lastWorkspace');
};

const getRecaptchaToken = action => {
  return new Promise((resolve, reject) => {
    try {
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          const captchaToken = await window.grecaptcha.execute('6LdRecYUAAAAAIUu1SE0LGeo3SN9wrVmohJ0Zcyg', {
            action
          });
          resolve(captchaToken);
        });
      } else {
        resolve(process.env.SKIP_CAPTCHA_KEY);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const login = async (email, password) => {
  const captchaToken = await getRecaptchaToken('login');
  const response = await axios.post(
    '/auth',
    {
      username: email,
      password: password,
      captcha_token: captchaToken,
      nonce: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)
    },
    {
      headers: {
        Authorization: `Basic`
      }
    }
  );

  return response.data;
};
const signup = async (firstName, lastName, email, password) => {
  const captchaToken = await getRecaptchaToken('signup');
  const response = await axios.post('/signup', {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    source: 'signup.form',
    captcha_token: captchaToken,
    visitor_id: readCookie('ana_visitorId'),
    referrer: readCookie('ana_originReferrer')
  });

  return response.data;
};

const logout = () => {
  clearAuthStorage();
};

const loginWithTempToken = (tempToken, cb) => {
  axios
    .post('/users/me/exchange_temp_token_for_token', { temp_token: tempToken })
    .then(response => {
      var data = response.data;
      if (data.access_token) {
        localStorage.setItem('token', data.access_token);
        if (cb) cb(true);
      } else {
        if (cb) cb(false);
      }
    })
    .catch(error => {
      console.error(error);
      if (cb) cb(false);
    });
};

export const getToken = () => {
  return localStorage.getItem('token') || getParameterByName('t');
};

export const isLoggedIn = () => !!getToken();
export const isAnonymousUser = () => localStorage.getItem('isAnonymousUser') == 'true';

export const googleLogin = async googleUser => {
  const token = await getRecaptchaToken('login');
  const { id_token } = googleUser.getAuthResponse();
  const { data } = await axios.post('/oauth/google', { id_token, captcha_token: token });
  return data;
};

const auth = {
  login,
  signup,
  logout,
  loginWithTempToken,
  isLoggedIn,
  isAnonymousUser,
  getToken,
  googleLogin
};

export default auth;
