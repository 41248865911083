<template>
  <transition name="fadeHeight">
    <div class="banner" v-if="show && isDesktop">
      <an-banner v-bind="props" @close="onClose">
        <component :is="componentName" />
      </an-banner>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';

export default {
  data() {
    return {
      show: false,
      componentName: '',
      props: {}
    };
  },
  mounted() {
    EventBus.$on('show-banner', this.showBanner);
    EventBus.$on('hide-banner', this.onClose);
  },
  destroyed() {
    EventBus.$off('show-banner', this.showBanner);
    EventBus.$off('hide-banner', this.onClose);
  },
  methods: {
    showBanner({ name, ...props }) {
      this.componentName = name;
      this.props = props;
      this.show = true;
      this.$trackEvent('webapp.banner.show', { name });
    },
    onClose() {
      if (this.show) {
        this.$trackEvent('webapp.banner.close');
        this.show = false;
      }
      this.componentName = '';
      this.props = {};
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  height: 60px;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
