var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view slideInRight" }, [
    _c("div", { staticClass: "title-container" }, [
      _c(
        "div",
        {
          staticStyle: {
            cursor: "pointer",
            display: "flex",
            "align-items": "center"
          },
          on: { click: _vm.backToComments }
        },
        [
          _c("svg-icon", {
            staticStyle: { color: "white", "margin-right": "5px" },
            attrs: { fill: "currentColor", name: "caret-left", size: 20 }
          }),
          _c("div", { staticClass: "title" }, [_vm._v("Back")])
        ],
        1
      ),
      _vm.currentComment.status == "OPEN"
        ? _c(
            "div",
            {
              staticClass: "resolve",
              on: {
                click: function($event) {
                  return _vm.$emit("resolve", _vm.currentComment)
                }
              }
            },
            [_vm._v(" Resolve ")]
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "body-wrapper" },
      [
        _c(
          "virtual-list",
          {
            staticClass: "vv-list",
            attrs: {
              wclass: "v-list",
              size: 132,
              remain: _vm.getRemain,
              bench: 0
            }
          },
          [
            _c(
              "div",
              { staticClass: "comments-list" },
              [
                _c("Comment", {
                  style: { background: "#333333" },
                  attrs: {
                    isParent: "",
                    isReply: "",
                    comment: _vm.currentComment
                  },
                  on: {
                    openComments: function($event) {
                      return _vm.$emit("change", {
                        view: "comments"
                      })
                    }
                  }
                }),
                _vm._l(_vm.currentCommentReplies, function(comment) {
                  return _c("Comment", {
                    key: comment.id,
                    attrs: { isReply: "", comment: comment }
                  })
                })
              ],
              2
            )
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "reply-comment-container" }, [
      _c(
        "div",
        { staticClass: "form-container" },
        [
          _c("an-textarea", {
            attrs: {
              autoFocus: "",
              className: "comment-textarea",
              rows: "1",
              placeholder: "Add your reply"
            },
            on: { enter: _vm.handleCommentReply },
            model: {
              value: _vm.replyCommentText,
              callback: function($$v) {
                _vm.replyCommentText = $$v
              },
              expression: "replyCommentText"
            }
          }),
          _c(
            "div",
            {
              staticClass: "icon-container",
              class: { active: _vm.isInputActive },
              on: { click: _vm.handleCommentReply }
            },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { name: "send", size: 23, fill: "currentColor" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }