var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isPreprocessing
    ? _c(
        "div",
        { staticClass: "w-full" },
        [
          _c("Prism", {
            staticClass: "usage-snippet",
            staticStyle: { padding: "20px", "margin-top": "24px" },
            attrs: {
              language: _vm.getLanguage,
              mode: "usageCode",
              masterId: _vm.masterId,
              componentData: _vm.componentData,
              code: _vm.usageCode
            },
            on: {
              propMouseOver: _vm.handlePropMouseover,
              propMouseLeave: _vm.handlePropMouseout,
              "update-component-prop-name": _vm.handleUpdatePropName,
              "update-component-name": _vm.handleUpdateComponentName,
              "update-component-prop-enable": _vm.handleUpdatePropEnable
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }