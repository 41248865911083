var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm._width,
        height: _vm._height,
        "aria-labelledby": _vm.name,
        role: "presentation"
      }
    },
    [
      _c("title", { attrs: { id: _vm.name, lang: "en" } }, [
        _vm._v(_vm._s(_vm.name) + " icon")
      ]),
      _c(
        "g",
        { attrs: { fill: _vm.color } },
        [_c(_vm.relevantIconComponent, { tag: "component" })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }