var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-state-container" }, [
      _c("img", {
        staticClass: "illustration",
        attrs: { src: require("@/assets/illustrations/not-found.svg") }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("No results found")]),
      _c("div", { staticClass: "body" }, [
        _vm._v(" Sorry, we couldn’t find any results for this search. "),
        _c("span", [_vm._v("Maybe try a different one?")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }