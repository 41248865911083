import Vue from 'vue';
import VueSlider from 'vue-slider-component';
import Banner from '@/components/Banners/Banner.vue';
import Button from '@/components/Button/Button.vue';
import Link from '@/components/Link/Link.vue';
import Card from '@/components/Card/Card.vue';
import Input from '@/components/Input/Input.vue';
import NumberInput from '@/components/Input/NumberInput.vue';
import Select from '@/components/Select/Select.vue';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
import AnimaIcon from '@/components/SvgIcon/AnimaIcon.vue';
import Emoji from '@/components/SvgIcon/Emoji.vue';
import UserAvatar from '@/components/Avatar/UserAvatar.vue';
import Textarea from '@/components/Textarea/Textarea.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Radio from '@/components/Radio/Radio.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import Logo from '@/components/Logo/Logo.vue';
import TooltipBig from '@/components/TooltipBig/TooltipBig.vue';
import Prompt from '@/components/Prompt/Prompt.vue';

import AccountModal from '@/components/Account/Account';

import DeleteAccountModal from '@/components/Account/DeleteAccount';

import NewProjectModal from '@/components/Team/NewProject.vue';
import NewTeamModal from '@/components/Team/NewTeam.vue';
import DeleteProjectModal from '@/components/Team/DeleteProject.vue';
import ArchiveProjectModal from '@/components/Team/ArchiveProject.vue';
import MoveProjectModal from '@/components/Team/MoveProject.vue';

import TeamSettingsGeneralModal from '@/components/Team/Settings/General.vue';
import TeamSettingsMembersModal from '@/components/Team/Settings/Members.vue';
import TeamSettingsPermissionsModal from '@/components/Team/Settings/Permissions.vue';
import TeamSettingsBillingModal from '@/components/Team/Settings/Billing.vue';
import TeamSettingsDeleteModal from '@/components/Team/Settings/Delete.vue';
import TeamPartialAccess from '@/components/Team/PartialAccess.vue';

import ProjectInvite from '@/components/Project/Invite/Invite.vue';
import ProjectRequestAccess from '@/components/Project/RequestAccess.vue';
import ProjectSettings from '@/components/Project/Settings/Settings.vue';

import PaymentSuccessModal from '@/components/Payment/Success.vue';
import ConnectSlackModal from '@/views/ConnectSlack.vue';
import PluginsModal from '@/views/Plugins';
import FigmaFileSyncModal from '@/views/FigmaFileImport';
import SketchFileSyncModal from '@/views/SketchFileUpload';
import PricingModal from '@/views/Pricing';
import PaymentModal from '@/views/Payment';
import DowngradeModal from '@/views/Downgrade/Downgrade';
import CancelPlanModal from '@/views/Downgrade/CancelPlan';
import SwitchToDesktopModal from '@/views/SwitchToDesktop.vue';
import UpgradeTeamPlanModal from '@/views/UpgradeTeamPlanModal.vue';
import LearnVideoModal from '@/components/Learn/VideoModal.vue';

import ComponentInterface from '@/components/OmniView/Code/ComponentInterface.vue';
import ExportCode from '@/components/OmniView/ExportCode.vue';
import PackageIsReadyModal from '@/components/OmniView/PackageIsReadyModal.vue';

import AppStatusBanner from '@/components/Banners/AppStatusBanner';
import UpgradeTeamPlanBanner from '@/components/Banners/UpgradeTeamPlanBanner';

// import shared components and add them to the array

const sharedComponents = [
  Banner,
  Button,
  Card,
  Input,
  NumberInput,
  Select,
  SvgIcon,
  AnimaIcon,
  Emoji,
  Link,
  UserAvatar,
  Textarea,
  Toggle,
  Checkbox,
  Radio,
  Tooltip,
  TooltipBig,
  Logo,
  Prompt
];
const modalComponents = [
  PaymentSuccessModal,
  AccountModal,
  DeleteAccountModal,
  NewProjectModal,
  MoveProjectModal,
  NewTeamModal,
  ArchiveProjectModal,
  DeleteProjectModal,
  TeamSettingsGeneralModal,
  TeamSettingsMembersModal,
  TeamSettingsPermissionsModal,
  TeamSettingsBillingModal,
  TeamSettingsDeleteModal,
  TeamPartialAccess,
  ProjectInvite,
  ProjectRequestAccess,
  ProjectSettings,
  PluginsModal,
  FigmaFileSyncModal,
  SketchFileSyncModal,
  PricingModal,
  PaymentModal,
  DowngradeModal,
  CancelPlanModal,
  SwitchToDesktopModal,
  UpgradeTeamPlanModal,
  ConnectSlackModal,
  ComponentInterface,
  ExportCode,
  PackageIsReadyModal,
  LearnVideoModal
];
const bannerComponents = [AppStatusBanner, UpgradeTeamPlanBanner];

export const registerSharedComponents = () => {
  sharedComponents.forEach(component => Vue.component(component.name, component));
};

export const registerModalComponents = () => {
  modalComponents.forEach(component => Vue.component(component.name, component));
};

export const registerBannerComponents = () => {
  bannerComponents.forEach(component => Vue.component(component.name, component));
};

// external components

Vue.component('VueSlider', VueSlider);
