var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in" } },
    [
      _vm.loading
        ? _c("LoadingScreen")
        : !!_vm.projects.length
        ? _c(_vm.listComponent, {
            tag: "component",
            attrs: {
              itemsType: "project",
              items: _vm.projects,
              actions: _vm.actions,
              members: _vm.members,
              forceShowActions: _vm.isArchive,
              filteredResults: !!_vm.searchQuery,
              totalAmount: _vm.totalProjects,
              isLoadingMore: _vm.loadingMore,
              withCta: ""
            },
            on: { "load-more": _vm.loadMore, "click-project": _vm.onClick }
          })
        : !!_vm.searchQuery
        ? _c("NoSearchResults")
        : _c("EmptyState", { attrs: { type: _vm.type } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }