var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["banner-container", _vm.variant] },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          ref: "closeButton",
          staticClass: "clickable-icon",
          on: { click: _vm.onClose }
        },
        [_c("svg-icon", { attrs: { name: "close" } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }