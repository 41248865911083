var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { "data-cy": "upgrade-team-modal" } },
    [
      _c("div", { staticClass: "illustration" }, [
        _c("img", { attrs: { src: _vm.illustrationSrc } })
      ]),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", {
        staticClass: "subtitle",
        domProps: { innerHTML: _vm._s(_vm.contentHtml) }
      }),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "an-button",
            {
              attrs: { "data-cy": "upgrade-team-btn" },
              on: { click: _vm.openPlans }
            },
            [_vm._v("See upgrade plans")]
          ),
          _c(
            "an-link",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }