var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full flex items-center justify-center",
      style: { height: "100%" }
    },
    [
      _vm.isLoading ? [_c("DefaultLoader", { attrs: { size: 40 } })] : _vm._e(),
      _vm.showBorder && !_vm.isLoading
        ? _c("div", { staticClass: "name-border", style: _vm.getMaskSize }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.currentWebComponentName))
            ])
          ])
        : _vm._e(),
      _vm.mask && !_vm.isLoading
        ? _c("div", { staticClass: "mask", style: _vm.getMaskSize })
        : _vm._e(),
      _c("transition", { attrs: { name: "fadeIn" } }, [
        _c("iframe", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading"
            }
          ],
          ref: "iframeRef",
          style: _vm.getComponentFrameStyles,
          attrs: { name: _vm.name, id: _vm.name, frameborder: "0" }
        })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }