var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section", attrs: { id: "section-" + _vm.section.id } },
    [
      _c("div", { staticClass: "section-header" }, [
        _c("div", { staticClass: "content d-container" }, [
          _c(
            "div",
            { staticClass: "drag-handler" },
            [_c("svg-icon", { attrs: { name: "draghandler", size: 24 } })],
            1
          ),
          _c("div", { staticClass: "section-input" }, [
            _c("span", {
              staticClass: "name-mirror",
              domProps: { innerHTML: _vm._s(_vm.sectionCopy.name || "&nbsp;") }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sectionCopy.name,
                  expression: "sectionCopy.name"
                }
              ],
              ref: "nameInput",
              staticClass: "inline-edit",
              attrs: {
                type: "text",
                autocomplete: "off",
                spellcheck: "false",
                name: "section-name",
                minlength: "1",
                maxlength: "100"
              },
              domProps: { value: _vm.sectionCopy.name },
              on: {
                blur: _vm.updateSectionName,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.sectionCopy, "name", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "section-info",
              style: { cursor: _vm.isOpen ? "auto" : "pointer" },
              on: {
                click: function($event) {
                  _vm.isOpen = true
                }
              }
            },
            [
              _vm.itemsBySection.length == 0
                ? _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(" No screens ")
                  ])
                : !_vm.isOpen
                ? _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v(
                      " (" +
                        _vm._s(_vm.itemsBySection.length) +
                        " screen" +
                        _vm._s(_vm.itemsBySection.length > 1 ? "s" : "") +
                        " collapsed) "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticStyle: { flex: "auto" } })
            ]
          ),
          _c(
            "div",
            { staticClass: "section-actions" },
            [
              _c(
                "v-tip",
                {
                  staticClass: "flex",
                  attrs: {
                    placement: "bottom-end",
                    trigger: "click",
                    offset: "4"
                  }
                },
                [
                  _c("svg-icon", {
                    staticClass: "icon-wrapper",
                    attrs: {
                      size: 20,
                      fill: "currentColor",
                      name: "three-dots-horizontal"
                    }
                  }),
                  _c("template", { slot: "popover" }, [
                    _c("div", { staticClass: "section-pop" }, [
                      _c("div", { staticClass: "section-pop-items" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            staticClass: "item",
                            on: {
                              click: function($event) {
                                return _vm.handleSectionCommand("copyWebLink")
                              }
                            }
                          },
                          [_vm._v("Copy web link")]
                        ),
                        _vm.section.id != "allId"
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass: "delete-item-link item",
                                on: {
                                  click: function($event) {
                                    return _vm.handleSectionCommand("delete")
                                  }
                                }
                              },
                              [_vm._v(" Delete section ")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ],
                2
              ),
              _vm.itemsBySection.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "icon-wrapper",
                      on: {
                        click: function($event) {
                          _vm.isOpen = !_vm.isOpen
                        }
                      }
                    },
                    [
                      _vm.isOpen
                        ? _c("svg-icon", {
                            attrs: {
                              name: "carret-down",
                              size: 20,
                              fill: "currentColor"
                            }
                          })
                        : _c("svg-icon", {
                            attrs: {
                              name: "caret-right",
                              size: 20,
                              fill: "currentColor"
                            }
                          })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "section-items-container d-container",
          attrs: { "data-section-id": _vm.section.id }
        },
        _vm._l(_vm.itemsBySection, function(item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen"
                }
              ],
              key: item.id,
              staticClass: "section-item",
              attrs: { "data-id": item.id }
            },
            [
              index != 0
                ? _c(
                    "div",
                    {
                      staticClass: "section-divider",
                      on: {
                        click: function($event) {
                          return _vm.$emit("create", {
                            index: index,
                            section: _vm.section
                          })
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "line" }),
                      _c("svg-icon", {
                        staticClass: "icon",
                        staticStyle: { margin: "6px 0" },
                        attrs: { fill: "currentColor", name: "divide" }
                      }),
                      _c("div", { staticClass: "line" })
                    ],
                    1
                  )
                : _c("div", { staticStyle: { width: "30px" } }),
              _c("MasterScreenCard", {
                key: item.id,
                staticClass: "dragable",
                staticStyle: { width: "100%" },
                attrs: {
                  screen: item,
                  actions: _vm.actions,
                  flags: item.flags,
                  selected: item.isSelected
                },
                on: {
                  click: function($event) {
                    return _vm.navigate(item)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }