var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OnboardingTopBar", { attrs: { homepageUrl: "/" } }),
      _vm.loading
        ? _c("LoadingScreen")
        : _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "illustration" }, [
              _vm.platformIllustrationSrc
                ? _c("img", {
                    staticClass: "platform-icon pulse",
                    attrs: { src: _vm.platformIllustrationSrc }
                  })
                : _vm._e()
            ]),
            _c("div", { staticClass: "title" }, [
              _vm._v("Login complete. You’re all set!")
            ]),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(" We successfully paired "),
              _c("u", [_vm._v(_vm._s(_vm.email))]),
              _vm._v(" with " + _vm._s(_vm.platformName) + "."),
              _c("br"),
              _vm._v(
                " Now head back and start using Anima plugins to create awesome prototypes. "
              )
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c("an-button", { on: { click: _vm.handleOpen } }, [
                  _vm._v("Back to " + _vm._s(_vm.platformName))
                ]),
                _c(
                  "an-link",
                  {
                    staticClass: "link",
                    attrs: { variant: "primary" },
                    on: { click: _vm.navigateToExplore }
                  },
                  [_vm._v("Explore Anima")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c("span", [_vm._v("Not " + _vm._s(_vm.email) + "? ")]),
                _c(
                  "an-link",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.switchAccount }
                  },
                  [_vm._v("Log in")]
                ),
                _c("span", [_vm._v(" with another account")])
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }