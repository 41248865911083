var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "domain-settings-container" }, [
        _c("div", { staticClass: "label" }, [_vm._v("Subdomain")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c("an-input", {
              attrs: {
                invalid: !_vm.isValidSubDomain,
                placeholder: "Your animaapp.io subdomain"
              },
              model: {
                value: _vm.projectFields.subdomain,
                callback: function($$v) {
                  _vm.$set(_vm.projectFields, "subdomain", $$v)
                },
                expression: "projectFields.subdomain"
              }
            }),
            _c(
              "span",
              {
                staticClass: "secondary-text",
                staticStyle: { "margin-left": "14px" }
              },
              [_vm._v(".animaapp.io")]
            )
          ],
          1
        ),
        _vm.subdomainError
          ? _c("div", { staticClass: "error-message" }, [
              _vm._v(" " + _vm._s(_vm.subdomainError) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "label" }, [_vm._v("Custom domain")]),
        _c(
          "div",
          { staticClass: "input custom-domain" },
          [
            _c("an-input", {
              attrs: { placeholder: "www.mydomain.com" },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.addCustomDomain.apply(null, arguments)
                }
              },
              model: {
                value: _vm.customDomain,
                callback: function($$v) {
                  _vm.customDomain = $$v
                },
                expression: "customDomain"
              }
            }),
            _c(
              "an-button",
              {
                attrs: { isWorking: _vm.isDomainWorking, variant: "secondary" },
                on: { click: _vm.addCustomDomain }
              },
              [_vm._v("Add")]
            )
          ],
          1
        ),
        _vm.customDomainsList.length
          ? _c(
              "div",
              { staticClass: "existing-domains" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v("Existing domains:")
                ]),
                _vm._l(_vm.customDomainsList, function(customDomain) {
                  return _c("CustomDomainRow", {
                    key: customDomain.domain,
                    attrs: {
                      customDomain: customDomain,
                      isDeleted: _vm.isDeleted(customDomain.domain)
                    },
                    on: {
                      remove: function($event) {
                        return _vm.markCustomDomainRemoved(customDomain)
                      },
                      "un-remove": function($event) {
                        return _vm.markCustomDomainNotRemoved(customDomain)
                      }
                    }
                  })
                })
              ],
              2
            )
          : _c("div", { staticClass: "pad" })
      ]),
      _c(
        "Collapse",
        {
          attrs: { title: "Site information" },
          nativeOn: {
            click: function($event) {
              return _vm.$trackEvent(
                "project-settings-meta.site-info-section.toggle"
              )
            }
          }
        },
        [
          _c("div", { staticClass: "pad" }, [
            _c(
              "div",
              { staticClass: "upload-icon" },
              [
                _c("UploadImage", {
                  attrs: {
                    url: _vm.uploadIamgeUrl("meta_favicon_url"),
                    src: _vm.project.meta_favicon_url
                  },
                  on: { success: _vm.reloadProject },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent(
                        "project-settings-meta.upload-favicon.click"
                      )
                    }
                  }
                }),
                _c("div", { staticClass: "upload-icon-text" }, [
                  _c("div", [_vm._v("Favicon")]),
                  _c("div", { staticClass: "secondary-text small" }, [
                    _vm._v("Upload a logo 32x32 pixels, PNG or JPG formats.")
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload-cover-image" },
              [
                _c("div", { staticClass: "label" }, [_vm._v("Cover image")]),
                _c("UploadImage", {
                  attrs: {
                    url: _vm.uploadIamgeUrl("meta_image_url"),
                    src: _vm.project.meta_image_url,
                    height: 140,
                    width: 240
                  },
                  on: { success: _vm.reloadProject },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent(
                        "project-settings-meta.upload-meta-image.click"
                      )
                    }
                  }
                }),
                _c("div", { staticClass: "secondary-text" }, [
                  _vm._v(
                    " This image shows when your site is shared on social networks like Facebook, Twitter, etc. "
                  )
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "mwa" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Mobile web app")]),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                "Show a custom app name and icon on mobile phone homescreens"
              )
            ]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-input", {
                  attrs: { placeholder: "Mobile web app name" },
                  model: {
                    value: _vm.projectFields.mobile_app_name,
                    callback: function($$v) {
                      _vm.$set(_vm.projectFields, "mobile_app_name", $$v)
                    },
                    expression: "projectFields.mobile_app_name"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload-icon" },
              [
                _c("UploadImage", {
                  attrs: {
                    url: _vm.uploadIamgeUrl("mobile_app_icon_url"),
                    src: _vm.project.mobile_app_icon_url
                  },
                  on: { success: _vm.reloadProject },
                  nativeOn: {
                    click: function($event) {
                      return _vm.$trackEvent(
                        "project-settings-meta.upload-mobile-app-icon.click"
                      )
                    }
                  }
                }),
                _c("div", { staticClass: "upload-icon-text" }, [
                  _c("div", [_vm._v("App icon")]),
                  _c("div", { staticClass: "secondary-text small" }, [
                    _vm._v("Upload an icon 180x180 pixels, in PNG format.")
                  ])
                ])
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "Collapse",
        {
          attrs: { title: "SEO Settings" },
          nativeOn: {
            click: function($event) {
              return _vm.$trackEvent("project-settings-meta.seo-section.toggle")
            }
          }
        },
        [
          _c("div", { staticClass: "pad" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Site title")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-input", {
                  attrs: { placeholder: "Add site title" },
                  model: {
                    value: _vm.projectFields.meta_title,
                    callback: function($$v) {
                      _vm.$set(_vm.projectFields, "meta_title", $$v)
                    },
                    expression: "projectFields.meta_title"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "label" }, [_vm._v("Site description")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-textarea", {
                  attrs: {
                    placeholder: _vm.siteDesctptionPlacholder,
                    rows: 3,
                    withBorder: "",
                    allowMultiline: ""
                  },
                  model: {
                    value: _vm.projectFields.meta_description,
                    callback: function($$v) {
                      _vm.$set(_vm.projectFields, "meta_description", $$v)
                    },
                    expression: "projectFields.meta_description"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "separated" }, [_vm._v("Analytics")]),
          _c("div", { staticClass: "pad" }, [
            _c("div", { staticClass: "label" }, [_vm._v("Custom HTML tags")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-textarea", {
                  attrs: {
                    placeholder: _vm.customHtmlTagsPlaceholder,
                    withBorder: "",
                    allowMultiline: ""
                  },
                  model: {
                    value: _vm.projectFields.meta_custom_snippet,
                    callback: function($$v) {
                      _vm.$set(_vm.projectFields, "meta_custom_snippet", $$v)
                    },
                    expression: "projectFields.meta_custom_snippet"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "label" }, [_vm._v("Analytics code")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-textarea", {
                  attrs: {
                    placeholder: _vm.analyticsCodePlaceholder,
                    withBorder: "",
                    allowMultiline: ""
                  },
                  model: {
                    value: _vm.projectFields.analytics_snippet,
                    callback: function($$v) {
                      _vm.$set(_vm.projectFields, "analytics_snippet", $$v)
                    },
                    expression: "projectFields.analytics_snippet"
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "Collapse",
        {
          attrs: { title: "Form submissions" },
          nativeOn: {
            click: function($event) {
              return _vm.$trackEvent(
                "project-settings-meta.submissions-section.toggle"
              )
            }
          }
        },
        [
          _c("div", { staticClass: "submissions pad" }, [
            _c(
              "div",
              { staticClass: "label" },
              [
                _c(
                  "an-link",
                  {
                    attrs: {
                      href: _vm.downloadCsvUrl,
                      variant: "primary",
                      download: ""
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.$trackEvent(
                          "project-settings-meta.download-csv.click"
                        )
                      }
                    }
                  },
                  [_vm._v(" Download CSV ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm.projectChanged
        ? _c(
            "div",
            { staticClass: "actions fixed" },
            [
              _c(
                "an-button",
                {
                  attrs: { isWorking: _vm.isWorking },
                  on: { click: _vm.save }
                },
                [_vm._v("Save changes")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }