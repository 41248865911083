var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-container",
      style: { cursor: _vm.isReply ? "default" : "pointer" },
      on: { click: _vm.commentBodyClicked }
    },
    [
      _c("an-user-avatar", {
        staticClass: "av",
        attrs: { user: _vm.comment.user, noBorder: "" }
      }),
      _c("div", { staticClass: "content" }, [
        !_vm.isCreate
          ? _c("div", { staticClass: "username-date" }, [
              _c("div", {
                staticClass: "username",
                domProps: { textContent: _vm._s(_vm.comment.user.name) }
              }),
              _c("div", {
                staticClass: "date",
                domProps: { textContent: _vm._s(_vm.createdAt) }
              }),
              _c(
                "div",
                { staticClass: "popover" },
                [
                  _c(
                    "PopoverMenu",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isReply,
                          expression: "!isReply"
                        }
                      ],
                      ref: "menu1",
                      attrs: {
                        popperClass: "omni-menu",
                        items: _vm.commentActions,
                        position: "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "y",
                          attrs: { slot: "reference", v: "" },
                          slot: "reference"
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon",
                            attrs: {
                              fill: "currentColor",
                              name: "three-dots-horizontal",
                              size: 18
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "PopoverMenu",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isReply && !_vm.readOnly,
                          expression: "isReply && !readOnly"
                        }
                      ],
                      ref: "menu2",
                      attrs: {
                        popperClass: "omni-menu",
                        items: _vm.replyActions,
                        position: "right"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "y",
                          attrs: { slot: "reference", v: "" },
                          slot: "reference"
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "icon",
                            attrs: {
                              fill: "currentColor",
                              name: "three-dots-horizontal",
                              size: 18
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.isFormOpen
          ? _c(
              "div",
              { staticClass: "create-container" },
              [
                _c("an-textarea", {
                  attrs: {
                    autoFocus: "",
                    rows: "1",
                    placeholder: "",
                    className: "comment-textarea"
                  },
                  on: { enter: _vm.handleSubmit },
                  model: {
                    value: _vm.newCommentText,
                    callback: function($$v) {
                      _vm.newCommentText = $$v
                    },
                    expression: "newCommentText"
                  }
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "padding-top": "4px",
                      "justify-content": "flex-end"
                    }
                  },
                  [
                    _c(
                      "an-button",
                      {
                        staticStyle: { "font-size": "16px", height: "30px" },
                        attrs: { variant: "secondary" },
                        on: { click: _vm.handleCancel }
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "an-button",
                      {
                        staticStyle: {
                          "margin-left": "8px",
                          "font-size": "16px",
                          height: "30px"
                        },
                        attrs: {
                          type: "submit",
                          variant: "primary",
                          isWorking: _vm.isWorking
                        },
                        on: { click: _vm.handleSubmit }
                      },
                      [_vm._v(" Save ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _c("div", [
              _vm.isCreate
                ? _c(
                    "p",
                    {
                      staticClass: "fakeTa",
                      on: {
                        click: function($event) {
                          _vm.isFormOpen = true
                        }
                      }
                    },
                    [_vm._v(" Add a comment... ")]
                  )
                : _c("p", {
                    staticClass: "x",
                    domProps: { textContent: _vm._s(_vm.comment.text) }
                  }),
              !_vm.isReply
                ? _c("div", { staticClass: "reply" }, [
                    _c(
                      "div",
                      { staticClass: "no-hover" },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: {
                            fill: "currentColor",
                            size: 20,
                            name: _vm.commentBreakpointIcon
                          }
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.comment.number_of_replies) + " replies"
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "hover",
                        on: { click: _vm.openCommentReplies }
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "icon",
                          attrs: {
                            fill: "currentColor",
                            size: 20,
                            name: "reply"
                          }
                        }),
                        _c("span", [_vm._v("reply")])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }