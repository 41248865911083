var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type === "all"
      ? _c("div", { staticClass: "empty-state-container" }, [
          _vm.isUserDeveloper
            ? _c(
                "div",
                [
                  _vm.isCreatingSampleProject || _vm.isSyncing
                    ? _c("LoadingScreen", {
                        attrs: { type: _vm.isSyncing ? "syncing" : "default" }
                      })
                    : _c("div", { staticClass: "developer-container" }, [
                        _c("div", { staticClass: "developer-illustration" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/illustrations/sample-design-box-developer.svg")
                            }
                          })
                        ]),
                        _c(
                          "div",
                          { staticClass: "developer-content" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Ready to turn design into code?")
                            ]),
                            _c("div", { staticClass: "body" }, [
                              _vm._v(
                                " Pick any component and get its code, HTML, React or Vue."
                              ),
                              _c("br"),
                              _vm._v(" Or, download a full web-page."),
                              _c("br"),
                              _c("br"),
                              _vm._v(
                                " To get started, let’s bring your design into Anima. "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "actions" },
                              [
                                _vm.isDesktop
                                  ? _c(
                                      "an-button",
                                      { on: { click: _vm.newProject } },
                                      [_vm._v("New Project")]
                                    )
                                  : _vm._e(),
                                _vm.isDesktop
                                  ? _c(
                                      "span",
                                      { staticClass: "or-separator" },
                                      [_vm._v("Or")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "an-button",
                                  {
                                    attrs: {
                                      variant: "empty",
                                      isWorking: _vm.isCreatingSampleProject
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.createSampleProjectAndReload(
                                          { sample_type: _vm.sampleType }
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Try our sample project ")]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "divider" }),
                            _c("div", { staticClass: "body" }, [
                              _vm._v(
                                " Don’t have a design? Start your first project by inviting your team designers to sync the design "
                              )
                            ]),
                            _c(
                              "an-button",
                              {
                                attrs: { variant: "secondary" },
                                on: { click: _vm.inviteToTeam }
                              },
                              [_vm._v("Invite to team")]
                            )
                          ],
                          1
                        )
                      ])
                ],
                1
              )
            : _c("div", [
                _c("div", { staticClass: "all-projects-header" }, [
                  _c("div", { staticClass: "all-projects-illustration" }),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("Create your first project")
                    ]),
                    _c("div", { staticClass: "body" }, [
                      _vm._v(
                        " Sync your design to Anima and get clean, Flexbox-based responsive code components in HTML, React or Vue. "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _vm.isDesktop
                          ? _c("an-button", { on: { click: _vm.newProject } }, [
                              _vm._v("New Project")
                            ])
                          : _vm._e(),
                        _vm.isDesktop
                          ? _c("span", { staticClass: "or-separator" }, [
                              _vm._v("Or")
                            ])
                          : _vm._e(),
                        _c(
                          "an-button",
                          {
                            attrs: {
                              variant: "empty",
                              isWorking: _vm.isCreatingSampleProject
                            },
                            on: { click: _vm.createSampleProjectAndReload }
                          },
                          [_vm._v(" Try our sample project ")]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "boxes" },
                  _vm._l(_vm.columns, function(c, index) {
                    return _c("EmptyStateBox", {
                      key: "col_" + index,
                      attrs: {
                        title: c.title,
                        content: c.content,
                        linkLabel: c.linkLabel
                      },
                      on: { "click-cta": c.onClick }
                    })
                  }),
                  1
                )
              ])
        ])
      : _vm.type === "archive"
      ? _c("div", { staticClass: "empty-state-container" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("No archived projects yet")
          ]),
          _c("div", { staticClass: "body" }, [
            _vm._v(
              " Here you can store team projects that are no longer active "
            )
          ])
        ])
      : _vm.type === "shared"
      ? _c("div", { staticClass: "empty-state-container" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Hey look, nothing!")]),
          _c("div", { staticClass: "body" }, [
            _vm._v("Seems like no one shared projects with you yet...")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }