var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Project settings")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v(
        "Manage project settings and guest access for " +
          _vm._s(_vm.project.name)
      )
    ]),
    _c(
      "div",
      { staticClass: "navigation" },
      [
        _c("Tabs", {
          attrs: { activeTabLabel: _vm.activeTabLabel, items: _vm.tabs },
          on: { change: _vm.onTabChange }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c(
          _vm.component,
          _vm._b(
            { tag: "component", on: { close: _vm.close } },
            "component",
            _vm.props || {},
            false
          )
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }