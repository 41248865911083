<template>
  <header @mouseleave="handleNavigatorMouseLeave" :style="getHeaderStyle" class="header">
    <!-- NAVIGATOR -->
    <NetflixMenu @handleScreenChange="handleScreenChange" />
    <!-- LOGO -->
    <router-link tag="div" :to="{ name: 'team', params: { teamSlug: $route.params.teamSlug } }" class="logo">
      <an-logo type="mini" />
    </router-link>
    <!-- SCREEN -->
    <div :style="getHeaderTitleStyle" class="titles">
      <div class="project-screen">
        <router-link
          tag="div"
          :to="{ name: 'project', params: { teamSlug: $route.params.teamSlug, projectId: $route.params.projectId } }"
          class="project-title"
          >{{ currentProject.name }}</router-link
        >
        <svg-icon :size="16" class="t-icon" fill="currentColor" name="arrow-right"></svg-icon>
        <div id="netflix-menu-trigger" class="screen-title" @click="toggleNavigator({})">
          <span>{{ currentComponent.name }}</span>
          <svg-icon class="icon" :size="10" fill="currentColor" name="carret-down"></svg-icon>
        </div>
      </div>
      <div v-show="!isMobile" class="info" v-tip="lastUpdateInfo">
        Screen {{ getScreenNumber }} of {{ this.currentComponents.length }}. {{ updatedAt }}
      </div>
    </div>
    <!-- MODE -->
    <template v-if="!isMobile">
      <div class="mode-breakpoints">
        <div class="mode-selector">
          <div
            :class="{ selected: activeMode.name == mode.name }"
            @click="handleModeChange({ mode })"
            class="mode-container"
            v-for="(mode, i) in modes"
            :key="i"
            data-cy="mode-container"
          >
            <template v-if="mode.name == 'C'">
              <an-tip-big
                :showBanner="true"
                trigger="manual"
                :open="openCodeTooltip"
                @close="openCodeTooltip = false"
                data-cy="explore-popover"
              >
                <template slot="popover-caller">
                  <div class="flex flex-col items-center">
                    <svg-icon fill="currentColor" class="mode-icon" :size="20" :name="mode.icon"></svg-icon>
                    <div class="mode-text">{{ mode.displayName }}</div>
                  </div>
                </template>
                <template slot="popover-title">
                  Explore Anima Code
                </template>
                <template slot="popover-text">
                  Get clean, Flexbox-based responsive code components in HTML, React or Vue, with zero dependencies and
                  minimal code refactoring.
                </template>
                <template slot="popover-footer">
                  <img :src="require('@/assets/svg/tooltip-code-example.svg')" />
                </template>
              </an-tip-big>
            </template>

            <template v-else>
              <svg-icon fill="currentColor" class="mode-icon" :size="20" :name="mode.icon"></svg-icon>
              <div class="mode-text">{{ mode.displayName }}</div>
            </template>
          </div>
        </div>
        <!-- BREAKPOINTS -->
        <BreakpointsSelector
          v-if="!isComponentView"
          @breakpointChange="handleBreakpointChange"
          @handleScreenChange="handleScreenChange"
        />
      </div>

      <div class="actions">
        <v-tip :trigger="'hover'" offset="10">
          <an-button
            :isWorking="isPublishing"
            v-if="isAbleToPublish"
            @click="handlePublish"
            variant="secondary"
            size="sm"
            >Publish</an-button
          >

          <template slot="popover">
            <div class="publish-tip">
              Publish your latest changes to your custom domain.
            </div>
          </template>
        </v-tip>

        <an-button v-if="isPublished" @click="handleOpenCustomDomain" variant="secondary" size="sm">
          Visit site
        </an-button>
        <an-link v-else-if="!isPro" class="go-pro-button" variant="primary" @click="goPro">
          <div class="go-pro-button">
            <svg-icon name="rocket-pro" :size="20" />
            <span>Go Pro</span>
          </div>
        </an-link>
        <an-button
          data-cy="header-export-button"
          @click="handleDownloadCodePopup"
          style="margin-left:15px"
          :isWorking="isExportLoading"
          size="sm"
          variant="secondary"
        >
          Export Code
        </an-button>

        <an-button style="margin-left:15px" @click="handleShare" variant="primary" size="sm">Share</an-button>
        <v-tip :trigger="'hover'" :delay="{ show: 400, hide: 0 }">
          <an-link style="margin-left:10px; margin-top:5px" @click="copyUrlToClipboard" data-cy="copy-link-button">
            <svg-icon name="link" fill="white" :size="24"></svg-icon>
          </an-link>
          <template slot="popover">
            <div class="br-tip">
              <div class="br-tip-title">
                Copy Link
              </div>
            </div>
          </template>
        </v-tip>
      </div>
    </template>
  </header>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import { formatDateTimeConversational } from '@/utils/date';
import BreakpointsSelector from '@/components/OmniView/Header/BreakpointsSelector.vue';
import copy from '@/utils/copyToClp';
import { EventBus, openModal, toastSuccess } from '@/services/bus';
import { mapWidthToBreakpointText } from '@/components/OmniView/utils';
import downloadCodeMixin from '@/components/OmniView/downloadCodeMixin';
import downloadCodeMixinLegacy from '@/components/OmniView/downloadCodeMixinLegacy';
import NetflixMenu from '@/components/OmniView/Header/NetflixMenu';
import { getCdnUrl } from '@/utils/urls';
import { TeamMixin } from '@/mixins';

export default {
  components: {
    BreakpointsSelector,
    NetflixMenu
  },
  mixins: [downloadCodeMixin, downloadCodeMixinLegacy, TeamMixin],
  data() {
    return {
      openCodeTooltip: false,
      isPublishing: false,
      codePrefsOpen: false
    };
  },
  mounted() {
    this._type = 'Flex';
    EventBus.$on('handleScreenChange', this.handleScreenChange);

    if (!localStorage.getItem('alreadyVisitedOMNI') && this.hasPermissions()) {
      this.openCodeTooltip = true;
      localStorage.setItem('alreadyVisitedOMNI', true);
    }
    if (this.$route.query.utm_source === 'copylink') {
      if (!this.isUserDeveloper) {
        // Redirecting a non developer user to the play mode
        this.$router.replace(this.$route.path);
      }
    }
  },
  destroyed() {
    EventBus.$off('handleScreenChange', this.handleScreenChange);
  },
  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('projectReleases', { currentProjectRelease: 'currentItem' }),
    ...mapState('components', { currentComponents: 'items' }),
    ...mapState('omniview', { compareOpacity: 'compareOpacity' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('domains', { domains: 'items' }),
    ...mapGetters({
      currentComponent: 'components/currentComponent',
      modes: 'omniview/modes',
      breakpoints: 'omniview/breakpoints',
      activeMode: 'omniview/activeMode',
      activeBreakpoint: 'omniview/activeBreakpoint',
      currentFrameWidth: 'omniview/currentFrameWidth',
      hasPermissions: 'teamMemberships/hasPermissions',
      isExportAllowed: 'omniview/isExportAllowed',
      codeDownloadPrefs: 'omniview/codeDownloadPrefs',
      isComponentView: 'webComponents/isComponentView',
      isPro: 'omniview/isPro',
      isUserDeveloper: 'users/isUserDeveloper'
    }),
    generateShareUrl() {
      const { model_id: layer, slug: screenSlug } = this.currentComponent;
      const { short_id: projectId, slug: teamSlug } = this.currentProject;
      const path = {
        name: 'omniview',
        params: { teamSlug, projectId, screenSlug },
        query: { mode: 'code', layer: layer, utm_source: 'copylink' }
      };
      const resolved = this.$router.resolve(path);
      return `${process.env.APP_BASE_URL}${resolved.href}`;
    },
    getHeaderTitleStyle() {
      return {
        'font-size': this.isMobile ? '12px' : '14px'
      };
    },

    getHeaderStyle() {
      return {
        height: this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)',
        'min-height': this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)'
      };
    },
    isAbleToPublish() {
      const { domain_project_release, live_project_release } = this.currentProject;
      return this.domains.length > 0 && live_project_release != domain_project_release;
    },
    isPublished() {
      const { domain_project_release, live_project_release } = this.currentProject;
      return this.domains.length > 0 && live_project_release == domain_project_release;
    },
    getScreenNumber() {
      const index = this.currentComponents.map(c => c.id).indexOf(this.currentComponent.id);
      if (index == -1) {
        return 1;
      }
      return index + 1;
    },
    lastUpdateInfo() {
      if (this.currentComponent.plugin_name && this.currentComponent.owner_name) {
        return `Last updated by ${this.currentComponent.owner_name} from ${this.currentComponent.plugin_name}`;
      } else {
        return null;
      }
    },
    updatedAt() {
      return this.currentComponent.updated_at
        ? 'Last updated ' + formatDateTimeConversational(this.currentComponent.updated_at, true)
        : '';
    },
    isExportLoading() {
      return this.exportCodeLoading;
    }
  },
  methods: {
    mapWidthToBreakpointText,
    ...mapMutations({
      resetSelection: 'omniview/resetSelection',
      setCompareOpacity: 'omniview/setCompareOpacity',
      selectScreen: 'components/setCurrentItem',
      setCodeDownloadPrefs: 'omniview/setCodeDownloadPrefs'
    }),
    ...mapActions({
      handleModeChange: 'omniview/handleModeChange',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    handleDownloadCodePopup() {
      this.$trackEvent('omniview.export-code.button-clicked');
      openModal({
        name: 'export-code',
        variant: 'center',
        opacity: 0.3,
        mode: 'dark',
        whiteOverlay: true,
        background: '#2d2d2d',
        width: 500,
        closeButton: true,
        props: {
          eventSource: 'omniview',
          upgradePlanPageParams: {
            source: 'omniview',
            nextPage: this.$route,
            afterClosing: this.handleDownloadCodePopup
          }
        }
      });

      this.$gtm.trackEvent({
        event: 'export_code_button',
        event_category: 'Export Code Button',
        event_action: this.currentUser?.latest_paired_design_tool,
        event_label: this.codeDownloadPrefs.framework
      });
    },
    goPro() {
      const query = {
        interval: 'annually',
        plan: 'pro'
      };
      this.$router.push({
        name: 'team-payment',
        query,
        params: {
          source: 'omniview_get_pro',
          nextPage: this.$route
        }
      });
      this.$trackEvent('omniview.header-upgrade-button.click');
    },
    handleNavigatorMouseLeave(e) {
      if (e.toElement && !this.$el.contains(e.toElement) && ![...e.toElement.classList].includes('tooltip-inner')) {
        this.toggleNavigator({ forceClose: true });
      }
    },
    toggleNavigator(params = {}) {
      EventBus.$emit('toggle-netflix-memu', params);
    },
    async handlePublish() {
      if (this.isPublishing) return;
      try {
        const { projectId } = this.$route.params;
        const { live_project_release } = this.currentProject;
        this.isPublishing = true;
        await this.updateProject({
          id: projectId,
          payload: {
            domain_project_release: {
              type: 'project_releases',
              id: live_project_release
            }
          }
        });
        await this.fetchProject({ id: projectId, skipCache: true });
      } catch (error) {
        console.log(error);
      } finally {
        this.isPublishing = false;
      }
    },
    handleOpenCustomDomain() {
      const url = 'http://' + this.domains[0].domain;
      const win = window.open(url, '_blank');
      win.focus();
    },
    handleShare() {
      this.$trackEvent('omniview.share-button.click');
      openModal({
        name: 'project-invite',
        props: { eventSource: 'omniview' },
        onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
      });
    },
    handleBreakpointChange(e) {
      this.$emit('breakpointChange', e);
    },
    handleScreenChange({ component = null, screenSlug = '', fetchBreakpoints = true, callbackEvent = false }) {
      const { projectId } = this.$route.params;
      const { mode } = this.$route.query;

      if (!component && screenSlug) {
        component = this.currentComponents.find(c => c.slug == screenSlug);
      }

      this.resetSelection();

      this.$router
        .replace({
          name: 'omniview',
          params: { projectId: projectId, screenSlug: component.slug },
          query: { mode: mode ? mode : 'play' }
        })
        .then(() => {
          this.selectScreen(component);
          EventBus.$emit('update-layer-whiteList');
          this.$emit('screenChange', {
            fetchBreakpoints
          });
          if (callbackEvent) {
            EventBus.$emit(callbackEvent.name, callbackEvent.params);
          }
        })
        .catch(() => {});
    },

    getScreenThumb(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      let thumb = thumbnails_urls && thumbnails_urls['640x640'];
      thumb = getCdnUrl(thumb || thumb_url || thumbnail_url, { width: 160, height: 90 });
      return {
        'background-image': `url('${thumb}')`
      };
    },
    copyUrlToClipboard() {
      this.$trackEvent('omniview.copy-link-button.click');
      copy(this.generateShareUrl);
      toastSuccess('The link to this screen was copied to the clipboard.');
    }
  },
  watch: {
    activeMode({ displayName } = {}) {
      if (displayName) this.$trackEvent('omniview.mode.switch', { mode: displayName });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Header';
.publish-tip {
  width: 218px;
  background: #333333;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  color: white;
  border-radius: 10px;
  padding: 20px;
}
.download-tip {
  background: #333333;
  width: 336px;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(black, 0.2);
  &-item {
    padding: 16px 20px;
    // &:not(:last-child) {
    //   margin-bottom: 16px;
    // }
  }
  &-title {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  &-text {
    font-size: 12px;
    line-height: 18px;
    opacity: 0.4;
    margin-bottom: 10px;
  }
  .badge {
    border-radius: 100px;
    width: 41px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    &.new {
      background: #009379;
      margin-left: auto;
    }
    &.pro {
      background: #3b3b3b;
      margin-left: 16px;
    }
  }
  .settings-icon {
    opacity: 0.4;
    position: relative;
    left: -4px;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &.active {
      color: var(--primary);
      opacity: 1;
    }
  }
  .action {
    color: var(--primary);
    cursor: pointer;
    font-size: 12px;
  }
  .hr {
    height: 1px;
    width: 100%;
    background: #2d2d2d;
  }
}
.go-pro-button {
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 4px;
  }
}
.br-tip {
  margin-right: 20px;
  max-width: 207px;
  padding: 10px;
  background: #333333;
  color: white;
  border-radius: 10px;
}
.br-tip-title {
  font-size: 14px;
  text-transform: capitalize;
}
.titles {
  .project-title {
    padding-right: 3px;
  }
  .screen-title span {
    padding-right: 3px;
  }
  .info {
    font-size: 12px;
  }
  .project-screen {
    max-width: 260px;
  }
  .project-screen .icon {
    margin: 2px 5px 0 5px;
    opacity: 1;
  }
  .project-screen .t-icon {
    opacity: 1;
    margin: 0;
  }
}
</style>
