var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "an-select",
      attrs: { tabindex: 0 },
      on: {
        blur: function($event) {
          _vm.open = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "selected",
          class: {
            open: _vm.open,
            placeholder: !_vm.selected
          },
          on: {
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.selected ? _vm.getSelectedString() : _vm.placeholder) +
              " "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "options", class: { hide: !_vm.open } },
        _vm._l(_vm._options, function(option, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "option",
              attrs: {
                "data-cy":
                  "option-" + option.value.replace(/\s+/g, "-").toLowerCase()
              },
              on: {
                click: function($event) {
                  return _vm.selectItem(option)
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(option.label))]),
              _vm.selected === option.label
                ? _c("svg-icon", {
                    staticClass: "check-icon",
                    attrs: { size: 18, name: "plus" }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }