import { isDev } from '@/utils/environment';

export const checkAppStatus = async () => {
  if (isDev()) return 'ok';

  try {
    const res = await fetch('https://32ry8y9mbwg7.statuspage.io/api/v2/summary.json');
    const { status } = await res.json();

    if (!status?.indicator || status.indicator === 'none') {
      return 'ok';
    }
    return 'problem';
  } catch (err) {
    err;
  }
  return 'ok';
};
