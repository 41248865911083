<template>
  <div
    ref="plusIcon"
    :class="['clickable-icon', $theme, { focus, submitted }]"
    @mouseover="hoverIcon = true"
    @mouseout="hoverIcon = false"
    @click="$emit('click')"
  >
    <Lottie v-if="submitted" fileName="circle-check" autoplay :height="44" :width="44" />
    <svg-icon v-else :name="plusIconName" :size="36" />
  </div>
</template>

<script>
import Lottie from '@/components/Lottie/Lottie';
import { colorCircleChildren } from '@/utils/colors';

export default {
  data() {
    return {
      hoverIcon: false
    };
  },
  props: {
    focus: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Lottie
  },
  computed: {
    plusIconName() {
      const { $theme, hoverIcon } = this;
      return $theme === 'dark' || hoverIcon ? 'circle-plus-dark' : 'circle-plus';
    }
  },
  methods: {
    changeIcon() {
      const themeColor = this.$theme === 'dark' ? 'var(--white)' : 'var(--secondary)';
      const color = this.hoverIcon ? 'var(--red)' : themeColor;
      colorCircleChildren(this.$refs.plusIcon, color);
    }
  },
  watch: {
    hoverIcon() {
      this.changeIcon();
    }
  }
};
</script>

<style lang="scss" scoped>
.clickable-icon {
  height: 36px;
  width: 36px;
  opacity: 0.4;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    background-color: var(--primary);
  }
  &.submitted {
    height: 44px;
    width: 44px;
    &:hover {
      background-color: transparent;
    }
  }
  &.focus,
  &.submitted {
    opacity: 1;
  }
}
</style>
