import { upperFirst, lowerFirst } from 'lodash-es';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';
dayjs.extend(relativeTime);
dayjs.extend(UTC);

const deserializeDate = dateString => {
  if (!dateString) return;
  return new Date(dateString + `${dateString.endsWith('Z') ? '' : 'Z'}`);
};

export const formatDate = (date, format = 'MMMM D, YYYY') => (date ? dayjs(date).format(format) : date);

export const formatDateTime = (date, format = 'MMMM D, YYYY, h:mm A') => (date ? dayjs(date).format(format) : date);

export const formatDateTimeForAPI = date =>
  date
    ? dayjs(date)
        .utc()
        .format()
    : date;

export const formatDateTimeConversational = (date, useLowerFirst = false) => {
  if (!date) {
    return date;
  }
  let rv = dayjs(deserializeDate(date)).fromNow();
  if (useLowerFirst) {
    rv = lowerFirst(rv);
  } else {
    rv = upperFirst(rv);
  }
  return rv;
};

export const formatDateTimeShort = date => {
  let format = formatDateTimeConversational(date);
  if (format) {
    if (format.startsWith('A ')) {
      format = '1 ' + format.substring(2);
    }

    format = format.replace(/ years?/, 'y');
    format = format.replace(/ months?/, 'mo');
    format = format.replace(/ weeks?/, 'w');
    format = format.replace(/ days/, 'd');
    format = format.replace(/ hours?/, 'h');
    format = format.replace(/ minutes?/, 'm');
    format = format.replace(/ seconds?/, 's');
  }
  return format;
};
