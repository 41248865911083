var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overrides" },
    [
      _c("element-overrides", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selected,
            expression: "selected"
          }
        ]
      }),
      _c("all-overrides", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.selected,
            expression: "!selected"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }