<template>
  <div class="omniview-container">
    <template v-if="!isMobile">
      <div v-if="notFoundError || componentNotFoundError" class="loader">
        <PageNotFound :resource="componentNotFoundError ? 'component' : 'project'" />
      </div>
      <div v-else-if="errors.major" class="loader">
        {{ errors.major }}
      </div>
      <div v-else-if="!ready" class="loader">
        <LoadingScreen
          :delay="false"
          theme="dark"
          :type="currentProject && currentProject.is_syncing ? 'syncing' : 'default'"
        />
      </div>
      <transition name="fadeIn">
        <div class="wrapper" v-show="ready && !errors.major">
          <Header v-show="!isFullScreen && !showOnlyCode" @screenChange="reactToScreenChange" />
          <main :style="{ paddingTop: getPaddingTop }" id="omniview-content">
            <ComponentsSidebar v-if="showComponentsSidebar" v-show="!showOnlyCode" />
            <div
              :style="{
                height: `calc(100% - ${panelHeight}px)`,
                paddingBottom: getPaddingBottom,
                position: 'relative'
              }"
              ref="mainContent"
              class="main-content flex flex-col"
            >
              <MainFrame v-show="!showOnlyCode" />

              <!-- ARROWS -->
              <ScreenNavArrow side="right" @click="reactToScreenChange" :show="showNavigationArrows" />
              <ScreenNavArrow side="left" @click="reactToScreenChange" :show="showNavigationArrows" />

              <!-- SIDEBAR -->
              <!-- PANEL -->

              <Panels
                :style="{ width: mainContentWidth + 'px', ...(!showPanel ? { maxHeight: 0 } : {}) }"
                @generateCode="generateCode"
                @toggle="togglePanel"
              />
            </div>
            <Sidebar v-show="!showOnlyCode" />
          </main>
        </div>
      </transition>
    </template>
    <template v-else>
      <div v-if="notFoundError || componentNotFoundError" class="loader">
        <PageNotFound :resource="componentNotFoundError ? 'component' : 'project'" />
      </div>
      <transition v-else name="fade">
        <div class="wrapper" v-show="ready && !errors.major">
          <Header @screenChange="reactToScreenChange" />
          <main :style="{ paddingTop: getPaddingTop }" id="omniview-content">
            <div :style="{ height: '100%', paddingBottom: getPaddingBottom }" ref="mainContent" class="main-content">
              <MainFrame />
            </div>
          </main>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import api, { fetchApiWithCache } from '@/api';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { uuid } from '@/utils/uuid';
import { EventBus, hideBanner, openModal } from '@/services/bus';
import {
  CLOSE_PANEL,
  HIGHLIGHT_COMMENT,
  SELECT_OVERRIDE_NODE,
  SEND_COMPONENT_MESSAGE,
  SEND_MESSAGE,
  UPDATE_DEV_PREFS
} from '@/utils/events/omniviewEvents';
import Header from '@/components/OmniView/Header/OmniviewHeader.vue';
import Sidebar from '@/components/OmniView/Sidebar/Sidebar.vue';
import ComponentsSidebar from '@/components/OmniView/Sidebar/ComponentsSidebar.vue';
import MainFrame from '@/components/OmniView/MainFrame.vue';
import Panels from '@/components/OmniView/Panels/Panels.vue';
import ScreenNavArrow from '@/components/OmniView/ScreenNavArrow';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import PageNotFound from '@/views/PageNotFound';
import auth from '@/auth';
import { dataURLtoFile, filterObject, isSameRoute } from '@/utils/javascript';
import axios from 'axios';
import { capitalize, get, has, isEmpty } from 'lodash-es';
import codegenMixin from '@/components/OmniView/codegenMixin';
import panelMixin from '@/components/OmniView/panelMixin';
import CodePrefsMixin from '@/components/OmniView/CodePrefsMixin';
import { SocketMixin } from '@/mixins';
import { generateModelNodesMapWorker } from '@/components/OmniView/workerFunctions';
import { isValidURL } from '@/utils/urls';

export default {
  components: {
    Header,
    Sidebar,
    ComponentsSidebar,
    MainFrame,
    Panels,
    ScreenNavArrow,
    LoadingScreen,
    PageNotFound
  },
  mixins: [codegenMixin, panelMixin, SocketMixin, CodePrefsMixin],
  data() {
    return {
      notFoundError: false,
      componentNotFoundError: false,
      loading: {
        breakpoints: true,
        fetching: true,
        comments: true,
        assets: true,
        project: true,
        release: true,
        domains: false,
        metadata: true,
        model: {}
      },
      errors: {
        major: false,
        comments: false,
        assets: false,
        breakpoints: false
      },
      isSidebarMinimized: false,
      paywallCounter: 0
    };
  },
  computed: {
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('omniview', { isCompareEnabled: 'isCompareEnabled' }),
    ...mapState('omniview', { isFullScreen: 'isFullScreen' }),
    ...mapState('omniview', { showOnlyCode: 'showOnlyCode' }),
    ...mapState('omniview', { isChromeExtension: 'isChromeExtension' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('releases', { currentRelease: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapState('projects', { registryUrl: 'registryUrl' }),
    ...mapState('componentsMetadata', { metadatas: 'items' }),
    ...mapGetters({
      activeMode: 'omniview/activeMode',
      nodes: 'omniview/nodes',
      currentNode: 'omniview/currentNode',
      modes: 'omniview/modes',
      currentComponentMetadata: 'componentsMetadata/currentComponentMetadata',
      queue: 'omniview/queue',
      isAnimaScriptReady: 'omniview/isAnimaScriptReady',

      codegenLang: 'omniview/codegenLang',
      codegenHTMLLayout: 'omniview/codegenHTMLLayout',
      codegenReactStyle: 'omniview/codegenReactStyle',
      codegenVueStyle: 'omniview/codegenVueStyle',
      codegenReactSyntax: 'omniview/codegenReactSyntax',
      isGeneratingCode: 'omniview/isGeneratingCode',
      currentMasterSlug: 'omniview/currentMasterSlug',
      nodesWithOverrides: 'omniview/nodesWithOverrides',
      isMultipleSelectionEnabled: 'omniview/isMultipleSelectionEnabled',
      multiSelectedNodes: 'omniview/multiSelectedNodes',
      captureType: 'omniview/captureType',
      isComponentView: 'webComponents/isComponentView',
      projectSlugs: 'omniview/projectSlugs',
      hasPermissions: 'teamMemberships/hasPermissions',
      panelHeight: 'omniview/panelHeight',
      similarScreensIdsTemp: 'components/similarScreensIdsTemp'
    }),
    ready() {
      const { component } = this.$route.query;
      const baseCondition = !this.loading.fetching && !this.currentProject.is_syncing;
      if (component) {
        return baseCondition && !this.isWebComponentsLoading && !this.componentNotFoundError;
      }
      return baseCondition;
    },

    selected() {
      const { layer } = this.$route.query;
      return !!this.currentNode.id || !!layer;
    },
    getPaddingBottom() {
      let p = 0;
      // if (this.isCompareEnabled || this.activeMode.name == 'In' || this.isMobile) return p;

      // if (this.activeMode.name == 'Co') {
      //   p = this.selected ? '20px' : '0';
      // }
      // if (this.activeMode.name == 'C') {
      //   p = this.isMultipleSelectionEnabled || this.isComponentView ? '40px' : this.selected ? '60px' : '40px';
      // }

      return p;
    },
    getPaddingTop() {
      if (this.isFullScreen) return 0;
      const p = this.isMobile ? 'var(--omniview-topbar-height-mobile)' : 'var(--omniview-topbar-height)';
      return p;
    },
    isPlayMode() {
      return this.activeMode.name === 'In';
    },
    showNavigationArrows() {
      const { isPlayMode, allCurrentComponents, ready } = this;
      return ready && isPlayMode && allCurrentComponents?.length > 1;
    },

    showComponentsSidebar() {
      const { activeMode } = this;
      return activeMode.name === 'C';
    }
  },
  created() {
    this.init();
  },
  mounted() {
    const { breakpoint } = this.$route.params;

    if (breakpoint && breakpoint == 'res') {
      this.setActiveBreakpoint({ id: 'res' });
    }

    hideBanner();
    this.openDownloadExportCodeModalIfNeeded();
    this.openDownloadPackageModalIfNeeded();

    this.$intercom.boot({
      user_id: this.currentUser.id,
      name: this.currentUser.name,
      email: this.currentUser.email
    });

    this.$intercom._call('onShow', this.trackIntercomShow);

    this.$intercom.update({ hide_default_launcher: true });
    window.addEventListener('message', this.messageListener, false);
    EventBus.$on('regenerate', this.handleRegenerate);
    EventBus.$on('update-layer-whiteList', this.fetchLayerWhiteList);

    EventBus.$on('generate-component-code', this.generateComponentCode);
    EventBus.$on('update-anima-scripts-modelNodes', this.populateModelNodes);
    EventBus.$on('autosize', this.autosize);
    EventBus.$on(UPDATE_DEV_PREFS, this.updateDeveloperPreferences);

    this.$tracking.setUserEmail(this.currentUser.email);
    this.$tracking.setUserId(this.currentUser.id);
    this.$tracking.setContext({ latest_paired_design_tool: this.currentUser.latest_paired_design_tool });
    this.$tracking.alias(this.currentUser.id);
  },
  destroyed() {
    this.$intercom.update({ hide_default_launcher: false });
    this.cleanup();
    this.setIsPopulatingComponentFrame({ flag: true });
    window.removeEventListener('message', this.messageListener, false);
    EventBus.$off('generate-component-code', this.generateComponentCode);
    EventBus.$off('regenerate', this.handleRegenerate);
    EventBus.$off('update-layer-whiteList', this.fetchLayerWhiteList);
    EventBus.$off(UPDATE_DEV_PREFS, this.updateDeveloperPreferences);

    EventBus.$off('update-anima-scripts-modelNodes', this.populateModelNodes);

    this.resetTrackingData();
  },
  watch: {
    currentProject: {
      handler: 'handleProjectChange',
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setBreakpoints: 'omniview/setBreakpoints',
      setIsCompareEnabled: 'omniview/setIsCompareEnabled',
      setActiveBreakpoint: 'omniview/setActiveBreakpoint',
      setIsAnimaScriptReady: 'omniview/setIsAnimaScriptReady',
      setCurrentProject: 'projects/setCurrentItem',
      setCurrentNode: 'omniview/setCurrentNode',
      setIsFullScreen: 'omniview/setIsFullScreen',
      setShowOnlyCode: 'omniview/setShowOnlyCode',
      setCurrentNodeHTML: 'omniview/setCurrentNodeHTML',
      setCurrentNodeJSX: 'omniview/setCurrentNodeJSX',
      setCurrentNodeCSS: 'omniview/setCurrentNodeCSS',
      setCurrentNodePath: 'omniview/setCurrentNodePath',
      setIsGeneratingCode: 'omniview/setIsGeneratingCode',
      selectScreen: 'components/setCurrentItem',
      setCurrentComponentData: 'components/setCurrentComponentData',
      setProjectAssetsRegistry: 'projects/setProjectAssetsRegistry',
      setRegistryUrl: 'projects/setRegistryUrl',
      setIsWaitingForOverrides: 'omniview/setIsWaitingForOverrides',
      setIsExportAllowed: 'omniview/setIsExportAllowed',
      setNodes: 'omniview/setNodes',
      setMetadata: 'componentsMetadata/setMetadata',
      setCommentsSubView: 'omniview/setCommentsSubView',
      setIsSidebarMinimized: 'omniview/setIsSidebarMinimized',
      resetSelection: 'omniview/resetSelection',
      setDomLoading: 'omniview/setDomLoading',
      setIframeLoading: 'omniview/setIframeLoading',
      setCurrentMasterSlug: 'omniview/setCurrentMasterSlug',
      setNodesWithOverrides: 'omniview/setNodesWithOverrides',
      setCodegenLang: 'omniview/setCodegenLang',
      setMultiSelectedNodes: 'omniview/setMultiSelectedNodes',
      setCurrentNodeMd5Map: 'omniview/setCurrentNodeMd5Map',
      setIsModelLoading: 'omniview/setIsModelLoading',
      setSuggestedComponents: 'codegen/setSuggestedComponents',
      setBase64Screenshot: 'omniview/setBase64Screenshot',
      setProjectSlugs: 'omniview/setProjectSlugs',
      setExtraTrackingData: 'tracking/setExtraData'
    }),
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      fetchStyleguide: 'styleguide/fetchAllOfParent',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchMetadata: 'componentsMetadata/fetchMetadata',
      updateMetadata: 'componentsMetadata/update',
      createMetadata: 'componentsMetadata/create',
      fetchProject: 'projects/fetchOne',
      updateProject: 'projects/update',
      fetchSingleComponent: 'components/fetchOne',
      fetchProjectRelease: 'projectReleases/fetchOne',
      fetchRelease: 'releases/fetchOne',
      fetchComponents: 'components/fetchAllOfParent',
      fetchCustomDomains: 'domains/fetchAllOfParent',
      cleanup: 'omniview/cleanup',
      fetchProjectGuests: 'projectGuests/fetchAllOfParent',
      getNodesWithOverridesData: 'omniview/getNodesWithOverridesData',
      updateNodeOverrides: 'componentsMetadata/updateNodeOverrides',
      trackIntercomShow: 'tracking/trackIntercomShow',
      pollSyncingProject: 'projects/pollSyncingProject'
    }),
    async init() {
      this.checkIfExportAllowed();
      await this.loadData();
    },

    initSocket() {
      const { projectId } = this.$route.params;
      this.openSocket(this.currentProject.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'project', action: 'updated' }, project => {
        if (!project || !project.id) return;

        const { is_pre_process_running, is_syncing } = this.currentProject;

        if (is_pre_process_running && !project.is_pre_process_running) {
          this.fetchDBWebComponents({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: { skip_cache: true, get_all: true }
          });
        }

        if (isEmpty(this.currentStyleguide) && project.is_styleguide_generated) {
          this.fetchStyleguide({
            parent: 'projects',
            id: projectId,
            skipCache: true,
            params: { skip_cache: true }
          });
        }

        // set a delay if the project done syncing
        if (is_syncing && !project.is_syncing) {
          setTimeout(() => {
            this.setCurrentProject(project);
          }, 1500);
        } else {
          this.setCurrentProject(project);
        }
      });
    },

    openDownloadPackageModalIfNeeded() {
      const { package_download: base64Url } = this.$route.query;
      const downloadUrl = window?.atob(base64Url || '');
      if (isValidURL(downloadUrl)) {
        openModal({
          name: 'package-ready',
          variant: 'center',
          width: 500,
          closeButton: true,
          mode: 'dark',
          props: { downloadUrl }
        });
      }
    },

    openDownloadExportCodeModalIfNeeded() {
      const { modal } = this.$route.query;
      if (modal === 'export') {
        openModal({
          name: 'export-code',
          variant: 'center',
          opacity: 0.3,
          mode: 'dark',
          whiteOverlay: true,
          background: '#2d2d2d',
          width: 500,
          closeButton: true,
          props: { eventSource: 'omniview' }
          // onCloseRedirect: { name: 'omniview', params: { ...this.$route.params }, query: { ...this.$route.query } }
        });
      }
    },

    setDeveloperPreferencesLocally({ developer_preferences = {} } = {}) {
      const { lang, react_style, react_syntax, html_layout, vue_style } = developer_preferences;

      if (lang) EventBus.$emit('set-code-language', { name: lang });
      if (html_layout) this.handleHTMLLayoutChange({ value: html_layout });
      if (react_style) this.handleReactStyleChange({ value: react_style });
      if (react_syntax) this.handleReactSyntaxChange({ value: react_syntax });
      if (vue_style) this.handleVueStyleChange({ value: vue_style });
    },

    handleProjectChange(project) {
      this.setDeveloperPreferencesLocally(project);
      this.setTrackingData();
    },

    async updateDeveloperPreferences({
      lang,
      react_style,
      react_syntax,
      html_layout,
      vue_style,
      triggerFullPreProcess = false
    } = {}) {
      try {
        const { id } = this.currentProject;
        let web_framework = lang || this.codegenLang;
        const payload = {
          developer_preferences: {
            web_framework: capitalize(web_framework),
            lang: lang || this.codegenLang,
            react_style: react_style || this.codegenReactStyle,
            react_syntax: react_syntax || this.codegenReactSyntax,
            html_layout: html_layout || this.codegenHTMLLayout,
            vue_style: vue_style || this.codegenVueStyle
          }
        };

        await this.updateProject({ id, payload }).then(() => {
          if (triggerFullPreProcess) {
            this.triggerFullPreProcess();
          }
        });
      } catch (err) {
        console.error(err);
        this.$trackEvent('omniview.dev-preferences-update.failure', { message: err.message });
      }
    },

    handleRegenerate({ refresh }) {
      this.regenerateCode().then(() => {
        if (refresh) {
          EventBus.$emit('refresh-iframe');
        }
      });
    },
    autosize() {
      const c = document.querySelectorAll('textarea');
      [...c].forEach(t => (t.style.maxHeight = 'unset'));
    },

    async fetchLayerWhiteList() {
      try {
        const { screenSlug, projectId } = this.$route.params;
        let url = `project/${projectId}/component/${screenSlug}/selectable_layers`;
        const { data } = await fetchApiWithCache(url);
        if (data) {
          this.whitelist = data;
          EventBus.$emit(SEND_MESSAGE, {
            action: 'set_whitelist',
            whitelist: this.codegenLang == 'html' ? [] : this.whitelist
          });
        }
      } catch (error) {
        this.whitelist = [];
      }
    },

    async populateModelNodes({ reset = false, iframe = '' } = {}) {
      const { currentScreen } = await this.getModelAndScreen();

      await this.$waitFor(() => this.isWebComponentsLoading, false);

      let modelNodesMap = await this.$worker.run(generateModelNodesMapWorker, [
        {
          model: currentScreen,
          webInstancesMap: this.webInstancesMap || [],
          components: this.DbWebComponents || [],
          metadata: this.currentComponentMetadata || { overrides: {} },
          codegenLang: this.codegenLang,
          currentWebComponent: this.currentWebComponent
        }
      ]);

      this.setModelNodesMap(modelNodesMap);

      if (iframe == 'componentIframe') {
        EventBus.$emit(SEND_COMPONENT_MESSAGE, {
          action: 'set_model_nodes',
          iframeName: 'all',
          modelNodes: reset ? {} : modelNodesMap,
          allowInnerComponentSelection: true
        });
        return;
      }

      EventBus.$emit(SEND_MESSAGE, {
        action: 'set_model_nodes',
        modelNodes: reset ? {} : modelNodesMap,
        allowInnerComponentSelection: this.codegenLang == 'html'
      });
    },

    checkIfExportAllowed() {
      const { projectId } = this.$route.params;
      axios.get('/rpc/export/allowed?project_id=' + projectId).then(({ data }) => {
        this.setIsExportAllowed(data.result || false);
      });
    },

    async checkForNodeInRoute(mode) {
      // mode here is an object

      const { layer: queryLayer, component: queryComponent } = this.$route.query;

      if (queryComponent) {
        await this.$waitFor(() => !this.loading.project && !this.isWebComponentsLoading, true);

        let model_id = queryComponent;

        const { master } = this.getMasterAndInstanceByNodeId(model_id);
        if (!master) {
          this.componentNotFoundError = true;
        }

        this.selectNodeById({
          nodeId: model_id,
          metadata: {
            source: 'client',
            callbackEvent: {
              name: 'open-component-in-library',
              params: { preProcessParams: { forcePreProcess: true } }
            }
          }
        });
      } else {
        if (!queryLayer) return;

        switch (mode.name) {
          case 'In':
            // no layers in play mode
            break;
          case 'Co':
            // TODO: implement me
            EventBus.$emit(SEND_MESSAGE, {
              action: HIGHLIGHT_COMMENT,
              data: {
                nodeId: queryLayer,
                metadata: {
                  source: 'route'
                }
              }
            });
            break;
          case 'C':
            EventBus.$emit(SEND_MESSAGE, {
              action: SELECT_OVERRIDE_NODE,
              data: {
                nodeId: queryLayer,
                metadata: {
                  source: 'route'
                }
              }
            });
            break;

          default:
            break;
        }
      }
    },
    async loadData() {
      try {
        const { projectId, screenSlug, teamSlug } = this.$route.params;
        this.loading.fetching = true;
        this.loadMetadataOrCreate();
        this.populateComments();
        this.populateCustomDomains();
        this.populateGuests();
        this.fetchStyleguide({
          parent: 'projects',
          id: projectId
        }).then(data => {
          const results = get(data || {}, 'results', []);
          if (results.length == 0) {
            axios.post(`/project/${projectId}/trigger_styleguide`);
          } else {
            const classes = get(results[0], 'classes', {});
            if (!isEmpty(classes)) {
              const sampleClass = classes[Object.keys(classes)[0]];
              if (!has(sampleClass, 'usage')) {
                axios.post(`/project/${projectId}/trigger_styleguide`);
              }
            }
          }
        });

        this.fetchProjectAndReleases();

        if (!teamSlug) {
          const { mode } = this.$route.meta;
          let m = mode ? mode : 'play';

          let query = { mode: m };

          await this.$waitFor(() => this.loading.project, false);

          if (m == 'comments') {
            const { commentNumber } = this.$route.params;

            await this.$waitFor(() => this.loading.comments, false);

            let commentFromRoute = this.comments.find(c => c.number == commentNumber);
            if (commentFromRoute) {
              EventBus.$emit('open-comment-thread', commentFromRoute);

              if (commentFromRoute.node_id) {
                query = { ...query, layer: commentFromRoute.node_id };
              }
            }
          }

          this.$router
            .replace({
              name: 'omniview',
              params: { ...this.$route.params, teamSlug: this.currentProject.team_slug },
              query
            })
            .catch(e => console.log(e));

          this.fetchTeamMemberships({ id: this.currentProject.team_slug, params: { is_slug: true } });
          this.fetchUserMemberships({ id: 'me' });
        } else {
          this.fetchTeamMemberships({ id: teamSlug, params: { is_slug: true } });
          this.fetchUserMemberships({ id: 'me' });
        }

        // fetch web components from db (live and suggested)
        this.fetchDBWebComponents({
          parent: 'projects',
          id: projectId,
          skipCache: true,
          params: { skip_cache: true, get_all: true }
        });

        await this.$waitFor(() => this.currentProject && !this.currentProject.is_syncing, true);

        // Fetch screens & select
        const { results: components } = await this.fetchComponents({
          parent: 'projects',
          id: projectId,
          params: { page_size: 100 }
        });

        const projectSlugs = components.map(s => s.slug);
        this.setProjectSlugs(projectSlugs);

        if (this.isAnimaScriptReady) {
          EventBus.$emit(SEND_MESSAGE, {
            action: 'update-slugs',
            slugs: projectSlugs
          });
        }

        const component = components.find(c => c.slug == screenSlug);
        this.fetchLayerWhiteList();
        this.selectScreen(component);
        this.loading.fetching = false;

        // fetch assets
        this.fetchAssets();
        // fetch breakpoints (requires project and screens to be loaded)
        this.getBreakpoints();

        this.errors.major = null;
      } catch (error) {
        if (error?.status == 403) {
          openModal({ name: 'project-request-access', onCloseRedirect: '/', mode: 'dark' });
        } else {
          this.errors.major = 'Sorry, something went wrong.';
          this.notFoundError = true;
        }
        console.warn('Major Failure', error);
      } finally {
        this.loading.fetching = false;
      }
    },
    reactToScreenChange({ fetchBreakpoints }) {
      const { master_slug } = this.currentComponent;
      // if (this.currentMasterSlug && master_slug != this.currentMasterSlug) {
      //   this.setDomLoading(true);
      //   this.setIframeLoading(true);
      // }

      this.setCurrentMasterSlug(master_slug);

      if (fetchBreakpoints) {
        this.getBreakpoints();
      }
      // this.fetchAssets();
      // this.populateModelNodes();
    },
    populateGuests() {
      const { projectId } = this.$route.params;

      return this.fetchProjectGuests({
        parent: 'projects',
        id: projectId,
        params: { page_size: 200 }
      });
    },
    async fetchProjectAndReleases() {
      const { projectId } = this.$route.params;
      try {
        this.loading.project = true;
        await this.fetchProject({ id: projectId, skipCache: true });
        const { live_project_release, is_syncing } = this.currentProject;
        if (is_syncing) {
          this.pollSyncingProject({ id: projectId });
        }

        this.initSocket();
        this.loading.project = false;
        this.loading.release = true;
        const { release } = await this.fetchProjectRelease({ id: live_project_release });
        await this.fetchRelease({ id: release });
        this.loading.release = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.project = this.loading.release = false;
      }
    },

    loadNodesWithOverrides() {
      let ids = [];

      for (let index = 0; index < this.metadatas.length; index++) {
        const screenMetadata = this.metadatas[index];

        let sids = Object.keys(screenMetadata.overrides || {});
        ids = [...ids, ...sids];
      }

      this.getNodesWithOverridesData(ids);
    },

    async loadMetadataOrCreate() {
      const { projectId, screenSlug } = this.$route.params;
      const { results: metadatas } = await this.fetchMetadata({
        id: projectId
      });
      this.loading.metadata = false;
      const currentComponentMetadata = metadatas.find(m => m.component_slug == screenSlug) || {};

      if (!currentComponentMetadata.id) {
        const newMetadata = await this.createMetadata({
          parent: 'projects',
          id: projectId,
          payload: {
            component_slug: screenSlug
          }
        });

        this.setMetadata([...this.metadatas, newMetadata]);
      }
    },

    async fetchAssets() {
      try {
        this.loading.assets = true;
        const { projectId } = this.$route.params;
        const { currentScreen: currentScreenModel } = await this.getModelAndScreen();
        this.setCurrentComponentData(currentScreenModel);

        const token = auth.getToken();
        const headers = {
          Authorization: `JWT ${token}`,
          'content-type': 'application/json'
        };
        const registryURLResponse = await fetch(
          `${process.env.API_BASE_URL}/v2/rpc/projects/${projectId}/generate_assets_registry_url`,
          { headers }
        );
        const { assets_registry_url } = await registryURLResponse.json();
        this.setRegistryUrl(assets_registry_url);
        const registryResponse = await fetch(decodeURI(assets_registry_url)).catch(e => console.log(e));
        const assetsJson = await registryResponse.json();
        this.setProjectAssetsRegistry(assetsJson);
      } catch (error) {
        console.log(error, 'FAILED LOADING ASSETS');
        this.errors.assets = true;
      } finally {
        this.loading.assets = false;
      }
    },

    async populateComments() {
      const { projectId } = this.$route.params;
      try {
        this.loading.comments = true;
        await this.fetchProjectComments({
          id: projectId,
          params: {
            page_size: 100
          }
        });
      } catch (error) {
        console.error('Failed to load comments', error);
      } finally {
        this.loading.comments = false;
      }
    },
    async populateCustomDomains() {
      const { projectId } = this.$route.params;
      if (this.loading.domains) return;
      try {
        this.loading.domains = true;
        await this.fetchCustomDomains({
          parent: 'projects',
          id: projectId
        });
      } catch (error) {
        console.error('Failed to load custom domains', error);
      } finally {
        this.loading.domains = false;
      }
    },

    messageListener(e) {
      if (e && e.data && e.data.sender === 'fullScreen') {
        //if the chrome extension wants to change the full screen
        let showOnlyCode = e.data.message.showOnlyCode;
        this.setShowOnlyCode(showOnlyCode);
        return;
      } else if (e && e.data && e.data.sender === 'selectLayer' && e.data.message && e.data.message.layerId) {
        //the chrome extension wants to change the layerId
        let layerId = e.data.message.layerId;
        this.checkForNodeInRoute(this.activeMode, layerId);
        return;
      }

      if (!e.data.action) return;
      if (!e.origin.includes('animaapp.io')) return;
      const data = e.data.data;
      let shouldRegenerate = true;

      const handleCommentsModeNode = () => {
        let { view } = e.data.info;
        if (!view) view = 'comments';
        if (this.currentNode.id && this.currentNode.id == data.id) {
          return;
        }
        this.setIsSidebarMinimized(false);

        this.setCurrentNode({ ...data, viewName: '' });
        this.setCurrentNodePath(data.path);

        const outerComments = (this.comments || []).filter(c => !has(c, 'reply_to'));
        const outerCommentsForThisNode = outerComments.filter(c => c.node_id == data.id);

        // drill into replies if there is only one comment
        if (outerCommentsForThisNode.length == 1) {
          EventBus.$emit('open-comment-thread', outerCommentsForThisNode[0]);
        } else {
          this.setCommentsSubView(view);
          EventBus.$emit('focus-comment-input');
        }
      };

      switch (e.data.action) {
        case 'edit-node':
          {
            let newRoute = { ...this.$route, query: { ...this.$route.query, layer: data.id } };
            if (isSameRoute(this.$route, newRoute)) {
              this.handleCodeModeNodeMessage(data);
            } else {
              this.$router
                .replace(newRoute)
                .then(() => {
                  this.handleCodeModeNodeMessage(data);
                })
                .catch(e => {
                  console.log(e);
                });
            }
          }
          break;

        case 'node-subNodes-map':
          {
            let subNodesMap = data;
            let newDbItems = this.DbWebComponents.map(master => {
              const wci = get(master, 'instances', []);
              const pi = wci.find(i => i.is_primary) || {};
              let id = get(pi, 'model_id', wci[0].model_id);
              let matches = get(subNodesMap, id, []);
              let subsIds = matches.map(mId => {
                const { master } = this.getMasterAndInstanceByNodeId(mId);
                return master.id;
              });
              return {
                ...master,
                subsIds
              };
            });
            this.setWebComponents(newDbItems);
          }

          break;

        case 'on-preview-start':
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: true });
          break;
        case 'on-preview-end':
          if (data.error) {
            this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
          } else {
            if (data.isLocal) {
              this.setBase64Screenshot(data.base64);
              this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              break;
            }
            const file = dataURLtoFile(data.base64, `${data.nodeId}.png`);
            const formData = new FormData();
            formData.append('file', file, file.name);
            api
              .post(`/v2/uploads/projects/${this.currentProject.id}/component_capture`, formData)
              .then(res => {
                let url = '';
                if (res.data.public_url) {
                  url = res.data.public_url.replace(
                    'https://anima-uploads.s3.amazonaws.com',
                    'https://image-cdn.animaapp.com'
                  );
                }

                this.updateNodeOverrides({
                  nodeId: data.nodeId,
                  fields: { preview_url: url }
                });
              })
              .catch(e => console.log(e))
              .finally(() => {
                this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              });
          }
          break;
        case 'on-capture-start':
          this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: true });
          break;
        case 'on-capture-end':
          if (data.error) {
            this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
          } else {
            const file = dataURLtoFile(data.base64, `${data.nodeId}.${this.captureType}`);
            const formData = new FormData();
            formData.append('file', file, file.name);
            api
              .post(`/v2/uploads/projects/${this.currentProject.id}/component_capture`, formData)
              .then(res => {
                let url = '';
                if (res.data.public_url) {
                  url = res.data.public_url.replace(
                    'https://anima-uploads.s3.amazonaws.com',
                    'https://image-cdn.animaapp.com'
                  );
                }

                this.updateNodeOverrides({
                  nodeId: data.nodeId,
                  fields: { capture_url: url }
                });
              })
              .catch(e => console.log(e))
              .finally(() => {
                this.setIsGeneratingCapture({ ...this.isGeneratingCapture, [data.nodeId]: false });
              });
          }

          break;

        case 'comment-node-change':
          {
            let newRoute = { ...this.$route, query: { ...this.$route.query, layer: data.id } };
            if (isSameRoute(this.$route, newRoute)) {
              handleCommentsModeNode();
            } else {
              this.$router
                .push(newRoute)
                .then(() => {
                  handleCommentsModeNode();
                })
                .catch(e => {
                  console.log(e);
                });
            }
          }
          break;

        case 'save-overrides':
          if (has(e.data.info, 'regenerate')) {
            shouldRegenerate = e.data.info.regenerate;
          }

          this.setNodes(data);

          this.setNodesWithOverrides({ ...this.nodesWithOverrides, ...filterObject(data, node => node.isModifed) });
          this.setIsWaitingForOverrides(false);
          this.saveComponentOverrides(data, {
            regenerate: shouldRegenerate
          });
          // this.handleOverrideAdded(data);
          break;
        case 'turbo-ready':
          this.setIsAnimaScriptReady(true);
          EventBus.$emit(SEND_MESSAGE, {
            action: 'create-hotspots'
          });

          break;
        case 'link-click':
          {
            console.log('link-click');
            let slug = e.data.data.url.replace(`https://${this.currentProject.subdomain}.animaapp.io/`, '');
            let currentSlug = this.$route.params.screenSlug;

            if (this.projectSlugs.includes(slug) && slug != currentSlug) {
              EventBus.$emit('handleScreenChange', { screenSlug: slug, fetchBreakpoints: true });
            }
          }

          break;
        case 'turbo-render':
          this.checkForModeInRoute();
          while (!this.queue.isEmpty()) {
            let job = this.queue.dequeue();
            if (job) {
              job();
            }
          }
          break;

        case 'dom-load':
          console.log('DOM-load');

          this.checkForModeInRoute();
          EventBus.$emit(SEND_MESSAGE, {
            action: 'set_whitelist',
            whitelist: this.codegenLang == 'html' ? [] : this.whitelist
          });

          this.$waitFor(() => this.loading.metadata, false).then(() => {
            this.loadNodesWithOverrides();
          });

          if (this.firstLoad) {
            this.$waitFor(() => this.isAnimaScriptReady, true).then(() => {
              EventBus.$emit(SEND_MESSAGE, {
                action: 'update-slugs',
                slugs: this.projectSlugs.length > 0 ? this.projectSlugs : this.currentProject.page_names
              });
              this.setDomLoading(false);
            });
          } else {
            this.handleModeChange({
              mode: this.mappedRouteToMode,
              fromRoute: false
            });
            this.setDomLoading(false);
          }

          this.firstLoad = false;

          break;

        case 'multi-selection-start':
          EventBus.$emit(CLOSE_PANEL, { forceClose: true });
          break;
        case 'multi-selection-update':
          if (data.lowestCommonParentId) {
            this.setMultiSelectedNodes(data.nodes.map(o => o.node));
            EventBus.$emit(SEND_MESSAGE, {
              action: SELECT_OVERRIDE_NODE,
              data: {
                nodeId: data.lowestCommonParentId,
                metadata: {
                  source: 'client',
                  scrollIntoView: false
                }
              }
            });
          }

          break;

        case 'nodes-data-map':
          this.setNodesWithOverrides(e.data.data);
          break;
        case 'escape-clicked':
          this.handleEscapeClicked();
          break;
        case 'overrides-activated':
          this.checkForNodeInRoute(this.activeMode);
          break;
        case 'comments-activated':
          this.checkForNodeInRoute(this.activeMode);

          break;

        default:
          break;
      }
    },

    async saveComponentOverrides(data, { regenerate = true } = {}) {
      const { projectId } = this.$route.params;

      const overrides = await this.getModelOverrides(data);
      const { id } = this.currentComponentMetadata;
      await this.updateMetadata({
        id,
        payload: {
          overrides
        }
      });

      this.fetchMetadata({
        id: projectId,
        skipCache: true
      });

      if (regenerate) {
        this.regenerateCode();
      }
    },

    async regenerateCode() {
      // const { projectId } = this.$route.params;
      const singleComponent = await this.fetchSingleComponent({
        id: this.currentComponent.id
      });

      await api.post('/rpc/project/regenerate_code', {
        project_id: this.currentProject.id,
        release_short_id: singleComponent.release.short_id,
        project_release_id: singleComponent.project_release
      });

      // await poll({
      //   fn: () => this.fetchProject({ id: projectId, skipCache: true }),
      //   validate: project => {
      //     return project && !project.is_syncing;
      //   },
      //   interval: 3000,
      //   maxAttempts: 20
      // });
      // console.log('REGENERATION DONE');
    },

    async getBreakpoints() {
      const { breakpoint } = this.$route.params;
      try {
        this.loading.breakpoints = true;

        await this.$waitFor(() => !this.loading.project && this.ready, true);

        this.setBreakpoints([]);

        const { similar_screens_id } = this.currentProject;

        const { id: currentComponentId } = this.currentComponent;

        const sizes = [];
        let currentSizeIndex = -1;
        let currentSize = -1;
        for (let i = 0; i < (similar_screens_id || this.similarScreensIdsTemp).length; i++) {
          const group = similar_screens_id[i];
          const ids = group.map(g => g.id);
          if (ids.includes(currentComponentId)) {
            for (let j = 0; j < group.length; j++) {
              const { id } = group[j];
              if (id == currentComponentId) {
                currentSizeIndex = j;
              }
              const foundComponent = this.allCurrentComponents.find(c => c.id == id);
              sizes.push({ width: foundComponent.width, component: foundComponent });
            }
            break;
          }
        }

        const sizesOnly = sizes.map(s => s.width);

        if (currentSizeIndex != -1) {
          currentSize = sizesOnly[currentSizeIndex];
        }

        sizes.sort((a, b) => (a.width < b.width ? 1 : -1));

        let breakpoints = sizes.map(({ width, component }) => ({ id: uuid(), width, component }));

        if (breakpoints.length == 0) {
          breakpoints = [{ id: uuid(), width: this.currentComponent.width, component: this.currentComponent }];
        }

        this.setBreakpoints(breakpoints);

        // ffrom route params
        if (!breakpoint || breakpoint != 'res') {
          if (currentSize == -1) {
            this.setActiveBreakpoint(breakpoints[0]);
          } else {
            this.setActiveBreakpoint(breakpoints.find(br => br.width == currentSize));
          }
        }
      } catch (error) {
        this.setBreakpoints([]);
        this.errors.breakpoints = true;
        console.log('FAILED TO LOAD BREAKPOINTS', error);
      } finally {
        this.loading.breakpoints = false;
      }
    },
    async getBreakpointsFromModel() {
      const { breakpoint } = this.$route.params;

      try {
        await this.$waitFor(() => !this.loading.project && !this.loading.release, true);

        const { model, currentScreen } = await this.getModelAndScreen({ slim: true });

        const sizes = [];
        let currentSizeIndex = -1;
        let currentSize = -1;
        for (let i = 0; i < (model.similarScreenIds || []).length; i++) {
          const ids = model.similarScreenIds[i];
          if (ids.includes(currentScreen.modelID)) {
            for (let j = 0; j < ids.length; j++) {
              const id = ids[j];
              if (id == currentScreen.modelID) {
                currentSizeIndex = j;
              }
              const foundScreen = model.screens.find(s => s.modelID == id);
              const foundComponent = this.allCurrentComponents.find(c => c.name == foundScreen.viewName);
              sizes.push({ width: foundScreen.width, component: foundComponent });
            }
            break;
          }
        }

        const sizesOnly = sizes.map(s => s.width);

        if (currentSizeIndex != -1) {
          currentSize = sizesOnly[currentSizeIndex];
        }

        sizes.sort((a, b) => (a.width < b.width ? 1 : -1));

        let breakpoints = sizes.map(({ width, component }) => ({ id: uuid(), width, component }));

        if (breakpoints.length == 0) {
          breakpoints = [{ id: uuid(), width: currentScreen.width, component: this.currentComponent }];
        }

        this.setBreakpoints(breakpoints);

        if (!breakpoint || breakpoint != 'res') {
          if (currentSize == -1) {
            this.setActiveBreakpoint(breakpoints[0]);
          } else {
            this.setActiveBreakpoint(breakpoints.find(br => br.width == currentSize));
          }
        }
      } catch (error) {
        this.setBreakpoints([]);
        this.errors.breakpoints = true;
        console.log('FAILED TO LOAD BREAKPOINTS', error);
      } finally {
        this.loading.breakpoints = false;
      }
    },

    generateModelNodeMap(node) {
      for (let i = 0; i < node.subviews.length; i++) {
        let child = node.subviews[i];

        const nodeOverrides = this.currentComponentMetadata.overrides[child.modelID] || {};

        let is_generic_component = !!get(this.webInstancesMap, child.modelID, false);

        let is_component = !!get(this.webInstancesMap, child.modelID, false);
        let { is_suggestion, component_name: masterComponentName, master } = this.isSuggestionById(child.modelID);

        let is_web_component = is_component && !is_suggestion && master['is_live'];

        let component_name = has(nodeOverrides, 'viewName') ? nodeOverrides.viewName : child.viewName;
        if (is_web_component || is_suggestion) {
          component_name = masterComponentName;
        }

        if (this.codegenLang == 'html') {
          is_generic_component = is_web_component = is_suggestion = false;
        }

        this.modelNodesMap[child.modelID] = {
          viewName: component_name,
          is_component: is_generic_component && (is_web_component || is_suggestion),
          is_web_component: is_web_component,
          is_suggestion: is_suggestion,
          is_image: child.is_image || false,
          modelID: child.modelID,
          model_class: child.model_class,
          width: child.width,
          height: child.height
        };

        this.generateModelNodeMap(child);
      }
    },

    setTrackingData() {
      const { currentProject: project } = this;
      const extraData = { sampled_project_id: null };
      if (project?.is_sample_project) {
        extraData.sampled_project_id = project.source_project;
      }
      this.setExtraTrackingData(extraData);
    },
    resetTrackingData() {
      this.setExtraTrackingData({});
    }
  }
};
</script>

<style lang="scss" scoped>
.omniview-container {
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#omniview-content {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  // background-color: #3b3b3b;
}

.main-content {
  position: relative;
  flex: 1;
  overflow: hidden;
  background-color: #2d2d2d;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
