var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.releases.length
      ? _c("div", { staticClass: "empty-state" }, [
          _c("div", { staticClass: "empty-state-title" }, [
            _vm._v("No versions yet")
          ]),
          _c("div", { staticClass: "empty-state-subtitle" }, [
            _vm._v(
              " Here you'll see a list of available previous versions of your design once synced from Sketch, Figma or XD. "
            )
          ])
        ])
      : _c("table", { staticClass: "table-container" }, [
          _vm._m(0),
          _c(
            "tbody",
            { staticClass: "table-body" },
            _vm._l(_vm.releases, function(item, i) {
              return _c("tr", { key: "row" + i }, [
                _c("td", [_vm._v(_vm._s(item.revision_number))]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.formatDate(item.created_at, "MMM DD, YYYY HH:mm")
                    )
                  )
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "align-right" },
                    [
                      _c(
                        "an-tooltip",
                        {
                          attrs: {
                            text: "View version " + item.revision_number
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clickable-icon",
                              on: {
                                click: function($event) {
                                  return _vm.viewVersion(item)
                                }
                              }
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "eye", size: 24 }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "align-right" },
                    [
                      _vm.isLive(item)
                        ? _c("span", { staticClass: "live" }, [_vm._v("Live")])
                        : _c(
                            "an-link",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.recover(item)
                                }
                              }
                            },
                            [_vm._v("Set as Live")]
                          )
                    ],
                    1
                  )
                ])
              ])
            }),
            0
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "table-header" }, [
      _c("tr", [
        _c("td", [_vm._v("Version")]),
        _c("td", [_vm._v("Date created")]),
        _c("td"),
        _c("td")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }