var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "inputContainer",
      style: {
        "--height": _vm.inputSizes[_vm.size] + "px",
        "--iconContainerWidth": _vm.iconSize * 2 + 5 + "px"
      }
    },
    [
      _vm.icon
        ? _c(
            "div",
            { staticClass: "inputIconContainer" },
            [_c("svg-icon", { attrs: { size: _vm.iconSize, name: _vm.icon } })],
            1
          )
        : _vm._e(),
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              class: [
                "input",
                ((_obj = {
                  invalid: _vm.invalid,
                  hasIcon: _vm.icon,
                  noBorder: _vm.noBorder
                }),
                (_obj[_vm.theme] = true),
                _obj)
              ],
              attrs: { type: _vm.inputType },
              domProps: { value: _vm.value }
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _vm.type === "password"
        ? _c(
            "div",
            {
              ref: "passIcon",
              class: _vm.passwordIconClasses,
              on: { click: _vm.toggleShowPassword }
            },
            [_c("svg-icon", { attrs: { size: 24, name: "eye" } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }