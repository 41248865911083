var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { "data-cy": "pricing-page" } },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Choose your plan")]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          " Upgrade your team plan to get unlimited projects, code export and much more. "
        ),
        _c("br"),
        _vm._v(
          " " + _vm._s(_vm.moneyBackPeriod) + " days money-back guarantee. "
        )
      ]),
      _c(
        "div",
        { staticClass: "billing-cycle-toggle" },
        [
          _c("an-toggle", {
            staticClass: "toggle-wrapper",
            attrs: { offTitle: "Monthly", onTitle: "Yearly" },
            model: {
              value: _vm.isAnnual,
              callback: function($$v) {
                _vm.isAnnual = $$v
              },
              expression: "isAnnual"
            }
          }),
          _c(
            "div",
            { staticClass: "discount" },
            [
              _vm._v(" Save 20% "),
              _c("svg-icon", { attrs: { name: "quirky-arrow", size: 36 } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "body" },
        _vm._l(_vm.plans, function(plan) {
          return _c("PricingColumn", {
            key: plan.name,
            attrs: {
              plan: plan,
              price: _vm.getPrice(plan.name),
              billingCycle: _vm.isAnnual ? "annual" : "month",
              isCurrentPlan: _vm.isSameAsActiveSubscription(plan),
              theme: _vm.theme,
              ribbonName: plan.ribbonName
            },
            on: { "select-plan": _vm.onSelectPlan }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }