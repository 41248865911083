var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "figma-card-wrap",
      class: { isSyncing: _vm.isSyncing },
      style: { cursor: _vm.bodySelect ? "pointer" : "default" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.handleCardClick.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "figma-card" }, [
        _c("div", { staticClass: "border" }),
        _c("div", { staticStyle: { position: "relative", "flex-grow": "1" } }, [
          _c("div", { staticClass: "cover-wrap" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoadingThumbnail,
                    expression: "isLoadingThumbnail"
                  }
                ],
                staticClass: "flex items-center justify-center w-full"
              },
              [
                _c("svg-icon", {
                  staticClass: "icon-spin",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { fill: "currentColor", name: "spin", size: 14 }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoadingThumbnail,
                    expression: "!isLoadingThumbnail"
                  }
                ],
                staticClass: "cover-wrap-inner",
                staticStyle: { "background-color": "rgb(229, 229, 229)" }
              },
              [
                _c("div", {
                  staticClass: "cover-image",
                  style: _vm.thumbnailURL
                })
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "footer-wrap" }, [
          _vm.selectable
            ? _c(
                "div",
                { staticClass: "checkbox-wrap" },
                [
                  _c("Checkbox", {
                    attrs: { value: _vm.localValue },
                    on: { input: _vm.handleInput }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "name truncate flex items-center" }, [
            _c("span", { staticClass: "truncate" }, [
              _vm._v(_vm._s(_vm.data.name))
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }