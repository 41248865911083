<template>
  <div class="paypal-container">
    <div ref="paypalButton" />
  </div>
</template>

<script>
import { createPayPalButton } from '@/services/payment/paypal';
import { getPlanId } from '@/services/subscriptions';

export default {
  props: {
    plan: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  computed: {
    planId() {
      const { plan, interval } = this;
      const service = 'paypal';
      return getPlanId({ service, plan, interval });
    }
  },
  mounted() {
    setTimeout(() => {
      this.setPaypalButton();
    }, 0);
  },
  methods: {
    async setPaypalButton() {
      const { onClick, onApprove, onError, planId, quantity } = this;
      const { paypalButton } = this.$refs;
      paypalButton.innerHTML = null;
      const payload = { plan_id: planId, quantity: quantity.toString(10) };
      await createPayPalButton(paypalButton, payload, { onClick, onApprove, onError });
    },
    onClick(e) {
      this.$trackEvent('paypal-button.click-event.fire');
      this.$emit('click', e);
    },
    onApprove(e) {
      this.$trackEvent('paypal-button.approve-event.fire');
      this.$emit('approve', e);
    },
    onError(e) {
      this.$trackEvent('paypal-button.error-event.fire');
      this.$emit('error', e);
    }
  },
  watch: {
    planId: 'setPaypalButton',
    quantity: 'setPaypalButton'
  }
};
</script>
