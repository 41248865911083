import { get } from 'lodash';

export const mapWidthToBreakpointIcon = width => {
  const icon = width >= 1024 ? 'desktop' : width > 750 ? 'tablet' : 'mobile';
  return icon;
};
export const mapWidthToBreakpointText = width => {
  const text = width >= 1024 ? 'desktop' : width > 750 ? 'tablet' : 'mobile';
  return text;
};
export const mapWidthToBreakpointRange = width => {
  const text = width >= 1024 ? '1024 & up' : width > 750 ? '1023px - 750px' : '375px-749px';
  return text;
};

export const has_map = {
  has_spans: text => {
    return text.includes('</span>');
  },
  has_google_fonts: text => {
    return text.includes('fonts.googleapis.com');
  },
  has_embedded_code: text => {
    return text.includes('</code>');
  },
  has_video: text => {
    return text.includes('</video>');
  },
  has_hover: text => {
    let re = /(\.(.*):hover)/;
    return re.test(text);
  },
  has_enter_animation: text => {
    return text.includes('.animate-enter');
  },
  has_links: text => {
    return text.includes('</a>');
  },
  has_flexbox: text => {
    return text.includes('display: flex');
  },
  has_absolute: text => {
    return text.includes('position: absolute');
  }
};

const counter_map = {
  flexbox_count: cssText => {
    return (cssText.match(/display: flex/g) || []).length;
  },
  absolute_count: cssText => {
    return (cssText.match(/position: absolute/g) || []).length;
  },
  relative_count: cssText => {
    return (cssText.match(/position: relative/g) || []).length;
  },
  width_height_count: cssText => {
    return (cssText.match(/[^\-](width|height):/g) || []).length + (cssText.match(/min-(width|height):/g) || []).length;
  },
  rules_count: cssText => {
    return (cssText.match(/{/g) || []).length;
  },

  tags_count: htmlText => {
    // count by self closing and closing tags
    return (htmlText.match(/(<[^>]*\/>|<\/[^>]*>)/g) || []).length;
  },
  img_count: htmlText => {
    return (htmlText.match(/<img/g) || []).length;
  },
  meaningless_classname_use_count: htmlText => {
    return (
      htmlText.match(
        /class(Name)?="(rect|oval|flex-(row|col)|overlap|line|combined-shape|ellipse|fill|group|path|rectangle|vector|x[\d-]+|image[^a-zA-Z"]*"|span)/g
      ) || []
    ).length;
  },
  anima_classname_use_count: htmlText => {
    return (htmlText.match(/class(Name)?="(flex-(row|col)|overlap|container-center-(horizontal|vertical|span))/g) || [])
      .length;
  }
};

export const analyzeHTML = t => {
  let _t = t || '';
  const counters = {
    tags_count: counter_map.tags_count(_t),
    img_count: counter_map.img_count(_t),
    meaningless_classname_use_count: counter_map.meaningless_classname_use_count(_t),
    anima_classname_use_count: counter_map.anima_classname_use_count(_t)
  };
  const normalizedCounters = {
    img_count_n: counters.img_count / counters.tags_count,
    meaningless_classname_use_count_n: counters.meaningless_classname_use_count / counters.tags_count,
    anima_classname_use_count_n: counters.anima_classname_use_count / counters.tags_count
  };
  return {
    ...counters,
    ...normalizedCounters,
    has_links: has_map.has_links(_t),
    has_video: has_map.has_video(_t),
    has_spans: has_map.has_spans(_t),
    has_embedded_code: has_map.has_embedded_code(_t)
  };
};
export const analyzeCSS = t => {
  let _t = t || '';
  const counters = {
    flexbox_count: counter_map.flexbox_count(_t),
    absolute_count: counter_map.absolute_count(_t),
    width_height_count: counter_map.width_height_count(_t),
    rules_count: counter_map.rules_count(_t)
  };
  const normalizedCounters = {
    flexbox_count_n: counters.flexbox_count / counters.rules_count,
    absolute_count_n: counters.absolute_count / counters.rules_count,
    width_height_count_n: counters.width_height_count / counters.rules_count
  };
  return {
    ...counters,
    ...normalizedCounters,
    has_google_fonts: has_map.has_google_fonts(_t),
    has_hover: has_map.has_hover(_t),
    has_enter_animation: has_map.has_enter_animation(_t),
    has_flexbox: has_map.has_flexbox(_t),
    has_absolute: has_map.has_absolute(_t)
  };
};
export const analyzeCode = (html, css) => {
  // if (!html || !css) return {}

  return {
    ...analyzeHTML(html),
    ...analyzeCSS(css)
  };
};

export const patchInstanceFromMaster = (instance, master) => {
  // debugger;
  let copyInstance = {};
  let masterInstanceRegularProps = get(master, 'props.regular', []);
  let masterInstanceNestedProps = get(master, 'props.nested', []);

  let instanceRegularProps = get(instance, 'props.regular', []);
  let instanceNestedProps = get(instance, 'props.nested', []);

  instanceRegularProps = instanceRegularProps.map(prop => {
    let regularPropData = masterInstanceRegularProps.find(p => p.prop_fingerprint == prop.prop_fingerprint) || {};

    return {
      ...prop,
      ...regularPropData
    };
  });

  instanceNestedProps = instanceNestedProps.map(prop => {
    let nestedPropData = masterInstanceNestedProps.find(p => p.prop_fingerprint == prop.prop_fingerprint) || {};

    return {
      ...prop,
      ...nestedPropData
    };
  });

  copyInstance = {
    ...instance,
    props: {
      regular: instanceRegularProps,
      nested: instanceNestedProps
    }
  };
  return copyInstance;
};

export const openLink = ({ link, newTab = true } = {}) => {
  let a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = link;
  newTab && a.setAttribute('target', '_blank');
  a.click();
  document.body.removeChild(a);
};
