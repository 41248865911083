<template>
  <div data-cy="pricing-page" class="container">
    <div class="title">Choose your plan</div>
    <div class="subtitle">
      Upgrade your team plan to get unlimited projects, code export and much more.
      <br />
      {{ moneyBackPeriod }} days money-back guarantee.
    </div>
    <div class="billing-cycle-toggle">
      <an-toggle offTitle="Monthly" onTitle="Yearly" class="toggle-wrapper" v-model="isAnnual" />
      <div class="discount">
        Save 20%
        <svg-icon name="quirky-arrow" :size="36" />
      </div>
    </div>
    <div class="body">
      <PricingColumn
        v-for="plan in plans"
        :key="plan.name"
        :plan="plan"
        :price="getPrice(plan.name)"
        :billingCycle="isAnnual ? 'annual' : 'month'"
        :isCurrentPlan="isSameAsActiveSubscription(plan)"
        :theme="theme"
        :ribbonName="plan.ribbonName"
        @select-plan="onSelectPlan"
      />
    </div>
  </div>
</template>

<script>
import PricingColumn from '@/components/Pricing/PricingColumn';
import { getPrice } from '@/services/subscriptions';
import { fixPlanName } from '@/utils/billing';
import { mapActions, mapGetters, mapState } from 'vuex';

const getFeatures = activeFeaturesIndex => {
  return [
    'One project',
    'High fidelity prototype',
    'Team collaboration',
    'Export React & Vue components',
    'Export HTML code',
    'Custom domain'
  ].map((item, index) => ({
    label: item,
    isActive: index < activeFeaturesIndex
  }));
};

export default {
  name: 'pricing',
  data() {
    return {
      isAnnual: true,
      email: '',
      password: '',
      errorMessage: '',
      loading: false,
      createdTime: null,
      intervalId: null
    };
  },
  props: {
    onboardingFlow: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['light', 'dark'].includes(value)
    }
  },
  components: {
    PricingColumn
  },
  created() {
    this.createdTime = new Date();
  },
  mounted() {
    this.setInterval();
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters({
      activeSubscription: 'stripeSubscriptions/activeSubscription',
      isActiveExperiment: 'experiments/isActive'
    }),
    selectedInterval() {
      return this.isAnnual ? 'annually' : 'monthly';
    },
    isOnboardingFlow() {
      const { onboarding } = this.$route.query;
      return this.onboardingFlow || onboarding;
    },
    moneyBackPeriod() {
      const value = this.isActiveExperiment('money-back-period');
      return value === '30' ? 30 : 7;
    },
    plans() {
      const proFeatures = [{ label: 'Unlimited projects', isActive: true }, ...getFeatures(6).slice(1)];
      const plansData = {
        free: {
          name: 'Free',
          description: 'For individuals or teams just getting started with high fidelity prototyping.',
          features: getFeatures(3)
        },
        basic: {
          name: 'Basic',
          description: 'For individuals & teams collaborating through multiple projects.',
          features: getFeatures(3),
          scratchFirstFeature: true
        },
        pro: {
          name: 'Pro',
          description: 'Collaborate through multiple projects, export code and publish websites.',
          features: proFeatures,
          ribbonName: 'popular-ribbon'
        },
        team: {
          name: 'Team',
          description: 'For teams looking to boost their collaboration.',
          features: proFeatures,
          minQuantity: 3
        }
      };
      let plansList;

      if (this.isDesktop) {
        plansList = [plansData.free, plansData.basic, plansData.pro, plansData.team];
      } else {
        plansList = [plansData.pro, plansData.team, plansData.basic, plansData.free];
      }

      if (this.isActiveExperiment('hide-basic-plan')) {
        return plansList.filter(plan => plan.name !== 'Basic');
      }

      return plansList;
    }
  },
  methods: {
    getQueryFromUrl(url) {
      const urlSplited = url.split('?');
      urlSplited.shift();
      if (!urlSplited) {
        return '';
      }
      return urlSplited[0];
    },
    ...mapActions({
      getDefaultTeamSlug: 'users/getDefaultTeamSlug',
      trackIdleTime: 'tracking/trackIdleTime'
    }),
    setInterval() {
      this.createdTime = new Date();
      this.intervalId = setInterval(() => this.trackIdleTime({ startTime: this.createdTime }), 1e4);
    },
    getPrice(planName) {
      const interval = this.selectedInterval;
      const plan = fixPlanName(planName).toLowerCase();
      return getPrice({ plan, interval });
    },
    isSameAsActiveSubscription(plan) {
      const { interval, product_name = 'Free' } = this.activeSubscription ?? {};
      const productName = product_name === 'Prototype' ? 'Basic' : product_name;

      if (this.isOnboardingFlow) {
        return false;
      }

      if (plan.name === 'Free' && productName === 'Free') {
        return true;
      }

      return plan.name === productName && interval === this.selectedInterval;
    },
    async onSelectPlan(plan) {
      const interval = this.selectedInterval;
      const planName = plan.name;
      const teamSlug = this.isOnboardingFlow ? await this.getDefaultTeamSlug() : this.$route.params.teamSlug;

      this.$trackEvent('pricing.select-plan.click', { planName, interval });

      if (planName === 'Free') {
        let routeName = 'team-downgrade';
        if (this.isOnboardingFlow) {
          this.$emit('close');
          routeName = 'team';
        }
        this.$router.push({ name: routeName, params: { teamSlug } }, () => {});
      } else {
        const rawQuery = this.getQueryFromUrl(this.$router.history._startLocation);
        const query = new URLSearchParams(rawQuery);
        let source;

        if (this.$route.params['source']) {
          source = this.$route.params['source'];
        } else {
          source = query.get('utm_source');
        }
        this.$router.push({
          name: 'team-payment',
          params: {
            teamSlug,
            source,
            nextPage: this.$route.params['nextPage'],
            afterClosing: this.$route.params['afterClosing']
          },
          query: { plan: planName.toLowerCase(), interval }
        });
      }
      this.$gtm.trackEvent({
        event: 'team_pricing',
        event_category: 'team_pricing',
        event_action: planName,
        event_label: this.user?.role
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';

.container {
  max-width: 1440px;
}
.body {
  display: flex;
  justify-content: center;
  width: 100%;
  @include mobile {
    align-items: center;
    flex-direction: column;
  }
}
.billing-cycle-toggle {
  display: flex;
  align-items: flex-end;
  margin-top: 25px;
}
.discount {
  @include quirky-text;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  margin-left: -30px;
}
</style>
