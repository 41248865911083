var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.sections, function(section) {
      return _c("SettingsItem", {
        key: section.title,
        attrs: {
          title: section.title,
          text: section.text,
          disabled: section.disabled,
          disabledText: section.disabledText,
          ctaText: section.ctaText
        },
        on: { click: section.onClick }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }