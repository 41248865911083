var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "google-button",
      attrs: { type: "button" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseout: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c(
        "span",
        { ref: "icon", staticClass: "google-button__icon" },
        [_c("svg-icon", { key: _vm.iconName, attrs: { name: _vm.iconName } })],
        1
      ),
      _c("span", { staticClass: "google-button__text" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }