import { noop } from '@/utils/sugar';

const paypalScriptSrc = clientId =>
  `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&disable-funding=credit,card`;

let scriptTag;

const buttonsConfig = ({ plan_id, quantity }, { onClick, onApprove, onError }, style = {}) => ({
  createSubscription: (data, actions) =>
    actions.subscription.create({
      plan_id,
      quantity: quantity.toString(10)
    }),
  onInit: () => true,
  onClick,
  onApprove,
  onError,
  // customize style documentation:
  // https://developer.paypal.com/docs/checkout/integration-features/customize-button
  style: { height: 36, color: 'black', shape: 'pill', label: 'pay', ...style }
});

export const initPaypal = () => {
  const clientId = process.env.PAYPAL_CLIENT_ID;
  scriptTag = document.createElement('script');
  scriptTag.src = paypalScriptSrc(clientId);
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
};

export const createPayPalButton = async (ref, payload, { onClick = noop, onApprove = noop, onError = noop }) => {
  const config = buttonsConfig(payload, { onClick, onApprove, onError });
  if (!window.paypal && scriptTag) {
    scriptTag.addEventListener('load', () => window.paypal.Buttons(config).render(ref));
  } else {
    window.paypal.Buttons(config).render(ref);
  }
};
