<template>
  <div>
    <div class="title">Let’s get started</div>
    <div class="navigation">
      <Tabs :activeTabLabel="activeTabLabel" :items="tabs" @change="onTabChange" />
      <div class="search">
        <SearchInput v-model="searchQuery" @toggle-open="toggleSearchOpen" />
      </div>
    </div>
    <div class="content">
      <div class="title">The absolute beginner's guide</div>
      <div class="description">
        Want to get started with Anima, but don’t know where to begin? Anima beginner's guide will help you start
        designing immediately.
      </div>
    </div>
    <div>
      <default-loader v-if="loading && !loadingMore" />
      <CardsGrid
        v-else
        itemsType="education-post"
        :items="posts"
        :filteredResults="!!searchQuery"
        :totalAmount="totalPosts"
        :isLoadingMore="loadingMore"
        @click-education-post="openPost"
        @load-more="loadMore"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Tabs from '@/components/Layout/Tabs/Tabs';
import SearchInput from '@/components/SearchInput/SearchInput';
import CardsGrid from '@/components/CardsGrid/CardsGrid';
import { filterArrayBySearchQuery } from '@/utils/javascript';
import { openModal } from '@/services/bus';
import DefaultLoader from '@/components/Loading/DefaultLoader.vue';

export default {
  name: 'learn',
  data() {
    return {
      activeTabLabel: 'For designers',
      audience: 'designers',
      tabs: [
        { label: 'For designers', value: 'designers' },
        { label: 'For developers', value: 'developers' }
      ],
      searchQuery: '',
      loading: false,
      loadingMore: false
    };
  },
  components: {
    Tabs,
    SearchInput,
    CardsGrid,
    DefaultLoader
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('educationPosts', { educationPosts: 'items' }),
    ...mapState('educationPosts', { totalPosts: 'total' }),
    posts() {
      const { searchQuery } = this;
      let list = this.educationPosts;

      if (searchQuery) {
        list = filterArrayBySearchQuery(list, ['title', 'tag'], this.searchQuery);
      }

      return list;
    }
  },
  methods: {
    ...mapActions({ fetchPosts: 'educationPosts/fetchAll' }),
    async fetchData({ nextPage = false } = {}) {
      const { audience } = this;
      try {
        this.loading = true;
        await this.fetchPosts({
          params: { audience },
          nextPage
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      this.loadingMore = true;
      await this.fetchData({ nextPage: true });
      this.loadingMore = false;
    },
    onTabChange({ label, value }) {
      this.activeTabLabel = label;
      this.audience = value;
    },
    toggleSearchOpen(value) {
      this.searchBarOpen = value;
      if (value) {
        this.$trackEvent('learn-page.search.open');
      }
    },
    openPost(item) {
      const { src, title, post_type } = item;
      this.$trackEvent('learn-page.post-card.click', { post_type, title });
      if (post_type === 'video') {
        openModal({
          name: 'learn-video-modal',
          variant: 'center',
          width: 700,
          closeButton: true,
          props: { src, title }
        });
      }
    }
  },
  watch: {
    audience: {
      handler: 'fetchData',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_navigationBar.scss';

.title {
  @include headline;
  margin-bottom: 30px;
}
.navigation {
  justify-content: flex-start;
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  text-align: center;
  .title {
    margin-bottom: 20px;
  }
  .description {
    max-width: 660px;
  }
}
</style>
