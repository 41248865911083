var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OnboardingTopBar", { attrs: { homepageUrl: "/" } }),
      _c("div", { staticClass: "container dark" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Reset your password")]),
        _c("div", { staticClass: "subtitle" }, [
          _vm._v(" Hi again! Enter your new password and you’re good to go. ")
        ]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("New password")]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-input", {
                  attrs: {
                    type: "password",
                    theme: _vm.theme,
                    invalid: !_vm.passwordValid,
                    placeholder: "Password",
                    focus: ""
                  },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                }),
                !_vm.passwordValid
                  ? _c("div", { staticClass: "invalid-message" }, [
                      _vm._v(" Password must contain at least 8 characters ")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "label" }, [
              _vm._v("Confirm new password")
            ]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-input", {
                  attrs: {
                    type: "password",
                    theme: _vm.theme,
                    invalid: !_vm.confirmPasswordValid,
                    placeholder: "Password"
                  },
                  model: {
                    value: _vm.confirmPassword,
                    callback: function($$v) {
                      _vm.confirmPassword = $$v
                    },
                    expression: "confirmPassword"
                  }
                }),
                !_vm.confirmPasswordValid
                  ? _c("div", { staticClass: "invalid-message" }, [
                      _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c("transition", { attrs: { name: "fadeIn" } }, [
              _vm.password && _vm.confirmPassword
                ? _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c(
                        "an-button",
                        {
                          attrs: { isWorking: _vm.isWorking },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("Reset password")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "link" },
              [
                _c(
                  "an-link",
                  {
                    attrs: { theme: _vm.theme },
                    on: { click: _vm.navigateToLogin }
                  },
                  [_vm._v("Return to log in")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }