import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'team',
  modelName: 'teams',
  crud: true,
  endpoint: {
    default: '/v2/teams',
    create: '/v2/users/me/teams'
  },
  actions: {
    async createDefaultTeam({ commit, dispatch }, teamName = 'My team') {
      const newTeam = await dispatch('create', { payload: { name: teamName, is_default: true } });
      commit('setCurrentItem', newTeam);
      return newTeam;
    },
    setCurrentTeamProps({ state, commit }, payload) {
      const { currentItem: team } = state;
      commit('setCurrentItem', { ...team, ...payload });
      if (payload.name) {
        const membershipsPayload = { team_name: payload.name };
        commit(
          'teamMemberships/updateTeamMemberships',
          { teamId: team.id, payload: membershipsPayload },
          { root: true }
        );
      }
    }
  }
});
