var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.containerClasses,
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.item)
        },
        mouseover: function($event) {
          _vm.hover = true
        },
        mouseleave: function($event) {
          _vm.hover = false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "notification-avatar" },
        [_c("an-user-avatar", { attrs: { user: _vm.item.actor } })],
        1
      ),
      _c("div", { staticClass: "notification-content" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.userName))]),
          _vm.hover
            ? _c("div", { staticClass: "time" }, [
                _vm.compactView
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_c("svg-icon", { attrs: { name: "close", size: 14 } })],
                      1
                    )
                  : _vm.isUnread
                  ? _c(
                      "div",
                      [
                        _c(
                          "an-link",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$emit("mark-read", _vm.item)
                              }
                            }
                          },
                          [_vm._v("Mark as read")]
                        )
                      ],
                      1
                    )
                  : _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.timeAgo))
                    ])
              ])
            : _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.timeAgo))])
        ]),
        !_vm.compactView && _vm.hasProjectData
          ? _c(
              "div",
              { staticClass: "project" },
              [
                _vm.isComment ? _c("span", [_vm._v("on ")]) : _vm._e(),
                _c(
                  "an-link",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("click-project", _vm.item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.item.project.name))]
                )
              ],
              1
            )
          : _vm._e(),
        _c("div", {
          staticClass: "comment",
          domProps: { innerHTML: _vm._s(_vm.content) }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }