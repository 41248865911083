<template>
  <div :id="`section-${section.id}`" class="section">
    <div class="section-header">
      <div class="content d-container">
        <div class="drag-handler">
          <svg-icon name="draghandler" :size="24"></svg-icon>
        </div>
        <div class="section-input">
          <span v-html="sectionCopy.name || '&nbsp;'" class="name-mirror" />
          <input
            type="text"
            autocomplete="off"
            spellcheck="false"
            name="section-name"
            class="inline-edit"
            minlength="1"
            maxlength="100"
            ref="nameInput"
            v-model="sectionCopy.name"
            @blur="updateSectionName"
          />
        </div>

        <div class="section-info" :style="{ cursor: isOpen ? 'auto' : 'pointer' }" @click="isOpen = true">
          <div style="margin-left:20px" v-if="itemsBySection.length == 0">
            No screens
          </div>
          <div v-else-if="!isOpen" style="margin-left:20px">
            ({{ itemsBySection.length }} screen{{ itemsBySection.length > 1 ? 's' : '' }} collapsed)
          </div>
          <div style="flex:auto"></div>
        </div>
        <div class="section-actions">
          <v-tip class="flex" placement="bottom-end" :trigger="'click'" offset="4">
            <svg-icon class="icon-wrapper" :size="20" fill="currentColor" name="three-dots-horizontal"></svg-icon>

            <template slot="popover">
              <div class="section-pop">
                <div class="section-pop-items">
                  <!-- <div v-close-popover @click="handleSectionCommand('rename')" class="item">Rename section</div> -->
                  <div v-close-popover @click="handleSectionCommand('copyWebLink')" class="item">Copy web link</div>
                  <div
                    v-close-popover
                    @click="handleSectionCommand('delete')"
                    v-if="section.id != 'allId'"
                    class="delete-item-link item"
                  >
                    Delete section
                  </div>
                </div>
              </div>
            </template>
          </v-tip>
          <div v-if="itemsBySection.length > 0" class="icon-wrapper" @click="isOpen = !isOpen">
            <svg-icon v-if="isOpen" name="carret-down" :size="20" fill="currentColor"></svg-icon>
            <svg-icon v-else name="caret-right" :size="20" fill="currentColor"></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div :data-section-id="section.id" class="section-items-container d-container">
      <div
        v-show="isOpen"
        v-for="(item, index) in itemsBySection"
        class="section-item"
        :key="item.id"
        :data-id="item.id"
      >
        <div v-if="index != 0" @click="$emit('create', { index, section })" class="section-divider">
          <div class="line"></div>
          <svg-icon class="icon" style="margin:6px 0;" fill="currentColor" name="divide"></svg-icon>
          <div class="line"></div>
        </div>
        <div v-else style="width:30px"></div>
        <MasterScreenCard
          class="dragable"
          :key="item.id"
          :screen="item"
          :actions="actions"
          :flags="item.flags"
          :selected="item.isSelected"
          @click="navigate(item)"
          style="width:100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { updateArrayItemById } from '@/utils/javascript';
import { getSortedItems, isPositionChanged, calculateItemListPosition } from '@/services/dnd';
import MasterScreenCard from '@/components/Project/MasterScreenCard';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { toastSuccess } from '@/services/bus';
export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: true,
      sortableInstane: null,
      sectionCopy: {}
    };
  },
  components: {
    MasterScreenCard
  },
  computed: {
    ...mapState('projects', { currentProject: 'currentItem' }),
    ...mapState('teams', { currentTeam: 'currentItem' }),
    ...mapGetters({
      sections: 'components/sections',
      sectionsSorted: 'components/sectionsSorted',
      allowSectionsEdit: 'components/allowSectionsEdit'
    }),
    itemsBySection() {
      return getSortedItems(this.items, this.section.id).map(i => ({
        ...i,
        thumb_url: this.getThumbUrl(i)
      }));
    },
    omniviewRoute() {
      return ({ slug: screenSlug }) => {
        const { short_id: projectId } = this.currentProject;
        const { slug: teamSlug } = this.currentTeam;
        return { name: 'omniview', params: { teamSlug, projectId, screenSlug } };
      };
    }
  },
  methods: {
    ...mapMutations({
      setComponents: 'components/setComponents',
      selectScreen: 'components/setCurrentItem',
      setSections: 'components/setSections'
    }),

    ...mapActions({
      updateComponent: 'components/update',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),

    getThumbUrl(component) {
      const { thumb_url, thumbnails_urls, thumbnail_url } = component;
      const thumb = thumbnails_urls && thumbnails_urls['640x640'];
      return thumb || thumb_url || thumbnail_url;
    },

    navigate(component) {
      this.$trackEvent('screen-card.thumbnail.click');
      this.selectScreen(component);
      this.$router.push(this.omniviewRoute(component));
    },
    handleItemDrop(itemId, d, s) {
      if (!isPositionChanged(s, d)) return;
      const sorting_section_position = calculateItemListPosition(this.items, d, s, itemId);
      const currentSection = this.sections.find(section => section.id == d.droppableId);
      const sorting_section = {
        id: d.droppableId,
        name: currentSection.name,
        listPosition: currentSection.listPosition
      };
      const itemUpdateValues = {
        sorting_section,
        sorting_section_position
      };
      const flattenItems = this.items.reduce((acc, currentItem) => {
        const { linkedScreens = [] } = currentItem;
        return [...acc, currentItem, ...linkedScreens];
      }, []);
      const newItems = updateArrayItemById(flattenItems, itemId, itemUpdateValues);
      this.setComponents(newItems);
      try {
        this.updateComponent({
          id: itemId,
          payload: itemUpdateValues
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateSectionName() {
      const { projectId } = this.$route.params;
      const name = this.sectionCopy.name.trim();
      if (name == this.section.name) return;
      if (!name) {
        this.sectionCopy.name = this.section.name;
        return;
      }
      const updatedSections = updateArrayItemById(this.sections, this.section.id, { name });
      this.setSections(updatedSections);
      await this.updateProject({
        id: projectId,
        payload: {
          sections_sort: {
            sections: updatedSections
          }
        }
      });
      this.fetchProject({ id: projectId, skipCache: true });
    },
    handleSectionCommand(c) {
      switch (c) {
        case 'rename':
          this.$refs.nameInput.select();
          break;
        case 'delete':
          this.$emit('delete', this.section);
          break;
        case 'copyWebLink':
          this.$emit('copyWebLink', this.section);
          toastSuccess('Copied!');
          break;

        default:
          break;
      }
    },
    initItemsSortableInstance() {
      const sortableContainer = this.$el.querySelector('.section-items-container');
      if (!sortableContainer) return;
      this.sortableInstane = new Sortable(sortableContainer, {
        scroll: true,
        group: 'nested',
        handle: '.content',
        disabled: !this.allowSectionsEdit,
        forceFallback: true,
        fallbackOnBody: true,
        scrollSensitivity: 180,
        scrollSpeed: 20,
        bubbleScroll: true,
        animation: 200,
        onEnd: evt => {
          const itemId = evt.item.getAttribute('data-id');
          const sourceDroppableId = evt.from.getAttribute('data-section-id');
          const destinationDroppableId = evt.to.getAttribute('data-section-id');
          const source = {
            droppableId: sourceDroppableId,
            index: evt.oldIndex
          };
          const destination = {
            droppableId: destinationDroppableId,
            index: evt.newIndex
          };
          this.handleItemDrop(itemId, destination, source);
          this.$emit('dropEnd', { source, destination });
        }
      });
    }
  },
  mounted() {
    this.initItemsSortableInstance();
    this.sectionCopy = { ...this.section };
  },
  watch: {
    allowSectionsEdit(allow) {
      this.sortableInstane && this.sortableInstane.option('disabled', allow);
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.section-header {
  background: white;
  height: 40px;
  width: 100%;
}
.section-header .content {
  height: 100%;
  display: flex;
  padding-right: 10px;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 1070px) {
  .section-header .content {
    padding-right: 0;
  }
  .drag-handler {
    right: 6px;
    position: relative;
  }
}

.drag-handler {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  cursor: -webkit-grab;
  cursor: grab;
}
.drag-handler img {
  width: 20px;
  pointer-events: none;
  user-select: none;
}
.section-input {
  position: relative;
  // margin-bottom: 4px;
}
.section-info {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.section-actions {
  display: flex;
  align-items: center;
}

.section-item {
  display: flex;
  align-items: center;
  /* height: 248px; */
  height: 100%;
  margin-bottom: 40px;
  max-width: calc(1fr - 30px);
}

.section-divider {
  width: 30px;
  height: 75%;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  padding: 20px 0;
  .line {
    width: 2px;
    background: var(--primary);
    flex: 1;
    margin: 0 auto;
    visibility: hidden;
  }
  .icon {
    color: var(--primary);
    visibility: hidden !important;
  }

  &:hover {
    .line,
    .icon {
      visibility: visible !important;
    }
  }
}

.expandableInputWrapper {
  overflow: hidden;
  display: flex;
  flex-shrink: 1;
}
input.inline-edit {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  // border-bottom: 1px dashed transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

input.inline-edit,
.name-mirror {
  font-size: 16px;
  color: #3b3b3b;
  caret-color: var(--primary);
  vertical-align: top;
  padding: 6px 0;
  font-weight: 500;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.name-mirror {
  display: inline-block;
  visibility: hidden;
}

input.inline-edit:hover,
input.inline-edit:focus {
  // border-bottom: 1px dashed black;
  transition: background-color 0.5s;
}

.icon-wrapper {
  width: 28px;
  height: 28px;
  font-size: 12px;
  margin-left: 12px;
  color: #3b3b3b;
  border-radius: 50%;
  cursor: pointer;
  /* padding: 12px; */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  color: black;
}
.icon-wrapper:hover {
  /* background: #409eff; */
  opacity: 1;
}
.icon-wrapper .icon {
  font-size: 20px;
}

.section-items-container {
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding: 26px 0;
  width: 100%;
  width: calc(100% + 15px);
  margin-left: -15px;
  column-gap: 30px;
}
.copied {
  background: #92eeaf;
  border-radius: 4px;
  /* color: white; */
  font-size: 12px;
  padding: 4px 10px;
}

.section-pop {
  padding: 20px;
  background: white;
  width: 164px;
  color: white;
  font-size: 14px;
  box-shadow: var(--slightly-stronger-shadow);
  border-radius: 10px;

  &-items {
    color: var(--primary-text);
    .item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>

<style lang="scss">
.delete-item-link:hover {
  color: var(--primary);
}
.dragable {
  .content {
    cursor: -moz-grab;
    cursor: grab;
  }
}
</style>
