import Vue from 'vue';
import { createStoreModule } from '../utils';
import { EventBus } from '@/services/bus';
import router from '@/router';
import {
  DEACTIVATE_ALL,
  ACTIVATE_OVERRIDES,
  ACTIVATE_HOTSPOTS,
  ACTIVATE_COMMENTS,
  SEND_MESSAGE,
  RESET_COMMENTS,
  CLEAR_CODE_SELECTION,
  CLOSE_PANEL,
  OPEN_PANEL
} from '@/utils/events/omniviewEvents';

import { isSameRoute, simpleQueue } from '@/utils/javascript';
import { has } from 'lodash-es';

import store from '..';
import { uuid } from '@/utils/uuid';

const INTERCOM_TOURS_MAP = {
  'download-code-panel': 203593,
  'override-tag': 203682,
  'override-css-properties': 203683,
  grouping: 203651,
  compare: 203667,
  'code-framework': 203678,
  'code-preferences': 203680,
  'path-bar': 203675
};

let fromChromeExtensionUrl =
  location.href && location.href.toString() && location.href.toString().includes('chromeExtension=true');

export default createStoreModule({
  name: 'omniview',
  crud: false,
  state: {
    queue: new simpleQueue(),
    iframeId: uuid(),
    // Mode
    modes: [
      { name: 'In', icon: 'interactive', displayName: 'play' },
      { name: 'Co', icon: 'comments', displayName: 'comment' },
      { name: 'C', icon: 'code', displayName: 'code' }
    ],
    activeMode: { name: 'In', icon: 'interactive' },
    // Breakpoints
    breakpoints: [],
    slugsMap: {},
    activeBreakpoint: { id: 'res' },
    // IFrame
    currentFrameWidth: 0,
    currentFrameHeight: 0,
    // Node
    nodes: {},
    nodesWithOverrides: {},
    modelNodesMap: {},
    currentNode: {},
    lastGeneratedId: '',
    currentNodeModel: false,
    currentNodeHTML: '',
    currentNodeCSS: '',
    currentNodeJSX: '',
    currentNodeMd5Map: {},
    playgroundCode: {
      html: '',
      css: '',
      jsx: ''
    },
    isGeneratingPlaygroundCode: false,
    isExportingPlaygroundCode: false,
    currentNodePath: [],
    // Compare
    isCompareEnabled: false,
    isMultipleSelectionEnabled: false,
    isFullScreen: false,
    extensionName: new URLSearchParams(window.location.search)
      ? new URLSearchParams(window.location.search).get('extensionName')
      : '',
    isChromeExtension:
      (window.parent &&
        window.location !== window.parent.location &&
        window.document &&
        window.document.URL &&
        document.referrer &&
        typeof document.referrer === 'string' &&
        document.referrer.includes('figma')) ||
      fromChromeExtensionUrl
        ? true
        : false,
    showOnlyCode:
      (window.parent &&
        window.location !== window.parent.location &&
        window.document &&
        window.document.URL &&
        document.referrer &&
        typeof document.referrer === 'string' &&
        document.referrer.includes('figma')) ||
      fromChromeExtensionUrl
        ? true
        : false,
    compareOpacity: 0.5,
    visitedStyleguide: localStorage.getItem('visitedStyleguide')
      ? JSON.parse(localStorage.getItem('visitedStyleguide'))
      : {},
    codegenLang: localStorage.getItem('codeFramework') || 'html',
    codegenReactSyntax: localStorage.getItem('reactSyntax') || 'functional',
    codegenReactStyle: localStorage.getItem('reactStyle') || 'css',
    codegenVueStyle: localStorage.getItem('vueStyle') || 'css',
    codegenHTMLLayout: localStorage.getItem('htmlLayout') || 'auto_flexbox',
    codeDownloadPrefs: localStorage.getItem('codeDownloadPrefs')
      ? JSON.parse(localStorage.getItem('codeDownloadPrefs'))
      : { layout: 'auto_flexbox', framework: 'html' },
    htmlLayoutOptions: [
      { value: 'flexbox', label: 'Flexbox' },
      { value: 'absolute', label: 'Absolute Position' }
    ],
    reactSyntaxOptions: [
      { value: 'functional', label: 'Functions' },
      { value: 'class', label: 'Classes' }
    ],
    reactStyleOptions: [
      { value: 'css', label: 'Plain CSS' },
      { value: 'styled', label: 'Styled Components' },
      { value: 'sass', label: 'Sass', badgeText: 'Beta' }
    ],
    vueStyleOptions: [
      { value: 'css', label: 'Plain CSS' },
      { value: 'sass', label: 'Sass', badgeText: 'Beta' }
    ],
    isGeneratingCode: false,
    isWaitingForOverrides: false,
    isExportAllowed: false,
    isSyncAllowed: false,
    commentsSubView: 'comments',
    isSidebarMinimized: false,
    isComponentsSidebarMinimized: true,
    domLoading: true,
    iframeLoading: true,
    currentMasterSlug: false,
    multiSelectedNodes: [],
    captureType: 'png',
    isGeneratingCapture: {},
    componentViewData: '',
    componentViewSize: {
      componentIframe: {
        width: 0,
        height: 0
      },
      componentIframeInterface: {
        width: 0,
        height: 0
      },
      suggestedComponentIframe: {
        width: 0,
        height: 0
      }
    },
    base64Screenshot: null,
    panelHeight: 0,
    isAnimaScriptReady: false,
    projectSlugs: []
  },
  getters: {
    queue: state => state.queue,
    modes: state => state.modes,
    breakpoints: state => state.breakpoints,
    slugsMap: state => state.slugsMap,
    projectSlugs: state => state.projectSlugs,
    domLoading: state => state.domLoading,
    isAnimaScriptReady: state => state.isAnimaScriptReady,
    iframeLoading: state => state.iframeLoading,
    iframeId: state => state.iframeId,
    activeMode: state => state.activeMode,
    panelHeight: state => state.panelHeight,
    activeBreakpoint: state => state.activeBreakpoint,
    currentFrameWidth: state => state.currentFrameWidth,
    currentFrameHeight: state => state.currentFrameHeight,
    nodes: state => state.nodes,
    visitedStyleguide: state => state.visitedStyleguide,
    nodesWithOverrides: state => state.nodesWithOverrides,
    currentNode: state => state.currentNode,
    lastGeneratedId: state => state.lastGeneratedId,
    currentNodeModel: state => state.currentNodeModel,
    currentNodeHTML: state => state.currentNodeHTML,
    currentNodeCSS: state => state.currentNodeCSS,
    currentNodeJSX: state => state.currentNodeJSX,
    playgroundCode: state => state.playgroundCode,
    isGeneratingPlaygroundCode: state => state.isGeneratingPlaygroundCode,
    isExportingPlaygroundCode: state => state.isExportingPlaygroundCode,
    currentNodePath: state => state.currentNodePath,
    codegenLang: state => state.codegenLang,
    codegenReactSyntax: state => state.codegenReactSyntax,
    codegenReactStyle: state => state.codegenReactStyle,
    codegenVueStyle: state => state.codegenVueStyle,
    codegenHTMLLayout: state => state.codegenHTMLLayout,
    codeDownloadPrefs: state => state.codeDownloadPrefs,
    isExportAllowed: state => state.isExportAllowed,
    isSyncAllowed: state => state.isSyncAllowed,
    isGeneratingCode: state => state.isGeneratingCode,
    commentsSubView: state => state.commentsSubView,
    isSidebarMinimized: state => state.isSidebarMinimized,
    isComponentsSidebarMinimized: state => state.isComponentsSidebarMinimized,
    currentMasterSlug: state => state.currentMasterSlug,
    isMultipleSelectionEnabled: state => state.isMultipleSelectionEnabled,
    multiSelectedNodes: state => state.multiSelectedNodes,
    currentNodeMd5Map: state => state.currentNodeMd5Map,
    captureType: state => state.captureType,
    isGeneratingCapture: state => state.isGeneratingCapture,
    componentViewData: state => state.componentViewData,
    modelNodesMap: state => state.modelNodesMap,
    componentViewSize: state => state.componentViewSize,
    nodeOverrides: (_state, getters, _rootState, rootGetters) => {
      const componentOverrides = rootGetters['componentsMetadata/currentComponentMetadata'].overrides || {};
      const currentNode = getters['currentNode'];
      const nodeOverrides = componentOverrides[currentNode.id];
      if (!nodeOverrides) return {};

      return nodeOverrides;
    },
    isNativeImage(_state, getters) {
      const { layer } = router.currentRoute.query;
      const modelNodesMap = getters['modelNodesMap'];
      let n = modelNodesMap[layer] || {};
      let a = has(n, 'model_class');
      return a && n['model_class'] == 'ADModelImageView';
    },
    styleType(state) {
      let type;
      switch (state.codegenLang) {
        case 'html':
          type = 'css';
          break;
        case 'react':
          type = state.codegenReactStyle;
          break;
        case 'vue':
          type = state.codegenVueStyle;
          break;
        default:
          type = 'unknown';
          break;
      }
      return type;
    },
    codegenStylesheetLang(state) {
      let type = 'css';
      switch (state.codegenLang) {
        case 'react':
          if (['css', 'sass', 'scss'].includes(state.codegenReactStyle)) {
            type = state.codegenReactStyle;
            break;
          }
          type = 'css';
          break;
        case 'vue':
          type = state.codegenVueStyle;
          break;
        default:
          type = 'css';
      }
      return type;
    },
    isPro(_state, _getters, _rootState, _rootGetters) {
      const { teamSlug } = _rootState.route.params;
      const hasPermissions = _rootGetters['teamMemberships/hasPermissions'];
      return hasPermissions({ teamSlug, plan: 'pro' });
    },
    shouldShowPaywall(_state, _getters, _rootState) {
      const { isChromeExtension } = _state;
      const { isPro } = _getters;
      const isAdmin = !!_rootState?.users?.currentItem?.is_admin;
      const isSampleProject = !!_rootState?.projects?.currentItem?.is_sample_project;
      const isEnterprise = !!process.env.ORGANIZATION;

      return (!isChromeExtension && !isPro && !isAdmin && !isSampleProject) || isEnterprise;
    },
    isCodeDisplayed(state) {
      return !!(state.setCurrentNodeCSS || state.currentNodeHTML || state.currentNodeJSX);
    }
  },
  mutations: {
    setCommentsSubView: (state, v) => (state.commentsSubView = v),
    setIframeId: (state, id) => (state.iframeId = id),
    setIsSidebarMinimized: (state, f) => {
      state.isSidebarMinimized = f;
      setTimeout(() => {
        EventBus.$emit('update-offset');
      }, 200);
    },
    setIsComponentsSidebarMinimized: (state, f) => {
      state.isComponentsSidebarMinimized = f;
      setTimeout(() => {
        EventBus.$emit('update-offset');
      }, 200);
    },
    setCompareOpacity: (state, opacity) => (state.compareOpacity = opacity),
    setProjectSlugs: (state, sgs) => (state.projectSlugs = sgs),
    setPanelHeight: (state, opacity) => (state.panelHeight = opacity),
    setSlugsMap: (state, map) => (state.slugsMap = map),
    setIsCompareEnabled: (state, flag) => (state.isCompareEnabled = flag),
    setIsMultipleSelectionEnabled: (state, flag) => {
      state.isMultipleSelectionEnabled = flag;
    },
    setIsFullScreen: (state, flag) => (state.isFullScreen = flag),
    setLastGeneratedId: (state, id) => (state.lastGeneratedId = id),
    setShowOnlyCode: (state, flag) => (state.showOnlyCode = flag),
    setBreakpoints: (state, breakpoints) => (state.breakpoints = breakpoints),
    setActiveBreakpoint: (state, breakpoint) => {
      if (breakpoint.id == 'res') {
        EventBus.$emit('highlight-handler', true);
      }
      state.activeBreakpoint = breakpoint;
    },
    setActiveMode: (state, mode) => (state.activeMode = mode),
    setIsAnimaScriptReady: (state, f) => (state.isAnimaScriptReady = f),
    setVisitedStyleguide: (state, f) => (state.visitedStyleguide = f),
    setCurrentFrameWidth: (state, width) => (state.currentFrameWidth = width),
    setCurrentFrameHeight: (state, height) => (state.currentFrameHeight = height),
    setNodes: (state, nodes) => (state.nodes = nodes),
    setNodesWithOverrides: (state, nodes) => (state.nodesWithOverrides = nodes),
    setModelNodesMap: (state, map) => (state.modelNodesMap = map),
    setCurrentNode: (state, node) => (state.currentNode = node),
    setCurrentNodeModel: (state, model) => (state.currentNodeModel = model),
    setCurrentNodeHTML: (state, value) => {
      state.currentNodeHTML = value.replace('<!DOCTYPE html>', '').trim();
    },
    setCurrentNodeJSX: (state, value) => (state.currentNodeJSX = value),
    setCurrentNodeMd5Map: (state, map) => (state.currentNodeMd5Map = map),

    setPlaygroundCode: (state, value) => {
      state.playgroundCode = value;
    },
    setIsGeneratingPlaygroundCode: (state, value) => (state.isGeneratingPlaygroundCode = value),
    setIsExportingPlaygroundCode: (state, value) => (state.isExportingPlaygroundCode = value),
    setCurrentNodeCSS: (state, css) => (state.currentNodeCSS = css),
    setCurrentNodePath: (state, path) => (state.currentNodePath = path),
    setCodegenLang: (state, lang) => {
      EventBus.$emit('update-anima-scripts-modelNodes', { reset: false });
      state.codegenLang = lang;
    },
    setCodegenReactSyntax: (state, t) => (state.codegenReactSyntax = t),
    setCodegenReactStyle: (state, t) => (state.codegenReactStyle = t),
    setCodegenVueStyle: (state, t) => (state.codegenVueStyle = t),
    setCodegenHTMLLayout: (state, t) => (state.codegenHTMLLayout = t),
    setCodeDownloadPrefs: (state, t) => (state.codeDownloadPrefs = t),
    setIsWaitingForOverrides: (state, f) => (state.isWaitingForOverrides = f),
    setIsExportAllowed: (state, f) => (state.isExportAllowed = f),
    setIsSyncAllowed: (state, f) => (state.isSyncAllowed = f),
    setIsGeneratingCode: (state, f) => (state.isGeneratingCode = f),
    setDomLoading: (state, f) => (state.domLoading = f),
    setIframeLoading: (state, f) => (state.iframeLoading = f),
    setCurrentMasterSlug: (state, f) => (state.currentMasterSlug = f),
    setMultiSelectedNodes: (state, nodes) => (state.multiSelectedNodes = nodes),
    setCaptureType: (state, t) => (state.captureType = t),
    setIsGeneratingCapture: (state, f) => (state.isGeneratingCapture = f),
    setComponentViewData: (state, f) => (state.componentViewData = f),
    setComponentViewSize: (state, { iframeName, data }) => {
      Vue.set(state.componentViewSize, iframeName, data);
    },

    resetPlaygroundCode: state => {
      state.playgroundCode = {
        html: '',
        css: '',
        jsx: ''
      };
    },

    resetSelection: state => {
      state.currentNodeModel = false;
      state.currentNode = {};
      state.currentNodePath = [];
      state.currentNodeHTML = '';
      state.currentNodeJSX = '';
      state.currentNodeCSS = '';
      state.lastGeneratedId = '';

      store.commit('webComponents/setNestedComponentsStack', [], { root: true });

      if (state.activeMode.name == 'Co') {
        EventBus.$emit(SEND_MESSAGE, {
          action: RESET_COMMENTS
        });
      }
      if (state.activeMode.name == 'C') {
        EventBus.$emit(SEND_MESSAGE, {
          action: CLEAR_CODE_SELECTION
        });
        EventBus.$emit(SEND_MESSAGE, {
          action: 'disable-component-view'
        });
      }
      router
        .replace({
          ...router.currentRoute,
          query: {
            ...router.currentRoute.query,
            layer: undefined,
            component: undefined
          }
        })
        .catch(() => {});
    },
    setBase64Screenshot: (state, val) => (state.base64Screenshot = val)
  },
  actions: {
    cleanup({ commit, state }) {
      commit('resetSelection');
      commit('setActiveMode', state.modes[0]);
      commit('setBreakpoints', []);
      commit('setBreakpoints', []);
      commit('setLastGeneratedId', '');
      commit('setIsGeneratingPlaygroundCode', false);
      commit('setIsExportingPlaygroundCode', false);
      commit('setIsGeneratingCode', false);
    },
    getBackAllTheOverrides({ commit }, info) {
      commit('setIsWaitingForOverrides', true);
      EventBus.$emit(SEND_MESSAGE, {
        action: 'save-node',
        info
      });
    },
    getNodesWithOverridesData(_, ids) {
      EventBus.$emit(SEND_MESSAGE, {
        action: 'get-nodes-by-id',
        data: {
          ids
        }
      });
    },
    handleModeChange: ({ commit, state }, { mode, fromRoute = false }) => {
      return new Promise(resolve => {
        if (!fromRoute && mode.name === state.activeMode.name) {
          resolve(mode);
          return;
        }

        EventBus.$emit(SEND_MESSAGE, {
          action: DEACTIVATE_ALL
        });

        const before = () => {
          state.isMultipleSelectionEnabled = false;
        };

        const after = () => {
          state.isCompareEnabled = false;
          commit('setActiveMode', mode);
          setTimeout(() => {
            EventBus.$emit('update-offset');
            EventBus.$emit('autosize');
          }, 300);
          resolve(mode);
        };

        const enablePlayMode = () => {
          before();
          EventBus.$emit(SEND_MESSAGE, {
            action: ACTIVATE_HOTSPOTS
          });
          EventBus.$emit(CLOSE_PANEL, { forceClose: true });
          after();
        };
        const enableCommentsMode = () => {
          before();
          state.isSidebarMinimized = false;
          EventBus.$emit('update-comment-form');
          EventBus.$emit(SEND_MESSAGE, {
            action: ACTIVATE_COMMENTS
          });
          after();
        };
        const enableCodeMode = () => {
          before();
          const { screenSlug } = router.currentRoute.params;
          if (state.activeBreakpoint?.id === 'res') {
            const activeBr = state.breakpoints.find(br => br.component.slug == screenSlug);
            EventBus.$emit('handleScreenChange', { screenSlug, fetchBreakpoints: false });

            activeBr && (state.activeBreakpoint = activeBr);
          }

          EventBus.$emit(SEND_MESSAGE, {
            action: ACTIVATE_OVERRIDES
          });

          EventBus.$emit(OPEN_PANEL);

          after();
        };

        switch (mode.name) {
          case 'In':
            {
              commit('resetSelection');
              if (fromRoute) {
                enablePlayMode();
              } else {
                // eslint-disable-next-line
                let { layer, component, ...q } = router.currentRoute.query;
                let newRoute = { ...router.currentRoute, query: { ...q, mode: 'play' } };
                if (isSameRoute(router.currentRoute, newRoute)) {
                  enablePlayMode();
                } else {
                  router
                    .push(newRoute)
                    .then(() => {
                      enablePlayMode();
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }
              }
            }

            break;

          case 'Co':
            if (fromRoute) {
              enableCommentsMode();
            } else {
              // eslint-disable-next-line
              const { component, ...q } = router.currentRoute.query;
              let newRoute = { ...router.currentRoute, query: { ...q, mode: 'comments' } };
              if (isSameRoute(router.currentRoute, newRoute)) {
                enableCommentsMode();
              } else {
                router
                  .push(newRoute)
                  .then(() => {
                    enableCommentsMode();
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }
            }

            break;
          case 'C':
            if (fromRoute) {
              enableCodeMode();
            } else {
              let newRoute = { ...router.currentRoute, query: { ...router.currentRoute.query, mode: 'code' } };
              if (isSameRoute(router.currentRoute, newRoute)) {
                enableCodeMode();
              } else {
                router
                  .push(newRoute)
                  .then(() => {
                    enableCodeMode();
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }
            }

            break;

          default:
            break;
        }
      });
    },
    startTour(_, name) {
      Vue.prototype.$intercom._call('startTour', INTERCOM_TOURS_MAP[name]);
      Vue.prototype.$trackEvent('omniview.intercom-tour.show', { name });
    }
  }
});
