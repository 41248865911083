// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../../assets/svg/mask.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper[data-v-419e45c6] {\n  padding: 30px;\n  position: relative;\n}\n.back[data-v-419e45c6] {\n  position: absolute;\n  top: 30px;\n  left: 30px;\n  color: var(--primary);\n  cursor: pointer;\n}\n.header[data-v-419e45c6] {\n  font-size: 24px;\n  line-height: 30px;\n  color: #333333;\n  margin-bottom: 20px;\n}\n.interface-body > * + *[data-v-419e45c6] {\n  margin-left: 10px;\n}\n.sub-header[data-v-419e45c6] {\n  font-size: 14px;\n  color: rgba(51, 51, 51, 0.6);\n  text-align: center;\n}\n.frame[data-v-419e45c6] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.frame[data-v-419e45c6] {\n  width: 50%;\n  min-height: 375px;\n  height: 375px;\n}", ""]);
// Exports
module.exports = exports;
