var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-in-" + _vm.side } }, [
    _vm.show
      ? _c(
          "div",
          {
            class: ["arrow-container", _vm.side],
            on: { click: _vm.onClickArrow }
          },
          [
            _c("svg-icon", {
              attrs: { name: "arrow-" + _vm.side, size: 36, fill: "white" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }