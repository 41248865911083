<template>
  <div class="onboarding">
    <TopBar @close="onClose" closeButton />
    <Pricing :theme="theme" onboardingFlow />
  </div>
</template>

<script>
import TopBar from '@/components/Onboarding/TopBar';
import Pricing from '@/views/Pricing';

export default {
  data() {
    return { theme: 'dark' };
  },
  components: {
    TopBar,
    Pricing
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
    document.body.style.overflowY = 'auto';
  },
  methods: {
    onClose() {
      const { params } = this.$route;
      this.$router.push({ name: 'team', params });
      this.$trackEvent('onboarding-pricing.close-button.click');
    }
  }
};
</script>
