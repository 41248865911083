var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "push-notifications", style: { zIndex: 50000 } },
    [
      _c(
        "transition-group",
        { attrs: { name: "slide-in-left", tag: "div" } },
        _vm._l(_vm.notifications, function(n, idx) {
          return _c(
            "div",
            { key: n.id + "-" + idx, staticClass: "notification-container" },
            [
              _c("Notification", {
                attrs: { item: n, compactView: "" },
                on: {
                  click: n.onClick,
                  close: function($event) {
                    return _vm.removeNotification(n)
                  }
                }
              }),
              n.onClick
                ? _c(
                    "div",
                    { staticClass: "link" },
                    [
                      _c(
                        "an-link",
                        {
                          attrs: { variant: "primary" },
                          on: { click: n.onClick }
                        },
                        [_vm._v(_vm._s(n.cta))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }