var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "number-input-container" },
    [
      _c(
        "an-tooltip",
        {
          attrs: {
            text: _vm.minErrorTooltipText,
            position: "bottom-left",
            disabled: !_vm.minErrorTooltipText
          }
        },
        [
          _c(
            "div",
            { staticClass: "clickable-icon", on: { click: _vm.subtract } },
            [_c("svg-icon", { attrs: { name: "circle-minus", size: 24 } })],
            1
          )
        ]
      ),
      _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
        _c("div", { key: _vm.displayValue, staticClass: "number" }, [
          _vm._v(" " + _vm._s(_vm.displayValue) + " ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "clickable-icon", on: { click: _vm.add } },
        [_c("svg-icon", { attrs: { name: "circle-plus", size: 24 } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }