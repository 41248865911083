var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination-container" },
    [
      _c("span", [_vm._v(_vm._s(_vm.paginationText))]),
      _c(
        "div",
        { staticClass: "pagination-bar" },
        [_c("ProgressBar", { attrs: { progress: _vm.progress, height: 4 } })],
        1
      ),
      _c(
        "an-button",
        {
          attrs: { variant: "secondary", isWorking: _vm.isLoadingMore },
          on: {
            click: function($event) {
              return _vm.$emit("load-more")
            }
          }
        },
        [_vm._v("Load more")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }