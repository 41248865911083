var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("Let’s get started")]),
    _c(
      "div",
      { staticClass: "navigation" },
      [
        _c("Tabs", {
          attrs: { activeTabLabel: _vm.activeTabLabel, items: _vm.tabs },
          on: { change: _vm.onTabChange }
        }),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("SearchInput", {
              on: { "toggle-open": _vm.toggleSearchOpen },
              model: {
                value: _vm.searchQuery,
                callback: function($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      [
        _vm.loading && !_vm.loadingMore
          ? _c("default-loader")
          : _c("CardsGrid", {
              attrs: {
                itemsType: "education-post",
                items: _vm.posts,
                filteredResults: !!_vm.searchQuery,
                totalAmount: _vm.totalPosts,
                isLoadingMore: _vm.loadingMore
              },
              on: {
                "click-education-post": _vm.openPost,
                "load-more": _vm.loadMore
              }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("The absolute beginner's guide")
      ]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          " Want to get started with Anima, but don’t know where to begin? Anima beginner's guide will help you start designing immediately. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }