var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OnboardingTopBar", { attrs: { homepageUrl: "/" } }),
      _c("div", { staticClass: "container dark" }, [
        !_vm.resetPasswordSubmitted
          ? _c("div", [
              _c("div", { staticClass: "title" }, [
                _vm._v("Forgot your password?")
              ]),
              _c("div", { staticClass: "subtitle" }, [
                _vm._v(
                  " Don’t worry, it happens to the best of us! We’ll email you a password reset link. "
                )
              ]),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Your email address")
                  ]),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c("an-input", {
                        attrs: {
                          id: "email",
                          type: "email",
                          theme: _vm.theme,
                          invalid: !_vm.emailValid,
                          placeholder: "Email address",
                          focus: ""
                        },
                        on: {
                          input: function($event) {
                            _vm.showButton = true
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  ),
                  _c("transition", { attrs: { name: "fadeIn" } }, [
                    _vm.showButton
                      ? _c(
                          "div",
                          { staticClass: "actions" },
                          [
                            _c(
                              "an-button",
                              {
                                attrs: { isWorking: _vm.isWorking },
                                on: { click: _vm.submit }
                              },
                              [_vm._v("Reset password")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "link" },
                    [
                      _c(
                        "an-link",
                        {
                          attrs: { theme: _vm.theme },
                          on: { click: _vm.navigateToLogin }
                        },
                        [_vm._v("Return to log in")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c("div", [
              _c("div", { staticClass: "title" }, [_vm._v("Check your inbox")]),
              _vm._m(0),
              _c(
                "div",
                { staticClass: "link" },
                [
                  _c(
                    "an-link",
                    { attrs: { theme: _vm.theme }, on: { click: _vm.submit } },
                    [_vm._v("Resend email")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("an-button", { on: { click: _vm.navigateToLogin } }, [
                    _vm._v("Back to log in")
                  ])
                ],
                1
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _vm._v(" We’ve sent a reset link to your inbox. "),
      _c("br"),
      _vm._v(" If it doesn’t show up soon, check your spam folder. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }