<template>
  <div class="container">
    <div class="title">Plan and Billing</div>
    <div class="subtitle">Change or update your team plan and keep track of your billing settings.</div>

    <div class="body">
      <div class="separated">
        <div class="label">Current subscription</div>
        <div class="plan">
          <div class="plan-header">
            <div class="plan-header-text plan-name">
              <span>{{ planName }} plan</span>
              <span v-if="isPaused" class="warn-text">{{ pauseText }}</span>
            </div>
            <div class="plan-header-cta">
              <an-link v-if="isActivePlan" class="plan-header-cta-cancel" @click="cancelPlan">
                Cancel plan
              </an-link>
              <an-button variant="secondary" @click="onChangePlanClick" :isWorking="isModifyPlanWorking">
                {{ modifyPlanButtonText }}
              </an-button>
            </div>
          </div>
          <div class="plan-text">
            {{ planInterval }}
            <div v-if="!isPaused" :class="{ 'warn-text': isCancelled || isPassedDue }">
              {{ planRenewal }}
            </div>
          </div>
        </div>
      </div>

      <div class="separated" v-if="!isEmpty(activeSubscription) && !(isCancelled || isPaused)">
        <div class="label">Contributors on your team</div>
        <div class="seats">
          <div class="seats-header">
            <div class="seats-header-text seats-name">
              <span>{{ contributorsTitle }}</span>
            </div>
            <div class="seats-header-cta">
              <an-button variant="secondary" @click="onAddSeatsClick">
                Add seats
              </an-button>
            </div>
          </div>
          <div class="seats-text">
            {{ pricePerUserStatement }}<br />
            {{ seatsUsedStatement }}
          </div>
        </div>
      </div>

      <div class="separated" v-if="!isEmpty(activeSubscription)">
        <div class="label">Payment information</div>
        <div class="payment">
          <div class="payment-header">
            <div class="payment-header-text">
              <span class="payment-amount">{{ totalPrice(activeSubscription) }} </span>
              <span>{{ paymentIntervalStatement }}</span>
            </div>

            <div class="payment-header-cta">
              <!-- STRIPE BUTTONS -->
              <an-link
                v-if="isCancelled && team.credit_card_last_digits && isStripeActiveSubscription"
                class="payment-header-cta-cancel"
                @click="showDeleteCardPrompt = true"
                primaryHover
              >
                Delete card
              </an-link>
              <an-button
                v-else-if="!showStripeCard && isStripeActiveSubscription"
                variant="secondary"
                @click="onManagePaymentClick"
                :isWorking="isManagePaymentWorking"
              >
                Manage payment
              </an-button>

              <!-- PAYPAL BUTTONS -->
              <an-button v-if="isPaypalActiveSubscription" variant="secondary" @click="openPaypal">
                Open PayPal
              </an-button>
            </div>

            <an-prompt v-if="showDeleteCardPrompt" @close="showDeleteCardPrompt = false">
              <div class="prompt-content">
                <div class="title">Delete payment method</div>
                <div class="text">Are you sure you want to delete this payment method?</div>
                <div class="actions">
                  <an-button @click="removeStripeCard" :isWorking="isManagePaymentWorking">Delete</an-button>
                  <an-link @click="showDeleteCardPrompt = false">Cancel</an-link>
                </div>
              </div>
            </an-prompt>
          </div>

          <div class="payment-text">
            <div>{{ nextPayment }}</div>
            <div class="payment-label" v-if="showStripeCard">Payment method</div>
            <div v-if="showStripeCard">
              <div class="stripe-card">
                <div><StripeCardInput @mount="onStripeCardMount" @change="onStripeCardChange" /></div>
                <div>
                  <an-button
                    @click="updateCard"
                    variant="secondary"
                    :disabled="!validCard"
                    :isWorking="isManagePaymentWorking"
                  >
                    Update
                  </an-button>
                </div>
                <div><an-link @click="onCancelUpdateCard">Dismiss</an-link></div>
              </div>
            </div>
            <div>
              {{ paymentInformationText }}
            </div>
          </div>
        </div>
      </div>

      <div class="separated">
        <div class="label">Invoice to</div>
        <an-textarea placeholder="Acme Corp, Inc. VAT 47845734" withBorder v-model="invoiceTo" allowMultiline />
      </div>

      <div class="payment-table" v-if="invoices && invoices.length">
        <div class="label">Invoices</div>
        <table class="table-container">
          <thead class="table-header">
            <tr>
              <td>Date</td>
              <td>Type</td>
              <td>Amount</td>
              <td>Invoices</td>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="(item, i) in invoices" :key="`row${i}`">
              <td>{{ formatDate(item.created * 1000, 'MMM DD, YYYY') }}</td>
              <td>Invoice</td>
              <td>{{ invoicePrice(item) }}</td>
              <td class="flex">
                <an-link
                  class="clickable-icon"
                  :href="item.hosted_invoice_url"
                  target="_blank"
                  @click="$trackEvent('team-settings-billing.view-invoice.click')"
                >
                  <svg-icon name="eye" :size="24" />
                </an-link>
                <an-link
                  class="clickable-icon"
                  :href="item.invoice_pdf"
                  download
                  @click="$trackEvent('team-settings-billing.download-invoice.click')"
                >
                  <svg-icon name="download-dark" :size="24" />
                </an-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="fade">
      <div class="actions fixed" v-if="hasChanges">
        <an-button @click="save" :isWorking="isWorking">Save changes</an-button>
        <an-link @click="$emit('close')">Cancel</an-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { isEmpty, upperFirst } from 'lodash-es';
import { formatDate } from '@/utils/date';
import { formatPrice } from '@/utils/billing';
import { EventBus, toastError, toastSuccess } from '@/services/bus';
import { SubscriptionMixin } from '@/mixins';
import StripeCardInput from '@/components/Payment/StripeCardInput';
import dayjs from 'dayjs';

export default {
  name: 'team-settings-billing',
  data() {
    return {
      invoiceTo: '',
      billingEmail: '',
      nextBillingDate: null,
      currentSubscriptionEnd: null,
      creditCard: null,
      validCard: false,
      showStripeCard: false,
      isWorking: false,
      showDeleteCardPrompt: false,
      isManagePaymentWorking: false,
      isModifyPlanWorking: false
    };
  },
  components: {
    StripeCardInput
  },
  mounted() {
    this.fetchData();
    this.reset();
  },
  destroyed() {
    this.setInvoices([]);
  },
  mixins: [SubscriptionMixin],
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('invoices', { invoices: 'items' }),
    ...mapState('stripeSubscriptions', { stripe: 'items' }),
    ...mapState('paypalSubscriptions', { paypal: 'items' }),
    ...mapGetters('stripeSubscriptions', ['activeSubscription']),
    ...mapGetters('teamMemberships', ['contributorsCountInTeam']),
    hasChanges() {
      const { billingEmail, invoiceTo } = this;
      const { billing_email, invoice_to } = this.team;
      return billingEmail !== billing_email || invoiceTo !== invoice_to;
    },
    planName() {
      const { product_name = '' } = this.activeSubscription;
      const fixedPlanName = product_name === 'Prototype' ? 'Basic' : upperFirst(product_name);
      return fixedPlanName || 'Free';
    },
    isPaused() {
      return this.activeSubscription?.is_paused;
    },
    pauseText() {
      const { pause_end } = this.activeSubscription;
      if (pause_end) {
        return `Paused until ${formatDate(pause_end, 'MMM DD, YYYY')}`;
      }
      return '';
    },
    isCancelled() {
      return !!this.activeSubscription?.cancelled_requested_at_date;
    },
    isPassedDue() {
      return this.activeSubscription?.status === 'past_due';
    },
    isActivePlan() {
      return !isEmpty(this.activeSubscription) && !this.isPaused && !this.isCancelled;
    },
    isStripeActiveSubscription() {
      const { _service: service } = this.activeSubscription ?? {};
      return service === 'stripe';
    },
    isPaypalActiveSubscription() {
      const { _service: service } = this.activeSubscription ?? {};
      return service === 'paypal';
    },
    modifyPlanButtonText() {
      if (this.isPaused) {
        return 'Cancel pause';
      } else if (this.isCancelled) {
        return 'Renew plan';
      } else if (this.planName === 'Free') {
        return 'Upgrade';
      } else {
        return 'Change plan';
      }
    },
    planInterval() {
      const { interval } = this.activeSubscription;
      if (isEmpty(this.activeSubscription)) {
        return 'Limited to one project.';
      }
      return `${upperFirst(interval)} plan`;
    },
    planRenewal() {
      if (this.isCancelled && this.activeSubscription?._service === 'paypal') {
        return 'Cancelled.';
      }
      if (this.isPassedDue) {
        return 'Payment failed! Please validate your payment method.';
      }
      if (isEmpty(this.currentSubscriptionEnd)) {
        return '';
      }

      const date = formatDate(this.currentSubscriptionEnd);
      return `${this.isCancelled ? 'Ends' : 'Next billing date is'} on ${date}`;
    },
    contributorsTitle() {
      const { quantity } = this.activeSubscription;
      return `${quantity} contributor${quantity === 1 ? '' : 's'}`;
    },
    pricePerUserStatement() {
      const { product_name = '', interval = '' } = this.activeSubscription;
      const price = this.getPrice({ plan: product_name.toLowerCase(), interval });
      return `${formatPrice(price)} / contributor / month`;
    },
    seatsUsedStatement() {
      const { contributorsCountInTeam: contributorsCount = 0 } = this;
      const { quantity: seats = 0 } = this.activeSubscription ?? {};
      if (seats && contributorsCount && contributorsCount <= seats) {
        return `${contributorsCount} of ${seats} seats are in use`;
      }
      return '';
    },
    totalPrice() {
      return subscription => {
        const { product_name = '', interval = '', quantity = 0 } = subscription;
        const price = this.getPrice({
          plan: product_name.toLowerCase(),
          interval,
          quantity,
          perMonth: interval === 'monthly'
        });
        return formatPrice(price);
      };
    },
    paymentIntervalStatement() {
      const { interval = '' } = this.activeSubscription;
      const intervalStatement = `per ${interval === 'monthly' ? 'month' : 'year'}`;
      return intervalStatement;
    },
    paymentInformationText() {
      const { _service: service } = this.activeSubscription ?? {};
      const { credit_card_last_digits } = this.team;
      if (service === 'stripe' && credit_card_last_digits) {
        return `Card ending with **** ${credit_card_last_digits}`;
      } else if (service === 'paypal') {
        return 'Paid with PayPal';
      } else {
        return '';
      }
    },
    nextPayment() {
      if (!this.nextBillingDate || this.isPaused) return '';
      const date = formatDate(this.nextBillingDate);
      return `Next payment is scheduled for ${date}`;
    }
  },
  methods: {
    isEmpty,
    formatDate,
    ...mapActions({
      updateTeam: 'teams/update',
      fetchTeam: 'teams/fetchOne',
      fetchInvoices: 'invoices/fetchAllOfParent'
    }),
    ...mapMutations({
      setInvoices: 'invoices/setItems'
    }),
    async fetchData() {
      const { id, stripe_customer_id } = this.team;
      const { id: subscriptionId, _service: service } = this.activeSubscription ?? {};

      this.nextBillingDate = null;
      this.currentSubscriptionEnd = null;

      if (!id) return;

      const params = { stripe_customer_id };
      if (stripe_customer_id) {
        this.fetchInvoices({ parent: 'teams', id, params });
      }

      if (subscriptionId && service === 'stripe') {
        const {
          data: { next_billing_date, current_period_end }
        } = await this.fetchSubscriptionData({ service, subscriptionId });

        this.nextBillingDate = next_billing_date ? dayjs(next_billing_date) : null;
        this.currentSubscriptionEnd = current_period_end ? dayjs(current_period_end) : null;
      }
    },
    async removeStripeCard() {
      try {
        const { id: teamId } = this.team;
        this.$trackEvent('team-settings-billing.remove-card.click');

        this.isManagePaymentWorking = true;

        await this.deleteStripeSource(teamId);
        this.$trackEvent('team-settings-billing.remove-card.success');

        await this.fetchTeam({ id: teamId, skipCache: true });
        toastSuccess('Credit card removed successfully');

        this.showDeleteCardPrompt = false;
      } catch (err) {
        console.error(err);
        this.$trackEvent('team-settings-billing.remove-card.failure', { message: err.message });
        toastError('Failed removing credit card number :(');
      } finally {
        this.isManagePaymentWorking = false;
      }
    },
    onManagePaymentClick() {
      this.$trackEvent('team-settings-billing.manage-payment-button.click');
      this.showStripeCard = true;
    },
    openPaypal() {
      this.$trackEvent('team-settings-billing.open-paypal.click');
      window.open('https://www.paypal.com/myaccount/summary', '_blank');
    },
    onStripeCardMount(card) {
      this.creditCard = card;
      this.validCard = true;
    },
    onStripeCardChange({ error }) {
      this.validCard = !error;
    },
    async updateCard() {
      this.$trackEvent('team-settings-billing.update-card-button.click');
      try {
        const { id } = this.team;

        this.isManagePaymentWorking = true;

        await this.updateStripeSource(this.creditCard, id);
        await this.fetchTeam({ id, skipCache: true });

        this.$trackEvent('team-settings-billing.update-card.success');

        this.showStripeCard = false;
      } catch (err) {
        this.$trackEvent('team-settings-billing.update-card.failure', { message: err.messsage });
        toastError('Failed updating payment method');
      } finally {
        this.isManagePaymentWorking = false;
      }
    },
    onCancelUpdateCard() {
      this.$trackEvent('team-settings-billing.cancel-update-card.click');
      this.showStripeCard = false;
      this.validCard = false;
      this.creditCard = null;
    },
    reset() {
      this.invoiceTo = this.team.invoice_to;
      this.billingEmail = this.team.billing_email;
    },
    cancelPlan() {
      const { interval, _service: service } = this.activeSubscription ?? {};
      this.$trackEvent('team-settings-billing.cancel-plan.click');
      if (interval === 'annually' || service === 'paypal') {
        this.$router.push({ name: 'team-cancel-plan' });
      } else {
        this.$router.push({ name: 'team-downgrade' });
      }
    },
    invoicePrice({ total }) {
      return formatPrice(total / 100);
    },
    async onChangePlanClick() {
      if (this.isPaused) {
        this.$trackEvent('team-settings-billing.cancel-pause-button.click');
        this.unpausePlan();
      } else if (this.isCancelled) {
        this.$trackEvent('team-settings-billing.renew-button.click');
        this.renewPlan();
      } else {
        this.$trackEvent('team-settings-billing.change-plan-button.click');
        this.$router.push({ name: 'team-pricing' });
      }
    },
    async onAddSeatsClick() {
      const { interval } = this.activeSubscription ?? {};
      const query = { plan: this.planName.toLowerCase(), interval };
      this.$trackEvent('team-settings-billing.add-seats-button.click');
      this.$router.push({ name: 'team-payment', query });
    },
    async unpausePlan() {
      try {
        const { id: teamId } = this.team;
        const { id: subscriptionId, _service: service } = this.activeSubscription;

        this.isModifyPlanWorking = true;
        await this.unpauseSubscription({ service, teamId, subscriptionId });
        EventBus.$emit('reload-team-info');

        this.$trackEvent('team-settings-billing.cancel-pause.success');
        toastSuccess('Congrats! Your Anima plan was successfully reactivated.');
      } catch (err) {
        this.$trackEvent('team-settings-billing.cancel-pause.failure');
        toastError('Failed reactivating subscription');
      } finally {
        this.isModifyPlanWorking = false;
      }
    },
    async renewPlan() {
      try {
        const { id: teamId } = this.team;
        const { id: subscriptionId, _service: service } = this.activeSubscription;

        this.isModifyPlanWorking = true;
        await this.renewSubscription({ service, teamId, subscriptionId });
        EventBus.$emit('reload-team-info');

        this.$trackEvent('team-settings-billing.renew.success');
        toastSuccess('Congrats! Your Anima plan was successfully renewed.');
      } catch (err) {
        this.$trackEvent('team-settings-billing.renew.failure');
        toastError('Failed renewing subscription');
      } finally {
        this.isModifyPlanWorking = false;
      }
    },
    async save() {
      const { invoiceTo: invoice_to, billingEmail: billing_email } = this;
      const { id } = this.team;
      const payload = { invoice_to, billing_email };

      this.$trackEvent('team-settings-billing.save-button.click');

      try {
        this.isWorking = true;
        await this.updateTeam({ id, payload });
        await this.fetchTeam({ id, skipCache: true });

        this.$trackEvent('team-settings-billing.save.success');
      } catch (err) {
        toastError('Failed saving your billing information');
      } finally {
        this.isWorking = false;
      }
    },
    cancel() {
      this.$emit('close');
      this.$trackEvent('team-settings-billing.cancel.click');
    }
  },
  watch: {
    team() {
      this.fetchData();
      this.reset();
    },
    stripe() {
      this.reset();
    },
    paypal() {
      this.reset();
    },
    activeSubscription() {
      this.fetchData();
      this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
@import '@/styles/_mixins.scss';
.body,
.subtitle {
  width: 700px;
}
.plan,
.payment,
.seats {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &-text {
      font-size: 22px;
      .warn-text {
        margin-left: 12px;
      }
    }
    &-cta {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > * + * {
        margin-left: 20px;
      }
      &-cancel {
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &-name,
  &-amount {
    font-weight: bold;
  }
  &-table,
  &-billing-email-label {
    margin-top: 30px;
  }
  &-label {
    margin-top: 12px;
  }
}
.warn-text {
  @include body-text;
  color: var(--primary);
}
.stripe-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    &:first-child {
      width: 100%;
    }
    margin-right: 12px;
  }
}
.prompt-content {
  text-align: center;
  .title {
    @include headline;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .text {
    margin-bottom: 30px;
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > * + * {
      margin-top: 20px;
    }
  }
}
.separated:last-child {
  border-bottom: none;
}
</style>
