<template>
  <div class="interface">
    <div style="color:white" v-if="propsLoading" class="flex items-center justify-center w-full h-full">
      <svg-icon fill="currentColor" class="icon-spin" name="spin" :size="30"></svg-icon>
    </div>
    <template v-else>
      <div class="flex items-center">
        <span v-text="'<'" class="s"></span>
        <GhostInput
          @blur="$emit('update-component-name', { name: componentNameCopy })"
          @keyup.enter="$event.target.blur()"
          class="interfaceInput c-name"
          v-model="componentNameCopy"
          :key="renderId"
        />
      </div>
      <!-- Regular Props -->
      <ul class="prop-list flex flex-col space-y-6px">
        <li
          :class="{ highlight: isPropNodeSelected(prop, 'regular') }"
          v-for="prop in regularProps"
          :key="prop.prop_fingerprint"
          class="flex items-center prop-item space-x-8px"
          @mouseover="handlePropMouseOver(prop)"
          @mouseout="handlePropMouseOut"
        >
          <an-checkbox
            @input="$emit('update-component-prop-enable', { prop, type: 'regular' })"
            noMargin
            variant="dark"
            v-model="prop['is_enable']"
          />

          <GhostInput
            @blur="$emit('update-component-prop-name', { prop, type: 'regular' })"
            @keyup.enter="$event.target.blur()"
            class="interfaceInput prop"
            v-model="prop.name"
            :key="renderId"
          />

          <div class="truncate" style="color:white">
            {{ getPropValueDisplay(prop) }}
          </div>
        </li>
      </ul>
      <!-- Nested Props -->
      <ul style="padding-left: 15px;margin-top: 10px;" class="flex flex-col space-y-6px">
        <li v-for="prop in nestedProps" :key="prop.prop_fingerprint" class="flex flex-col space-y-6px">
          <div
            :class="{ highlight: isPropNodeSelected(prop, 'nested') }"
            style="color:white;padding:0 10px"
            class="flex items-center space-x-8px nested-row"
            @mouseover="handlePropMouseOver(prop)"
            @mouseout="handlePropMouseOut"
          >
            <an-checkbox
              @input="$emit('update-component-prop-enable', { prop, type: 'nested' })"
              noMargin
              variant="dark"
              v-model="prop['is_enable']"
            />
            <GhostInput
              @blur="$emit('update-component-prop-name', { prop, type: 'nested' })"
              @keyup.enter="$event.target.blur()"
              class="interfaceInput prop"
              v-model="prop.name"
              ref="nestedInputRefs"
              :key="renderId"
            />
            <span>{{ prop['is_enable'] && prop.value ? `= {` : `{ ... }` }}</span>

            <div style="margin-left:auto" class="flex items-center nested-row-actions">
              <an-button
                :style="{ '--height': '22px', '--padding': '0 15px' }"
                size="sm"
                variant="secondary"
                v-if="prop['is_enable'] && prop.value"
                @click="handleNestedLayerClick(prop)"
                >Edit</an-button
              >
            </div>
          </div>

          <div v-if="prop['is_enable'] && prop.value" class="truncate flex flex-col space-y-6px" style="color:white">
            <ul class="flex flex-col" v-for="(prop_value, prop_name) in prop.value" :key="prop_name">
              <li style="color:white;opacity:0.7" class="flex items-center prop-item-nested space-x-8px">
                <!-- <an-checkbox disabled noMargin variant="dark" :value="false" /> -->
                <!-- <GhostInput disabled class="interfaceInput prop" :value="prop_name" /> -->

                <span>{{ prop_name }}</span>

                <div class="truncate">
                  {{ `= ${typeof prop_value === 'object' && prop_value !== null ? '{ ... }' : `"${prop_value}"`}` }}
                </div>
              </li>
            </ul>
            <span style="padding-left: 23px;">{{ `}` }}</span>
          </div>
        </li>
      </ul>
      <div>
        <span v-text="'/>'" class="s"></span>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
import GhostInput from '@/components/Input/GhostInput.vue';

import { SEND_COMPONENT_MESSAGE } from '@/utils/events/omniviewEvents';
import { mapGetters } from 'vuex';
import { uuid } from '@/utils/uuid';
export default {
  components: {
    GhostInput
  },
  props: {
    componentName: {
      type: String,
      required: true
    },
    regularProps: {
      type: Array,
      required: true
    },
    nestedProps: {
      type: Array,
      required: true
    },
    propsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentNameCopy: '',
      renderId: uuid()
    };
  },
  watch: {
    componentName: {
      handler(name) {
        this.componentNameCopy = name;
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentWebComponent: 'webComponents/currentWebComponent',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId',
      currentWebComponentNode: 'webComponents/currentWebComponentNode'
    })
  },
  mounted() {
    EventBus.$on('web_component_override_update', this.forceReRender);
  },
  destroyed() {
    EventBus.$off('web_component_override_update', this.forceReRender);
  },
  methods: {
    forceReRender() {
      this.componentNameCopy = this.componentName;
      this.renderId = Math.random();
    },
    isPropNodeSelected(prop, type) {
      let { id } = this.currentWebComponentNode;
      if (!id) return false;

      if (type == 'regular') {
        return prop.model_id == id;
      }

      if (type == 'nested') {
        const { instance } = this.getMasterAndInstanceByNodeId(id);
        if (!instance) return false;
        return prop.model_id == instance.model_id;
      }
    },
    handlePropMouseOver(prop) {
      EventBus.$emit(SEND_COMPONENT_MESSAGE, {
        action: 'simulate-code-mouseover',
        data: {
          nodeId: prop.model_id
        },
        iframeName: 'componentIframeInterface'
      });
    },
    handlePropMouseOut() {
      EventBus.$emit(SEND_COMPONENT_MESSAGE, {
        action: 'simulate-code-mouseout',
        iframeName: 'componentIframeInterface'
      });
    },
    getPropValueDisplay({ type, value }) {
      if (type === 'string') {
        return `= "${value}"`;
      } else {
        return `= ${value}`;
      }
    },
    handleNestedLayerClick({ model_id }) {
      this.$emit('onPropsLoading', true);
      EventBus.$emit('open-component-in-library', {
        nodeId: model_id,
        preProcessParams: {
          forcePreProcess: true
        },
        openComponentInterface: false,
        onDoneEvent: {
          name: 'update-interface-props',
          params: {}
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.interface {
  width: 50%;
  min-height: 375px;
  height: 375px;
}

.prop-list {
  margin-top: 10px;
  padding-left: 15px;
}
.prop-item {
  padding: 0 10px;
  border-radius: 5px;
  &-nested {
    padding-left: 42px;
  }
}
.space-x-8px {
  > * + * {
    margin-left: 8px;
  }
}
.space-y-6px {
  > * + * {
    margin-top: 6px;
  }
}
.interface {
  background: #333333;
  border-radius: 5px;
  padding: 30px 20px;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
}

.s {
  color: #fd9170;
  margin-right: 5px;
}

.interfaceInput {
  background: #2d2d2d;
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  max-width: 200px;

  &.prop {
    caret-color: #80cbc4;
    color: #80cbc4;
    .editable {
      caret-color: #80cbc4;
    }
  }
  &.c-name {
    caret-color: #fd9170;
    color: #fd9170;
    .editable {
      caret-color: #fd9170;
    }
  }

  &:hover {
    border: 1px solid var(--red);
  }
}

.highlight {
  background-color: rgba(255, 255, 255, 0.1);
}

.nested-row {
  &-actions {
    display: none;
  }

  &:hover,
  &.highlight {
    .nested-row-actions {
      display: flex;
    }
  }
}
</style>
