var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "iframe-wrapper" },
    [
      _c("div", {
        staticClass: "overlay",
        style: { "pointer-events": _vm.isDragging ? "all" : "none" }
      }),
      _c(
        "transition",
        { attrs: { name: "fadeIn" } },
        [
          _c("ComponentFrame", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isComponentView,
                expression: "isComponentView"
              }
            ],
            key: "componentIframe",
            attrs: {
              mask: "",
              padding: 0,
              showBorder: "",
              name: "componentIframe"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isComponentView,
              expression: "!isComponentView"
            }
          ],
          key: "fullIframe",
          ref: "rsc",
          staticClass: "iframe-container",
          class: { responsive: _vm.isResponseMode },
          on: { click: _vm.handleIframeContainerClicked }
        },
        [
          _vm.activeMode.name == "In"
            ? _c(
                "div",
                {
                  staticClass: "full-screen-toggle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.toggleFullScreen.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.isFullScreen
                    ? _c("svg-icon", {
                        attrs: {
                          size: 20,
                          fill: "currentColor",
                          name: "page-minimize"
                        }
                      })
                    : _c("svg-icon", {
                        attrs: {
                          fill: "currentColor",
                          size: 20,
                          name: "page-enlarge"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              ref: "rs",
              staticClass: "resizable-iframe",
              style: {
                width:
                  this.isDesktop && _vm.activeBreakpoint.width
                    ? _vm.activeBreakpoint.width + "px"
                    : "100%",
                maxWidth: _vm.isResponseMode ? _vm.maxContainerWidth : "",
                minWidth: _vm.isResponseMode ? _vm.minContainerWidth : "",
                height: this.getViewportHeight()
              }
            },
            [
              !_vm.isPreview
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCompareEnabled,
                          expression: "isCompareEnabled"
                        }
                      ],
                      staticClass: "pop-out-container"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "compare-controlls" },
                        [
                          _c("span", { staticStyle: { width: "42px" } }, [
                            _vm._v(
                              _vm._s(Math.ceil(_vm.compareOpacity * 100) + "%")
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "slidecontainer" },
                            [
                              _c("svg-icon", {
                                directives: [
                                  {
                                    name: "tip",
                                    rawName: "v-tip",
                                    value: {
                                      content: "Result of Anima generated code",
                                      placement: "top-center",
                                      offset: 12
                                    },
                                    expression:
                                      "{\n                content: 'Result of Anima generated code',\n                placement: 'top-center',\n                offset: 12\n              }"
                                  }
                                ],
                                staticStyle: { "flex-shrink": "0" },
                                attrs: {
                                  fill: "currentColor",
                                  size: 20,
                                  name: "compare-0"
                                }
                              }),
                              _c("vue-slider", {
                                staticStyle: {
                                  width: "100%",
                                  "margin-left": "8px",
                                  "margin-right": "10px"
                                },
                                attrs: {
                                  value: _vm.compareOpacity * 100,
                                  min: 0,
                                  max: 100,
                                  tooltip: "none"
                                },
                                on: { change: _vm.handleSliderInput }
                              }),
                              _c("svg-icon", {
                                directives: [
                                  {
                                    name: "tip",
                                    rawName: "v-tip",
                                    value: {
                                      content:
                                        "Original design from design tool",
                                      placement: "top-center",
                                      offset: 12
                                    },
                                    expression:
                                      "{\n                content: 'Original design from design tool',\n                placement: 'top-center',\n                offset: 12\n              }"
                                  }
                                ],
                                staticStyle: { "flex-shrink": "0" },
                                attrs: {
                                  fill: "currentColor",
                                  size: 20,
                                  name: "compare-1"
                                }
                              })
                            ],
                            1
                          ),
                          _c("svg-icon", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              fill: "currentColor",
                              size: 20,
                              name: "close"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.setIsCompareEnabled(false)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "popOut",
                          staticClass: "pop-out",
                          style: _vm.popOutStyle
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.currentComponent.thumbnail_url,
                              alt: ""
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _c("span", [
                _c(
                  "div",
                  {
                    staticClass: "tools",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("transition", { attrs: { name: "flash" } }, [
                      _vm.isResponseMode
                        ? _c("div", { staticClass: "flash-indicator" })
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isResponseMode,
                            expression: "isResponseMode"
                          }
                        ],
                        staticClass: "canvas-resize-handle",
                        class: { show: _vm.firstRenderOrDrag }
                      },
                      [
                        _vm._m(0),
                        _c("div", { staticClass: "handle left-handle" })
                      ]
                    )
                  ],
                  1
                ),
                _c("iframe", {
                  ref: "iframeRef",
                  staticClass: "iframe",
                  style: {
                    width:
                      _vm.isResponseMode || this.isMobile
                        ? "100%"
                        : _vm.activeBreakpoint.width + "px",
                    height: "100%"
                  },
                  attrs: {
                    "data-hj-allow-iframe": "",
                    id: "iframeId",
                    name: "iframeName",
                    frameborder: "0",
                    src: _vm.iframeUrl
                  }
                })
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "handle right-handle" }, [
      _c("div", { staticClass: "gutter-handle" }),
      _c("div", { staticClass: "tab-handle", attrs: { id: "tab-handle" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }