import { render, staticRenderFns } from "./ElemetOverrides.vue?vue&type=template&id=20a2b238&scoped=true&"
import script from "./ElemetOverrides.vue?vue&type=script&lang=js&"
export * from "./ElemetOverrides.vue?vue&type=script&lang=js&"
import style0 from "./ElemetOverrides.vue?vue&type=style&index=0&id=20a2b238&lang=scss&scoped=true&"
import style1 from "./ElemetOverrides.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a2b238",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20a2b238')) {
      api.createRecord('20a2b238', component.options)
    } else {
      api.reload('20a2b238', component.options)
    }
    module.hot.accept("./ElemetOverrides.vue?vue&type=template&id=20a2b238&scoped=true&", function () {
      api.rerender('20a2b238', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/OmniView/Code/ElemetOverrides.vue"
export default component.exports