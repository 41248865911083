import {
  getPrice,
  getPlanId,
  updateStripeSource,
  deleteStripeSource,
  unpauseSubscription,
  pauseSubscription,
  activateCancellationOffer,
  renewSubscription,
  fetchSubscriptionData,
  createSubscription,
  cancelSubscription,
  tempValidatePromoCode
} from '@/services/subscriptions';

export default {
  methods: {
    cancelSubscription,
    createSubscription,
    deleteStripeSource,
    fetchSubscriptionData,
    getPlanId,
    getPrice,
    pauseSubscription,
    activateCancellationOffer,
    renewSubscription,
    tempValidatePromoCode,
    unpauseSubscription,
    updateStripeSource
  }
};
