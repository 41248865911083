<template>
  <an-prompt v-if="show" @close="$emit('close')">
    <div class="feedback-prompt-container">
      <div class="title">We're sad to see you go</div>
      <div>
        In order to build the best solution we need your help.<br />
        Please share your feedback and help us improve.
      </div>
      <div class="reasons">
        <div v-for="(reason, idx) in reasons" :key="idx">
          <an-radio :label="reason" :value="chosenReason" @change="onRadioChange" :centered="false">
            <div class="reason">
              <div @click="onRadioChange(reason)" class="reason-label">{{ reason }}</div>
              <an-input v-if="chosenReason === reason" placeholder="Tell us more please" v-model="details" focus />
            </div>
          </an-radio>
        </div>
      </div>
      <div class="actions">
        <an-button @click="onClickDowngrade" :disabled="ctaDisabled">Downgrade to Free</an-button>
        <an-link @click="dismiss">I don’t want to downgrade</an-link>
      </div>
    </div>
  </an-prompt>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';
export default {
  data() {
    return {
      chosenReason: '',
      details: null,
      reasons: [
        'Code was not good enough',
        'The result looks different from the design',
        'The product is not clear',
        'Project ended',
        'Missing feature',
        'Other'
      ]
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapGetters('stripeSubscriptions', ['activeSubscription']),
    ctaDisabled() {
      return !this.chosenReason;
    }
  },
  methods: {
    onRadioChange(value) {
      this.chosenReason = value;
    },
    getProps() {
      const { chosenReason: reason, details: freeText } = this;
      const { role } = this.user;
      const { teamSlug } = this.$route.params;
      const { created_at: subscriptionCreatedAt } = this.activeSubscription || {};
      let props = { reason, freeText, teamSlug, role };
      if (subscriptionCreatedAt) {
        props.timeToChurnInDays = dayjs().diff(subscriptionCreatedAt, 'days');
      }
      return props;
    },
    onClickDowngrade() {
      this.$trackEvent('cancel-plan-page.cancel-feedback.submit', this.getProps());
      this.$emit('submit');
    },
    dismiss() {
      this.$emit('dismiss');
    }
  },
  watch: {
    chosenReason() {
      this.details = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.feedback-prompt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  @include headline;
  font-weight: 500;
  margin-bottom: 20px;
}
.title,
.subtitle {
  text-align: center;
}
.reasons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 40px 0 40px 40px;
  width: 100%;
  @include mobile {
    margin: 40px 0;
  }
}
.reason {
  text-align: left;
  .reason-label {
    cursor: pointer;
  }
  .inputContainer {
    margin: 10px 0;
    width: 350px;
    @include mobile {
      width: 200px;
    }
  }
}
.actions {
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 20px;
  }
}
</style>
