var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "label" }, [_vm._v("Project name")]),
      _c(
        "div",
        { staticClass: "input" },
        [
          _c("an-input", {
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "label" }, [_vm._v("Current team")]),
      _c("div", { staticClass: "separated" }, [
        _c(
          "div",
          { staticClass: "team-row-container pad-left" },
          [
            _c("TeamRow", { key: _vm.team.id, attrs: { team: _vm.team } }),
            _c(
              "an-link",
              {
                attrs: { variant: "primary" },
                on: {
                  click: function($event) {
                    _vm.changeTeam = !_vm.changeTeam
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.changeTeam ? "Close" : "Change") + " ")]
            )
          ],
          1
        ),
        _vm.changeTeam
          ? _c("div", [
              _c("div", { staticClass: "label pad-left move-label" }, [
                _vm._v("Move to")
              ]),
              _c(
                "div",
                { staticClass: "teams-list" },
                _vm._l(_vm.teams, function(t, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: _vm.teamClasses(t),
                      on: {
                        click: function($event) {
                          _vm.selectedTeam = t
                        }
                      }
                    },
                    [_c("TeamRow", { key: t.slug, attrs: { team: t } })],
                    1
                  )
                }),
                0
              ),
              _vm.selectedTeam.id
                ? _c("div", { staticClass: "warn" }, [
                    _vm._v(
                      " All current team members will lose access to this project and it’ll be fully accessible to “" +
                        _vm._s(_vm.selectedTeam.name) +
                        "” team members. "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "separated" },
        [
          _c("an-link", { on: { click: _vm.archive } }, [
            _vm._v("Archive project")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "separated" },
        [
          _c("an-link", { on: { click: _vm.remove } }, [
            _vm._v("Delete project")
          ])
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.hasChanges
          ? _c(
              "div",
              { staticClass: "actions fixed" },
              [
                _c(
                  "an-button",
                  {
                    attrs: { isWorking: _vm.isWorking },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Save changes")]
                ),
                _c("an-link", { on: { click: _vm.cancel } }, [_vm._v("Cancel")])
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }