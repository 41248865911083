<template>
  <div class="container">
    <div class="title">Downgrade your plan</div>
    <div class="subtitle">
      Are you sure you want to change your Anima plan to free?
      <br />Sometimes you just need a short break!
    </div>

    <div class="body">
      <div class="separated">
        <an-radio :centered="false" name="downgrade" label="pause" :value="downgradeType" @change="onRadioChange">
          <div class="downgrade-label">Pause subscription</div>
          <div class="downgrade-text">
            Pause your subscription for up to 3 months, effective on your next billing date. You can resume or cancel at
            any time.
          </div>
          <div class="pause-select">
            <div class="label">Select pausing period</div>
            <div class="select" tabindex="-1" @click="toggleSelectOpen" @focusout="closeSelect">
              {{ pausePeriod }}
              <div class="select-icon" :class="{ rotated: pauseSelectIsOpen }">
                <svg-icon name="arrow-right" :size="30" />
              </div>
            </div>
            <Popover :isOpen="pauseSelectIsOpen" position="left" popperClass="popper" @close="closeSelect">
              <an-link @click="changePeriod('1 month')">Pause for 1 month</an-link>
              <an-link @click="changePeriod('2 month')">Pause for 2 months</an-link>
              <an-link @click="changePeriod('3 month')">Pause for 3 months</an-link>
            </Popover>
          </div>
        </an-radio>
      </div>
      <div class="separated">
        <an-radio :centered="false" label="cancel" :value="downgradeType" @change="onRadioChange">
          <div class="downgrade-label">Cancel subscription</div>
          <div>
            Your subscription will remain active until the end of your billing period. You’ll have full access to all
            your projects by then.
          </div>
        </an-radio>
      </div>
      <div class="actions">
        <an-button @click="apply" :isWorking="isWorking">Apply changes</an-button>
        <an-link @click="cancel">Dismiss</an-link>
      </div>
    </div>

    <an-prompt v-if="showCancelOfferPrompt" @close="showCancelOfferPrompt = false">
      <div class="prompt-content">
        <div class="title">{{ firstName }}, before you cancel,<br />consider this offer:</div>
        <div class="disclaimer">
          <span class="text bold">{{ cancelOfferTitle }}</span>
          <img src="@/assets/illustrations/cat-in-a-box.png" class="prompt-illustration" />
          <span class="text" v-if="cancelOfferSuccess">
            We're happy to have you with us!
          </span>
          <span class="text" v-else>Give us another try. No commitment.</span>
        </div>
        <div class="actions" v-if="cancelOfferSuccess">
          <an-link @click="closeCancelOfferPrompt">Dismiss</an-link>
        </div>
        <div class="actions" v-else>
          <an-button @click="onActivateCancellationOffer" :isWorking="cancelOfferWorking">Accept offer</an-button>
          <an-link @click="closeCancelOfferPrompt">Continue to cancel subscription</an-link>
        </div>
      </div>
    </an-prompt>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapState } from 'vuex';
import Popover from '@/components/Popovers/Popover';
import { EventBus, toastError } from '@/services/bus';
import { SubscriptionMixin, UserMixin } from '@/mixins';
import { reportCustomEvent } from 'anima-ppc-tracking';
import { formatDate } from '@/utils/date';

export default {
  name: 'downgrade',
  data() {
    return {
      downgradeType: 'cancel',
      pausePeriod: '1 month',
      rotateSelectIcon: false,
      pauseSelectIsOpen: false,
      isWorking: false,

      showCancelOfferPrompt: false,
      cancelOfferWorking: false,
      cancelOfferSuccess: false,
      nextBillingDate: null
    };
  },
  mixins: [SubscriptionMixin, UserMixin],
  components: {
    Popover
  },
  mounted() {
    const { interval: activeSubscriptionInterval = null } = this.activeSubscription ?? {};
    if (activeSubscriptionInterval === 'annually') {
      this.$router.push({ name: 'team-cancel-plan' });
    }
  },
  computed: {
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters({
      getExperimentVariant: 'experiments/isActive',
      activeSubscription: 'stripeSubscriptions/activeSubscription',
      firstName: 'users/firstName'
    }),
    cancelOfferType() {
      return this.getExperimentVariant('cancel-offer') || '1-month-free';
    },
    cancelOfferTitle() {
      if (this.cancelOfferSuccess) return 'You got it!';
      else if (this.cancelOfferType === '1-month-free') return 'Get your next month free!';
      else return 'Get 50% off for 3 months!';
    },
    nextBillingCycleStatement() {
      if (!this.nextBillingDate) return '';
      const date = formatDate(this.nextBillingDate, 'MMM DD, YYYY');
      return `Your next billing cycle is scheduled to ${date}`;
    }
  },
  methods: {
    ...mapActions({ updateSubscription: 'stripeSubscriptions/update' }),
    toggleSelectOpen() {
      this.pauseSelectIsOpen = !this.pauseSelectIsOpen;
    },
    closeSelect() {
      this.pauseSelectIsOpen = false;
    },
    onRadioChange(value) {
      this.downgradeType = value;
    },
    changePeriod(value) {
      this.pausePeriod = value;
    },
    closeCancelOfferPrompt() {
      this.showCancelOfferPrompt = false;
      this.$trackEvent('team-downgrade.cancel-offer-prompt.close', { offer: this.cancelOfferType });
      if (this.cancelOfferSuccess) {
        this.$emit('close', { redirect: { name: 'learn' } });
      } else {
        this.$router.push({ name: 'team-cancel-plan' });
      }
    },
    async pause() {
      try {
        const { pausePeriod, activeSubscription, team } = this;
        const { id: subscriptionId, product_name = 'Free', _service: service } = activeSubscription ?? {};
        const start = dayjs();
        const end = start.add(parseInt(pausePeriod, 10), 'month').toDate();

        const plan = product_name === 'Prototype' ? 'Basic' : product_name;
        this.$trackEvent('team-downgrade.pause', { plan, pausePeriod });

        this.isWorking = true;

        await this.pauseSubscription({ teamId: team.id, subscriptionId, start, end, service });

        EventBus.$emit('reload-team-info');
        this.$trackEvent('team-downgrade.pause.success');

        this.$gtm.trackEvent({
          event: 'freeze_account',
          event_category: 'Freeze Account',
          event_action: this.user?.role,
          event_label: this.isActiveUserByRole() ? 'Activated' : 'Not Activated'
        });

        reportCustomEvent('Pause Plan', { plan, pausePeriod });

        this.$router.push({ name: 'team-settings-billing' });
      } catch (err) {
        this.$trackEvent('team-downgrade.pause.failure', { message: err.message });
        toastError('Failed pausing subscription...');
      } finally {
        this.isWorking = false;
      }
    },
    async onActivateCancellationOffer() {
      const { slug: teamSlug } = this.team;
      const offer = this.cancelOfferType;
      try {
        this.$trackEvent('team-downgrade.cancel-offer-prompt.accept', { teamSlug, offer });

        this.cancelOfferWorking = true;
        const { data } = await this.activateCancellationOffer({ teamSlug, offer });
        this.nextBillingDate = data.next_billing_date;

        this.$trackEvent('team-downgrade.cancel-offer.success', { teamSlug, offer });

        this.cancelOfferSuccess = true;
      } catch (err) {
        const { request } = err;
        this.$trackEvent('team-downgrade.cancel-offer.failure', { message: err.message, teamSlug, offer });
        if (request?.status === 403) toastError("Oops! You can't use this offer twice");
        else toastError('Oops! something went wrong...');
      } finally {
        this.cancelOfferWorking = false;
      }
    },
    apply() {
      const { downgradeType, team, activeSubscription } = this;
      this.$trackEvent('team-downgrade.apply-changes-button.click', { downgradeType });
      if (downgradeType === 'pause') {
        this.pause();
      } else if (downgradeType === 'cancel') {
        const { _service: service } = activeSubscription;
        const { used_cancellation_offer } = team;

        if (service === 'stripe' && !used_cancellation_offer) {
          this.showCancelOfferPrompt = true;
          this.$trackEvent('team-downgrade.cancel-offer.show');
        } else {
          this.$router.push({ name: 'team-cancel-plan' });
        }
      }
    },
    cancel() {
      this.$trackEvent('team-downgrade.cancel.click');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.downgrade-label {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}
.downgrade-text {
  margin-bottom: 30px;
}
.pause-select {
  display: inline-block;
  position: relative;
}
.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border);
  border-radius: 10px;
  padding: 12px 22px;
  width: 300px;
  outline: none;
  cursor: pointer;
  &-icon {
    transition: transform 0.2s ease;
    transform: rotate(90deg);
    &.rotated {
      transform: rotate(-90deg);
    }
  }
}
.popper {
  padding: 30px 20px;
  width: 300px;
  box-shadow: var(--slightly-stronger-shadow);
  > a + a {
    margin-top: 20px;
  }
}
.prompt-content {
  text-align: center;
  .title {
    @include headline;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .disclaimer {
    @include disclaimer-container;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .text.bold {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
    .prompt-illustration {
      width: 170px;
      margin: 30px;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > * + * {
      margin-top: 20px;
    }
  }
}
</style>
