<template>
  <div class="container">
    <div class="illustration">
      <img src="@/assets/illustrations/ashamed-man.png" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="body">
      <an-input v-model="teamName" placeholder="Name your new team" @keyup.enter="create" focus />
    </div>
    <div class="actions" v-if="teamName">
      <an-button @click="create" :isWorking="isWorking">Create team</an-button>
      <an-link @click="$emit('close')">Cancel</an-link>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/bus';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'new-team',
  data() {
    return {
      title: 'Create a new team',
      teamName: '',
      isWorking: false
    };
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ctaData() {
      return {
        label: 'Create',
        loading: this.isWorking
      };
    }
  },
  methods: {
    ...mapActions({
      createTeam: 'teams/create',
      reloadUserTeams: 'teamMemberships/fetchAllUserMemberships',
      fetchMemberships: 'teamMemberships/fetchAllTeamMemberships'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    async create() {
      const parent = 'users';
      const id = 'me';
      const payload = { name: this.teamName };

      if (!this.teamName) {
        return console.error('Name is mandatory!');
      }

      this.$trackEvent('new-team.save-button.click');

      try {
        this.isWorking = true;
        const team = await this.createTeam({ parent, id, payload });
        this.setCurrentTeam(team);
        await Promise.all([
          this.reloadUserTeams({ parent, id, skipCache: true }),
          this.fetchMemberships({ parent: 'team', id: team.slug, params: { is_slug: true } })
        ]);
        this.$trackEvent('new-team.save.success');
        this.$router.push({ name: 'team-members', params: { teamSlug: team.slug } });
      } catch (err) {
        this.$trackEvent('new-team.save.failure', { message: err.message });
        toastError('Failed creating new team');
      } finally {
        this.isWorking = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.body {
  width: 400px;
}
</style>
