<template>
  <div class="view slideInRight">
    <div class="title-container">
      <div style="cursor:pointer; display: flex; align-items: center;" @click="backToComments">
        <svg-icon style="color:white;margin-right:5px;" fill="currentColor" name="caret-left" :size="20"></svg-icon>
        <div class="title">Back</div>
      </div>
      <div v-if="currentComment.status == 'OPEN'" @click="$emit('resolve', currentComment)" class="resolve">
        Resolve
      </div>
      <!-- <div style="margin-left:auto;color:white" v-else>Resolved</div> -->
    </div>
    <div class="body-wrapper">
      <virtual-list class="vv-list" wclass="v-list" :size="132" :remain="getRemain" :bench="0">
        <div class="comments-list">
          <Comment
            :style="{ background: '#333333' }"
            @openComments="
              $emit('change', {
                view: 'comments'
              })
            "
            isParent
            isReply
            :comment="currentComment"
          />
          <Comment isReply v-for="comment in currentCommentReplies" :key="comment.id" :comment="comment" />
        </div>
      </virtual-list>
    </div>
    <!-- REPLY COMMENT INPUT -->
    <div class="reply-comment-container">
      <div class="form-container">
        <an-textarea
          @enter="handleCommentReply"
          v-model="replyCommentText"
          autoFocus
          :className="'comment-textarea'"
          rows="1"
          placeholder="Add your reply"
        />
        <div :class="{ active: isInputActive }" @click="handleCommentReply" class="icon-container">
          <svg-icon class="icon" name="send" :size="23" fill="currentColor"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from '@/utils/uuid';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import Comment from '@/components/OmniView/Comments/Comment.vue';

import { SEND_MESSAGE, RESET_COMMENTS } from '@/utils/events/omniviewEvents';
import { EventBus } from '@/services/bus';
import VirtualList from 'vue-virtual-scroll-list';

export default {
  name: 'replies',
  components: {
    Comment,
    VirtualList
  },
  data() {
    return {
      replyCommentText: '',
      isWorking: false
    };
  },

  computed: {
    ...mapState('users', { currentUser: 'currentItem' }),
    ...mapState('comments', { comments: 'items' }),
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentComment: 'comments/currentComment',
      replies: 'comments/replies'
    }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapState('projects', { currentProject: 'currentItem' }),
    isInputActive() {
      return this.replyCommentText != '';
    },
    currentCommentReplies() {
      return this.replies.filter(r => r.reply_to == this.currentComment.id);
    },
    getRemain() {
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      return Math.floor((h - 150 - (this.selected ? 60 : 0)) / 132);
    }
  },
  methods: {
    ...mapMutations({
      setCurrentNode: 'omniview/setCurrentNode',
      setComments: 'comments/setComments'
    }),
    ...mapActions({
      fetchProjectComments: 'comments/fetchComments',
      createComment: 'comments/create'
    }),

    backToComments() {
      // this.clearSelection();
      this.$emit('change', {
        view: 'comments'
      });
    },

    clearSelection() {
      this.setCurrentNode({});
      EventBus.$emit(SEND_MESSAGE, {
        action: RESET_COMMENTS
      });
    },
    async handleCommentReply() {
      const { projectId } = this.$route.params;

      if (!this.replyCommentText) return;
      if (this.isWorking) return;
      try {
        const text = this.replyCommentText;
        if (!text) return;
        this.isWorking = true;
        // optimistic create
        const fakeReplyId = uuid();
        const created_at = new Date();
        created_at.setHours(created_at.getHours() - 1);
        const fakeReply = {
          id: fakeReplyId,
          node_id: this.currentNode.id,
          text,
          user: this.currentUser,
          reply_to: this.currentComment.id,
          created_at
        };
        this.setComments([fakeReply, ...this.comments]);

        this.replyCommentText = '';

        const payload = {
          text,
          node_id: this.currentNode.id,
          component: {
            type: 'components',
            id: this.currentComponent.id
          },
          reply_to: {
            type: 'comments',
            id: this.currentComment.id
          }
        };
        // send create comment request
        await this.createComment({
          parent: 'projects',
          id: projectId,
          payload: payload
        });
        const { results: newComments } = await this.fetchProjectComments({
          cachePolicy: 'no-cache',
          id: projectId,
          params: {
            page_size: 100
          }
        });
        this.setComments(newComments);

        this.isWorking = false;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../View.scss';

.title-container {
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0 20px;
  align-items: center;
  background-color: #3b3b3b;
  border-bottom: 1px solid #2d2d2d;

  z-index: 10;
  .title {
    font-size: 16px;
    color: white;
  }
}

.body-wrapper {
  flex: 1 1 auto;
  padding-top: 60px;
  padding-bottom: 120px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.comments-list {
  width: 100%;
  height: 100%;
}

.reply-comment-container {
  min-height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  border-top: 1px solid #2d2d2d;

  .form-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 16px;
    color: white;
    transition: background 0.2s;

    .icon {
      opacity: 0.3;
      left: -2px;
      position: relative;
      color: white;
    }

    &.active {
      cursor: pointer;
      background: var(--primary);
      color: white;
      .icon {
        opacity: 1;
      }
    }

    &:hover {
    }
  }
}

.resolve {
  margin-left: auto;
  color: white;
  &:hover {
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
