const MAX_SKETCH_VERSION = 1000;

export function numericSketchVersion(verStr) {
  const parts = verStr.split('.').map(parseFloat);
  let final = 0;
  for (let i = 0; i < parts.length; i++) {
    final += parts[i] / MAX_SKETCH_VERSION ** i;
  }
  return final;
}

export default class SketchFile {
  constructor(sketchFileZip /* : JSZip.JSZipObject */) {
    this._zip = sketchFileZip;
  }

  async process() {
    const metaRaw = await this._zip.files['meta.json'].async('string');
    this._meta = JSON.parse(metaRaw);
    this.version = numericSketchVersion(this._meta.appVersion);
  }

  getTotalArtboardsNumber() {
    const pagesRefs = this._meta.pagesAndArtboards;
    let count = 0;
    for (let page of Object.values(pagesRefs)) {
      count += (Object.keys(page.artboards) || []).length;
    }
    return count;
  }

  getPages() {
    const pagesRefs = this._meta.pagesAndArtboards;

    let pages = {};
    for (let pageId in pagesRefs) {
      pages[pageId] = {
        name: pagesRefs[pageId].name,
        id: pageId
      };
    }

    return pages;
  }

  getArtboards(pageId) {
    const rawArtboards = this._meta.pagesAndArtboards[pageId].artboards;
    let artboards = {};
    for (let artboardId in rawArtboards) {
      artboards[artboardId] = {
        name: rawArtboards[artboardId].name,
        id: artboardId
      };
    }
    return artboards;
  }
}
