var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "block centered" }, [
    _c("div", { staticClass: "block-title" }, [
      _c("span", [_vm._v("HTML Tag")]),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-left": "auto" } },
        [
          _c("GhostInput", {
            ref: "tagNameRef",
            staticClass: "editableInput tag",
            attrs: {
              disabled: _vm.nodeOverrides["is_image"] || _vm.isNativeImage,
              undo: !_vm.isNativeImage && !_vm.nodeOverrides["is_image"],
              permaUndo: !_vm.isNativeImage && !_vm.nodeOverrides["is_image"]
            },
            on: {
              undo: _vm.undoTagNameChange,
              focus: function($event) {
                _vm.isTagInputFocused = true
              },
              blur: _vm.handleTagBlur,
              input: function($event) {
                _vm.isTagInputFocused = true
              },
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleTagKeydownEnter.apply(null, arguments)
                },
                _vm.handleTagKeydown
              ]
            },
            model: {
              value: _vm.newTagName,
              callback: function($$v) {
                _vm.newTagName = $$v
              },
              expression: "newTagName"
            }
          }),
          _vm.isTagInputFocused
            ? _c(
                "div",
                { staticClass: "autocomplete tag" },
                _vm._l(_vm.tagNameAutocomplete, function(tag, index) {
                  return _c(
                    "div",
                    {
                      key: tag,
                      staticClass: "item",
                      class: { "selected-tag": index == _vm.selectedTagIndex },
                      on: {
                        mousedown: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.handleTagNameAutocompleteChange(tag)
                        },
                        mouseenter: function($event) {
                          _vm.selectedTagIndex = index
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(tag) + " ")]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }