var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltip-container" },
    [
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.isOpen && !_vm.isMobile
          ? _c("div", {
              staticClass: "tooltip",
              style: _vm.tooltipStyle,
              domProps: { innerHTML: _vm._s(_vm.html) }
            })
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "tooltip-trigger",
          attrs: { tabindex: "-1" },
          on: {
            mouseover: function($event) {
              _vm.hover = true
            },
            mouseleave: _vm.close,
            focusout: _vm.close,
            click: _vm.onClick
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }