var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-row-container" }, [
    _c("div", { staticClass: "thumbnail" }, [_vm._t("thumbnail")], 2),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "text" }, [
        _c("div", { staticClass: "file" }, [
          _c("div", { staticClass: "file-name" }, [
            _vm._v(_vm._s(_vm.fileNameDisplay))
          ]),
          _c("div", { staticClass: "file-extension" }, [
            _vm._v(_vm._s(_vm.fileExtension))
          ])
        ]),
        _vm.uploadDate
          ? _c("div", { staticClass: "upload-date" }, [
              _vm._v("Uploaded on " + _vm._s(_vm.date))
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        [
          _vm.actions && _vm.actions.length
            ? _c(
                "PopoverMenu",
                { attrs: { items: _vm.popoverActions, position: "right" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clickable-icon actions",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "three-dots-horizontal", size: 30 }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }