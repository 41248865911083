var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      style: {
        width: _vm.isSidebarVisible
          ? _vm.isSidebarMinimized
            ? "40px"
            : "var(--omniview-sidebar-width)"
          : 0
      }
    },
    [
      _vm.activeMode.name == "C"
        ? _c(
            "div",
            {
              staticClass: "sidebar-toggle",
              class: { open: !_vm.isSidebarMinimized },
              on: { click: _vm.handleToggleSidebar }
            },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: {
                  name: !_vm.isSidebarMinimized ? "arrow-right" : "arrow-left",
                  size: 25,
                  fill: "currentColor"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "transition",
            { attrs: { name: "fadeIn" } },
            [
              _vm.showContent
                ? _c(_vm.currentView, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSidebarMinimized,
                        expression: "!isSidebarMinimized"
                      }
                    ],
                    tag: "component"
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }