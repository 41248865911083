<template>
  <transition :name="transitionName">
    <Modal
      v-if="isOpen"
      :variant="variant"
      :width="width"
      :mode="mode"
      :opacity="opacity"
      :whiteOverlay="whiteOverlay"
      :background="background"
      :component="componentName"
      :componentProps="componentProps"
      :closeButton="closeButton"
      @close="closeModal"
      @updateParams="handleUpdate"
    />
  </transition>
</template>

<script>
import { isEmpty } from 'lodash-es';
import Modal from '@/components/Modal/Modal.vue';
import { EventBus } from '@/services/bus';

export default {
  components: {
    Modal
  },
  data() {
    return {
      componentName: null,
      variant: 'full-screen',
      mode: 'light',
      opacity: 0.8,
      width: 600,
      background: '',
      redirect: null,
      componentProps: null,
      closeButton: false,
      forceHideCloseButton: false,
      isOpen: false,
      onClose: null
    };
  },
  computed: {
    transitionName() {
      return this.variant === 'full-screen' ? 'modal' : 'fade';
    }
  },
  mounted() {
    EventBus.$on('open-modal', this.openModal);
  },
  methods: {
    openModal({
      name,
      variant = 'full-screen',
      onCloseRedirect = {},
      onClose = null,
      props = {},
      mode = 'light',
      whiteOverlay = false,
      closeButton = false,
      opacity = 0.8,
      width = 600,
      background = '',
      fsHideCloseButton = false
    } = {}) {
      this.componentName = name;
      this.variant = variant;
      if (!this.redirect) {
        this.redirect = onCloseRedirect;
      }
      this.componentProps = props;
      this.mode = mode;
      this.background = background;
      this.whiteOverlay = whiteOverlay;
      this.opacity = opacity;
      this.width = width;
      this.onClose = onClose;
      if (variant === 'full-screen') {
        this.closeButton = !fsHideCloseButton;
      } else {
        this.closeButton = closeButton;
      }
      this.isOpen = true;
    },
    closeModal({ redirect, afterClosing } = {}) {
      const route = redirect || this.redirect;
      if (this.onClose) this.onClose();
      if (!isEmpty(route)) this.$router.push(route, () => {});
      this.isOpen = false;
      this.redirect = null;
      this.componentProps = null;
      this.closeButton = false;
      this.onClose = null;
      EventBus.$emit('close-modal', { name: this.componentName });
      if (afterClosing) afterClosing();
    },
    handleUpdate(obj) {
      Object.keys(obj).forEach(key => {
        this[key] = obj[key];
      });
    }
  }
};
</script>
