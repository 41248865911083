var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      style: {
        width: _vm.isSidebarVisible
          ? _vm.isComponentsSidebarMinimized
            ? "40px"
            : "300px"
          : 0
      }
    },
    [
      _vm.activeMode.name == "C"
        ? _c(
            "div",
            {
              staticClass: "sidebar-toggle",
              class: { open: !_vm.isComponentsSidebarMinimized },
              on: { click: _vm.handleToggleSidebar }
            },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: {
                  name: !_vm.isComponentsSidebarMinimized
                    ? "arrow-left"
                    : "arrow-right",
                  size: 25,
                  fill: "currentColor"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isComponentsSidebarMinimized && _vm.showContent,
                expression: "!isComponentsSidebarMinimized && showContent"
              }
            ],
            staticClass: "c"
          },
          [
            _c("div", { staticClass: "header" }, [
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "flex items-center" }, [
                  _vm.stack.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "icon-c",
                          staticStyle: { "margin-right": "10px" },
                          on: { click: _vm.back }
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { color: "white" },
                            attrs: {
                              fill: "currentColor",
                              name: "arrow-left",
                              size: 24
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" Components ")
                ])
              ])
            ]),
            _c(
              "ul",
              {
                key: _vm.stack.length,
                staticClass: "flex flex-col w-full c-list",
                class: _vm.animation,
                style: _vm.getHeight
              },
              _vm._l(_vm.components, function(w) {
                return _c(
                  "li",
                  {
                    key: w.id,
                    staticClass: "component-item",
                    class: { active: _vm.isActive(w) },
                    on: {
                      click: function($event) {
                        return _vm.handleComponentCardClicked(w)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center",
                        staticStyle: { flex: "1", width: "260px" }
                      },
                      [
                        _c("WebComponentThumb", {
                          attrs: {
                            webComponent: w,
                            maxWidth: 260,
                            maxHeight: 46
                          }
                        }),
                        _c("div", { staticClass: "text flex flex-col" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(" " + _vm._s(w.name) + " ")
                          ])
                        ]),
                        w.subsIds.length > 0
                          ? _c("svg-icon", {
                              directives: [
                                {
                                  name: "tip",
                                  rawName: "v-tip",
                                  value: "Contains multiple components",
                                  expression: "'Contains multiple components'"
                                }
                              ],
                              staticStyle: {
                                color: "white",
                                "margin-left": "auto",
                                "flex-shrink": "0"
                              },
                              attrs: {
                                fill: "currentColor",
                                name: "stack",
                                size: 24
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }