<template>
  <transition :name="`slide-in-${side}`">
    <div :class="['arrow-container', side]" @click="onClickArrow" v-if="show">
      <svg-icon :name="`arrow-${side}`" :size="36" fill="white" />
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/services/bus';
import { mapGetters, mapMutations, mapState } from 'vuex';
export default {
  props: {
    side: {
      type: String,
      default: 'right',
      validator: v => ['right', 'left'].includes(v)
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('components', { allCurrentComponents: 'items' }),
    ...mapState('components', { currentComponent: 'currentItem' }),
    ...mapGetters({
      slugsMap: 'omniview/slugsMap'
    }),
    nextComponent() {
      const { currentComponent, allCurrentComponents, side } = this;
      const { screenSlug, releaseShortId } = this.$route.params;
      const slug = screenSlug || currentComponent.slug;
      let i = releaseShortId
        ? allCurrentComponents.findIndex(c => this.slugsMap[c.variableID] === slug)
        : allCurrentComponents.findIndex(c => c.slug === slug);

      if (side === 'right') {
        i = (i + 1) % allCurrentComponents.length;
      } else {
        if (i === 0) {
          i = allCurrentComponents.length;
        }
        i = i - 1;
      }
      return allCurrentComponents[i];
    }
  },
  methods: {
    ...mapMutations({ selectScreen: 'components/setCurrentItem' }),
    onClickArrow() {
      // @Ben use this event it takes care of everything
      EventBus.$emit('handleScreenChange', { component: this.nextComponent, fetchBreakpoints: true });
    }
  }
};
</script>

<style lang="scss" scoped>
.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: var(--secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  top: calc(50% - 30px);
  &.right {
    right: -40px;
    &:hover {
      right: -15px;
    }
  }
  &.left {
    left: -40px;
    &:hover {
      left: -15px;
    }
  }
}

@keyframes slide-in-right {
  0% {
    right: -60px;
  }
  50% {
    right: -15px;
  }
}
@keyframes slide-in-left {
  0% {
    left: -60px;
  }
  50% {
    left: -15px;
  }
}

//enter animations
.slide-in-right-enter-active {
  animation: slide-in-right 0.5s linear;
}
.slide-in-left-enter-active {
  animation: slide-in-left 0.5s linear;
}

// leave animations
.slide-in-right-leave-active,
.slide-in-left-leave-active {
  transition: all 0.2s ease;
}
.slide-in-right-leave-to {
  transform: translateX(60px);
}
.slide-in-left-leave-to {
  transform: translateX(-60px);
}
</style>
