var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dev-console" }, [
    _c(
      "div",
      {
        ref: "closeButton",
        staticClass: "clickable-icon",
        on: { click: _vm.onClose }
      },
      [
        _c("svg-icon", { attrs: { name: "close", fill: "'var(--secondary)'" } })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dev-console-content" },
      [
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("div", { staticClass: "item-label" }, [_vm._v("API URL")]),
            _c("an-input", {
              model: {
                value: _vm.apiURL,
                callback: function($$v) {
                  _vm.apiURL = $$v
                },
                expression: "apiURL"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item" },
          [
            _c("div", { staticClass: "item-label" }, [_vm._v("Codegen URL")]),
            _c("an-input", {
              model: {
                value: _vm.codegenURL,
                callback: function($$v) {
                  _vm.codegenURL = $$v
                },
                expression: "codegenURL"
              }
            })
          ],
          1
        ),
        _c(
          "an-button",
          { staticStyle: { float: "right" }, on: { click: _vm.onSave } },
          [_vm._v("Save")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }