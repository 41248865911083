var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex items-center",
      class: { focus: _vm.focus },
      style: { cursor: _vm.disabled ? "not-allowed" : "text" }
    },
    [
      _c(
        "div",
        _vm._g(
          _vm._b(
            {
              ref: "editable",
              staticClass: "editable",
              class: { ellipsis: !_vm.focus, undo: _vm.undo },
              style: _vm.getStyles,
              attrs: {
                contenteditable: "",
                spellcheck: "false",
                "data-placeholder": _vm.$attrs.placeholder,
                "data-cy": "ghost-input"
              }
            },
            "div",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        )
      ),
      _c(
        "div",
        {
          staticClass: "flex items-center justify-center",
          staticStyle: { "padding-left": "10px" },
          style: {
            display: (_vm.undo && _vm.focus) || _vm.permaUndo ? "flex" : "none"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex items-center justify-center undo-icon",
              on: {
                mousedown: function($event) {
                  return _vm.$emit("undo")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { fill: "currentColor", name: "restore", size: 16 }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }