var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "$rootRef", class: ["scrollOverlay", _vm.variant] }, [
    _c(
      "div",
      {
        class: [
          "clickableOverlay",
          _vm.variant,
          { noTopPadding: _vm.whiteOverlay }
        ],
        style: {
          background: _vm.whiteOverlay
            ? "rgba(255, 255, 255," + _vm.opacity + ")"
            : "rgba(0, 0, 0," + _vm.opacity + ")"
        },
        on: {
          click: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.close.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          {
            ref: "$modalRef",
            class: [
              "modal",
              _vm.variant,
              _vm.mode,
              { background: _vm.background }
            ],
            style: { "--width": _vm.width + "px", "--bg": _vm.background }
          },
          [
            _vm.closeButton
              ? _c(
                  "div",
                  {
                    class: ["close-button", _vm.variant],
                    on: { click: _vm.close }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "close",
                        size: _vm.closeButtonSize,
                        fill: _vm.closeButtonColor
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.component
              ? _c(
                  _vm.component,
                  _vm._b(
                    {
                      tag: "component",
                      on: {
                        updateParams: function($event) {
                          return _vm.$emit("updateParams", $event)
                        },
                        close: _vm.close
                      }
                    },
                    "component",
                    _vm.componentProps,
                    false
                  )
                )
              : _vm._t("default")
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }