var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "paywall-container", attrs: { id: "panel-paywall" } },
          [
            _c(
              "div",
              {
                ref: "closeButton",
                staticClass: "close-button clickable-icon",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.close.apply(null, arguments)
                  }
                }
              },
              [
                _c("svg-icon", {
                  attrs: { name: "close-white", size: _vm.closeButtonSize }
                })
              ],
              1
            ),
            _c("div", { staticClass: "illustration" }, [
              _c("img", {
                attrs: { src: require("@/assets/illustrations/rocket-pro.svg") }
              })
            ]),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Unlock Pro features")
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    " Upgrade your plan to download code or access the Anima code components in HTML, React, or Vue. "
                  )
                ]),
                _c(
                  "an-button",
                  { attrs: { variant: "primary" }, on: { click: _vm.upgrade } },
                  [_vm._v("Upgrade")]
                )
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }