export const findNodeWorker = (m, n) => {
  const f = (id, currentNode) => {
    let i, currentChild, result;
    if (id == currentNode.modelID) {
      return currentNode;
    } else {
      for (i = 0; i < (currentNode.subviews || []).length; i++) {
        currentChild = currentNode.subviews[i];
        result = f(id, currentChild);
        if (result !== false) {
          return result;
        }
      }
      return false;
    }
  };
  let found = false;
  for (let index = 0; index < m.screens.length; index++) {
    let s = m.screens[index];
    found = f(n, s);
    if (found) {
      return found;
    }
  }
  return found;
};

export const multipleSelectWorker = (node, nodes) => {
  const findNode = (id, currentNode) => {
    let i, currentChild, result;
    if (id == currentNode.modelID) {
      return currentNode;
    } else {
      for (i = 0; i < currentNode.subviews.length; i++) {
        currentChild = currentNode.subviews[i];
        result = findNode(id, currentChild);
        if (result !== false) {
          return result;
        }
      }
      return false;
    }
  };
  const findAndRemove = (root, nodeWhiteList) => {
    let shouldRemove;

    if (!nodeWhiteList.includes(root.nodeID)) {
      shouldRemove = true;
    }

    for (let i = 0; i < root.subviews.length; i++) {
      const node = root.subviews[i];

      if (nodeWhiteList.some(id => findNode(id, node))) {
        shouldRemove = false;
      }

      if (shouldRemove) {
        root.subviews[i] = Object.assign(root.subviews[i], {
          subviews: []
        });
      } else {
        findAndRemove(node, nodeWhiteList);
      }
    }
  };
  const removeLeafs = (root, nodeWhiteList) => {
    let i = 0;
    restartLoop: while (i < root.subviews.length) {
      let node = root.subviews[i];
      if (!nodeWhiteList.includes(node.modelID) && node.subviews.length == 0) {
        root.subviews.splice(i, 1);
        i = 0;
        continue restartLoop;
      }
      removeLeafs(node, nodeWhiteList);
      i++;
    }
  };
  const clone = JSON.parse(JSON.stringify(node));
  findAndRemove(clone, nodes);
  removeLeafs(clone, nodes);
  return clone;
};

export const generateModelNodesMapWorker = ({
  model = { subviews: [] },
  webInstancesMap = {},
  components = [],
  metadata = { overrides: {} },
  codegenLang = 'html'
} = {}) => {
  const generateModelNodeMap = (
    map,
    {
      modelNode = { subviews: [] },
      webInstancesMap = {},
      components = [],
      metadata = { overrides: {} },
      codegenLang = 'html'
    } = {}
  ) => {
    for (let i = 0; i < (modelNode.subviews || []).length; i++) {
      let child = modelNode.subviews[i];

      const nodeOverrides = (metadata.overrides || {})[child.modelID] || {};
      let is_component = !!webInstancesMap[child.modelID];
      let { is_suggestion, component_name: masterComponentName, master } = isSuggestionById(child.modelID, components);
      let is_web_component = is_component && !is_suggestion && master['is_live'];
      let component_name = nodeOverrides['viewName'] != undefined ? nodeOverrides['viewName'] : child['viewName'];
      if (is_web_component || is_suggestion) {
        component_name = masterComponentName;
      }
      if (codegenLang == 'html') {
        is_component = is_web_component = is_suggestion = false;
      }
      map[child.modelID] = {
        viewName: component_name,
        is_component: is_component && (is_web_component || is_suggestion),
        is_web_component: is_web_component,
        is_suggestion: is_suggestion,
        is_image: child.is_image || false,
        modelID: child.modelID,
        model_class: child.model_class,
        width: child.width,
        height: child.height
      };

      generateModelNodeMap(map, { modelNode: child, components, metadata, webInstancesMap, codegenLang });
    }
  };

  const isSuggestionById = (nodeId, components) => {
    let { master } = getMasterAndInstanceByNodeId(nodeId, components);

    let component_name = '';
    let is_suggestion = master ? !master['is_live'] && master['is_suggestion'] : false;

    master && (component_name = master.name);

    return {
      component_name,
      is_suggestion,
      master
    };
  };

  const getMasterAndInstanceByNodeId = (nodeId, components) => {
    let foundMaster = false;
    let foundInstance = false;
    let foundPrimaryInstance = false;
    for (let i = 0; i < components.length; i++) {
      const wc = components[i];
      let wci = wc.instances || [];

      let instance = wci.find(instance => instance.model_id == nodeId);
      if (instance) {
        foundMaster = components[i];
        foundInstance = instance;
        break;
      }
    }

    if (foundMaster) {
      foundPrimaryInstance = foundMaster.instances.find(i => i.is_primary);
    }

    return { master: foundMaster, instance: foundInstance, primaryInstance: foundPrimaryInstance };
  };

  let modelNodesMap = {};

  let is_component = !!webInstancesMap[model.modelID];
  let { is_suggestion, master } = isSuggestionById(model.modelID, components);
  let is_web_component = is_component && !is_suggestion && master['is_live'];

  if (codegenLang == 'html') {
    is_component = is_web_component = is_suggestion = false;
  }

  modelNodesMap[model.modelID] = {
    modelID: model.modelID,
    model_class: model.model_class,
    is_component: is_component && (is_web_component || is_suggestion),
    is_web_component,
    viewName: model.viewName,
    is_image: model.is_image || false,
    width: model.width,
    height: model.height,
    is_root_screen: true
  };

  generateModelNodeMap(modelNodesMap, { modelNode: model, webInstancesMap, components, metadata, codegenLang });
  return modelNodesMap;
};
