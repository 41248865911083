var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notifications-popover-container",
      attrs: { tabindex: "-1" },
      on: { focusout: _vm.close }
    },
    [
      _c(
        "div",
        { ref: "icon", staticClass: "icon", on: { click: _vm.toggleOpen } },
        [_c("svg-icon", { attrs: { name: _vm.iconName, size: 30 } })],
        1
      ),
      _c(
        "Popover",
        {
          style: _vm.popoverStyles,
          attrs: { isOpen: _vm.isOpen },
          on: { close: _vm.close }
        },
        [
          _c(
            "div",
            { staticClass: "notifications-header" },
            [
              _c("span", [_vm._v("Notifications")]),
              _vm.hasNewNotifications
                ? _c(
                    "an-link",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.onMarkAllRead }
                    },
                    [_vm._v("Mark all as read")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "notifications-body" },
            [
              !_vm.notifications.length
                ? _c("EmptyState")
                : _vm._l(_vm.filteredNotifications, function(item) {
                    return _c("Notification", {
                      key: item.id,
                      attrs: { item: item },
                      on: {
                        click: _vm.onClick,
                        "click-project": _vm.onClickProject,
                        "mark-read": _vm.onMarkRead
                      }
                    })
                  })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }