c<template>
  <div
    :style="{
      '--height': inputSizes[size] + 'px',
      '--iconContainerWidth': iconSize * 2 + 5 + 'px'
    }"
    class="inputContainer"
  >
    <div v-if="icon" class="inputIconContainer">
      <svg-icon :size="iconSize" :name="icon" />
    </div>
    <input
      ref="input"
      :class="['input', { invalid, hasIcon: icon, noBorder, [theme]: true }]"
      v-bind="$attrs"
      :type="inputType"
      :value="value"
      v-on="listeners"
    />
    <div ref="passIcon" v-if="type === 'password'" :class="passwordIconClasses" @click="toggleShowPassword">
      <svg-icon :size="24" name="eye" />
    </div>
  </div>
</template>

<script>
import { colorPathChildren } from '@/utils/colors';
export default {
  name: 'an-input',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'xl',
      validator: value => {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
      }
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 16
    },
    invalid: {
      type: Boolean,
      default: false
    },
    filter: {
      default: undefined
    },
    type: {
      type: String,
      default: 'text',
      validator: value => {
        return ['email', 'text', 'number', 'password'].indexOf(value) !== -1;
      }
    },
    focus: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['dark', 'light'].includes(value)
    }
  },
  data() {
    return {
      inputSizes: {
        sm: 28,
        md: 36,
        lg: 40,
        xl: 48
      },
      showPassword: false
    };
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
    if (this.theme === 'dark') {
      colorPathChildren(this.$refs.passIcon, 'var(--white)');
    }
  },
  computed: {
    inputType() {
      if (this.type === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.type;
    },
    passwordIconClasses() {
      return {
        'password-icon': true,
        active: this.showPassword
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.handleInput,
        blur: this.handleBlur,
        keyup: this.handleKeyup
      };
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
    handleBlur(e) {
      this.$emit('blur', e.target.value);
    },
    handleKeyup(e) {
      this.$emit('keyup', e);
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>

<style lang="scss" scoped>
.inputContainer {
  position: relative;
  display: inline-block;
  height: var(--height);
  width: 100%;
}
.input {
  height: 100%;
  width: 100%;
  padding: 12px 22px;
  border-radius: 10px;
  outline: none;
  border: var(--border);
  caret-color: var(--primary);
  transition: border-color 1s ease;
  background: transparent;
  &:focus {
    border: 1px solid var(--secondary);
    outline: 0;
  }
  &::placeholder {
    color: var(--secondary-text);
  }
  &.dark {
    color: var(--white);
    &::placeholder {
      color: var(--dark-secondary-text);
    }
  }
  &.noBorder {
    border: none;
    padding: 0;
  }
  &.dark {
    border-color: var(--dark-border-color);
    &:focus {
      border-color: var(--white);
      outline: 0;
    }
  }
}
.invalid {
  &,
  &.dark,
  &:focus {
    border: 1px solid var(--red);
    box-shadow: none;
  }
}
.hasIcon {
  padding-left: var(--iconContainerWidth);
}
.inputIconContainer,
.password-icon {
  position: absolute;
  width: var(--iconContainerWidth);
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-icon {
  right: 20px;
  left: auto;
  width: auto;
  cursor: pointer;
  opacity: 0.4;
  &.active {
    opacity: 1;
  }
}
</style>
