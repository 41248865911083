import axios from 'axios';
import promiseRetry from 'promise-retry';
import auth from '@/auth';
import cache from 'memory-cache';
import { readCookie } from '@/utils/cookie';
import { isDev } from '@/utils/environment';

const mapAxiosErr = error => error?.response || error?.request || error;

const init = () => {
  axios.defaults.baseURL = localStorage.getItem('apiBaseURL') || process.env.API_BASE_URL || 'https://api.animaapp.com';
  axios.interceptors.request.use(
    config => {
      const token = auth.getToken();
      config.headers['X-Client-Id'] = 'com.animaapp.web';
      token && (config.headers['Authorization'] = `JWT ${token}`);
      const visitorId = readCookie('ana_visitorId');
      config.headers['X-Visitor-Id'] = visitorId ? visitorId : 'N/A';
      const experiment = readCookie(process.env.EXPERIMENTS_COOKIE_NAME);
      config.headers['X-Codegen-URL'] = localStorage.getItem('codegenBaseURL');
      experiment && (config.headers['X-Experiment'] = experiment);
      if (config.url.startsWith(`/v2`) && !isDev()) {
        config.baseURL = process.env.APP_BASE_URL;
      }
      return config;
    },
    err => {
      console.error(err);
    }
  );
  // TODO: refactor response interceptor

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log(error);
      if (error.response && error.response.headers && error.response.headers['X-EXCHANGE-TOKEN']) {
        localStorage.token = localStorage.setItem('token', error.response.headers['X-EXCHANGE-TOKEN']);
      }
      if (error.response && error.response.status == 401) {
        const next = window.location.hash.replace('#/', '/');
        const isAuth = error.config.url.endsWith('/auth');
        if (!isAuth && !next.startsWith('/login') && !window.location.href.startsWith('/login')) {
          if (!next.startsWith('/signup?pairing=true') && !next.startsWith('/signup?pairing=true')) {
            window.rerouting = true;
            auth.logout();
            window.location.href = '/login?redirect=' + encodeURIComponent(next);
            window.location.reload();
          }
        }
        return Promise.reject(mapAxiosErr(error));
      } else {
        return Promise.reject(mapAxiosErr(error));
      }
    }
  );
};

export const fetchApiWithCache = async (
  url,
  { params = {}, cachePolicy = 'cache-first', retry = false } = {},
  fetchApi = false
) => {
  try {
    const key = `${url}${JSON.stringify(params)}`;
    const isCacheAvailable = cache.get(key);
    const canUseCache = isCacheAvailable && cachePolicy !== 'no-cache';

    if (canUseCache && cachePolicy === 'cache-only') {
      return Promise.resolve(cache.get(key).data);
    }

    if (canUseCache) {
      return Promise.resolve(cache.get(key).data);
    } else {
      let data;
      if (!fetchApi) {
        data = await axios.get(url, { params });
      } else {
        if (retry) {
          data = await promiseRetry(
            function(doRetry, number) {
              console.log('attempt number', number);

              return fetch(url, params)
                .then(response => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    throw new Error('Something went wrong,fetch failed');
                  }
                })
                .catch(doRetry);
            },
            { retries: 3 }
          );
        } else {
          data = await fetch(url, params).then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Something went wrong,fetch failed');
            }
          });
        }

        if (!data) return Promise.reject('fetch failed');
      }
      if (data) {
        cache.put(key, { data, params });
      }

      return Promise.resolve(data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const api = {
  init,
  list: async (path, { params = {}, cachePolicy = 'cache-first' } = {}) => {
    try {
      const data = await fetchApiWithCache(path, { params, cachePolicy });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  get: async (path, objectId, { params = {}, cachePolicy = 'cache-first' } = {}) => {
    const url = objectId ? `${path}/${objectId}` : path;

    try {
      const data = await fetchApiWithCache(url, { params, cachePolicy });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  post: (path, data, config = {}) => axios.post(path, data, config),
  put: (path, objectId, data, config = {}) => axios.put(`${path}/${objectId}`, data, config),
  delete: (path, objectId) => axios.delete(`${path}/${objectId}`),
  clearCacheOf: key => {
    if (!key) return;
    // remove cache entries that have `key` in them.
    const keys = cache.keys().filter(k => k.includes(key));
    keys.forEach(k => cache.del(k));
  }
};

export default api;
