<template>
  <div class="pairing-container">
    <div class="container">
      <LoadingScreen v-if="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';

export default {
  data() {
    return {
      email: '',
      loading: false
    };
  },
  components: {
    LoadingScreen
  },
  mounted() {
    this.onMounted();
  },
  methods: {
    ...mapActions({
      generateToken: 'figmaIntegration/createToken'
    }),
    async onMounted() {
      this.loading = true;
      this.grantFigmaAccess();
    },
    async grantFigmaAccess() {
      const params = {
        state: 'ACTIVE',
        scope: 'file_read',
        response_type: 'code',
        redirect_uri: `${process.env.APP_BASE_URL}/figma/callback`,
        client_id: process.env.FIGMA_CLIENT_ID
      };
      const urlParams = Object.keys(params)
        .map(param => `${param}=${params[param]}`)
        .join('&');
      const { callbackUrl } = this.$route.query;
      if (callbackUrl) {
        localStorage.setItem('figmaCallbackUrl', callbackUrl);
      }
      window.open(`https://www.figma.com/oauth?${urlParams}`, '_self');
    }
  },
  watch: {
    '$route.query'() {
      this.onMounted();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_forms.scss';
.pairing-container {
  height: 100vh;
  width: 100%;
}
.container {
  max-width: 100%;
}
</style>
