import { get, isEmpty } from 'lodash-es';
import api from '@/api';
import { getStripeToken } from '@/services/payment/stripe';
import { fixPlanName } from '@/utils/billing';
import { isDev } from '@/utils/environment';

const PLAN_IDS = {
  stripe: {
    prototype: {
      monthly: 'anima-prototype-monthly-29',
      annually: 'anima-prototype-annually-25-1'
    },
    pro: {
      monthly: 'anima-monthly-39',
      annually: 'anima-pro-annually-31'
    },
    team: {
      monthly: 'anima-team-monthly-32',
      annually: 'anima-team-annually-26'
    }
  },
  paypal: {
    prototype: {
      monthly: isDev() ? 'P-7Y423058HL6858901L36NMVY' : 'P-9L615356WX143364PL4A62EY',
      annually: isDev() ? 'P-19K066423P254805UL36NNEA' : 'P-8WS045422U1104428L4A62WI'
    },
    pro: {
      monthly: isDev() ? 'P-5W883008BC517343YL36NN5Y' : 'P-8DW290690C195015FL4A6ZWA',
      annually: isDev() ? 'P-8D162782P50238434L36NNRY' : 'P-0Y9909335R883851DL4A6ZLA'
    },
    team: {
      monthly: isDev() ? 'P-1HD93160J38597406MAJLL4A' : 'P-8WH54102DM9735331MAJLNQI',
      annually: isDev() ? 'P-7AE59105GV4041848MAIDPZA' : 'P-6P5967597H026702GMAIDTNY'
    }
  }
};

const PRICES = {
  prototype: {
    monthly: 29,
    annually: 25
  },
  pro: {
    monthly: 39,
    annually: 31
  },
  team: {
    monthly: 32,
    annually: 26
  }
};

const basicToPrototype = plan => (plan.toLowerCase() === 'basic' ? 'prototype' : plan.toLowerCase());
const fixInterval = interval => (interval.startsWith('month') ? 'monthly' : 'annually');

export const getPrice = ({ plan, interval, quantity = 1, perMonth = true, discountPercent = 0 }) => {
  let pricePerMonth = get(PRICES, [basicToPrototype(plan), fixInterval(interval)], 0) * quantity;
  if (discountPercent && discountPercent > 0) {
    const absoluteDiscount = pricePerMonth * (discountPercent / 100);
    pricePerMonth -= absoluteDiscount;
  }
  return perMonth ? pricePerMonth : pricePerMonth * 12;
};

export const getPlanId = ({ service, plan = '', interval }) => {
  const path = [service, fixPlanName(plan), interval].map(p => p?.toLowerCase());
  return get(PLAN_IDS, path);
};

// change logic when trials are back.
const getIsTrial = () => false;

const getSubscriptionsUrl = ({ parentResource = 'teams', parentId, service }) => {
  const childResource = `${service === 'paypal' ? 'paypal_' : ''}subscriptions`;
  return `/v2/${parentResource}/${parentId}/${childResource}`;
};

const getSubscriptionPayload = (action, service, { planId, quantity, isTrial, paypalSubscriptionId, promoCode }) => {
  const subscription = { plan_id: planId, quantity, is_trial: isTrial };
  if (service === 'paypal') {
    subscription.last_requested_plan_id = planId;
    subscription.subscription_id = paypalSubscriptionId;
  } else if (action === 'create' && promoCode) {
    // currently paypal doesn't support promoCode, that's why the else-if.
    subscription.discount_promo_code = promoCode;
  }
  return subscription;
};

export const updateStripeSource = async (creditCard, teamId) => {
  const token = await getStripeToken(creditCard);
  const payload = { token: token.id, team: { id: teamId } };
  return api.post('/rpc/update_stripe_source', payload);
};

export const deleteStripeSource = teamId => {
  const payload = { team: { id: teamId } };
  return api.post('/rpc/delete_stripe_source', payload);
};

export const activateCancellationOffer = ({ teamSlug, offer }) => {
  const url = '/rpc/cancel_offer';
  const payload = { team_slug: teamSlug, offer };
  return api.post(url, payload);
};

export const createSubscription = async options => {
  const service = options.service.toLowerCase();
  const {
    interval,
    plan,
    quantity,
    subscriptionId,
    paypalSubscriptionId,
    promoCode,
    teamId,
    creditCard: creditCardElement
  } = options;
  const isTrial = getIsTrial();
  const planId = getPlanId({ service, interval, plan });

  if (!planId) {
    throw `Unknown plan: ${interval} ${plan}`;
  }

  // set new token
  if (service === 'stripe' && !isEmpty(creditCardElement)) await updateStripeSource(creditCardElement, teamId);

  // generate payload and url
  const subscriptionData = { planId, quantity, isTrial, paypalSubscriptionId, promoCode };
  const payload = getSubscriptionPayload(subscriptionId ? 'edit' : 'create', service, subscriptionData);
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  let subscription;

  if (subscriptionId) {
    const { data } = await api.put(url, subscriptionId, payload);
    subscription = data;
  } else {
    const { data } = await api.post(url, payload);
    subscription = data;
  }
  return subscription;
};

export const tempValidatePromoCode = (promoCode, _interval, _plan) => {
  const interval = _interval?.toLowerCase();
  const plan = _plan?.toLowerCase();
  if (promoCode === 'STUDENT-PRO-4923') {
    return plan === 'pro' && interval === 'annually';
  }
  if (
    promoCode === 'one-dollar-support-Pbl0' ||
    promoCode?.startsWith('animaFeedbackGift') ||
    promoCode === 'sep-2021-offer'
  ) {
    return interval === 'monthly';
  }
  return true;
};

export const pauseSubscription = ({ service, teamId, subscriptionId, start, end }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_paused: true, pause_start: start, pause_end: end };
  return api.put(url, subscriptionId, payload);
};

export const unpauseSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_paused: false, pause_start: null, pause_end: null };
  return api.put(url, subscriptionId, payload);
};

export const cancelSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_cancelled: true, cancelled_requested_at_date: new Date() };
  return api.put(url, subscriptionId, payload);
};

export const renewSubscription = ({ service, teamId, subscriptionId }) => {
  const url = getSubscriptionsUrl({ service, parentId: teamId });
  const payload = { is_cancelled: false, cancelled_requested_at_date: null };
  return api.put(url, subscriptionId, payload);
};

export const fetchSubscriptionData = ({ service, subscriptionId, plan = '', quantity, interval }) => {
  const url = `/${service}_subscription/details`;
  const params = { plan: getPlanId({ service, plan, interval }), quantity };
  return api.get(url, subscriptionId, { params, cachePolicy: 'no-cache' });
};
