import auth from '@/auth';
import { openModal } from '@/services/bus';

/**
 * @type {NavigationGuard}
 */
export const beforeSignupOrLogin = (to, from, next) => {
  if (auth.isLoggedIn() && !auth.isAnonymousUser()) {
    if (to.query.pairing) {
      next({ name: 'paired', query: to.query });
    } else {
      if (to.query.intent && document.referrer.indexOf('pricing') > -1) {
        localStorage.intent = JSON.stringify({
          intent: to.query.intent,
          plan: to.query.plan,
          interval: to.query.interval
        });
      }
      next('/');
    }
  } else {
    next();
  }
};

/**
 * @type {NavigationGuard}
 */
export const beforeEnterModal = (props = {}) => {
  return (to, from, next) => {
    let onCloseRedirect = props.onCloseRedirect || from || '/';
    if (props.calcRedirect) {
      const calcResult = props.calcRedirect(to, from);
      onCloseRedirect = calcResult || onCloseRedirect;
    }
    openModal({ ...props, onCloseRedirect });
    next();
  };
};
