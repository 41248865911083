<template>
  <PopoverMenu :items="roleOptions" position="right" :popoverStyle="{ width: '124px' }" :theme="$theme">
    <div
      slot="reference"
      ref="select"
      class="select"
      :class="{ isDeleted }"
      tabindex="-1"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      {{ prettyRole }}
      <svg-icon name="select-arrow-down" :size="24" />
    </div>
  </PopoverMenu>
</template>

<script>
import PopoverMenu from '@/components/Popovers/PopoverMenu';
import { colorPathChildren } from '@/utils/colors';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    role: {
      type: String,
      required: true
    },
    accessLevel: {
      type: String,
      required: true
    },
    isDeleted: {
      type: Boolean,
      default: false
    },
    withDelete: {
      type: Boolean,
      default: true
    },
    withAdmin: {
      type: Boolean,
      default: true
    },
    allowAll: {
      type: Boolean,
      default: false
    },
    allowDeleting: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PopoverMenu
  },
  mounted() {
    if (this.$theme?.includes('dark')) {
      setTimeout(() => {
        const { select } = this.$refs;
        colorPathChildren(select, 'var(--white)');
      }, 0);
    }
  },
  computed: {
    ...mapState('teamMemberships', { teamMembership: 'currentItem' }),
    ...mapGetters('teamMemberships', ['hasPermissions']),
    roleOptions() {
      const options = [
        {
          label: 'Viewer',
          onClick: () => this.onSelectRole('viewer'),
          disabled: this.isDisabled({ role: 'member', accessLevel: 'contributor' })
        },
        {
          label: 'Contributor',
          onClick: () => this.onSelectRole('contributor'),
          disabled: this.isDisabled({ role: 'member', accessLevel: 'contributor' })
        }
      ];
      if (this.withAdmin) {
        options.push({
          label: 'Admin',
          onClick: () => this.onSelectRole('admin'),
          disabled: this.isDisabled({ role: 'admin' })
        });
      }
      if (this.withDelete) {
        options.push({
          label: 'Remove',
          onClick: this.onRemove,
          isMarked: true,
          disabled: this.isDisabled({ role: 'admin' }) && !this.allowDeleting
        });
      }
      return options;
    },
    prettyRole() {
      const { role, accessLevel } = this;
      if (role === 'admin') return 'Admin';
      else if (accessLevel === 'viewer') return 'Viewer';
      else return 'Contributor';
    }
  },
  methods: {
    getAccessLevelAndRole(_role) {
      const role = _role === 'admin' ? 'admin' : 'member';
      const access_level = _role === 'viewer' ? 'viewer' : 'contributor';
      return { role, access_level };
    },
    onSelectRole(role) {
      const updatedRole = this.getAccessLevelAndRole(role);
      this.$emit('change-role', updatedRole);
    },
    onRemove() {
      this.$emit(this.isDeleted ? 'un-remove' : 'remove');
    },
    isDisabled(requiredPermissions) {
      const { allowAll, hasPermissions } = this;
      const hasOwnerPermissions = hasPermissions({ role: 'owner' });
      const hasSpecificPermissions = hasPermissions({ ...requiredPermissions });

      return !(allowAll || hasOwnerPermissions || hasSpecificPermissions);
    }
  }
};
</script>

<style scoped>
.select {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: opacity 1s ease;
}
.isDeleted {
  opacity: 0.3;
}
</style>
