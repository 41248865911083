<template>
  <div class="container">
    <div class="title">Payment</div>
    <div class="subtitle">Add a payment method and review your upgrade details.</div>

    <div class="body">
      <div class="billing-info">
        <div class="billing-plan">
          <div class="label">Billing plan</div>
          <PricingRow
            v-for="_interval in ['annually', 'monthly']"
            :key="_interval"
            :price="getPrice({ plan: planName, interval: _interval })"
            :planName="planNameDisplay"
            :planInterval="_interval"
            :isCurrentPlan="_interval === interval"
            :disabled="isActivePlan(_interval) || loading"
            :showRibbon="_interval === 'annually' && isNewSubscription && isDesktop"
            @click="changeInterval(_interval)"
          />
        </div>

        <div class="paid-seats">
          <div class="label">
            Select your team paid seats
          </div>
          <div class="number-of-contributors">
            <span>No. of contributors</span>
            <an-number-input
              v-model="quantity"
              :min="minSeats"
              :max="99"
              :disabled="loading"
              :minErrorTooltipText="minErrorTooltipText"
            />
          </div>
        </div>

        <div class="separated" v-if="isStripe">
          <div class="payment-method-label">
            Payment method
            <svg-icon name="powered-by-stripe" :width="110" :height="24" />
          </div>
          <div v-if="creditCardLastDigits && !clickedChange" class="payment-method-label label">
            {{ creditCardLastDigits }}
            <an-link @click="clickedChange = true" variant="primary">Change</an-link>
          </div>
          <div class="payment" v-else>
            <StripeCardInput @mount="onStripeCardMount" @error="onStripeError" />
            <div class="invoice-address-label">Invoice to (optional)</div>
            <an-textarea placeholder="Billing address" withBorder v-model="invoiceTo" allowMultiline />
          </div>
        </div>

        <div class="separated" v-if="isStripe && isNewSubscription">
          <div class="promo-code-header" @click="toggleCouponCodeSection">
            Add a coupon code
            <div class="collapse-icon" :class="{ rotated: isPromoCodeOpen }">
              <svg-icon name="arrow-right" :size="30" />
            </div>
          </div>

          <div v-if="isPromoCodeOpen">
            <div class="disclaimer promo-code-details" v-if="isActivePromoCode">
              <div class="promo-code-details-name">
                {{ promoCode }}
                <an-link @click="resetPromoCode" variant="primary">Remove</an-link>
              </div>
              <div class="promo-code-details-saving">
                {{ promoCodeSaving }}
              </div>
            </div>

            <div v-else>
              <div class="label promo-code-input-label">Coupon code</div>
              <div class="promo-code-input-container">
                <an-input v-model="promoCode" class="promo-code-input" />
                <an-button
                  variant="secondary"
                  @click="applyPromoCode"
                  :isWorking="isPromoCodeWorking"
                  :invalid="!!promoCodeError"
                  :disabled="!promoCode.trim()"
                >
                  Apply code
                </an-button>
              </div>
              <div class="promo-code-input-error" v-if="promoCodeError">{{ promoCodeError }}</div>
            </div>
          </div>
        </div>

        <div class="disclaimer" v-if="showSwitchService">
          <span v-if="isStripe">
            Having trouble checking out? Try
            <an-link data-cy="try-paypal" variant="primary" @click="switchService('paypal')">PayPal</an-link>
          </span>
          <span v-if="isPaypal">
            I changed my mind! Let me pay with my
            <an-link variant="primary" @click="switchService('stripe')">credit card</an-link>
          </span>
        </div>
      </div>

      <div class="checkout">
        <div class="checkout-title">Check out</div>
        <Loader v-if="loading" />
        <div v-else>
          <div class="checkout-list">
            <div v-for="line in checkoutDetails" :key="line" class="checkout-list-item">
              <svg-icon name="circle-check" :size="20" />
              <div class="checkout-list-item-text">{{ line }}</div>
            </div>
          </div>
          <div class="total-price-container">
            <span>Total</span>
            <transition name="slide-fade">
              <span class="total-price" :key="priceAfterDiscountDisplay">
                {{ priceAfterDiscountDisplay }}
              </span>
            </transition>
          </div>
          <div class="price-details" v-if="priceDetails">{{ priceDetails }}</div>
          <div class="diff-disclaimer" v-if="diffDisclaimer">{{ diffDisclaimer }}</div>
          <div class="cta" v-if="hasChanges">
            <div class="paypal-button" v-if="isPaypal">
              <an-button
                v-if="showPaypalLoading || paypalSubscriptionId"
                @click="purchase"
                variant="tertiary"
                :isWorking="isWorking"
              >
                {{ ctaText }}
              </an-button>
              <PayPalButton
                v-else
                :plan="planName"
                :interval="interval"
                :quantity="quantity"
                @click="onPaypalClick"
                @approve="onPaypalApproved"
                @error="onPaypalError"
              />
            </div>
            <div v-else-if="isStripe">
              <an-button data-cy="purchase-cta-btn" @click="purchase" :isWorking="isWorking">{{ ctaText }}</an-button>
            </div>
          </div>
        </div>
        <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportPurchase } from 'anima-ppc-tracking';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty, upperFirst } from 'lodash-es';

import { fixPlanName, formatPrice } from '@/utils/billing';
import { SubscriptionMixin } from '@/mixins';

import StripeCardInput from '@/components/Payment/StripeCardInput';
import PayPalButton from '@/components/Payment/PayPalButton';
import PricingRow from '@/components/Pricing/PricingRow';
import Loader from '@/components/Loading/DefaultLoader';
import { openModal, EventBus, toastError } from '@/services/bus';

export default {
  name: 'payment',
  data() {
    return {
      invoiceTo: '',
      quantity: 2,
      planName: '',
      interval: '',
      subscriptionId: '',
      stripeSubscriptionId: '',
      paypalSubscriptionId: '',
      promoCode: '',
      isPromoCodeOpen: false,
      promoCodeError: '',
      nextInvoiceData: {},
      isActivePromoCode: false,
      promoCodeDiscountPercent: 0,
      minTeamPlanSeats: 3,
      service: 'stripe',
      creditCard: null,
      clickedChange: false,
      loading: false,
      isWorking: false,
      showPaypalLoading: false,
      isPromoCodeWorking: false,
      errorMessage: null
    };
  },
  mixins: [SubscriptionMixin],
  components: {
    StripeCardInput,
    PayPalButton,
    PricingRow,
    Loader
  },
  mounted() {
    const { promo_code } = this.$route.query;
    this.reset();
    this.resetQuantity();
    if (promo_code) {
      this.promoCode = promo_code;
      this.isPromoCodeOpen = true;
      this.applyPromoCode();
    }
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapGetters({
      contributorsCount: 'teamMemberships/contributorsCountInTeam',
      activeSubscription: 'stripeSubscriptions/activeSubscription',
      isActiveExperiment: 'experiments/isActive'
    }),
    isAnnual() {
      return this.interval === 'annually';
    },
    isMonthly() {
      return this.interval === 'monthly';
    },
    isNewSubscription() {
      return isEmpty(this.activeSubscription);
    },
    hasChanges() {
      if (this.isNewSubscription) {
        return true;
      }
      const { planName, quantity, interval } = this;
      const { quantity: oldQuantity, interval: oldInterval, product_name: oldPlan = 'free' } = this.activeSubscription;
      return oldQuantity !== quantity || oldInterval !== interval || oldPlan !== fixPlanName(planName);
    },
    isStripe() {
      return this.service === 'stripe';
    },
    isPaypal() {
      return this.service === 'paypal';
    },
    creditCardLastDigits() {
      const { credit_card_last_digits } = this.team;
      if (!credit_card_last_digits) return null;
      return `Credit card ending with ${credit_card_last_digits.padStart(8, '*')}`;
    },
    ctaText() {
      const { id } = this.activeSubscription ?? {};
      return id ? 'Update plan' : 'Purchase plan';
    },
    showSwitchService() {
      return !this.activeSubscription?.id;
    },
    planNameDisplay() {
      return upperFirst(this.planName) || null;
    },
    moneyBackPeriod() {
      const value = this.isActiveExperiment('money-back-period');
      return value === '30' ? 30 : 7;
    },
    checkoutDetails() {
      const { product_name = 'Free', interval: oldInterval, quantity: oldQuantity } = this.activeSubscription;
      const planName = this.planNameDisplay;
      let firstLine;

      if (product_name === fixPlanName(planName)) {
        firstLine = `Update your ${planName} plan.`;
      } else if (product_name === 'Free' || planName === 'Pro') {
        firstLine = `Upgrade to ${planName} plan.`;
      } else {
        firstLine = `Change plan to ${planName}.`;
      }

      const details = [firstLine];

      if (this.isNewSubscription) {
        details.push(`Pay for ${this.quantity} contributor seats in your team.`);
        details.push(`Risk free. ${this.moneyBackPeriod} days money-back guarantee.`);
      } else {
        const { partial_price: partialPrice } = this.nextInvoiceData;

        if (oldQuantity && this.quantity !== oldQuantity) {
          const diff = this.quantity - oldQuantity;
          const absDiff = Math.abs(diff);
          const quantityMsg =
            diff < 0
              ? `Reduce ${absDiff} seats from your team.`
              : `Pay for ${absDiff} more contributors' seats on your team.`;
          details.push(quantityMsg);
        }

        if (partialPrice && partialPrice > 0) {
          details.push('You’ll only pay the difference between the plans.');
          if (oldInterval === this.interval) {
            details.push('You’ll be charged on the next billing cycle.');
          }
        } else if (partialPrice && partialPrice < 0) {
          const val =
            'The remaining amount will be saved as a credit on your account and put into effect at the beginning of your next billing cycle.';
          details.push(val);
        }
      }

      return details;
    },
    totalPrice() {
      const { planName: plan, interval, quantity, isMonthly: perMonth } = this;
      return this.getPrice({ plan, interval, quantity, perMonth });
    },
    totalPriceDiscount() {
      return this.totalPrice * (this.promoCodeDiscountPercent / 100);
    },
    priceAfterDiscountDisplay() {
      const {
        planName: plan,
        interval,
        quantity,
        isMonthly: perMonth,
        promoCodeDiscountPercent: discountPercent,
        nextInvoiceData = {}
      } = this;
      const { partial_price: partialPrice } = nextInvoiceData;
      const price = this.getPrice({ plan, interval, quantity, perMonth, discountPercent });
      return formatPrice(partialPrice || price);
    },
    priceDetails() {
      const { quantity, interval, planName: plan, isAnnual, nextInvoiceData = {} } = this;
      if (nextInvoiceData.partial_price) return;
      const price = formatPrice(this.getPrice({ plan, interval }));
      const quantityPhrase = `${quantity} seat${quantity === 1 ? '' : 's'}`;
      const pricePhrase = isAnnual ? `(${price} / mo × 12)` : price;
      return `${quantityPhrase} × ${pricePhrase}`;
    },
    promoCodeSaving() {
      const { promoCodeDiscountPercent, totalPriceDiscount } = this;
      return `Saving ${formatPrice(totalPriceDiscount)} (${promoCodeDiscountPercent}%)`;
    },
    minSeats() {
      const planRequiredMin = this.planName === 'team' ? 3 : 1;
      return Math.max(planRequiredMin, this.contributorsCount);
    },
    diffDisclaimer() {
      const { planName: newPlan = '' } = this;
      const { product_name: currentPlan = '' } = this.activeSubscription ?? {};
      const { partial_price } = this.nextInvoiceData ?? {};
      if (partial_price) {
        return '';
      }
      if (currentPlan.toLowerCase() === 'prototype' && newPlan.toLowerCase() === 'pro') {
        return 'You’ll only pay the difference towards the new plan – you won’t lose the money which you’ve already paid for your current plan.';
      } else if (currentPlan.toLowerCase() === 'pro' && newPlan.toLowerCase() === 'basic') {
        return 'The amount of the remaining pre-paid payment will be added to your account as a credit and will be used for the next payment.';
      }
      return '';
    },
    validPlanAndInterval() {
      const {
        query: { plan, interval }
      } = this.$route;
      return ['basic', 'pro', 'team'].includes(plan) && ['monthly', 'annually'].includes(interval);
    },
    minErrorTooltipText() {
      const { minSeats, quantity, minTeamPlanSeats } = this;
      if (quantity === minSeats && quantity === minTeamPlanSeats && this.planName === 'team') {
        return `Team plan requires at least ${minTeamPlanSeats} seats.`;
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
      fetchTeam: 'teams/fetchOne',
      fetchCoupon: 'coupons/fetchOne',
      updateTeam: 'teams/update'
    }),
    async onStripeCardMount(card) {
      this.creditCard = card;
      this.errorMessage = null;
    },
    onStripeError(error) {
      console.error(error);
    },
    switchService(service) {
      this.service = service;
      if (service === 'paypal') {
        this.resetPromoCode();
      }
    },
    onPaypalClick() {
      this.$trackEvent('payment.paypal-button.click');
      this.errorMessage = null;
    },
    onPaypalApproved(data) {
      this.paypalSubscriptionId = data?.subscriptionID;
      this.$trackEvent('payment.paypal.success');
      this.showPaypalLoading = true;
      this.purchase();
    },
    onPaypalError(data) {
      this.$trackEvent('payment.paypal.failure');
      console.error(data);
      this.errorMessage = data;
    },
    isActivePlan(_interval) {
      const { product_name = '', interval } = this.activeSubscription ?? {};
      const activePlan = product_name.toLowerCase();
      return _interval === interval && activePlan === this.planName;
    },
    reset() {
      const {
        query: { plan, interval, promo_code: promoCode }
      } = this.$route;
      const { id, subscription_id: serviceSubscriptionId, _service: service } = this.activeSubscription ?? {};

      if (id && promoCode) {
        return this.$emit('close', { redirect: { name: 'team' } });
      }

      if (!this.validPlanAndInterval) {
        return this.$router.push({ name: 'team-pricing' });
      }

      this.planName = plan;
      this.interval = interval;
      this.subscriptionId = id;
      this.errorMessage = null;

      if (service) {
        this.service = service;
        if (service === 'stripe') {
          this.stripeSubscriptionId = serviceSubscriptionId;
        } else if (service === 'paypal') {
          this.paypalSubscriptionId = serviceSubscriptionId;
        }
      }

      if (this.stripeSubscriptionId) {
        this.creditCard = null;
      }
    },
    async fetchData() {
      const { query = {}, params = {} } = this.$route;
      try {
        EventBus.$emit('reload-team-info', { skipCache: false });
        await this.fetchTeam({ id: params.teamSlug, params: { is_slug: true } });
      } catch (err) {
        this.$trackEvent('payment.fetch-team.failure', {
          ...params,
          ...query,
          message: err.message,
          email: this.user.email
        });

        this.$emit('close');
      }
    },
    resetPromoCode(leaveOpen = false) {
      const {
        query: { promo_code = '' }
      } = this.$route;
      this.promoCode = promo_code;
      this.isPromoCodeOpen = leaveOpen || !!this.promoCode;
      this.isActivePromoCode = false;
      this.promoCodeError = '';
      this.promoCodeDiscountPercent = 0;
    },
    resetQuantity() {
      const activeSubscriptionQuantity = this.activeSubscription?.quantity || 0;
      this.quantity = Math.max(activeSubscriptionQuantity, this.minSeats) || this.quantity;
    },
    async resetSubscriptionData() {
      try {
        const { planName: plan, quantity, interval } = this;
        const { id: subscriptionId, _service: service } = this.activeSubscription ?? {};
        this.nextInvoiceData = {};
        if (subscriptionId) {
          this.loading = true;
          const { data } = await this.fetchSubscriptionData({
            service,
            subscriptionId,
            plan,
            quantity,
            interval
          });
          this.nextInvoiceData = data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async changeInterval(newInterval) {
      const { query } = this.$route;
      await this.$router.replace({ query: { ...query, interval: newInterval } });
      this.applyPromoCode();
    },
    toggleCouponCodeSection() {
      this.isPromoCodeOpen = !this.isPromoCodeOpen;
    },
    async applyPromoCode() {
      const promoCode = this.promoCode?.trim();
      const { interval, planName } = this;
      if (!promoCode) return;

      try {
        this.$trackEvent('payment.apply-promo-code-button.click');

        if (!this.tempValidatePromoCode(promoCode, interval, planName)) {
          this.resetPromoCode(true);
          return toastError("Sorry, this coupon isn't valid for the plan you chose.");
        }

        this.promoCodeError = '';
        this.isPromoCodeWorking = true;
        const params = { primary_key: 'stripe_id' };

        const { percent_off, stripe_id } = await this.fetchCoupon({ id: promoCode, params, skipCache: true });
        this.isActivePromoCode = true;
        this.promoCode = stripe_id;
        this.promoCodeDiscountPercent = percent_off || 0;

        this.$trackEvent('payment.apply-promo-code.success', { promoCode });
      } catch (err) {
        this.promoCodeError = 'Oops! Invalid code. Please check and try again.';
        this.$trackEvent('payment.apply-promo-code.failure', { promoCode });
      } finally {
        this.isPromoCodeWorking = false;
      }
    },
    async updateInvoiceAddress() {
      const { team, service, invoiceTo } = this;
      try {
        if (service === 'stripe' && invoiceTo !== team?.invoice_to) {
          const { id } = team;
          const payload = { invoice_to: invoiceTo };
          await this.updateTeam({ id, payload });
          this.$trackEvent('payment.update-invoice-address.success');
        }
      } catch (err) {
        this.$trackEvent('payment.update-invoice-address.failure', {
          invoiceTo,
          service,
          teamId: team.id,
          errorMessage: err.message
        });
      }
    },
    async purchase() {
      const {
        team,
        interval,
        planName,
        quantity,
        service,
        subscriptionId,
        paypalSubscriptionId,
        creditCard,
        promoCode,
        isActivePromoCode,
        isDesktop
      } = this;
      const { id: teamId } = team;
      const newSubscription = !!this.isNewSubscription;
      const screenWidth = window?.innerWidth;
      this.errorMessage = null;
      this.isWorking = true;
      const eventProps = {
        interval,
        teamId,
        planName,
        quantity,
        promoCode,
        service,
        newSubscription,
        isDesktop,
        screenWidth
      };
      try {
        this.$trackEvent('payment.purchase-button.click', eventProps);

        if (isActivePromoCode && !this.tempValidatePromoCode(promoCode, interval, planName)) {
          return toastError("Sorry, this coupon isn't valid for the plan you chose.");
        }

        await this.createSubscription({
          interval,
          plan: upperFirst(planName),
          service,
          teamId,
          quantity,
          subscriptionId,
          paypalSubscriptionId,
          creditCard,
          promoCode: this.isActivePromoCode ? promoCode : null
        });

        EventBus.$emit('reload-team-info');
        EventBus.$emit('reload-user-memberships');

        this.$trackEvent('payment.purchase.success', eventProps);
        this.$gtm.trackEvent({
          event: 'purchases',
          event_category: 'Purchases',
          event_action: this.user?.role,
          event_label: this.user?.email
        });
        reportPurchase({}, this.user?.id);

        await this.updateInvoiceAddress();
        const { source } = this.$router.currentRoute.query;
        openModal({ name: 'payment-success', props: { source } });
      } catch (error) {
        const exception = error?.data?.message || error?.error || error.message || error.originalError || error;
        this.$trackEvent('payment.purchase.failure', { message: JSON.stringify(error.message), ...eventProps });
        this.$sentry.captureException(exception);
        this.errorMessage = exception;
      } finally {
        this.isWorking = false;
      }
    }
  },
  watch: {
    '$route.path': {
      handler: 'fetchData',
      immediate: true
    },
    '$route.query'() {
      this.reset();
      this.resetSubscriptionData();
    },
    activeSubscription() {
      this.reset();
      this.resetQuantity();
      this.resetSubscriptionData();
    },
    quantity() {
      this.resetSubscriptionData();
    },
    team({ invoice_to } = {}) {
      if (invoice_to) {
        this.invoiceTo = invoice_to;
      }
    },
    teamMemberships() {
      this.resetQuantity();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_table.scss';
.body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 760px;
  margin-top: 40px;
  padding-top: 40px;
  border-top: var(--border);
  @include mobile {
    flex-direction: column;
  }
}
.plan,
.payment {
  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    &-text {
      font-size: 22px;
    }
  }
  &-name,
  &-amount {
    font-weight: bold;
  }
  &-table,
  &-billing-email-label {
    margin-top: 30px;
  }
}
.billing-info {
  width: 390px;
  @include mobile {
    max-width: 100%;
  }
}
.billing-plan {
  margin-bottom: 30px;
}
.paypal-button {
  width: 154px;
}
.number-of-contributors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.checkout {
  background: var(--light-container-background);
  width: 330px;
  padding: 30px;
  border-radius: 10px;
  margin-top: 36px;
  &-title {
    @include secondary-title;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }
  &-list {
    padding: 0 0 30px;
    margin-bottom: 20px;
    border-bottom: var(--border);
  }
  &-list-item {
    display: flex;
    align-items: flex-start;
    .svg-container {
      margin: 4px 14px 0 0;
    }
    &-text {
      flex: 1;
    }
    + .checkout-list-item {
      margin-top: 20px;
    }
  }
}
.total-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .total-price {
    @include secondary-title;
    font-weight: 800;
  }
}
.price-details {
  color: var(--secondary-text);
}
.payment-method-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-address-label {
  margin: 30px 0 12px;
}
.cta {
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promo-code-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.collapse-icon {
  transition: transform 0.2s ease;
  transform: rotate(90deg);
  &.rotated {
    transform: rotate(-90deg);
  }
}
.promo-code-details {
  &-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-saving {
    color: var(--secondary-text);
  }
}
.promo-code-input {
  width: 235px;
  &-label {
    margin-top: 20px;
  }
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-error {
    color: var(--red);
    margin-top: 5px;
  }
}
.diff-disclaimer {
  border-top: var(--border);
  margin-top: 30px;
  padding-top: 20px;
}
.error {
  margin-top: 30px;
  text-align: center;
  color: var(--red);
}
</style>
