import { render, staticRenderFns } from "./Downgrade.vue?vue&type=template&id=1e7c091e&scoped=true&"
import script from "./Downgrade.vue?vue&type=script&lang=js&"
export * from "./Downgrade.vue?vue&type=script&lang=js&"
import style0 from "./Downgrade.vue?vue&type=style&index=0&id=1e7c091e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e7c091e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e7c091e')) {
      api.createRecord('1e7c091e', component.options)
    } else {
      api.reload('1e7c091e', component.options)
    }
    module.hot.accept("./Downgrade.vue?vue&type=template&id=1e7c091e&scoped=true&", function () {
      api.rerender('1e7c091e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Downgrade/Downgrade.vue"
export default component.exports