<template>
  <div class="multi-select-bar">
    <div class="number-items" @click="clickCheck">
      <div class="clickable-icon uncheck">
        <svg-icon :name="checkIcon" :size="24" />
      </div>
      {{ amountMessage }}
    </div>
    <div class="actions">
      <div
        class="clickable-icon trash"
        @mouseover="hoverIcon = true"
        @mouseout="hoverIcon = false"
        @click="$emit('delete')"
      >
        <div ref="trashIcon" v-html="require(`!html-loader!@/assets/svg/trash.svg`)" :size="30" />
      </div>
    </div>
  </div>
</template>

<script>
import { colorPathChildren } from '@/utils/colors';
export default {
  data() {
    return {
      hoverIcon: false
    };
  },
  props: {
    selection: {
      type: Array,
      required: true
    },
    selectionState: {
      type: String,
      default: 'none',
      validator: v => ['none', 'some', 'all'].includes(v)
    },
    type: {
      type: String,
      required: true,
      validator: v => ['screen', 'component'].includes(v)
    }
  },
  computed: {
    amountMessage() {
      const { selection } = this;
      const type = `${this.type}${selection.length === 1 ? '' : 's'}`;
      return `${selection.length} ${type} selected`;
    },
    checkIcon() {
      return this.selectionState === 'all' ? 'check' : 'uncheck';
    }
  },
  methods: {
    clickCheck() {
      this.$emit(this.selectionState === 'all' ? 'uncheck' : 'check-all');
    }
  },
  watch: {
    hoverIcon(val) {
      if (val) colorPathChildren(this.$refs.trashIcon, 'var(--white)');
      else colorPathChildren(this.$refs.trashIcon, 'var(--secondary)');
    }
  }
};
</script>

<style lang="scss" scoped>
.multi-select-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 30px;
  box-shadow: var(--shadow);
  background-color: var(--white);
  border-radius: 100px;
  .actions {
    margin-left: 150px;
  }
}
.number-items,
.actions {
  display: flex;
  align-items: center;
}
.clickable-icon {
  &.uncheck {
    margin-right: 12px;
  }
  &.trash {
    border: 1px solid var(--secondary);
    border-radius: 100%;
    &:hover {
      background-color: var(--secondary);
    }
  }
}
</style>
