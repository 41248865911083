var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["radio-container", { full: _vm.full }],
      style: {
        "--spacing-y": _vm.spacingY + "px",
        "--spacing-x": _vm.spacingX + "px",
        alignItems: _vm.centered ? "center" : "flex-start"
      },
      on: { click: _vm.handleContainerClick }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.radioButtonValue,
            expression: "radioButtonValue"
          }
        ],
        attrs: { type: "radio", hidden: "" },
        domProps: {
          value: _vm.label,
          checked: _vm._q(_vm.radioButtonValue, _vm.label)
        },
        on: {
          change: function($event) {
            _vm.radioButtonValue = _vm.label
          }
        }
      }),
      _c("div", {
        staticClass: "radio",
        domProps: {
          innerHTML: _vm._s(
            require("!html-loader!./" + _vm.checkedStatus + ".svg")
          )
        },
        on: { click: _vm.onClick }
      }),
      _c("div", { staticClass: "radio-label" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }