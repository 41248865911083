var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.containerStyle },
    [
      _c(
        "Upload",
        {
          attrs: { url: _vm.url, accept: _vm.accept, context: _vm.context },
          on: {
            success: function($event) {
              return _vm.$emit("success", $event)
            },
            start: function($event) {
              return _vm.$emit("start", $event)
            },
            failure: function($event) {
              return _vm.$emit("failure", $event)
            }
          }
        },
        [
          _vm.src
            ? _c("RoundedImage", {
                key: _vm.src,
                attrs: {
                  src: _vm.src,
                  height: _vm.height,
                  width: _vm.width,
                  circle: _vm.circle
                }
              })
            : _vm._e(),
          _c(
            "RoundedImage",
            {
              attrs: {
                slot: "init",
                height: _vm.height,
                width: _vm.width,
                circle: _vm.circle
              },
              slot: "init"
            },
            [_c("svg-icon", { attrs: { name: "plus" } })],
            1
          ),
          _c(
            "RoundedImage",
            {
              attrs: {
                slot: "loading",
                height: _vm.height,
                width: _vm.width,
                circle: _vm.circle
              },
              slot: "loading"
            },
            [_c("div", { staticClass: "bubble", style: _vm.loadingStyle })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }