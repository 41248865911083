<template>
  <transition name="slide-right">
    <div class="sidebar-container" v-show="isDesktop || isOpen">
      <div class="logo-section" v-if="isDesktop">
        <router-link to="/">
          <an-logo />
        </router-link>
      </div>

      <div v-if="isMobile" style="width: 100%">
        <div @click="$emit('close')">
          <svg-icon name="close" :size="30" fill="white" />
        </div>
        <div class="user-details-row">
          <div>
            <an-user-avatar :user="user" :size="60" />
          </div>
          <div>
            <div class="user-name">{{ user.name }}</div>
            <div class="user-email">{{ user.email }}</div>
          </div>
        </div>
      </div>

      <div class="section-first">
        <div class="link-label" @click="navigate('shared')" v-if="showSharedProjects">Shared with me</div>
      </div>

      <div class="title teams">
        <span>Teams</span>
        <an-tooltip text="New team" position="top-left" :offset="5" v-if="isDesktop">
          <div class="add-team" @click="newTeam">
            <svg-icon name="plus" />
          </div>
        </an-tooltip>
      </div>

      <div class="section">
        <div
          v-for="team in userTeams"
          :key="team.id"
          class="team-link"
          data-cy="team-link"
          @click="navigateToTeam(team)"
        >
          <div :class="teamLinkClasses(team)">
            {{ team.name }}
          </div>
          <div class="plan" v-if="shouldShowPlan">
            {{ fixPlanName(team.plan) }}
          </div>
        </div>
      </div>

      <div class="title" v-if="isDesktop">
        <span>Discover Anima</span>
      </div>

      <div class="section last">
        <div class="link-label" @click="navigate('plugins')" v-if="isDesktop">Plugins</div>
        <div class="link-label" v-if="isDesktop">
          <a :href="anima101Url" target="_blank" @click="trackNavigation('Anima 101')">
            Anima 101
          </a>
        </div>
        <div :class="['link-label', { selected: $route.name === 'learn' }]" @click="navigate('learn')" v-if="isDesktop">
          Learn
        </div>
        <div class="link-label" @click="logout" v-if="isMobile">Logout</div>
        <div class="link-label" v-show="false">Showcase</div>
      </div>

      <transition name="fade">
        <div class="anima-5-promo" v-if="showBanner">
          <Anima5Banner @click="goToBlogPost" @close="closeAnima5Banner" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { EventBus } from '@/services/bus';
import { UserMixin } from '@/mixins';
import Anima5Banner from '@/components/Layout/Sidebar/Anima5Banner.vue';
import auth from '@/auth';

export default {
  data() {
    return {
      anima101Url: 'https://www.animaapp.com/resources/anima-101',
      anima5Url:
        'https://www.animaapp.com/blog/inside-anima/introducing-anima-5-bringing-material-design-to-figma-and-adobe-xd/',
      showSharedProjects: !!localStorage.getItem('hasSharedProjects'),
      showBanner: true
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Anima5Banner
  },
  mounted() {
    this.fetchData();
    EventBus.$on('reload-user-memberships', this.fetchData);
  },
  destroyed() {
    EventBus.$off('reload-user-memberships', this.fetchData);
  },
  mixins: [UserMixin],
  computed: {
    shouldShowPlan() {
      return !process.env.ORGANIZATION_NAME;
    },
    ...mapState('projects', ['sharedProjects']),
    ...mapState('users', { user: 'currentItem' }),
    ...mapState('teamMemberships', { userTeamMemberships: 'user' }),
    ...mapGetters('teamMemberships', ['userTeams'])
  },
  methods: {
    ...mapActions({
      fetchAllUserMemberships: 'teamMemberships/fetchAllUserMemberships',
      fetchTeamMemberships: 'teamMemberships/fetchAllTeamMemberships',
      fetchSharedProjects: 'projects/fetchSharedProjects'
    }),
    ...mapMutations({
      selectTeam: 'teams/setCurrentItem',
      setUserMemberships: 'teamMemberships/setUserMemberships'
    }),
    async fetchData() {
      try {
        this.fetchSharedProjectsData();
        await this.fetchAllUserMemberships({ id: 'me', skipCache: true });
        this.checkForDefaultTeam();
        this.fetchExtraTeamMemberships();
      } catch (err) {
        console.error(err);
      }
    },
    async fetchSharedProjectsData() {
      await this.fetchSharedProjects();
      this.showSharedProjects = !!this.sharedProjects?.length;
      localStorage.setItem('hasSharedProjects', this.showSharedProjects);
    },
    newTeam() {
      this.$trackEvent('sidebar.new-team.click');
      this.$router.push({ name: 'new-team' });
    },
    fixPlanName: plan => (plan === 'Prototype' ? 'Basic' : plan) || 'Free',
    async fetchExtraTeamMemberships() {
      try {
        // fetch the first 5 team members of the user's teams.
        const promises = this.userTeamMemberships?.map(async tm => {
          try {
            const { results, total: totalMembers } = await this.fetchTeamMemberships({
              id: tm.team,
              params: { page_size: 5 },
              saveResults: false
            });
            return { ...tm, members: results, membersLength: results?.length, totalMembers };
          } catch (err) {
            return tm;
          }
        });
        if (promises) {
          const results = await Promise.all(promises);
          this.setUserMemberships({ results });
          this.trackUserTeamsData();
        }
      } catch (err) {
        console.error(err);
      }
    },
    teamLinkClasses(team) {
      const { teamSlug } = this.$route.params;
      return {
        'link-label': true,
        selected: team?.slug === teamSlug
      };
    },
    navigateToTeam(team) {
      this.selectTeam(team);
      this.$emit('close');
      this.navigate('team', { teamSlug: team.slug });
    },
    navigate(pageName, params = {}) {
      this.trackNavigation(pageName, params);
      this.$emit('close');
      this.$router.push({ name: pageName, params }, () => {});
    },
    trackNavigation(pageName, params = {}) {
      this.$trackEvent('webapp.sidebar.navigation', { pageName, ...params });
    },
    logout() {
      this.$trackEvent('sidebar.logout.click');
      auth.logout();
      window.location.reload();
    },
    goToBlogPost() {
      this.trackNavigation('Anima 5 blog post');
      window.open(this.anima5Url, '_blank');
    },
    closeAnima5Banner() {
      this.$trackEvent('sidebar.anima5-banner.close');
      this.showBanner = false;
    }
  },
  watch: {
    isOpen(newState) {
      if (this.isMobile) {
        if (newState) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'visible';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';
.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  line-height: var(--sidebar-line-height);
  padding: var(--sidebar-padding);
  height: var(--sidebar-height);
  width: var(--sidebar-width);
  background: var(--sidebar-background);
  color: var(--sidebar-font-color);
  @include mobile {
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}

.title {
  width: 100%;
  margin: var(--sidebar-title-margin);
  color: var(--sidebar-title-color);
  cursor: default;
  @include mobile {
    margin-top: 0;
  }
  &.teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--sidebar-title-padding);
    @include desktop {
      border-bottom: var(--sidebar-title-border-bottom);
    }
    @include mobile {
      padding: 0;
    }
    .add-team {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--white);
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.user {
  &-details-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 40px 0 30px;
    border-bottom: var(--sidebar-title-border-bottom);
    > * + * {
      margin-left: 16px;
    }
  }
  &-name {
    @include headline;
    @include ellipsis;
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
  }
  &-email {
    @include ellipsis;
    color: var(--white);
    opacity: 0.4;
  }
}

.logo-section {
  display: flex;
  @include mobile {
    height: 30px;
  }
  .logo {
    @include desktop {
      width: 85px;
      height: 25px;
    }
  }
  > * + * {
    margin-left: 20px;
  }
}

.section {
  &-first {
    margin: 50px 0 0;
    padding: 0;
  }
  width: 100%;
  padding: 30px 0 10px;
  &.last {
    > * + * {
      margin-top: 24px;
    }
    @include mobile {
      margin-bottom: 80px;
    }
  }
}

.team-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-link + .team-link,
.link-label + .link-label {
  margin-top: 20px;
}

.link-label {
  @include ellipsis;
  width: 170px;
  cursor: pointer;
  &.selected {
    font-weight: bold;
  }
}

.selected {
  font-weight: 500;
}

.plan {
  width: 40px;
  cursor: pointer;
  text-align: right;
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
}

.anima-5-promo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
