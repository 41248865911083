var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sync-container" },
    [
      !_vm.ready
        ? _c(
            "div",
            { staticClass: "loader" },
            [
              _c("LoadingScreen", {
                attrs: {
                  delay: false,
                  type: _vm.currentRelease.is_code_generation_done
                    ? "default"
                    : "syncing",
                  theme: "dark"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ready,
                expression: "ready"
              }
            ],
            staticClass: "wrapper"
          },
          [
            _c("SyncHeader", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFullScreen && !_vm.showOnlyCode,
                  expression: "!isFullScreen && !showOnlyCode"
                }
              ],
              on: { screenChange: _vm.reactToScreenChange }
            }),
            _c(
              "main",
              {
                ref: "mainContent",
                style: { paddingTop: _vm.getPaddingTop },
                attrs: { id: "sync-content" }
              },
              [
                _c(
                  "div",
                  { staticClass: "main-content" },
                  [
                    _c("MainFrame", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showOnlyCode,
                          expression: "!showOnlyCode"
                        }
                      ],
                      attrs: {
                        previewMasterSlug: _vm.masterSlug,
                        isPreview: !_vm.isReleaseOmni
                      }
                    }),
                    _c("ScreenNavArrow", {
                      attrs: { side: "right", show: _vm.showNavigationArrows },
                      on: { click: _vm.reactToScreenChange }
                    }),
                    _c("ScreenNavArrow", {
                      attrs: { side: "left", show: _vm.showNavigationArrows },
                      on: { click: _vm.reactToScreenChange }
                    }),
                    _c("Panels", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showPanel,
                          expression: "showPanel"
                        }
                      ],
                      style: { width: _vm.mainContentWidth + "px" },
                      on: {
                        generateCode: _vm.generateCode,
                        toggle: _vm.togglePanel
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }