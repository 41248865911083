<template>
  <div
    :style="{ height: maskSize.height * scaleValue + 'px', overflow: 'hidden' }"
    class="image-preview-container w-full"
  >
    <div class="flex items-center justify-center w-full" style="padding:20px" v-if="previewLoading">
      <svg-icon fill="currentColor" class="icon-spin" name="spin" :size="30"></svg-icon>
    </div>

    <div
      v-if="!isExternalImage && !previewLoading"
      :style="{
        width: maskSize.width + 'px',
        height: maskSize.height + 'px',
        transform: `scale(${scaleValue})`,
        transformOrigin: 'left top',
        maxWidth: scaleValue == 1 ? '100%' : 'unset'
      }"
      class="mask-container"
    ></div>

    <img
      :style="{
        transform: `scale(${scaleValue})`,
        transformOrigin: 'left top',
        maxWidth: scaleValue == 1 ? '100%' : 'unset'
      }"
      ref="previewImageRef"
      @load="updateMaskSize"
      @error="localLoading = false"
      v-show="src && !previewLoading"
      :src="previewSrc"
      alt=""
    />
    <div style="opacity:0.4" v-if="!loading && !src">
      Could not generate image preview
    </div>
  </div>
</template>

<script>
// import { get } from 'lodash-es';
import { mapGetters } from 'vuex';
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      timer: 0,
      maskSize: {
        width: 0,
        height: 0
      },
      scaleValue: 1,
      localLoading: false
    };
  },

  watch: {
    src(s) {
      if (s && !s.startsWith('data:image/')) {
        this.localLoading = true;
      } else {
        this.updateMaskSize();
      }
    }
  },

  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      isGeneratingCapture: 'omniview/isGeneratingCapture',
      isComponentOrSuggestionById: 'webComponents/isComponentOrSuggestionById',
      currentWebComponent: 'webComponents/currentWebComponent'
    }),
    previewLoading() {
      if (this.isExternalImage) return this.localLoading;
      return this.loading;
    },
    previewSrc() {
      if (this.isExternalImage) return `${this.src}?v=${new Date().getTime()}`;
      return this.src;
    },
    isExternalImage() {
      return !this.src?.startsWith('data:image/');
    }
  },
  methods: {
    async updateMaskSize() {
      let width, height;
      if (this.$refs.previewImageRef) {
        const { naturalWidth: w, naturalHeight: h } = this.$refs.previewImageRef;
        width = w;
        height = h;
        if (width && height) {
          this.maskSize = { width, height };
        }
        await this.$nextTick();
      }
      if ((height && height > 350) || (width && width > 320)) {
        this.scaleValue = +Math.min(350 / height, 320 / width).toFixed(2);
      } else {
        this.scaleValue = 1;
      }
      this.localLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
