var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "label" }, [_vm._v("Invite team members")]),
    _c("div", { staticClass: "invite-form", class: { focus: _vm.focusForm } }, [
      _c(
        "div",
        {
          staticClass: "invite-form-input",
          class:
            ((_obj = { focus: _vm.focusForm }), (_obj[_vm.$theme] = true), _obj)
        },
        [
          _c("an-input", {
            style: { width: "100%" },
            attrs: {
              placeholder: "Email address",
              size: "sm",
              noBorder: "",
              theme: _vm.$theme,
              focus: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.add.apply(null, arguments)
              },
              focus: function($event) {
                _vm.focusForm = true
              },
              blur: function($event) {
                _vm.focusForm = false
              }
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          }),
          _c("RoleSelect", {
            attrs: {
              role: _vm.newMemberRole.role,
              accessLevel: _vm.newMemberRole.access_level,
              isDeleted: !_vm.focusForm,
              withDelete: false,
              theme: _vm.$theme,
              allowAll: ""
            },
            on: {
              "change-role": _vm.onSelectRole,
              focus: function($event) {
                _vm.focusForm = true
              },
              blur: function($event) {
                _vm.focusForm = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { class: ["add-button", { "new-layout": _vm.newInviteLayout }] },
        [
          _vm.newInviteLayout
            ? _c(
                "an-button",
                { attrs: { variant: "secondary" }, on: { click: _vm.add } },
                [_vm._v("Invite")]
              )
            : _c("AddButton", {
                attrs: { submitted: _vm.submittedEmail, focus: _vm.focusForm },
                on: { click: _vm.add }
              })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "message" }, [
      _vm.isPayingTeam && _vm.contributorSelected
        ? _c("div", { staticClass: "disclaimer" }, [
            _vm._v(
              " Admin and Contributor roles require an additional paid seat. "
            )
          ])
        : _vm._e()
    ]),
    _vm.owners.length && _vm.showInviteWithLink
      ? _c("div", { staticClass: "invite-link" }, [
          _c(
            "div",
            { staticClass: "flex items-center space-between" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("Invite with link")]),
              _vm.newInviteLayout
                ? _c(
                    "an-link",
                    {
                      on: {
                        click: function($event) {
                          _vm.enableInviteWithLink = !_vm.enableInviteWithLink
                          _vm.toggleInviteLink()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.enableInviteWithLink ? "Disable" : "Enable"
                          ) +
                          " "
                      )
                    ]
                  )
                : _c("an-toggle", {
                    staticClass: "toggle-wrapper",
                    on: { change: _vm.toggleInviteLink },
                    model: {
                      value: _vm.enableInviteWithLink,
                      callback: function($$v) {
                        _vm.enableInviteWithLink = $$v
                      },
                      expression: "enableInviteWithLink"
                    }
                  })
            ],
            1
          ),
          _c("div", { staticClass: "flex items-center" }, [
            _vm.enableInviteWithLink
              ? _c(
                  "div",
                  { staticClass: "disclaimer flex items-center space-between" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c(
                          "PopoverMenu",
                          {
                            attrs: {
                              items: _vm.teamInviteLinkItems,
                              position: "right",
                              popoverStyle: { width: "124px" }
                            },
                            on: { click: _vm.updateInviteLink }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "invite-link-select",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Anyone with the link will join as a " +
                                      _vm._s(
                                        _vm.selectedTeamInviteLinkItem.label.toLowerCase()
                                      )
                                  )
                                ]),
                                _c("svg-icon", {
                                  attrs: { name: "select-arrow-down", size: 24 }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "an-link",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.copyInviteLink }
                      },
                      [_vm._v("Copy link")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm.owners.length
      ? _c(
          "div",
          { class: ["owner", _vm.$theme] },
          [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.ownersLabel))
            ]),
            _vm._l(_vm.owners, function(owner, index) {
              return _c("MemberRow", {
                key: "owner" + index,
                attrs: {
                  member: owner,
                  roleOptions: {
                    withAdmin: !_vm.hasSingleOwner,
                    withDelete: !_vm.hasSingleOwner
                  }
                },
                on: {
                  "change-role": _vm.onChangeOwnerRole,
                  remove: _vm.onRemove,
                  "resend-invite": _vm.onResendInvite
                }
              })
            })
          ],
          2
        )
      : _vm._e(),
    _vm.showMembersList
      ? _c("div", { staticClass: "members" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Team members")]),
          _c(
            "div",
            { staticClass: "rows" },
            _vm._l(_vm.members, function(member) {
              return _c("MemberRow", {
                key: "member-" + (member.id || member.email),
                attrs: {
                  member: member,
                  roleOptions: {
                    withAdmin: true,
                    withDelete: member.id.length !== _vm.tempIdLength
                  }
                },
                on: {
                  "change-role": _vm.onChangeRole,
                  remove: _vm.onRemove,
                  "resend-invite": _vm.onResendInvite
                }
              })
            }),
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }