<template>
  <div id="sections" class="sections">
    <Section
      @create="handleCreateSection"
      @delete="handleDeleteSection"
      @dropEnd="handleChildDropEnd"
      @copyWebLink="handleCopyWebLink"
      v-for="section in sectionsSorted"
      :key="section.id"
      :data-id="section.id"
      :section="section"
      :actions="actions"
      :items="items"
      ref="sections"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Section from './Section';
import { updateArrayItemById } from '@/utils/javascript';
import { getSortedItems, isPositionChanged, calculateSectionListPosition, getSortedSections } from '@/services/dnd';
import { uuid } from '@/utils/uuid';
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import copyToClp from '@/utils/copyToClp';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: true
    }
  },
  components: {
    Section
  },
  data() {
    return {
      prevSectionsState: {},
      sectionHighlight: {
        counter: null,
        count: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      sections: 'components/sections',
      allowSectionsEdit: 'components/allowSectionsEdit'
    }),
    sectionsSorted() {
      return getSortedSections(this.sections);
    }
  },
  methods: {
    ...mapMutations({
      setComponents: 'components/setComponents',
      selectScreen: 'components/setCurrentItem',
      setSections: 'components/setSections'
    }),

    ...mapActions({
      updateComponent: 'components/update',
      updateProject: 'projects/update',
      fetchProject: 'projects/fetchOne'
    }),
    handleCreateSection({ index, section }) {
      const { projectId } = this.$route.params;

      const newSectionId = uuid();
      const newSectionItemsIds = getSortedItems(this.items, section.id)
        .slice(index)
        .map(item => item.id);

      const updatedSections = [
        ...this.sections,
        {
          id: newSectionId,
          name: 'Untitled section',
          listPosition: this.sections.length + 1
        }
      ];

      this.setSections(updatedSections);
      this.updateProject({
        id: projectId,
        payload: {
          sections_sort: {
            sections: updatedSections
          }
        }
      }).then(() => {
        this.fetchProject({ id: projectId, skipCache: true });
      });

      const newItems = this.items.reduce(this.flattenLinkedScreens, []).map(item => {
        if (newSectionItemsIds.includes(item.id)) {
          return {
            ...item,
            sorting_section: { id: newSectionId }
          };
        }
        return item;
      });
      this.setComponents(newItems);
      newSectionItemsIds.forEach(id => {
        this.updateComponent({
          id,
          payload: {
            sorting_section: { id: newSectionId }
          }
        });
      });

      this.$nextTick(() => {
        const sectionEl = document.querySelector(`#section-${newSectionId}`);
        this.scrollSectionIntoView(sectionEl);
        const input = sectionEl.querySelector('.inline-edit');
        input.focus();
        input.select();
      });
    },
    handleDeleteSection(section) {
      const { projectId } = this.$route.params;
      const sectionItemsIds = getSortedItems(this.items, section.id).map(item => item.id);

      const removeIndex = this.sections.map(item => item.id).indexOf(section.id);
      const updatedSections = [...this.sections];
      updatedSections.splice(removeIndex, 1);

      this.setSections(updatedSections);

      this.updateProject({
        id: projectId,
        payload: {
          sections_sort: {
            sections: updatedSections
          }
        }
      }).then(() => {
        this.fetchProject({ id: projectId, skipCache: true });
      });

      const newItems = this.items.reduce(this.flattenLinkedScreens, []).map(item => {
        // if item was in the section move it to the default section
        if (sectionItemsIds.includes(item.id)) {
          return {
            ...item,
            sorting_section: { id: 'allId' }
          };
        }
        return item;
      });

      this.setComponents(newItems);
      sectionItemsIds.forEach(id => {
        this.updateComponent({
          id,
          payload: {
            sorting_section: { id: 'allId' }
          }
        });
      });
    },
    handleChildDropEnd({ destination }) {
      const { droppableId } = destination;
      let sectionRef = this.$refs.sections.find(el => el.section.id == droppableId);
      sectionRef.isOpen = true;
    },
    handleSectionDrop(sectionId, d, s) {
      if (!isPositionChanged(s, d)) return;
      const { projectId } = this.$route.params;

      const listPosition = calculateSectionListPosition(this.sections, d.index, sectionId);
      const sectionUpdateValues = {
        listPosition
      };
      const newSections = updateArrayItemById(this.sections, sectionId, sectionUpdateValues);

      this.setSections(newSections);

      this.updateProject({
        id: projectId,
        payload: {
          sections_sort: {
            sections: newSections
          }
        }
      }).then(() => {
        this.fetchProject({ id: projectId, skipCache: true });
      });
    },
    initSectionsSortableInstance() {
      const sortableContainer = document.querySelector('#sections');
      if (!sortableContainer) return;
      this.sortableInstane = new Sortable(sortableContainer, {
        handle: '.drag-handler',
        direction: 'vertical',
        disabled: !this.allowSectionsEdit,
        scroll: true,
        forceFallback: true,
        scrollSensitivity: 150,
        scrollSpeed: 20,
        dragClass: 'section-drag',
        ghostClass: 'section-ghost', // Class name for the drop placeholder
        chosenClass: 'section-chosen', // Class name for the chosen item
        // animation: 250,
        // easing: 'cubic-bezier(1, 0, 0, 1)',
        onStart: () => {
          this.prevSectionsState = {};
          // const sectionId = evt.item.getAttribute('data-id');

          this.$refs.sections.forEach(el => {
            this.prevSectionsState[el.section.id] = el.isOpen;
            el.isOpen = false;
          });

          const d = document.querySelector('.sortable-fallback').querySelector('.section-items-container');
          d.style.display = 'none';
        },
        onEnd: async evt => {
          const sectionId = evt.item.getAttribute('data-id');
          const droppableId = 'sections'; // does not matter since the source is the same as the destination
          const source = {
            droppableId,
            index: evt.oldIndex
          };
          const destination = {
            droppableId,
            index: evt.newIndex
          };
          this.handleSectionDrop(sectionId, destination, source);
          await this.$nextTick();
          let el = this.$refs.sections.find(el => el.section.id == sectionId);
          el.isOpen = this.prevSectionsState[sectionId]; // or true !
        }
      });
    },
    scrollSectionIntoView(sectionEl) {
      if (sectionEl) {
        sectionEl.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    handleCopyWebLink(section) {
      const url = `https://projects.animaapp.com${this.$route.path}?sort=sections&section=${section.id}`;
      copyToClp(url);
    },
    highlightSection(sectionEl) {
      if (!sectionEl) {
        clearInterval(this.sectionHighlight.counter);
        return;
      }
      if (this.sectionHighlight.count <= 0) {
        clearInterval(this.sectionHighlight.counter);
        sectionEl.style.removeProperty('background');
        return;
      }
      sectionEl.style.background = 'rgba(216, 216, 216,' + Math.max(0, this.sectionHighlight.count.toFixed(1)) + ')';
      // sectionEl.style.background = "red";
      this.sectionHighlight.count -= 0.1;
    },
    moveToSection() {
      const sectionId = this.$route.query.section;
      if (!sectionId) return;
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.$refs.sections) return;
          const sectionEl = this.$refs.sections.find(c => c.section.id == sectionId).$el;
          this.scrollSectionIntoView(sectionEl);
          this.sectionHighlight.count = 3;
          setInterval(() => this.highlightSection(sectionEl), 50);
        }, 1000);
      });
    },
    flattenLinkedScreens(acc, currentItem) {
      const { linkedScreens = [] } = currentItem;
      return [...acc, currentItem, ...linkedScreens];
    }
  },
  mounted() {
    this.initSectionsSortableInstance();
    this.moveToSection();
  },
  watch: {
    allowSectionsEdit(allow) {
      this.sortableInstane && this.sortableInstane.option('disabled', allow);
    }
  }
};
</script>

<style scoped>
.sections {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.d-container {
  width: 100%;
  /* margin: 0 auto; */
  text-align: left;
}
.sortable-ghost {
  opacity: 0.5;
}

.section-drag {
  opacity: 0.5 !important;
}

// .section-ghost {
//  background: green !important;
// }
.section-chosen {
  .section-header {
    background: rgba(#d8d8d8, 0.1) !important;
  }
}
</style>
