var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-input-container" },
    [
      _c(
        "div",
        {
          staticClass: "clickable-icon",
          on: {
            click: function($event) {
              return _vm.toggleOpen(!_vm.isOpen)
            }
          }
        },
        [_c("svg-icon", { attrs: { name: "search", size: 24 } })],
        1
      ),
      _c("transition", { attrs: { name: "search-input" } }, [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "input" },
              [
                _c("an-input", {
                  attrs: {
                    size: "sm",
                    placeholder: "Search",
                    noBorder: "",
                    focus: ""
                  },
                  on: { input: _vm.onValueChange, blur: _vm.onBlur },
                  model: {
                    value: _vm.innerValue,
                    callback: function($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }