<template>
  <div class="horizontal-layout-container">
    <div v-if="items.length">
      <transition-group name="slide-card" tag="div" v-if="itemsType === 'project'" class="horizontal-list">
        <ProjectRow
          v-for="item in items"
          :key="item.id"
          :project="item"
          :actions="actions"
          :members="members"
          :subtitle="item.subtitle"
          @click="$emit('click-project', item)"
        />
      </transition-group>
    </div>

    <Pagination
      v-if="shouldShowPagination"
      :itemsType="itemsType"
      :currentAmount="items.length"
      :totalAmount="totalAmount"
      :isLoadingMore="isLoadingMore"
      @load-more="$emit('load-more')"
    />
  </div>
</template>

<script>
import ProjectRow from '@/components/Project/ProjectRow';
import Pagination from '@/components/Pagination/Pagination';

export default {
  props: {
    itemsType: {
      type: String,
      required: true,
      validator: value => ['screen', 'project'].includes(value)
    },
    items: {
      type: Array,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    members: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: 'updated'
    },
    forceShowActions: {
      type: Boolean,
      default: false
    },
    filteredResults: {
      type: Boolean,
      default: false
    },
    isLoadingMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProjectRow,
    Pagination
  },
  computed: {
    shouldShowPagination() {
      const { totalAmount, items, filteredResults } = this;
      return items.length < totalAmount && !filteredResults;
    }
  }
};
</script>

<style lang="scss" scoped>
.horizontal-layout-container {
  margin-bottom: 100px;
}
.horizontal-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
</style>
