var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column",
      class:
        ((_obj = { "current-plan": _vm.isCurrentPlan }),
        (_obj[_vm.theme] = true),
        _obj)
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.plan.name))]),
      _c("div", { staticClass: "price" }, [
        _vm._v(
          _vm._s(
            _vm.plan.name === "Team" ? _vm.formatTeamPrice : _vm.formattedPrice
          )
        )
      ]),
      _c("div", { staticClass: "price-details" }, [
        _vm.plan.name === "Team"
          ? _c("span", [
              _vm._v(
                " Starts at " +
                  _vm._s(_vm.formattedPrice) +
                  " per user / month "
              ),
              _c("br"),
              _vm._v(
                " with " + _vm._s(_vm.plan.minQuantity) + " seats included "
              )
            ])
          : _c("span", [
              _vm._v(" Per user / month "),
              _c("br"),
              _vm._v(" billed " + _vm._s(_vm.billingCycle) + "ly ")
            ])
      ]),
      _c(
        "an-button",
        {
          staticClass: "cta",
          attrs: {
            "data-cy": "pricing-column-cta",
            variant: "primary",
            disabled: _vm.isCurrentPlan
          },
          on: { click: _vm.onClick }
        },
        [_vm._v(" " + _vm._s(_vm.ctaButtonLabel) + " ")]
      ),
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "description" }, [
        _vm._v(" " + _vm._s(_vm.plan.description) + " ")
      ]),
      _c(
        "div",
        { staticClass: "features-list" },
        [
          _c(
            "div",
            {
              staticClass: "quirky-scratch-container",
              class: { invisible: !_vm.plan.scratchFirstFeature }
            },
            [_vm._v(" Unlimited "), _c("div", { staticClass: "scratch" })]
          ),
          _vm._l(_vm.plan.features, function(feature) {
            return _c(
              "div",
              {
                key: feature.label,
                staticClass: "features-list-item",
                class: { inactive: !feature.isActive }
              },
              [
                _c("svg-icon", {
                  attrs: { name: _vm.getIconName(feature), size: 24 }
                }),
                _c("div", [_vm._v(_vm._s(feature.label))])
              ],
              1
            )
          })
        ],
        2
      ),
      _vm.ribbonName
        ? _c(
            "div",
            { staticClass: "ribbon" },
            [
              _c("svg-icon", {
                attrs: { name: _vm.ribbonName, width: 109, height: 109 }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }