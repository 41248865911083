<template>
  <div class="project-guests-container">
    <div class="guests-invite inviteLayout">
      <div class="separated inviteLayout">
        <div v-if="inviteLayout" class="invite-description">
          Invite guests only to this project to view prototypes &amp; leave<br />
          comments. Ideal for external users and clients.
        </div>
        <div class="label">Invite project guests</div>
        <div class="guests-input-form">
          <an-input
            placeholder="Email address"
            v-model="email"
            @keyup.enter="addGuest"
            @focus="focusForm = true"
            @blur="focusForm = false"
          />
          <div class="add-button">
            <AddButton @click="addGuest" :submitted="submitted" :focus="focusForm" />
          </div>
        </div>
        <div class="secondary-text margin-top" v-if="!inviteLayout">
          Guests only have access to projects they’re invited to. Guests can view prototypes and leave comments. Ideal
          for external users and clients. You can invite as many guests as you'd like for free.
        </div>
      </div>
      <div class="rows">
        <GuestRow v-for="guest in guests" :key="guest.id" :guest="guest" @remove="removeGuest(guest)" />
      </div>
    </div>
    <div class="team-members-disclaimer" v-if="!inviteLayout">
      <div class="label">Team members</div>
      <div class="text-container">
        This project is accessible to all members of team {{ team.name }}
        <div>
          <an-link @click="goToTeamSettings" variant="primary">Manage Team Members</an-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { mapState, mapActions } from 'vuex';
import { isValidEmail, normalizeEmail } from '@/utils/email';
import GuestRow from '@/components/Project/Settings/GuestRow';
import AddButton from '@/components/Button/AddButton';
import { uuid } from '@/utils/uuid';
import { toastError, toastSuccess, openModal } from '@/services/bus';
import { deleteArrayItemById } from '@/utils/javascript';

export default {
  data() {
    return {
      email: '',
      guests: [],
      focusForm: false,
      submitted: false,
      submittedInterval: null
    };
  },
  props: {
    inviteLayout: {
      type: Boolean,
      default: false
    },
    onCloseRedirect: {
      type: Object,
      default: () => null
    },
    eventSource: {
      type: String
    }
  },
  components: {
    GuestRow,
    AddButton
  },
  mounted() {
    this.reloadGuests({ skipCache: false });
  },
  computed: {
    ...mapState('projectGuests', { _guests: 'items' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { project: 'currentItem' }),
    hasChanges() {
      return false;
    },
    isDeleted() {
      const { deletedNumber, deleteGuests } = this;
      return ({ email = '' }) => !!(deletedNumber && deleteGuests.has(email.toLowerCase()));
    },
    eventProps() {
      const { eventSource } = this;
      return { eventSource };
    }
  },
  methods: {
    ...mapActions({
      createGuest: 'projectGuests/create',
      deleteGuest: 'projectGuests/delete',
      fetchGuests: 'projectGuests/fetchAllOfParent'
    }),
    reset() {
      this.guests = cloneDeep(this._guests).reverse();
    },
    goToTeamSettings() {
      const { onCloseRedirect } = this;
      openModal({ name: 'team-settings-members', onCloseRedirect });
    },
    async addGuest() {
      const email = normalizeEmail(this.email);
      const alreadyExists = this.guests.some(g => normalizeEmail(g.email) === email);

      this.$trackEvent('project-settings-guests.invite-guest.click', this.eventProps);

      if (!email) {
        return toastError('Please insert an email first');
      } else if (!isValidEmail(email)) {
        return toastError('Oops! This email is invalid!');
      } else if (alreadyExists) {
        return toastError('This user is already a member 🤔');
      }

      const { short_id: projectId } = this.project;
      const newGuest = { email, access_level: 'viewer' };
      const id = uuid();

      if (this.submittedInterval) {
        clearTimeout(this.submittedInterval);
        this.submittedInterval = null;
      }

      try {
        this.submitted = true;
        this.submittedInterval = setTimeout(() => (this.submitted = false), 3000);

        this.guests.unshift({ id, ...newGuest });
        let email = this.email;
        this.email = '';
        await this.createGuest({ parent: 'projects', id: projectId, payload: newGuest });

        toastSuccess(`Sent an email invitation to ${email}`);

        this.$trackEvent('project-settings-guests.invite-guest.success'), this.eventProps;
        this.reloadGuests();
      } catch (err) {
        this.$trackEvent('project-settings-guests.invite-guest.failure', { message: err.message });

        // undo UI changes
        this.guests = deleteArrayItemById(this.guests, id);
        const message = err?.data?.message || 'We encountered a problem adding this guest :(';
        toastError(message);
      }
    },
    async removeGuest(guest) {
      try {
        this.$trackEvent('project-settings-guests.remove-guest.click', this.eventProps);
        this.guests = deleteArrayItemById(this.guests, guest.id);

        await this.deleteGuest(guest);

        this.$trackEvent('project-settings-guests.remove-guest.success', this.eventProps);
        this.reloadGuests();
      } catch (err) {
        this.$trackEvent('project-settings-guests.remove-guest.failure', { message: err.message });

        // undo UI changes
        this.guests.unshift(this.guests, guest.id);

        toastError('We encountered a problem removing this guest :(');
      }
    },
    reloadGuests({ skipCache = true } = {}) {
      const { short_id: projectId } = this.project;
      this.fetchGuests({
        parent: 'projects',
        id: projectId,
        params: { page_size: 200, order_by: 'created_at' },
        skipCache
      });
    }
  },
  watch: {
    _guests: {
      handler: 'reset',
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.project-guests-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.team-members-disclaimer {
  margin-bottom: 30px;
}
.guests-invite {
  margin-bottom: 30px;
}
.guests-input-form {
  display: flex;
  align-items: center;
  padding-right: 12px;
  .add-button {
    height: 44px;
    width: 44px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 1s ease;
    &.dark:hover {
      opacity: 1;
      background-color: var(--white);
    }
  }
}
.invite-description {
  margin-bottom: 40px;
}
.separated.inviteLayout {
  padding-top: 0;
  border-top: 0;
}
.team-row-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
}
.margin-top {
  margin-top: 20px;
}
.selectable {
  padding: 15px 20px;
  cursor: pointer;
}
.selectable:hover,
.selected {
  background-color: var(--light-container-background);
}
.rows {
  max-height: 308px;
  overflow-y: auto;
}
.warn {
  margin-top: 20px;
  color: var(--primary);
}
</style>
