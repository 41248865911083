<template>
  <div class="flex flex-col code-pref-col">
    <div class="pref-col-title">{{ title }}</div>
    <an-radio
      v-for="op in options"
      :key="op.label"
      full
      :spacingX="6"
      :spacingY="2"
      type="dot"
      :label="op.value"
      :value="value"
      @change="$emit('change', $event)"
    >
      <span style="font-size:12px">{{ op.label }}</span>
      <span class="soon badge" v-if="op.badgeText">{{ op.badgeText }}</span>
    </an-radio>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.pref-col-title {
  font-size: 12px;
  padding: 0 4px;
  margin-bottom: 6px;
  opacity: 0.4;
}
.badge {
  border-radius: 100px;
  padding: 0 6px;
  height: 16px;
  display: flex-inline;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  &.soon {
    background: #009379;
    margin-left: 5px;
  }
}
</style>
