var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PopoverMenu",
    {
      attrs: {
        items: _vm.roleOptions,
        position: "right",
        popoverStyle: { width: "124px" },
        theme: _vm.$theme
      }
    },
    [
      _c(
        "div",
        {
          ref: "select",
          staticClass: "select",
          class: { isDeleted: _vm.isDeleted },
          attrs: { slot: "reference", tabindex: "-1" },
          on: {
            focus: function($event) {
              return _vm.$emit("focus")
            },
            blur: function($event) {
              return _vm.$emit("blur")
            }
          },
          slot: "reference"
        },
        [
          _vm._v(" " + _vm._s(_vm.prettyRole) + " "),
          _c("svg-icon", { attrs: { name: "select-arrow-down", size: 24 } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }