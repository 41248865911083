<template>
  <div>
    <div class="label">Project name</div>
    <div class="input"><an-input v-model="name" /></div>
    <div class="label">Current team</div>
    <div class="separated">
      <div class="team-row-container pad-left">
        <TeamRow :team="team" :key="team.id" />
        <an-link variant="primary" @click="changeTeam = !changeTeam">
          {{ changeTeam ? 'Close' : 'Change' }}
        </an-link>
      </div>
      <div v-if="changeTeam">
        <div class="label pad-left move-label">Move to</div>
        <div class="teams-list">
          <div :class="teamClasses(t)" v-for="(t, index) in teams" :key="index" @click="selectedTeam = t">
            <TeamRow :team="t" :key="t.slug" />
          </div>
        </div>
        <div v-if="selectedTeam.id" class="warn">
          All current team members will lose access to this project and it’ll be fully accessible to “{{
            selectedTeam.name
          }}” team members.
        </div>
      </div>
    </div>
    <div class="separated">
      <an-link @click="archive">Archive project</an-link>
    </div>
    <div class="separated">
      <an-link @click="remove">Delete project</an-link>
    </div>
    <transition name="fade">
      <div class="actions fixed" v-if="hasChanges">
        <an-button @click="save" :isWorking="isWorking">Save changes</an-button>
        <an-link @click="cancel">Cancel</an-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import TeamRow from '@/components/Team/TeamRow';
import { EventBus, toastError, toastSuccess } from '@/services/bus';

export default {
  data() {
    return {
      name: '',
      changeTeam: false,
      selectedTeam: {},
      isWorking: false
    };
  },
  components: {
    TeamRow
  },
  mounted() {
    this.reset();
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    ...mapState('teams', { team: 'currentItem' }),
    ...mapGetters('teamMemberships', { teamMemberships: 'userTeams' }),
    teams() {
      const { id: currentTeamId } = this.team;
      return this.teamMemberships.filter(tm => tm.id !== currentTeamId);
    },
    hasChanges() {
      return this.name !== this.project.name || this.selectedTeam.id;
    }
  },
  methods: {
    ...mapActions({ updateProject: 'projects/update', reloadProject: 'projects/fetchOne' }),
    ...mapMutations({ selectProject: 'projects/setCurrentItem' }),
    reset() {
      this.name = this.project.name;
      this.changeTeam = false;
      this.selectedTeam = {};
    },
    teamClasses(team) {
      return {
        'pad-left': true,
        'team-row-container': true,
        selectable: true,
        selected: team.id === this.selectedTeam.id
      };
    },
    archive() {
      this.$trackEvent('project-settings-general.archive-project-button.click');
      this.selectProject(this.project);
      this.$router.push({ name: 'archive-project' });
    },
    remove() {
      this.$trackEvent('project-settings-general.delete-project-button.click');
      this.selectProject(this.project);
      this.$router.push({ name: 'delete-project' });
    },
    async save() {
      const { id, short_id: projectId } = this.project;
      const { name, selectedTeam } = this;
      this.isWorking = true;

      this.$trackEvent('project-settings-general.save-button.click');

      if (!name.length) {
        this.isWorking = false;
        return toastError('Please provide a name');
      } else if (name !== this.project.name) {
        try {
          await this.updateProject({ id, payload: { name } });
        } catch (err) {
          toastError('Failed changing project name');
        }
      }

      if (selectedTeam?.id && selectedTeam.id !== this.team.id) {
        try {
          const payload = { team: { id: selectedTeam.id, type: 'teams' } };
          await this.updateProject({ id, payload });
          this.$router.replace({ name: 'project-settings', params: { teamSlug: selectedTeam.slug, projectId } });
        } catch (err) {
          toastError('Failed moving project to another team');
        }
      }

      EventBus.$emit('reload-project-data');
      this.isWorking = false;

      toastSuccess('Project was successfully updated.');
      this.reset();
    },
    cancel() {
      this.$trackEvent('project-settings-general.cancel.click');
      this.reset();
    }
  },
  watch: {
    project({ name }) {
      this.name = name;
    },
    changeTeam(newValue) {
      if (newValue) {
        this.$trackEvent('project-settings-general.change-team.click');
      } else {
        this.selectedTeam = {};
        this.$trackEvent('project-settings-general.close-change-team.click');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
.team-row-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
}
.teams-list {
  max-height: 308px;
  overflow-y: auto;
}
.pad-left {
  padding-left: 20px;
}
.move-label {
  margin: 30px 0;
}
.selectable {
  padding: 15px 20px;
  cursor: pointer;
}
.selectable:hover,
.selected {
  background-color: var(--light-container-background);
}
.warn {
  margin-top: 20px;
  color: var(--primary);
}
</style>
