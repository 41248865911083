<template>
  <transition name="fade" mode="out-in">
    <LoadingScreen v-if="loading" />
    <component
      :is="listComponent"
      v-else-if="!!projects.length"
      itemsType="project"
      :items="projects"
      :actions="actions"
      :members="members"
      :forceShowActions="isArchive"
      :filteredResults="!!searchQuery"
      :totalAmount="totalProjects"
      :isLoadingMore="loadingMore"
      @load-more="loadMore"
      @click-project="onClick"
      withCta
    />
    <NoSearchResults v-else-if="!!searchQuery" />
    <EmptyState v-else :type="type" />
  </transition>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import CardsGrid from '@/components/CardsGrid/CardsGrid';
import HorizontalLayout from '@/components/CardsGrid/HorizontalLayout';
import NoSearchResults from '@/components/Team/EmptyState/NoSearchResults';
import EmptyState from '@/components/Team/EmptyState/EmptyState';
import { EventBus, openModal, toastError } from '@/services/bus';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import { formatDate, formatDateTimeConversational } from '@/utils/date';
import { filterArrayBySearchQuery } from '@/utils/javascript';
import { TeamMixin, SocketMixin } from '@/mixins';
import api from '@/api';

export default {
  data() {
    return {
      actions: [
        { label: 'Archive', onClick: this.archive },
        { label: 'Delete', isSeparated: true, isMarked: true, onClick: this.remove }
      ],
      withCTA: false,
      loading: false,
      loadingMore: false
    };
  },
  props: {
    type: {
      type: String,
      default: 'all',
      validator: value => ['all', 'archive', 'shared'].includes(value)
    },
    shouldUpdate: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: 'grid'
    }
  },
  mixins: [TeamMixin, SocketMixin],
  components: {
    LoadingScreen,
    NoSearchResults,
    EmptyState
  },
  mounted() {
    EventBus.$on('update-projects', this.fetchData);
  },
  destroyed() {
    EventBus.$off('update-projects', this.fetchData);
  },
  computed: {
    ...mapState('teams', { team: 'currentItem' }),
    ...mapState('projects', { _projects: 'items' }),
    ...mapState('projects', { totalProjects: 'total' }),
    ...mapState('projects', ['sharedProjects']),
    ...mapState('teamMemberships', { teamMemberships: 'team' }),
    ...mapGetters({
      hasNewComments: 'notifications/hasNewComments',
      hasPermissions: 'teamMemberships/hasPermissions'
    }),
    projects() {
      const { sort } = this.$route.query;
      const generateSubtitle = this.getSubtitleCallback(sort);
      let list =
        this.type === 'shared'
          ? this.sharedProjects.map(s => {
              return { ...s, project: null, ...s.project };
            })
          : this._projects;
      list = list.map(p => ({
        ...p,
        subtitle: generateSubtitle(p),
        flags: {
          domain: p.domain_project_release,
          hasNewComments: this.hasNewComments({ id: p.short_id, type: 'project' }),
          views: false
        }
      }));
      if (this.searchQuery) {
        list = filterArrayBySearchQuery(list, ['name'], this.searchQuery);
      }
      return list;
    },
    members() {
      return this.type === 'shared' ? [] : this.teamMemberships;
    },
    isArchive() {
      return this.type === 'archive';
    },
    listComponent() {
      if (this.isMobile || this.layout === 'grid') return CardsGrid;
      else return HorizontalLayout;
    }
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchAllOfParent',
      fetchSharedProjects: 'projects/fetchSharedProjects',
      updateProject: 'projects/update'
    }),
    ...mapMutations({
      selectProject: 'projects/setCurrentItem',
      addProject: 'projects/unshiftItems',
      modifyProject: 'projects/editFromItems',
      removeProject: 'projects/removeFromItems'
    }),
    reset() {
      const { type } = this;
      const { teamSlug } = this.$route.params;
      const isOwner = this.hasPermissions({ teamSlug, role: 'owner' });
      const deleteAction = { label: 'Delete', isSeparated: true, isMarked: true, onClick: this.remove };

      this.withCTA = type === 'all';
      switch (type) {
        case 'archive':
          this.actions = [{ label: 'Restore', onClick: this.restore }];
          if (isOwner) {
            this.actions.push(deleteAction);
          }
          break;
        case 'shared':
          this.actions = [];
          break;
        case 'all':
        default:
          this.actions = [
            { label: 'Archive', onClick: this.archive },
            { label: 'Move', onClick: this.move },
            deleteAction
          ];
      }
    },
    onRouteChange() {
      this.reset();
      this.fetchData();
    },
    getSortingType(sortBy) {
      const sortMap = {
        updated: '-updated_at',
        created: '-created_at',
        name: 'name'
      };
      return sortMap[sortBy] || sortMap.updated;
    },
    getSubtitleCallback(sortBy) {
      const subtitleActions = {
        updated: p => formatDateTimeConversational(p.updated_at),
        created: p => formatDate(p.created_at),
        name: p => `${p.components_count || 0} screens`
      };
      return subtitleActions[sortBy] || subtitleActions.updated;
    },
    async fetchData({ skipCache = false, nextPage = false, showLoading = true } = {}) {
      const { teamSlug: id } = this.$route.params;
      const { sort } = this.$route.query;
      const params = {
        is_slug: true,
        page_size: 30,
        order_by: this.getSortingType(sort)
      };

      if (this.type === 'archive') params.is_archived = true;

      try {
        if (showLoading) {
          if (nextPage) this.loadingMore = true;
          else this.loading = true;
        }
        if (this.type === 'shared') {
          await this.fetchSharedProjects({ params });
        } else {
          await this.fetchProjects({
            parent: 'teams',
            id,
            params,
            nextPage,
            skipCache: skipCache || this.shouldUpdate
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    },
    loadMore() {
      this.$trackEvent('team-page.load-more.click');
      this.fetchData({ nextPage: true });
    },
    onClick(project) {
      this.$trackEvent('project-card.thumbnail.click');
      if (this.type === 'archive') {
        toastError('Please restore the project in order to open it');
      } else {
        this.navigateToProject(project);
      }
    },
    navigateToProject(project) {
      const { teamSlug: teamSlug } = this.$route.params;
      const { short_id: projectId } = project;
      this.selectProject(project);
      if (project.is_locked) {
        this.$trackEvent('team-page.locked-project-paywall.show');
        this.openUpgradeLockedModal();
      } else {
        this.$router.push({ name: 'project', params: { projectId, teamSlug } });
      }
    },
    async restore({ id }) {
      const { teamSlug } = this.$route.params;
      const payload = { is_archived: false };
      try {
        this.$trackEvent('project-card.quick-action-restore.click');
        this.loading = true;
        await this.updateProject({ id, payload });
        api.clearCacheOf(`${teamSlug}/projects`);
        await this.fetchData();
        EventBus.$emit('reload-team-info');
      } catch (err) {
        toastError('Failed restoring project');
      } finally {
        this.loading = false;
      }
    },
    archive(project) {
      this.$trackEvent('project-card.quick-action-archive.click');
      this.selectProject(project);
      openModal({ name: 'archive-project', onCloseRedirect: { name: 'team' } });
    },
    move(project) {
      this.$trackEvent('project-card.quick-action-move.click');
      this.selectProject(project);
      openModal({ name: 'move-project', onCloseRedirect: { name: 'team' } });
    },
    remove(project) {
      this.$trackEvent('project-card.quick-action-delete.click');
      this.selectProject(project);
      openModal({ name: 'delete-project', onCloseRedirect: { name: 'team' } });
    },
    setSocket() {
      this.openSocket(this.team?.id);
      if (!this.socket) return;
      this.socket.on({ resource: 'project' }, (project, action) => {
        switch (action) {
          case 'created':
            this.addProject(project);
            break;
          case 'updated':
            this.modifyProject(project);
            break;
          case 'deleted':
            this.removeProject(project);
            break;
          default:
            break;
        }
        this.fetchData({ skipCache: true, showLoading: false });
      });
    }
  },
  watch: {
    $route: {
      handler: 'onRouteChange',
      immediate: true
    },
    hasPermissions: 'reset',
    team: {
      handler: 'setSocket',
      immediate: true
    }
  }
};
</script>
