<template>
  <Avatar
    :key="user.profile_picture_url || user.email"
    :src="avatarImageURL"
    :text="avatarText"
    :backgroundColor="backgroundColor"
    :borderColor="borderColor"
    :size="size"
    :noBorder="noBorder"
    @click="onClick"
  />
</template>

<script>
import { sample } from 'lodash-es';
import Avatar from '@/components/Avatar/Avatar';
import { getColorForUser } from '@/utils/colors';
import { getCdnUrl } from '@/utils/urls';

export default {
  name: 'an-user-avatar',
  data() {
    return {
      bgColor: null
    };
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 36
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light',
      validator: value => ['dark', 'light'].includes(value)
    }
  },
  components: {
    Avatar
  },
  computed: {
    avatarImageURL() {
      let { profile_picture_url: url } = this.user;
      return getCdnUrl(url, { size: this.size, pos: 'centre' });
    },
    avatarText() {
      const { name, email } = this.user;
      if (name) {
        const initialsMatch = name?.match(/\b\w/g) || [];
        const firstLetter = initialsMatch.shift() || '';
        const lastLetter = initialsMatch.pop() || '';
        if (firstLetter && lastLetter) {
          return `${firstLetter}${lastLetter}`;
        }
      }
      if (email) {
        return email;
      }

      return sample(['😄', '😋', '🥳', '👾', '👽', '🐵', '🐔', '🦄', '🐣', '🐙']);
    },
    backgroundColor() {
      const { color, email } = this.user;
      return color || getColorForUser(email || '');
    },
    borderColor() {
      const { theme } = this;
      if (theme === 'dark') {
        return 'var(--dark-border)';
      }
      return 'var(--white)';
    }
  },
  methods: {
    onClick(data) {
      this.$emit('click', data);
    }
  }
};
</script>
