var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.userTeams, function(team) {
      return _c("div", { key: team.id, staticClass: "team-details" }, [
        _c(
          "div",
          { staticClass: "team-name", attrs: { "data-cy": "team-name" } },
          [_vm._v(_vm._s(team.name))]
        ),
        _c("div", { staticClass: "plan-details" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.fixTeamPlan(team)) + " ")]),
          _c(
            "div",
            [
              _c(
                "an-button",
                {
                  attrs: {
                    "data-cy": "subscriptions-upgrade-team-btn",
                    variant: _vm.buttonVariant(team)
                  },
                  on: {
                    click: function($event) {
                      return _vm.onPlanCtaClick(team)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.buttonText(team)) + " ")]
              )
            ],
            1
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }