var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    !_vm.isOnboardingFlow
      ? _c("div", { staticClass: "title" }, [_vm._v("Invite members to team")])
      : _vm._e(),
    !_vm.isOnboardingFlow
      ? _c("div", { staticClass: "subtitle" }, [
          _vm._v("Add or manage your team members and roles")
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "body" },
      [_c("MembersForm", { attrs: { showInviteWithLink: "" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }