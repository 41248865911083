<template>
  <div :class="['banner-container', variant]">
    <slot />
    <div ref="closeButton" class="clickable-icon" @click="onClose">
      <svg-icon name="close" />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/bus';
export default {
  name: 'an-banner',
  props: {
    variant: {
      type: String,
      default: 'fail',
      validator: v => ['success', 'fail', 'warn', 'info'].includes(v)
    }
  },
  data() {
    return { hover: false, color: 'var(--secondary)' };
  },
  methods: {
    onClose() {
      EventBus.$emit('close-banner');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 18px;
  width: 100%;
  height: 60px;
  text-align: center;
  &.fail {
    background-color: rgba(242, 191, 175, 0.2);
  }
  &.success {
    background-color: rgba(0, 147, 121, 0.2);
  }
  &.warn {
    background-color: rgba(248, 213, 126, 0.2);
  }
  &.info {
    background-color: rgba(51, 102, 255, 0.2);
  }
}
.clickable-icon {
  position: absolute;
  top: 18px;
  right: 18px;
  height: 24px;
  width: 24px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
</style>
