var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !_vm.isSuggestions
        ? [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "interface-body flex items-center" },
              [
                _c(
                  "div",
                  { staticClass: "frame" },
                  [
                    _c("ComponentFrame", {
                      attrs: { padding: 20, name: "componentIframeInterface" }
                    })
                  ],
                  1
                ),
                _c("PropsInterface", {
                  attrs: {
                    componentName: _vm.componentName,
                    regularProps: _vm.regularPropsCopy,
                    nestedProps: _vm.nestedPropsCopy,
                    propsLoading: _vm.propsLoading
                  },
                  on: {
                    "update-component-name": _vm.handleUpdateComponentName,
                    "update-component-prop-enable": _vm.handleUpdatePropEnable,
                    "update-component-prop-name": _vm.handleUpdatePropName,
                    onPropsLoading: _vm.onPropsLoading
                  }
                })
              ],
              1
            )
          ]
        : [
            !_vm.isReadyToStart
              ? [
                  _vm._m(1),
                  _c("div", { staticClass: "flex items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-full flex items-center justify-center"
                      },
                      [
                        _c(
                          "an-button",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { variant: "empty" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_vm._v("No Thanks")]
                        ),
                        _c(
                          "an-button",
                          { on: { click: _vm.handleReadyToStart } },
                          [_vm._v("Review suggestions")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col items-center",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [
                      _vm.suggestionIndex > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "flex items-center back",
                              on: { click: _vm.back }
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  fill: "currentColor",
                                  name: "arrow-left",
                                  size: 20
                                }
                              }),
                              _c(
                                "an-button",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { variant: "empty" }
                                },
                                [_vm._v("Back")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "header" }, [
                        _vm._v("Review component suggestions")
                      ]),
                      _c("div", { staticClass: "sub-header" }, [
                        _vm._v(
                          " We have a few suggestions for nested components. Edit props, prop names, and approve or dismiss the suggestions. "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "interface-body flex items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "frame" },
                        [
                          _c("ComponentFrame", {
                            attrs: { name: "suggestedComponentIframe" }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "w-full flex items-center",
                      staticStyle: { "margin-top": "30px" }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "Suggestion " +
                            _vm._s(_vm.suggestionIndex + 1) +
                            "/" +
                            _vm._s(_vm.suggestedNodeIds.length)
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center",
                          staticStyle: { "margin-left": "auto" }
                        },
                        [
                          _c(
                            "an-button",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: { variant: "empty" }
                            },
                            [_vm._v("Not a component")]
                          ),
                          _c("an-button", { on: { click: _vm.next } }, [
                            _vm._v("Approve")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col items-center",
        staticStyle: { "margin-bottom": "20px" }
      },
      [
        _c("div", { staticClass: "header" }, [
          _vm._v("Edit component interface")
        ]),
        _c("div", { staticClass: "sub-header" }, [
          _vm._v(
            " Choose which parts of the component will be props and change prop names. "
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col items-center",
        staticStyle: { "margin-bottom": "20px" }
      },
      [
        _c("div", { staticClass: "header" }, [
          _vm._v("We’ve found some nested components")
        ]),
        _c("div", { staticClass: "sub-header" }, [
          _vm._v(
            " We recommend you’ll take 30 sec to approve inner components, so we’ll produce your code nice and clean. Ready? "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }