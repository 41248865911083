// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/chevron-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".an-select[data-v-037c1e0f] {\n  position: relative;\n  text-align: left;\n  outline: none;\n}\n.selected[data-v-037c1e0f] {\n  display: flex;\n  align-items: center;\n  height: inherit;\n  justify-content: space-between;\n  text-indent: 10px;\n  font-weight: 400;\n  border-radius: 2px;\n  border: 1px solid #ebeef5;\n  color: #272833;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n.selected.placeholder[data-v-037c1e0f] {\n  font-size: 14px;\n  color: #c1c7cd;\n}\n.selected.open[data-v-037c1e0f] {\n  border-radius: 2px;\n  border: 1px solid #409eff;\n  transition: border-color 0.2s linear;\n}\n.selected[data-v-037c1e0f]::after {\n  content: \"\";\n  width: 32px;\n  height: 32px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.options[data-v-037c1e0f] {\n  color: #272833;\n  border-radius: 2px;\n  overflow: hidden;\n  border-color: #ebeef5;\n  border-width: 0px 1px 1px 1px;\n  border-style: solid;\n  position: absolute;\n  background-color: #ffffff;\n  left: 0;\n  right: 0;\n  z-index: 1000;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n  text-indent: 21px;\n  max-height: 192px;\n  overflow: auto;\n}\n.option[data-v-037c1e0f] {\n  color: #272833;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  height: 32px;\n  line-height: 32px;\n  display: flex;\n  align-items: center;\n}\n.option[data-v-037c1e0f]:hover {\n  background-color: #ecf5ff;\n}\n.option .check-icon[data-v-037c1e0f] {\n  color: #409eff;\n  margin: 0 15px;\n}\n.hide[data-v-037c1e0f] {\n  display: none;\n}", ""]);
// Exports
module.exports = exports;
