<template>
  <div class="container" :class="containerClass">
    <h1 class="title">Import from Sketch</h1>
    <h2 v-if="subtitle">{{ subtitle }}</h2>
    <transition name="fade">
      <div class="progress-bar-container" v-if="uploadState === 'uploading'">
        <ProgressBar :progress="uploadProgress" :height="12" backgroundColor="rgba(255, 98, 80, 0.3)" />
        <div class="progress-bar-text">Uploading screens</div>
      </div>
    </transition>
    <div v-show="stage === 'upload-file'" class="stage">
      <upload
        accept=".sketch"
        @start="onUploadStart"
        @success="onUploadSuccess"
        @failure="onUploadFailure"
        @upload-progress="onUploadProgress"
        :isFileValid="this.validateFileBeforeUpload"
        fieldName="file"
        :url="`/v2/uploads/projects/${this.project.id}/file`"
      >
        <div class="upload-file-frame">
          <div class="content">
            <img :src="require('@/assets/illustrations/sketch-to-anima.svg')" class="upload-file-frame-illustration" />
            <p class="upload-file-frame-text">
              <span class="faded">Drag &amp; drop, or</span> <span class="demmy-link">Browse your files</span>
            </p>
          </div>
        </div>
      </upload>
    </div>

    <sketch-select-artboards
      v-if="stage === 'pick-artboards'"
      :sketch-file="sketchFile"
      @submit="syncArtboards"
      @close="handleClose"
    />

    <div v-if="stage === 'syncing-project'">
      <default-loader v-if="sketchProcessState <= 3" />
      <div v-if="sketchProcessState === 1">Processing Sketch file</div>
      <div v-if="sketchProcessState === 2">Uploading processed data</div>
      <div v-if="sketchProcessState === 3">Generating code</div>
      <div v-if="sketchProcessState === 4">Oops. Something went wrong.</div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';
import SketchFile, { numericSketchVersion } from '@/plugins/SketchFile';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import Upload from '@/components/Upload/Upload.vue';
import { mapState } from 'vuex';
import api from '@/api';
import axios from 'axios';
import DefaultLoader from '@/components/Loading/DefaultLoader.vue';
import SketchSelectArtboards from './SketchSelectArtboards.vue';
import { EventBus, toastError } from '@/services/bus';
import pickBy from 'lodash-es/pickBy';
import { poll } from '@/utils/javascript';

const DEFAULT_SKETCH_VERSION = '70.6';

function encodeArtboardsByPagesToServer(selectedArtboardsByPages) {
  const res = {};
  for (let pageId in selectedArtboardsByPages) {
    const allFalse = Object.values(selectedArtboardsByPages[pageId]).every(v => !v);
    if (allFalse) {
      continue;
    }
    const allTrue = Object.values(selectedArtboardsByPages[pageId]).every(v => !!v);
    if (allTrue) {
      // All of the artboards are True. To reduce request size, the server
      // reads an empty page as a page with all artboards.
      res[pageId] = {};
    } else {
      res[pageId] = pickBy(selectedArtboardsByPages[pageId]);
    }
  }
  return res;
}

export default {
  components: { Upload, DefaultLoader, SketchSelectArtboards, ProgressBar },
  name: 'SketchFileSync',
  data() {
    return {
      uploadState: 'init',
      file: null,
      sketchFileUrl: null,
      sketchFileProcessingDone: false,

      uploadProgress: 0,

      supportedSketchVersion: DEFAULT_SKETCH_VERSION,

      sketchFile: null,
      sketchProcessState: null,
      sketchStatusUrl: null,
      sketchSyncId: null
    };
  },
  async created() {
    api
      .get(`/services/server-sync/supported_sketch_version`)
      .then(({ data: version }) => {
        this.supportedSketchVersion = numericSketchVersion(version?.toString() || DEFAULT_SKETCH_VERSION);
      })
      .catch(console.error);
  },
  computed: {
    ...mapState('projects', { project: 'currentItem' }),
    stage() {
      if (this.sketchProcessState !== null) {
        return 'syncing-project';
      }
      if (this.sketchFileProcessingDone) {
        return 'pick-artboards';
      }
      if (this.uploadState === 'init' || this.uploadState === 'uploading' || this.uploadState === 'failed') {
        return 'upload-file';
      }
      return 'pick-artboards';
    },
    subtitle() {
      if (this.stage === 'pick-artboards' || this.stage === 'pick-artboards') {
        return 'Select the artboards you’d like to sync to yout project';
      }
      return '';
    },
    containerClass() {
      if (this.stage === 'upload-file' || this.stage === 'pick-artboards') {
        return 'full';
      }
      return '';
    }
  },
  methods: {
    onUploadSuccess(result) {
      this.$trackEvent('sketch-file-upload.file-upload.success');

      this.uploadState = 'success';
      this.sketchFileUrl = result.public_url;
    },
    onUploadFailure() {
      this.$trackEvent('sketch-file-upload.file-upload.failure');
      this.$sentry.captureMessage('sketch-file-upload.sync-file.failure', 'error');
      toastError('Oops. Something went wrong. Please try again.');
      this.uploadState = 'failed';
    },
    getAmountOfSelectedArtboards(selectedArtboardsByPages) {
      let amountOfArtboards = 0;
      let amountOfSelectedArtboards = 0;

      Object.keys(selectedArtboardsByPages).forEach(pageId => {
        Object.keys(selectedArtboardsByPages[pageId]).forEach(screenId => {
          amountOfArtboards += 1;
          amountOfSelectedArtboards += selectedArtboardsByPages[pageId][screenId] ? 1 : 0;
        });
      });
      return { amountOfArtboards, amountOfSelectedArtboards };
    },
    async syncArtboards({ selectedArtboardsByPages, importBy }) {
      const { amountOfArtboards, amountOfSelectedArtboards } = this.getAmountOfSelectedArtboards(
        selectedArtboardsByPages
      );

      this.$trackEvent('sketch-file-upload.sync-file.start', {
        importBy,
        amountOfArtboards,
        amountOfSelectedArtboards
      });

      let syncId, statusUrl;
      try {
        await this.finishUpload();
        const {
          data: { sync_id, status_url }
        } = await api.post(`/services/server-sync/projects/${this.project.id}/sync`, {
          sketch_file_url: this.sketchFileUrl,
          artboards_by_pages: encodeArtboardsByPagesToServer(selectedArtboardsByPages)
        });
        syncId = sync_id;
        statusUrl = status_url;
      } catch (error) {
        const exception = error.error || error.message || error.originalError || error;
        this.$sentry.captureException(exception);
        this.$trackEvent('sketch-file-upload.sync-file.failure', { reason: 'request-failed', message: exception });
        toastError('Oops. Something went wrong. Please try again.');
        this.uploadState = 'init';
        this.sketchProcessState = null;
      }

      this.sketchSyncId = syncId;
      this.sketchStatusUrl = statusUrl;
      this.listenToStatusChanges();
    },
    listenToStatusChanges() {
      setTimeout(async () => {
        if (!this.sketchStatusUrl) {
          return;
        }
        const newStatus = await axios.get(this.sketchStatusUrl).catch(this.handleSyncError);
        const newStatusCode = newStatus.data.status;
        if (newStatusCode !== this.sketchProcessState) {
          this.sketchProcessState = newStatusCode;
        }
        if (newStatusCode === 3) {
          setTimeout(this.handleWrapSync, 1500);
          return;
        }
        if (newStatusCode === 4) {
          this.handleSyncError();
          return;
        }
        this.listenToStatusChanges();
      }, 1000);
    },
    finishUpload() {
      return poll({
        fn: () => this.uploadState,
        validate: state => {
          if (state === 'failed') throw new Error('Upload failed.');
          else return state === 'success';
        },
        interval: 100,
        maxAttempts: 3000 // 3000 * 100 = 5 minutes
      });
    },
    handleSyncError() {
      this.$trackEvent('sketch-file-upload.sync-file.failure', { reason: 'sync-failed' });
      this.$sentry.captureMessage('sketch-file-upload.sync-file.failure', 'error');
      this.handleError();
    },
    handleError(msg = 'Oops. Something went wrong. Please try again.') {
      toastError(msg);
      this.handleClose();
    },
    handleClose() {
      this.$emit('close');
    },
    handleWrapSync() {
      this.$trackEvent('sketch-file-upload.sync-file.success');

      EventBus.$emit('reload-project-data');
      this.$emit('close');
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    onUploadProgress({ loaded, total }) {
      if (!total) return 0;
      this.uploadProgress = loaded / total;
    },
    async onUploadStart(uploadContext) {
      this.$trackEvent('sketch-file-upload.file-upload.start');
      try {
        this.uploadState = 'uploading';
        const zip = await JSZip.loadAsync(uploadContext.fileRef.files[0]);
        this.fileName = uploadContext.fileRef.files[0].name;
        const sketchFile = new SketchFile(zip);
        await sketchFile.process();
        this.sketchFileProcessingDone = true;
        if (sketchFile.version > this.supportedSketchVersion) {
          this.$trackEvent('sketch-file-upload.sync-file.failure', {
            reason: 'unsupported-version',
            version: sketchFile.version
          });
          toastError(`Unsupported Sketch file version.`);
          this.handleError();
        }
        this.sketchFile = sketchFile;
        this.$trackEvent('sketch-file-upload.parse-file.success');
      } catch (e) {
        console.error(e);
        this.$trackEvent('sketch-file-upload.parse-file.failure');
        this.handleError('Invalid file format. Please try with a new file.');
      }
    },
    validateFileBeforeUpload(file) {
      const fileName = file.files[0].name;
      if (fileName.toLowerCase().endsWith('.sketch')) {
        return true;
      }
      this.$trackEvent('sketch-file-upload.file-upload.wrong-format', { format: fileName.split('.').pop() });
      toastError('Uploaded file is not a valid Sketch file. Only *.sketch files are allowed.');
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/_fullscreenLayout.scss';
@import '@/styles/_mixins.scss';
.title {
  margin-bottom: 20px;
}
h2 {
  margin-bottom: 40px;
}
.container.full {
  max-width: unset;
  .stage {
    margin-top: 40px;
    align-items: center;
  }
}
.clickable {
  cursor: pointer;
}
.file-name {
  margin: 84px 0;
}
.divider {
  height: 1px;
  background: var(--tertiary);
  width: 100%;
  margin: 30px 00;
}
.stage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-align: left;
  &.pick-artboards p {
    margin: 8px 0;
  }
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  .progress-bar-text {
    margin-top: 20px;
    opacity: 0.4;
  }
}

.upload-file-frame {
  width: 75vw;
  height: 60vh;
  min-height: 540px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  .content {
    position: relative;
    width: 100%;
    text-align: center;
    .upload-file-frame-text {
      color: var(--primary-text);
      .faded {
        opacity: 0.4;
      }
      .demmy-link {
        color: var(--red);
        text-decoration: underline;
      }
    }
    .upload-file-frame-illustration {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 35%;
    }
  }
  @include dashed-border;
  @include mobile {
    min-height: 300px;
  }
}
</style>
