<template>
  <div class="login-page-container">
    <OnboardingTopBar homepageUrl="https://animaapp.com" />
    <div class="login-container">
      <LoadingScreen v-if="googleLoading" />
      <div class="form-container" v-else>
        <div class="title">Log in to Anima</div>
        <div class="subtitle">
          Don't have an account yet?
          <an-link @click="$router.push({ name: 'signup', query: $route.query })" variant="primary" data-cy="signup">
            Sign up for free
          </an-link>
        </div>
        <div class="social-login" v-if="showGoogleLogin">
          <GoogleButton @click="onGoogleLoginClick" @success="onGoogleLoginSuccess" @failure="onGoogleLoginFailure">
            Log in with Google
          </GoogleButton>
        </div>
        <div class="text-divider" v-if="showGoogleLogin">
          <Divider text="Or" :theme="theme" />
        </div>
        <form v-on:submit.prevent="login">
          <div class="property">
            <div class="input">
              <an-input
                id="email"
                type="email"
                v-model="email"
                :theme="theme"
                placeholder="name@work-email.com"
                data-cy="login-email"
              />
            </div>
          </div>
          <div class="property">
            <an-input
              id="pass"
              type="password"
              v-model="password"
              :theme="theme"
              placeholder="Password"
              data-cy="login-password"
            />
          </div>
          <div class="forgot-password">
            <an-link @click="forgotPassword" :theme="theme" primaryHover>
              Forgot password?
            </an-link>
          </div>
          <div class="invalid-message" v-if="errorMessage">
            <svg-icon name="info" fill="var(--red)" />
            <span>{{ errorMessage }}</span>
          </div>
          <div class="actions">
            <an-button class="justify-center" type="submit" :isWorking="loading" size="xl" data-cy="login-submit">
              Log in
            </an-button>
          </div>
        </form>
      </div>
      <div class="login-illustration" v-if="!googleLoading" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import LoadingScreen from '@/components/Loading/LoadingScreen';
import GoogleButton from '@/components/Button/GoogleButton';
import Divider from '@/components/Divider/Divider';
import OnboardingTopBar from '@/components/Onboarding/TopBar';
import { SignupMixin } from '@/mixins';

import auth from '@/auth';
import { toastError } from '@/services/bus';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      theme: 'dark',
      loading: false,
      googleLoading: false,
      showGoogleLogin: !process.env.ORGANIZATION_NAME
    };
  },
  mixins: [SignupMixin],
  components: {
    GoogleButton,
    Divider,
    LoadingScreen,
    OnboardingTopBar
  },
  mounted() {
    this.applyTheme(this.theme, 'onboarding');
    if (window && window.top && window.top.postMessage) {
      //if it's chrome extension we will send a message so we can send the user to login
      window.top.postMessage('needs-to-login', '*');
    }
  },
  beforeDestroy() {
    this.removeTheme();
  },
  computed: {
    ...mapGetters({ firstName: 'users/firstName' })
  },
  methods: {
    ...mapActions({
      getUser: 'users/fetchOne',
      createTeam: 'teams/create',
      updateUser: 'users/update'
    }),
    ...mapMutations({
      setCurrentTeam: 'teams/setCurrentItem'
    }),
    onGoogleLoginClick() {
      this.$trackEvent('login.google-login-button.click');
    },
    async onGoogleLoginSuccess(googleUser) {
      try {
        this.googleLoading = true;
        await this.onGoogleSuccess(googleUser);
      } catch (error) {
        this.$sentry.captureException(error);
        toastError("We couldn't log you in with your Google account :(");
      } finally {
        this.googleLoading = false;
      }
    },
    async onGoogleLoginFailure(err) {
      this.$sentry.captureException(err);
      this.$trackEvent('login.google-login.failure');
      toastError("We couldn't log you in with your Google account :(");
    },
    forgotPassword() {
      this.$router.replace({ name: 'forgot-password', query: this.$route.query });
    },
    async login() {
      try {
        this.$trackEvent('login.login-button.click');

        this.errorMessage = '';
        this.loading = true;
        const { access_token: token } = await auth.login(this.email, this.password);
        localStorage.setItem('token', token);

        const user = await this.getUser({ id: 'me', skipCache: true });

        this.$tracking.setUserEmail(user.email);
        this.$tracking.setUserId(user.id);
        this.$tracking.alias(user.id);

        this.$trackEvent('login.login.success');

        await this.redirectAfterAuth(true);
      } catch (error) {
        this.$sentry.captureException(error);
        this.errorMessage = error?.data?.description || error?.message || 'Incorrect email, password or both.';
        this.$trackEvent('login.login.failure', { message: this.errorMessage, statusCode: error?.status });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$topBarHeight: 80px;
.form-container {
  width: 335px;
  text-align: center;
  @include mobile {
    width: 375px;
    padding: 20px;
  }
}
.title {
  @include headline;
  font-weight: 500;
  text-align: center;
}
.subtitle {
  margin: 20px 0 40px;
  @include mobile {
    margin: 16px 0;
  }
}
.details {
  padding: 0;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $topBarHeight;
  height: calc(100vh - #{$topBarHeight});
  width: 100%;
  @include mobile {
    margin-top: 60px;
  }
  .container {
    margin: 0;
    width: 335px;
    @include mobile {
      width: 100%;
    }
  }
  .login-illustration {
    background: url('~@/assets/illustrations/signup.svg');
    background-size: cover;
    margin-left: 210px;
    width: 624px;
    height: 550px;
    @media screen and (max-width: 1280px) {
      width: 500px;
      height: 440px;
    }
    @include mobile {
      display: none;
    }
  }
}
.title {
  @include mobile {
    font-size: 32px;
    line-height: 50px;
  }
}
.social-signup,
.text-divider {
  margin: 40px 0;
  width: 100%;
}
.actions {
  height: 48px;
  margin-top: 10px;
  @include mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  button {
    width: 100%;
    &.an-button {
      @include mobile {
        border-radius: 0;
      }
    }
  }
}
.property + .property {
  margin-top: 24px;
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  opacity: 0.4;
  margin-bottom: 30px;
  &:hover {
    opacity: 1;
  }
}
.invalid-message {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: var(--red);
  > * + * {
    margin-left: 12px;
  }
}
</style>
