var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { variant: "top", closeButton: "" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [_c("div", { staticClass: "prompt-container" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }