import { createStoreModule } from '../utils';

export default createStoreModule({
  name: 'notificationsSettings',
  modelName: 'notifications_settings',
  crud: true,
  endpoint: {
    default: '/v2/notifications_settings',
    list: '/v2/users/me/notifications_settings'
  },
  getters: {
    emailSettings: s => s.items?.find(item => item.channel === 'email') ?? {},
    slackSettings: s => s.items?.find(item => item.channel === 'slack') ?? {}
  }
});
