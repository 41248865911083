<template>
  <div class="wrapper">
    <template v-if="!isSuggestions">
      <div style="margin-bottom:20px" class="flex flex-col items-center">
        <div class="header">Edit component interface</div>
        <div class="sub-header">
          Choose which parts of the component will be props and change prop names.
        </div>
      </div>
      <div class="interface-body flex items-center">
        <div class="frame">
          <ComponentFrame :padding="20" name="componentIframeInterface" />
        </div>
        <PropsInterface
          :componentName="componentName"
          :regularProps="regularPropsCopy"
          :nestedProps="nestedPropsCopy"
          :propsLoading="propsLoading"
          @update-component-name="handleUpdateComponentName"
          @update-component-prop-enable="handleUpdatePropEnable"
          @update-component-prop-name="handleUpdatePropName"
          @onPropsLoading="onPropsLoading"
        />
      </div>
    </template>
    <template v-else>
      <template v-if="!isReadyToStart">
        <div style="margin-bottom:20px" class="flex flex-col items-center">
          <div class="header">We’ve found some nested components</div>
          <div class="sub-header">
            We recommend you’ll take 30 sec to approve inner components, so we’ll produce your code nice and clean.
            Ready?
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-full flex items-center justify-center">
            <an-button @click="$emit('close')" style="margin-right:10px" variant="empty">No Thanks</an-button>
            <an-button @click="handleReadyToStart">Review suggestions</an-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div style="margin-bottom:20px" class="flex flex-col items-center">
          <div v-if="suggestionIndex > 0" @click="back" class="flex items-center back">
            <svg-icon fill="currentColor" name="arrow-left" :size="20"></svg-icon>
            <an-button style="margin-left:5px" variant="empty">Back</an-button>
          </div>

          <div class="header">Review component suggestions</div>
          <div class="sub-header">
            We have a few suggestions for nested components. Edit props, prop names, and approve or dismiss the
            suggestions.
          </div>
        </div>
        <div class="interface-body flex items-center">
          <div class="frame">
            <ComponentFrame name="suggestedComponentIframe" />
          </div>
          <!-- <PropsInterface
            :componentName="suggestedComponentName"
            :regularProps="suggestedRegularPropsCopy"
            :nestedProps="suggestedRegularPropsCopy"
          /> -->
        </div>
        <div style="margin-top:30px" class="w-full flex items-center">
          <div>Suggestion {{ suggestionIndex + 1 }}/{{ suggestedNodeIds.length }}</div>
          <div class="flex items-center" style="margin-left:auto">
            <an-button style="margin-right:20px" variant="empty">Not a component</an-button>
            <an-button @click="next">Approve</an-button>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ComponentFrame from '@/components/OmniView/ComponentFrame';
import PropsInterface from '@/components/OmniView/Code/PropsInterface';
import { cloneDeep, get, camelCase } from 'lodash-es';
import { EventBus } from '@/services/bus';
import { SEND_COMPONENT_MESSAGE } from '@/utils/events/omniviewEvents';
import { AsyncQueue } from '@/utils/javascript';
import axios from 'axios';
import { JAVASCRIPT_KEYWORDS } from './constants';

const requestQueue = new AsyncQueue();

export default {
  props: {
    isSuggestions: {
      type: Boolean,
      default: false
    },
    suggestedNodeIds: {
      type: Array,
      default: () => []
    },
    suggestionsScope: {
      type: String,
      default: 'project'
    }
  },
  name: 'component-interface',
  components: {
    ComponentFrame,
    PropsInterface
  },
  data() {
    return {
      propsLoading: false,
      componentName: '',
      regularPropsCopy: [],
      nestedPropsCopy: [],

      isReadyToStart: false,
      suggestionIndex: 0,
      suggestedComponentName: '',
      activeSuggestedComponent: {},
      suggestedRegularPropsCopy: [],
      suggestedNestedPropsCopy: []
    };
  },
  computed: {
    ...mapGetters({
      currentNode: 'omniview/currentNode',
      currentWebComponent: 'webComponents/currentWebComponent',
      getMasterAndInstanceByNodeId: 'webComponents/getMasterAndInstanceByNodeId'
    })
  },

  methods: {
    ...mapActions({
      updateWebComponentOverride: 'webComponents/updateWebComponentOverride'
    }),

    setupSuggestedComponent(nodeId) {
      const { master, instance } = this.getMasterAndInstanceByNodeId(nodeId);
      this.activeSuggestedComponent = { master, instance };
      const { regular, nested } = this.getCurrentSuggestedProps();

      this.suggestedRegularPropsCopy = regular;
      this.suggestedNestedPropsCopy = nested;
      this.suggestedComponentName = master['component_name'] || master['name'] || '';

      EventBus.$emit('populate-component-frame', { iframeName: 'suggestedComponentIframe', nodeId });
    },
    onPropsLoading(flag) {
      this.propsLoading = flag;
    },
    async handleReadyToStart() {
      this.isReadyToStart = true;
      this.$emit('updateParams', { width: 1168 });

      await this.$nextTick();
      const nodeId = this.suggestedNodeIds[0];

      this.setupSuggestedComponent(nodeId);
    },
    next() {
      if (this.suggestionIndex < this.suggestedNodeIds.length - 1) {
        this.suggestionIndex += 1;
        let nodeId = this.suggestedNodeIds[this.suggestionIndex];
        this.setupSuggestedComponent(nodeId);
      }
    },
    back() {
      if (this.suggestionIndex > 0) {
        this.suggestionIndex += -1;
        let nodeId = this.suggestedNodeIds[this.suggestionIndex];
        this.setupSuggestedComponent(nodeId);
      }
    },

    validateName(new_name) {
      let og_name = `${new_name}`;
      let specialCharsTest = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let alphaTest = /^[A-Z]$/i;

      new_name = camelCase(new_name);
      new_name = og_name.charAt(0) + new_name.slice(1);

      if (specialCharsTest.test(new_name)) {
        return false;
      }

      if (JAVASCRIPT_KEYWORDS.includes(new_name.toLowerCase())) {
        new_name = 'x' + new_name;
      }
      if (!alphaTest.test(new_name.charAt(0))) {
        new_name = 'x' + new_name;
      }
      if (new_name.length > 35) {
        new_name = new_name.slice(0, 35);
      }
      return new_name;
    },

    async handleUpdateComponentName(payload) {
      const { name: newName, scope } = payload;

      if (scope == 'suggested') return;

      const { projectId } = this.$route.params;
      const { master } = this.currentWebComponent;

      const oldName = master['component_name'] || '';
      if (!newName) return;

      const validName = this.validateName(newName);

      if (newName == oldName || !validName) {
        this.updateCopy();
        EventBus.$emit('web_component_override_update');
        return;
      }

      this.componentName = validName;

      const requestParams = {
        project_id: projectId,
        master_id: master.master_id,
        override_key: 'component_name',
        new_override_value: this.componentName
      };

      this.updateWebComponentOverride({
        requestParams,
        type: 'name'
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.edit-name', {
        oldName: oldName,
        name: this.componentName,
        projectId,
        masterId: master.masterId
      });
    },
    async handleUpdatePropName(payload) {
      const { prop: newProp, type } = payload;
      const { projectId } = this.$route.params;
      let { master } = this.currentWebComponent;

      const currentProps = this.getCurrentProps()[type];
      const oldProp = currentProps.find(p => p.prop_fingerprint == newProp.prop_fingerprint);

      if (!newProp.name) return;

      if (newProp.name == oldProp.name) return;

      let validPropName = this.validateName(newProp.name);

      if (!validPropName) {
        this.updateCopy();
        EventBus.$emit('web_component_override_update');
        return;
      }

      const requestParams = {
        project_id: projectId,
        master_id: master.master_id,
        override_key: 'prop_name',
        prop_fingerprint: oldProp.prop_fingerprint,
        new_override_value: validPropName
      };

      this.updateWebComponentOverride({
        requestParams,
        type
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.edit-prop-name', {
        oldName: oldProp.name,
        name: validPropName,
        propType: type,
        projectId,
        masterId: master.masterId
      });
    },

    handleUpdatePropEnable(payload) {
      const { prop: newProp, type } = payload;
      let { master } = this.currentWebComponent;
      const { projectId } = this.$route.params;

      const requestParams = {
        project_id: projectId,
        master_id: master.master_id,
        override_key: 'prop_enable',
        new_override_value: newProp['is_enable'],
        prop_fingerprint: newProp.prop_fingerprint,
        prop_enable: !newProp['is_enable']
      };

      this.updateWebComponentOverride({
        requestParams,
        type
      });
      this.AfterUpdate();

      requestQueue.enqueue(() => this.makeUpdateCall(requestParams));

      this.$trackEvent('omniview.component.prop-toggle', { enabled: newProp['is_enable'], propType: type });
    },

    async AfterUpdate() {
      this.updateCopy();
      await this.$nextTick();
      // EventBus.$emit('update-anima-scripts-modelNodes');
      EventBus.$emit('pre-process', { forcePreProcess: true });
      EventBus.$emit('generate-code', { forceGenerate: true });
      EventBus.$emit('web_component_override_update');
    },
    async makeUpdateCall(requestParams) {
      const { project_id, master_id } = requestParams;
      const { data } = await axios.put(`/project/${project_id}/web_component/${master_id}/overrides`, {
        overrides: [requestParams]
      });
      return data;
    },

    getCurrentProps() {
      let { instance } = this.currentWebComponent;
      instance = cloneDeep(instance);
      const regular = get(instance, 'props.regular', []);
      const nested = get(instance, 'props.nested', []);
      return {
        regular,
        nested
      };
    },
    getCurrentSuggestedProps() {
      let { instance } = this.activeSuggestedComponent;
      if (!instance) return { regular: [], nested: [] };
      instance = cloneDeep(instance);
      const regular = get(instance, 'props.regular', []);
      const nested = get(instance, 'props.nested', []);
      return {
        regular,
        nested
      };
    },
    getNewProps() {
      return {
        regular: this.regularPropsCopy,
        nested: this.nestedPropsCopy
      };
    },

    handlePropMouseOver(prop) {
      EventBus.$emit(SEND_COMPONENT_MESSAGE, {
        action: 'simulate-code-mouseover',
        data: {
          nodeId: prop.model_id
        },
        iframeName: 'componentIframeInterface'
      });
    },
    handlePropMouseOut() {
      EventBus.$emit(SEND_COMPONENT_MESSAGE, {
        action: 'simulate-code-mouseout',
        iframeName: 'componentIframeInterface'
      });
    },
    updateCopy() {
      if (!this.isSuggestions) {
        const { master } = this.currentWebComponent;
        const { regular, nested } = this.getCurrentProps();
        this.regularPropsCopy = regular;
        this.nestedPropsCopy = nested;
        this.componentName = master['name'] || master['component_name'] || '';
      }
      this.propsLoading = false;
    }
  },
  mounted() {
    EventBus.$on('update-interface-props', this.updateCopy);
    this.updateCopy();
  },
  beforeDestroy() {
    if (this.isSuggestions) {
      localStorage.setItem(`${this.suggestionsScope}_lastShownSuggestionTimestamp`, Date.now().toString());
    }
  },
  destroyed() {
    EventBus.$off('update-interface-props', this.updateCopy);
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 30px;
  position: relative;
}
.back {
  position: absolute;
  top: 30px;
  left: 30px;
  color: var(--primary);
  cursor: pointer;
}
.header {
  font-size: 24px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 20px;
}
.interface-body > * + * {
  margin-left: 10px;
}
.sub-header {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.6);
  // max-width: 500px;
  text-align: center;
}

.frame {
  background-image: url('./../../../assets/svg/mask.svg');
  // background-repeat: no-repeat;
  // background-size: cover;
}

.frame {
  width: 50%;
  min-height: 375px;
  height: 375px;
}
</style>
