var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "members" },
    [
      _vm._l(_vm.users, function(member, index) {
        return _c("an-user-avatar", {
          key: member.user.email || index,
          staticClass: "member",
          attrs: {
            user: member.user,
            borderColor: _vm.avatarStyle.borderColor,
            size: _vm.size
          },
          on: {
            click: function($event) {
              return _vm.$emit("click", member)
            }
          }
        })
      }),
      _vm.showMore && _vm.users.length
        ? _c(
            "div",
            {
              staticClass: "more",
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                }
              }
            },
            [
              _c("svg-icon", { attrs: { name: "circle-plus", size: _vm.size } })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }